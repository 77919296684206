import Login from '../views/authentication/login';
import Register from '../views/authentication/register';
import MobileDashboard from '../views/mobile-dashboard/mobile-dashboard';
import FootpedalReplacementForm from '../views/general/footpedal-replacement-form';
import FootpedalReplacementResults from '../views/general/footpedal-replacement-results';
import RejoyView from '../views/general/rejoy';

var authRoutes = [
    { path: '/fpreplacement', name: 'Footpedal Replacement Form', icon: 'mdi mdi-account-key', component: FootpedalReplacementForm },
    { path: '/fpreplacementresults', name: 'Footpedal Replacement Results', icon: 'mdi mdi-account-key', component: FootpedalReplacementResults },
    { path: '/rejoy', name: 'Rejoy', icon: 'mdi mdi-account-key', component: RejoyView },
    { path: '/authentication/login', name: 'Login', icon: 'mdi mdi-account-key', component: Login }
    //{ path: '/authentication/register', name: 'Register', icon: 'mdi mdi-account-plus', component: Register }
];
export default authRoutes;
