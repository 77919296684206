import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";
import { Rating } from 'react-simple-star-rating'

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class FeedbackList extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
      obj: {},
			modal: false,
			modal2: false,
			isOpen: false,
			feedbackData: [],
      proceduresData: [],
      a1: [
        "Very satisfied, the treatment helped my injury.",
        "Somewhat satisfied, still have an injury.",
        "Helped just a little bit.",
        "Didn't work at all."
      ],
      a2: [
        "Resolved my injury.",
        "Allowed me to recuperate faster.",
        "Reduced the amount of pain I had.",
        "I feel it gave me better performance.",
        "I didn't notice any help with the laser treatment."
      ],
      a1Summary: [
        "Very satisfied",
        "Somewhat satisfied",
        "Helped a little",
        "Didn't work"
      ],
      a2Summary: [
        "Resolved injury",
        "Recuperated faster",
        "Reduced pain",
        "Better performance",
        "No Help"
      ],
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

    this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);

  }


	async componentDidMount() {
		this.getFeedbackData();
		//this.intervalID = setInterval(this.getFeedbackData, 5000);
	}

	componentWillUnmount() {
		//clearInterval(this.intervalID);
	}

  toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	toggle2() {
	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

  viewFeedbackDetail(obj) {

	  this.setState({
	    modal: !this.state.modal
	  });

	}

	getFeedbackData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-dashboard-user-feedback-records";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("getFeedbackData data: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ feedbackData: body.response });
		} else {
      //ADD ERROR MESSAGE
    }

	};

	deleteFeedback = async () => {

		console.log("Delete Note: " + this.state.obj.description);

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-dashboard-user-feedback-record?FeedbackRecordID="+this.state.obj.feedbackRecordID;

    console.log("Delete note url: " + url);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("Delete note response: " + JSON.stringify(body.response));

		if (body.status == 'Success') {

			this.toggle2();
      this.getFeedbackData();

		} else {


		}

	};

  getProceduresForFeedbackData = async (obj) => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-treatment-procedure-records-for-feedback?FeedbackRecordID="+obj.feedbackRecordID;

    console.log("getProceduresForFeedbackData url: " + url);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("getProceduresForFeedbackData data: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ proceduresData: body.results });

      // this.setState({
      //   modal: !this.state.modal,
      // });

      //setTimeout(this.toggle, 1000);
      this.toggle();

		} else {
      //ADD ERROR MESSAGE
    }

	};

  filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id;

		console.log("filterCaseInsensitive row[id]: " + JSON.stringify(id));

		if (id == 'createdDate') {
			var dateString = Moment(row[id]).format('MM/DD/YYYY');
			return (
					dateString !== undefined ?
							String(dateString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (id == 'declined') {
			var boolString = row[id]==0 ? "true" : "false";
			return (
					boolString !== undefined ?
							String(boolString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (id == 'notExperienced') {
			var boolString = row[id]==0 ? "false" : "true";
			return (
					boolString !== undefined ?
							String(boolString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (row[id] !== null){
			return (
					row[id] !== undefined ?
							String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}
	}

  render() {

		const data = this.state.feedbackData.map((feedback, key) => {

			//const buf = Buffer.from(prop.detailedissue, 'utf8');

			//buf.toString(); // 'Hello, World'

			return {
				id: key,
        feedbackRecordID: feedback.ID,
				name: feedback.Name,
				email: feedback.Email,
				createdDate: feedback.CreatedDate,
				rating: feedback.Rating,
        feedback: feedback.Feedback,
				declined: feedback.Declined,
				notExperienced: feedback.NotExperienced,
				view: (
					// we've added some custom button actions
					<div className="text-center">
            <Button
  							onClick={() => {
                  let obj = data.find(o => o.id === key);
                  console.log('button obj: ' + obj);
                  this.setState({
                    obj: obj,
										modal: true,
                  });
  								//this.showVerification(obj);

                  //this.getProceduresForFeedbackData(obj);
  							}}
                style={{ height: "25px", lineHeight: "0.6" }}
  							color="link"
  							size="sm"
  							round="true"
  							icon="true"
  							className="ml-2"
  							title="View Feedback"
  						>
  							<i style={{ lineHeight: "1.1" }} className="mdi mdi-eye mdi-24px" />
  					</Button>
						{/* use this button to add a edit kind of action */}
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				),
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal2: !this.state.modal2,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
					</div>
				)
			};
		});

		return (
			<Fragment>
				<Row>
					<Col md="12">
						<Card className="card-hover">
							<CardBody>
								<Row className="mb-3">
									<Col md="6">
										<h1 style={{color:'#737782'}}>Treatment Feedback</h1>
									</Col>
								</Row>
								<ReactTable
									style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
									columns={[
										{
                      Header: "Feedback",
                      accessor: "view",
                      sortable: false,
                      minWidth: 80,
                      maxWidth: 80,
                      filterable: false
                    },
										{
											Header: "Date",
											accessor: "createdDate",
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{Moment(row.value).format("MM/DD/YYYY")}</div>
												)
										},
										{
											Header: "Name",
											accessor: "name",
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
										{
											Header: "Email",
											accessor: "email",
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
										{
											Header: "Rating",
											accessor: "rating",
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center", marginTop: "-3px" }}><Rating ratingValue={row.value} readonly={true} transition={false} size={20} /></div>
												)
										},
										{
											Header: "Not Enough Experience",
											accessor: "notExperienced",
											minWidth: 170,
											maxWidth: 170,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<Fragment>
													{
														//mdi-close-circle-outline
													}
													{row.value == 0 ? (
														<i className="mdi mdi-checkbox-blank-outline mdi-24px ml-auto mr-auto" />
													) : (
														<i className="mdi mdi-checkbox-marked mdi-24px ml-auto mr-auto" />
													)}
													</Fragment>
												)
										},
										{
											Header: "Notes",
											accessor: "feedback",
											minWidth: 500,
                      maxWidth: 500,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<Fragment>
													{row.value && row.value.length>0 ? (
														<div style={{ textAlign: "center" }}>{row.value.substring(0, 70)}...</div>
													) : (
														<div style={{ textAlign: "center" }}>N/A</div>
													)}
													</Fragment>
												)
										},
										{
											Header: "Feedback Status",
											accessor: "declined",
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<Fragment>
													{
														//mdi-close-circle-outline
													}
													{row.value == 0 ? (
														<i style={{ color: "#48b203" }} className="mdi mdi-check-circle-outline mdi-24px ml-auto mr-auto" />
													) : (
														<i style={{ color: "#dc3545" }} className="mdi mdi-close-circle-outline mdi-24px ml-auto mr-auto" />
													)}
													</Fragment>
												)
										},
										{
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      minWidth: 60,
                      maxWidth: 60,
                      filterable: false
                    },
									]}
									defaultPageSize={15}
									sortable={true}
									showPaginationBottom={true}
									className="-striped -highlight"
									data={data}
									filterable
                  filterAll={true}
							    defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
									getTrGroupProps={(state, rowInfo, column, instance) => {
													if (rowInfo !== undefined) {
															return {
																	onClick: (e, handleOriginal) => {
																		console.log('It was in this row:', rowInfo)
																		console.log('with ID:', rowInfo.original.categoryID);

																		//this.goToRepairLog(rowInfo.original.repairId);
																	},
																	style: {
																			cursor: 'pointer'
																	}
															}
													}}
											}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
        <div>
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>User Feedback</ModalHeader>
            <ModalBody>
							<Row>
								<Col md="6">
									<Label for="subject">User:</Label>
								</Col>
								<Col md="6">
									<Input style={{ height: "20px", padding: "0", paddingLeft: "5px" }} type="text" name="name" id="name" defaultValue={this.state.obj.name} disabled/>
								</Col>
							</Row>
							<Row>
								<Col md="6">
									<Label for="subject">Email:</Label>
								</Col>
								<Col md="6">
									<Input style={{ height: "20px", padding: "0", paddingLeft: "5px" }} type="text" name="email" id="email" defaultValue={this.state.obj.email} disabled/>
								</Col>
							</Row>
							<hr/>
							<Label>How do you like the Dashboard?</Label>
							<Row className="text-center mt-1 mb-4">
								<Col>
									<Rating style={{ marginLeft: "auto", marginRight: "auto"}} size={50} ratingValue={this.state.obj.rating} readonly={true} transition={false} />
								</Col>
							</Row>
							<Label>Do you mind providing a suggestion on what to improve?</Label>
							<Input style={{width:'100%', height: '170px'}} type="textarea" name="feedback" id="feedback" defaultValue={this.state.obj.feedback} disabled="disabled"/>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" className="ml-1" onClick={this.toggle}>Close</Button>
            </ModalFooter>
          </Modal>
					<Modal isOpen={this.state.modal2} toggle={this.toggle2}>
    				<ModalHeader toggle={this.toggle2}>Delete Feedback</ModalHeader>
    				<ModalBody>
  						<span>Are you sure you want to delete this User's Feedback?</span>
    				</ModalBody>
  					<ModalFooter>
  						<Button color="primary" onClick={this.deleteFeedback}>Yes</Button>
  						<Button color="secondary" className="ml-1" onClick={this.toggle2}>Cancel</Button>
  					</ModalFooter>
  			  </Modal>
        </div>
			</Fragment>
		);
	}
}

export default FeedbackList;
