import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";
import ReactPDF from '@react-pdf/renderer';
import { pdf, Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ButtonDropdown
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';
import logoEvo from '../../../assets/images/logo2_x3_text.png';

const styles = StyleSheet.create({
  page: { backgroundColor: '#000000' },
  section: { color: 'white', textAlign: 'center', margin: 30 },
  title: {
    fontSize: 20,
    marginVertical: 5,
    textAlign: 'center',
    color: '#f3c12b'
  },
  TableCellHeaderStyle : {
    padding: 5,
    textAlign: "center",
    fontSize: 14,
    fontWeight: 900,
    backgroundColor: '#ffffff'
  },
  TableCellBodyStyle : {
    padding: 5,
    textAlign: "center",
    fontSize: 11,
    backgroundColor: '#f3c12b'
  },
  TableStyle : {
    padding: 5,
  },
  Table: {
    marginVertical: 5,
    marginHorizontal: 5,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
});

class MyDocument extends React.Component {

  async componentDidMount() {

    console.log("MyDocument warranty data: " + JSON.stringify(this.props.data));

	}

  render() {
         return (
           <Document>
             <Page size="A4" style={styles.page}>
               <Image style={styles.image} src={logoEvo} />
               <Text style={styles.title}></Text>
               <Text style={styles.title}>Warranty Records Report</Text>
               <Text style={styles.title}></Text>
               <Table style={styles.Table} data={this.props.data}>
                 <TableHeader style={styles.Table} textAlign={"center"}>
                     <TableCell style={styles.TableCellHeaderStyle}>
                         Purchase Date
                     </TableCell>
                     <TableCell style={styles.TableCellHeaderStyle}>
                         Serial Number
                     </TableCell>
                     <TableCell style={styles.TableCellHeaderStyle}>
                         User Email
                     </TableCell>
                     <TableCell style={styles.TableCellHeaderStyle}>
                         Warranty Length
                     </TableCell>
                     <TableCell style={styles.TableCellHeaderStyle}>
                         Purchase Price
                     </TableCell>
                 </TableHeader>
                 <TableBody textAlign={"center"} >
								 		<DataTableCell style={styles.TableCellBodyStyle} getContent={(p) => Moment(p.warrantyPurchaseDate).format('MMM Do YYYY')}/>
                    <DataTableCell style={styles.TableCellBodyStyle} getContent={(p) => p.serialNumber}/>
                    <DataTableCell style={styles.TableCellBodyStyle} getContent={(p) => p.email}/>
                    <DataTableCell style={styles.TableCellBodyStyle} getContent={(p) => p.warrantyLength+" years"}/>
                    <DataTableCell style={styles.TableCellBodyStyle} getContent={(p) => "$"+p.warrantyPurchasePrice}/>
                 </TableBody>
               </Table>
             </Page>
           </Document>
     )}
}

class WarrantyRecordsList extends React.Component {

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			repairLogs: [],
      warrantyRecords: [],
			modal: false,
			modal4: false,
			selectAll: false,
			checked: [],
			selectdata: [],
			selectedRecords: [],
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			dropdownOpen: false,
      currentDataSetting: 0,
      startDate: Moment().subtract(1, 'week').format('YYYY-MM-DD'),
			endDate: Moment().format('YYYY-MM-DD'),
			currentDate: Moment().format('YYYY-MM-DD'),
			currentItem: "Global",
			isOpen: false,
	  };

		this.pdfdata = [];

		this.toggle = this.toggle.bind(this);
		this.handleChange = this.handleChange.bind(this);
    this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(this);
		this.toggleDropDown = this.toggleDropDown.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);

  }

	async componentDidMount() {

    this.getData();
	}

	componentWillUnmount() {

	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	toggle4() {
	  this.setState({
	    modal4: !this.state.modal4
	  });
	}

	generateReport(data) {

		if (data.length<201) {
			//this.uploadToS3(data);

		  this.setState({
		    modal: !this.state.modal
		  });
		} else {
			this.toggle4();
		}

	}

	handleChange = () => {
    var selectAll = !this.state.selectAll;
    this.setState({ selectAll: selectAll });
    var checkedCopy = [];
    this.state.selectdata.forEach(function(e, index) {
      checkedCopy.push(selectAll);
    });
    this.setState({
      checked: checkedCopy
    });
  };

  handleSingleCheckboxChange = index => {
    console.log(index);

    var checkedCopy = this.state.checked;
    checkedCopy[index] = !this.state.checked[index];
    if (checkedCopy[index] === false) {
      this.setState({ selectAll: false });
    }

    this.setState({
      checked: checkedCopy
    });
  };

	toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	handleDateChange(event) {
		if (event.target.getAttribute('name') == 'startDate') {
			this.setState({startDate: event.target.value});
			this.setState({
	      currentItem: "Custom Range",
				currentDataSetting: 3
	    });
		} else if (event.target.getAttribute('name') == 'endDate') {
			//console.log('this.state.code: ' + event.target.value);
			this.setState({endDate: event.target.value});
			this.setState({
				currentItem: "Custom Range",
				currentDataSetting: 3
	    });
		}
  }

	handleSubmit(event) {

		this.getData();
  }

	selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id,
      warrantyRecords: [],
    });

		if (id==1) {
			this.setState({ startDate: Moment().startOf('day').format('YYYY-MM-DD') });
		} else if (id==2) {
			this.setState({ startDate: Moment().startOf('week').format('YYYY-MM-DD') });
		} else if (id==3) {
			this.setState({ startDate: Moment().startOf('month').format('YYYY-MM-DD') });
		}

		this.setState({ endDate: Moment().format('YYYY-MM-DD') });
    setTimeout(this.getData, 300);
  }

	goToRepairLog(id){
		window.location.href="/repair-log/"+id;
	}

	getData = async () => {

		if (this.state.currentDataSetting==0) {

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-all-warranty-purchase";

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all warranty records: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ warrantyRecords: body.response, selectdata: body.response });

				var checkedCopy = [];
			  var selectAll = this.state.selectAll;
				body.response.forEach(function(e, index) {
					checkedCopy.push(selectAll);
				});
				this.setState({
					checked: checkedCopy,
					selectAll: selectAll
				});
			} else {
	      //ADD ERROR MESSAGE
	    }

		} else {

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-all-warranty-purchase-date-range?StartDate="+this.state.startDate+"&EndDate="+this.state.endDate;

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all warranty records date range: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ warrantyRecords: body.response, selectdata: body.response });

				var checkedCopy = [];
			  var selectAll = this.state.selectAll;
				body.response.forEach(function(e, index) {
					checkedCopy.push(selectAll);
				});
				this.setState({
					checked: checkedCopy,
					selectAll: selectAll
				});
			} else {
	      //ADD ERROR MESSAGE
	    }

		}

	};

	filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id;

		console.log("filterCaseInsensitive row[id]: " + JSON.stringify(id));

		if (id == 'warrantyPurchaseDate' || id == 'shipmentDate') {
			var dateString = Moment(row[id]).format('MMM Do YYYY');
			return (
					dateString !== undefined ?
							String(dateString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (row[id] !== null){
			return (
					row[id] !== undefined ?
							String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}
	}

  render() {

    const pdfdata = this.state.warrantyRecords.map((prop, key) => {

			var isSelected = false;

			if (prop.selected) {
				isSelected = prop.selected;
			}

			return {
				id: key,
        serialNumber: prop.SerialNumber,
				shipmentDate: prop.shipment_date,
				warrantyLength: prop.WarrantyLength,
				warrantyPurchaseDate: prop.WarrantyPurchaseDate,
				warrantyPurchasePrice: prop.WarrantyPurchasePrice,
				email: prop.LoginID,
				selected: isSelected,
			};
		});

		var tempChecked = this.state.checked;

		const selectedRecords = pdfdata.filter(warranty => tempChecked[warranty.id]==true);

		console.log("selectedRecords: " + JSON.stringify(selectedRecords));

		const data = this.state.warrantyRecords.map((prop, key) => {

			return {
				id: key,
        serialNumber: prop.SerialNumber,
				shipmentDate: prop.shipment_date,
				warrantyLength: prop.WarrantyLength,
				warrantyPurchaseDate: prop.WarrantyPurchaseDate,
				warrantyPurchasePrice: prop.WarrantyPurchasePrice,
				email: prop.LoginID,
				checkbox: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Input
							onChange={() => {
								if (this.state.warrantyRecords[key].selected) {
									this.state.warrantyRecords[key].selected = !this.state.warrantyRecords[key].selected
								} else {
									this.state.warrantyRecords[key].selected = true;
								}

								//let obj = this.pdfdata.find(o => o.id === key);
								//obj.selected = !obj.selected;
								console.log('obj selected: ' + this.state.warrantyRecords[key].selected);
								//this.pdfdata[key] = obj;
							}}
							checked={this.state.warrantyRecords[key].selected}
							name="confirmed"
							id="confirmed"
							type="checkbox" />
							{this.state.warrantyRecords[key].selected ? "Selected" : "Not Selected"}
					</div>
				)
			};
		});

		return (

      <Card className="card-hover">
				<CardBody>
          <Row className="mb-3">
            <Col md="6">
              <h1 style={{color:'#737782'}}>Warranty Records</h1>
            </Col>
          </Row>
					<Row className="mt-3 mb-3">
            <Col xs="12" md="6" lg="6" className="text-left">
              <div className="d-flex justify-content-between float-left" style={{ width: '400px' }}>
                <div className="mt-1" style={{ width: '45px' }}>
                  <h6 style={{ lineHeight: '1.8' }}>From:</h6>
                </div>
                <div className="mt-1 ml-2" style={{ width: '135px' }}>
                  <Input name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleDateChange} />
                </div>
                <div className="mt-1 ml-2" style={{ width: '23px' }}>
                  <h6 style={{ lineHeight: '1.8' }}>To:</h6>
                </div>
                <div className="mt-1 ml-2" style={{ width: '135px' }}>
                  <Input name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleDateChange} />
                </div>
                <div className="mt-1 ml-2" style={{ width: '50px' }}>
                  <Button size="sm" color="dark" onClick={() => this.handleSubmit()} title="GO">
                    GO
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs="12" md="6" lg="6" className="text-right">
              <div>
                <ButtonDropdown isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
                  <DropdownToggle color="dark" caret>
                    {this.state.currentItem}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => this.selectDropDownItem(0, 'Global')}>Global</DropdownItem>
										<DropdownItem onClick={() => this.selectDropDownItem(1, 'Today')}>Today</DropdownItem>
                    <DropdownItem onClick={() => this.selectDropDownItem(2, 'This Week')}>This Week</DropdownItem>
                    <DropdownItem onClick={() => this.selectDropDownItem(3, 'This Month')}>This Month</DropdownItem>
                    <DropdownItem onClick={() => this.selectDropDownItem(4, 'Custom Range')}>Custom Range</DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
								{selectedRecords.length>0 &&
									<Button className="ml-3" size="md" color="brightgreen" title="PRINT REPORT" onClick={() => this.generateReport(selectedRecords)}>
	                  Generate Report
	                </Button>
								}
              </div>
            </Col>
          </Row>
					<ReactTable
					  style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
						columns={[
							{
								Header: (
									<Fragment>
									<Label for="confirmed" check>
                  <Input
                    type="checkbox"
                    onChange={this.handleChange}
                    checked={this.state.selectAll}
                  />
										{this.state.selectAll ? "Unselect All" : "Select All"}
									</Label>
									</Fragment>
                ),
                Cell: row => (
									<div style={{ textAlign: "center", position: 'relative' }}>
                  <Input
                    type="checkbox"
                    defaultChecked={this.state.checked[row.index]}
                    checked={this.state.checked[row.index]}
                    onChange={() => this.handleSingleCheckboxChange(row.index)}
                  />
									</div>
                ),
								minWidth: 120,
								maxWidth: 150,
                sortable: false,
                filterable: false
							},
							{
								Header: "Shipment Date",
								accessor: "shipmentDate",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
					          <div style={{ textAlign: "center" }}>{row.value ? Moment(row.value).format('MMM Do YYYY') : ""}</div>
					        )
							},
              {
								Header: "Purchase Date",
								accessor: "warrantyPurchaseDate",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
					          <div style={{ textAlign: "center" }}>{Moment(row.value).format('MMM Do YYYY')}</div>
					        )
							},
							{
								Header: "Serial Number",
								accessor: "serialNumber",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
					          <div style={{ textAlign: "center" }}>{row.value}</div>
					        )
							},
							{
								Header: "User Email",
								accessor: "email",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
					          <div style={{ textAlign: "center" }}>{row.value}</div>
					        )
							},
							{
								Header: "Warranty Length",
								accessor: "warrantyLength",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value} Years</div>
					        )
							},
							{
								Header: "Purchase Price",
								accessor: "warrantyPurchasePrice",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
					          <div style={{ textAlign: "center" }}>${row.value}</div>
					        )
							}
						]}
						defaultPageSize={15}
					  sortable={true}
						showPaginationBottom={true}
						className="-striped -highlight"
						data={data}
						filterable
						filterAll={true}
						defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
						getTrGroupProps={(state, rowInfo, column, instance) => {
                    if (rowInfo !== undefined) {
                        return {
                            onClick: (e, handleOriginal) => {
                              console.log('It was in this row:', rowInfo)
															console.log('with ID:', rowInfo.original.repairId);

															//this.goToRepairLog(rowInfo.original.repairId);
                            },
                            style: {
                                cursor: 'pointer'
                            }
                        }
                    }}
                }
					/>
        </CardBody>
				<div>
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
    				<ModalHeader toggle={this.toggle}>Warranty Records Report</ModalHeader>
    				<ModalBody>
              <PDFViewer style={{ height: "600px", width: "100%"}}>
                <MyDocument data={selectedRecords} />
              </PDFViewer>
    				</ModalBody>
  					<ModalFooter>
  						<Button color="primary" className="ml-1" onClick={this.toggle}>Done</Button>
  					</ModalFooter>
  			  </Modal>
				</div>
				<div>
					<Modal isOpen={this.state.modal4} toggle={this.toggle4}>
						<ModalHeader toggle={this.toggle4}>Report Generation Error</ModalHeader>
						<ModalBody>
							<span>Maximum 200 procedures for Warranty Report.</span>
						</ModalBody>
						<ModalFooter>
							<Button color="primary" className="ml-1" onClick={this.toggle4}>OK</Button>
						</ModalFooter>
					</Modal>
				</div>
      </Card>
		);
	}
}

export default WarrantyRecordsList;
