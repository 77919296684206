import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";
import { saveAs } from "file-saver";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
	Collapse,
} from 'reactstrap';

import update from 'react-addons-update';
import redpin from '../../../assets/images/pins/red_pin.png';
import greenpin from '../../../assets/images/pins/green_pin.png';
import brokenpin from '../../../assets/images/pins/broken_pin.png';
import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class TopPerformersView extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			currentUser,
			modal: false,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
      topPerformers: [],
      users: [],
			ratioRange: 250,
      noData: true,
			accordianValues: [{
				open: true
			},{
				open: false
			},{
				open: false
			},{
				open: false
			},{
				open: false
			},{
				open: false
			},{
				open: false
			},{
				open: false
			},{
				open: false
			},{
				open: false
			}],
			isOneOpen: true
	  };

		this.toggleAccordian = this.toggleAccordian.bind(this);
		// this.toggle4 = this.toggle4.bind(this);
		// this.toggle5 = this.toggle5.bind(this);
		// this.toggle6 = this.toggle6.bind(this);
		// this.handleDateChange = this.handleDateChange.bind(this);



  }

	async componentDidMount() {

    //this.getUsers();
    this.getData();
	}

	toggleAccordian (index) {

		console.log("toggleAccordian: " + JSON.stringify(index));

		var accordianValues = JSON.parse(JSON.stringify(this.state.accordianValues));

		accordianValues[index].open = !accordianValues[index].open;

		this.setState({ accordianValues });
	}

	componentWillUnmount() {
		clearInterval(this.intervalID);

	}

  getData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-top-performers";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("top performers body response: " + JSON.stringify(body));

		if (body.status == 'Success') {
			if (body.response.length>0) {

				var topPerformers = body.response;
				var topPerformersData = [];

				for ( var i = 0; i < topPerformers.length; i++ ) {

					var item = JSON.parse(JSON.stringify(topPerformers[i]));

					console.log("top performers item: " + JSON.stringify(item));

					var topProcedureData = await this.getTopProceduresForDevice(item.SerialNumber);

					item.topProcedureData = topProcedureData;

					// var topPerformer = {
					// 	performer,
					// 	topProcedureData
					// }

					console.log("top performer: " + JSON.stringify(item));

					topPerformersData.push(item);

				}

				// topPerformers.forEach((item, i) => {
				//
				// });


        this.setState({ topPerformers: topPerformersData });
        this.state.noData = false;

			} else {
				this.state.noData = true;
			}
			//this.forceUpdate();
		} else {
			this.state.noData = true;
		}

	};

	getTopProceduresForDevice = async (serial) => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"top-procedures-for-device?SerialNumber="+serial;

		try {
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			//console.log("topproceduresbycategoryfordevice: " + JSON.stringify(body));

			var data = {};

			if (body.status == 'Success') {

				if (body.results.length > 0) {
					var largest = 100;
					for (var i=0; i < body.results.length; i++) {
						if (body.results[i].total > largest) {
							largest = body.results[i].total;
						}
					}

					var data = {
						topProcedures: body.results,
						ratioRange: 2*largest
					}
					return data;
				} else {
					return data;
				}

			} else {
				return data;
			}
		}
		catch (err) {
			console.log(err);
		}

	};

  getUsers = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-users";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});
		const body = await response.json();
		console.log("all users: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ users: body.response.Users });
		} else {
      //ADD ERROR MESSAGE
    }

	};

	getTrProps = (state, rowInfo, instance) => {

    if (rowInfo) {
			if (this.state.accordianValues[rowInfo.index].open) {
				return {
	        style: {
	          borderBottom: "1px solid",
	          borderBottomColor: "#282B31"
	        }
	      };
			} else {
				return {
	        style: {
	          borderBottom: "1px solid",
	          borderBottomColor: "#282B31"
	        }
	      };
			}
    }
    return {};
  };

	getColor(presetID) {

		var color = "success";

		if (presetID>=1 && presetID<=5) {
			color = "success";
		} else if (presetID>=11 && presetID<=19) {
			color = "info";
		} else if (presetID>=21 && presetID<=23) {
			color = "yellow";
		}

		return color;

	}

  render() {

    const data = this.state.topPerformers.map((prop, key) => {

			var vitalsPath = "/vitals/"+prop.SerialNumber;
			var customizePath = "/customize/"+prop.SerialNumber;
			var remoteSupportPath = "/remotesupport/"+prop.SerialNumber;
			var vitalsDashboardPath = "/vitals-dashboard/"+prop.SerialNumber;
			var calibrationPath = "/calibration?serial="+prop.SerialNumber;
			var logsPath = "/device-logs/"+prop.SerialNumber;
			var remotesupportblackbox = "/remotesupportblackbox/"+prop.SerialNumber;

      var user = {};
      var firstName = "";
      var lastName = "";
      if (this.state.users.length>0) {
        //user = this.state.users.find(u => u.SerialNumber === msg.serial_num);
      }

			var firmwareV = parseFloat(prop.firmware_version);

			if (prop.firmware_version.length==1) {
				firmwareV = parseFloat(prop.firmware_version).toFixed(1);
			}

			var topProcedures = [{
				total: 100,
				name: "Decontamination"
			},{
				total: 120,
				name: "Troughing"
			},{
				total: 150,
				name: "3mm PBM"
			}]

			return {
				id: key,
        count: key+1,
				firmware: firmwareV,
				city: prop.city,
				state: prop.state,
				topProcedures: prop.topProcedureData,
        SerialNumber: prop.SerialNumber,
        FirstName: prop.firstName,
        LastName: prop.lastName,
				Email: prop.email,
        timestamp: prop.timestamp,
        total: prop.total,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<a href={vitalsPath} title="Vitals"><i className="mdi mdi-stethoscope mdi-24px"/></a>
						<a href={customizePath} title="Customize"><i className="mdi mdi-message-settings-variant mdi-24px"/></a>
						<a href={vitalsDashboardPath} title="Dashboard"><i className="mdi mdi-view-dashboard mdi-24px"/></a>
						<a href={remoteSupportPath} title="Remote Support"><i className="mdi mdi-lan-connect mdi-24px"/></a>
						{this.state.currentUser.payload["custom:role"] && this.state.currentUser.payload["custom:role"] > 1 &&
							<a href={remotesupportblackbox} title="Remote Support Black Box" target="_blank"><i className="mdi mdi-play-box-outline mdi-24px"/></a>
						}
						<a href={calibrationPath} title="Calibration"><i className="mdi mdi-chart-bar mdi-24px"/></a>
						{this.state.currentUser.payload["custom:role"] && this.state.currentUser.payload["custom:role"] > 1 &&
							<a href={logsPath} title="Device Logs"><i className="mdi mdi-format-line-spacing mdi-24px"/></a>
						}
					</div>
				)
			};
		});

		return (
			<Fragment>
				<Row className="mb-3">
					<Col md="6">
						<h1 style={{color:'#737782'}}>Top Performers</h1>
					</Col>
				</Row>
        <Row>
					<Col md="12">
            <Card className="card-hover">
      				<CardBody>
                <ReactTable
                  style={{autocomplete: 'none', "line-height": "1"}}
                  columns={[
                    {
                      Header: "Ranking",
                      accessor: "count",
                      style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                      Cell: row => (
                          <div style={{ textAlign: "center" }}>#{row.value}</div>
                        )
                    },
                    {
                      Header: "Serial",
                      accessor: "SerialNumber",
                      style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                      Cell: row => (
                          <div style={{ textAlign: "center" }}>{row.value}</div>
                        )
                    },
                    {
                      Header: "First Name",
                      accessor: "FirstName",
                      style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                      Cell: row => (
                          <div style={{ textAlign: "center" }}>{row.value}</div>
                        )
                    },
                    {
                      Header: "Last Name",
                      accessor: "LastName",
                      style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                      Cell: row => (
                          <div style={{ textAlign: "center" }}>{row.value}</div>
                        )
                    },
										{
                      Header: "City",
                      accessor: "city",
                      style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                      Cell: row => (
                          <div style={{ textAlign: "center" }}>{row.value}</div>
                        )
                    },
                    {
                      Header: "State",
                      accessor: "state",
                      style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                      Cell: row => (
                          <div style={{ textAlign: "center" }}>{row.value}</div>
                        )
                    },
										{
                      Header: "Top Procedures",
                      accessor: "topProcedures",
                      style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											minWidth: 420,
											maxWidth: 460,
											minHeight: 50,
                      Cell: row => (
													<Fragment>
													<div className="pt-1">
													{this.state.accordianValues[row.index].open ? (
														<Fragment>
															<i style={{color:'#ffc108'}} className="mdi mdi-arrow-up-drop-circle-outline mdi-36px float-right" onClick={() => {this.toggleAccordian(row.index)}}/>
														</Fragment>
													) : (
														<Fragment>
															<i style={{color:'#ffc108'}} className="mdi mdi-arrow-down-drop-circle-outline mdi-36px float-right" onClick={() => {this.toggleAccordian(row.index)}}/>
														</Fragment>
													)}
													</div>
													<Collapse isOpen={this.state.accordianValues[row.index].open}>
														<div className="pt-1">
															{row.value.topProcedures.map((procedure, i) => (
																<Fragment>
																{i<3 &&
																	<Fragment>
																	<Row key={i}>
																		<Col xs="3" md="3" lg="3" className="text-left">
																			<span>{procedure.total}</span>
																		</Col>
																		<Col xs="9" md="9" lg="9" className="text-right">
																			{procedure.name == "7mm" || procedure.name == "3mm" || procedure.name == "25mm" ? (
																				<span>{procedure.name} PBM</span>
																			) : (
																				<span>{procedure.name}</span>
																			)}
																		</Col>
																	</Row>
																	<Progress multi className="mt-1 mb-3">
																		<Progress bar color={this.getColor(procedure.preset)} value={(procedure.total/row.value.ratioRange)*100} />
																	</Progress>
																	</Fragment>
																}
																</Fragment>
															))}
														</div>
													</Collapse>
													</Fragment>
                        )
                    },
                    {
                      Header: "Total Procedures",
                      accessor: "total",
											width: 180,
                      style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                      Cell: row => (
                          <div style={{ textAlign: "center" }}>{row.value} {row.value=="1" ? "procedure" : "procedures"}</div>
                        )
                    },
										{
											Header: "Actions",
											accessor: "actions",
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											width: 200,
											sortable: false,
											filterable: false
										}
                  ]}
                  defaultPageSize={10}
                  sortable={true}
                  showPaginationBottom={false}
                  className="-striped -highlight"
                  data={data}
									getTrProps={this.getTrProps}
                  getTrGroupProps={(state, rowInfo, column, instance) => {
                          if (rowInfo !== undefined) {
                              return {
                                  onClick: (e, handleOriginal) => {
                                    console.log('It was in this row:', rowInfo)
                                    console.log('with ID:', rowInfo.original.repairId);

                                    //this.goToRepairLog(rowInfo.original.repairId);
                                  },
                                  style: {
                                      cursor: 'pointer'
                                  }
                              }
                          }}
                      }
                />
      				</CardBody>
      			</Card>
					</Col>
				</Row>
			</Fragment>
		);
	}
}

export default TopPerformersView;
