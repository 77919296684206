import React from "react";
import { Component, Fragment, PureComponent } from "react";
import { Auth } from "aws-amplify";
import { ToastContainer, toast } from 'react-toastify';

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	CustomInput,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Fade,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { authenticationService } from '../../../jwt/_services';
import Chart from 'react-c3-component';
import 'c3/c3.css';

class UserInfo extends React.Component {

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;

    this.state = {
			rSelected: 3,
			totalProcedures: 0,
			currentUserEmail: JSON.parse(localStorage.getItem('currentUserEmail')),
			isEditing: false,
			firstName: JSON.parse(localStorage.getItem('firstName')),
			lastName: JSON.parse(localStorage.getItem('lastName')),
			phoneNumber: '',
			status: 1,
			modal: false,
			code: 123456,
			subscriptionContactExists: false,
			isSubscribed: false,
			currentUser,
			allowPhoneContact: false,
			isContactArchived: false
	  };

		this.toggle = this.toggle.bind(this);

  }

	async componentDidMount() {

		console.log("currentUser: "+JSON.stringify(this.props.currentUser));
		console.log("status: "+JSON.stringify(this.state.status));

		if (this.state.currentUser.payload["custom:allowphonecontact"]) {

			if (this.state.currentUser.payload["custom:allowphonecontact"]==1) {
				this.setState({ allowPhoneContact: true });
			} else {
				this.setState({ allowPhoneContact: false });
			}

		} else {
			this.setState({ allowPhoneContact: false });
		}

		var phoneNString = localStorage.getItem('phoneNumber');

		console.log("phoneNString: "+JSON.stringify(phoneNString));

		if (phoneNString != "undefined") {
			var phoneN = JSON.parse(localStorage.getItem('phoneNumber'));

			if (phoneN) {
				this.setState({phoneNumber: phoneN});
			}
		}

		this.checkUserEmailSubscription();

	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	async sendCode() {
		var code = Math.floor(100000 + Math.random() * 900000);
		console.log("random code: "+code);

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"verify-change?ConfirmationCode="+code;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.props.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("Device Registered User response: " + JSON.stringify(body.response));

		if (body.status == 'Success') {
			this.setState({code: code});
		}
	}

	async updateUser(firstname, lastname, phoneNumber) {
		const user = await Auth.currentAuthenticatedUser();
		await Auth.updateUserAttributes(user, {
			'given_name': firstname,
			'family_name': lastname,
			'custom:phone': phoneNumber
		});
	}

	async toggleTextOptIn() {

		if (this.state.allowPhoneContact) {
			const user = await Auth.currentAuthenticatedUser();
			var result = await Auth.updateUserAttributes(user, {
				'custom:allowphonecontact': '0'
			});

			if (result=="SUCCESS") {
				this.setState({allowPhoneContact: false});
				localStorage.setItem('currentUser', JSON.stringify(user.signInUserSession.idToken));
				console.log("new currentUser: " + JSON.stringify(user.signInUserSession.idToken));
				//console.log("props currentUser: " + JSON.stringify(this.props.currentUser));
			}
		} else {
			const user = await Auth.currentAuthenticatedUser();
			var result = await Auth.updateUserAttributes(user, {
				'custom:allowphonecontact': '1'
			});
			//console.log("updateUserAttributes response: " + JSON.stringify(result));

			if (result=="SUCCESS") {
				this.setState({allowPhoneContact: true});
				localStorage.setItem('currentUser', JSON.stringify(user.signInUserSession.idToken));
				console.log("new currentUser: " + JSON.stringify(user.signInUserSession.idToken));
				//console.log("props currentUser: " + JSON.stringify(this.props.currentUser));
			}
		}

	}

	async toggleEmailOptIn() {

		if (this.state.isSubscribed) {

			var subscribeStatus = "unsubscribed";
			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-user-email-subscription-status?Status="+subscribeStatus;

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.props.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("edit-user-email-subscription-status unsubscribed response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({isSubscribed: false});
			}

		} else {

			if (this.state.subscriptionContactExists) {

				var subscribeStatus = "subscribed";
				var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-user-email-subscription-status?Status="+subscribeStatus;

				const response = await fetch(url, {
					method: 'GET',
					headers: {
						Authorization: this.props.currentUser.jwtToken
					},
				});

				const body = await response.json();
				console.log("edit-user-email-subscription-status subscribed response: " + JSON.stringify(body));

				if (body.status == 'Success') {
					this.setState({isSubscribed: true});
				}

			} else {

				var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"user-subscribe-to-email";

				const response = await fetch(url, {
					method: 'GET',
					headers: {
						Authorization: this.props.currentUser.jwtToken
					},
				});

				const body = await response.json();
				console.log("user-subscribe-to-email response: " + JSON.stringify(body));

				if (body.status == 'Success') {
					this.setState({isSubscribed: true, subscriptionContactExists: true});
				}

			}

		}

	}

	async checkUserEmailSubscription() {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"user-email-subscription-status";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.props.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("checkUserEmailSubscription response: " + JSON.stringify(body));

		if (body.status == 'Success') {

			this.setState({ subscriptionContactExists: true });

			if (body.response.status=='subscribed') {
				this.setState({isSubscribed: true});
			} else if (body.response.status=='archived') {
				this.setState({isSubscribed: false});
				this.setState({ subscriptionContactExists: false, isContactArchived: true });
			} else {
				this.setState({isSubscribed: false});
			}

		} else {
			this.setState({ subscriptionContactExists: false });
		}
	}

	setVerifyStatus(status) {
		console.log("set status!");

		if (status == 2) {
			this.sendCode();
		}

		this.setState({status: status});
	}

	// sendAccountDeletionRequest = async () => {
	//
	// 	var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-account-deletion-request";
	//
	// 	const response = await fetch(url, {
	// 		method: 'GET',
	// 		headers: {
	// 			Authorization: this.state.currentUser.jwtToken
	// 		},
	// 	});
	//
	// 	const body = await response.json();
	// 	console.log("sendAccountDeletionRequest data: " + JSON.stringify(body));
	//
	// 	if (body.status == 'Success') {
	// 		//this.setState({ feedbackData: body.response });
	// 	} else {
  //     //ADD ERROR MESSAGE
  //   }
	//
	// };

	sendAccountDeletionRequest = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-account-deletion-request";

		try {

			//console.log("sendAccountDeletionRequest this.props.currentUser.jwtToken: " + JSON.stringify(this.props.currentUser.jwtToken));

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("sendAccountDeletionRequest response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				//this.toggle4();
				toast.success("Account deletion request sent.", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					closeButton: false
				});

			} else {

				//this.toggle4();
				toast.error(body.response, {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					closeButton: false
				});

			}

		}
		catch (err) {
			console.log("sendAccountDeletionRequest Fetch Error: " + err);
		}

		this.toggle();

	};

	render() {
		return (
      <Card className="card-hover">
				<CardBody style={{ height: 400 }}>
				{this.state.status == 1 &&
					<Fragment>
					<Fade in="true">
					<Row>
						<Col xs="6" md="6" lg="6" className="text-left">
							<CardTitle>Registered User</CardTitle>
						</Col>
						<Col xs="6" md="6" lg="6" className="text-right">
							<i className="mdi mdi-account-edit font-24" style={{ color: '#8a9097' }} onClick={()=>{ this.setVerifyStatus(2)} }/>
						</Col>
					</Row>
					<Row className="pb-3 pt-3">
						<Col xs="4" md="4" lg="4">
							<h6>First Name:</h6>
						</Col>
						<Col xs="8" md="8" lg="8">
							<h6 style={{ color: '#414755' }}>{this.state.firstName}</h6>
						</Col>
					</Row>
					<Row className="pb-3">
						<Col xs="4" md="4" lg="4">
							<h6>Last Name:</h6>
						</Col>
						<Col xs="8" md="8" lg="8">
							<h6  style={{ color: '#414755' }}>{this.state.lastName}</h6>
						</Col>
					</Row>
					<Row className="pb-3">
						<Col xs="4" md="4" lg="4">
							<h6>Phone Number:</h6>
						</Col>
						<Col xs="8" md="8" lg="8">
							{this.state.phoneNumber ? (
								<h6  style={{ color: '#414755' }}>{this.state.phoneNumber}</h6>
							) : (
								<h6  style={{ color: '#414755' }}>(ADD)</h6>
							)}

						</Col>
					</Row>
					<Row>
						<Col xs="4" md="4" lg="4">
							<h6>Email:</h6>
						</Col>
						<Col xs="8" md="8" lg="8">
							<h6  style={{ color: '#414755' }}>{this.state.currentUserEmail}</h6>
						</Col>
					</Row>
					{!this.state.isContactArchived &&
						<Row className="pt-4">
							<Col xs="4" md="4" lg="4">
								<h6 style={{ lineHeight: '1.8' }}>Subscribe to Emails:</h6>
							</Col>
							<Col xs="8" md="8" lg="8">
								{this.state.isSubscribed ? (
									<Fragment>
										<Button style={{ width: '100px' }} color="danger" size="sm" onClick={()=>{ this.toggleEmailOptIn()} }>Unsubscribe</Button>
									</Fragment>
								) : (
									<Fragment>
										<Button style={{ width: '100px' }} color="brightgreen" size="sm" onClick={()=>{ this.toggleEmailOptIn()} }>Subscribe</Button>
									</Fragment>
								)}
							</Col>
						</Row>
					}
					<Row className="pt-4">
						<Col xs="4" md="4" lg="4">
							<h6 style={{ lineHeight: '1.8' }}>Text Messages:</h6>
						</Col>
						<Col xs="8" md="8" lg="8">
							{this.state.allowPhoneContact ? (
								<Fragment>
									<Button style={{ width: '100px' }} color="danger" size="sm" onClick={()=>{ this.toggleTextOptIn()} }>Opt Out</Button>
								</Fragment>
							) : (
								<Fragment>
									<Button style={{ width: '100px' }} color="brightgreen" size="sm" onClick={()=>{ this.toggleTextOptIn()} }>Opt In</Button>
								</Fragment>
							)}
						</Col>
					</Row>
					{
						// <Row className="pt-4">
						// 	<Col xs="12" md="12" lg="12">
						// 		<Button style={{ width: '100%' }} color="danger" size="sm" onClick={()=>{ this.toggle()} }>DELETE ACCOUNT</Button>
						// 	</Col>
						// </Row>
					}
					</Fade>
					</Fragment>
				}
				{this.state.status == 2 &&
					<Fragment>
					<Fade in="true">
					<Row>
						<Col xs="6" md="6" lg="6" className="text-left">
							<CardTitle>Enter the Code</CardTitle>
						</Col>
						<Col xs="6" md="6" lg="6" className="text-right">
							<Button style={{ color: '#414755' }} size="sm" onClick={()=>{ this.setVerifyStatus(1)} }>Cancel</Button>
						</Col>
					</Row>
					<Row>
						<Col xs="12" md="12" lg="12" className="text-left">
							<h4>We sent a verification code to: {this.state.currentUserEmail}</h4>
						</Col>
					</Row>
					<Formik
											initialValues={{
													code: ""
											}}
											validationSchema={Yup.object().shape({
													code: Yup.string().required('Verification Code is Required')
											})}
											onSubmit={({ code }, { setStatus, setSubmitting }) => {
													//setStatus();

													if (code == this.state.code) {
														setSubmitting(false);
														this.setVerifyStatus(3);
													} else {
														setStatus("Code entered does not match.");
														setSubmitting(false);
													}

											}}
											render={({ errors, status, touched, isSubmitting }) => (
					<Form className="" id="loginform">
						<InputGroup className="mb-3">
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
									Verification Code
								</InputGroupText>
							</InputGroupAddon>
							<Field name="code" type="text" className={'form-control' + (errors.code && touched.code ? ' is-invalid' : '')} />
															<ErrorMessage name="code" component="div" className="invalid-feedback" />
						</InputGroup>

						<Row className="mb-3">
							<Col xs="12">
								<Button style={{width: '100%'}} color="dark" onClick={()=>{ this.sendCode()} }>
									RESEND EMAIL
								</Button>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col xs="12">
								<button type="submit" className="btn btn-block btn-primary" disabled={isSubmitting}>VERIFY CODE</button>
							</Col>
						</Row>
						{status &&
													<div className={'alert alert-danger'}>{status}</div>
											}
					</Form>
					)}
					/>
					</Fade>
						</Fragment>
				}
				{this.state.status == 3 &&
					<Fragment>
					<Fade in="true">
					<Row>
						<Col xs="6" md="6" lg="6" className="text-left">
							<CardTitle>Registered User</CardTitle>
						</Col>
						<Col xs="6" md="6" lg="6" className="text-right">
							<Button style={{ color: '#414755' }} size="sm" onClick={()=>{ this.setVerifyStatus(1)} }>Cancel</Button>
						</Col>
					</Row>
					<Formik
											initialValues={{
													firstname: this.state.firstName,
													lastname: this.state.lastName,
													phoneNumber: this.state.phoneNumber
											}}
											validationSchema={Yup.object().shape({
													firstname: Yup.string().required('First Name is required'),
													lastname: Yup.string().required('Last Name is required'),
													phoneNumber: Yup.string().required('Phone Number is required')
											})}
											onSubmit={({ firstname, lastname, phoneNumber }, { setStatus, setSubmitting }) => {
													setStatus();

													this.updateUser(firstname, lastname, phoneNumber);
													this.setState({firstName: firstname});
													this.setState({lastName: lastname});
													this.setState({phoneNumber: phoneNumber});
					                localStorage.setItem('firstName', JSON.stringify(firstname));
					                localStorage.setItem('lastName', JSON.stringify(lastname));
													localStorage.setItem('phoneNumber', JSON.stringify(phoneNumber));
													setSubmitting(false);
													this.setVerifyStatus(1);

											}}
											render={({ errors, status, touched, isSubmitting }) => (
					<Form className="" id="loginform">
						<InputGroup className="mb-2">
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
									First Name
								</InputGroupText>
							</InputGroupAddon>
							<Field name="firstname" type="text" className={'form-control' + (errors.firstname && touched.firstname ? ' is-invalid' : '')} />
															<ErrorMessage name="firstname" component="div" className="invalid-feedback" />
						</InputGroup>

						<InputGroup className="mb-2">
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
									Last Name
								</InputGroupText>
							</InputGroupAddon>
							<Field name="lastname" type="text" className={'form-control' + (errors.lastname && touched.lastname ? ' is-invalid' : '')} />
															<ErrorMessage name="lastname" component="div" className="invalid-feedback" />
						</InputGroup>

						<InputGroup className="mb-2">
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
									Phone Number
								</InputGroupText>
							</InputGroupAddon>
							<Field name="phoneNumber" type="text" className={'form-control' + (errors.phoneNumber && touched.phoneNumber ? ' is-invalid' : '')} />
															<ErrorMessage name="phoneNumber" component="div" className="invalid-feedback" />
						</InputGroup>
						<Row className="mb-3">
							<Col xs="12">
								<button type="submit" className="btn btn-block btn-primary" disabled={isSubmitting}>Save</button>
							</Col>
						</Row>
						{status &&
													<div className={'alert alert-danger'}>{status}</div>
											}
					</Form>
					)}
					/>
					</Fade>
						</Fragment>
				}
        </CardBody>
				<div>
				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>Confirm</ModalHeader>
					<ModalBody>
						<span>Are you sure you want to request the deletion of you account?</span>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" className="ml-1" onClick={this.toggle}>Cancel</Button>
						<Button color="primary" className="ml-1" onClick={this.sendAccountDeletionRequest}>CONFIRM DELETE</Button>
					</ModalFooter>
				</Modal>
				</div>
      </Card>
		);
	}
}

export default UserInfo;
