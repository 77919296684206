import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class NPSFeedbackList extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			obj: {},
			bomData: [],
      npsData: [],
			modal: false,
			userMarket: 0,
			isInternationalTech: false,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.toggle = this.toggle.bind(this);
		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);

  }


	async componentDidMount() {

		var userMarket = 0;

		this.getBOMData(userMarket);

    this.getNPSData();
	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	async onRadioBtnClick(userMarket) {

		await this.setState({ userMarket });

		//setTimeout(() => this.getBOMData(userMarket), 300);
		this.getBOMData(userMarket);

	}

	componentWillUnmount() {
		//console.log("Laser-Usage componentWillUnmount");
		clearInterval(this.intervalID);
	}

  getNPSData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-all-nps-user-feedback";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all NPS data: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ npsData: body.response });
		} else {
      //ADD ERROR MESSAGE
    }

	};


	getBOMData = async (userMarket) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com/v1/get-bill-of-materials?MarketID="+userMarket;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all BOM data: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ bomData: body.response });
		} else {
      //ADD ERROR MESSAGE
    }

	};

	deleteFeedback = async () => {

		//console.log("Delete Note: " + this.state.obj.description);

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-dashboard-user-nps-feedback-record?FeedbackRecordID="+this.state.obj.npsID;

    console.log("Delete note url: " + url);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("Delete note response: " + JSON.stringify(body));

		if (body.status == 'Success') {

			this.toggle();
      this.getNPSData();

		} else {


		}

	};

	filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id;

		//console.log("filterCaseInsensitive row[id]: " + JSON.stringify(id));



		if (id == 'RatingDate') {
			var dateString = Moment(row[id]).utc().format('MM/DD/YYYY');
			return (
					dateString !== undefined ?
							String(dateString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (row[id] !== null){
			return (
					row[id] !== undefined ?
							String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}
	}

  render() {

    var participants = this.state.npsData.length;
    var npsScore = 0;
    var promotors = 0;
    var detractors = 0;

    const data = this.state.npsData.map((prop, key) => {

			if (prop.Rating>8) {
        promotors = promotors+1;
      }

      if (prop.Rating<7) {
        detractors = detractors+1;
      }

			return {
				id: key,
				npsID: prop.ID,
				Name: prop.Name,
        Email: prop.Email,
				Rating: prop.Rating,
				RatingDate: prop.RatingDate,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
								console.log('button obj: ' + obj);
								this.setState({
									modal: !this.state.modal,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to add a edit kind of action */}
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

    console.log("promotors total: " + JSON.stringify(promotors));
    console.log("detractors total: " + JSON.stringify(detractors));

    var promotorsRatio = (promotors/participants)*100;
    var detractorsRatio = (detractors/participants)*100;

    console.log("promotorsRatio: " + JSON.stringify(promotorsRatio));
    console.log("detractorsRatio: " + JSON.stringify(detractorsRatio));

    if (this.state.npsData.length>0) {
      npsScore = parseFloat(promotorsRatio - detractorsRatio).toFixed(1);
    }

		return (
			<Fragment>
				<Row>
					<Col md="12">
						<Card className="card-hover">
							<CardBody>
								<Row className="mb-3">
									<Col md="4">
										<h1 style={{color:'#737782'}}>NPS User Feedback</h1>
									</Col>
									<Col md="6">
									</Col>
									<Col md="2">
                    <Row className="text-right">
                      <h4 className="text-right" style={{color:'#FFC108'}}>NPS Score: {npsScore}</h4>
                    </Row>
                    <Row className="text-right">
                      <h4 className="text-right" style={{color:'#FFC108'}}>Participants: {participants}</h4>
                    </Row>
									</Col>
								</Row>
								<ReactTable
									style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
									columns={[
										{
											Header: "Name",
											accessor: "Name",
											minWidth: 15,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
										{
											Header: "Email",
											accessor: "Email",
											minWidth: 15,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
										{
											Header: "Rating",
											accessor: "Rating",
											minWidth: 15,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
										{
											Header: "Date Submitted",
											accessor: "RatingDate",
											minWidth: 15,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{Moment(row.value).utc().format('MM/DD/YYYY')}</div>
												)
										},
										{
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      minWidth: 60,
                      maxWidth: 60,
                      filterable: false
                    },
									]}
									defaultPageSize={15}
									sortable={true}
									showPaginationBottom={true}
									className="-striped -highlight"
									data={data}
									filterable
									defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
									getTrGroupProps={(state, rowInfo, column, instance) => {
													if (rowInfo !== undefined) {
															return {
																	onClick: (e, handleOriginal) => {
																		console.log('It was in this row:', rowInfo)
																		console.log('with ID:', rowInfo.original.categoryID);

																		//this.goToRepairLog(rowInfo.original.repairId);
																	}
															}
													}}
											}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<div>
				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>Delete Feedback</ModalHeader>
					<ModalBody>
						<span>Are you sure you want to delete this User's Feedback?</span>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.deleteFeedback}>Yes</Button>
						<Button color="secondary" className="ml-1" onClick={this.toggle}>Cancel</Button>
					</ModalFooter>
				</Modal>
				</div>
			</Fragment>
		);
	}
}

export default NPSFeedbackList;
