import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class UserMessageLogsList extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			obj: {},
			bomData: [],
      logData: [],
			modal: false,
			userMarket: 0,
			isInternationalTech: false,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.toggle = this.toggle.bind(this);
		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);

  }


	async componentDidMount() {

    this.getMessageLogData();
	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	async onRadioBtnClick(userMarket) {

		await this.setState({ userMarket });

		//setTimeout(() => this.getBOMData(userMarket), 300);
		//this.getBOMData(userMarket);

	}

	componentWillUnmount() {
		//console.log("Laser-Usage componentWillUnmount");
		//clearInterval(this.intervalID);
	}

  getMessageLogData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-user-message-logs";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("getMessageLogData data: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ logData: body.results });
		} else {
      //ADD ERROR MESSAGE
    }

	};

	deleteMessageRecord = async () => {

		//console.log("Delete Note: " + this.state.obj.description);

		try {

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-user-message-log?MessageLogID="+this.state.obj.logID;

	    console.log("Delete note url: " + url);

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("deleteMessageRecord response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				this.toggle();
	      this.getMessageLogData();

			} else {

			}

		}
		catch (err) {
			console.log(err);
		}

	};

	filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id;

		//console.log("filterCaseInsensitive row[id]: " + JSON.stringify(id));



		if (id == 'RatingDate') {
			var dateString = Moment(row[id]).utc().format('MM/DD/YYYY');
			return (
					dateString !== undefined ?
							String(dateString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (row[id] !== null){
			return (
					row[id] !== undefined ?
							String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}
	}

  render() {

    const data = this.state.logData.map((prop, key) => {

			return {
				id: key,
				logID: prop.id,
				SerialNumber: prop.SerialNumber,
        FirstName: prop.FirstName,
        LastName: prop.LastName,
        Recipient: prop.Recipient,
				Subject: prop.Subject,
				TechnicianFirstName: prop.TechnicianFirstName,
        TechnicianLastName: prop.TechnicianLastName,
        TechnicianEmail: prop.TechnicianEmail,
        CreationDate: prop.CreationDate,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
								console.log('button obj: ' + obj);
								this.setState({
									modal: !this.state.modal,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-1"
						>
							<i className="fa fa-trash-alt" />
						</Button>
						{/* use this button to add a edit kind of action */}
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

		return (
			<Fragment>
				<Row>
					<Col md="12">
						<Card className="card-hover">
							<CardBody>
								<Row className="mb-3">
									<Col md="4">
										<h1 style={{color:'#737782'}}>User Message Logs</h1>
									</Col>
									<Col md="6">
									</Col>
									<Col md="2">
									</Col>
								</Row>
								<ReactTable
									style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
									columns={[
										{
											Header: "Serial",
											accessor: "SerialNumber",
											minWidth: 50,
											maxWidth: 80,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
										{
											Header: "First Name",
											accessor: "FirstName",
											minWidth: 100,
											maxWidth: 130,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
										{
											Header: "Last Name",
											accessor: "LastName",
											minWidth: 110,
											maxWidth: 140,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
                    {
											Header: "Recipient",
											accessor: "Recipient",
											minWidth: 250,
											maxWidth: 280,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
                    {
											Header: "Subject",
											accessor: "Subject",
											minWidth: 300,
											maxWidth: 500,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
                    {
											Header: "Tech Given",
											accessor: "TechnicianFirstName",
											minWidth: 90,
											maxWidth: 120,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
                    {
											Header: "Tech Family",
											accessor: "TechnicianLastName",
											minWidth: 100,
											maxWidth: 130,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
                    {
											Header: "Tech Email",
											accessor: "TechnicianEmail",
											minWidth: 180,
											maxWidth: 210,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
										{
											Header: "Date Sent",
											accessor: "CreationDate",
											minWidth: 140,
											maxWidth: 170,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{Moment(row.value).format('MM/DD/YYYY h:mma')}</div>
												)
										},
										{
											Header: "Actions",
											accessor: "actions",
											width: 60,
											sortable: false,
											filterable: false
										}
									]}
									defaultPageSize={15}
									sortable={true}
									showPaginationBottom={true}
									className="-striped -highlight"
									data={data}
									filterable
									defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
									getTrGroupProps={(state, rowInfo, column, instance) => {
													if (rowInfo !== undefined) {
															return {
																	onClick: (e, handleOriginal) => {
																		console.log('It was in this row:', rowInfo)
																		console.log('with ID:', rowInfo.original.categoryID);

																		//this.goToRepairLog(rowInfo.original.repairId);
																	}
															}
													}}
											}
								>
								{(state, makeTable, instance) => {
							            let recordsInfoText = "";

							            const { filtered, pageRows, pageSize, sortedData, page } = state;

							            if (sortedData && sortedData.length > 0) {
							              let isFiltered = filtered.length > 0;

							              let totalRecords = sortedData.length;

							              let recordsCountFrom = page * pageSize + 1;

							              let recordsCountTo = recordsCountFrom + pageRows.length - 1;

							              if (isFiltered)
							                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
							              else
							                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
							            } else recordsInfoText = "No records";

							            return (
							              <div className="main-grid mt-2">
															{makeTable()}
															<Row className="mt-2 text-center">
																<Col md="12">
																	<Label style={{fontSize:'15px'}} for=""><b>{recordsInfoText}</b></Label>
																</Col>
															</Row>
							              </div>
							            );
							          }}
							        </ReactTable>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<div>
				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>Delete User Message Record</ModalHeader>
					<ModalBody>
						<span>Are you sure you want to delete this Messager Record?</span>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.deleteMessageRecord}>Yes</Button>
						<Button color="secondary" className="ml-1" onClick={this.toggle}>Cancel</Button>
					</ModalFooter>
				</Modal>
				</div>
			</Fragment>
		);
	}
}

export default UserMessageLogsList;
