import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import { getEmailTextMessageOne, getEmailHTMLMessageOne, getEmailTextMessageTwo, getEmailHTMLMessageTwo, getEmailTextMessageThree, getEmailHTMLMessageThree } from "./get-email-html";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import update from 'react-addons-update';
import redpin from '../../../assets/images/pins/red_pin.png';
import greenpin from '../../../assets/images/pins/green_pin.png';
import brokenpin from '../../../assets/images/pins/broken_pin.png';
import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';
import * as mqtt from 'react-paho-mqtt';
import Paho from 'paho-mqtt';
import { getConnectionURL } from "../../../mqtt-helpers/mqtt-request";

const stateLabelValues = [
{ 'label':'Alabama', 'value': 'AL' },
{ 'label':'Alaska', 'value': 'AK'},
{ 'label':'American Samoa', 'value': 'AS'},
{ 'label':'Arizona', 'value': 'AZ'},
{ 'label':'Arkansas', 'value': 'AR'},
{ 'label':'California', 'value': 'CA'},
{ 'label':'Colorado', 'value': 'CO'},
{ 'label':'Connecticut', 'value': 'CT'},
{ 'label':'Delaware', 'value': 'DE'},
{ 'label':'District of Columbia', 'value': 'DC'},
{ 'label':'States of Micronesia', 'value': 'FM'},
{ 'label':'Florida', 'value': 'FL'},
{ 'label':'Georgia', 'value': 'GA'},
{ 'label':'Guam', 'value': 'GU'},
{ 'label':'Hawaii', 'value': 'HI'},
{ 'label':'Idaho', 'value': 'ID'},
{ 'label':'Illinois', 'value': 'IL'},
{ 'label':'Indiana', 'value': 'IN'},
{ 'label':'Iowa', 'value': 'IA'},
{ 'label':'Kansas', 'value': 'KS'},
{ 'label':'Kentucky', 'value': 'KY'},
{ 'label':'Louisiana', 'value': 'LA'},
{ 'label':'Maine', 'value': 'ME'},
{ 'label':'Marshall Islands', 'value': 'MH'},
{ 'label':'Maryland', 'value': 'MD'},
{ 'label':'Massachusetts', 'value': 'MA'},
{ 'label':'Michigan', 'value': 'MI'},
{ 'label':'Minnesota', 'value': 'MN'},
{ 'label':'Mississippi', 'value': 'MS'},
{ 'label':'Missouri', 'value': 'MO'},
{ 'label':'Montana', 'value': 'MT'},
{ 'label':'Nebraska', 'value': 'NE'},
{ 'label':'Nevada', 'value': 'NV'},
{ 'label':'New Hampshire', 'value': 'NH'},
{ 'label':'New Jersey', 'value': 'NJ'},
{ 'label':'New Mexico', 'value': 'NM'},
{ 'label':'New York', 'value': 'NY'},
{ 'label':'North Carolina', 'value': 'NC'},
{ 'label':'North Dakota', 'value': 'ND'},
{ 'label':'Northern Mariana Islands', 'value': 'MP'},
{ 'label':'Ohio', 'value': 'OH'},
{ 'label':'Oklahoma', 'value': 'OK'},
{ 'label':'Oregan', 'value': 'OR'},
{ 'label':'Palau', 'value': 'PW'},
{ 'label':'Pennsilvania', 'value': 'PA'},
{ 'label':'Puerto Rico', 'value': 'PR'},
{ 'label':'Rhode Island', 'value': 'RI'},
{ 'label':'South Carolina', 'value': 'SC'},
{ 'label':'South Dakota', 'value': 'SD'},
{ 'label':'Tennessee', 'value': 'TN'},
{ 'label':'Texas', 'value': 'TX'},
{ 'label':'Utah', 'value': 'UT'},
{ 'label':'Vermont', 'value': 'VT'},
{ 'label':'Virgin Islands', 'value': 'VI'},
{ 'label':'Virginia', 'value': 'VA'},
{ 'label':'Washington', 'value': 'WA'},
{ 'label':'West Virginia', 'value': 'WV'},
{ 'label':'Wisconsin', 'value': 'WI'},
{ 'label':'Wyoming', 'value': 'WY'}
];

var messagesArray = [];
var online_markers = [];
var devicesIcons = [];

class DevicesList extends React.Component {

	intervalID = 0;
	intervalID2 = 1;
	intervalID3 = 2;

	constructor(props) {
    super(props);

		var connectionTimeout;

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		//console.log('currentUser info: ' + JSON.stringify(currentUser.token));

		this.onMessageArrived = this.onMessageArrived.bind(this);
    this.onConnectionLost = this.onConnectionLost.bind(this);

		// var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    // console.log('random hash: ' + hash);
		// const client = new Paho.Client(getConnectionURL(), hash+currentUser.OBJID);
    // client.onMessageArrived = this.onMessageArrived;
    // client.onConnectionLost = this.onConnectionLost;
    // client.connect({
		// 	timeout: 3,
		// 	useSSL: true,
		// 	mqttVersion: 4,
		// 	cleanSession: true,
		// 	onSuccess: this.onConnect,
		// 	onFailure: (e) => {console.log("here is the error" , e); }
    // });

    this.state = {
			mainMessage: [''],
			noDevices: false,
			subMessage: [''],
			online_markers: [''],
			devicesLoaded: false,
			client: null,
			currentUser,
      messageToSend:'',
      isConnected: false,
			isReceivingMessages: false,
			devices: [''],
			totalResults: 0,
			rSelected: 3,
			totalProcedures: 0,
			modal: false,
      modal2: false,
			modal4: false,
			modal5: false,
			modal6: false,
      dropdownOpen: false,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
      users: [],
			devices: [],
			devicesIcons: [],
      roles: [],
      obj: {},
			statusSubject: "Failed",
			statusMessage: "Failed to send message.",
			deviceInfo: [],
			userPhoneNumber: "",
			allowSMS: false,
			shipmentDate: Moment().format('YYYY-MM-DD'),
			messageType: "Email",
			msgFirstName: "",
			msgLastName: ""
	  };

		this.toggle4 = this.toggle4.bind(this);
		this.toggle5 = this.toggle5.bind(this);
		this.toggle6 = this.toggle6.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);

  }

	async componentDidMount() {
		this.connectToMQTT();

    this.getData();

		this.intervalID2 = setInterval(this.handleMessage, 3000);
		this.intervalID = setInterval(this.getMessage, 5000);
		this.intervalID3 = setInterval(this.updateStateDeviceIcons, 3000);
		//this.test();
	}

	componentWillUnmount() {
		clearInterval(this.intervalID);
    clearInterval(this.intervalID2);
		clearInterval(this.intervalID3);

		if (this.state.client && this.state.client.isConnected()) {
			this.state.client.disconnect();
		}

	}

	updateStateDeviceIcons = async () => {

	 	this.setState({ devicesIcons: devicesIcons });
	}

	toggle4() {
	  this.setState({
	    modal4: !this.state.modal4
	  });
	}

	toggle5() {
	  this.setState({
	    modal5: !this.state.modal5
	  });
	}

	toggle6() {
	  this.setState({
	    modal6: !this.state.modal6
	  });
	}

	handleDateChange(event) {
		if (event.target.getAttribute('name') == 'shipmentdate') {
			this.setState({shipmentDate: event.target.value});
		}
  }

	onChangeMessageType = (event) => {

		console.log("input id: " + event.target.id);
		console.log("input value: " + event.target.value);
		console.log("checked value: " + event.target.checked);

		let id = event.target.id;
		let value = event.target.value;
		let checked = event.target.checked;

		this.setState({ messageType: value });

		// if (checked != undefined) {
		//
		// 	if (event.target.type == 'checkbox') {
		// 		repairLog[id] = checked ? 1 : 0;
		// 		console.log("set checked as value");
		// 	} else if (event.target.type == 'date') {
		// 		repairLog[id] = value;
		// 		console.log("set checked as value");
		// 	}
		//
		// }

		console.log("onChangeMessageType value: " + JSON.stringify(value));

	}

	sendMessage = (event) => {
		event.preventDefault();

		//console.log("Send message");
		let email = event.target.email.value;
    //console.log(`email is:`, email);
		let phone = event.target.phone.value;
		//console.log(`phone is:`, phone);
		let messagetype = event.target.messagetype.value;
		//console.log(`messagetype is:`, messagetype);

		let subject = "";
		if (event.target.subject) {
			subject = event.target.subject.value;
		}
		console.log(`subject is:`, subject);

		let message = "";
		if (event.target.message) {
			message = event.target.message.value;
		}
		console.log(`message is:`, message);

		let formattedPhoneNumber = this.enforcePhoneNumberPattern(phone);
		//console.log(`Formatted Phone Number is:`, formattedPhoneNumber);

		if (messagetype == "Email") {
			this.sendEmailMessage(email, subject, message);
		} else if (messagetype == "About your Gemini EVO update due to Wi-Fi") {
			this.sendTemplateEmailMessage(email, messagetype, 1);
		} else if (messagetype == "About your Gemini EVO update due to low battery") {
			this.sendTemplateEmailMessage(email, messagetype, 2);
		} else if (messagetype == "About your Gemini EVO activation pedal pairing") {
			this.sendTemplateEmailMessage(email, messagetype, 3);
		} else {
			this.sendTextMessage(formattedPhoneNumber, subject, message);
		}
	}

	sendEmailMessage = async (recipient, subject, message) => {

		var requestBody = {
			emailRecipient:[recipient],
			emailSubject:subject,
			emailHTMLMessage:message,
			emailTextMessage:message
		}

		console.log("send-email-to-user requestBody: " + JSON.stringify(requestBody));

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-email-to-user";

		try {

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: this.state.currentUser.jwtToken,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(requestBody)
			});

			const body = await response.json();
			console.log("send-email-to-user response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				this.toggle4();
				this.setState({
					statusSubject: "SUCCESS!",
					statusMessage: "Email sent Successfully!"
				})

			} else {

				this.toggle4();
				this.setState({
					statusSubject: "FAILED!",
					statusMessage: "Failed to send Email to "+recipient+"."
				})

			}

		}
		catch (err) {
			console.log(err);
		}

		this.toggle5();

	};

	sendTemplateEmailMessage = async (recipient, subject, template) => {

		var textBody = getEmailTextMessageOne();
		var htmlBody = getEmailHTMLMessageOne();

		var logDetails = {
			SerialNumber: this.state.obj.SerialNumber,
			FirstName: this.state.msgFirstName,
			LastName: this.state.msgLastName,
			Email: recipient,
			TechnicianEmail: this.state.currentUser.payload.email,
			TechnicianFirstName: this.state.currentUser.payload.given_name,
			TechnicianLastName: this.state.currentUser.payload.family_name,
		}

		var requestBody = {
			emailRecipient:[recipient],
			emailSubject:subject,
			emailHTMLMessage:htmlBody,
			emailTextMessage:textBody,
			emailType: 2,
			logDetails
		}

		if (template==1) {
			textBody = getEmailTextMessageOne();
			htmlBody = getEmailHTMLMessageOne();

			requestBody = {
				emailRecipient:[recipient],
				emailSubject:subject,
				emailHTMLMessage:htmlBody,
				emailTextMessage:textBody,
				emailType: 2,
				logDetails
			}
		} else if (template==2) {
			textBody = getEmailTextMessageTwo();
			htmlBody = getEmailHTMLMessageTwo();

			requestBody = {
				emailRecipient:[recipient],
				emailSubject:subject,
				emailHTMLMessage:htmlBody,
				emailTextMessage:textBody,
				emailType: 2,
				logDetails
			}
		} else if (template==3) {
			textBody = getEmailTextMessageThree();
			htmlBody = getEmailHTMLMessageThree();

			requestBody = {
				emailRecipient:[recipient],
				emailSubject:subject,
				emailHTMLMessage:htmlBody,
				emailTextMessage:textBody,
				emailType: 2,
				logDetails
			}
		}

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-email-to-user";

		try {

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: this.state.currentUser.jwtToken,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(requestBody)
			});

			const body = await response.json();
			console.log("send-email-to-user response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				this.toggle4();
				this.setState({
					statusSubject: "SUCCESS!",
					statusMessage: "Email sent Successfully!"
				})

			} else {

				this.toggle4();
				this.setState({
					statusSubject: "FAILED!",
					statusMessage: "Failed to send Email to "+recipient+"."
				})

			}

		}
		catch (err) {
			console.log(err);
		}

		this.toggle5();

	};

	saveShipmentDate = (event) => {
		event.preventDefault();

		//console.log("Save Date");
		let id = event.target.id.value;
    //console.log(`id is:`, id);
		let shipmentdate = event.target.shipmentdate.value;
    //console.log(`shipmentdate is:`, shipmentdate);
		let serialnumber = event.target.serialnumber.value;
    //console.log(`serialnumber is:`, serialnumber);

		let newObj = JSON.parse(JSON.stringify(this.state.devices));

		newObj[id].shipment_date = shipmentdate;
		this.setState({
			devices: newObj
		})
    //console.log("saved date is:", this.state.devices[id].shipment_date);

		this.updateShipmentDate(shipmentdate, serialnumber);

		this.toggle6();
	}

	updateShipmentDate = async (shipmentDate, serialNumber) => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"update-shipment-date-for-device?ShipmentDate="+shipmentDate+"&SerialNumber="+serialNumber;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		//console.log("Update shipment date response: " + JSON.stringify(body.response));

		if (body.status == 'Success') {
			//this.setState({deviceInfo: body.response});
			//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
			this.state.noDataForDevices = false;
		} else {
			this.state.noDataForDevices = true;
		}

	};

	enforcePhoneNumberPattern(string) {
	  let newString = string.match(/[0-9]{0,14}/g);

	  if (newString === null) {
	    return '';
	  }

	  // Join parts returned from RegEx match
	  newString = newString.join('');

	  // Start number with "+"
	  newString = '+' + newString;

	  // Limit length to 15 characters
	  newString = newString.substring(0, 15);

	  return newString;
	}

	// sendTextMessage = async (recipient, subject, message) => {
	//
	// 	var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-text-message-to-user?TextRecipient="+recipient+"&TextSubject="+subject+"&TextMessage="+encodeURIComponent(message);
	//
	// 	const response = await fetch(url, {
	// 		method: 'GET',
	// 		headers: {
	// 			Authorization: this.state.currentUser.jwtToken
	// 		},
	// 	});
	//
	// 	const body = await response.json();
	// 	//console.log("Send Text response: " + JSON.stringify(body.results));
	//
	// 	if (body.status == 'Success') {
	//
	// 		this.toggle4();
	// 		this.setState({
	// 			statusSubject: "SUCCESS!",
	// 			statusMessage: "Text Message sent Successfully!"
	// 		})
	//
	// 	} else {
	//
	// 		this.toggle4();
	// 		this.setState({
	// 			statusSubject: "FAILED!",
	// 			statusMessage: "Failed to send Text Message to "+recipient+"."
	// 		})
	//
	// 	}
	//
	// 	this.toggle5();
	//
	// };

	sendTextMessage = async (recipient, subject, message) => {

		var logDetails = {
			SerialNumber: this.state.obj.SerialNumber,
			FirstName: this.state.msgFirstName,
			LastName: this.state.msgLastName,
			PhoneNumber: recipient,
			TechnicianEmail: this.state.currentUser.payload.email,
			TechnicianFirstName: this.state.currentUser.payload.given_name,
			TechnicianLastName: this.state.currentUser.payload.family_name,
		}

		var requestBody = {
			TextRecipient:recipient,
			TextSubject:subject,
			TextMessage:message,
			textType: 2,
			logDetails
		}

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"post-text-message-to-user";

		//?TextRecipient="+recipient+"&TextSubject="+subject+"&TextMessage="+encodeURIComponent(message)

		try {

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: this.state.currentUser.jwtToken,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(requestBody)
			});

			// const response = await fetch(url, {
			// 	method: 'GET',
			// 	headers: {
			// 		Authorization: this.state.currentUser.jwtToken
			// 	},
			// });

			const body = await response.json();
			console.log("Send Text response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				//await this.saveCorrespondenceLog(recipient, subject, message);

				this.toggle4();
				this.setState({
					statusSubject: "SUCCESS!",
					statusMessage: "Text Message sent Successfully!"
				})

			} else {

				this.toggle4();
				this.setState({
					statusSubject: "FAILED!",
					statusMessage: "Failed to send Text Message to "+recipient+"."
				})

			}

		}
		catch (err) {
			console.log(err);
		}

		this.toggle5();

	};


	test = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"set-wifi-provisioned-for-device?SerialNumber=00013";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		//console.log("test response: " + JSON.stringify(body));

	};

	getUserData = async (serialNumber) => {
		//console.log("Getting Device Registered User");

		if (this.state.currentUser) {
			//console.log("topprocedures 1: ");

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-user-for-device?SerialNumber="+serialNumber;

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Device Registered User response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {

				let deviceInfo = body.response;

				this.setState({deviceInfo});
				//console.log("Device Reg Phone: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("custom:phone")).map(filteredName => (filteredName.Value)));
				let phoneNumber = deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("custom:phone")).map(filteredName => (filteredName.Value));
				let allowphonecontact = deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("custom:allowphonecontact")).map(filteredName => (filteredName.Value));
				//console.log("Allow SMS: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("custom:allowphonecontact")).map(filteredName => (filteredName.Value)));

				let firstName = deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value));
				let lastName = deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("family_name")).map(filteredName => (filteredName.Value));

				var allowPhoneContactValue = false;

				if (allowphonecontact) {
					if (allowphonecontact == "1") {
						allowPhoneContactValue = true;
					} else {
						allowPhoneContactValue = false;
					}
				}

				this.setState({
					userPhoneNumber: phoneNumber,
					allowSMS: allowPhoneContactValue,
					msgFirstName: firstName,
					msgLastName: lastName
				})
				this.state.noDataForDevices = false;
			} else {
				this.state.noDataForDevices = true;
			}
		}
	};

	connectToMQTT = async () => {
		authenticationService.getGlobalConnectionURL()
				.then(
						connectionUrl => {
							//console.log('connectToMQTT connectionUrl: ' + JSON.stringify(connectionUrl));

							//var url1 = "wss://a1f3ynjyzalf3r-ats.iot.us-west-2.amazonaws.com/mqtt?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAS3T2QUYOSMZKW4V2%2F20210201%2Fus-west-2%2Fiotdevicegateway%2Faws4_request&X-Amz-Date=20210201T225801Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=dff6d2d6b29772129d10a43adf2b218d07b3cd3423ac13e32c71c6d7f8032eaf";

							if (connectionUrl.status == 'Success') {
								var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
								//console.log('random hash: ' + hash);
								this.state.client = new Paho.Client(connectionUrl.response, hash+this.state.currentUser.payload.sub);
								this.state.client.onMessageArrived = this.onMessageArrived;
								this.state.client.onConnectionLost = this.onConnectionLost;
								this.state.client.connect({
									timeout: 3,
									useSSL: true,
									mqttVersion: 4,
									cleanSession: true,
									onSuccess: this.onConnect,
									onFailure: (e) => {console.log("here is the error" , e); }
								});
							}
						},
						error => {
							console.log('error.message: ' + error.message);
						}
				);
	}

  getData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"all-devices";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all devices: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ devices: body.results, totalResults: body.results.length });
		} else {
      //ADD ERROR MESSAGE
    }

		for ( var i = 0; i < this.state.devices.length; i++ ) {

			// if (!this.state.devices[i].geo_timestamp || !this.state.devices[i].WifiProvisioned) {
			// 	this.state.devicesIcons.push( {
			// 		serial_number: this.state.devices[i].SerialNumber,
	    //     current_icon: brokenpin
			// 	});
			// } else {
			// 	this.state.devicesIcons.push( {
			// 		serial_number: this.state.devices[i].SerialNumber,
	    //     current_icon: redpin
			// 	});
			// }

			if (this.state.devices[i].geo_timestamp || this.state.devices[i].WifiProvisioned) {
				devicesIcons.push( {
					id: i,
					serial_number: this.state.devices[i].SerialNumber,
	        current_icon: redpin
				});
			} else {
				devicesIcons.push( {
					id: i,
					serial_number: this.state.devices[i].SerialNumber,
	        current_icon: brokenpin
				});
			}

		}

    this.setState({ devicesLoaded: true });
	};

  handleChange = (selectedOption) => {
    //console.log(`Option selected:`, selectedOption.target.value);
    //console.log(`obj is:`, this.state.obj);
    this.state.obj.role = selectedOption.target.value;
    //console.log(`new obj is:`, this.state.obj);
  }

	goToPage = (selected) => {
    //console.log(`Go To Page Option selected:`, selected);
  }

	checkIsConnected = () => {
		if (this.state.isReceivingMessages) {
			//console.log("Vitals Is Connected!");
		} else {
			//console.log("Vitals Is Not Connected!");
		}
	}

	updateIsConnected = () => {
		this.setState({isReceivingMessages: false});
	}

	onMessageArrived(entry) {
    //console.log("dashboard onMessageArrived:"+entry.payloadString);
		const msg = JSON.parse(entry.payloadString);
		if (msg.batch) {
			messagesArray.push(msg);
		} else {
			//this.setState({subMessage: JSON.parse(entry.payloadString)});
			//console.log("dashboard_sub_messages:"+this.state.subMessage);
		}

  }

	// onMessageArrived(entry) {
  //   //console.log("Deviceslist onMessageArrived:"+entry.payloadString);
	// 	const msg = JSON.parse(entry.payloadString);
	// 	if (msg.batch) {
	// 		this.mainMessage = JSON.parse(entry.payloadString);
	// 		//console.log("heartbeat_main_messages geo_lat:"+this.mainMessage.geo_lat);
	// 	} else {
	// 		this.subMessage = JSON.parse(entry.payloadString);
	// 		//console.log("heartbeat_sub_messages serial_num:"+this.subMessage.serial_num);
	// 	}
	//
	// 	var found_online_marker = false;
	// 	for ( var i = 0; i < this.state.online_markers.length; i++ ) {
	// 		if ( this.state.online_markers[ i ].serial_number == msg.serial_num ) {
	// 			this.state.online_markers[ i ].last_update = new Date();
	// 			found_online_marker = true;
	// 		}
	// 	}
	// 	if ( found_online_marker == false ) {
	// 		//console.log("add green");
	// 		this.state.online_markers.push( {
	// 			serial_number: msg.serial_num,
  //       icon: greenpin,
	// 			last_update: new Date()
	// 		} );
	// 	}
	//
  //   for ( var i = 0; i < this.state.online_markers.length; i++ ) {
  //     for ( var j = 0; j < this.state.devicesIcons.length; j++ ) {
  //       if ( this.state.online_markers[ i ].serial_number == this.state.devicesIcons[j].serial_number ) {
  //         this.setState(update(this.state, {
  //         	devicesIcons: {
  //         		[j]: {
  //               current_icon: {
  //                 $set: greenpin
  //               }
  //         		}
  //         	}
  //         }));
  //         //this.state.devicesIcons[j].current_icon = 'https://img.icons8.com/emoji/48/000000/green-heart.png';
  //         //console.log("Serial:"+this.state.devicesIcons[j].serial_number+" current_icon:"+this.state.devicesIcons[j].current_icon);
  // 			}
  //     }
	// 	}
  // }

	handleMessage = async () => {

		//let messages = messagesArray;
		let messages = JSON.parse(JSON.stringify(messagesArray));
		//let messages = [...messagesArray];

		//console.log("dashboard messagesArray 2:"+messages);

		//this.setState({ handlingMessage: true });

		//console.log("Handling Message");

		var resetArray = false;

		for ( var i = 0; i < messages.length; i++ ) {

			let msg = messages[i];

			var found_online_marker = false;

			var anIndex = 0;
			var onlineDevice = online_markers.find(function(device, index) {

				if(device.serial_number == msg.serial_num) {
					online_markers[ index ].last_update = new Date();
					//console.log('handleMessage found serial: ' + JSON.stringify(msg.serial_num));
					found_online_marker = true;
					return true;
				}
			});

			if ( found_online_marker == false ) {
				//console.log("add green");

				var city = "Unknown";
				var state = "Unknown";
				var country = "Unknown";

				//let device = this.state.devices.find(d => d.SerialNumber === msg.serial_num);

				//console.log("handleMessage device not found serial: "+device.SerialNumber);
				//console.log("handleMessage device not found city: "+device.geo_city);

				// if (device) {
				// 	city = device.geo_city;
				// 	state = device.geo_state;
				// 	country = device.geo_country;
				// }
				//
				// let life810 = msg.life810;
				// let life980 = msg.life980;
				//
				// var d = Number(life810 + life980);
				// var h = Math.floor(d / 3600);
				// var m = Math.floor(d % 3600 / 60);
				// var s = Math.floor(d % 3600 % 60);
				//
				// var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
				// var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
				// var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";
				//
				// online_markers.push( {
				// 	serial_number: msg.serial_num,
	      //   icon: greenpin,
				// 	last_update: new Date(),
				// 	city: city,
				// 	state: state,
				// 	country: country,
				// 	version: msg.fw_ver,
				// 	usage: hDisplay
				// });

				online_markers.push( {
					serial_number: msg.serial_num,
	        icon: greenpin,
					last_update: new Date()
				});

				//console.log('handleMessage online markers count: ' + JSON.stringify(online_markers.length));
				//console.log('handleMessage online markers: ' + JSON.stringify(online_markers));
			}

      let device = devicesIcons.find(d => d.serial_number === msg.serial_num);
      //console.log('handleMessage device: ' + JSON.stringify(device));

			if (device) {
				let newDevicesIcons = JSON.parse(JSON.stringify(devicesIcons));

	      newDevicesIcons[device.id].current_icon = greenpin;

				devicesIcons = newDevicesIcons;
			}

      //this.setState({ devicesIcons: newDevicesIcons });

      //console.log('handleMessage all devicesIcons: ' + JSON.stringify(this.state.devicesIcons));

			if (i == (messages.length-1)) {
				resetArray = true;
			}

		}

		if (resetArray) {
			messagesArray = [];
		}


		//this.setState({ handlingMessage: false });

	}

	getMessage = async () => {
    //console.log('mapcontainer lat: ' + this.mainMessage.geo_lat);
    //console.log('mapcontainer lon: ' + this.mainMessage.geo_lng);
    //console.log('online markers count: ' + JSON.stringify(online_markers.length));

		for ( var a = 0; a < online_markers.length; a++ ) {

      let device = devicesIcons.find(d => d.serial_number === online_markers[ a ].serial_number);
      //console.log('handleMessage device: ' + JSON.stringify(device));

			if ( new Date() - online_markers[ a ].last_update > 10000 ) {
				online_markers[a].icon = redpin;

				if (device) {
					let newDevicesIcons = JSON.parse(JSON.stringify(devicesIcons));

	        newDevicesIcons[device.id].current_icon = redpin;

					devicesIcons = newDevicesIcons;
				}

        //this.setState({ devicesIcons: newDevicesIcons });

        //console.log('handleMessage all devicesIcons: ' + JSON.stringify(devicesIcons));
			} else {
				online_markers[a].icon = greenpin;

				if (device) {
					let newDevicesIcons = JSON.parse(JSON.stringify(devicesIcons));

	        newDevicesIcons[device.id].current_icon = greenpin;

					devicesIcons = newDevicesIcons;
				}

        //this.setState({ devicesIcons: newDevicesIcons });

        //console.log('handleMessage all devicesIcons: ' + JSON.stringify(devicesIcons));
			}

		}

  }

	// getMessage = async () => {
  //   //console.log('mapcontainer lat: ' + this.mainMessage.geo_lat);
  //   //console.log('mapcontainer lon: ' + this.mainMessage.geo_lng);
  //   //console.log('online markers: ' + JSON.stringify(this.props.online_markers));
  //   //console.log('all markers: ' + JSON.stringify(this.state.markerObjects[12].icon.url));
  //   //this.state.devices[12].SerialNumber = "00100";
  //   //console.log('updated serial: ' + this.state.devices[12].SerialNumber);
	// 	//console.log('devicesIcons: ' + this.state.devicesIcons);
	//
  //   for ( var a = 0; a < this.state.online_markers.length; a++ ) {
  //     for ( var i = 0; i < this.state.devicesIcons.length; i++ ) {
  //       if ( this.state.devicesIcons[ i ].serial_number == this.state.online_markers[ a ].serial_number ) {
  //         // console.log("Found marker " + online_markers[a].serial_number)
  //         if ( new Date() - this.state.online_markers[ a ].last_update > 10000 ) {
  //           this.setState(update(this.state, {
  //           	devicesIcons: {
  //           		[i]: {
  //                 current_icon: {
  //                   $set: redpin
  //                 }
  //           		}
  //           	}
  //           }));
  //           this.setState(update(this.state, {
  //           	online_markers: {
  //           		[a]: {
  //                 icon: {
  //                   $set: redpin
  //                 }
  //           		}
  //           	}
  //           }));
  //         } else {
  //           this.setState(update(this.state, {
  //           	devicesIcons: {
  //           		[i]: {
  //                 current_icon: {
  //                   $set: greenpin
  //                 }
  //           		}
  //           	}
  //           }));
  //           this.setState(update(this.state, {
  //           	online_markers: {
  //           		[a]: {
  //                 icon: {
  //                   $set: greenpin
  //                 }
  //           		}
  //           	}
  //           }));
  //         }
  //       }
  //     }
  //   }
  // }

	onConnect = () => {
    const { client } = this.state;
    console.log("Connected!!!!");
    //client.subscribe('00013/out');
    this.setState({isConnected: true, error: ''})
		//this.sendMessage("@@@get_all");

		const subChannel = "#";
		this.state.client.subscribe(subChannel);
  };


  // sendMessage(){
  //   var message = new Paho.Message(this.state.messageToSend);
  //   message.destinationName = "00013";
	//
  //   if(this.state.isConnected){
  //     this.state.client.send(message);
  //   }else{
  //     this.connect(this.state.client)
  //       .then(() => {
  //         this.state.client.send(message);
  //         this.setState({error: '', isConnected: true});
  //       })
  //       .catch((error)=> {
  //         console.log(error);
  //         this.setState({error: error});
  //       });
  // 	}
  // }


  onConnectionLost(responseObject) {
    if (responseObject.errorCode !== 0) {
      console.log("onConnectionLost:"+responseObject.errorMessage);
      this.setState({error: 'Lost Connection', isConnected: false});
			this.connectToMQTT();
    }
  }

	filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id;

		//console.log("filterCaseInsensitive row[id]: " + JSON.stringify(id));

		if (id == 'creation_date') {
			var dateString = Moment(row[id]).format('MMM Do YYYY');
			return (
					dateString !== undefined ?
							String(dateString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (id == 'SerialNumber') {
			var serialString = row[id].SN;
			return (
					serialString !== undefined ?
							String(serialString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (row[id] !== null){
			return (
					row[id] !== undefined ?
							String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}
	}

	render() {
    const data = this.state.devices.map((prop, key) => {

			var vitalsPath = "/vitals/"+prop.SerialNumber;
			var customizePath = "/customize/"+prop.SerialNumber;
			var remoteSupportPath = "/remotesupport/"+prop.SerialNumber;
			var vitalsDashboardPath = "/vitals-dashboard/"+prop.SerialNumber;
			var calibrationPath = "/calibration?serial="+prop.SerialNumber;
			var logsPath = "/device-logs/"+prop.SerialNumber;
			var remotesupportblackbox = "/remotesupportblackbox/"+prop.SerialNumber;

			var icon = redpin;

			if (this.state.devicesLoaded && devicesIcons.length>0) {
				icon = devicesIcons[key].current_icon ? devicesIcons[key].current_icon : 0
			}

			var ship_date = "Not Requested";

			if (prop.shipment_date) {
				ship_date = Moment(prop.shipment_date).format('MMM Do YYYY');
			}

			var appPlatform = 0;

			if (prop.AppPlatform) {
				appPlatform = prop.AppPlatform;
			}

			if (prop.snAppPlatform) {
				appPlatform = prop.snAppPlatform;
			}

			var appVersion = "";
			var firmwareVersion = "";

			if (prop.AppVersion) {
				appVersion = prop.AppVersion;
			}

			if (prop.snAppVersion) {
				appVersion = prop.snAppVersion;
			}

			if (prop.firmware_version) {
				if (prop.firmware_version.length==1) {
					firmwareVersion = parseFloat(prop.firmware_version).toFixed(1);
				} else {
					firmwareVersion = prop.firmware_version;
				}

			}

			return {
				id: key,
				icon: {online: icon, kit: prop.Kit},
				iconSortable: icon,
        userID: prop.OBJID,
				SerialNumber: { SN:prop.SerialNumber, notInService:prop.NotInService },
				Kit: prop.Kit,
				firmware_version: firmwareVersion,
				geo_city: prop.geo_city,
				geo_state: prop.geo_state,
				geo_country: prop.geo_country,
				BatchNumber: prop.BatchNumber,
				Email: prop.LoginID,
        creation_date: prop.creation_date,
				shipment_date: ship_date,
				appPlatform: appPlatform,
				appVersion: appVersion,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button size="sm" color="link" href={vitalsPath} title="Vitals">
							<i className="mdi mdi-stethoscope mdi-24px"/>
						</Button>
						<Button size="sm" color="link" href={customizePath} title="Customize">
							<i className="mdi mdi-message-settings-variant mdi-24px"/>
						</Button>
						<Button size="sm" color="link" href={vitalsDashboardPath} title="Dashboard">
							<i className="mdi mdi-view-dashboard mdi-24px"/>
						</Button>
						<Button size="sm" color="link" href={remoteSupportPath} title="Remote Support">
							<i className="mdi mdi-lan-connect mdi-24px"/>
						</Button>
						{this.state.currentUser.payload["custom:role"] && this.state.currentUser.payload["custom:role"] > 1 &&
							<Button size="sm" color="link" href={remotesupportblackbox} title="Remote Support Black Box" target="_blank">
								<i className="mdi mdi-play-box-outline mdi-24px"/>
							</Button>
						}
						<Button size="sm" color="link" href={calibrationPath} title="Calibration">
							<i className="mdi mdi-chart-bar mdi-24px"/>
						</Button>
						{this.state.currentUser.payload["custom:role"] && this.state.currentUser.payload["custom:role"] > 1 &&
							<Button size="sm" color="link" href={logsPath} title="Device Logs">
								<i className="mdi mdi-format-line-spacing mdi-24px"/>
							</Button>
						}
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                //console.log('button obj LoginID: ' + obj.Email);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj,
									messageType: "Email",
								});
								this.getUserData(obj.SerialNumber);
								//this.showVerification(obj);
							}}
							color="link"
							size="sm"
							round="true"
							icon="true"
							 title="Send Message"
							className=""
						>
							<i className="mdi mdi-email mdi-24px" />
						</Button>
						{this.state.currentUser.payload["custom:role"] && this.state.currentUser.payload["custom:role"] > 1 &&
							<Button
								onClick={() => {
									let obj = data.find(o => o.id === key);
	                //console.log('button obj LoginID: ' + obj.Email);
									this.setState({
										modal6: !this.state.modal6,
										obj: obj
									});

									if (obj.shipment_date=="Not Requested") {
										this.setState({
											shipmentDate: Moment().format('YYYY-MM-DD')
										});
									} else {
										//console.log('shipmentDate is: ' + obj.shipment_date);
										let day = obj.shipment_date;
										let parsed = Moment(day, "MMM Do YYYY");
										this.setState({
											shipmentDate: parsed.format('YYYY-MM-DD')
										});
									}
								}}
								color="link"
								size="sm"
								round="true"
								icon="true"
								 title="Save Shipment Date"
								className=""
							>
								<i className="mdi mdi-truck-delivery mdi-24px" />
							</Button>
						}
					</div>
				)
			};
		});

		//admin columns
		var columns = [
			{
				Header: "",
				accessor: "icon",
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				width: 35,
				filterable: false,
				Cell: row => (
						<Fragment>
							{row.value.kit == 'Int' || row.value.kit == 'Intl' &&
								<Fragment>
								{row.value.online==greenpin &&
									<div style={{ textAlign: "center" }}><i style={{ color: '#BC02C1'}} className="mdi mdi-heart mdi-24px" /></div>
								}
								{row.value.online==redpin &&
									<div style={{ textAlign: "center" }}><i style={{ color: '#e2212b'}} className="mdi mdi-heart mdi-24px" /></div>
								}
								{row.value.online==brokenpin &&
									<div style={{ textAlign: "center" }}><i style={{ color: '#e2212b'}} className="mdi mdi-heart-broken mdi-24px" /></div>
								}
								</Fragment>
							}
							{row.value.kit == 'US' &&
								<Fragment>
								{row.value.online==greenpin &&
									<div style={{ textAlign: "center" }}><i style={{ color: '#42BF00'}} className="mdi mdi-heart mdi-24px" /></div>
								}
								{row.value.online==redpin &&
									<div style={{ textAlign: "center" }}><i style={{ color: '#e2212b'}} className="mdi mdi-heart mdi-24px" /></div>
								}
								{row.value.online==brokenpin &&
									<div style={{ textAlign: "center" }}><i style={{ color: '#e2212b'}} className="mdi mdi-heart-broken mdi-24px" /></div>
								}
								</Fragment>
							}
							{row.value.kit == 'Demo' &&
								<Fragment>
								{row.value.online==greenpin &&
									<div style={{ textAlign: "center" }}><i style={{ color: '#0356DB'}} className="mdi mdi-heart mdi-24px" /></div>
								}
								{row.value.online==redpin &&
									<div style={{ textAlign: "center" }}><i style={{ color: '#e2212b'}} className="mdi mdi-heart mdi-24px" /></div>
								}
								{row.value.online==brokenpin &&
									<div style={{ textAlign: "center" }}><i style={{ color: '#e2212b'}} className="mdi mdi-heart-broken mdi-24px" /></div>
								}
								</Fragment>
							}
						</Fragment>
					)
			},
			{
				Header: "",
				accessor: "iconSortable",
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				minWidth: 10,
				show: false,
				Cell: row => (
						<div style={{ textAlign: "center" }}><img src={row.value} width="25" height="25"/></div>
					)
			},
			{
				Header: "Serial",
				accessor: "SerialNumber",
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				width: 70,
				Cell: row => (
					<Fragment>
						{row.value.notInService ? (
							<div className="textstrike" style={{ textAlign: "center" }}>{row.value.SN}</div>
						) : (
							<div style={{ textAlign: "center" }}>{row.value.SN}</div>
						)}
					</Fragment>
					)
			},
			{
				Header: "App",
				accessor: "appPlatform",
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				width: 70,
				Cell: row => (
					<Fragment>
						{row.value==1 &&
							<div style={{ textAlign: "center" }}><span style={{ fontSize: "20px", lineHeight: "1.4", color: "#fff" }} class="material-icons">apple</span></div>
						}
						{row.value==2 &&
							<div style={{ textAlign: "center" }}><span style={{ fontSize: "20px", lineHeight: "1.4", color: "#a4c639" }} class="material-icons">android</span></div>
						}
					</Fragment>
					)
			},
			{
				Header: "Email Address",
				accessor: "Email",
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				width: 230,
				Cell: row => (
						<div style={{ textAlign: "center" }}>{row.value}</div>
					)
			},
			{
				Header: "Firmware",
				accessor: "firmware_version",
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				width: 80,
				Cell: row => (
					<Fragment>
					{row.value == "2.0" ? (
						<div style={{ textAlign: "center", color: "#85FF6C" }}>{parseFloat(row.value).toFixed(1)}</div>
					) : (
						<div style={{ textAlign: "center" }}>{row.value}</div>
					)}
					</Fragment>
					)
			},
			{
				Header: "Kit",
				accessor: "Kit",
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				width: 50,
				Cell: row => (
						<div style={{ textAlign: "center" }}>{row.value}</div>
					)
			},
			{
				Header: "City",
				accessor: "geo_city",
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				width: 140,
				Cell: row => (
						<div style={{ textAlign: "center" }}>{row.value}</div>
					)
			},
			{
				Header: "State",
				accessor: "geo_state",
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				width: 120,
				Cell: row => (
						<div style={{ textAlign: "center" }}>{row.value}</div>
					)
			},
			{
				Header: "Country",
				accessor: "geo_country",
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				width: 140,
				Cell: row => (
						<div style={{ textAlign: "center" }}>{row.value}</div>
					)
			},
			{
				Header: "Created",
				accessor: "creation_date",
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				width: 130,
				Cell: row => (
						<div style={{ textAlign: "center" }}>{Moment(row.value).format('MMM Do YYYY')}</div>
					)
			},
			{
				Header: "Shipped",
				accessor: "shipment_date",
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				width: 140,
				Cell: row => (
						<div style={{ textAlign: "center" }}>{row.value}</div>
					)
			},
			{
				Header: "Batch",
				accessor: "BatchNumber",
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				width: 80,
				Cell: row => (
						<div style={{ textAlign: "center" }}>{row.value}</div>
					)
			},
			{
				Header: "Actions",
				accessor: "actions",
				width: 380,
				sortable: false,
				filterable: false
			}
		];

		if (this.state.currentUser.payload["custom:role"] && this.state.currentUser.payload["custom:role"]==1) {

			if (this.state.currentUser.payload["custom:market"] == "us-azena" || this.state.currentUser.payload["custom:market"] == "us") {
				columns = [
					{
						Header: "",
						accessor: "icon",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 35,
						filterable: false,
						Cell: row => (
								<Fragment>
									{row.value.kit == 'Int' || row.value.kit == 'Intl' &&
										<Fragment>
										{row.value.online==greenpin &&
											<div style={{ textAlign: "center" }}><i style={{ color: '#BC02C1'}} className="mdi mdi-heart mdi-24px" /></div>
										}
										{row.value.online==redpin &&
											<div style={{ textAlign: "center" }}><i style={{ color: '#e2212b'}} className="mdi mdi-heart mdi-24px" /></div>
										}
										{row.value.online==brokenpin &&
											<div style={{ textAlign: "center" }}><i style={{ color: '#e2212b'}} className="mdi mdi-heart-broken mdi-24px" /></div>
										}
										</Fragment>
									}
									{row.value.kit == 'US' &&
										<Fragment>
										{row.value.online==greenpin &&
											<div style={{ textAlign: "center" }}><i style={{ color: '#42BF00'}} className="mdi mdi-heart mdi-24px" /></div>
										}
										{row.value.online==redpin &&
											<div style={{ textAlign: "center" }}><i style={{ color: '#e2212b'}} className="mdi mdi-heart mdi-24px" /></div>
										}
										{row.value.online==brokenpin &&
											<div style={{ textAlign: "center" }}><i style={{ color: '#e2212b'}} className="mdi mdi-heart-broken mdi-24px" /></div>
										}
										</Fragment>
									}
									{row.value.kit == 'Demo' &&
										<Fragment>
										{row.value.online==greenpin &&
											<div style={{ textAlign: "center" }}><i style={{ color: '#0356DB'}} className="mdi mdi-heart mdi-24px" /></div>
										}
										{row.value.online==redpin &&
											<div style={{ textAlign: "center" }}><i style={{ color: '#e2212b'}} className="mdi mdi-heart mdi-24px" /></div>
										}
										{row.value.online==brokenpin &&
											<div style={{ textAlign: "center" }}><i style={{ color: '#e2212b'}} className="mdi mdi-heart-broken mdi-24px" /></div>
										}
										</Fragment>
									}
								</Fragment>
							)
					},
					{
						Header: "",
						accessor: "iconSortable",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						minWidth: 10,
						show: false,
						Cell: row => (
								<div style={{ textAlign: "center" }}><img src={row.value} width="25" height="25"/></div>
							)
					},
					{
						Header: "Serial",
						accessor: "SerialNumber",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 70,
						Cell: row => (
							<Fragment>
								{row.value.notInService ? (
									<div className="textstrike" style={{ textAlign: "center" }}>{row.value.SN}</div>
								) : (
									<div style={{ textAlign: "center" }}>{row.value.SN}</div>
								)}
							</Fragment>
							)
					},
					{
						Header: "App",
						accessor: "appPlatform",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 70,
						Cell: row => (
							<Fragment>
								{row.value==1 &&
									<div style={{ textAlign: "center" }}><span style={{ fontSize: "20px", lineHeight: "1.4", color: "#fff" }} class="material-icons">apple</span></div>
								}
								{row.value==2 &&
									<div style={{ textAlign: "center" }}><span style={{ fontSize: "20px", lineHeight: "1.4", color: "#a4c639" }} class="material-icons">android</span></div>
								}
							</Fragment>
							)
					},
					{
						Header: "Email Address",
						accessor: "Email",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 230,
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "Firmware",
						accessor: "firmware_version",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 80,
						Cell: row => (
							<Fragment>
							{row.value == "2.0" ? (
								<div style={{ textAlign: "center", color: "#85FF6C" }}>{parseFloat(row.value).toFixed(1)}</div>
							) : (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)}
							</Fragment>
							)
					},
					{
						Header: "Kit",
						accessor: "Kit",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 50,
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "City",
						accessor: "geo_city",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 140,
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "State",
						accessor: "geo_state",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 120,
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "Country",
						accessor: "geo_country",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 140,
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "Created",
						accessor: "creation_date",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 130,
						Cell: row => (
								<div style={{ textAlign: "center" }}>{Moment(row.value).format('MMM Do YYYY')}</div>
							)
					},
					{
						Header: "Shipped",
						accessor: "shipment_date",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 140,
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "Batch",
						accessor: "BatchNumber",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 80,
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "Actions",
						accessor: "actions",
						width: 380,
						sortable: false,
						filterable: false
					}
				];
			} else {
				columns = [
					{
						Header: "",
						accessor: "icon",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 35,
						filterable: false,
						Cell: row => (
								<Fragment>
									{row.value.kit == 'Int' || row.value.kit == 'Intl' &&
										<Fragment>
										{row.value.online==greenpin &&
											<div style={{ textAlign: "center" }}><i style={{ color: '#BC02C1'}} className="mdi mdi-heart mdi-24px" /></div>
										}
										{row.value.online==redpin &&
											<div style={{ textAlign: "center" }}><i style={{ color: '#e2212b'}} className="mdi mdi-heart mdi-24px" /></div>
										}
										{row.value.online==brokenpin &&
											<div style={{ textAlign: "center" }}><i style={{ color: '#e2212b'}} className="mdi mdi-heart-broken mdi-24px" /></div>
										}
										</Fragment>
									}
									{row.value.kit == 'US' &&
										<Fragment>
										{row.value.online==greenpin &&
											<div style={{ textAlign: "center" }}><i style={{ color: '#42BF00'}} className="mdi mdi-heart mdi-24px" /></div>
										}
										{row.value.online==redpin &&
											<div style={{ textAlign: "center" }}><i style={{ color: '#e2212b'}} className="mdi mdi-heart mdi-24px" /></div>
										}
										{row.value.online==brokenpin &&
											<div style={{ textAlign: "center" }}><i style={{ color: '#e2212b'}} className="mdi mdi-heart-broken mdi-24px" /></div>
										}
										</Fragment>
									}
									{row.value.kit == 'Demo' &&
										<Fragment>
										{row.value.online==greenpin &&
											<div style={{ textAlign: "center" }}><i style={{ color: '#0356DB'}} className="mdi mdi-heart mdi-24px" /></div>
										}
										{row.value.online==redpin &&
											<div style={{ textAlign: "center" }}><i style={{ color: '#e2212b'}} className="mdi mdi-heart mdi-24px" /></div>
										}
										{row.value.online==brokenpin &&
											<div style={{ textAlign: "center" }}><i style={{ color: '#e2212b'}} className="mdi mdi-heart-broken mdi-24px" /></div>
										}
										</Fragment>
									}
								</Fragment>
							)
					},
					{
						Header: "",
						accessor: "iconSortable",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						minWidth: 10,
						show: false,
						Cell: row => (
								<div style={{ textAlign: "center" }}><img src={row.value} width="25" height="25"/></div>
							)
					},
					{
						Header: "Serial",
						accessor: "SerialNumber",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 70,
						Cell: row => (
							<Fragment>
								{row.value.notInService ? (
									<div className="textstrike" style={{ textAlign: "center" }}>{row.value.SN}</div>
								) : (
									<div style={{ textAlign: "center" }}>{row.value.SN}</div>
								)}
							</Fragment>
							)
					},
					{
						Header: "App",
						accessor: "appPlatform",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 70,
						Cell: row => (
							<Fragment>
								{row.value==1 &&
									<div style={{ textAlign: "center" }}><span style={{ fontSize: "20px", lineHeight: "1.4", color: "#fff" }} class="material-icons">apple</span></div>
								}
								{row.value==2 &&
									<div style={{ textAlign: "center" }}><span style={{ fontSize: "20px", lineHeight: "1.4", color: "#a4c639" }} class="material-icons">android</span></div>
								}
							</Fragment>
							)
					},
					{
						Header: "Email Address",
						accessor: "Email",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 230,
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "Firmware",
						accessor: "firmware_version",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 80,
						Cell: row => (
							<Fragment>
							{row.value == "2.0" ? (
								<div style={{ textAlign: "center", color: "#85FF6C" }}>{parseFloat(row.value).toFixed(1)}</div>
							) : (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)}
							</Fragment>
							)
					},
					{
						Header: "Kit",
						accessor: "Kit",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 50,
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "City",
						accessor: "geo_city",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 140,
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "State",
						accessor: "geo_state",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 120,
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "Country",
						accessor: "geo_country",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 140,
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "Created",
						accessor: "creation_date",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 130,
						Cell: row => (
								<div style={{ textAlign: "center" }}>{Moment(row.value).format('MMM Do YYYY')}</div>
							)
					},
					{
						Header: "Shipped",
						accessor: "shipment_date",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 140,
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "Batch",
						accessor: "BatchNumber",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						width: 80,
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					}
				];
			}

		}

		return (
      <Card className="card-hover">
				<CardBody>
          <Row className="mb-3">
            <Col md="6">
              <h1 style={{color:'#737782'}}>Gemini Devices</h1>
            </Col>
          </Row>
          <ReactTable
            style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
						columns={columns}
						defaultPageSize={10}
            sortable={true}
						sorted={[{ // the sorting model for the table
					      id: 'iconSortable',
					      desc: true
					    },
							{
					      id: 'Email',
					      desc: true
					  	},
							{
					      id: 'creation_date',
					      desc: false
					  }]}
						showPaginationBottom={true}
						className="-striped -highlight"
						data={data}
						filterable
						filterAll={true}
						defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
					>
					{(state, makeTable, instance) => {
				            let recordsInfoText = "";

				            const { filtered, pageRows, pageSize, sortedData, page } = state;

				            if (sortedData && sortedData.length > 0) {
				              let isFiltered = filtered.length > 0;

				              let totalRecords = sortedData.length;

				              let recordsCountFrom = page * pageSize + 1;

				              let recordsCountTo = recordsCountFrom + pageRows.length - 1;

				              if (isFiltered)
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
				              else
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
				            } else recordsInfoText = "No records";

				            return (
				              <div className="main-grid mt-2">
												{makeTable()}
												<Row className="mt-2 text-center">
													<Col md="12">
														<Label style={{fontSize:'15px'}} for=""><b>{recordsInfoText}</b></Label>
													</Col>
												</Row>
				              </div>
				            );
				          }}
				        </ReactTable>
        </CardBody>
				<div>
				<Modal isOpen={this.state.modal4} toggle={this.toggle4}>
					{this.state.obj.Email ? (
					<Fragment>
						<ModalHeader toggle={this.toggle4}>Send a Message</ModalHeader>
						<ModalBody>
							<Row>
								<Col xs="12" md="12" lg="12" className="text-left pb-3">
									<h4>Send message to owner of Device: {this.state.obj.SerialNumber}</h4>
								</Col>
							</Row>
							<Form onSubmit={this.sendMessage}>
								<Input type="hidden" name="email" id="email" defaultValue={this.state.obj.Email}/>
								<Input type="hidden" name="phone" id="phone" defaultValue={this.state.userPhoneNumber}/>
								<FormGroup>
					        <Label for="messagetype">Select Message Type:</Label>
					        <Input type="select" name="messagetype" id="messagetype" onChange={this.onChangeMessageType}>
					          <option>Email</option>
										{this.state.userPhoneNumber.length > 0 && this.state.allowSMS &&
											<Fragment>
											<option>Text Message</option>
											</Fragment>
										}
					        </Input>
					      </FormGroup>
								<FormGroup>
									<Label for="subject">Subject:</Label>
									<Input type="text" name="subject" id="subject" placeholder="Enter your subject..."/>
								</FormGroup>
								<FormGroup>
									<Label for="message">Message:</Label>
									<Input type="textarea" name="message" id="message" placeholder="Enter your message..."/>
								</FormGroup>
								<FormGroup>
									<Button color="primary" type="submit">Send</Button>
									<Button color="secondary" className="ml-1" onClick={this.toggle4}>Cancel</Button>
								</FormGroup>
							</Form>
						</ModalBody>
					</Fragment>
				) : (
					<Fragment>
					<ModalHeader toggle={this.toggle4}>No Current Owner</ModalHeader>
					<ModalBody>
						<span>This device currently does not have an owner. {this.state.obj.Email}</span>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" className="ml-1" onClick={this.toggle4}>OK</Button>
					</ModalFooter>
					</Fragment>
				)}
				</Modal>
				<Modal isOpen={this.state.modal5} toggle={this.toggle5}>
  				<ModalHeader toggle={this.toggle5}>{this.state.statusSubject}</ModalHeader>
  				<ModalBody>
						<span>{this.state.statusMessage}</span>
  				</ModalBody>
					<ModalFooter>
						<Button color="primary" className="ml-1" onClick={this.toggle5}>OK</Button>
					</ModalFooter>
			  </Modal>
				<Modal isOpen={this.state.modal6} toggle={this.toggle6}>
					<ModalBody>
						<Row>
							<Col xs="12" md="12" lg="12" className="text-left pb-3">
								<h4>Enter the shipment date for: {this.state.obj.SerialNumber}</h4>
							</Col>
						</Row>
						<Form onSubmit={this.saveShipmentDate}>
							<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
							<Input type="hidden" name="serialnumber" id="serialnumber" defaultValue={this.state.obj.SerialNumber}/>
							<FormGroup>
								<Label for="shipmentdate">Shipment Date:</Label>
								<Input name="shipmentdate" id="shipmentdate" size='sm' type="date" value={this.state.shipmentDate} onChange={this.handleDateChange} />
							</FormGroup>
							<FormGroup>
								<Button color="primary" type="submit">Update</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle6}>Cancel</Button>
							</FormGroup>
						</Form>
					</ModalBody>
			  </Modal>
				</div>
      </Card>
		);
	}
}

export default DevicesList;
