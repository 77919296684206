import React from "react";
import { Component, Fragment } from "react";
import {
	Progress,
	Card,
	CardBody,
  CardTitle,
	Input,
	Row,
	Col,
	Button,
	ButtonGroup
} from 'reactstrap';

import img1 from '../../../assets/images/background/active-bg.png';

class VisualAudioStatus extends React.Component {

	noResults = true;

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 1,
			topProcedures: [],
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			colors: [{color:'warning'}, {color:'warning'}, {color:'success'}, {color:'info'}, {color:'teal'}, {color:'cyan'}],
	  };

		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
  }

	async componentDidMount() {
	}

	async onRadioBtnClick(rSelected) {
		await this.setState({ rSelected });
	}

	render() {
		return (
			/*--------------------------------------------------------------------------------*/
			/* Used In Dashboard-1 [Classic]                                                  */
			/*--------------------------------------------------------------------------------*/
			<Card className="card-hover">
				<CardBody style={{ minHeight: 417 }}>
          <CardTitle>Visual & Audible Status</CardTitle>
					{this.props.isReceivingMessages &&
						<Fragment>
						<div className="pt-2">
							<Row>
								<Col className="text-nowrap">
									<h6>Sound</h6>
								</Col>
							</Row>
							<Row>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
					          this.props.message.sound==0
					          ? <Progress bar color="yellow" value="100" />
					          : (
					            <Progress bar color="yellow" value="0" />
					          )
					        }
									</Progress>
								</Col>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
										this.props.message.sound==1
										? <Progress bar color="yellow" value="100" />
										: (
											<Progress bar color="yellow" value="0" />
										)
									}
									</Progress>
								</Col>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
										this.props.message.sound==2
										? <Progress bar color="yellow" value="100" />
										: (
											<Progress bar color="yellow" value="0" />
										)
									}								</Progress>
								</Col>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
										this.props.message.sound==3
										? <Progress bar color="yellow" value="100" />
										: (
											<Progress bar color="yellow" value="0" />
										)
									}								</Progress>
								</Col>
							</Row>
							<Row>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>OFF</h6>
								</Col>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>LOW</h6>
								</Col>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>MEDIUM</h6>
								</Col>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>HIGH</h6>
								</Col>
							</Row>
						</div>
						<div className="pt-2">
							<Row>
								<Col className="text-nowrap">
									<h6>Aiming Light</h6>
								</Col>
							</Row>
							<Row>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
										this.props.message.aiming==0
										? <Progress bar color="yellow" value="100" />
										: (
											<Progress bar color="yellow" value="0" />
										)
									}
									</Progress>
								</Col>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
										this.props.message.aiming==1
										? <Progress bar color="yellow" value="100" />
										: (
											<Progress bar color="yellow" value="0" />
										)
									}
									</Progress>
								</Col>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
										this.props.message.aiming==2
										? <Progress bar color="yellow" value="100" />
										: (
											<Progress bar color="yellow" value="0" />
										)
									}
									</Progress>
								</Col>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
										this.props.message.aiming==3
										? <Progress bar color="yellow" value="100" />
										: (
											<Progress bar color="yellow" value="0" />
										)
									}
									</Progress>
								</Col>
							</Row>
							<Row>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>OFF</h6>
								</Col>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>LOW</h6>
								</Col>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>MEDIUM</h6>
								</Col>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>HIGH</h6>
								</Col>
							</Row>
						</div>
						<div className="pt-2">
							<Row>
								<Col className="text-nowrap">
									<h6>Foot Pedal Haptic Feedback</h6>
								</Col>
							</Row>
							<Row>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
										this.props.message.haptic==0
										? <Progress bar color="yellow" value="100" />
										: (
											<Progress bar color="yellow" value="0" />
										)
									}
									</Progress>
								</Col>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
										this.props.message.haptic==1
										? <Progress bar color="yellow" value="100" />
										: (
											<Progress bar color="yellow" value="0" />
										)
									}
									</Progress>
								</Col>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
										this.props.message.haptic==2
										? <Progress bar color="yellow" value="100" />
										: (
											<Progress bar color="yellow" value="0" />
										)
									}
									</Progress>
								</Col>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
										this.props.message.haptic==3
										? <Progress bar color="yellow" value="100" />
										: (
											<Progress bar color="yellow" value="0" />
										)
									}
									</Progress>
								</Col>
							</Row>
							<Row>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>OFF</h6>
								</Col>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>LOW</h6>
								</Col>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>MEDIUM</h6>
								</Col>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>HIGH</h6>
								</Col>
							</Row>
						</div>
						<div className="pt-2">
							<Row>
								<Col className="text-nowrap">
									<h6>PBM Haptic Feedback</h6>
								</Col>
							</Row>
							<Row>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
										this.props.message.pbm_motor==0
										? <Progress bar color="yellow" value="100" />
										: (
											<Progress bar color="yellow" value="0" />
										)
									}
									</Progress>
								</Col>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
										this.props.message.pbm_motor==1
										? <Progress bar color="yellow" value="100" />
										: (
											<Progress bar color="yellow" value="0" />
										)
									}
									</Progress>
								</Col>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
										this.props.message.pbm_motor==2
										? <Progress bar color="yellow" value="100" />
										: (
											<Progress bar color="yellow" value="0" />
										)
									}
									</Progress>
								</Col>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
										this.props.message.pbm_motor==3
										? <Progress bar color="yellow" value="100" />
										: (
											<Progress bar color="yellow" value="0" />
										)
									}
									</Progress>
								</Col>
							</Row>
							<Row>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>OFF</h6>
								</Col>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>LOW</h6>
								</Col>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>MEDIUM</h6>
								</Col>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>HIGH</h6>
								</Col>
							</Row>
						</div>
						<div className="pt-2">
							<Row>
								<Col className="text-nowrap">
									<h6>Tip Illumination</h6>
								</Col>
							</Row>
							<Row>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
										this.props.message.tip==0
										? <Progress bar color="yellow" value="100" />
										: (
											<Progress bar color="yellow" value="0" />
										)
									}
									</Progress>
								</Col>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
										this.props.message.tip==1
										? <Progress bar color="yellow" value="100" />
										: (
											<Progress bar color="yellow" value="0" />
										)
									}
									</Progress>
								</Col>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
										this.props.message.tip==2
										? <Progress bar color="yellow" value="100" />
										: (
											<Progress bar color="yellow" value="0" />
										)
									}
									</Progress>
								</Col>
								<Col xs="3" md="3" lg="3">
									<Progress multi className="mt-1 mb-1">
									{
										this.props.message.tip==3
										? <Progress bar color="yellow" value="100" />
										: (
											<Progress bar color="yellow" value="0" />
										)
									}
									</Progress>
								</Col>
							</Row>
							<Row>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>OFF</h6>
								</Col>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>LOW</h6>
								</Col>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>MEDIUM</h6>
								</Col>
								<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
									<h6>HIGH</h6>
								</Col>
							</Row>
						</div>
						</Fragment>
					}
					{!this.props.isReceivingMessages &&
						<Fragment>
						{this.state.currentUser && this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']==2 && this.props.historyMessage ? (
							<Fragment>
							<div className="pt-2">
								<Row>
									<Col className="text-nowrap">
										<h6>Sound</h6>
									</Col>
								</Row>
								<Row>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
						          this.props.historyMessage.sound==0
						          ? <Progress bar color="yellow" value="100" />
						          : (
						            <Progress bar color="yellow" value="0" />
						          )
						        }
										</Progress>
									</Col>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
											this.props.historyMessage.sound==1
											? <Progress bar color="yellow" value="100" />
											: (
												<Progress bar color="yellow" value="0" />
											)
										}
										</Progress>
									</Col>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
											this.props.historyMessage.sound==2
											? <Progress bar color="yellow" value="100" />
											: (
												<Progress bar color="yellow" value="0" />
											)
										}								</Progress>
									</Col>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
											this.props.historyMessage.sound==3
											? <Progress bar color="yellow" value="100" />
											: (
												<Progress bar color="yellow" value="0" />
											)
										}								</Progress>
									</Col>
								</Row>
								<Row>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>OFF</h6>
									</Col>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>LOW</h6>
									</Col>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>MEDIUM</h6>
									</Col>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>HIGH</h6>
									</Col>
								</Row>
							</div>
							<div className="pt-2">
								<Row>
									<Col className="text-nowrap">
										<h6>Aiming Light</h6>
									</Col>
								</Row>
								<Row>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
											this.props.historyMessage.aiming==0
											? <Progress bar color="yellow" value="100" />
											: (
												<Progress bar color="yellow" value="0" />
											)
										}
										</Progress>
									</Col>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
											this.props.historyMessage.aiming==1
											? <Progress bar color="yellow" value="100" />
											: (
												<Progress bar color="yellow" value="0" />
											)
										}
										</Progress>
									</Col>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
											this.props.historyMessage.aiming==2
											? <Progress bar color="yellow" value="100" />
											: (
												<Progress bar color="yellow" value="0" />
											)
										}
										</Progress>
									</Col>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
											this.props.historyMessage.aiming==3
											? <Progress bar color="yellow" value="100" />
											: (
												<Progress bar color="yellow" value="0" />
											)
										}
										</Progress>
									</Col>
								</Row>
								<Row>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>OFF</h6>
									</Col>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>LOW</h6>
									</Col>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>MEDIUM</h6>
									</Col>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>HIGH</h6>
									</Col>
								</Row>
							</div>
							<div className="pt-2">
								<Row>
									<Col className="text-nowrap">
										<h6>Foot Pedal Haptic Feedback</h6>
									</Col>
								</Row>
								<Row>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
											this.props.historyMessage.haptic==0
											? <Progress bar color="yellow" value="100" />
											: (
												<Progress bar color="yellow" value="0" />
											)
										}
										</Progress>
									</Col>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
											this.props.historyMessage.haptic==1
											? <Progress bar color="yellow" value="100" />
											: (
												<Progress bar color="yellow" value="0" />
											)
										}
										</Progress>
									</Col>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
											this.props.historyMessage.haptic==2
											? <Progress bar color="yellow" value="100" />
											: (
												<Progress bar color="yellow" value="0" />
											)
										}
										</Progress>
									</Col>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
											this.props.historyMessage.haptic==3
											? <Progress bar color="yellow" value="100" />
											: (
												<Progress bar color="yellow" value="0" />
											)
										}
										</Progress>
									</Col>
								</Row>
								<Row>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>OFF</h6>
									</Col>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>LOW</h6>
									</Col>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>MEDIUM</h6>
									</Col>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>HIGH</h6>
									</Col>
								</Row>
							</div>
							<div className="pt-2">
								<Row>
									<Col className="text-nowrap">
										<h6>PBM Haptic Feedback</h6>
									</Col>
								</Row>
								<Row>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
											this.props.historyMessage.pbm_motor==0
											? <Progress bar color="yellow" value="100" />
											: (
												<Progress bar color="yellow" value="0" />
											)
										}
										</Progress>
									</Col>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
											this.props.historyMessage.pbm_motor==1
											? <Progress bar color="yellow" value="100" />
											: (
												<Progress bar color="yellow" value="0" />
											)
										}
										</Progress>
									</Col>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
											this.props.historyMessage.pbm_motor==2
											? <Progress bar color="yellow" value="100" />
											: (
												<Progress bar color="yellow" value="0" />
											)
										}
										</Progress>
									</Col>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
											this.props.historyMessage.pbm_motor==3
											? <Progress bar color="yellow" value="100" />
											: (
												<Progress bar color="yellow" value="0" />
											)
										}
										</Progress>
									</Col>
								</Row>
								<Row>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>OFF</h6>
									</Col>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>LOW</h6>
									</Col>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>MEDIUM</h6>
									</Col>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>HIGH</h6>
									</Col>
								</Row>
							</div>
							<div className="pt-2">
								<Row>
									<Col className="text-nowrap">
										<h6>Tip Illumination</h6>
									</Col>
								</Row>
								<Row>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
											this.props.historyMessage.tip==0
											? <Progress bar color="yellow" value="100" />
											: (
												<Progress bar color="yellow" value="0" />
											)
										}
										</Progress>
									</Col>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
											this.props.historyMessage.tip==1
											? <Progress bar color="yellow" value="100" />
											: (
												<Progress bar color="yellow" value="0" />
											)
										}
										</Progress>
									</Col>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
											this.props.historyMessage.tip==2
											? <Progress bar color="yellow" value="100" />
											: (
												<Progress bar color="yellow" value="0" />
											)
										}
										</Progress>
									</Col>
									<Col xs="3" md="3" lg="3">
										<Progress multi className="mt-1 mb-1">
										{
											this.props.historyMessage.tip==3
											? <Progress bar color="yellow" value="100" />
											: (
												<Progress bar color="yellow" value="0" />
											)
										}
										</Progress>
									</Col>
								</Row>
								<Row>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>OFF</h6>
									</Col>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>LOW</h6>
									</Col>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>MEDIUM</h6>
									</Col>
									<Col xs="3" md="3" lg="3" className="text-center led-progress-label text-nowrap">
										<h6>HIGH</h6>
									</Col>
								</Row>
							</div>
							</Fragment>
						) : (
							<div className="mt-5 text-center">
								<h5>Device {this.props.currentDevice} is Currently not Connected.</h5>
							</div>
						)}
						</Fragment>
					}
				</CardBody>
			</Card>

		);
	}
}

export default VisualAudioStatus;
