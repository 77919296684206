import React from "react";
import { Component, Fragment } from "react";
import DropIn from "braintree-web-drop-in-react";
import Moment from 'moment';
import Loader from 'react-loader-spinner'
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
	Card,
	CardBody,
	CardTitle,
	CardFooter,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Fade
} from 'reactstrap';

import { authenticationService } from '../../../jwt/_services';
import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import warranty from '../../../assets/images/warranty/warranty.png';
import warrantyPDF from '../../../assets/pdf/warranty/warranty.pdf';
import warrantyPDF2 from '../../../assets/pdf/warranty/warranty_2.pdf';
import warrantyPDF3 from '../../../assets/pdf/warranty/warranty_3.pdf';
import logoEvo from '../../../assets/images/logo2_x3_text.png';
import warrantyPart1 from '../../../assets/images/warranty/warranty_part1.png';
import warrantyPart2 from '../../../assets/images/warranty/warranty_part2.png';

import factoryWarrantyTop from '../../../assets/images/warranty/factory_top.png';
import warranty2Top from '../../../assets/images/warranty/warranty2_top.png';
import warranty3Top from '../../../assets/images/warranty/warranty3_top.png';
import warrantyBottom from '../../../assets/images/warranty/warranty_bottom.png';
import extendedWarrantyBottom from '../../../assets/images/warranty/extended_warranty_bottom.png';

import {
  Elements,
  ElementsConsumer,
	PaymentElement,
  CardElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

//Test
const stripePromise_dev = loadStripe("pk_test_51KUJzRGInnNpESco7cSFpEhK2O0uOw5ZLBNwEvQTSp2UEi3GrVo7xmpmXEmpDCfFC0HhD1B8WhEWKwak9NePqEFp005KLwhir3");

//Production
const stripePromise = loadStripe("pk_live_51KUJzRGInnNpESco77LqgWZl5nradYhGW05VR5KSSpvUYGLwkcmkheT6VYZINuGeJOKjDapz0A0O9vWOR6K8OpWd007bBhH7u4");

// Create styles
const styles = StyleSheet.create({
  page: { backgroundColor: '#000000' },
  section: { color: 'white', textAlign: 'center', margin: 30 },
  title: {
    fontSize: 20,
    marginVertical: 5,
    textAlign: 'center',
    color: '#f3c12b'
  },
  TableCellHeaderStyle : {
    padding: 5,
    textAlign: "center",
    fontSize: 14,
    fontWeight: 900,
    backgroundColor: '#ffffff'
  },
  TableCellBodyStyle : {
    padding: 5,
    textAlign: "center",
    fontSize: 11,
    backgroundColor: '#f3c12b'
  },
  TableStyle : {
    padding: 5,
  },
  Table: {
    marginVertical: 5,
    marginHorizontal: 5,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 0,
  },
});

const warrantyStyles = StyleSheet.create({
  page: { backgroundColor: '#000000' },
  section: { color: 'white', textAlign: 'center', margin: 30 },
  title: {
    fontSize: 14,
    marginVertical: 2,
    textAlign: 'center',
    color: '#000000',
    marginHorizontal: 200,
		backgroundColor: '#f3c12b',
		height: 25,
		paddingTop: 5,
  },
  TableCellHeaderStyle : {
    padding: 5,
    textAlign: "center",
    fontSize: 14,
    fontWeight: 900,
    backgroundColor: '#ffffff'
  },
  TableCellBodyStyle : {
    padding: 5,
    textAlign: "center",
    fontSize: 11,
    backgroundColor: '#f3c12b'
  },
  TableStyle : {
    padding: 5,
  },
  Table: {
    marginVertical: 5,
    marginHorizontal: 5,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 0,
  },
});

const baseStripeElementOptions = {
	style: {
			base: {
					fontFamily: 'Oxanium',
					fontSize: '16px',
							color: '#000000',
							'::placeholder': {
							color: '#000000',
					},
			},
			invalid: {
					color: '#9e2146',
			},
	}
}

const CARD_OPTIONS = {
	iconStyle: "solid",
	style: {
		base: {
			iconColor: "black",
			color: "#black",
			fontWeight: 500,
			fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
			fontSize: "16px",
			fontSmoothing: "antialiased",
			":-webkit-autofill": {
				color: "#007aff"
			},
			"::placeholder": {
				color: "#2b2f38",
			}
		},
		invalid: {
			iconColor: "#e31738",
			color: "#e31738"
		}
	}
};

const cardElementStyle = {
	border: "1px solid currentColor",
	borderColor: "black",
  borderRadius: "4px",
  background: "#fff",
  marginBottom: "20px",
  paddingTop: "8px",
	paddingBottom: "8px",
	paddingLeft: "4px",
	paddingRight: "4px",
}

var paymentMethod = {};

class PaymentForm extends React.Component {

	constructor(props) {
    super(props);

    // This binding is necessary to make `this` work in the callback
    //this.handleStripeSubmit = this.handleStripeSubmit.bind(this);
  }

	confirmPayment = async (paymentMethod) => {
		console.log('confirmPayment paymentMethod: ', paymentMethod);
		//this.props.completeStripePayment(paymentMethod);
	}

  handleStripeSubmit = async (event) => {

		event.preventDefault();

    const { elements, stripe } = this.props;
    const cardElement = elements.getElement(CardElement);

    // const {error, paymentMethod} = await stripe.confirmPayment({
    //   type: 'card',
    //   card: cardElement,
    // });
		//
    // if (error) {
    //   console.log('[error]', error);
    // } else {
    //   console.log('[PaymentMethod]', paymentMethod);
    //   // ... SEND to your API server to process payment intent
    // }

		console.log('PaymentForm email: ', this.props.email);
		var email = "";

		if (this.props.email) {
			email = this.props.email;
		}

		const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
			billing_details: {
	      email: email,
	    },
    });

    if (error) {
      console.log('[error]', error);

			toast.error(error.message, {
				position: "top-center",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
				closeButton: false
			});

    } else {
      console.log('[PaymentMethod]', paymentMethod);
      // ... SEND to your API server to process payment intent
			//this.confirmPayment(paymentMethod);
			this.props.completeStripePayment(paymentMethod);
    }

		// stripe.createPaymentMethod({
		// 	type: 'card',
		// 	card: cardElement,
		// })
		// .then(function(result) {
		// 	// Handle result.error or result.paymentMethod
		// 	console.log('[PaymentMethod]: ', result.paymentMethod);
		// 	paymentMethod = result.paymentMethod;
		//
		// 	this.confirmPayment();
		// 	//this.props.completeStripePayment(paymentMethod);
		// });

		// const result = await stripe.confirmPayment({
    //   //`Elements` instance that was used to create the Payment Element
    //   elements,
    //   confirmParams: {
    //     return_url: this.props.redirectPath,
    //   },
		// 	redirect: "if_required"
    // });
		//
    // if (result.error) {
    //   // Show error to your customer (for example, payment details incomplete)
    //   console.log("Stripe payment error: "+result.error.message);
    // } else {
    //   // Your customer will be redirected to your `return_url`. For some payment
    //   // methods like iDEAL, your customer will be redirected to an intermediate
    //   // site first to authorize the payment, then redirected to the `return_url`.
		//
		// 	console.log("Stripe payment success: "+JSON.stringify(result));
    // }

		// console.log('PaymentForm clientSecret: '+this.props.stripeOptions.clientSecret);
		//
		// // Retrieve the PaymentIntent
    // stripe
    //   .retrievePaymentIntent(this.props.stripeOptions.clientSecret)
    //   .then(({paymentIntent}) => {
    //     // Inspect the PaymentIntent `status` to indicate the status of the payment
    //     // to your customer.
    //     //
    //     // Some payment methods will [immediately succeed or fail][0] upon
    //     // confirmation, while others will first enter a `processing` state.
    //     //
    //     // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
		//
		// 		console.log('PaymentForm paymentIntent: '+JSON.stringify(paymentIntent));
		//
    //     switch (paymentIntent.status) {
    //       case 'succeeded':
    //         console.log('PaymentForm Success! Payment received.');
    //         break;
		//
    //       case 'processing':
    //         console.log("PaymentForm Payment processing. We'll update you when payment is received.");
    //         break;
		//
    //       case 'requires_payment_method':
    //         // Redirect your user back to your payment page to attempt collecting
    //         // payment again
    //         console.log('PaymentForm Payment failed. Please try another payment method.');
    //         break;
		//
    //       default:
    //         console.log('PaymentForm Something went wrong.');
    //         break;
    //     }
    //   });
  };

  render() {
    return (
      <>
				<form onSubmit={this.handleStripeSubmit.bind(this)}>
					<div className="mt-4 mb-4" style={cardElementStyle}>
	        	<CardElement options={CARD_OPTIONS}/>
					</div>
					<Button className="mr-2" color="secondary" onClick={() => {this.props.backToWarrantyOptions()}}>BACK</Button>
	        <Button color="brightgreen" disabled={!this.props.stripe}>CONFIRM PURCHASE</Button>
	      </form>
      </>
    );
  }
}

class GenFactoryWarranty extends React.Component {

  async componentDidMount() {

    console.log("MyDocument procedure data: " + JSON.stringify(this.props.serialNumber));
	}

  render() {
         return (
					 <Document>
						 <Page size="A4" style={warrantyStyles.page}>
							 <Image style={warrantyStyles.image} src={factoryWarrantyTop} />
							 <Text style={warrantyStyles.title}>SERIAL NUMBER: {this.props.serialNumber}</Text>
							 <Image style={warrantyStyles.image} src={warrantyBottom} />
						 </Page>
					 </Document>
     )}
}

class GenExtendedWarranty extends React.Component {

  async componentDidMount() {

    console.log("MyDocument procedure data: " + JSON.stringify(this.props.serialNumber));
	}

  render() {
         return (
					 <Fragment>
					 {this.props.warrantyLength==2 &&
						 <Fragment>
						 <Document>
	             <Page size="A4" style={warrantyStyles.page}>
	               <Image style={warrantyStyles.image} src={warranty2Top} />
	               <Text style={warrantyStyles.title}>SERIAL NUMBER: {this.props.serialNumber}</Text>
								 <Image style={warrantyStyles.image} src={extendedWarrantyBottom} />
	             </Page>
	           </Document>
						 </Fragment>
					 }
					 {this.props.warrantyLength==3 &&
						 <Fragment>
						 <Document>
	             <Page size="A4" style={warrantyStyles.page}>
	               <Image style={warrantyStyles.image} src={warranty3Top} />
	               <Text style={warrantyStyles.title}>SERIAL NUMBER: {this.props.serialNumber}</Text>
								 <Image style={warrantyStyles.image} src={extendedWarrantyBottom} />
	             </Page>
	           </Document>
						 </Fragment>
					 }
					 </Fragment>
     )}
}


class ExtendedWarranty extends React.Component {

	instance;
	noWarranty = true;
	warrantyTermDaysTotal = 0;
	warrantyLengthDays = 0;
	factoryWarrantyLengthDays = 0;
	factoryWarrantyDaysLeft = 0;
	factoryWarrantyDaysTotal = 0;
	extendedWarrantyDaysLeft = 0;
	daysLeft = 0;
	intervalID = 0;

	constructor(props) {

    super(props);
    this.state = {
			rSelected: 3,
			totalProcedures: 0,
			currentUser: this.props.currentUser,
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			currentUserEmail: JSON.parse(localStorage.getItem('currentUserEmail')),
			currentDevice: this.props.currentDevice,
			device: {},
			modal: false,
			modal2: false,
			modal3: false,
			statusSubject: "Failed",
			statusMessage: "Failed to send message.",
			clientToken: null,
			isReadyToPay: false,
			purchasePending: false,
			warrantyPurchaseCompleted: false,
			showPurchaseWarranty: false,
			currentWarrentyOption: 0,
			currentWarrantyAmount: 0,
			factoryWarrantyStartsOnDate: "",
			factoryWarrantyEndsOnDate: "",
			warrantyStartsOnDate: "",
			warrantyEndsOnDate: "",
			hasShipmentDate: false,
			stripeData: {},
			stripeOptions: {},
			paymentMethodID: ""
	  };

		this.toggle = this.toggle.bind(this);
		this.toggle1 = this.toggle1.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		this.toggle3 = this.toggle3.bind(this);
		this.getLatestDeviceList = this.getLatestDeviceList.bind(this);
		this.backToWarrantyOptions = this.backToWarrantyOptions.bind(this);
  }

	async componentDidMount() {
		//this.state.deviceList = JSON.parse(localStorage.getItem('deviceList'));
		console.log("MY deviceList: "+JSON.stringify(this.state.deviceList));
		//console.log("MY jwtToken: "+JSON.stringify(this.state.currentUser.jwtToken));

		//const date = new Date(JSON.stringify(this.state.device.WarrantyStartDate));
		//this.setState({purchasePending: true});
		this.checkIfWarrantyExists();
		//this.getBrainTreeToken();
		this.getLatestDeviceList();

		this.intervalID = setInterval(this.getLatestDeviceList, 10000);
	}

	componentWillUnmount() {
		clearInterval(this.intervalID);
	}

	sendWarrantyPurchasedEmail = async () => {
		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-warranty-purchased-email";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("send-warranty-purchased-email response: " + JSON.stringify(body));

		if (body.status == 'Success') {

		}
	}

	completeStripePayment = async (paymentMethod) => {

		console.log("completeStripePayment: "+JSON.stringify(paymentMethod));

		this.setState({purchasePending: true});

		try {

			//LIVE Production
			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-payment-intent?WarrantyID="+this.state.currentWarrentyOption+"&SerialNumber="+this.props.currentDevice+"&PaymentMethod="+paymentMethod.id;

			//TEST Sandbox
			//var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-payment-intent-test?WarrantyID="+this.state.currentWarrentyOption+"&SerialNumber="+this.props.currentDevice+"&PaymentMethod="+paymentMethod.id;

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Stripe call response: "+JSON.stringify(body));

			if (body.status === 'succeeded') {

					toast.success("Warranty Purchase Successful!", {
						position: "top-center",
						autoClose: 2000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "dark",
						closeButton: false
					});
					console.log('warranty response info: ' + JSON.stringify(body));
					this.setState({purchasePending: false});
					this.setState({warrantyPurchaseCompleted: true});
					console.log('warrantyPurchaseCompleted: true');
					this.sendWarrantyPurchasedEmail();
					this.sendWarrantyEmailMessage(this.state.currentWarrentyOption);
					this.getLatestDeviceList();

			} else if (body.status === 'Error') {
					console.log('error info: ' + body.response);
					this.setState({purchasePending: false});

					if (body.response.raw) {
						toast.error(body.response.raw.message, {
							position: "top-center",
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "dark",
							closeButton: false
						});
					} else {
						toast.error(body.response, {
							position: "top-center",
							autoClose: 3000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "dark",
							closeButton: false
						});
					}


			} else {
				console.log('status info: ' + body.status);
				this.setState({purchasePending: false});
			}

		}
		catch (err) {
			console.log(err);
		}

	}

	getBrainTreeToken = async () => {
		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"braintree-token";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

    const body = await response.json();
		console.log("MY token: "+body.response);
		console.log("Current User: "+JSON.stringify(this.state.currentUser));

		if (body.status == 'Success') {
			this.setState({
	      clientToken: body.response
	    });
		}
	}

	sendWarrantyEmailMessage = async (warrantyLength) => {

		var currentDate = Moment().format('MM-DD-YYYY');

		var recipient = "team@geminievo.com";
		var subject = "Warranty Purchased";
		var message = "Warranty Purchased:\nSerial Number: "+this.props.currentDevice+"\nWarranty Length: "+warrantyLength+" years\nDate Purchased: "+currentDate;
		//var message = "Warranty Purchased: Serial Number: 00013 | Warranty Length: 2 years | Date Purchased: 10-10-2021";


		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-email-to-user?EmailRecipient="+recipient+"&EmailSubject="+subject+"&EmailMessage="+encodeURIComponent(message);

		console.log("sendWarrantyEmailMessage url: " + url);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("Send Text response: " + JSON.stringify(body));

		if (body.status == 'Success') {

			//this.toggle4();
			// this.setState({
			// 	statusSubject: "SUCCESS!",
			// 	statusMessage: "Email sent Successfully!"
			// })

		} else {

			//this.toggle4();
			// this.setState({
			// 	statusSubject: "FAILED!",
			// 	statusMessage: "Failed to send Email to "+recipient+"."
			// })

		}

		//this.toggle5();

	};

	getLatestDeviceList() {
		authenticationService.getDeviceList(this.props.currentUser.jwtToken)
		.then(
				deviceList => {

						let status = deviceList.status;
						console.log('status: ' + status);

						if (status === 'Success') {
								localStorage.setItem('deviceList', JSON.stringify(deviceList.response));
								console.log('warranty current divice info: ' + JSON.stringify(deviceList.response));
						} else if (status === 'Error') {
								console.log('error info: ' + deviceList.response);
						} else {
							console.log('status info: ' + status);
						}

						this.setState({deviceList: JSON.parse(localStorage.getItem('deviceList'))})

						//this.toggle();
						this.checkIfWarrantyExists();

				},
				error => {
						console.log('status info: ' + error);
				}
		);
	}

	checkIfWarrantyExists () {

		if (this.state.deviceList) {

			var thisDevice = this.state.deviceList.find(d => d.SerialNumber === this.state.currentDevice);
			console.log("thisDevice is: "+JSON.stringify(thisDevice));

			for ( var i = 0; i < this.state.deviceList.length; i++ ) {
				if (this.state.deviceList[i].SerialNumber == this.state.currentDevice) {

					console.log("device is: "+JSON.stringify(this.state.deviceList[i]));
					this.setState({device: this.state.deviceList[i]});

					if (this.state.deviceList[i].WarrantyLength!=0) {
						this.noWarranty = false;
					} else {
						this.noWarranty = true;
					}

					if (this.state.deviceList[i].shipment_date) {
						console.log("shipment_date is not null");
						this.state.hasShipmentDate = true;

						var shipment_date = Moment(this.state.deviceList[i].shipment_date).format('MM/DD/YYYY');
						console.log("shipment_date: "+shipment_date);
						this.setState({factoryWarrantyStartsOnDate: shipment_date});

						var factoryExpiration = Moment(this.state.deviceList[i].shipment_date).add(2, 'years');
						var factoryEndDate = Moment(this.state.deviceList[i].shipment_date).add(2, 'years').format('MMM Do YYYY');
						this.setState({factoryWarrantyEndsOnDate: factoryEndDate});

						var warrantyStartDate = Moment(factoryExpiration).add(1, 'days').format('MMM Do YYYY');
						var warrantyStartDateMoment = Moment(factoryExpiration).add(1, 'days');

						console.log("componentDidMount WarrantyStartDate: "+warrantyStartDate);
						this.setState({warrantyStartsOnDate: warrantyStartDate});

						var current = Moment().endOf('day');
						this.factoryWarrantyDaysLeft = Moment.duration(factoryExpiration.diff(current)).asDays();
						this.factoryWarrantyDaysLeft = this.factoryWarrantyDaysLeft.toFixed(0);
						console.log("factoryWarrantyDaysLeft: "+this.factoryWarrantyDaysLeft);
						this.factoryWarrantyDaysTotal = Moment.duration(factoryExpiration.diff(this.state.deviceList[i].shipment_date)).asDays();
						this.factoryWarrantyDaysTotal = this.factoryWarrantyDaysTotal.toFixed(0);
						console.log("factoryWarrantyDaysTotal: "+this.factoryWarrantyDaysTotal);

						var warrantyTermLength = this.state.deviceList[i].WarrantyLength;

						var extendedExpiration = Moment(warrantyStartDateMoment).add(warrantyTermLength, 'years');
						var extendedExpirationFormat = Moment(warrantyStartDateMoment).add(warrantyTermLength, 'years').format('MMM Do YYYY');
						this.setState({warrantyEndsOnDate: extendedExpirationFormat});
						this.warrantyTermDaysTotal = Moment.duration(extendedExpiration.diff(warrantyStartDateMoment)).asDays();
						this.warrantyTermDaysTotal = this.warrantyTermDaysTotal.toFixed(0);
						console.log("warrantyLengthDays: "+this.warrantyTermDaysTotal);

						//this.warrantyTermDaysTotal = warrantyTermLength*365;
						var test = Moment.duration(extendedExpiration.diff(warrantyStartDateMoment)).asDays();
						this.factoryWarrantyLengthDays = (this.factoryWarrantyDaysLeft/(2*365))*100;
						console.log("MY warrantyLengthDays: "+this.warrantyLengthDays);

						if (this.factoryWarrantyDaysLeft > 0) {
							this.extendedWarrantyDaysLeft = this.warrantyTermDaysTotal;
						} else {
							this.extendedWarrantyDaysLeft = Moment.duration(warrantyStartDateMoment.diff(current)).asDays();
						}
						this.warrantyLengthDays = (this.extendedWarrantyDaysLeft/this.warrantyTermDaysTotal)*100;
					} else {
						console.log("shipment_date is  null");
						this.state.hasShipmentDate = false;
					}
				}
			}
		}
	}

	async buy() {
    // Send the nonce to your server
    const { nonce } = await this.instance.requestPaymentMethod();

		this.setState({purchasePending: true});

		authenticationService.purchaseWarranty(
			nonce,
			this.state.currentWarrantyAmount,
			this.state.currentWarrentyOption,
			this.state.device.UID,
			this.state.device.SerialNumber)
		.then(
				warrantyResponse => {

						let status = warrantyResponse.status;
						console.log('status: ' + status);
						console.log('warrantyResponse: ' + JSON.stringify(warrantyResponse));

						if (status === 'Success') {
								console.log('warranty response info: ' + JSON.stringify(warrantyResponse));
								this.setState({purchasePending: false});
								this.setState({warrantyPurchaseCompleted: true});
								console.log('warrantyPurchaseCompleted: true');
								this.sendWarrantyEmailMessage(this.state.currentWarrentyOption);

								this.getLatestDeviceList();

						} else if (status === 'Error') {
								console.log('error info: ' + warrantyResponse.response);
						} else {
							console.log('status info: ' + status);
						}
				},
				error => {
						console.log('status info: ' + error);
				}
		);

  }

	toggle() {
		console.log('toggle clicked');
	  this.setState({
	    showPurchaseWarranty: !this.state.showPurchaseWarranty
	  });

		if (this.state.isReadyToPay) {
			this.setState({
		    isReadyToPay: !this.state.isReadyToPay
		  });
		}
	}

	toggle1() {
		console.log('toggle1 clicked');
		this.setState({
	    modal: !this.state.modal
	  });
	}

	toggle2() {
		console.log('toggle2 clicked');
		this.setState({
	    modal2: !this.state.modal2
	  });
	}

	toggle3() {
		console.log('toggle3 clicked');
		this.setState({
	    modal3: !this.state.modal3
	  });
	}

	backToWarrantyOptions() {
		if (this.state.isReadyToPay) {
			this.setState({
		    isReadyToPay: !this.state.isReadyToPay
		  });
		}
	}

	payOptionSelected = async warrantyOption => {

		if (warrantyOption == 2) {
			this.setState({currentWarrentyOption: warrantyOption});
			this.setState({currentWarrantyAmount: "1200.00"})
		} else if (warrantyOption == 3) {
			this.setState({currentWarrentyOption: warrantyOption});
			this.setState({currentWarrantyAmount: "1500.00"})
		}

		console.log("Selected warrantyOption is: " + JSON.stringify(this.state.warrantyOption));
		this.setState({ isReadyToPay: true })

	}

	sendEmailRequest = async () => {

		var firstName = this.props.currentUser.payload.given_name;
		var lastName = this.props.currentUser.payload.family_name;
		var emailAddress = this.props.currentUser.payload.email;

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-request-shipment-date-email?FirstName="+firstName+"&LastName="+lastName+"&EmailAddress="+emailAddress;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("Send Text response: " + JSON.stringify(body.results));

		if (body.status == 'Success') {

			this.toggle1();
			this.setState({
				statusSubject: "SUCCESS!",
				statusMessage: "Shipment date request sent Successfully!"
			})

		} else {

			this.toggle1();
			this.setState({
				statusSubject: "FAILED!",
				statusMessage: "Failed to send shipment date request. Please contact support."
			})

		}

	};

	render() {

		const style = {
        borderColor: "#28a745",
        boxShadow: "0 0 0 0.2rem rgba(40, 167, 69, 0.25)"
    };

		var redirectPath = "http://localhost:3000/customize/"+this.state.currentDevice;

		// const timeElapsed = Date.now();
		// const today = new Date(timeElapsed);
		// var purchaseDate = today.toDateString();
		//purchaseDate = purchaseDate.toDateString();

		var canPurchaseWarranty = false;

		if (this.state.deviceList) {

			var thisDevice = this.state.deviceList.find(d => d.SerialNumber === this.state.currentDevice);
			console.log("thisDevice is: "+JSON.stringify(thisDevice));

			if (thisDevice) {
				if (thisDevice.Kit=="US") {
					canPurchaseWarranty = true;
				}
			}

		}

		return (
      <Card className="card-hover">
				{!this.state.showPurchaseWarranty &&
					<Fade in="true">
					<CardBody style={{ minHeight: this.props.height }}>
						<Row className="pb-1">
							<Col xs="6" md="6" lg="6" className="text-left">
								<div className="float-left"><CardTitle>Warranty</CardTitle></div>
								<div className="float-left"><i style={{color: '#b3b5bb', lineHeight: "0.8"}} className="mdi mdi-certificate mdi-36px ml-1"/></div>
							</Col>
							<Col xs="6" md="6" lg="6" className="text-right">
							{!this.state.hasShipmentDate ? (
								<Fragment>
								<Button
									size="sm"
									style={{width: '150px'}}
									color="dark"
									onClick={() => {this.sendEmailRequest()}}
								>
									Request Shipment Date
								</Button>
								</Fragment>
							) : (
								<Fragment>
									<h4 style={{ color: "#f3c12b"}}>Shipped: {this.state.factoryWarrantyStartsOnDate}</h4>
								</Fragment>
							)}
							</Col>
						</Row>
						<div className="pb-3">
							{this.state.hasShipmentDate ? (
								<Fragment>
								<Row className="mt-3">
									<Col className="text-center">
										<span>Your orignal factory Warranty is valid for 24 months from Shipment Date</span>
									</Col>
								</Row>
								<Row className="">
									<Col className="text-center mt-1">
										<h4 style={{ color: "#f3c12b"}}>Expires in {this.factoryWarrantyDaysLeft} days</h4>
									</Col>
								</Row>
								<Row className="mt-3">
									<Col xs="1" md="1" lg="1">
									</Col>
									<Col xs="10" md="10" lg="10">
										<div className="">
											<Button
												size="md"
												style={{width: '100%'}}
												color="primary"
												onClick={() => {this.toggle2()}}
											>
												Print Factory Warranty Certificate
											</Button>
										</div>
									</Col>
									<Col xs="1" md="1" lg="1">
									</Col>
								</Row>
								{
									// <Row className="mt-2">
									// 	<Col xs="6" md="6" lg="6" className="text-left">
									// 		<span>Days Left</span>
									// 	</Col>
									// 	<Col xs="6" md="6" lg="6" className="text-right">
									// 		<span>{this.factoryWarrantyDaysLeft} of {this.factoryWarrantyDaysTotal} Days</span>
									// 	</Col>
									// </Row>
									// <Progress multi className="mt-2">
									// 	<Progress bar color="yellow" value={this.factoryWarrantyLengthDays} />
									// </Progress>
								}
								</Fragment>
							) : (
								<Fragment>
								<Row className="mt-5">
									<Col className="text-center">
										<span>Your orignal factory Warranty is valid for 24 months from Shipment Date</span>
									</Col>
								</Row>
								<Row className="mt-3">
									<Col xs="1" md="1" lg="1">
									</Col>
									<Col xs="10" md="10" lg="10">
										<div className="">
											<Button
												size="md"
												style={{width: '100%'}}
												color="primary"
												onClick={() => {this.toggle2()}}
											>
												Print Warranty Certificate
											</Button>
										</div>
									</Col>
									<Col xs="1" md="1" lg="1">
									</Col>
								</Row>
								</Fragment>
							)}
							{!this.noWarranty && canPurchaseWarranty &&
								<Fragment>
								<Row className="pt-5 pb-1">
									<Col xs="6" md="6" lg="6" className="text-left">
										<div className="float-left"><CardTitle>Extended Warranty</CardTitle></div>
									</Col>
								</Row>
								{this.state.hasShipmentDate ? (
									<Fragment>
									<Row className="mt-1">
										<Col className="text-center">
											{this.noWarranty ? (
												<span>Device is not under Extended Warranty</span>
											) : (
												<span>Your extended warranty is valid for {this.state.device.WarrantyLength*12} months after factory warranty expires.</span>
											)}
										</Col>
									</Row>
									<Row className="mt-1">
										<Col className="text-center">
											<h4 style={{ color: "#f3c12b"}}>Expires in {parseInt(this.factoryWarrantyDaysLeft)+parseInt(this.extendedWarrantyDaysLeft)} days</h4>
										</Col>
									</Row>
									<Row className="mt-3">
										<Col xs="1" md="1" lg="1">
										</Col>
										<Col xs="10" md="10" lg="10">
											<div className="">
												<Button
													size="md"
													style={{width: '100%'}}
													color="primary"
													onClick={() => {this.toggle3()}}
												>
													Print Extended Warranty Certificate
												</Button>
											</div>
										</Col>
										<Col xs="1" md="1" lg="1">
										</Col>
									</Row>
									{
										// <Row className="mt-2">
										// 	<Col xs="6" md="6" lg="6" className="text-left">
										// 		<span>Days Left</span>
										// 	</Col>
										// 	<Col xs="6" md="6" lg="6" className="text-right">
										// 		<span>{this.extendedWarrantyDaysLeft} of {this.warrantyTermDaysTotal} Days</span>
										// 	</Col>
										// </Row>
										// <Progress multi className="mt-2">
										// 	<Progress bar color="yellow" value={this.warrantyLengthDays} />
										// </Progress>
									}
									</Fragment>
								) : (
									<Fragment>
									<Row className="mt-3">
										<Col className="text-center">
											<span>Your extended warranty is valid for {this.state.device.WarrantyLength*12} months after factory warranty expires.</span>
										</Col>
									</Row>
									<Row className="mt-3">
										<Col xs="1" md="1" lg="1">
										</Col>
										<Col xs="10" md="10" lg="10">
											<div className="">
												<Button
													size="md"
													style={{width: '100%'}}
													color="primary"
													onClick={() => {this.toggle3()}}
												>
													Print Extended Warranty Certificate
												</Button>
											</div>
										</Col>
										<Col xs="1" md="1" lg="1">
										</Col>
									</Row>
									</Fragment>
								)}
								</Fragment>
							}
						</div>
						{this.noWarranty &&
							<Fragment>
							<Row className="pt-5 pb-1">
								<Col xs="6" md="6" lg="6" className="text-left">
									<div className="float-left"><CardTitle>Extended Warranty</CardTitle></div>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col xs="1" md="1" lg="1">
								</Col>
								<Col xs="10" md="10" lg="10">
									<div className="">
										<Button
											size="md"
											style={{width: '100%'}}
											color="brightgreen"
											onClick={() => {this.toggle()}}
										>
											Purchase Extended Warranty
										</Button>
									</div>
								</Col>
								<Col xs="1" md="1" lg="1">
								</Col>
							</Row>
							</Fragment>
						}
	        </CardBody>
					</Fade>
				}
				{this.state.showPurchaseWarranty &&
					<Fragment>
					{this.state.isReadyToPay &&
						<Fragment>
						{this.state.warrantyPurchaseCompleted ? (
							<Fragment>
							<Fade in="true">
							<CardBody style={{ minHeight: 420 }}>
								<Row className="mt-4">
									<Col xs="12" md="12" lg="12" className="text-left">
										<h3>Waranty Purchase Successful</h3>
									</Col>
								</Row>
								<Row className="mt-3">
									<Col xs="12" md="12" lg="12" className="text-left">
										<h6>An email has been sent to {this.state.currentUserEmail} with additional information about your warranty.</h6>
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<Button className="mr-2" color="secondary" onClick={() => {this.toggle()}}>OK</Button>
							</CardFooter>
							</Fade>
							</Fragment>
						) : (
							<Fragment>
							<Fade in="true">
							<CardBody style={{ minHeight: 420 }}>
								{this.state.currentWarrentyOption == 2 &&
									<Fragment>
									<Fade in="true">
									<Row>
										<Col xs="6" md="6" lg="6" className="text-left">
											<span>Warranty</span>
										</Col>
										<Col xs="6" md="6" lg="6" className="text-right">
											<span>Extended - 2 Additional years</span>
										</Col>
									</Row>
									<Row>
										<Col xs="6" md="6" lg="6" className="text-left">
											<span>Price</span>
										</Col>
										<Col xs="6" md="6" lg="6" className="text-right">
											<span>US$ 1,200.00</span>
										</Col>
									</Row>
									</Fade>
									</Fragment>
								}
								{this.state.currentWarrentyOption == 3 &&
									<Fragment>
									<Fade in="true">
									<Row>
										<Col xs="6" md="6" lg="6" className="text-left">
											<span>Warranty</span>
										</Col>
										<Col xs="6" md="6" lg="6" className="text-right">
											<span>Extended - 3 Additional years</span>
										</Col>
									</Row>
									<Row>
										<Col xs="6" md="6" lg="6" className="text-left">
											<span>Price</span>
										</Col>
										<Col xs="6" md="6" lg="6" className="text-right">
											<span>US$ 1,500.00</span>
										</Col>
									</Row>
									</Fade>
									</Fragment>
								}
								{this.state.purchasePending &&
									<Fade in="true">
									<Row className="justify-content-center">
										<Loader type="ThreeDots" color="#48b203" height={100} width={100}/>
									</Row>
									</Fade>
								}
								{!this.state.purchasePending && this.state.stripeOptions &&
									<div>
									<Fade in="true">
										<Elements stripe={process.env.REACT_APP_ENV=="/v1/" ? stripePromise : stripePromise_dev} options={this.state.stripeOptions}>
											<ElementsConsumer>
												{(ctx: any) => <PaymentForm {...ctx} email={this.state.currentUserEmail} stripeOptions={this.state.stripeOptions} completeStripePayment={this.completeStripePayment} backToWarrantyOptions={this.backToWarrantyOptions} redirectPath={redirectPath}/>}
											</ElementsConsumer>
										</Elements>
									</Fade>
									</div>
								}
							</CardBody>
							<CardFooter>
								{
									//<Button className="mr-2" color="secondary" onClick={() => {this.backToWarrantyOptions()}}>BACK</Button>
									//<Button color="brightgreen" onClick={this.buy.bind(this)}>CONFIRM PURCHASE</Button>
								}
							</CardFooter>
							</Fade>
							</Fragment>
						)}
						</Fragment>
					}
					{!this.state.isReadyToPay &&
						<Fragment>
						<Fade in="true">
						<CardBody style={{ minHeight: 420 }}>
						<Row>
							<Col xs="6" md="6" lg="6" className="text-left">
								<h5><b>2 years</b></h5>
							</Col>
							<Col xs="6" md="6" lg="6" className="text-right">
								<h5><b>US$ 1,200.00</b></h5>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col xs="12" md="12" lg="12" className="text-left">
								<Button style={{width: '100%'}} color="brightgreen" onClick={() => {this.payOptionSelected(2)}}>PURCHASE</Button>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col xs="12" md="12" lg="12" className="text-left">
								<p className="p-1">Extend the factory warranty on your Gemini laser by 2 years beyond the original expiration date.
								The same terms and conditions apply as the original factory warranty. See your Gemini User Manual for warranty details.</p>
							</Col>
						</Row>
						<Row>
							<Col xs="6" md="6" lg="6" className="text-left">
								<h5><b>3 years</b></h5>
							</Col>
							<Col xs="6" md="6" lg="6" className="text-right">
								<h5><b>US$ 1,500.00</b></h5>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col xs="12" md="12" lg="12" className="text-left">
								<Button style={{width: '100%'}} color="brightgreen" onClick={() => {this.payOptionSelected(3)}}>PURCHASE</Button>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col xs="12" md="12" lg="12" className="text-left">
								<p className="p-1">Extend the factory warranty on your Gemini laser by 3 years beyond the original expiration date.
								The same terms and conditions apply as the original factory warranty. See your Gemini User Manual for warranty details.</p>
							</Col>
						</Row>
						</CardBody>
						<CardFooter>
							<Button color="secondary" onClick={this.toggle}>Cancel</Button>
						</CardFooter>
						</Fade>
						</Fragment>
					}
					</Fragment>
				}
				<div>
				<Modal isOpen={this.state.modal} toggle={this.toggle1}>
  				<ModalHeader toggle={this.toggle1}>{this.state.statusSubject}</ModalHeader>
  				<ModalBody>
						<span>{this.state.statusMessage}</span>
  				</ModalBody>
					<ModalFooter>
						<Button color="primary" className="ml-1" onClick={this.toggle1}>OK</Button>
					</ModalFooter>
			  </Modal>
				</div>
				<div>
          <Modal isOpen={this.state.modal2} toggle={this.toggle2}>
    				<ModalHeader toggle={this.toggle2}>Factory Warranty Information</ModalHeader>
    				<ModalBody>
							<PDFViewer style={{ height: "730px", width: "100%"}}>
								<GenFactoryWarranty serialNumber={this.state.currentDevice} warrantyLength={this.state.device.WarrantyLength} />
							</PDFViewer>
    				</ModalBody>
  					<ModalFooter>
  						<Button color="primary" className="ml-1" onClick={this.toggle2}>OK</Button>
  					</ModalFooter>
  			  </Modal>
				</div>
				<div>
          <Modal isOpen={this.state.modal3} toggle={this.toggle3}>
    				<ModalHeader toggle={this.toggle3}>Extended Warranty Information</ModalHeader>
    				<ModalBody>
							<PDFViewer style={{ height: "730px", width: "100%"}}>
								<GenExtendedWarranty serialNumber={this.state.currentDevice} warrantyLength={this.state.device.WarrantyLength} />
							</PDFViewer>
    				</ModalBody>
  					<ModalFooter>
  						<Button color="primary" className="ml-1" onClick={this.toggle3}>OK</Button>
  					</ModalFooter>
  			  </Modal>
				</div>
				<ToastContainer/>
      </Card>
		);
	}
}

export default ExtendedWarranty;
