import React from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import { authenticationService } from '../../../jwt/_services';

class ResetPassword extends React.Component {

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 3,
			totalProcedures: 0,
			modal: false,
			currentUserEmail: JSON.parse(localStorage.getItem('currentUserEmail')),
	  };

		this.toggle = this.toggle.bind(this);
  }

	async componentDidMount() {

	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	resetPasswordCall = () => {
		const { currentUser } = this.state;
		authenticationService.forgotPassword(this.state.currentUserEmail)
		.then(
				response => {

						let status = response.status;
						console.log('status: ' + status);

						if (status === 'Success') {
								console.log('reset password response info: ' + response);
								this.toggle();
						} else if (status === 'Error') {
								console.log('error info: ' + response.response);
						} else {
							console.log('status info: ' + status);
						}
				},
				error => {
						console.log('status info: ' + error);
				}
		);

	}

	render() {
		return (
      <Card className="card-hover">
				<CardBody style={{ minHeight: 193 }}>
					<CardTitle>Change Password</CardTitle>
					<Row className="pb-4 pt-3">
						<Col xs="3" md="3" lg="3">
							<h6>Email:</h6>
						</Col>
						<Col xs="9" md="9" lg="9">
							<h6 style={{ color: '#414755' }}>{this.state.currentUserEmail}</h6>
						</Col>
					</Row>
					<Row className="text-center">
						<Col xs="1" md="1" lg="1">
						</Col>
						<Col xs="10" md="10" lg="10">
							<Button style={{width: '100%'}} color="dark" onClick={() => this.resetPasswordCall()}>
								Change Password
							</Button>
						</Col>
						<Col xs="1" md="1" lg="1">
						</Col>
					</Row>
        </CardBody>
				<div>
				<Modal color="dark" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
	        <ModalHeader toggle={this.toggle}>Change Password</ModalHeader>
	        <ModalBody>
						An email has been sent to {this.state.currentUserEmail}. Please follow the link provided to complete your password reset.
	        </ModalBody>
	        <ModalFooter>
	          <Button color="secondary" onClick={this.toggle}>OK</Button>
	        </ModalFooter>
	      </Modal>
				</div>
      </Card>
		);
	}
}

export default ResetPassword;
