import React from "react";
import { Component, Fragment } from "react";
import {
	Card,
	CardBody,
	CardTitle,
	Row,
	Col,
	Button,

} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as mqtt from 'react-paho-mqtt';
import Paho from 'paho-mqtt';
import { getConnectionURL } from "../../mqtt-helpers/mqtt-request";
import { authenticationService } from '../../jwt/_services';
import ReactGA from "react-ga4";

import {
  ExtendedWarranty,
  Firmware,
  LaserUnits,
  MessageCenter,
  PreSetNonsurgical,
  PreSetSurgical,
  RegisteredUser,
  RemoteSupport,
  ResetPassword,
	ReturnOnInvestmentCustomize
} from '../../components/vitals-customize/';

class VitalsCustomize extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		var connectionTimeout;

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

		this.onMessageArrived = this.onMessageArrived.bind(this);
    this.onConnectionLost = this.onConnectionLost.bind(this);

    this.state = {
			mainMessage: [''],
			allProcedures:[{"OBJID":1,"Name":"Decontamination","CategoryID":1},{"OBJID":2,"Name":"Aphthous Ulcer","CategoryID":1},{"OBJID":3,"Name":"Herpetic Ulcer","CategoryID":1},{"OBJID":4,"Name":"Debridement","CategoryID":1},{"OBJID":5,"Name":"Hemostasis","CategoryID":1},{"OBJID":11,"Name":"Incision/Excision","CategoryID":2},{"OBJID":12,"Name":"Implant Recovery","CategoryID":2},{"OBJID":13,"Name":"Tooth Exposure","CategoryID":2},{"OBJID":14,"Name":"Operculectomy","CategoryID":2},{"OBJID":15,"Name":"Gingivoplasty","CategoryID":2},{"OBJID":16,"Name":"Gingivectomy","CategoryID":2},{"OBJID":17,"Name":"Frenectomy","CategoryID":2},{"OBJID":18,"Name":"Pulpotomy","CategoryID":2},{"OBJID":19,"Name":"Troughing","CategoryID":2},{"OBJID":21,"Name":"7mm","CategoryID":3},{"OBJID":22,"Name":"3mm","CategoryID":3},{"OBJID":23,"Name":"25mm","CategoryID":3}],
			currentPresetsTests:[{"index":164,"product":1,"serial_num":"00013","p0111":11,"p0211":12,"p0311":10,"p0112":13,"p0212":15,"p0312":11,"p0113":8,"p0213":9,"p0313":7,"p0114":12,"p0214":14,"p0314":11,"p0115":8,"p0215":9,"p0315":7,"p0116":10,"p0216":11,"p0316":9,"p0117":12,"p0217":14,"p0317":11,"p0118":8,"p0218":10,"p0318":7,"p0119":9,"p0219":10,"p0319":8,"p0101":6,"p0201":6,"p0301":5,"p0102":4,"p0202":5,"p0302":3,"p0103":7,"p0203":8,"p0303":5,"p0104":9,"p0204":10,"p0304":8,"p0105":0,"p0205":0,"p0305":0,"p0121":1,"p0221":1,"p0321":1,"p0122":1,"p0222":1,"p0322":1,"p0123":10,"p0223":10,"p0323":10}],
			currentPresets:[''],
			updatingMessage:[''],
			firmwareUpdatedMessage:[],
			surgicalProcedures:[],
			nonSurgicalProcedures:[],
			painReliefProcedures:[],
			currentSurgicalPresets:[''],
			subMessage: [''],
			client: null,
			currentUser,
      messageToSend:'',
			currentDevice: this.props.match.params.id,
			currentDeviceUID: '',
			noDevices: false,
      isConnected: false,
			isReceivingMessages: false,
			firmwareUpdatedMessageReceived: false,
			isUpdating: false,
			devices: JSON.parse(localStorage.getItem('deviceList')),
	  };

  }

	async componentDidMount() {

		ReactGA.pageview(window.location.pathname);

		this.connectToMQTT();

		var currentDeviceUIDFound = false;

		if (this.state.devices) {
			console.log('device list: ' + JSON.stringify(this.state.devices));
			for ( var i = 0; i < this.state.devices.length; i++ ) {
				console.log('device serial: ' + this.state.devices[ i ].SerialNumber);
				console.log('current device serial: ' + this.state.currentDevice);
				if ( this.state.devices[ i ].SerialNumber == this.state.currentDevice ) {
					console.log('found vitals customize device UID: ' + this.state.devices[ i ].UID);
					await this.setState({currentDeviceUID: this.state.devices[ i ].UID});
					console.log('vitals customize device UID: ' + this.state.currentDeviceUID);
					currentDeviceUIDFound = true;
				}
			}
		}

		if (!currentDeviceUIDFound) {
			this.getDeviceInfo();
		}

		//setInterval(this.checkIsReceivingMessages, 2000);
	}

	componentWillUnmount() {

		//var currentConnectedDevice = JSON.parse(localStorage.getItem('currentConnectedDevice'));

		// if (currentConnectedDevice == this.state.currentDevice) {
		// 	localStorage.setItem('currentConnectedDevice', '');
		// 	localStorage.setItem('isDeviceReceivingMessages', false);
		// }

		clearTimeout(this.connectionTimeout);

		if (this.state.client && this.state.client.isConnected()) {
			this.state.client.disconnect();
			this.setState({error: 'Lost Connection', isConnected: false});
		}

	}

	connectToMQTT = async () => {
		authenticationService.getDeviceConnectionURL(this.state.currentDevice)
				.then(
						connectionUrl => {
							console.log('connectToMQTT connectionUrl: ' + JSON.stringify(connectionUrl));

							//var url1 = "wss://a1f3ynjyzalf3r-ats.iot.us-west-2.amazonaws.com/mqtt?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAS3T2QUYOSMZKW4V2%2F20210201%2Fus-west-2%2Fiotdevicegateway%2Faws4_request&X-Amz-Date=20210201T225801Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=dff6d2d6b29772129d10a43adf2b218d07b3cd3423ac13e32c71c6d7f8032eaf";

							if (connectionUrl.status == 'Success') {
								var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
								console.log('random hash: ' + hash);
								var clientIdHash = "g2_"+this.state.currentDevice+"_"+connectionUrl.type;
								this.state.client = new Paho.Client(connectionUrl.response, clientIdHash);
								this.state.client.onMessageArrived = this.onMessageArrived;
								this.state.client.onConnectionLost = this.onConnectionLost;
								this.state.client.connect({
									timeout: 3,
									useSSL: true,
									mqttVersion: 4,
									cleanSession: true,
									onSuccess: this.onConnect,
									onFailure: (e) => {console.log("here is the error" , e); }
								});
							}
						},
						error => {
							console.log('error.message: ' + error.message);
						}
				);
	}

	checkIsReceivingMessages = () => {
		if (this.state.isReceivingMessages) {
			console.log("Vitals Customize Is Connected!");
		} else {
			console.log("Vitals Customize Is Not Connected!");
		}
	}

	updateIsReceivingMessages = () => {
		this.setState({isReceivingMessages: false});

		//localStorage.setItem('currentConnectedDevice', '');
		localStorage.setItem('isDeviceReceivingMessages', false);

		console.log("Vitals Customize isReceivingMessages false");
	}

	getDeviceInfo = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-device-info?SerialNumber="+this.state.currentDevice;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("device info: " + JSON.stringify(body.results));

		if (body.status == 'Success') {
			if (body.results.length > 0) {

				this.state.currentDeviceUID = body.results[0].md5;

				console.log("check this.state.currentDeviceUID: "+JSON.stringify(this.state.currentDeviceUID));
				if (this.state.currentDeviceUID) {
					console.log("send message");
					var message = '@@@get_preset,'+this.state.currentDeviceUID;
					this.sendMessage(message)
				}

			} else {

			}
		}
	};

	getProcedures = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"all-procedures";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});
		const body = await response.json();
		console.log("allprocedures: " + JSON.stringify(body));

		if (body.status == 'Success') {
			if (body.results.length > 0) {
				//let powerAvg = parseFloat(body.results[0].power, 10)/10;
				//let roundedPower = powerAvg.toFixed(1)

				var procedures = body.results;
				var surgicalProcedures = procedures.filter(p => p.CategoryID === 2);
				var nonSurgicalProcedures = procedures.filter(p => p.CategoryID === 1);
				var painReliefProcedures = procedures.filter(p => p.CategoryID === 3);

				console.log("var surgicalProcedures: " + JSON.stringify(surgicalProcedures));
				console.log("var nonSurgicalProcedures: " + JSON.stringify(nonSurgicalProcedures));
				console.log("var painReliefProcedures: " + JSON.stringify(painReliefProcedures));

				this.setState({ surgicalProcedures, nonSurgicalProcedures, painReliefProcedures });

				// for (let i = 0; i < body.results.length; i++) {
				// 	if (body.results[i].CategoryID == 2) {
				// 		//console.log("preset is: "+procedure);
				// 		this.setState({ surgicalProcedures: [...this.state.surgicalProcedures, body.results[i]] })
				// 	} else if (body.results[i].CategoryID == 1) {
				// 		//console.log("preset is: "+procedure);
				// 		this.setState({ nonSurgicalProcedures: [...this.state.nonSurgicalProcedures, body.results[i]] })
				// 	} else if (body.results[i].CategoryID == 3) {
				// 		//console.log("preset is: "+procedure);
				// 		this.setState({ painReliefProcedures: [...this.state.painReliefProcedures, body.results[i]] })
				// 	}
				// }
				//
				// console.log("getProcedures surgicalProcedures: " + JSON.stringify(this.state.surgicalProcedures));
				// console.log("getProcedures nonSurgicalProcedures: " + JSON.stringify(this.state.nonSurgicalProcedures));
				// console.log("getProcedures painReliefProcedures: " + JSON.stringify(this.state.painReliefProcedures));

				console.log("this.state.currentDeviceUID: "+JSON.stringify(this.state.currentDeviceUID));
				if (this.state.currentDeviceUID) {
					console.log("send message");
					var message = '@@@get_preset,'+this.state.currentDeviceUID;
					this.sendMessage(message)
					//var temp = '@@@save_preset010106,9298d3e2ad450ad13b08527188fc18110c1a88870ed593a2385b9b5ff160edfa'
					//this.sendMessage(temp)
				}

				//this.noResults = false;
			} else {
				//this.noResults = true;
			}
		}
	};

	getDeviceList = () => {
		const { currentUser } = this.state;
		authenticationService.getDeviceList(currentUser.OBJID, currentUser.token)
		.then(
				deviceList => {

						let status = deviceList.status;
						console.log('status: ' + status);

						if (status === 'Success') {
								console.log('current divice info: ' + deviceList[0].SerialNumber);
								this.setState({currentDevice: deviceList[0].SerialNumber});
								const subChannel = deviceList[0].SerialNumber+'/out';
								this.state.client.subscribe(subChannel);
						} else if (status === 'Error') {
								console.log('error info: ' + deviceList.response);
						} else {
							console.log('status info: ' + status);
						}
				},
				error => {
						console.log('status info: ' + error);
				}
		);

	}

	createPresetArrays() {
		for (var key in this.state.currentPresets) {
			if (key != "index" && key != "product" && key != "serial_num") {
				//console.log("preset is: "+key);
				// var a = str.charAt(3)
				// var b = str.charAt(4)
				// var ab = a+b
				// if (ab == 01)
				// for ()
			}
		}
	}

	onMessageArrived(entry) {
		this.setState({isReceivingMessages: true});
		clearTimeout(this.connectionTimeout);
    //console.log("Vitals Customize onMessageArrived:"+entry.payloadString);
		const msg = JSON.parse(entry.payloadString);

		localStorage.setItem('currentConnectedDevice', JSON.stringify(msg.serial_num));
		localStorage.setItem('isDeviceReceivingMessages', true);

		if (msg.batch) {
			this.setState({mainMessage: JSON.parse(entry.payloadString)});
			//console.log("vitals_main_messages:"+JSON.stringify(this.state.mainMessage));
			//this.setState({ firmwareUpdatedMessage: [] });

			// if (this.state.firmwareUpdatedMessage.length>0) {
			// 	setTimeout(()=>{ this.resetFirmwareUpdatedMessage(); }, 2000);
			// }
			this.setState({ firmwareUpdatedMessage: [] });
		} else if (msg.p0111) {
			this.setState({currentPresets: JSON.parse(entry.payloadString)});
			console.log("currentPresetsReceived:"+JSON.stringify(this.state.currentPresets));
			//this.createPresetArrays();
		} else if (msg.updating) {
			this.setState({updatingMessage: JSON.parse(entry.payloadString)});
			console.log("updatingMessageReceived:"+JSON.stringify(this.state.updatingMessage));
			this.setState({isUpdating: true});
		} else if (msg.update_status) {
			this.setState({firmwareUpdatedMessage: JSON.parse(entry.payloadString)});
			console.log("firmwareUpdatedMessage:"+JSON.stringify(this.state.firmwareUpdatedMessage));
			this.setState({firmwareUpdatedMessageReceived: true});
			this.setState({isUpdating: false});
			//this.setState({ updatingMessage: [] });
			setTimeout(()=>{ this.resetUpdatingMessage(); }, 2000);
		} else {
			this.setState({subMessage: JSON.parse(entry.payloadString)});
			console.log("vitals_sub_messages:"+this.state.subMessage);
		}
		this.connectionTimeout = setInterval(this.updateIsReceivingMessages, 10000);
  }

	resetFirmwareUpdatedMessage() {
		this.setState({ firmwareUpdatedMessage: [] });
	}

	resetUpdatingMessage() {
		this.setState({ updatingMessage: [] });
	}

  onConnect = () => {
    const { client } = this.state;
    console.log("Connected!!!!");
    //client.subscribe('00013/out');
    this.setState({isConnected: true, error: ''})
		//this.sendMessage("@@@get_all");
		console.log('myCurrentDevice id: ' + this.state.currentDevice);
		//this.setState({currentDevice: this.state.currentDevice});
		const subChannel = this.state.currentDevice+'/out';
		this.state.client.subscribe(subChannel);
		this.connectionTimeout = setInterval(this.updateIsReceivingMessages, 10000);
		this.getProcedures();
  };


  sendMessage(message){
    var sendmessage = new Paho.Message(message);
    sendmessage.destinationName = this.state.currentDevice;
		console.log("About to send message: "+message);
    if(this.state.isConnected && !this.state.isUpdating){
			console.log("send message is connected!!!!");

			if (this.state.client.isConnected()) {
				console.log("sendMessage - client is connected!!!!");
				this.state.client.send(sendmessage);
			} else {
				console.log("sendMessage - client is NOT connected!!!!");
			}

    }else{
      // this.connect(this.state.client)
      //   .then(() => {
      //     this.state.client.send(message);
      //     this.setState({error: '', isConnected: true});
      //   })
      //   .catch((error)=> {
      //     console.log(error);
      //     this.setState({error: error});
      //   });
  	}
  }

	sendOutsideMessage(message, state){
    var message = new Paho.Message(message);
    message.destinationName = state.currentDevice;

    if(state.isConnected){
			console.log("send message is connected!!!!");
      state.client.send(message);
    }else{
      // this.connect(this.state.client)
      //   .then(() => {
      //     this.state.client.send(message);
      //     this.setState({error: '', isConnected: true});
      //   })
      //   .catch((error)=> {
      //     console.log(error);
      //     this.setState({error: error});
      //   });
  	}
  }

	testfunction (message) {
		console.log('test message is: ' + message);
	}


  onConnectionLost(responseObject) {
    if (responseObject.errorCode !== 0) {
      console.log("vitals customize onConnectionLost:"+responseObject.errorMessage);
      this.setState({error: 'Lost Connection', isConnected: false});

			//this.connectToMQTT();
    }
  }

	render() {

		var intlDevices = [];
		var showWarranty = true;

		if (this.state.devices) {
			intlDevices = this.state.devices.filter(device => device.Kit.toLowerCase() === "intl");
			console.log("intlDevices: "+JSON.stringify(intlDevices));
			if (intlDevices.length>0) {
				showWarranty = false;
			}
		}

		var vitalsPath = "/vitals/"+this.state.currentDevice;
		var remoteSupportPath = "/remotesupport/"+this.state.currentDevice;
		var vitalsDashboardPath = "/vitals-dashboard/"+this.state.currentDevice;
		var deviceLogs = "/device-logs/"+this.state.currentDevice;
		return (
			<div>
				<Row className="mb-3">
					<Col lg="6" xl="3">
						<h1 style={{color:'#34d099'}}>Customize</h1>
					</Col>
					<Col lg="6" xl="3">
						<h4 className="pt-3">Serial {this.state.currentDevice}</h4>
					</Col>
					<Col lg="6" xl="6">
						<div className="float-right">
							<Button color="dark" href={vitalsPath}>
								Vitals
							</Button>
							{this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']>0 &&
								<Fragment>
								<Button className="ml-2" color="dark" href={remoteSupportPath}>
									Remote Support
								</Button>
								<Button className="ml-2" color="dark" href={vitalsDashboardPath}>
									Dashboard
								</Button>
								<Button className="ml-2" color="dark" href={deviceLogs}>
									Device Logs
								</Button>
								</Fragment>
							}
						</div>
					</Col>
				</Row>
				<Row>
					<Col lg="6" xl="4">
						<RegisteredUser currentUser={this.state.currentUser} currentDevice={this.state.currentDevice} />
					</Col>
					<Col lg="6" xl="4">
						{!this.state.currentUser.payload['custom:role'] || this.state.currentUser.payload['custom:role']==0 &&
							<LaserUnits currentDevice={this.state.currentDevice} isReceivingMessages={this.state.isReceivingMessages} />
						}
						{this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']>0 &&
							<RemoteSupport currentDevice={this.state.currentDevice} />
						}
            <Firmware isUpdating={this.state.isUpdating} firmwareUpdatedMessageReceived={this.state.firmwareUpdatedMessageReceived} currentDeviceUID={this.state.currentDeviceUID} state={this.state} firmwareUpdatedMessage={this.state.firmwareUpdatedMessage} updatingMessage={this.state.updatingMessage} mainMessage={this.state.mainMessage} currentDevice={this.state.currentDevice} isReceivingMessages={this.state.isReceivingMessages}  sendMessage={this.sendMessage} />
					</Col>
					<Col lg="6" xl="4">
						<ReturnOnInvestmentCustomize currentDevice={this.state.currentDevice} currentUser={this.state.currentUser} />
					</Col>
					<Col lg="6" xl="4">
						<PreSetSurgical sendMessage={this.sendMessage} getDeviceInfo={this.getDeviceInfo} state={this.state} currentDeviceUID={this.state.currentDeviceUID} procedures={this.state.surgicalProcedures} currentPresets={this.state.currentPresets} currentDevice={this.state.currentDevice} isReceivingMessages={this.state.isReceivingMessages} />
					</Col>
					<Col lg="6" xl="4">
						<PreSetNonsurgical sendMessage={this.sendMessage} getDeviceInfo={this.getDeviceInfo} state={this.state} currentDeviceUID={this.state.currentDeviceUID} procedures={this.state.nonSurgicalProcedures} currentPresets={this.state.currentPresets} currentDevice={this.state.currentDevice} isReceivingMessages={this.state.isReceivingMessages} />
					</Col>
					<Col lg="6" xl="4">
					{(!this.state.currentUser.payload['custom:role'] || this.state.currentUser.payload['custom:role']==0) && showWarranty &&
						<ExtendedWarranty currentDevice={this.state.currentDevice} currentUser={this.state.currentUser} height={479} />
					}
					</Col>
				</Row>
			</div>
		);
	}
}

export default VitalsCustomize;


// <Row>
// 	<Col xs={12}>
// 		<h1>All Procedures from Database:</h1>
// 		{this.state.procedures.map(procedure => (
// 				<p key={procedure.OBJID}>{procedure.Name}</p>
// 		))}
// 	</Col>
// </Row>
