import React from "react";
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';

class WifiStrength extends React.Component {

	noResults = true;

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 3,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			totalProcedures: 0,
	  };

		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
  }

	async componentDidMount() {

		//setInterval(this.checkReceivingMessages, 2000);
	}

	checkReceivingMessages = () => {
		console.log("checkReceivingMessages: "+this.props.isReceivingMessages);
		if (this.props.isReceivingMessages) {
			console.log("Wifi Is Receiving Messages!");
		} else {
			console.log("Wifi Is Not Receiving Messages!");
		}
	}

	getMessage = () => {
		console.log("onMessageArrivedInWifiStrength:"+this.props.message.wifi);
  }

  onRadioBtnClick(rSelected) {
    this.setState({ rSelected });
  }

	render() {

		var signalStrength = 1;
		var signalStrengthValue = this.props.message.wifi*-1;
		var good = -55;
		var poor = -75;

		if (this.props.message.wifi > good) {
			signalStrength = 3;
		} else if (this.props.message.wifi < good || this.props.message.wifi > poor) {
			signalStrength = 2;
		} else if (this.props.message.wifi < poor) {
			signalStrength = 1;
		}

		var signalStrengthHistory = 1;
		var signalStrengthHistoryValue = 0;
		if (this.props.historyMessage) {
			signalStrengthHistoryValue = this.props.historyMessage.wifi*-1;

			if (this.props.historyMessage.wifi > good) {
				signalStrengthHistory = 3;
			} else if (this.props.historyMessage.wifi < good || this.props.historyMessage.wifi > poor) {
				signalStrengthHistory = 2;
			} else if (this.props.historyMessage.wifi < poor) {
				signalStrengthHistory = 1;
			}
		}

		return (
      <Card className="card-hover">
				<CardBody style={{ minHeight: 193 }}>
					<CardTitle>Wifi Strength</CardTitle>
					{this.props.isReceivingMessages &&
						<Fragment>
						<Row className="mt-1">
							<Col xs="6" md="6" lg="6" className="text-left">
								<span>Network Name</span>
							</Col>
							<Col xs="6" md="6" lg="6" className="text-right">
								<span>{this.props.message.wifi_ssid}</span>
							</Col>
						</Row>
						<Row className="mt-3">
							<Col xs="12" md="12" lg="12">
								<div className="">
									<ButtonGroup style={{ width: '100%' }}>
										<Button
											style={{width: '100%', cursor: 'default'}}
											color="good"
											active={signalStrength === 3}
											disabled={signalStrength !== 3}
										>
											Good
										</Button>
										<Button
											color="weak"
											style={{width: '100%', cursor: 'default'}}
											active={signalStrength === 2}
											disabled={signalStrength !== 2}
										>
											Weak
										</Button>
										<Button
											color="damaged"
											style={{width: '100%', cursor: 'default'}}
											active={signalStrength === 1}
											disabled={signalStrength !== 1}
										>
											Poor
										</Button>
									</ButtonGroup>
								</div>
							</Col>
						</Row>
						<div className="pb-3">
							<Row className="mt-2">
								<Col xs="6" md="6" lg="6" className="text-left">
									<span>Strength</span>
								</Col>
							</Row>
							<Progress multi className="mt-1">
								<Progress bar color="yellow" value={signalStrength*25} />
							</Progress>
						</div>
						</Fragment>
					}
					{!this.props.isReceivingMessages &&
						<Fragment>
						{this.state.currentUser && this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']==2 && this.props.historyMessage ? (
							<Fragment>
							<Row className="mt-1">
								<Col xs="6" md="6" lg="6" className="text-left">
									<span>Network Name</span>
								</Col>
								<Col xs="6" md="6" lg="6" className="text-right">
									<span>{this.props.historyMessage.wifi_ssid}</span>
								</Col>
							</Row>
							<Row className="mt-3">
								<Col xs="12" md="12" lg="12">
									<div className="">
										<ButtonGroup style={{ width: '100%' }}>
											<Button
												style={{width: '100%', cursor: 'default'}}
												color="good"
												active={signalStrengthHistory === 3}
												disabled={signalStrengthHistory !== 3}
											>
												Good
											</Button>
											<Button
												color="weak"
												style={{width: '100%', cursor: 'default'}}
												active={signalStrengthHistory === 2}
												disabled={signalStrengthHistory !== 2}
											>
												Weak
											</Button>
											<Button
												color="damaged"
												style={{width: '100%', cursor: 'default'}}
												active={signalStrengthHistory === 1}
												disabled={signalStrengthHistory !== 1}
											>
												Poor
											</Button>
										</ButtonGroup>
									</div>
								</Col>
							</Row>
							<div className="pb-3">
								<Row className="mt-2">
									<Col xs="6" md="6" lg="6" className="text-left">
										<span>Strength</span>
									</Col>
								</Row>
								<Progress multi className="mt-1">
									<Progress bar color="yellow" value={signalStrengthHistory*25} />
								</Progress>
							</div>
							</Fragment>
						) : (
							<div className="mt-5 text-center">
								<h5>Device {this.props.currentDevice} is Currently not Connected.</h5>
							</div>
						)}
						</Fragment>
					}
        </CardBody>
      </Card>
		);
	}
}

export default WifiStrength;
