import React from "react";
import { Component, Fragment, PureComponent } from "react";
import Moment from 'moment';
import {
	Progress,
	Card,
  CardTitle,
	CardBody,
	Row,
	Col,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ButtonDropdown,
	Tooltip,
	Button
} from 'reactstrap';

import Chart from 'react-c3-component';
import 'c3/c3.css';

import redpin from '../../../assets/images/pins/red_pin.png';
import greenpin from '../../../assets/images/pins/green_pin.png';

const stateLabelValues = [
{ 'label':'Alabama', 'value': 'AL' },
{ 'label':'Alaska', 'value': 'AK'},
{ 'label':'American Samoa', 'value': 'AS'},
{ 'label':'Arizona', 'value': 'AZ'},
{ 'label':'Arkansas', 'value': 'AR'},
{ 'label':'California', 'value': 'CA'},
{ 'label':'Colorado', 'value': 'CO'},
{ 'label':'Connecticut', 'value': 'CT'},
{ 'label':'Delaware', 'value': 'DE'},
{ 'label':'District of Columbia', 'value': 'DC'},
{ 'label':'States of Micronesia', 'value': 'FM'},
{ 'label':'Florida', 'value': 'FL'},
{ 'label':'Georgia', 'value': 'GA'},
{ 'label':'Guam', 'value': 'GU'},
{ 'label':'Hawaii', 'value': 'HI'},
{ 'label':'Idaho', 'value': 'ID'},
{ 'label':'Illinois', 'value': 'IL'},
{ 'label':'Indiana', 'value': 'IN'},
{ 'label':'Iowa', 'value': 'IA'},
{ 'label':'Kansas', 'value': 'KS'},
{ 'label':'Kentucky', 'value': 'KY'},
{ 'label':'Louisiana', 'value': 'LA'},
{ 'label':'Maine', 'value': 'ME'},
{ 'label':'Marshall Islands', 'value': 'MH'},
{ 'label':'Maryland', 'value': 'MD'},
{ 'label':'Massachusetts', 'value': 'MA'},
{ 'label':'Michigan', 'value': 'MI'},
{ 'label':'Minnesota', 'value': 'MN'},
{ 'label':'Mississippi', 'value': 'MS'},
{ 'label':'Missouri', 'value': 'MO'},
{ 'label':'Montana', 'value': 'MT'},
{ 'label':'Nebraska', 'value': 'NE'},
{ 'label':'Nevada', 'value': 'NV'},
{ 'label':'New Hampshire', 'value': 'NH'},
{ 'label':'New Jersey', 'value': 'NJ'},
{ 'label':'New Mexico', 'value': 'NM'},
{ 'label':'New York', 'value': 'NY'},
{ 'label':'North Carolina', 'value': 'NC'},
{ 'label':'North Dakota', 'value': 'ND'},
{ 'label':'Northern Mariana Islands', 'value': 'MP'},
{ 'label':'Ohio', 'value': 'OH'},
{ 'label':'Oklahoma', 'value': 'OK'},
{ 'label':'Oregan', 'value': 'OR'},
{ 'label':'Palau', 'value': 'PW'},
{ 'label':'Pennsilvania', 'value': 'PA'},
{ 'label':'Puerto Rico', 'value': 'PR'},
{ 'label':'Rhode Island', 'value': 'RI'},
{ 'label':'South Carolina', 'value': 'SC'},
{ 'label':'South Dakota', 'value': 'SD'},
{ 'label':'Tennessee', 'value': 'TN'},
{ 'label':'Texas', 'value': 'TX'},
{ 'label':'Utah', 'value': 'UT'},
{ 'label':'Vermont', 'value': 'VT'},
{ 'label':'Virgin Islands', 'value': 'VI'},
{ 'label':'Virginia', 'value': 'VA'},
{ 'label':'Washington', 'value': 'WA'},
{ 'label':'West Virginia', 'value': 'WV'},
{ 'label':'Wisconsin', 'value': 'WI'},
{ 'label':'Wyoming', 'value': 'WY'}
];

const testValues = [
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' },
	{ 'serial_number':'00013', 'icon': greenpin, 'version': '1.5', 'usage': '124 hrs', 'city': 'Redding', 'state': 'California', 'country': 'United States' }
];

class DeviceRow extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        online_markers: [],
      };
      console.log('devices count: ' + this.state.online_markers.length);
    }

		async componentDidMount() {

			this.refreshDevices();

			setInterval(this.refreshDevices, 600000);
		}

		refreshDevices = () => {
			this.setState({online_markers: this.props.online_markers});
			//console.log('refreshDevices devices count: ' + this.state.online_markers.length);
			//console.log('refreshDevices devices: ' + JSON.stringify(this.state.online_markers));
		}

    goToVitals(serial){
      window.location.href="/vitals/"+serial;
    }

    render() {
        //const {id, serial_number} = this.props;
        //console.log('devices count: ' + this.state.online_markers.length);
        return (
          <div className="mt-2">
					{this.state.online_markers.map((device, i) => {

						//this.props.allDevices
						let foundDevice = this.props.allDevices.find(d => d.SerialNumber === device.serial_number);
						//console.log('DeviceRow foundDevice: ' + JSON.stringify(foundDevice));

						var vitalsPath = "/vitals/"+device.serial_number;
						var customizePath = "/customize/"+device.serial_number;
						var remoteSupportPath = "/remotesupport/"+device.serial_number;
						var vitalsDashboardPath = "/vitals-dashboard/"+device.serial_number;
						var calibrationPath = "/calibration?serial="+device.serial_number;
						var logsPath = "/device-logs/"+device.serial_number;
						var remotesupportblackbox = "/remotesupportblackbox/"+device.serial_number;

						return (
							<Fragment>
							{i < 10000 &&
								<Fragment>
								{device.icon != redpin &&
									<Fragment>
										<Row>
											<Col xs="12" md="12" lg="12" className="text-center">
												<div style={{ width: '100%' }}>
													<div className="float-left">
														{foundDevice && (foundDevice.Kit=='Int' || foundDevice.Kit=='Intl') &&
															<i style={{ color: '#BC02C1', lineHeight: "0.9"}} className="mdi mdi-heart mdi-24px" />
														}
														{foundDevice && foundDevice.Kit=='US' &&
															<i style={{ color: '#42BF00', lineHeight: "0.9"}} className="mdi mdi-heart mdi-24px" />
														}
														{foundDevice && foundDevice.Kit=='Demo' &&
															<i style={{ color: '#0356DB', lineHeight: "0.9"}} className="mdi mdi-heart mdi-24px" />
														}
													</div>
													<div className="float-left">
														<span className="ml-2" style={{ lineHeight: '1.5' }}>{device.serial_number} | </span>
													</div>
													<div className="float-left">
														<span className="ml-2" style={{ lineHeight: '1.5' }}>v{device.version} | </span>
													</div>
													<div className="float-left">
													{device.city &&
														<span className="ml-1" style={{ lineHeight: '1.5' }}>{device.city}, {stateLabelValues.filter(state => state.label.includes(device.state)).map(filteredName => (filteredName.value))} | {device.country=="United States" ? "USA" : device.country}</span>
													}
													</div>
													<div className="float-right">
														<Button style={{ padding: 0 }} color="link" href={vitalsPath} title="Vitals">
															<i style={{ lineHeight: '0.3' }} className="mdi mdi-stethoscope mdi-18px"/>
														</Button>
														<Button style={{ padding: 0 }} color="link" href={customizePath} title="Customize">
															<i style={{ lineHeight: '0.3' }} className="mdi mdi-message-settings-variant mdi-18px"/>
														</Button>
														<Button style={{ padding: 0 }} color="link" href={vitalsDashboardPath} title="Dashboard">
															<i style={{ lineHeight: '0.3' }} className="mdi mdi-view-dashboard mdi-18px"/>
														</Button>
														<Button style={{ padding: 0 }} color="link" href={remoteSupportPath} title="Remote Support">
															<i style={{ lineHeight: '0.3' }} className="mdi mdi-lan-connect mdi-18px"/>
														</Button>
														<Button style={{ padding: 0 }} color="link" href={remotesupportblackbox} title="Remote Support Black Box" target="_blank">
															<i style={{ lineHeight: '0.3' }} className="mdi mdi-play-box-outline mdi-18px"/>
														</Button>
														<Button style={{ padding: 0 }} color="link" href={calibrationPath} title="Calibration">
															<i style={{ lineHeight: '0.3' }} className="mdi mdi-chart-bar mdi-18px"/>
														</Button>
													</div>
												</div>
												<div className="pt-3" style={{ width: '100%' }}>
													<hr className="new1" />
												</div>
											</Col>
										</Row>
									</Fragment>
								}
								</Fragment>
							}
							</Fragment>
						);
					})}


          </div>
        );
    }
}

class UnitsOn extends React.Component {

	constructor(props) {
    super(props);
    this.state = {
			online_markers: this.props.online_markers,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			currentDate: Moment().format('YYYY-MM-DD'),
			mostRecentDevices: [],
			allDevices: [],
			currentItem: "All Units",
      markerDisplayStatus: 0,
			isDropDownOpen: false,
			tooltipOpen: false,
	  };

		this.getLocationInfo = this.getLocationInfo.bind(this);
		this.toggleDropDown = this.toggleDropDown.bind(this);

		this.toggleTooltip = this.toggleTooltip.bind(this);
  }

	async componentDidMount() {
		setInterval(this.refreshDevices, 4000);
		this.getDeviceData();
		this.getMostRecentDevices();
	}

	toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

	toggleDropDown() {
    this.setState({
      isDropDownOpen: !this.state.isDropDownOpen
    });
  }

	selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			markerDisplayStatus: id
    });

  }

	goToVitals(serial){
		window.location.href="/vitals/"+serial;
	}

	getDeviceData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"all-devices";

		try {
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("getDeviceData all devices: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ allDevices: body.results });
			} else {
	      //ADD ERROR MESSAGE
	    }
		} catch (err) {
			console.log(err);
		}

	};

	refreshDevices = () => {
		this.setState({online_markers: this.props.online_markers});
		//console.log('refreshDevices devices count: ' + this.state.online_markers.length);
		//console.log('refreshDevices devices: ' + JSON.stringify(this.state.online_markers));
	}

	getMostRecentDevices = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"most-recent-online-devices?Date="+this.state.currentDate;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("most-recent-online-devices: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ mostRecentDevices: body.results });
			} else {
	      //ADD ERROR MESSAGE
	    }

		} catch (err) {
			console.log(err);
		}

	};

	getLocationInfo(serial) {

		var location = "";

		let device = this.props.devices.find(d => d.SerialNumber === serial);

		//console.log("getLocationInfo device found serial: "+JSON.stringify(device));

		if (device) {
			if (device.geo_city && device.geo_city != "") {
				location = device.geo_city;
				if (device.geo_state && device.geo_state != "") {
					var stateAbr = stateLabelValues.filter(state => state.label.includes(device.geo_state)).map(filteredName => (filteredName.value))
					location = device.geo_city + "," + stateAbr;
				}
			}
		}

		return location;

	}

	render() {
		return (
			/*--------------------------------------------------------------------------------*/
			/* Used In Dashboard-1 [Classic]                                                  */
			/*--------------------------------------------------------------------------------*/
			<Card className="card-hover">
				<CardBody style={{ minHeight: 650 }}>
					<Row className="mb-3">
						<Col xs="7">
							<CardTitle>Units ON by Location
							<i style={{color: '#ffffff'}} className="mdi mdi-information-outline ml-1" id="UnitsOnTooltip"/>
							<Tooltip placement="top" isOpen={this.state.tooltipOpen} target="UnitsOnTooltip" toggle={this.toggleTooltip}>
								Shows the most recent online devices and the current online devices.
							</Tooltip>
							</CardTitle>
						</Col>
						<Col xs="5" className="text-right">
							<ButtonDropdown isOpen={this.state.isDropDownOpen} toggle={this.toggleDropDown}>
								<DropdownToggle color="dark" caret>
									{this.state.currentItem}
								</DropdownToggle>
								<DropdownMenu right>
									<DropdownItem onClick={() => this.selectDropDownItem(0, 'All Units')}>All Units</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(1, 'Online Only')}>Online Only</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(2, 'Offline Only')}>Offline Only</DropdownItem>
								</DropdownMenu>
							</ButtonDropdown>
						</Col>
					</Row>
					<div className="mt-2 text-center" style={{ height: 574, overflow: 'auto' }}>
						{this.state.markerDisplayStatus==0 &&
							<Fragment>
								<DeviceRow online_markers={this.state.online_markers} allDevices={this.state.allDevices} />
								{this.state.mostRecentDevices.map((device, i) => {

									var vitalsPath = "/vitals/"+device.SerialNumber;
									var customizePath = "/customize/"+device.SerialNumber;
									var remoteSupportPath = "/remotesupport/"+device.SerialNumber;
									var vitalsDashboardPath = "/vitals-dashboard/"+device.SerialNumber;
									var calibrationPath = "/calibration?serial="+device.SerialNumber;
									var logsPath = "/device-logs/"+device.SerialNumber;
									var remotesupportblackbox = "/remotesupportblackbox/"+device.SerialNumber;

									return (
 									 <Fragment>
 									 {i < 10000 &&
 										 <Fragment>
 										 {device.icon != redpin &&
 											 <Fragment>
 												 <Row>
 													 <Col xs="12" md="12" lg="12" className="text-center">
 														 <div style={{ width: '100%' }}>
 															 <div className="float-left">
																 <i style={{ color: '#e2212b', lineHeight: "0.9"}} className="mdi mdi-heart mdi-24px" />
 															 </div>
 															 <div className="float-left">
 																 <span className="ml-2" style={{ lineHeight: '1.5' }}>{device.SerialNumber} | </span>
 															 </div>
 															 <div className="float-left">
 																 <span className="ml-2" style={{ lineHeight: '1.5' }}>{this.getLocationInfo(device.SerialNumber)}</span>
 															 </div>
															 <div className="float-right">
		 														<Button style={{ padding: 0 }} color="link" href={vitalsPath} title="Vitals">
		 															<i style={{ lineHeight: '0.3' }} className="mdi mdi-stethoscope mdi-18px"/>
		 														</Button>
		 														<Button style={{ padding: 0 }} color="link" href={customizePath} title="Customize">
		 															<i style={{ lineHeight: '0.3' }} className="mdi mdi-message-settings-variant mdi-18px"/>
		 														</Button>
		 														<Button style={{ padding: 0 }} color="link" href={vitalsDashboardPath} title="Dashboard">
		 															<i style={{ lineHeight: '0.3' }} className="mdi mdi-view-dashboard mdi-18px"/>
		 														</Button>
		 														<Button style={{ padding: 0 }} color="link" href={remoteSupportPath} title="Remote Support">
		 															<i style={{ lineHeight: '0.3' }} className="mdi mdi-lan-connect mdi-18px"/>
		 														</Button>
		 														<Button style={{ padding: 0 }} color="link" href={remotesupportblackbox} title="Remote Support Black Box" target="_blank">
		 															<i style={{ lineHeight: '0.3' }} className="mdi mdi-play-box-outline mdi-18px"/>
		 														</Button>
		 														<Button style={{ padding: 0 }} color="link" href={calibrationPath} title="Calibration">
		 															<i style={{ lineHeight: '0.3' }} className="mdi mdi-chart-bar mdi-18px"/>
		 														</Button>
		 													</div>
 														 </div>
 														 <div className="pt-3" style={{ width: '100%' }}>
 															 <hr className="new1" />
 														 </div>
 													 </Col>
 												 </Row>
 											 </Fragment>
 										 }
 										 </Fragment>
 									 }
 									 </Fragment>
 									);
		 						})}
							</Fragment>
						}
						{this.state.markerDisplayStatus==1 &&
							<DeviceRow online_markers={this.state.online_markers} allDevices={this.state.allDevices} />
						}
						{this.state.markerDisplayStatus==2 &&
							<Fragment>
								{this.state.mostRecentDevices.map((device, i) => {

									var vitalsPath = "/vitals/"+device.SerialNumber;
									var customizePath = "/customize/"+device.SerialNumber;
									var remoteSupportPath = "/remotesupport/"+device.SerialNumber;
									var vitalsDashboardPath = "/vitals-dashboard/"+device.SerialNumber;
									var calibrationPath = "/calibration?serial="+device.SerialNumber;
									var logsPath = "/device-logs/"+device.SerialNumber;
									var remotesupportblackbox = "/remotesupportblackbox/"+device.SerialNumber;

									return (
 									 <Fragment>
 									 {i < 10000 &&
 										 <Fragment>
 										 {device.icon != redpin &&
 											 <Fragment>
 												 <Row>
 													 <Col xs="12" md="12" lg="12" className="text-center">
 														 <div style={{ width: '100%' }}>
 															 <div className="float-left">
																 <i style={{ color: '#e2212b', lineHeight: "0.9"}} className="mdi mdi-heart mdi-24px" />
 															 </div>
 															 <div className="float-left">
 																 <span className="ml-2" style={{ lineHeight: '1.5' }}>{device.SerialNumber} | </span>
 															 </div>
 															 <div className="float-left">
 																 <span className="ml-2" style={{ lineHeight: '1.5' }}>{this.getLocationInfo(device.SerialNumber)}</span>
 															 </div>
															 <div className="float-right">
		 														<Button style={{ padding: 0 }} color="link" href={vitalsPath} title="Vitals">
		 															<i style={{ lineHeight: '0.3' }} className="mdi mdi-stethoscope mdi-18px"/>
		 														</Button>
		 														<Button style={{ padding: 0 }} color="link" href={customizePath} title="Customize">
		 															<i style={{ lineHeight: '0.3' }} className="mdi mdi-message-settings-variant mdi-18px"/>
		 														</Button>
		 														<Button style={{ padding: 0 }} color="link" href={vitalsDashboardPath} title="Dashboard">
		 															<i style={{ lineHeight: '0.3' }} className="mdi mdi-view-dashboard mdi-18px"/>
		 														</Button>
		 														<Button style={{ padding: 0 }} color="link" href={remoteSupportPath} title="Remote Support">
		 															<i style={{ lineHeight: '0.3' }} className="mdi mdi-lan-connect mdi-18px"/>
		 														</Button>
		 														<Button style={{ padding: 0 }} color="link" href={remotesupportblackbox} title="Remote Support Black Box" target="_blank">
		 															<i style={{ lineHeight: '0.3' }} className="mdi mdi-play-box-outline mdi-18px"/>
		 														</Button>
		 														<Button style={{ padding: 0 }} color="link" href={calibrationPath} title="Calibration">
		 															<i style={{ lineHeight: '0.3' }} className="mdi mdi-chart-bar mdi-18px"/>
		 														</Button>
		 													</div>
 														 </div>
 														 <div className="pt-3" style={{ width: '100%' }}>
 															 <hr className="new1" />
 														 </div>
 													 </Col>
 												 </Row>
 											 </Fragment>
 										 }
 										 </Fragment>
 									 }
 									 </Fragment>
 									);
		 						})}

							</Fragment>
						}
					</div>
				</CardBody>
			</Card>

		);
	}
}

export default UnitsOn;
