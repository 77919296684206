import React from "react";
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Row,
	Col,
	Button,

} from 'reactstrap';
import Iframe from 'react-iframe'
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as mqtt from 'react-paho-mqtt';
import Paho from 'paho-mqtt';
import { getConnectionURL } from "../../mqtt-helpers/mqtt-request";
import { authenticationService } from '../../jwt/_services';
import ReactGA from "react-ga4";

import {
	RemoteSupportBlackBoxView
} from '../../components/remote-support/';

class RemoteSupportBlackBox extends React.Component {

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			mainMessage: [''],
			subMessage: [''],
			currentUser,
      messageToSend:'',
			currentDevice: this.props.match.params.id,
      isConnected: false,
			devices: [''],
	  };

    this.iframeURL = 'https://www.geminilaser.com/dashboard/support/g2_remote.php?sn='+this.state.currentDevice;
		//this.iframeURL = 'https://www.google.com';
  }

	componentDidMount() {
		ReactGA.pageview(window.location.pathname);
	}

	render() {
		var customizePath = "/customize/"+this.state.currentDevice;
    var vitalsPath = "/vitals/"+this.state.currentDevice;
		var vitalsDashboardPath = "/vitals-dashboard/"+this.state.currentDevice;
		var deviceLogs = "/device-logs/"+this.state.currentDevice;
		return (
			<div>
				<Row className="mb-3">
					<Col md="5">
						<h1 style={{color:'#e04e28'}}>Remote Support Black Box</h1>
					</Col>
					<Col md="2">
						<h4 className="pt-3">Serial {this.state.currentDevice}</h4>
					</Col>
					<Col lg="5" xl="5">
						<div className="float-right">
							<Button color="dark" href={vitalsPath}>
								Vitals
							</Button>
							<Button className="ml-2" color="dark" href={customizePath}>
								Customize
							</Button>
							{this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']>0 &&
								<Fragment>
								<Button className="ml-2" color="dark" href={vitalsDashboardPath}>
									Dashboard
								</Button>
								<Button className="ml-2" color="dark" href={deviceLogs}>
									Device Logs
								</Button>
								</Fragment>
							}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs="12" md="12" lg="12">
						<RemoteSupportBlackBoxView currentDevice={this.state.currentDevice} />
					</Col>
				</Row>
        {
					// <Card className="card-hover">
  				// <CardBody style={{ height: 740 }}>
          //   <Row>
					// 	<Col xs="12" md="12" lg="12">
					// 		<Iframe url={this.iframeURL}
					// 		width="100%"
					// 		height="700px"
					// 		id="myId"
					// 		className="myClassname"
					// 		display="initial"
					// 		position="relative"/>
					// 	</Col>
          //   </Row>
          // </CardBody>
        	// </Card>
				}
			</div>
		);
	}
}

export default RemoteSupportBlackBox;


// <Row>
// 	<Col xs={12}>
// 		<h1>All Procedures from Database:</h1>
// 		{this.state.procedures.map(procedure => (
// 				<p key={procedure.OBJID}>{procedure.Name}</p>
// 		))}
// 	</Col>
// </Row>
