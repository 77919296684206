import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";
import { findDOMNode } from "react-dom";
import $ from "jquery";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import redpin from '../../../assets/images/pins/red_pin.png';
import greenpin from '../../../assets/images/pins/green_pin.png';
import brokenpin from '../../../assets/images/pins/broken_pin.png';
import 'c3/c3.css';
import '../../../assets/remote/css/remote.css';
import { authenticationService } from '../../../jwt/_services';
import * as mqtt from 'react-paho-mqtt';
import Paho from 'paho-mqtt';

class RemoteSupportView extends React.Component {

	intervalID = 0;
	intervalID2 = 0;

	constructor(props) {
    super(props);

		const display_segments = [
			"HerpeticUlcer",
			"Hemostasis",
			"AphtousUlcer",
			"Debridement",
			"Decontamination1",
			"Decontamination2",
			"NonSurgical1",
			"NonSurgical2",
			"NonSurgical3",
			"Laser810Line",
			"Laser810Active1",
			"Laser810Active2",
			"Laser810Wavelength1",
			"Laser810Wavelength2",
			"Laser810Wavelength3",
			"Laser810Standby",
			"SoundHigh",
			"SoundMedium",
			"SoundLow",
			"SoundMute",
			"Laser100WPeak",
			"Power10",
			"Power9",
			"Power8",
			"Power7",
			"Power6",
			"Power1",
			"Power2",
			"Power3",
			"Power4",
			"Power5",
			"FirmwareUpdate",
			"RemoteInterlock",
			"SpeakerHigh",
			"SpeakerMedium",
			"SpeakerLow",
			"SpeakerMute",
			"SpeakerIcon",
			"UltradentLogo",
			"SoundIcon",
			"LeftDigitTop",
			"LeftDigitTopLeft",
			"LeftDigitCenter",
			"LeftDigitBottomLeft",
			"LeftDigitBottom",
			"LeftDigitBottomRight",
			"LeftDigitTopRight",
			"VersionIcon",
			"DotIcon",
			"Surgical1",
			"Surgical2",
			"Surgical3",
			"ImplantRecovery",
			"ToothExposure",
			"Gingivectomy",
			"Frenectomy",
			"PassError1",
			"PassCheck1",
			"PassCheck2",
			"PassError2",
			"Laser150WPeak",
			"RightDigitTop",
			"RightDigitTopRight",
			"RightDigitBottomRight",
			"RightDigitBottom",
			"RightDigitBottomLeft",
			"RightDigitCenter",
			"RightDigitTopLeft",
			"AverageWatts",
			"TimerInSeconds",
			"IncisionExcision",
			"Operculectomy",
			"Gingivoplasty",
			"Pulpotomy",
			"Troughing",
			"PassError4",
			"PassCheck4",
			"PassError3",
			"PassCheck3",
			"EnterPasscode",
			"PowerLineBottom",
			"Power11",
			"Power12",
			"Power13",
			"Power14",
			"Power15",
			"Power20",
			"Power19",
			"Power18",
			"Power17",
			"Power16",
			"PowerLineTop",
			"BluetoothIcon",
			"BatteryIcon",
			"ACIcon",
			"BatteryLow",
			"BatteryMedium",
			"BatteryHigh",
			"BatteryFull",
			"WifiLow",
			"Tip25mm",
			"Tip3mm",
			"Tip7mm",
			"PainRelief3",
			"PainRelief2",
			"PainRelief1",
			"Laser980Line",
			"Laser980Active2",
			"Laser980Active1",
			"Laser980Wavelength3",
			"Laser980Wavelength2",
			"Laser980Wavelength1",
			"Laser980Standby",
			"AimingHigh",
			"AimingMedium",
			"AimingLow",
			"AimingOff",
			"AimingIcon",
			"WifiHigh",
			"WifiMedium",
		];
		const dead_front_buttons = [
			"home_btn",
			"preset_btn",
			"update_btn",
			"w810_btn",
			"w980_btn",
			"dual_btn",
			"haptic_btn",
			"aiming_btn",
			"tip_btn",
			"volume_btn",
			"wifi_btn",
			"menu_btn",
			"voice_btn",
			"up_btn",
			"down_btn",
			"left_btn",
			"right_btn",
			"go_btn",
			"stop_btn",
		];
		//var tn=0;
		//var last_ping=0;

		var connectionTimeout;

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

		this.onMessageArrived = this.onMessageArrived.bind(this);
    this.onConnectionLost = this.onConnectionLost.bind(this);

    this.state = {
			mainMessage: [''],
			noDevices: false,
			subMessage: [''],
			client: null,
			currentUser,
			display_segments,
			dead_front_buttons,
      messageToSend:'',
      isConnected: false,
			isReceivingMessages: false,
			devices: [''],
			rSelected: 3,
			totalProcedures: 0,
      dropdownOpen: false,
			gemini_connection: "Disconnected",
			cloud_connection: "Disconnected",
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			currentDeviceUID: '',
			tn: 0,
			verificationError: false,
			debugPass: '78A36$hd785Ndj!',
			modal: false,
			modal2: false,
			last_ping: 0,
			debugData: [],
	  };

		this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);

		this.checkConnection = this.checkConnection.bind(this);

  }

	ws = new WebSocket('wss://1eptrexnv5.execute-api.us-west-2.amazonaws.com/v1')

	async componentDidMount() {
		var w = document.getElementById('glassscreen').clientHeight;

		/*var elem = document.getElementById('Debridement');
		elem.style.width = "100px";
		elem.style.marginLeft = "1000px";*/

		//document.getElementById("Debridement").style.width = 1000;
		//document.getElementById("Debridement").style.left = 1000;
		//document.getElementById("Debridement").style.visibility = "visible";
		//document.getElementById("Debridement").style.display = "inherit";

		console.log("this.display_segments: "+this.state.display_segments);
		this.Checkoverlay();
		this.getDeviceInfo();
		this.connectToMQTT();
		this.hide_touched_button();

		this.intervalID = setInterval(this.checkConnection, 1000);
		this.intervalID2 = setInterval(this.Checkoverlay, 2000);

		this.ws.onopen = () => {
			// on connecting, do nothing but log it to the console
			console.log('connected')
		}

		this.ws.onmessage = evt => {
			// listen to data sent from the websocket server
			console.log('ws message: '+JSON.stringify(evt.data))
			const message = JSON.stringify(evt.data)
			this.state.debugData.unshift(message);
			//this.setState({dataFromServer: message})
			// console.log(message)
		}

		this.ws.onclose = () => {
			console.log('disconnected')
			// automatically try to reconnect on connection loss

		}

	}

	componentWillUnmount() {
		clearInterval(this.intervalID);
		clearInterval(this.intervalID2);

		if (this.state.client && this.state.client.isConnected()) {
			this.state.client.disconnect();
		}

	}

	toggle() {
		this.setState({ modal: !this.state.modal });
	}

	toggle2() {
		this.setState({ modal2: !this.state.modal2 });
	}

	handleVerifySubmit = (event) => {
		event.preventDefault();

	  let debugpasscode = event.target.debugpasscode.value;
    console.log(`password is:`, debugpasscode);

		if (debugpasscode == this.state.debugPass) {
			this.setState({
				verificationError: false
			});

			this.toggle();

			//SEND COMMAND
			this.showDebugLogs();

		} else {
			this.setState({
		    verificationError: true
		  });
		}
	}

	showDebugLogs = () => {
		console.log("showDebugLogs!");

		this.sendMessage("@@@remote_debug");
	}

	checkConnection() {
		console.log("checkConnection");
		//this.state.tn=Math.round((new Date()).getTime() / 1000);

		let tn = Math.round((new Date()).getTime() / 1000);
		this.setState({ tn: tn});

		console.log("this.tn: "+this.state.tn);
		console.log("this.last_ping: "+this.state.last_ping);

		if (this.state.tn-this.state.last_ping>15)
		{
			this.setState({ gemini_connection: "Disconnected"});
		}
	}

	getDeviceInfo = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-device-info?SerialNumber="+this.props.currentDevice;

		console.log("get device info url: " + url);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("device info: " + JSON.stringify(body.results));

		if (body.status == 'Success') {
			if (body.results.length > 0) {

				this.setState({ currentDeviceUID: body.results[0].md5})

				console.log("check this.state.currentDeviceUID: "+JSON.stringify(this.state.currentDeviceUID));

			} else {

			}
		}
	};

 	hide_touched_button()
	{
		$(".btn_highlight").hide();
		$(".r_btn_highlight").hide();
	}

	connectToMQTT = async () => {
		authenticationService.getDeviceConnectionURL(this.props.currentDevice)
				.then(
						connectionUrl => {
							console.log('connectToMQTT connectionUrl: ' + JSON.stringify(connectionUrl));

							//var url1 = "wss://a1f3ynjyzalf3r-ats.iot.us-west-2.amazonaws.com/mqtt?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAS3T2QUYOSMZKW4V2%2F20210201%2Fus-west-2%2Fiotdevicegateway%2Faws4_request&X-Amz-Date=20210201T225801Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=dff6d2d6b29772129d10a43adf2b218d07b3cd3423ac13e32c71c6d7f8032eaf";

							if (connectionUrl.status == 'Success') {
								var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
								console.log('random hash: ' + hash);
								var clientIdHash = "g2_"+this.props.currentDevice+"_"+connectionUrl.type;
								console.log('clientIdHash: ' + clientIdHash);
								this.state.client = new Paho.Client(connectionUrl.response, clientIdHash);
								this.state.client.onMessageArrived = this.onMessageArrived;
								this.state.client.onConnectionLost = this.onConnectionLost;
								this.state.client.connect({
									timeout: 3,
									useSSL: true,
									mqttVersion: 4,
									cleanSession: true,
									onSuccess: this.onConnect,
									onFailure: (e) => {console.log("here is the error" , e); }
								});
							}
						},
						error => {
							console.log('error.message: ' + error.message);
						}
				);
	}

	checkIsConnected = () => {
		if (this.state.isReceivingMessages) {
			console.log("Vitals Is Connected!");
		} else {
			console.log("Vitals Is Not Connected!");
		}
	}

	onMessageArrived(entry) {
    console.log("remote Support onMessageArrived:"+entry.payloadString);
		const json_message = JSON.parse(entry.payloadString);
		this.setState({ gemini_connection: "Connected"});

		let last_ping = Math.round((new Date()).getTime() / 1000);
		this.setState({ last_ping: last_ping });
		//console.log("set this.last_ping:"+this.state.last_ping);
		this.handleMessage(json_message);
		//this.test();

  }

	async handleMessage(json_message) {

		if (json_message.serial_num!=undefined) $("#serial_num").html("Serial Number: "+json_message.serial_num);
		if (json_message.batch!=undefined) $("#batch_num").html("Batch: "+json_message.batch);
		if (json_message.geo_lat!=undefined && json_message.geo_lng!=undefined) $("#geolocation").html("Geolocation: "+json_message.geo_lat + ", " + json_message.geo_lng);
		if (json_message.fw_ver!=undefined) $("#firmware_version").html("Firmware Version: "+json_message.fw_ver);
		if (json_message.fw_up!=undefined)
		{
			if (json_message.fw_up!=0)
				$("#firmware_status").html("Firmware Status: Update Available");
			else
				$("#firmware_status").html("Firmware Status: Up to date");
		}
		if (json_message.wifi!=undefined) $("#wifi_signal").html("Wifi Signal: "+json_message.wifi);
		if (json_message.ble!=undefined) $("#ble_signal").html("Bluetooth Signal: "+json_message.ble);
		if (json_message.V!=undefined && json_message.I!=undefined) $("#batteryinfo").html("Battery Info: "+json_message.V + "mV / " + json_message.I + "mA");
		if (json_message.life810!=undefined && json_message.life980!=undefined) $("#laserlife").html("810nm life: "+json_message.life810 + " sec / 980nm life: " + json_message.life980 + " sec");
		if (json_message.cal810!=undefined && json_message.cal980!=undefined) $("#lasercal").html("810nm calibration: "+json_message.cal810 + " / 980nm calibration: " + json_message.cal980);
		if (json_message.i_data!=undefined)
		{
			var i_data=json_message.i_data;
			if ((i_data>>0) & 1 != 0) $("#footpedal_pressed").show(); else $("#footpedal_pressed").hide();
			if ((i_data>>1) & 1 != 0) $("#aiming_tip").show(); else $("#aiming_tip").hide();
			if ((i_data>>2) & 1 != 0) $("#illumination_tip").show(); else $("#illumination_tip").hide();
		}
		// if (json_message.updating!=undefined) {
		//
		// 	var powerArray = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];
		// 	var powerArray2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
		//
		// 	var powerLevel = "power1";
		//
		// 	for (var i=0; i<powerArray.length; i++) {
		//
		// 		if (i>0) {
		//
		// 			if (powerArray[i-1]<json_message.updating && json_message.updating<=powerArray[i]) {
		// 				console.log("should show power"+powerArray2[i]);
		// 				powerLevel = "power"+powerArray2[i];
		// 			}
		// 		}
		//
		// 	}
		//
		// 	console.log("powerLevel: "+powerLevel);
		// 	$("#"+powerLevel).show();
		// 	$("#"+powerLevel).css("opacity","1");
		// }
		if (json_message.display_data!=undefined)
		{
			$("#on_button").show();
			$("#stop_button").show();

			var panel=json_message.display_data.match(/../g);
			for (var a=0;a<15;a++)
			{
				for (var b=0;b<8;b++)
				{
					if (parseInt(panel[a],16) & 1<<b)
					{
						//console.log("show: #"+this.state.display_segments[(a*8)+b]);
						$("#"+this.state.display_segments[(a*8)+b]).show();
					}
					else
					{
						$("#"+this.state.display_segments[(a*8)+b]).hide();
					}
					if (parseInt(panel[a+15],16) & 1<<b)
					{
						$("#"+this.state.display_segments[(a*8)+b]).css("opacity","0.3");
					}
					else
					{
						$("#"+this.state.display_segments[(a*8)+b]).css("opacity","1");
					}
				}
			}
			var dead_front=json_message.df_data.match(/../g);
			for (var a=0;a<19;a++)
			{
				$("#"+this.state.dead_front_buttons[a]).css("background-color","#"+dead_front[(a*3)]+dead_front[(a*3)+1]+dead_front[(a*3)+2]);
				if (this.state.dead_front_buttons[a]=="go_btn" && $("#"+this.state.dead_front_buttons[a]).css("background-color") == "rgb(0, 0, 0)")
				{
					$("#go_btn").css("zIndex",0);
					$("#stop_btn").css("zIndex",1);
				}
				if (this.state.dead_front_buttons[a]=="stop_btn" && $("#"+this.state.dead_front_buttons[a]).css("background-color") == "rgb(0, 0, 0)")
				{
					$("#go_btn").css("zIndex",1);
					$("#stop_btn").css("zIndex",0);
				}
				if ($("#"+this.state.dead_front_buttons[a]).css("background-color") == "rgb(0, 0, 0)")
				{
					$("#"+this.state.dead_front_buttons[a]).css("display","none");
				}
				else
				{
					$("#"+this.state.dead_front_buttons[a]).css("display","block");
				}
			}
			var touched_button=json_message.df_button;
			if (touched_button>>0 & 1) {
				$("#home_btn_touched").addClass("btn_highlight");
				$("#home_btn_touched").removeClass("r_btn_highlight");
				$("#home_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>1 & 1) {
				$("#preset_btn_touched").addClass("btn_highlight");
				$("#preset_btn_touched").removeClass("r_btn_highlight");
				$("#preset_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>2 & 1) {
				$("#update_btn_touched").addClass("btn_highlight");
				$("#update_btn_touched").removeClass("r_btn_highlight");
				$("#update_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>3 & 1) {
				$("#w810_btn_touched").addClass("btn_highlight");
				$("#w810_btn_touched").removeClass("r_btn_highlight");
				$("#w810_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>4 & 1) {
				$("#dual_btn_touched").addClass("btn_highlight");
				$("#dual_btn_touched").removeClass("r_btn_highlight");
				$("#dual_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>5 & 1) {
				$("#w980_btn_touched").addClass("btn_highlight");
				$("#w980_btn_touched").removeClass("r_btn_highlight");
				$("#w980_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>6 & 1) {
				$("#wifi_btn_touched").addClass("btn_highlight");
				$("#wifi_btn_touched").removeClass("r_btn_highlight");
				$("#wifi_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>7 & 1) {
				$("#menu_btn_touched").addClass("btn_highlight");
				$("#menu_btn_touched").removeClass("r_btn_highlight");
				$("#menu_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>8 & 1) {
				$("#voice_btn_touched").addClass("btn_highlight");
				$("#voice_btn_touched").removeClass("r_btn_highlight");
				$("#voice_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>9 & 1) {
				$("#haptic_btn_touched").addClass("btn_highlight");
				$("#haptic_btn_touched").removeClass("r_btn_highlight");
				$("#haptic_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>10 & 1) {
				$("#aiming_btn_touched").addClass("btn_highlight");
				$("#aiming_btn_touched").removeClass("r_btn_highlight");
				$("#aiming_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>11 & 1) {
				$("#tip_btn_touched").addClass("btn_highlight");
				$("#tip_btn_touched").removeClass("r_btn_highlight");
				$("#tip_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>12 & 1) {
				$("#volume_btn_touched").addClass("btn_highlight");
				$("#volume_btn_touched").removeClass("r_btn_highlight");
				$("#volume_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>13 & 1) {
				$("#up_btn_touched").addClass("btn_highlight");
				$("#up_btn_touched").removeClass("r_btn_highlight");
				$("#up_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>14 & 1) {
				$("#down_btn_touched").addClass("btn_highlight");
				$("#down_btn_touched").removeClass("r_btn_highlight");
				$("#down_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>15 & 1) {
				$("#left_btn_touched").addClass("btn_highlight");
				$("#left_btn_touched").removeClass("r_btn_highlight");
				$("#left_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>16 & 1) {
				$("#right_btn_touched").addClass("btn_highlight");
				$("#right_btn_touched").removeClass("r_btn_highlight");
				$("#right_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>17 & 1) {
				$("#go_btn_touched").addClass("btn_highlight");
				$("#go_btn_touched").removeClass("r_btn_highlight");
				$("#go_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			touched_button=json_message.rdf_button;
			if (touched_button>>0 & 1) {
				$("#home_btn_touched").addClass("r_btn_highlight");
				$("#home_btn_touched").removeClass("btn_highlight");
				$("#home_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>1 & 1) {
				$("#preset_btn_touched").addClass("r_btn_highlight");
				$("#preset_btn_touched").removeClass("btn_highlight");
				$("#preset_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>2 & 1) {
				$("#update_btn_touched").addClass("r_btn_highlight");
				$("#update_btn_touched").removeClass("btn_highlight");
				$("#update_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>3 & 1) {
				$("#w810_btn_touched").addClass("r_btn_highlight");
				$("#w810_btn_touched").removeClass("btn_highlight");
				$("#w810_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>4 & 1) {
				$("#dual_btn_touched").addClass("r_btn_highlight");
				$("#dual_btn_touched").removeClass("btn_highlight");
				$("#dual_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>5 & 1) {
				$("#w980_btn_touched").addClass("r_btn_highlight");
				$("#w980_btn_touched").removeClass("btn_highlight");
				$("#w980_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>6 & 1) {
				$("#wifi_btn_touched").addClass("r_btn_highlight");
				$("#wifi_btn_touched").removeClass("btn_highlight");
				$("#wifi_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>7 & 1) {
				$("#menu_btn_touched").addClass("r_btn_highlight");
				$("#menu_btn_touched").removeClass("btn_highlight");
				$("#menu_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>8 & 1) {
				$("#voice_btn_touched").addClass("r_btn_highlight");
				$("#voice_btn_touched").removeClass("btn_highlight");
				$("#voice_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>9 & 1) {
				$("#haptic_btn_touched").addClass("r_btn_highlight");
				$("#haptic_btn_touched").removeClass("btn_highlight");
				$("#haptic_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>10 & 1) {
				$("#aiming_btn_touched").addClass("r_btn_highlight");
				$("#aiming_btn_touched").removeClass("btn_highlight");
				$("#aiming_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>11 & 1) {
				$("#tip_btn_touched").addClass("r_btn_highlight");
				$("#tip_btn_touched").removeClass("btn_highlight");
				$("#tip_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>12 & 1) {
				$("#volume_btn_touched").addClass("r_btn_highlight");
				$("#volume_btn_touched").removeClass("btn_highlight");
				$("#volume_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>13 & 1) {
				$("#up_btn_touched").addClass("r_btn_highlight");
				$("#up_btn_touched").removeClass("btn_highlight");
				$("#up_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>14 & 1) {
				$("#down_btn_touched").addClass("r_btn_highlight");
				$("#down_btn_touched").removeClass("btn_highlight");
				$("#down_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>15 & 1) {
				$("#left_btn_touched").addClass("r_btn_highlight");
				$("#left_btn_touched").removeClass("btn_highlight");
				$("#left_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>16 & 1) {
				$("#right_btn_touched").addClass("r_btn_highlight");
				$("#right_btn_touched").removeClass("btn_highlight");
				$("#right_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
			if (touched_button>>17 & 1) {
				$("#go_btn_touched").addClass("r_btn_highlight");
				$("#go_btn_touched").removeClass("btn_highlight");
				$("#go_btn_touched").show();
				setTimeout(this.hide_touched_button, 300);
			}
		}

		// $("#PowerLineTop").show();
		// $("#PowerLineBottom").show();
		// //$(".cropped_segments").css("display","block");
		// $("#power1").removeClass("cropped_segments");
		// $("#power2").removeClass("cropped_segments");
		// $("#power3").removeClass("cropped_segments");
		// $("#power4").removeClass("cropped_segments");
		// $("#power5").removeClass("cropped_segments");
		// $("#power6").removeClass("cropped_segments");
		// $("#power7").removeClass("cropped_segments");
		// $("#power8").removeClass("cropped_segments");
		// $("#power9").removeClass("cropped_segments");
		// $("#power10").removeClass("cropped_segments");
		// $("#power1").show();
		// $("#power2").show();
		// $("#power3").show();
		// $("#power4").show();
		// $("#Power5").show();
		// $("#power6").show();
		// $("#power7").show();
		// $("#power8").show();
		// $("#power9").show();
		// $("#power10").show();
		// $("#power1").css("display","block");
		// $("#power2").css("display","block");
		// $("#power3").css("display","block");
		// $("#power4").css("display","block");
		// $("#power5").css("display","block");
		// $("#power6").css("display","block");
		// $("#power7").css("display","block");
		// $("#power8").css("display","block");
		// $("#power9").css("display","block");
		// $("#power10").css("display","block");
		// $("#power1").css("opacity","1");
		// $("#power2").css("opacity","1");
		// $("#power3").css("opacity","1");
		// $("#power4").css("opacity","1");
		// $("#power5").css("opacity","1");
		// $("#power6").css("opacity","1");
		// $("#power7").css("opacity","1");
		// $("#power8").css("opacity","1");
		// $("#power9").css("opacity","1");
		// $("#power10").css("opacity","1");
		//
		// $("#power11").removeClass("cropped_segments");
		// $("#power12").removeClass("cropped_segments");
		// $("#power13").removeClass("cropped_segments");
		// $("#power14").removeClass("cropped_segments");
		// $("#power15").removeClass("cropped_segments");
		// $("#power16").removeClass("cropped_segments");
		// $("#power17").removeClass("cropped_segments");
		// $("#power18").removeClass("cropped_segments");
		// $("#power19").removeClass("cropped_segments");
		// $("#power20").removeClass("cropped_segments");
		// $("#power11").show();
		// $("#power12").show();
		// $("#power13").show();
		// $("#power14").show();
		// $("#Power15").show();
		// $("#power16").show();
		// $("#power17").show();
		// $("#power18").show();
		// $("#power19").show();
		// $("#power20").show();
		// $("#power11").css("opacity","1");
		// $("#power12").css("opacity","1");
		// $("#power13").css("opacity","1");
		// $("#power14").css("opacity","1");
		// $("#power15").css("opacity","1");
		// $("#power16").css("opacity","1");
		// $("#power17").css("opacity","1");
		// $("#power18").css("opacity","1");
		// $("#power19").css("opacity","1");
		// $("#power20").css("opacity","1");
		// $("#power11").css("display","block");
		// $("#power12").css("display","block");
		// $("#power13").css("display","block");
		// $("#power14").css("display","block");
		// $("#power15").css("display","block");
		// $("#power16").css("display","block");
		// $("#power17").css("display","block");
		// $("#power18").css("display","block");
		// $("#power19").css("display","block");
		// $("#power20").css("display","block");

	}

	onConnect = () => {
    const { client } = this.state;
    console.log("Connected!!!!");
    //client.subscribe('00013/out');
    this.setState({isConnected: true, cloud_connection: "Connected", error: ''})
		//this.sendMessage("@@@get_all");

		const subChannel = this.props.currentDevice+"/out";
		this.state.client.subscribe(subChannel);

		this.sendMessage("@@@get_all");
  };


  sendMessage(message){

		console.log("Sending Message: "+message);

		var formatted = message+","+this.state.currentDeviceUID;

		console.log("Formatted Message: "+formatted);

    var messageFormatted = new Paho.Message(formatted);

		console.log("destinationName: "+this.props.currentDevice);

    messageFormatted.destinationName = this.props.currentDevice;

		this.state.client.send(messageFormatted);

    // if(this.state.isConnected){
		// 	console.log("Is Connected");
    //   this.state.client.send(messageFormatted);
    // }else{
		// 	console.log("Is NOT Connected");
    //   // this.connect(this.state.client)
    //   //   .then(() => {
    //   //     this.state.client.send(message);
    //   //     this.setState({error: '', isConnected: true});
    //   //   })
    //   //   .catch((error)=> {
    //   //     console.log(error);
    //   //     this.setState({error: error});
    //   //   });
  	// }
  }


  onConnectionLost(responseObject) {
    if (responseObject.errorCode !== 0) {
      console.log("onConnectionLost:"+responseObject.errorMessage);
      this.setState({error: 'Lost Connection', isConnected: false});
			this.connectToMQTT();
    }
  }

	upbtnClick() {
		if ($("#up_btn").css("background-color")!="rgb(0, 0, 0)")
		{
			console.log("button up");
			this.sendMessage("@@@btn_up");
			$("#up_btn").css("background-color","#ffffff");
		}
	}

	downbtnClick() {
		if ($("#down_btn").css("background-color")!="rgb(0, 0, 0)")
		{
			console.log("button down");
			this.sendMessage("@@@btn_down");
			$("#down_btn").css("background-color","#ffffff");
		}
	}

	leftbtnClick() {
		if ($("#left_btn").css("background-color")!="rgb(0, 0, 0)")
		{
			console.log("button left");
			this.sendMessage("@@@btn_left");
			$("#left_btn").css("background-color","#ffffff");
		}
	}

	rightbtnClick() {
		if ($("#right_btn").css("background-color")!="rgb(0, 0, 0)")
		{
			console.log("button right");
			this.sendMessage("@@@btn_right");
			$("#right_btn").css("background-color","#ffffff");
		}
	}

	gobtnClick() {
		if ($("#go_btn").css("background-color")!="rgb(0, 0, 0)")
		{
			console.log("button go");
			this.sendMessage("@@@btn_go");
		}
	}

	stopbtnClick() {
		if ($("#stop_btn").css("background-color")!="rgb(0, 0, 0)")
		{
			console.log("button go");
			this.sendMessage("@@@btn_go");
		}
	}

	homebtnClick() {
		if ($("#home_btn").css("background-color")!="rgb(0, 0, 0)")
		{
			console.log("button home");
			this.sendMessage("@@@btn_home");
			$("#home_btn").css("background-color","#ffffff");
		}
	}

	presetbtnClick() {
		if ($("#preset_btn").css("background-color")!="rgb(0, 0, 0)")
		{
			console.log("button preset");
			this.sendMessage("@@@btn_preset");
			$("#preset_btn").css("background-color","#ffffff");
		}
	}

	updatebtnClick() {
		if ($("#update_btn").css("background-color")!="rgb(0, 0, 0)")
		{
			console.log("button update");
			this.sendMessage("@@@btn_fw_update");
			$("#update_btn").css("background-color","#ffffff");
		}
	}

	w810btnClick() {
		if ($("#w810_btn").css("background-color")!="rgb(0, 0, 0)")
		{
			console.log("button 810");
			this.sendMessage("@@@btn_w810");
			$("#w810_btn").css("background-color","#ffffff");
		}
	}

	dualbtnClick() {
		if ($("#dual_btn").css("background-color")!="rgb(0, 0, 0)")
		{
			console.log("button dual");
			this.sendMessage("@@@btn_dual");
			$("#dual_btn").css("background-color","#ffffff");
		}
	}

	w980btnClick() {
		if ($("#w980_btn").css("background-color")!="rgb(0, 0, 0)")
		{
			console.log("button 980");
			this.sendMessage("@@@btn_w980");
			$("#w980_btn").css("background-color","#ffffff");
		}
	}

	hapticbtnClick() {
		if ($("#haptic_btn").css("background-color")!="rgb(0, 0, 0)")
		{
			console.log("button haptic");
			this.sendMessage("@@@btn_haptic");
			$("#haptic_btn").css("background-color","#ffffff");
		}
	}

	aimingbtnClick() {
		if ($("#aiming_btn").css("background-color")!="rgb(0, 0, 0)")
		{
			console.log("button aiming");
			this.sendMessage("@@@btn_aiming");
			$("#aiming_btn").css("background-color","#ffffff");
		}
	}

	tipbtnClick() {
		if ($("#tip_btn").css("background-color")!="rgb(0, 0, 0)")
		{
			console.log("button tip");
			this.sendMessage("@@@btn_tip");
			$("#tip_btn").css("background-color","#ffffff");
		}
	}

	volumebtnClick() {
		if ($("#volume_btn").css("background-color")!="rgb(0, 0, 0)")
		{
			console.log("button volume");
			this.sendMessage("@@@btn_volume");
			$("#volume_btn").css("background-color","#ffffff");
		}
	}

	wifibtnClick() {
		if ($("#wifi_btn").css("background-color")!="rgb(0, 0, 0)")
		{
			console.log("button wifi");
			//this.sendMessage("@@@btn_wifi");
		}
	}

	menubtnClick() {
		if ($("#menu_btn").css("background-color")!="rgb(0, 0, 0)")
		{
			console.log("button menu");
			this.sendMessage("@@@btn_menu");
			$("#menu_btn").css("background-color","#ffffff");
		}
	}

	voicebtnClick() {
		if ($("#voice_btn").css("background-color")!="rgb(0, 0, 0)")
		{
			console.log("button voice");
			this.sendMessage("@@@btn_voice");
			$("#voice_btn").css("background-color","#ffffff");
		}
	}

 	Checkoverlay()
	{
		var w=$("#glassscreen").width();
		var h=$("#glassscreen").height();
		console.log("glassscreen width: "+w+" & height: "+h);
		var minw=(1920*h)/1080
		$("#glassscreen").css("min-width",minw);
		$("#parameters").css("top",(30*h)/1080);
		$("#parameters").css("left",((30*h)/1080)+((w-minw)/2));
		$("#aiming_tip").css("width",(100*w)/1920);
		$("#aiming_tip").css("height",(100*h)/1080);
		$("#aiming_tip").css("top",(658*h)/1080);
		$("#aiming_tip").css("left",((821*h)/1080)+((w-minw)/2));
		$("#illumination_tip").css("width",(110*w)/1920);
		$("#illumination_tip").css("height",(110*h)/1080);
		$("#illumination_tip").css("top",(658*h)/1080);
		$("#illumination_tip").css("left",((930*h)/1080)+((w-minw)/2));
		$("#stop_button").css("width",(60*w)/1920);
		$("#stop_button").css("height",(60*h)/1080);
		$("#stop_button").css("top",(672*h)/1080);
		$("#stop_button").css("left",((1050*h)/1080)+((w-minw)/2));
		$("#on_button").css("width",(60*w)/1920);
		$("#on_button").css("height",(60*h)/1080);
		$("#on_button").css("top",(674*h)/1080);
		$("#on_button").css("left",((1490*h)/1080)+((w-minw)/2));
		$("#footpedal_pressed").css("width",(706*w)/1920);
		$("#footpedal_pressed").css("height",(353*h)/1080);
		$("#footpedal_pressed").css("top",(727*h)/1080);
		$("#footpedal_pressed").css("left",((180*h)/1080)+((w-minw)/2));

		$("#Hemostasis").css("width",(187*w)/1920);
		$("#Hemostasis").css("height",(33*h)/1080);
		$("#Hemostasis").css("top",(470*h)/1080);
		$("#Hemostasis").css("left",((952*h)/1080)+((w-minw)/2));
		$("#Debridement").css("width",(187*w)/1920);
		$("#Debridement").css("height",(33*h)/1080);
		$("#Debridement").css("top",(404*h)/1080);
		$("#Debridement").css("left",((952*h)/1080)+((w-minw)/2));
		// $("#HerpeticUlcer").css("width",(187*w)/1920);
		// $("#HerpeticUlcer").css("height",(33*h)/1080);
		// $("#HerpeticUlcer").css("top",(437*h)/1080);
		// $("#HerpeticUlcer").css("left",((952*h)/1080)+((w-minw)/2));
		$("#AphtousUlcer").css("width",(187*w)/1920);
		$("#AphtousUlcer").css("height",(33*h)/1080);
		$("#AphtousUlcer").css("top",(437*h)/1080);
		$("#AphtousUlcer").css("left",((952*h)/1080)+((w-minw)/2));
		$("#Decontamination1").css("width",(187*w)/1920);
		$("#Decontamination1").css("height",(33*h)/1080);
		$("#Decontamination1").css("top",(371*h)/1080);
		$("#Decontamination1").css("left",((952*h)/1080)+((w-minw)/2));
		$("#NonSurgical1").css("width",(187*w)/1920);
		$("#NonSurgical1").css("height",(42*h)/1080);
		$("#NonSurgical1").css("top",(329*h)/1080);
		$("#NonSurgical1").css("left",((952*h)/1080)+((w-minw)/2));
		$("#Laser810Line").css("width",(198*w)/1920);
		$("#Laser810Line").css("height",(141*h)/1080);
		$("#Laser810Line").css("top",(188*h)/1080);
		$("#Laser810Line").css("left",((952*h)/1080)+((w-minw)/2));
		$("#Laser810Active1").css("width",(198*w)/1920);
		$("#Laser810Active1").css("height",(141*h)/1080);
		$("#Laser810Active1").css("top",(188*h)/1080);
		$("#Laser810Active1").css("left",((952*h)/1080)+((w-minw)/2));
		$("#Laser810Wavelength1").css("width",(198*w)/1920);
		$("#Laser810Wavelength1").css("height",(141*h)/1080);
		$("#Laser810Wavelength1").css("top",(188*h)/1080);
		$("#Laser810Wavelength1").css("left",((952*h)/1080)+((w-minw)/2));
		$("#Laser810Standby").css("width",(198*w)/1920);
		$("#Laser810Standby").css("height",(141*h)/1080);
		$("#Laser810Standby").css("top",(188*h)/1080);
		$("#Laser810Standby").css("left",((952*h)/1080)+((w-minw)/2));
		$("#SoundMute").css("width",(78*w)/1920);
		$("#SoundMute").css("height",(35*h)/1080);
		$("#SoundMute").css("top",(169*h)/1080);
		$("#SoundMute").css("left",((1025*h)/1080)+((w-minw)/2));
		$("#SoundLow").css("width",(78*w)/1920);
		$("#SoundLow").css("height",(35*h)/1080);
		$("#SoundLow").css("top",(169*h)/1080);
		$("#SoundLow").css("left",((1025*h)/1080)+((w-minw)/2));
		$("#SoundMedium").css("width",(78*w)/1920);
		$("#SoundMedium").css("height",(35*h)/1080);
		$("#SoundMedium").css("top",(169*h)/1080);
		$("#SoundMedium").css("left",((1025*h)/1080)+((w-minw)/2));
		$("#SoundHigh").css("width",(78*w)/1920);
		$("#SoundHigh").css("height",(35*h)/1080);
		$("#SoundHigh").css("top",(169*h)/1080);
		$("#SoundHigh").css("left",((1025*h)/1080)+((w-minw)/2));
		$("#SoundIcon").css("width",(78*w)/1920);
		$("#SoundIcon").css("height",(35*h)/1080);
		$("#SoundIcon").css("top",(169*h)/1080);
		$("#SoundIcon").css("left",((1025*h)/1080)+((w-minw)/2));
		$("#UltradentLogo").css("width",(156*w)/1920);
		$("#UltradentLogo").css("height",(19*h)/1080);
		$("#UltradentLogo").css("top",(55*h)/1080);
		$("#UltradentLogo").css("left",((1221*h)/1080)+((w-minw)/2));
		$("#SpeakerIcon").css("width",(317*w)/1920);
		$("#SpeakerIcon").css("height",(103*h)/1080);
		$("#SpeakerIcon").css("top",(74*h)/1080);
		$("#SpeakerIcon").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#SpeakerMute").css("width",(317*w)/1920);
		$("#SpeakerMute").css("height",(103*h)/1080);
		$("#SpeakerMute").css("top",(74*h)/1080);
		$("#SpeakerMute").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#SpeakerLow").css("width",(317*w)/1920);
		$("#SpeakerLow").css("height",(103*h)/1080);
		$("#SpeakerLow").css("top",(74*h)/1080);
		$("#SpeakerLow").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#SpeakerMedium").css("width",(317*w)/1920);
		$("#SpeakerMedium").css("height",(103*h)/1080);
		$("#SpeakerMedium").css("top",(74*h)/1080);
		$("#SpeakerMedium").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#SpeakerHigh").css("width",(317*w)/1920);
		$("#SpeakerHigh").css("height",(103*h)/1080);
		$("#SpeakerHigh").css("top",(74*h)/1080);
		$("#SpeakerHigh").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#RemoteInterlock").css("width",(317*w)/1920);
		$("#RemoteInterlock").css("height",(103*h)/1080);
		$("#RemoteInterlock").css("top",(74*h)/1080);
		$("#RemoteInterlock").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#WifiLow").css("width",(317*w)/1920);
		$("#WifiLow").css("height",(103*h)/1080);
		$("#WifiLow").css("top",(74*h)/1080);
		$("#WifiLow").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power5").css("width",h*.2);
		$("#Power5").css("left",h*.281);
		$("#Power4").css("width",h*.2);
		$("#Power4").css("left",h*.265);
		$("#Power3").css("width",h*.2);
		$("#Power3").css("left",h*.249);
		$("#Power2").css("width",h*.2);
		$("#Power2").css("left",h*.233);
		$("#Power1").css("width",h*.2);
		$("#Power1").css("left",h*.217);
		$("#Power6").css("width",h*.2);
		$("#Power6").css("left",h*.297);
		$("#Power7").css("width",h*.2);
		$("#Power7").css("left",h*.313);
		$("#Power8").css("width",h*.2);
		$("#Power8").css("left",h*.329);
		$("#Power9").css("width",h*.2);
		$("#Power9").css("left",h*.345);
		$("#Power10").css("width",h*.2);
		$("#Power10").css("left",h*.36);
		$("#Power5").css("width",(317*w)/1920);
		$("#Power5").css("height",(103*h)/1080);
		$("#Power5").css("top",(74*h)/1080);
		$("#Power5").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power4").css("width",(317*w)/1920);
		$("#Power4").css("height",(103*h)/1080);
		$("#Power4").css("top",(74*h)/1080);
		$("#Power4").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power3").css("width",(317*w)/1920);
		$("#Power3").css("height",(103*h)/1080);
		$("#Power3").css("top",(74*h)/1080);
		$("#Power3").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power2").css("width",(317*w)/1920);
		$("#Power2").css("height",(103*h)/1080);
		$("#Power2").css("top",(74*h)/1080);
		$("#Power2").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power1").css("width",(317*w)/1920);
		$("#Power1").css("height",(103*h)/1080);
		$("#Power1").css("top",(74*h)/1080);
		$("#Power1").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power6").css("width",(317*w)/1920);
		$("#Power6").css("height",(103*h)/1080);
		$("#Power6").css("top",(74*h)/1080);
		$("#Power6").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power7").css("width",(317*w)/1920);
		$("#Power7").css("height",(103*h)/1080);
		$("#Power7").css("top",(74*h)/1080);
		$("#Power7").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power8").css("width",(317*w)/1920);
		$("#Power8").css("height",(103*h)/1080);
		$("#Power8").css("top",(74*h)/1080);
		$("#Power8").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power9").css("width",(317*w)/1920);
		$("#Power9").css("height",(103*h)/1080);
		$("#Power9").css("top",(74*h)/1080);
		$("#Power9").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power10").css("width",(317*w)/1920);
		$("#Power10").css("height",(103*h)/1080);
		$("#Power10").css("top",(74*h)/1080);
		$("#Power10").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Laser100WPeak").css("width",(317*w)/1920);
		$("#Laser100WPeak").css("height",(103*h)/1080);
		$("#Laser100WPeak").css("top",(74*h)/1080);
		$("#Laser100WPeak").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#LeftDigitTop").css("width",(207*w)/1920);
		$("#LeftDigitTop").css("height",(120*h)/1080);
		$("#LeftDigitTop").css("top",(177*h)/1080);
		$("#LeftDigitTop").css("left",((1195*h)/1080)+((w-minw)/2));
		$("#LeftDigitTopLeft").css("width",(207*w)/1920);
		$("#LeftDigitTopLeft").css("height",(120*h)/1080);
		$("#LeftDigitTopLeft").css("top",(177*h)/1080);
		$("#LeftDigitTopLeft").css("left",((1195*h)/1080)+((w-minw)/2));
		$("#LeftDigitCenter").css("width",(207*w)/1920);
		$("#LeftDigitCenter").css("height",(120*h)/1080);
		$("#LeftDigitCenter").css("top",(177*h)/1080);
		$("#LeftDigitCenter").css("left",((1195*h)/1080)+((w-minw)/2));
		$("#LeftDigitBottomLeft").css("width",(207*w)/1920);
		$("#LeftDigitBottomLeft").css("height",(120*h)/1080);
		$("#LeftDigitBottomLeft").css("top",(177*h)/1080);
		$("#LeftDigitBottomLeft").css("left",((1195*h)/1080)+((w-minw)/2));
		$("#LeftDigitBottom").css("width",(207*w)/1920);
		$("#LeftDigitBottom").css("height",(120*h)/1080);
		$("#LeftDigitBottom").css("top",(177*h)/1080);
		$("#LeftDigitBottom").css("left",((1195*h)/1080)+((w-minw)/2));
		$("#LeftDigitBottomRight").css("width",(207*w)/1920);
		$("#LeftDigitBottomRight").css("height",(120*h)/1080);
		$("#LeftDigitBottomRight").css("top",(177*h)/1080);
		$("#LeftDigitBottomRight").css("left",((1195*h)/1080)+((w-minw)/2));
		$("#LeftDigitTopRight").css("width",(207*w)/1920);
		$("#LeftDigitTopRight").css("height",(120*h)/1080);
		$("#LeftDigitTopRight").css("top",(177*h)/1080);
		$("#LeftDigitTopRight").css("left",((1195*h)/1080)+((w-minw)/2));
		$("#VersionIcon").css("width",(207*w)/1920);
		$("#VersionIcon").css("height",(120*h)/1080);
		$("#VersionIcon").css("top",(177*h)/1080);
		$("#VersionIcon").css("left",((1195*h)/1080)+((w-minw)/2));
		$("#DotIcon").css("width",(207*w)/1920);
		$("#DotIcon").css("height",(120*h)/1080);
		$("#DotIcon").css("top",(177*h)/1080);
		$("#DotIcon").css("left",((1195*h)/1080)+((w-minw)/2));
		$("#Surgical1").css("width",(156*w)/1920);
		$("#Surgical1").css("height",(42*h)/1080);
		$("#Surgical1").css("top",(329*h)/1080);
		$("#Surgical1").css("left",((1221*h)/1080)+((w-minw)/2));
		$("#ImplantRecovery").css("width",(156*w)/1920);
		$("#ImplantRecovery").css("height",(33*h)/1080);
		$("#ImplantRecovery").css("top",(404*h)/1080);
		$("#ImplantRecovery").css("left",((1221*h)/1080)+((w-minw)/2));
		$("#ToothExposure").css("width",(156*w)/1920);
		$("#ToothExposure").css("height",(33*h)/1080);
		$("#ToothExposure").css("top",(437*h)/1080);
		$("#ToothExposure").css("left",((1221*h)/1080)+((w-minw)/2));
		$("#Gingivectomy").css("width",(156*w)/1920);
		$("#Gingivectomy").css("height",(33*h)/1080);
		$("#Gingivectomy").css("top",(536*h)/1080);
		$("#Gingivectomy").css("left",((1221*h)/1080)+((w-minw)/2));
		$("#Frenectomy").css("width",(156*w)/1920);
		$("#Frenectomy").css("height",(33*h)/1080);
		$("#Frenectomy").css("top",(569*h)/1080);
		$("#Frenectomy").css("left",((1221*h)/1080)+((w-minw)/2));
		$("#PassError1").css("width",(198*w)/1920);
		$("#PassError1").css("height",(59*h)/1080);
		$("#PassError1").css("top",(668*h)/1080);
		$("#PassError1").css("left",((1204*h)/1080)+((w-minw)/2));
		$("#PassCheck1").css("width",(198*w)/1920);
		$("#PassCheck1").css("height",(59*h)/1080);
		$("#PassCheck1").css("top",(668*h)/1080);
		$("#PassCheck1").css("left",((1204*h)/1080)+((w-minw)/2));
		$("#PassCheck2").css("width",(198*w)/1920);
		$("#PassCheck2").css("height",(59*h)/1080);
		$("#PassCheck2").css("top",(668*h)/1080);
		$("#PassCheck2").css("left",((1204*h)/1080)+((w-minw)/2));
		$("#PassError2").css("width",(198*w)/1920);
		$("#PassError2").css("height",(59*h)/1080);
		$("#PassError2").css("top",(668*h)/1080);
		$("#PassError2").css("left",((1204*h)/1080)+((w-minw)/2));
		$("#EnterPasscode").css("width",(198*w)/1920);
		$("#EnterPasscode").css("height",(59*h)/1080);
		$("#EnterPasscode").css("top",(668*h)/1080);
		$("#EnterPasscode").css("left",((1204*h)/1080)+((w-minw)/2));
		$("#PassCheck3").css("width",(198*w)/1920);
		$("#PassCheck3").css("height",(59*h)/1080);
		$("#PassCheck3").css("top",(668*h)/1080);
		$("#PassCheck3").css("left",((1204*h)/1080)+((w-minw)/2));
		$("#PassError3").css("width",(198*w)/1920);
		$("#PassError3").css("height",(59*h)/1080);
		$("#PassError3").css("top",(668*h)/1080);
		$("#PassError3").css("left",((1204*h)/1080)+((w-minw)/2));
		$("#PassCheck4").css("width",(198*w)/1920);
		$("#PassCheck4").css("height",(59*h)/1080);
		$("#PassCheck4").css("top",(668*h)/1080);
		$("#PassCheck4").css("left",((1204*h)/1080)+((w-minw)/2));
		$("#PassError4").css("width",(198*w)/1920);
		$("#PassError4").css("height",(59*h)/1080);
		$("#PassError4").css("top",(668*h)/1080);
		$("#PassError4").css("left",((1204*h)/1080)+((w-minw)/2));
		$("#Troughing").css("width",(156*w)/1920);
		$("#Troughing").css("height",(33*h)/1080);
		$("#Troughing").css("top",(635*h)/1080);
		$("#Troughing").css("left",((1221*h)/1080)+((w-minw)/2));
		$("#Pulpotomy").css("width",(156*w)/1920);
		$("#Pulpotomy").css("height",(33*h)/1080);
		$("#Pulpotomy").css("top",(602*h)/1080);
		$("#Pulpotomy").css("left",((1221*h)/1080)+((w-minw)/2));
		$("#Gingivoplasty").css("width",(156*w)/1920);
		$("#Gingivoplasty").css("height",(33*h)/1080);
		$("#Gingivoplasty").css("top",(503*h)/1080);
		$("#Gingivoplasty").css("left",((1221*h)/1080)+((w-minw)/2));
		$("#Operculectomy").css("width",(156*w)/1920);
		$("#Operculectomy").css("height",(33*h)/1080);
		$("#Operculectomy").css("top",(470*h)/1080);
		$("#Operculectomy").css("left",((1221*h)/1080)+((w-minw)/2));
		$("#IncisionExcision").css("width",(156*w)/1920);
		$("#IncisionExcision").css("height",(33*h)/1080);
		$("#IncisionExcision").css("top",(371*h)/1080);
		$("#IncisionExcision").css("left",((1221*h)/1080)+((w-minw)/2));
		$("#TimerInSeconds").css("width",(156*w)/1920);
		$("#TimerInSeconds").css("height",(32*h)/1080);
		$("#TimerInSeconds").css("top",(297*h)/1080);
		$("#TimerInSeconds").css("left",((1221*h)/1080)+((w-minw)/2));
		$("#AverageWatts").css("width",(156*w)/1920);
		$("#AverageWatts").css("height",(32*h)/1080);
		$("#AverageWatts").css("top",(297*h)/1080);
		$("#AverageWatts").css("left",((1221*h)/1080)+((w-minw)/2));
		$("#RightDigitTopLeft").css("width",(207*w)/1920);
		$("#RightDigitTopLeft").css("height",(120*h)/1080);
		$("#RightDigitTopLeft").css("top",(177*h)/1080);
		$("#RightDigitTopLeft").css("left",((1195*h)/1080)+((w-minw)/2));
		$("#RightDigitCenter").css("width",(207*w)/1920);
		$("#RightDigitCenter").css("height",(120*h)/1080);
		$("#RightDigitCenter").css("top",(177*h)/1080);
		$("#RightDigitCenter").css("left",((1195*h)/1080)+((w-minw)/2));
		$("#RightDigitBottomLeft").css("width",(207*w)/1920);
		$("#RightDigitBottomLeft").css("height",(120*h)/1080);
		$("#RightDigitBottomLeft").css("top",(177*h)/1080);
		$("#RightDigitBottomLeft").css("left",((1195*h)/1080)+((w-minw)/2));
		$("#RightDigitBottom").css("width",(207*w)/1920);
		$("#RightDigitBottom").css("height",(120*h)/1080);
		$("#RightDigitBottom").css("top",(177*h)/1080);
		$("#RightDigitBottom").css("left",((1195*h)/1080)+((w-minw)/2));
		$("#RightDigitBottomRight").css("width",(207*w)/1920);
		$("#RightDigitBottomRight").css("height",(120*h)/1080);
		$("#RightDigitBottomRight").css("top",(177*h)/1080);
		$("#RightDigitBottomRight").css("left",((1195*h)/1080)+((w-minw)/2));
		$("#RightDigitTopRight").css("width",(207*w)/1920);
		$("#RightDigitTopRight").css("height",(120*h)/1080);
		$("#RightDigitTopRight").css("top",(177*h)/1080);
		$("#RightDigitTopRight").css("left",((1195*h)/1080)+((w-minw)/2));
		$("#RightDigitTop").css("width",(207*w)/1920);
		$("#RightDigitTop").css("height",(120*h)/1080);
		$("#RightDigitTop").css("top",(177*h)/1080);
		$("#RightDigitTop").css("left",((1195*h)/1080)+((w-minw)/2));
		$("#Laser150WPeak").css("width",h*.2);
		$("#Laser150WPeak").css("left",h*.457);
		$("#PowerLineBottom").css("width",(317*w)/1920);
		$("#PowerLineBottom").css("height",(103*h)/1080);
		$("#PowerLineBottom").css("top",(74*h)/1080);
		$("#PowerLineBottom").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power11").css("width",(317*w)/1920);
		$("#Power11").css("height",(103*h)/1080);
		$("#Power11").css("top",(74*h)/1080);
		$("#Power11").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power12").css("width",(317*w)/1920);
		$("#Power12").css("height",(103*h)/1080);
		$("#Power12").css("top",(74*h)/1080);
		$("#Power12").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power13").css("width",(317*w)/1920);
		$("#Power13").css("height",(103*h)/1080);
		$("#Power13").css("top",(74*h)/1080);
		$("#Power13").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power14").css("width",(317*w)/1920);
		$("#Power14").css("height",(103*h)/1080);
		$("#Power14").css("top",(74*h)/1080);
		$("#Power14").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power15").css("width",(317*w)/1920);
		$("#Power15").css("height",(103*h)/1080);
		$("#Power15").css("top",(74*h)/1080);
		$("#Power15").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power20").css("width",(317*w)/1920);
		$("#Power20").css("height",(103*h)/1080);
		$("#Power20").css("top",(74*h)/1080);
		$("#Power20").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power19").css("width",(317*w)/1920);
		$("#Power19").css("height",(103*h)/1080);
		$("#Power19").css("top",(74*h)/1080);
		$("#Power19").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power18").css("width",(317*w)/1920);
		$("#Power18").css("height",(103*h)/1080);
		$("#Power18").css("top",(74*h)/1080);
		$("#Power18").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power17").css("width",(317*w)/1920);
		$("#Power17").css("height",(103*h)/1080);
		$("#Power17").css("top",(74*h)/1080);
		$("#Power17").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#Power16").css("width",(317*w)/1920);
		$("#Power16").css("height",(103*h)/1080);
		$("#Power16").css("top",(74*h)/1080);
		$("#Power16").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#PowerLineTop").css("width",(317*w)/1920);
		$("#PowerLineTop").css("height",(103*h)/1080);
		$("#PowerLineTop").css("top",(74*h)/1080);
		$("#PowerLineTop").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#BluetoothIcon").css("width",(317*w)/1920);
		$("#BluetoothIcon").css("height",(103*h)/1080);
		$("#BluetoothIcon").css("top",(74*h)/1080);
		$("#BluetoothIcon").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#BatteryIcon").css("width",(317*w)/1920);
		$("#BatteryIcon").css("height",(103*h)/1080);
		$("#BatteryIcon").css("top",(74*h)/1080);
		$("#BatteryIcon").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#ACIcon").css("width",(317*w)/1920);
		$("#ACIcon").css("height",(103*h)/1080);
		$("#ACIcon").css("top",(74*h)/1080);
		$("#ACIcon").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#BatteryLow").css("width",(317*w)/1920);
		$("#BatteryLow").css("height",(103*h)/1080);
		$("#BatteryLow").css("top",(74*h)/1080);
		$("#BatteryLow").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#BatteryMedium").css("width",(317*w)/1920);
		$("#BatteryMedium").css("height",(103*h)/1080);
		$("#BatteryMedium").css("top",(74*h)/1080);
		$("#BatteryMedium").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#BatteryHigh").css("width",(317*w)/1920);
		$("#BatteryHigh").css("height",(103*h)/1080);
		$("#BatteryHigh").css("top",(74*h)/1080);
		$("#BatteryHigh").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#BatteryFull").css("width",(317*w)/1920);
		$("#BatteryFull").css("height",(103*h)/1080);
		$("#BatteryFull").css("top",(74*h)/1080);
		$("#BatteryFull").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#FirmwareUpdate").css("width",(317*w)/1920);
		$("#FirmwareUpdate").css("height",(103*h)/1080);
		$("#FirmwareUpdate").css("top",(74*h)/1080);
		$("#FirmwareUpdate").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#WifiMedium").css("width",(317*w)/1920);
		$("#WifiMedium").css("height",(103*h)/1080);
		$("#WifiMedium").css("top",(74*h)/1080);
		$("#WifiMedium").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#WifiHigh").css("width",(317*w)/1920);
		$("#WifiHigh").css("height",(103*h)/1080);
		$("#WifiHigh").css("top",(74*h)/1080);
		$("#WifiHigh").css("left",((1139*h)/1080)+((w-minw)/2));
		$("#AimingIcon").css("width",(78*w)/1920);
		$("#AimingIcon").css("height",(35*h)/1080);
		$("#AimingIcon").css("top",(169*h)/1080);
		$("#AimingIcon").css("left",((1506*h)/1080)+((w-minw)/2));
		$("#AimingOff").css("width",(78*w)/1920);
		$("#AimingOff").css("height",(35*h)/1080);
		$("#AimingOff").css("top",(169*h)/1080);
		$("#AimingOff").css("left",((1506*h)/1080)+((w-minw)/2));
		$("#AimingLow").css("width",(78*w)/1920);
		$("#AimingLow").css("height",(35*h)/1080);
		$("#AimingLow").css("top",(169*h)/1080);
		$("#AimingLow").css("left",((1506*h)/1080)+((w-minw)/2));
		$("#AimingMedium").css("width",(78*w)/1920);
		$("#AimingMedium").css("height",(35*h)/1080);
		$("#AimingMedium").css("top",(169*h)/1080);
		$("#AimingMedium").css("left",((1506*h)/1080)+((w-minw)/2));
		$("#AimingHigh").css("width",(78*w)/1920);
		$("#AimingHigh").css("height",(35*h)/1080);
		$("#AimingHigh").css("top",(169*h)/1080);
		$("#AimingHigh").css("left",((1506*h)/1080)+((w-minw)/2));
		$("#Laser980Active1").css("width",(78*w)/1920);
		$("#Laser980Active1").css("height",(35*h)/1080);
		$("#Laser980Active1").css("top",(169*h)/1080);
		$("#Laser980Active1").css("left",((1506*h)/1080)+((w-minw)/2));
		$("#Laser980Line").css("width",(198*w)/1920);
		$("#Laser980Line").css("height",(141*h)/1080);
		$("#Laser980Line").css("top",(188*h)/1080);
		$("#Laser980Line").css("left",((1456*h)/1080)+((w-minw)/2));
		$("#Laser980Active1").css("width",(198*w)/1920);
		$("#Laser980Active1").css("height",(141*h)/1080);
		$("#Laser980Active1").css("top",(188*h)/1080);
		$("#Laser980Active1").css("left",((1456*h)/1080)+((w-minw)/2));
		$("#Laser980Wavelength1").css("width",(198*w)/1920);
		$("#Laser980Wavelength1").css("height",(141*h)/1080);
		$("#Laser980Wavelength1").css("top",(188*h)/1080);
		$("#Laser980Wavelength1").css("left",((1456*h)/1080)+((w-minw)/2));
		$("#Laser980Standby").css("width",(198*w)/1920);
		$("#Laser980Standby").css("height",(141*h)/1080);
		$("#Laser980Standby").css("top",(188*h)/1080);
		$("#Laser980Standby").css("left",((1456*h)/1080)+((w-minw)/2));
		$("#PainRelief1").css("width",(172*w)/1920);
		$("#PainRelief1").css("height",(42*h)/1080);
		$("#PainRelief1").css("top",(329*h)/1080);
		$("#PainRelief1").css("left",((1482*h)/1080)+((w-minw)/2));
		$("#Tip7mm").css("width",(156*w)/1920);
		$("#Tip7mm").css("height",(66*h)/1080);
		$("#Tip7mm").css("top",(371*h)/1080);
		$("#Tip7mm").css("left",((1498*h)/1080)+((w-minw)/2));
		$("#Tip3mm").css("width",(156*w)/1920);
		$("#Tip3mm").css("height",(58*h)/1080);
		$("#Tip3mm").css("top",(437*h)/1080);
		$("#Tip3mm").css("left",((1498*h)/1080)+((w-minw)/2));
		$("#Tip25mm").css("width",(156*w)/1920);
		$("#Tip25mm").css("height",(89*h)/1080);
		$("#Tip25mm").css("top",(495*h)/1080);
		$("#Tip25mm").css("left",((1498*h)/1080)+((w-minw)/2));

		$("#up_btn,#up_btn_touched").css("width",(37*2*h)/1080);
		$("#up_btn,#up_btn_touched").css("height",(37*h)/1080);
		$("#up_btn,#up_btn_touched").css("top",(817*h)/1080);
		$("#up_btn,#up_btn_touched").css("left",((1266*h)/1080)+((w-minw)/2));
		$("#down_btn,#down_btn_touched").css("width",(37*2*h)/1080);
		$("#down_btn,#down_btn_touched").css("height",(37*h)/1080);
		$("#down_btn,#down_btn_touched").css("top",(921*h)/1080);
		$("#down_btn,#down_btn_touched").css("left",((1266*h)/1080)+((w-minw)/2));
		$("#left_btn,#left_btn_touched").css("width",(60*h)/1080);
		$("#left_btn,#left_btn_touched").css("height",(60*h)/1080);
		$("#left_btn,#left_btn_touched").css("top",(857*h)/1080);
		$("#left_btn,#left_btn_touched").css("left",((1197*h)/1080)+((w-minw)/2));
		$("#right_btn,#right_btn_touched").css("width",(60*h)/1080);
		$("#right_btn,#right_btn_touched").css("height",(60*h)/1080);
		$("#right_btn,#right_btn_touched").css("top",(857*h)/1080);
		$("#right_btn,#right_btn_touched").css("left",((1351*h)/1080)+((w-minw)/2));
		$("#stop_btn,#stop_btn_touched").css("width",(47*2*h)/1080);
		$("#stop_btn,#stop_btn_touched").css("height",(47*h)/1080);
		$("#stop_btn,#stop_btn_touched").css("top",(864*h)/1080);
		$("#stop_btn,#stop_btn_touched").css("left",((1257*h)/1080)+((w-minw)/2));
		$("#go_btn,#go_btn_touched").css("width",(47*2*h)/1080);
		$("#go_btn,#go_btn_touched").css("height",(47*h)/1080);
		$("#go_btn,#go_btn_touched").css("top",(864*h)/1080);
		$("#go_btn,#go_btn_touched").css("left",((1257*h)/1080)+((w-minw)/2));
		$("#home_btn,#home_btn_touched").css("width",(68*h)/1080);
		$("#home_btn,#home_btn_touched").css("height",(68*h)/1080);
		$("#home_btn,#home_btn_touched").css("top",(776*h)/1080);
		$("#home_btn,#home_btn_touched").css("left",((1020*h)/1080)+((w-minw)/2));
		$("#preset_btn,#preset_btn_touched").css("width",(68*h)/1080);
		$("#preset_btn,#preset_btn_touched").css("height",(68*h)/1080);
		$("#preset_btn,#preset_btn_touched").css("top",(844*h)/1080);
		$("#preset_btn,#preset_btn_touched").css("left",((1020*h)/1080)+((w-minw)/2));
		$("#update_btn,#update_btn_touched").css("width",(68*h)/1080);
		$("#update_btn,#update_btn_touched").css("height",(68*h)/1080);
		$("#update_btn,#update_btn_touched").css("top",(912*h)/1080);
		$("#update_btn,#update_btn_touched").css("left",((1020*h)/1080)+((w-minw)/2));
		$("#w810_btn,#w810_btn_touched").css("width",(50*2.4*h)/1080);
		$("#w810_btn,#w810_btn_touched").css("height",(50*h)/1080);
		$("#w810_btn,#w810_btn_touched").css("top",(770*h)/1080);
		$("#w810_btn,#w810_btn_touched").css("left",((1100*h)/1080)+((w-minw)/2));
		$("#dual_btn,#dual_btn_touched").css("width",(50*2.4*h)/1080);
		$("#dual_btn,#dual_btn_touched").css("height",(50*h)/1080);
		$("#dual_btn,#dual_btn_touched").css("top",(770*h)/1080);
		$("#dual_btn,#dual_btn_touched").css("left",((1241*h)/1080)+((w-minw)/2));
		$("#w980_btn,#w980_btn_touched").css("width",(50*2.4*h)/1080);
		$("#w980_btn,#w980_btn_touched").css("height",(50*h)/1080);
		$("#w980_btn,#w980_btn_touched").css("top",(770*h)/1080);
		$("#w980_btn,#w980_btn_touched").css("left",((1384*h)/1080)+((w-minw)/2));
		$("#haptic_btn,#haptic_btn_touched").css("width",(68*h)/1080);
		$("#haptic_btn,#haptic_btn_touched").css("height",(68*h)/1080);
		$("#haptic_btn,#haptic_btn_touched").css("top",(832*h)/1080);
		$("#haptic_btn,#haptic_btn_touched").css("left",((1124*h)/1080)+((w-minw)/2));
		$("#aiming_btn,#aiming_btn_touched").css("width",(68*h)/1080);
		$("#aiming_btn,#aiming_btn_touched").css("height",(68*h)/1080);
		$("#aiming_btn,#aiming_btn_touched").css("top",(900*h)/1080);
		$("#aiming_btn,#aiming_btn_touched").css("left",((1124*h)/1080)+((w-minw)/2));
		$("#tip_btn,#tip_btn_touched").css("width",(75*h)/1080);
		$("#tip_btn,#tip_btn_touched").css("height",(75*h)/1080);
		$("#tip_btn,#tip_btn_touched").css("top",(825*h)/1080);
		$("#tip_btn,#tip_btn_touched").css("left",((1413*h)/1080)+((w-minw)/2));
		$("#volume_btn,#volume_btn_touched").css("width",(75*h)/1080);
		$("#volume_btn,#volume_btn_touched").css("height",(75*h)/1080);
		$("#volume_btn,#volume_btn_touched").css("top",(900*h)/1080);
		$("#volume_btn,#volume_btn_touched").css("left",((1413*h)/1080)+((w-minw)/2));
		$("#wifi_btn,#wifi_btn_touched").css("width",(68*h)/1080);
		$("#wifi_btn,#wifi_btn_touched").css("height",(68*h)/1080);
		$("#wifi_btn,#wifi_btn_touched").css("top",(776*h)/1080);
		$("#wifi_btn,#wifi_btn_touched").css("left",((1518*h)/1080)+((w-minw)/2));
		$("#menu_btn,#menu_btn_touched").css("width",(68*h)/1080);
		$("#menu_btn,#menu_btn_touched").css("height",(68*h)/1080);
		$("#menu_btn,#menu_btn_touched").css("top",(844*h)/1080);
		$("#menu_btn,#menu_btn_touched").css("left",((1518*h)/1080)+((w-minw)/2));
		$("#voice_btn,#voice_btn_touched").css("width",(68*h)/1080);
		$("#voice_btn,#voice_btn_touched").css("height",(68*h)/1080);
		$("#voice_btn,#voice_btn_touched").css("top",(912*h)/1080);
		$("#voice_btn,#voice_btn_touched").css("left",((1518*h)/1080)+((w-minw)/2));
	}

	render() {

		// var authorized = false;
		//
		// if (this.state.currentUser.payload.email=="ryan.a.stickel@gmail.com" || this.state.currentUser.payload.email=="rimai@azenamedical.com") {
		// 	authorized = true;
		// }

		return (
      <Card className="card-hover">
				<CardBody style={{ height: 800, overflow: "auto" }}>
					{this.state.currentUser.payload["custom:role"] && this.state.currentUser.payload["custom:role"]>1 &&
						<Fragment>
						<div className="text-center mb-2" id="header">
							<Button className="text-center" color="yellow" onClick={this.toggle}>
								Show Debug Logs
							</Button>
						</div>
						</Fragment>
					}
					<div id="glassscreen">
						<div id="parameters">
							<div>Cloud Connection: {this.state.cloud_connection}</div>
							<div>Gemini Connection: {this.state.gemini_connection}</div>
							<div id="serial_num"></div>
							<div id="batch_num"></div>
							<div id="geolocation"></div>
							<div id="firmware_version"></div>
							<div id="firmware_status"></div>
							<div id="wifi_signal"></div>
							<div id="ble_signal"></div>
							<div id="batteryinfo"></div>
							<div id="laserlife"></div>
							<div id="lasercal"></div>
						</div>
						<div id="footpedal_pressed" class="cropped_segments"></div>
						<div id="aiming_tip" class="cropped_segments"></div>
						<div id="illumination_tip" class="cropped_segments"></div>
						<div id="stop_button" class="cropped_segments"></div>
						<div id="on_button" class="cropped_segments"></div>
		        <div id="Hemostasis" class="cropped_segments"></div>
		       	<div id="Debridement" class="cropped_segments"></div>
		       	<div id="HerpeticUlcer" class="cropped_segments"></div>
		       	<div id="AphtousUlcer" class="cropped_segments"></div>
		       	<div id="Decontamination1" class="cropped_segments"></div>
		       	<div id="Decontamination2" class="cropped_segments"></div>
		       	<div id="NonSurgical1" class="cropped_segments"></div>
		       	<div id="NonSurgical2" class="cropped_segments"></div>
		       	<div id="NonSurgical3" class="cropped_segments"></div>
	        	<div id="Laser810Line" class="cropped_segments"></div>
	        	<div id="Laser810Active1" class="cropped_segments"></div>
		        <div id="Laser810Active2" class="cropped_segments"></div>
		       	<div id="Laser810Wavelength1" class="cropped_segments"></div>
		       	<div id="Laser810Wavelength2" class="cropped_segments"></div>
		       	<div id="Laser810Wavelength3" class="cropped_segments"></div>
		       	<div id="Laser810Standby" class="cropped_segments"></div>
		       	<div id="SoundMute" class="cropped_segments"></div>
		       	<div id="SoundLow" class="cropped_segments"></div>
		       	<div id="SoundMedium" class="cropped_segments"></div>
		       	<div id="SoundHigh" class="cropped_segments"></div>
	        	<div id="SoundIcon" class="cropped_segments"></div>
	        	<div id="UltradentLogo" class="cropped_segments"></div>
		        <div id="SpeakerIcon" class="cropped_segments"></div>
		       	<div id="SpeakerMute" class="cropped_segments"></div>
		       	<div id="SpeakerLow" class="cropped_segments"></div>
		       	<div id="SpeakerMedium" class="cropped_segments"></div>
		       	<div id="SpeakerHigh" class="cropped_segments"></div>
		       	<div id="RemoteInterlock" class="cropped_segments"></div>
		       	<div id="WifiLow" class="cropped_segments"></div>
		       	<div id="Power5" class="cropped_segments"></div>
		        <div id="Power4" class="cropped_segments"></div>
		       	<div id="Power3" class="cropped_segments"></div>
		       	<div id="Power2" class="cropped_segments"></div>
		       	<div id="Power1" class="cropped_segments"></div>
		       	<div id="Power6" class="cropped_segments"></div>
		       	<div id="Power7" class="cropped_segments"></div>
		       	<div id="Power8" class="cropped_segments"></div>
		       	<div id="Power9" class="cropped_segments"></div>
		       	<div id="Power10" class="cropped_segments"></div>
	        	<div id="Laser100WPeak" class="cropped_segments"></div>
		        <div id="LeftDigitTop" class="cropped_segments"></div>
		       	<div id="LeftDigitTopLeft" class="cropped_segments"></div>
		       	<div id="LeftDigitCenter" class="cropped_segments"></div>
		       	<div id="LeftDigitBottomLeft" class="cropped_segments"></div>
		       	<div id="LeftDigitBottom" class="cropped_segments"></div>
		       	<div id="LeftDigitBottomRight" class="cropped_segments"></div>
		       	<div id="LeftDigitTopRight" class="cropped_segments"></div>
		       	<div id="VersionIcon" class="cropped_segments"></div>
		       	<div id="DotIcon" class="cropped_segments"></div>
		        <div id="Surgical1" class="cropped_segments"></div>
		       	<div id="Surgical2" class="cropped_segments"></div>
		       	<div id="Surgical3" class="cropped_segments"></div>
		       	<div id="ImplantRecovery" class="cropped_segments"></div>
		       	<div id="ToothExposure" class="cropped_segments"></div>
		       	<div id="Gingivectomy" class="cropped_segments"></div>
		       	<div id="Frenectomy" class="cropped_segments"></div>
		       	<div id="PassError1" class="cropped_segments"></div>
		       	<div id="PassCheck1" class="cropped_segments"></div>
	        	<div id="PassCheck2" class="cropped_segments"></div>
		        <div id="PassError2" class="cropped_segments"></div>
		       	<div id="EnterPasscode" class="cropped_segments"></div>
		       	<div id="PassCheck3" class="cropped_segments"></div>
		       	<div id="PassError3" class="cropped_segments"></div>
		       	<div id="PassCheck4" class="cropped_segments"></div>
		       	<div id="PassError4" class="cropped_segments"></div>
		       	<div id="Troughing" class="cropped_segments"></div>
		       	<div id="Pulpotomy" class="cropped_segments"></div>
		       	<div id="Gingivoplasty" class="cropped_segments"></div>
	        	<div id="Operculectomy" class="cropped_segments"></div>
	        	<div id="IncisionExcision" class="cropped_segments"></div>
		        <div id="TimerInSeconds" class="cropped_segments"></div>
		       	<div id="AverageWatts" class="cropped_segments"></div>
		       	<div id="RightDigitTopLeft" class="cropped_segments"></div>
		       	<div id="RightDigitCenter" class="cropped_segments"></div>
		       	<div id="RightDigitBottomLeft" class="cropped_segments"></div>
		       	<div id="RightDigitBottom" class="cropped_segments"></div>
		       	<div id="RightDigitBottomRight" class="cropped_segments"></div>
		       	<div id="RightDigitTopRight" class="cropped_segments"></div>
		        <div id="RightDigitTop" class="cropped_segments"></div>
		       	<div id="Laser150WPeak" class="cropped_segments"></div>
		       	<div id="PowerLineBottom" class="cropped_segments"></div>
		       	<div id="Power11" class="cropped_segments"></div>
		       	<div id="Power12" class="cropped_segments"></div>
		       	<div id="Power13" class="cropped_segments"></div>
		       	<div id="Power14" class="cropped_segments"></div>
		       	<div id="Power15" class="cropped_segments"></div>
		        <div id="Power20" class="cropped_segments"></div>
		       	<div id="Power19" class="cropped_segments"></div>
		       	<div id="Power18" class="cropped_segments"></div>
		       	<div id="Power17" class="cropped_segments"></div>
		       	<div id="Power16" class="cropped_segments"></div>
		       	<div id="PowerLineTop" class="cropped_segments"></div>
		       	<div id="BluetoothIcon" class="cropped_segments"></div>
		       	<div id="BatteryIcon" class="cropped_segments"></div>
		       	<div id="ACIcon" class="cropped_segments"></div>
	        	<div id="BatteryLow" class="cropped_segments"></div>
		        <div id="BatteryMedium" class="cropped_segments"></div>
		       	<div id="BatteryHigh" class="cropped_segments"></div>
		       	<div id="BatteryFull" class="cropped_segments"></div>
		       	<div id="FirmwareUpdate" class="cropped_segments"></div>
		       	<div id="WifiMedium" class="cropped_segments"></div>
		       	<div id="WifiHigh" class="cropped_segments"></div>
		       	<div id="AimingIcon" class="cropped_segments"></div>
		       	<div id="AimingOff" class="cropped_segments"></div>
		       	<div id="AimingLow" class="cropped_segments"></div>
	        	<div id="AimingMedium" class="cropped_segments"></div>
		        <div id="AimingHigh" class="cropped_segments"></div>
		       	<div id="Laser980Standby" class="cropped_segments"></div>
		       	<div id="Laser980Wavelength1" class="cropped_segments"></div>
		       	<div id="Laser980Wavelength2" class="cropped_segments"></div>
		       	<div id="Laser980Wavelength3" class="cropped_segments"></div>
		       	<div id="Laser980Active1" class="cropped_segments"></div>
		       	<div id="Laser980Active2" class="cropped_segments"></div>
		       	<div id="Laser980Line" class="cropped_segments"></div>
		       	<div id="PainRelief1" class="cropped_segments"></div>
		        <div id="PainRelief2" class="cropped_segments"></div>
		       	<div id="PainRelief3" class="cropped_segments"></div>
		       	<div id="Tip7mm" class="cropped_segments"></div>
		       	<div id="Tip3mm" class="cropped_segments"></div>
		       	<div id="Tip25mm" class="cropped_segments"></div>
						<div id="up_btn" class="cropped_segments" onClick={() => this.upbtnClick()}></div>
						<div id="down_btn" class="cropped_segments" onClick={() => this.downbtnClick()}></div>
						<div id="left_btn" class="cropped_segments" onClick={() => this.leftbtnClick()}></div>
						<div id="right_btn" class="cropped_segments" onClick={() => this.rightbtnClick()}></div>
						<div id="stop_btn" class="cropped_segments" onClick={() => this.stopbtnClick()}></div>
						<div id="go_btn" class="cropped_segments" onClick={() => this.gobtnClick()}></div>
						<div id="home_btn" class="cropped_segments" onClick={() => this.homebtnClick()}></div>
						<div id="preset_btn" class="cropped_segments" onClick={() => this.presetbtnClick()}></div>
						<div id="update_btn" class="cropped_segments" onClick={() => this.updatebtnClick()}></div>
						<div id="w810_btn" class="cropped_segments" onClick={() => this.w810btnClick()}></div>
						<div id="dual_btn" class="cropped_segments" onClick={() => this.dualbtnClick()}></div>
						<div id="w980_btn" class="cropped_segments" onClick={() => this.w980btnClick()}></div>
						<div id="haptic_btn" class="cropped_segments" onClick={() => this.hapticbtnClick()}></div>
						<div id="aiming_btn" class="cropped_segments" onClick={() => this.aimingbtnClick()}></div>
						<div id="tip_btn" class="cropped_segments" onClick={() => this.tipbtnClick()}></div>
						<div id="volume_btn" class="cropped_segments" onClick={() => this.volumebtnClick()}></div>
						<div id="wifi_btn" class="cropped_segments" onClick={() => this.wifibtnClick()}></div>
						<div id="menu_btn" class="cropped_segments" onClick={() => this.menubtnClick()}></div>
						<div id="voice_btn" class="cropped_segments" onClick={() => this.voicebtnClick()}></div>
						<div id="up_btn_touched" class="btn_highlight" onClick={() => this.upbtnClick()}></div>
						<div id="down_btn_touched" class="btn_highlight" onClick={() => this.downbtnClick()}></div>
						<div id="left_btn_touched" class="btn_highlight" onClick={() => this.leftbtnClick()}></div>
						<div id="right_btn_touched" class="btn_highlight" onClick={() => this.rightbtnClick()}></div>
						<div id="stop_btn_touched" class="btn_highlight" onClick={() => this.stopbtnClick()}></div>
						<div id="go_btn_touched" class="btn_highlight" onClick={() => this.gobtnClick()}></div>
						<div id="home_btn_touched" class="btn_highlight" onClick={() => this.homebtnClick()}></div>
						<div id="preset_btn_touched" class="btn_highlight" onClick={() => this.presetbtnClick()}></div>
						<div id="update_btn_touched" class="btn_highlight" onClick={() => this.updatebtnClick()}></div>
						<div id="w810_btn_touched" class="btn_highlight" onClick={() => this.w810btnClick()}></div>
						<div id="dual_btn_touched" class="btn_highlight" onClick={() => this.dualbtnClick()}></div>
						<div id="w980_btn_touched" class="btn_highlight" onClick={() => this.w980btnClick()}></div>
						<div id="haptic_btn_touched" class="btn_highlight" onClick={() => this.hapticbtnClick()}></div>
						<div id="aiming_btn_touched" class="btn_highlight" onClick={() => this.aimingbtnClick()}></div>
						<div id="tip_btn_touched" class="btn_highlight" onClick={() => this.tipbtnClick()}></div>
						<div id="volume_btn_touched" class="btn_highlight" onClick={() => this.volumebtnClick()}></div>
						<div id="wifi_btn_touched" class="btn_highlight" onClick={() => this.wifibtnClick()}></div>
						<div id="menu_btn_touched" class="btn_highlight" onClick={() => this.menubtnClick()}></div>
						<div id="voice_btn_touched" class="btn_highlight" onClick={() => this.voicebtnClick()}></div>
					</div>
		      <div id="lastlogline"></div>
		      <div className="mb-3" id="hiddenpane"></div>
					<div className="mb-2" id="footer">
						{this.state.currentUser.payload["custom:role"] && this.state.currentUser.payload["custom:role"]>1 &&
							<Fragment>
							{this.state.debugData.length > 0 &&
								<Fragment>
								<div style={{ width: "100%", height: "700px", fontSize: "10px", overflow: "auto" }} className="pt-2 mb-3 pl-5 pr-5" id="header">

									<hr color="yellow"></hr>
									<Fragment>
									{this.state.debugData.map((message, i) => (
										<Fragment>
										<div className="pt-2" style={{ width: "100%"}}>
										<span style={{ color: "#348002" }}>{message}</span>
										</div>
										</Fragment>
									))}
									</Fragment>
								</div>
								<div style={{ width: "100%", fontSize: "10px" }} className="pt-2 mb-3 pl-5 pr-5" id="header">
									<hr color="yellow"></hr>
								</div>
								</Fragment>
							}
							</Fragment>
						}
					</div>
        </CardBody>
				<div>
				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>Confirm Authorication</ModalHeader>
					<ModalBody>
						<Row>
							<Col xs="12" md="12" lg="12" className="text-left">
								<h4>Enter password to enable debug logs for unit {this.props.currentDevice}</h4>
							</Col>
						</Row>
						<Form onSubmit={this.handleVerifySubmit}>
							<FormGroup>
								<Label for="debugpasscode">Password</Label>
								<Input type="text" name="debugpasscode" id="debugpasscode"/>
							</FormGroup>
							{this.state.verificationError &&
														<div className={'alert alert-danger'}>Password incorrect.</div>
												}
							<FormGroup>
								<Button color="primary" type="submit">VERIFY</Button>
								<Button color="secondary" className="ml-1" onClick={this.toggle}>CANCEL</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
				</div>
      </Card>
		);
	}
}

export default RemoteSupportView;
