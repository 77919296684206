import React from 'react';
import { Component, Fragment } from "react";
import Moment from 'moment';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner'
import { CometChat } from "@cometchat-pro/chat"
import { incomingMessageAlert } from "../../../CometChat/resources/audio/";
import { Rating } from 'react-simple-star-rating'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
	Nav,
	Collapse,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup,
	Label,
	Col,
	Row,
	Progress,
	Input,
	Fade,
	Badge
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar'

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

//Moving router
import Dashboard from '../../../views/dashboard/dashboard';
import Heartbeat from '../../../views/heartbeat/heartbeat';
import Vitals from '../../../views/vitals/vitals';
import VitalsCustomize from '../../../views/vitals/vitals-customize';
import VitalsDashboard from '../../../views/vitals/vitals-dashboard';
import RemoteSupport from '../../../views/vitals/remotesupport';
import RemoteSupportBlackBox from '../../../views/vitals/blackbox';
import VitalsLogs from '../../../views/vitals/vitals-logs';
import Users from '../../../views/users/users';
import Devices from '../../../views/devices/devices';
import Chat from '../../../views/chat/chat';
import Account from '../../../views/account/account';
import Procedures from '../../../views/procedures/procedures';
import PBMCalculator from '../../../views/pbm-calculator/pbm-calculator';
import Calculator2 from '../../../views/pbm-calculator/calculator-2';
import Calculator3 from '../../../views/pbm-calculator/calculator-3';
import PBMProtocols from '../../../views/pbm-calculator/pbm-protocols';
import FirmwareManagement from '../../../views/firmware-management/firmware-management';
import SoundManagement from '../../../views/sound-management/sound-management';
import AbortedFirmwareManagement from '../../../views/abort-management/abort-management';
import ApprovedUsers from '../../../views/approved-users/approved-users';
import Calibration from '../../../views/support/calibration';
import Downloads from '../../../views/support/downloads';
import Feedback from '../../../views/support/feedback';
import NPSFeedback from '../../../views/support/nps-feedback';
import FootpedalReport from '../../../views/support/footpedal-report';
import TopPerformers from '../../../views/support/top-performers';
import Promotions from '../../../views/support/promotions';
import Survey from '../../../views/support/survey';
import WarrantyRecords from '../../../views/warranty-records/warranty-records';
import DashboardFeedbackView from '../../../views/feedback/feedback';
import BatchShipmentDateUploadView from '../../../views/batch-shipment-date/batch-shipment-date-view';
import StatisticsReportView from '../../../views/statistics-report/statistics-report-view';
import UserMessageLogs from '../../../views/user-message-logs/user-message-logs';
import ErrorMessages from '../../../views/error-messages/error-messages';

import TrainingMaterials from '../../../views/training/training-materials';
import TechSupportVideos from '../../../views/training/tech-support-videos';
import UltradentSeminars from '../../../views/training/ultradent-seminars';
import LaserCETraining from '../../../views/training/laser-ce-training';

//Testing Features
//import TestFeature from '../../../views/test-feature/test-feature';
// {
// 	collapse: false,
// 	open: false,
// 	path: "/test-feature",
// 	name: 'Live Chat',
// 	state: "test feature",
// 	icon: 'mdi mdi-test-tube',
// 	component: TestFeature,
// 	child: [
//
// 	]
// },

import { authenticationService } from '../../../jwt/_services';
import * as mqtt from 'react-paho-mqtt';
import Paho from 'paho-mqtt';

import redpin from '../../../assets/images/pins/red_pin.png';
import greenpin from '../../../assets/images/pins/green_pin.png';
import poweredByAWS from '../../../assets/images/powered-by-aws.png';
import pbmProtocolsAd from '../../../assets/images/pbm-protocols-ad.png';
import warrantyPopup from '../../../assets/images/warranty-popup-image.png';

const mapStateToProps = state => ({
	...state
});

var testCount = 0;

const timer = ms => new Promise(res => setTimeout(res, ms))

class Sidebar extends React.Component {

	intervalID = 0;
	intervalID2 = 1;

	constructor(props) {
		super(props);
		this.expandLogo = this.expandLogo.bind(this);
		this.activeRoute.bind(this);

		const currentUser = authenticationService.currentUserValue;
		console.log("currentUser sidebar: "+JSON.stringify(currentUser));

		this.state = {
			authentication: this.activeRoute("/authentication") !== "" ? true : false,
			uicomponents: this.activeRoute("/ui-components") !== "" ? true : false,
			samplepages: this.activeRoute("/sample-pages") !== "" ? true : false,
			dashboardpages: this.activeRoute("/dashboards") !== "" ? true : false,
			iconsPages: this.activeRoute("/icons") !== "" ? true : false,
			formlayoutPages: this.activeRoute("/form-layouts") !== "" ? true : false,
			formpickerPages: this.activeRoute("/form-pickers") !== "" ? true : false,
			activeSubMenu: [],
			badgeCount: 0,
			chatUser: {},
			currentUser,
			modal: false,
			modal2: false,
			modal3: false,
			modal4: false,
			modal5: false,
			modal6: false,
			ThemeRoutes: [],
			windowWidth: window.innerWidth,
			currentDeviceToCheck: "",
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			deviceListOnline: [],
			statusSubject: "Failed",
			statusMessage: "Failed to send message.",
			responsePending: false,
			isLoggedIn: false,
			approvedTechList: ["team@geminievo.com"],
			approvedTechList2: ["team@geminievo.com", "ryan.a.stickel@gmail.com", "adisessa@mac.com", "jabe@azenamedical.com", "poppy.insixiengmy@ultradent.com", "orlaina.augustine@ultradent.com", "heather.simpson@ultradent.com", "shyann.holliday@ultradent.com"],
			currentRatingValue: 0,
			warrantyPopupRecord: {},
			userNPSFeedbackRecord: JSON.parse(localStorage.getItem('userNPSFeedbackRecord')),
		};

		this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		this.toggle3 = this.toggle3.bind(this);
		this.toggle4 = this.toggle4.bind(this);
		this.toggle5 = this.toggle5.bind(this);
		this.toggle6 = this.toggle6.bind(this);
		this.handleRatingChange = this.handleRatingChange.bind(this);
		console.log("MY sidebar deviceList: "+JSON.stringify(this.state.deviceList));

	}

	componentDidMount() {

		window.addEventListener("resize", this.handleResize);

		this.audio = new Audio(incomingMessageAlert);

		if (!this.state.currentUser.payload['custom:role'] || this.state.currentUser.payload['custom:role']==0) {
			//this.checkSerialNumbers();
			//this.connectToMQTT(this.state.deviceList[0].SerialNumber);

			if (this.state.deviceList != null) {
				//console.log("deviceList: "+JSON.stringify(this.state.deviceList));

				for ( var i = 0; i < this.state.deviceList.length; i++ ) {
					var device = this.state.deviceList[i];
					device.online = false;
					device.last_update = new Date();
					if (this.state.deviceList[i].SerialNumber == this.state.currentDevice) {
						if (this.props.isReceivingMessages) {
							device.online = true;
						}
					}

					this.state.deviceListOnline.push(device);
					//console.log("laser units this.state.deviceListOnline: "+JSON.stringify(this.state.deviceListOnline));
				}

				this.generateThemeRoutes();

				this.connectToMQTT();

				this.intervalID = setInterval(this.getMessage, 3000);
				this.intervalID2 = setInterval(this.generateThemeRoutes.bind(this), 5000);
			} else {
				this.generateThemeRoutes();
			}

		} else {

			//this.connectToMQTT();
			this.generateThemeRoutes();
		}

		this.loginChat();
		this.createChatListener();

		//this.toggle3();
		this.getUserFeedbackRecord();

		if (!this.state.currentUser.payload["custom:role"] || this.state.currentUser.payload["custom:role"]==0) {

			console.log("user deviceList: "+JSON.stringify(this.state.deviceList));

			if (this.state.deviceList && this.state.deviceList.length > 0) {

				var demoDevices = this.state.deviceList.filter(device => device.Kit.toLowerCase() === "demo");

				if (demoDevices.length==0) {
					//this.userNPSFeedbackCheck();
					setTimeout(()=>{ this.userNPSFeedbackCheck(); }, 300);
				}

			}

			setTimeout(()=>{ this.getPBMProtocolsFeedbackRecord(); }, 500);

			setTimeout(()=>{ this.getWarrantyPopupRecord(); }, 500);

		}

		//this.toggle6();

	}

	userNPSFeedbackCheck = async () => {

		if (this.state.userNPSFeedbackRecord.length>0) {

			console.log("sidebar userNPSFeedbackRecord: "+JSON.stringify(this.state.userNPSFeedbackRecord));

			if (this.state.userNPSFeedbackRecord[0].LoginCount>=3 && this.state.userNPSFeedbackRecord[0].Rating==0) {
				console.log("Show Feedback Poll");
				let userNPSNotToday = JSON.parse(localStorage.getItem('userNPSNotToday'));

				if (!userNPSNotToday) {
					this.toggle4();
				}

			}

		}

	}

	handleResize = (e) => {
	  this.setState({ windowWidth: window.innerWidth });
	}

	handleRatingChange = (rate) => {

    console.log("rate: " + rate);

    this.setState({
      currentRatingValue: rate
    });
  };

	loginChat = async () => {
    let authKey = "41791462bc6f3b9eea8086c20d15ccffb606bb70";
		// TEMP KEY FOR TESTING
    var uid = this.state.currentUser.payload.sub;

    var name = this.state.currentUser.payload.email;
		var email = this.state.currentUser.payload.email;
		var role = "default";

		if (this.state.currentUser.payload.given_name.length > 0 || this.state.currentUser.payload.family_name.length > 0) {
			name = this.state.currentUser.payload.given_name+" "+this.state.currentUser.payload.family_name;
		}

    if (this.state.currentUser.payload['custom:role']) {
      //uid = "tech01";
			if (this.state.currentUser.payload['custom:role']>0) {
				var role = "support";

				if (this.state.currentUser.payload.given_name.length > 0) {

					name = "Tech Support: "+this.state.currentUser.payload.given_name;
				}
			}

    }

    var user = new CometChat.User(uid);
    var userExists = false;

		let meta = {
			email
		};

    user.setName(name);
		user.setRole(role);
		user.setMetadata(meta);

		console.log("Chatview Create User...UID: "+uid);

    CometChat.createUser(user, authKey).then(
        userData1 => {
          console.log("Chatview createUser chat user created", userData1);

					CometChat.getLoggedinUser().then(
						user => {
							console.log("Chatview getLoggedinUser: ", user);
					  	if(!user){
								CometChat.login(uid, authKey).then(
			            userData2 => {
			              console.log("Chatview Login Successful:", { userData2 });
										localStorage.setItem('cometchatUser', JSON.stringify(userData2));
										this.setState({chatUser: userData2});
			              this.setState({isLoggedIn: true});
										localStorage.setItem('chatUser', JSON.stringify(userData2));
										localStorage.setItem('isChatLoggedIn', true);
										this.createChatListener();
										userData2.setMetadata(meta);
										CometChat.updateUser(userData2, authKey).then(
										    userData3 => {
										        console.log("CometChat updateUser: ", userData3);
														localStorage.setItem('cometchatUser', JSON.stringify(userData3));
														this.setState({chatUser: userData3});
										    }, error => {
										        console.log("CometChat updateUser error", error);
										    }
										);

			            },
			            error => {
			              console.log("Login failed with exception:", { error });
			            }
			          );
					    }
						}, error => {
							console.log("Chatview getLoggedinUser error: ", error);
						}
					);
        },error => {
					console.log("Chatview createUser chat user error:", {error});

					CometChat.getLoggedinUser().then(
						user => {

							console.log("Chatview getLoggedinUser 2: ", user);

					  	if(!user){
								CometChat.login(uid, authKey).then(
			            userData2 => {
			              console.log("Chatview Login 2 Successful:", { userData2 });
										localStorage.setItem('cometchatUser', JSON.stringify(userData2));
										this.setState({chatUser: userData2});
			              this.setState({isLoggedIn: true});
										localStorage.setItem('chatUser', JSON.stringify(userData2));
										localStorage.setItem('isChatLoggedIn', true);
										this.createChatListener();
										userData2.setMetadata(meta);
										CometChat.updateUser(userData2, authKey).then(
										    userData3 => {
										        console.log("CometChat updateUser 2: ", userData3);
														localStorage.setItem('cometchatUser', JSON.stringify(userData3));
														this.setState({chatUser: userData3});
										    }, error => {
										        console.log("CometChat updateUser 2 error: ", error);
										    }
										);
			            },
			            error => {
			              console.log("Chatview Login 2 failed with exception:", { error });
										//this.loginChat();
			            }
			          );
					    }
						}, error => {
							console.log("Chatview getLoggedinUser 2 error: ", error);
						}
					);
        }
    )

    //var chatUser = CometChat.getUser(uid);
    //this.setState({chatUser})

  }

	// loginChat = async () => {
  //   let authKey = "41791462bc6f3b9eea8086c20d15ccffb606bb70";
	// 	// TEMP KEY FOR TESTING
  //   var uid = this.state.currentUser.payload.sub;
	//
  //   var name = this.state.currentUser.payload.email;
	// 	var role = "default";
	//
	// 	if (this.state.currentUser.payload.given_name.length > 0 || this.state.currentUser.payload.family_name.length > 0) {
	// 		name = this.state.currentUser.payload.given_name+" "+this.state.currentUser.payload.family_name;
	// 	}
	//
  //   if (this.state.currentUser.payload['custom:role']) {
  //     //uid = "tech01";
	// 		if (this.state.currentUser.payload['custom:role']>0) {
	// 			var role = "support";
	//
	// 			if (this.state.currentUser.payload.given_name.length > 0) {
	//
	// 				name = "Tech Support: "+this.state.currentUser.payload.given_name;
	// 			}
	// 		}
	//
  //   }
	//
  //   var user = new CometChat.User(uid);
  //   var userExists = false;
	//
  //   user.setName(name);
	// 	user.setRole(role);
	//
	// 	console.log("Create User...UID: "+uid);
	//
  //   CometChat.createUser(user, authKey).then(
  //       user => {
  //         console.log("chat user created", user);
  //         CometChat.login(uid, authKey).then(
  //           user => {
  //             console.log("Login Successful:", { user });
  //             this.setState({chatUser: user});
  //             this.setState({isLoggedIn: true});
	// 						localStorage.setItem('chatUser', JSON.stringify(user));
	//             localStorage.setItem('isChatLoggedIn', true);
	// 						this.createChatListener();
  //           },
  //           error => {
  //             console.log("Login failed with exception:", { error });
  //           }
  //         );
  //       },error => {
	// 				console.log("chat user error:", {error});
	//
  //         CometChat.login(uid, authKey).then(
  //           user => {
  //             console.log("Login Successful:", { user });
  //             this.setState({chatUser: user});
  //             this.setState({isLoggedIn: true});
	// 						localStorage.setItem('chatUser', JSON.stringify(user));
	//             localStorage.setItem('isChatLoggedIn', true);
	// 						this.createChatListener();
  //           },
  //           error => {
  //             console.log("Login failed with exception:", { error });
  //           }
  //         );
  //       }
  //   )
	//
  //   //var chatUser = CometChat.getUser(uid);
  //   //this.setState({chatUser})
	//
  // }

	createChatListener() {

		var uid = this.state.currentUser.payload.sub;

		CometChat.getUnreadMessageCount().then(
		  array => {
		    console.log("Message count fetched", array);

				var badgeCount = 0;

				for (const property in array.users) {
				  console.log(`${property}: ${array.users[property]}`);

					badgeCount = badgeCount+array.users[property];
				}

				//var badgeCount = array.length;
				this.setState({ badgeCount });
				localStorage.setItem('badgeCount', badgeCount);

		  }, error => {
		    console.log("Error in getting message count", error);
		  }
		);

		let listenerID = "incomingMessagesListener";

		CometChat.addMessageListener(
		  listenerID,
		  new CometChat.MessageListener({
		    onTextMessageReceived: textMessage => {
		      console.log("Text message received successfully", textMessage);
					this.playAudio();
					let badgeCount = JSON.parse(localStorage.getItem('badgeCount'));
					localStorage.setItem('badgeCount', badgeCount+1);
					this.setState({ badgeCount: badgeCount+1 });
					console.log("BadgeCount is: ", badgeCount+1);
		    },
		    onMediaMessageReceived: mediaMessage => {
		      console.log("Media message received successfully", mediaMessage);
					this.playAudio();
					let badgeCount = JSON.parse(localStorage.getItem('badgeCount'));
					localStorage.setItem('badgeCount', badgeCount+1);
					this.setState({ badgeCount: badgeCount+1 });
					console.log("BadgeCount is: ", badgeCount+1);
		    },
		    onCustomMessageReceived: customMessage => {
		      console.log("Custom message received successfully", customMessage);
					this.playAudio();
					let badgeCount = JSON.parse(localStorage.getItem('badgeCount'));
					localStorage.setItem('badgeCount', badgeCount+1);
					this.setState({ badgeCount: badgeCount+1 });
					console.log("BadgeCount is: ", badgeCount+1);
		    }
		  })
		);
	}

	resetBadgeCount = () => {
		this.setState({ badgeCount: 0 });
		localStorage.setItem('badgeCount', 0);
	}

	playAudio = () => {

    //if it is disabled for chat wigdet in dashboard
    if (this.props.hasOwnProperty("widgetsettings")
    && this.props.widgetsettings
    && this.props.widgetsettings.hasOwnProperty("main")
    && (this.props.widgetsettings.main.hasOwnProperty("enable_sound_for_messages") === false
    || (this.props.widgetsettings.main.hasOwnProperty("enable_sound_for_messages")
    && this.props.widgetsettings.main["enable_sound_for_messages"] === false))) {
      return false;
    }

    this.audio.currentTime = 0;
    this.audio.play();
  }

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	toggle2() {
	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

	toggle3() {
	  this.setState({
	    modal3: !this.state.modal3
	  });
	}

	toggle4() {
	  this.setState({
	    modal4: !this.state.modal4
	  });
	}

	toggle5() {
	  this.setState({
	    modal5: !this.state.modal5
	  });
	}

	toggle6() {
	  this.setState({
	    modal6: !this.state.modal6
	  });
	}

	componentWillUnmount() {

		window.removeEventListener("resize", this.handleResize);

		let listenerID = "incomingMessagesListener";
		CometChat.removeMessageListener(listenerID);

		clearInterval(this.intervalID);
		clearInterval(this.intervalID2);

		if (this.state.client && this.state.client.isConnected()) {
			this.state.client.disconnect();
		}

	}

	async checkSerialNumbers () {

		if (localStorage.getItem('currentConnectedDevice')) {
			var currentConnectedDevice = JSON.parse(localStorage.getItem('currentConnectedDevice'));
			//console.log("sidebar currentConnectedDevice..."+currentConnectedDevice);
		}

	  for (var i = 0; i < this.state.deviceList.length; i++) {

			if (this.state.deviceList[i].SerialNumber != currentConnectedDevice) {
				this.connectToMQTT(this.state.deviceList[i].SerialNumber);
				//console.log("number count: "+i);
		    await timer(3000); // then the created Promise can be awaited
			}
	  }
	}

	getMessage = () => {

		//console.log('Sidebar getMessage ');

		for ( var i = 0; i < this.state.deviceListOnline.length; i++ ) {

			var time = new Date() - this.state.deviceListOnline[i].last_update;
			var serial = this.state.deviceList[i].SerialNumber;

			//console.log('Sidebar serial: '+serial+' time is: ' + time);

			if ( new Date() - this.state.deviceListOnline[ i ].last_update > 10000 ) {

				this.state.deviceListOnline[i].online = false;

			}

		}

  }


	connectToMQTT = async () => {
		authenticationService.getDevicesAliveURL()
				.then(
						connectionUrl => {
							//console.log('Sidebar connectToMQTT connectionUrl: ' + JSON.stringify(connectionUrl));

							//var url1 = "wss://a1f3ynjyzalf3r-ats.iot.us-west-2.amazonaws.com/mqtt?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAS3T2QUYOSMZKW4V2%2F20210201%2Fus-west-2%2Fiotdevicegateway%2Faws4_request&X-Amz-Date=20210201T225801Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=dff6d2d6b29772129d10a43adf2b218d07b3cd3423ac13e32c71c6d7f8032eaf";

							if (connectionUrl.status == 'Success') {
								var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
								//console.log('random hash: ' + hash);
								this.state.client = new Paho.Client(connectionUrl.response, hash+this.state.currentUser.payload.sub);
								this.state.client.onMessageArrived = this.onMessageArrived.bind(this);
								this.state.client.onConnectionLost = this.onConnectionLost.bind(this);
								this.state.client.connect({
									timeout: 10,
									useSSL: true,
									mqttVersion: 4,
									cleanSession: true,
									onSuccess: this.onConnect,
									onFailure: (e) => {console.log("here is the error" , e); }
								});
							}
						},
						error => {
							console.log('error.message: ' + error.message);
						}
				);
	}

	onMessageArrived(entry) {
		//this.setState({isReceivingMessages: true});
    //console.log("Sidebar onMessageArrived: "+entry.payloadString);
		const msg = JSON.parse(entry.payloadString);
		//console.log("Sidebar SerialNumber: " +msg.serial_num+ " online");

		for ( var i = 0; i < this.state.deviceListOnline.length; i++ ) {
			if (this.state.deviceListOnline[i].SerialNumber == msg.serial_num) {
				this.state.deviceListOnline[i].online = true;
				this.state.deviceListOnline[i].last_update = new Date();
			}
		}

		//console.log("Sidebar devices Online: " + this.state.deviceListOnline);
  }

  onConnect = () => {
    const { client } = this.state;
    console.log("Sidebar Connected!!!!");
    //client.subscribe('00013/out');
    this.setState({isConnected: true, error: ''})

		//console.log('myCurrentDevice Sidebar id: ' + this.state.currentDeviceToCheck);
		const subChannel = 'I_am_Alive';
		this.state.client.subscribe(subChannel);
  };

	onConnectionLost(responseObject) {
    if (responseObject.errorCode !== 0) {
      console.log("Sidebar onConnectionLost:"+responseObject.errorMessage);
      this.setState({error: 'Lost Connection', isConnected: false});
			//this.onConnect();
    }
  }

	generateThemeRoutes() {

		var deviceList = JSON.parse(localStorage.getItem('deviceList'));

		if (!this.state.currentUser.payload['custom:role'] || this.state.currentUser.payload['custom:role']==0) {
			deviceList = this.state.deviceListOnline;
		}

		//var deviceList = JSON.parse(localStorage.getItem('deviceList'));
		//var deviceList = this.state.deviceListOnline;

		const currentUser = authenticationService.currentUserValue;
		//console.log("currentUser router: "+JSON.stringify(currentUser));

		const isApprovedTech = this.state.approvedTechList.find(techEmail => currentUser.payload.email === techEmail);

		var upgradeLevel = true;

		//UNCOMMENT TO TURN ON USER UPGRADE FUNCTIONALITY
		//var upgradeLevel = JSON.parse(localStorage.getItem('upgradeLevel'));
		//console.log("upgradeLevel router: "+JSON.stringify(upgradeLevel));
		console.log("sidebar deviceList: "+JSON.stringify(deviceList));

		var deviceSubMenuList = [];

		var intlDevices = [];

		if (this.state.deviceList) {
			intlDevices = this.state.deviceList.filter(device => device.Kit.toLowerCase() === "intl");
		}

		//console.log("intlDevices: " + JSON.stringify(intlDevices));
		//console.log("intlDevices.length: " + JSON.stringify(intlDevices.length));

		if (deviceList) {
			for(var i=0; i<deviceList.length; i++) {

				var subchild = [];
				if (currentUser) {
					if (currentUser.payload["custom:role"]) {

						if (currentUser.payload["custom:role"]>1) {
							subchild = [
								{
									path: "/vitals/"+deviceList[i].SerialNumber,
									serial: deviceList[i].SerialNumber,
									name: 'Vitals',
									icon: 'mdi mdi-stethoscope',
									component: Vitals
								},
								{
									path: "/customize/"+deviceList[i].SerialNumber,
									serial: deviceList[i].SerialNumber,
									name: 'Customize',
									icon: 'mdi mdi-message-settings-variant',
									component: VitalsCustomize
								},
								{
									path: "/remotesupport/"+deviceList[i].SerialNumber,
									serial: deviceList[i].SerialNumber,
									name: 'Remote Support',
									icon: 'mdi mdi-lan-connect',
									component: RemoteSupport
								},
								{
									path: "/device-logs/"+deviceList[i].SerialNumber,
									serial: deviceList[i].SerialNumber,
									name: 'Device Logs',
									icon: 'mdi mdi-lan-connect',
									component: VitalsLogs
								}
							]
						} else if (currentUser.payload["custom:role"]==0) {
							subchild = [
								{
									path: "/vitals/"+deviceList[i].SerialNumber,
									serial: deviceList[i].SerialNumber,
									name: 'Vitals',
									icon: 'mdi mdi-stethoscope',
									component: Vitals
								},
								{
									path: "/customize/"+deviceList[i].SerialNumber,
									serial: deviceList[i].SerialNumber,
									name: 'Customize',
									icon: 'mdi mdi-message-settings-variant',
									component: VitalsCustomize
								}
							]
						} else {
							subchild = [
								{
									path: "/vitals/"+deviceList[i].SerialNumber,
									serial: deviceList[i].SerialNumber,
									name: 'Vitals',
									icon: 'mdi mdi-stethoscope',
									component: Vitals
								},
								{
									path: "/customize/"+deviceList[i].SerialNumber,
									serial: deviceList[i].SerialNumber,
									name: 'Customize',
									icon: 'mdi mdi-message-settings-variant',
									component: VitalsCustomize
								},
								{
									path: "/remotesupport/"+deviceList[i].SerialNumber,
									serial: deviceList[i].SerialNumber,
									name: 'Remote Support',
									icon: 'mdi mdi-lan-connect',
									component: RemoteSupport
								}
							]
						}


					} else {
						subchild = [
							{
								path: "/vitals/"+deviceList[i].SerialNumber,
								serial: deviceList[i].SerialNumber,
								name: 'Vitals',
								icon: 'mdi mdi-stethoscope',
								component: Vitals
							},
							{
								path: "/customize/"+deviceList[i].SerialNumber,
								serial: deviceList[i].SerialNumber,
								name: 'Customize',
								icon: 'mdi mdi-message-settings-variant',
								component: VitalsCustomize
							}
						]
					}
				}

				var device = {
					collapse: true,
					name: deviceList[i].Nickname,
					state: deviceList[i].Nickname,
					subchild: subchild,
					online: false
				};

				if (deviceList[i].online) {
					device.online = true;
				}

				// for ( var i = 0; i < this.state.deviceListOnline.length; i++ ) {
				// 	if (this.state.deviceListOnline[i].SerialNumber == deviceList[i].SerialNumber) {
				// 		if (this.state.deviceListOnline[i].online) {
				// 			device.online = true;
				// 		}
				// 	}
				// }


				//console.log("Sidebar New device menu item: "+JSON.stringify(device));
				deviceSubMenuList.push(device);
				//deviceSubMenuList.push(device2);
			}
			//console.log("Sidebar MY deviceList menu: "+JSON.stringify(deviceSubMenuList));
		}

		var ThemeRoutes = [
			{
				collapse: false,
				open: false,
				name: "Dashboard",
				path: "/dashboard",
				state: "dashboard",
				icon: "mdi mdi-view-dashboard",
				component: Dashboard,
				child: [

				]
			},
			{
				navlabel: true,
				name: "No Devices",
				icon: "mdi mdi-file-document-box",
			},
			{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

		];

		if (deviceList) {
			if (deviceList.length>0 && upgradeLevel) {

				//console.log("fulllayout has devicelist");

				if (intlDevices.length>0) {
					//console.log("Has INTL devices");
					ThemeRoutes = [
						{
							collapse: false,
							open: false,
							name: "Dashboard",
							path: "/dashboard",
							state: "dashboard",
							icon: "mdi mdi-view-dashboard",
							component: Dashboard,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Procedure Reports",
							path: "/procedure-reports",
							state: "procedure-reports",
							icon: "mdi mdi-library-books",
							component: Procedures,
							child: [

							]
						},
						{
							collapse: true,
							open: true,
							name: "Your Devices",
							state: "devices",
							icon: "mdi mdi-file-document-box",
							child: deviceSubMenuList
						},
						{
							collapse: true,
							open: true,
							name: "Training",
							state: "training",
							icon: "mdi mdi-message-video",
							child:  [
								{
									path: "/clinical-videos",
									name: 'Clinical Videos',
									icon: 'mdi mdi-message-video',
									component: TrainingMaterials
								},
								{
									path: "/ultradent-seminars",
									name: 'Ultradent Seminars',
									icon: 'mdi mdi-message-video',
									component: UltradentSeminars
								},
								{
									path: "/tech-support-videos",
									name: 'Technical Support Videos',
									icon: 'mdi mdi-message-video',
									component: TechSupportVideos
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Photobiomodulation (PBM)",
							state: "calculators",
							icon: "",
							child:  [
								{
									path: "/pbm-protocols",
									name: 'PBM Protocols',
									icon: 'mdi mdi-cloud-download',
									component: PBMProtocols
								},
								{
									path: "/pbm-calculator",
									name: 'PBM Dosage Calculator',
									icon: 'mdi mdi-calculator',
									component: PBMCalculator
								},
								{
									path: "/dft-calculator",
									name: 'PBM Dosage in Seconds',
									icon: 'mdi mdi-calculator',
									component: Calculator2
								},
								{
									path: "/tfd-calculator",
									name: 'PBM Dosage in J/Cm2',
									icon: 'mdi mdi-calculator',
									component: Calculator3
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Support",
							state: "support",
							icon: "mdi mdi-rocket",
							child:  [
								{
									path: "/downloads",
									name: 'Downloads',
									state: "downloads",
									icon: 'mdi mdi-cloud-download',
									component: Downloads
								},
								{
									path: "/calibration",
									name: 'Calibration',
									state: "calibration",
									icon: 'mdi mdi-chart-bar',
									component: Calibration
								},
								{
									path: "/survey",
									name: 'Survey',
									state: "survey",
									icon: 'mdi mdi-server',
									component: Survey
								},
								{
									path: "/feedback",
									name: 'Feedback',
									state: "feedback",
									icon: 'mdi mdi-contact-mail',
									component: Feedback
								},
							]
						},
						{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

					];
				} else {

					//console.log("Does NOT have INTL devices");

					ThemeRoutes = [
						{
							collapse: false,
							open: false,
							name: "Dashboard",
							path: "/dashboard",
							state: "dashboard",
							icon: "mdi mdi-view-dashboard",
							component: Dashboard,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Procedure Reports",
							path: "/procedure-reports",
							state: "procedure-reports",
							icon: "mdi mdi-library-books",
							component: Procedures,
							child: [

							]
						},
						{
							collapse: true,
							open: true,
							name: "Your Devices",
							state: "devices",
							icon: "mdi mdi-file-document-box",
							child: deviceSubMenuList
						},
						{
							collapse: true,
							open: true,
							name: "Training",
							state: "training",
							icon: "mdi mdi-message-video",
							child:  [
								{
									path: "/clinical-videos",
									name: 'Clinical Videos',
									icon: 'mdi mdi-message-video',
									component: TrainingMaterials
								},
								{
									path: "/laser-ce-training",
									name: 'Free Laser Training',
									icon: 'mdi mdi-message-video',
									component: LaserCETraining
								},
								{
									path: "/ultradent-seminars",
									name: 'Ultradent Seminars',
									icon: 'mdi mdi-message-video',
									component: UltradentSeminars
								},
								{
									path: "/tech-support-videos",
									name: 'Technical Support Videos',
									icon: 'mdi mdi-message-video',
									component: TechSupportVideos
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Photobiomodulation (PBM)",
							state: "calculators",
							icon: "",
							child:  [
								{
									path: "/pbm-protocols",
									name: 'PBM Protocols',
									icon: 'mdi mdi-cloud-download',
									component: PBMProtocols
								},
								{
									path: "/pbm-calculator",
									name: 'PBM Dosage Calculator',
									icon: 'mdi mdi-calculator',
									component: PBMCalculator
								},
								{
									path: "/dft-calculator",
									name: 'PBM Dosage in Seconds',
									icon: 'mdi mdi-calculator',
									component: Calculator2
								},
								{
									path: "/tfd-calculator",
									name: 'PBM Dosage in J/Cm2',
									icon: 'mdi mdi-calculator',
									component: Calculator3
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Support",
							state: "support",
							icon: "mdi mdi-rocket",
							child:  [
								{
									path: "/downloads",
									name: 'Downloads',
									state: "downloads",
									icon: 'mdi mdi-cloud-download',
									component: Downloads
								},
								{
									path: "/calibration",
									name: 'Calibration',
									state: "calibration",
									icon: 'mdi mdi-chart-bar',
									component: Calibration
								},
								{
									path: "/promotions",
									name: 'Promotions',
									state: "promotions",
									icon: 'mdi mdi-gift',
									component: Promotions
								},
								{
									path: "/survey",
									name: 'Survey',
									state: "survey",
									icon: 'mdi mdi-server',
									component: Survey
								},
								{
									path: "/feedback",
									name: 'Feedback',
									state: "feedback",
									icon: 'mdi mdi-contact-mail',
									component: Feedback
								},
								{
									path: "/chat",
									name: 'Tech Support Chat',
									state: "chat support",
									icon: 'mdi mdi-comment-text',
									component: Chat
								},
							]
						},
						{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

					];
				}

			} else {

				if (intlDevices.length>0) {
					ThemeRoutes = [
						{
							collapse: false,
							open: false,
							name: "Dashboard",
							path: "/dashboard",
							state: "dashboard",
							icon: "mdi mdi-view-dashboard",
							component: Dashboard,
							child: [

							]
						},
						{
							navlabel: true,
							name: "No Devices",
							icon: "mdi mdi-file-document-box",
						},
						{
							collapse: true,
							open: true,
							name: "Training",
							state: "training",
							icon: "mdi mdi-message-video",
							child:  [
								{
									path: "/clinical-videos",
									name: 'Clinical Videos',
									icon: 'mdi mdi-message-video',
									component: TrainingMaterials
								},
								{
									path: "/ultradent-seminars",
									name: 'Ultradent Seminars',
									icon: 'mdi mdi-message-video',
									component: UltradentSeminars
								},
								{
									path: "/tech-support-videos",
									name: 'Technical Support Videos',
									icon: 'mdi mdi-message-video',
									component: TechSupportVideos
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Photobiomodulation (PBM)",
							state: "calculators",
							icon: "",
							child:  [
								{
									path: "/pbm-protocols",
									name: 'PBM Protocols',
									icon: 'mdi mdi-cloud-download',
									component: PBMProtocols
								},
								{
									path: "/pbm-calculator",
									name: 'PBM Dosage Calculator',
									icon: 'mdi mdi-calculator',
									component: PBMCalculator
								},
								{
									path: "/dft-calculator",
									name: 'PBM Dosage in Seconds',
									icon: 'mdi mdi-calculator',
									component: Calculator2
								},
								{
									path: "/tfd-calculator",
									name: 'PBM Dosage in J/Cm2',
									icon: 'mdi mdi-calculator',
									component: Calculator3
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Support",
							state: "support",
							icon: "mdi mdi-rocket",
							child:  [
								{
									path: "/downloads",
									name: 'Downloads',
									state: "downloads",
									icon: 'mdi mdi-cloud-download',
									component: Downloads
								},
								{
									path: "/calibration",
									name: 'Calibration',
									state: "calibration",
									icon: 'mdi mdi-chart-bar',
									component: Calibration
								},
								{
									path: "/survey",
									name: 'Survey',
									state: "survey",
									icon: 'mdi mdi-server',
									component: Survey
								},
								{
									path: "/feedback",
									name: 'Feedback',
									state: "feedback",
									icon: 'mdi mdi-contact-mail',
									component: Feedback
								},
								{
									path: "/chat",
									name: 'Tech Support Chat',
									state: "chat support",
									icon: 'mdi mdi-comment-text',
									component: Chat
								},
							]
						},
						{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

					];
				} else {
					ThemeRoutes = [
						{
							collapse: false,
							open: false,
							name: "Dashboard",
							path: "/dashboard",
							state: "dashboard",
							icon: "mdi mdi-view-dashboard",
							component: Dashboard,
							child: [

							]
						},
						{
							navlabel: true,
							name: "No Devices",
							icon: "mdi mdi-file-document-box",
						},
						{
							collapse: true,
							open: true,
							name: "Training",
							state: "training",
							icon: "mdi mdi-message-video",
							child:  [
								{
									path: "/clinical-videos",
									name: 'Clinical Videos',
									icon: 'mdi mdi-message-video',
									component: TrainingMaterials
								},
								{
									path: "/laser-ce-training",
									name: 'Free Laser Training',
									icon: 'mdi mdi-message-video',
									component: LaserCETraining
								},
								{
									path: "/ultradent-seminars",
									name: 'Ultradent Seminars',
									icon: 'mdi mdi-message-video',
									component: UltradentSeminars
								},
								{
									path: "/tech-support-videos",
									name: 'Technical Support Videos',
									icon: 'mdi mdi-message-video',
									component: TechSupportVideos
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Photobiomodulation (PBM)",
							state: "calculators",
							icon: "",
							child:  [
								{
									path: "/pbm-protocols",
									name: 'PBM Protocols',
									icon: 'mdi mdi-cloud-download',
									component: PBMProtocols
								},
								{
									path: "/pbm-calculator",
									name: 'PBM Dosage Calculator',
									icon: 'mdi mdi-calculator',
									component: PBMCalculator
								},
								{
									path: "/dft-calculator",
									name: 'PBM Dosage in Seconds',
									icon: 'mdi mdi-calculator',
									component: Calculator2
								},
								{
									path: "/tfd-calculator",
									name: 'PBM Dosage in J/Cm2',
									icon: 'mdi mdi-calculator',
									component: Calculator3
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Support",
							state: "support",
							icon: "mdi mdi-rocket",
							child:  [
								{
									path: "/downloads",
									name: 'Downloads',
									state: "downloads",
									icon: 'mdi mdi-cloud-download',
									component: Downloads
								},
								{
									path: "/calibration",
									name: 'Calibration',
									state: "calibration",
									icon: 'mdi mdi-chart-bar',
									component: Calibration
								},
								{
									path: "/promotions",
									name: 'Promotions',
									state: "promotions",
									icon: 'mdi mdi-gift',
									component: Promotions
								},
								{
									path: "/survey",
									name: 'Survey',
									state: "survey",
									icon: 'mdi mdi-server',
									component: Survey
								},
								{
									path: "/feedback",
									name: 'Feedback',
									state: "feedback",
									icon: 'mdi mdi-contact-mail',
									component: Feedback
								},
								{
									path: "/chat",
									name: 'Tech Support Chat',
									state: "chat support",
									icon: 'mdi mdi-comment-text',
									component: Chat
								},
							]
						},
						{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

					];
				}

			}
		} else {
			ThemeRoutes = [
				{
					collapse: false,
					open: false,
					name: "Dashboard",
					path: "/dashboard",
					state: "dashboard",
					icon: "mdi mdi-view-dashboard",
					component: Dashboard,
					child: [

					]
				},
				{
					navlabel: true,
					name: "No Devices",
					icon: "mdi mdi-file-document-box",
				},
				{
					collapse: true,
					open: true,
					name: "Training",
					state: "training",
					icon: "mdi mdi-message-video",
					child:  [
						{
							path: "/clinical-videos",
							name: 'Clinical Videos',
							icon: 'mdi mdi-message-video',
							component: TrainingMaterials
						},
						{
							path: "/laser-ce-training",
							name: 'Free Laser Training',
							icon: 'mdi mdi-message-video',
							component: LaserCETraining
						},
						{
							path: "/ultradent-seminars",
							name: 'Ultradent Seminars',
							icon: 'mdi mdi-message-video',
							component: UltradentSeminars
						},
						{
							path: "/tech-support-videos",
							name: 'Technical Support Videos',
							icon: 'mdi mdi-message-video',
							component: TechSupportVideos
						},
					]
				},
				{
					collapse: true,
					open: true,
					name: "Photobiomodulation (PBM)",
					state: "calculators",
					icon: "",
					child:  [
						{
							path: "/pbm-protocols",
							name: 'PBM Protocols',
							icon: 'mdi mdi-cloud-download',
							component: PBMProtocols
						},
						{
							path: "/pbm-calculator",
							name: 'PBM Dosage Calculator',
							icon: 'mdi mdi-calculator',
							component: PBMCalculator
						},
						{
							path: "/dft-calculator",
							name: 'PBM Dosage in Seconds',
							icon: 'mdi mdi-calculator',
							component: Calculator2
						},
						{
							path: "/tfd-calculator",
							name: 'PBM Dosage in J/Cm2',
							icon: 'mdi mdi-calculator',
							component: Calculator3
						},
					]
				},
				{
					collapse: true,
					open: true,
					name: "Support",
					state: "support",
					icon: "mdi mdi-rocket",
					child:  [
						{
							path: "/downloads",
							name: 'Downloads',
							state: "downloads",
							icon: 'mdi mdi-cloud-download',
							component: Downloads
						},
						{
							path: "/calibration",
							name: 'Calibration',
							state: "calibration",
							icon: 'mdi mdi-chart-bar',
							component: Calibration
						},
						{
							path: "/promotions",
							name: 'Promotions',
							state: "promotions",
							icon: 'mdi mdi-gift',
							component: Promotions
						},
						{
							path: "/survey",
							name: 'Survey',
							state: "survey",
							icon: 'mdi mdi-server',
							component: Survey
						},
						{
							path: "/feedback",
							name: 'Feedback',
							state: "feedback",
							icon: 'mdi mdi-contact-mail',
							component: Feedback
						},
						{
							path: "/chat",
							name: 'Tech Support Chat',
							state: "chat support",
							icon: 'mdi mdi-comment-text',
							component: Chat
						},
					]
				},
				{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

			];
		}

		if (currentUser) {
			if (currentUser.payload["custom:role"] && currentUser.payload["custom:role"]==1) {

				if (isApprovedTech) {
					ThemeRoutes = [
						{
							collapse: false,
							open: false,
							path: "/users",
							name: "Users",
							state: "Users",
							icon: "mdi mdi-account-multiple",
							component: Users,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/user-message-logs",
							name: "User Message Logs",
							state: "user-message-logs",
							icon: "mdi mdi-google-circles-extended",
							component: UserMessageLogs,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/devices",
							name: "Devices",
							state: "Devices",
							icon: "mdi mdi-google-circles-extended",
							component: Devices,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/heartbeat",
							name: "Heartbeat",
							state: "Heartbeat",
							icon: "mdi mdi-heart-pulse",
							component: Heartbeat,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Tech Support Chat",
							path: "/chat",
							state: "chat",
							icon: "mdi mdi-comment-text",
							component: Chat,
							child: [

							]
						},
						{
							collapse: true,
							open: true,
							name: "Training",
							state: "training",
							icon: "mdi mdi-message-video",
							child:  [
								{
									path: "/clinical-videos",
									name: 'Clinical Videos',
									icon: 'mdi mdi-message-video',
									component: TrainingMaterials
								},
								{
									path: "/laser-ce-training",
									name: 'Free Laser Training',
									icon: 'mdi mdi-message-video',
									component: LaserCETraining
								},
								{
									path: "/ultradent-seminars",
									name: 'Ultradent Seminars',
									icon: 'mdi mdi-message-video',
									component: UltradentSeminars
								},
								{
									path: "/tech-support-videos",
									name: 'Technical Support Videos',
									icon: 'mdi mdi-message-video',
									component: TechSupportVideos
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Photobiomodulation (PBM)",
							state: "calculators",
							icon: "",
							child:  [
								{
									path: "/pbm-protocols",
									name: 'PBM Protocols',
									icon: 'mdi mdi-cloud-download',
									component: PBMProtocols
								},
								{
									path: "/pbm-calculator",
									name: 'PBM Dosage Calculator',
									icon: 'mdi mdi-calculator',
									component: PBMCalculator
								},
								{
									path: "/dft-calculator",
									name: 'PBM Dosage in Seconds',
									icon: 'mdi mdi-calculator',
									component: Calculator2
								},
								{
									path: "/tfd-calculator",
									name: 'PBM Dosage in J/Cm2',
									icon: 'mdi mdi-calculator',
									component: Calculator3
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Support",
							state: "support",
							icon: "mdi mdi-rocket",
							child:  [
								{
									path: "/downloads",
									name: 'Downloads',
									state: "downloads",
									icon: 'mdi mdi-cloud-download',
									component: Downloads
								},
								{
									path: "/calibration",
									name: 'Calibration',
									state: "calibration",
									icon: 'mdi mdi-chart-bar',
									component: Calibration
								},
								{
									path: "/promotions",
									name: 'Promotions',
									state: "promotions",
									icon: 'mdi mdi-gift',
									component: Promotions
								},
								{
									path: "/survey",
									name: 'Survey',
									state: "survey",
									icon: 'mdi mdi-server',
									component: Survey
								},
								{
									path: "/feedback",
									name: 'Feedback',
									state: "feedback",
									icon: 'mdi mdi-contact-mail',
									component: Feedback
								},
							]
						},
						{ path: '/', pathTo: '/users', name: 'Users', redirect: true }

					];
				} else {
					ThemeRoutes = [
						{
							collapse: false,
							open: false,
							path: "/users",
							name: "Users",
							state: "Users",
							icon: "mdi mdi-account-multiple",
							component: Users,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/user-message-logs",
							name: "User Message Logs",
							state: "user-message-logs",
							icon: "mdi mdi-google-circles-extended",
							component: UserMessageLogs,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/devices",
							name: "Devices",
							state: "Devices",
							icon: "mdi mdi-google-circles-extended",
							component: Devices,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/heartbeat",
							name: "Heartbeat",
							state: "Heartbeat",
							icon: "mdi mdi-heart-pulse",
							component: Heartbeat,
							child: [

							]
						},
						{
							collapse: true,
							open: true,
							name: "Training",
							state: "training",
							icon: "mdi mdi-message-video",
							child:  [
								{
									path: "/clinical-videos",
									name: 'Clinical Videos',
									icon: 'mdi mdi-message-video',
									component: TrainingMaterials
								},
								{
									path: "/laser-ce-training",
									name: 'Free Laser Training',
									icon: 'mdi mdi-message-video',
									component: LaserCETraining
								},
								{
									path: "/ultradent-seminars",
									name: 'Ultradent Seminars',
									icon: 'mdi mdi-message-video',
									component: UltradentSeminars
								},
								{
									path: "/tech-support-videos",
									name: 'Technical Support Videos',
									icon: 'mdi mdi-message-video',
									component: TechSupportVideos
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Photobiomodulation (PBM)",
							state: "calculators",
							icon: "",
							child:  [
								{
									path: "/pbm-protocols",
									name: 'PBM Protocols',
									icon: 'mdi mdi-cloud-download',
									component: PBMProtocols
								},
								{
									path: "/pbm-calculator",
									name: 'PBM Dosage Calculator',
									icon: 'mdi mdi-calculator',
									component: PBMCalculator
								},
								{
									path: "/dft-calculator",
									name: 'PBM Dosage in Seconds',
									icon: 'mdi mdi-calculator',
									component: Calculator2
								},
								{
									path: "/tfd-calculator",
									name: 'PBM Dosage in J/Cm2',
									icon: 'mdi mdi-calculator',
									component: Calculator3
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Support",
							state: "support",
							icon: "mdi mdi-rocket",
							child:  [
								{
									path: "/downloads",
									name: 'Downloads',
									state: "downloads",
									icon: 'mdi mdi-cloud-download',
									component: Downloads
								},
								{
									path: "/calibration",
									name: 'Calibration',
									state: "calibration",
									icon: 'mdi mdi-chart-bar',
									component: Calibration
								},
								{
									path: "/promotions",
									name: 'Promotions',
									state: "promotions",
									icon: 'mdi mdi-gift',
									component: Promotions
								},
								{
									path: "/survey",
									name: 'Survey',
									state: "survey",
									icon: 'mdi mdi-server',
									component: Survey
								},
								{
									path: "/feedback",
									name: 'Feedback',
									state: "feedback",
									icon: 'mdi mdi-contact-mail',
									component: Feedback
								},
							]
						},
						{ path: '/', pathTo: '/users', name: 'Users', redirect: true }

					];
				}

				if (currentUser.payload['custom:market'] && currentUser.payload['custom:market']=="us-azena" && currentUser.payload['custom:role'] && currentUser.payload['custom:role']==1) {
					ThemeRoutes = [
						{
							collapse: false,
							open: false,
							path: "/users",
							name: "Users",
							state: "Users",
							icon: "mdi mdi-account-multiple",
							component: Users,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/user-message-logs",
							name: "User Message Logs",
							state: "user-message-logs",
							icon: "mdi mdi-google-circles-extended",
							component: UserMessageLogs,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/devices",
							name: "Devices",
							state: "Devices",
							icon: "mdi mdi-google-circles-extended",
							component: Devices,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/heartbeat",
							name: "Heartbeat",
							state: "Heartbeat",
							icon: "mdi mdi-heart-pulse",
							component: Heartbeat,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Procedure Reports",
							path: "/procedure-reports",
							state: "procedure-reports",
							icon: "mdi mdi-library-books",
							component: Procedures,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Footpedal Report",
							path: "/footpedal-report",
							state: "footpedal-report",
							icon: "mdi mdi-library-books",
							component: FootpedalReport,
							child: [

							]
						},
						{
							collapse: true,
							open: true,
							name: "Training",
							state: "training",
							icon: "mdi mdi-message-video",
							child:  [
								{
									path: "/clinical-videos",
									name: 'Clinical Videos',
									icon: 'mdi mdi-message-video',
									component: TrainingMaterials
								},
								{
									path: "/laser-ce-training",
									name: 'Free Laser Training',
									icon: 'mdi mdi-message-video',
									component: LaserCETraining
								},
								{
									path: "/ultradent-seminars",
									name: 'Ultradent Seminars',
									icon: 'mdi mdi-message-video',
									component: UltradentSeminars
								},
								{
									path: "/tech-support-videos",
									name: 'Technical Support Videos',
									icon: 'mdi mdi-message-video',
									component: TechSupportVideos
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Photobiomodulation (PBM)",
							state: "calculators",
							icon: "",
							child:  [
								{
									path: "/pbm-protocols",
									name: 'PBM Protocols',
									icon: 'mdi mdi-cloud-download',
									component: PBMProtocols
								},
								{
									path: "/pbm-calculator",
									name: 'PBM Dosage Calculator',
									icon: 'mdi mdi-calculator',
									component: PBMCalculator
								},
								{
									path: "/dft-calculator",
									name: 'PBM Dosage in Seconds',
									icon: 'mdi mdi-calculator',
									component: Calculator2
								},
								{
									path: "/tfd-calculator",
									name: 'PBM Dosage in J/Cm2',
									icon: 'mdi mdi-calculator',
									component: Calculator3
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Support",
							state: "support",
							icon: "mdi mdi-rocket",
							child:  [
								{
									path: "/downloads",
									name: 'Downloads',
									state: "downloads",
									icon: 'mdi mdi-cloud-download',
									component: Downloads
								},
								{
									path: "/calibration",
									name: 'Calibration',
									state: "calibration",
									icon: 'mdi mdi-chart-bar',
									component: Calibration
								},
								{
									collapse: false,
									open: false,
									path: "/warranty-records",
									name: "Warranty Records",
									state: "warranty-records",
									icon: "mdi mdi-shield-outline",
									component: WarrantyRecords,
									child: [

									]
								},
								{
									path: "/promotions",
									name: 'Promotions',
									state: "promotions",
									icon: 'mdi mdi-gift',
									component: Promotions
								},
								{
									path: "/survey",
									name: 'Survey',
									state: "survey",
									icon: 'mdi mdi-server',
									component: Survey
								},
								{
									path: "/feedback",
									name: 'Feedback',
									state: "feedback",
									icon: 'mdi mdi-contact-mail',
									component: Feedback
								},
							]
						},
						{ path: '/', pathTo: '/users', name: 'Users', redirect: true }

					];
				}

			} else if (currentUser.payload["custom:role"] && currentUser.payload["custom:role"]>1) {

				if (isApprovedTech) {
					ThemeRoutes = [
						{
							collapse: false,
							open: false,
							name: "Dashboard",
							path: "/dashboard",
							state: "dashboard",
							icon: "mdi mdi-view-dashboard",
							component: Dashboard,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Chat Support",
							path: "/chat",
							state: "chat",
							icon: "mdi mdi-comment-text",
							component: Chat,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Procedure Reports",
							path: "/procedure-reports",
							state: "procedure-reports",
							icon: "mdi mdi-library-books",
							component: Procedures,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Top Performers",
							path: "/top-performers",
							state: "top-performers",
							icon: "mdi mdi-trophy-outline",
							component: TopPerformers,
							child: [

							]
						},
						{
							collapse: true,
							open: true,
							name: "Photobiomodulation (PBM)",
							state: "calculators",
							icon: "",
							child:  [
								{
									path: "/pbm-protocols",
									name: 'PBM Protocols',
									icon: 'mdi mdi-cloud-download',
									component: PBMProtocols
								},
								{
									path: "/pbm-calculator",
									name: 'PBM Dosage Calculator',
									icon: 'mdi mdi-calculator',
									component: PBMCalculator
								},
								{
									path: "/dft-calculator",
									name: 'PBM Dosage in Seconds',
									icon: 'mdi mdi-calculator',
									component: Calculator2
								},
								{
									path: "/tfd-calculator",
									name: 'PBM Dosage in J/Cm2',
									icon: 'mdi mdi-calculator',
									component: Calculator3
								},
							]
						},
						{
							collapse: false,
							open: false,
							path: "/heartbeat",
							name: "Heartbeat",
							state: "Heartbeat",
							icon: "mdi mdi-heart-pulse",
							component: Heartbeat,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/users",
							name: "Users",
							state: "Users",
							icon: "mdi mdi-account-multiple",
							component: Users,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/user-message-logs",
							name: "User Message Logs",
							state: "user-message-logs",
							icon: "mdi mdi-google-circles-extended",
							component: UserMessageLogs,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/devices",
							name: "Devices",
							state: "Devices",
							icon: "mdi mdi-google-circles-extended",
							component: Devices,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/error-messages",
							name: "Error Messages",
							state: "error-messages",
							icon: "mdi mdi-google-circles-extended",
							component: ErrorMessages,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/warranty-records",
							name: "Warranty Records",
							state: "warranty-records",
							icon: "mdi mdi-shield-outline",
							component: WarrantyRecords,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/calibration",
							name: "Calibration",
							state: "calibration",
							icon: "mdi mdi-chart-bar",
							component: Calibration,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "User Feedback",
							path: "/user-feedback",
							state: "user-feedback",
							icon: "mdi mdi-library-books",
							component: DashboardFeedbackView,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "NPS User Feedback",
							path: "/nps-user-feedback",
							state: "nps-user-feedback",
							icon: "mdi mdi-library-books",
							component: NPSFeedback,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Footpedal Report",
							path: "/footpedal-report",
							state: "footpedal-report",
							icon: "mdi mdi-library-books",
							component: FootpedalReport,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Purchase Date Upload",
							path: "/batch-shipment-date-upload",
							state: "batch-shipment-date-upload",
							icon: "mdi mdi-library-books",
							component: BatchShipmentDateUploadView,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Statistics Report",
							path: "/statistics-report",
							state: "statistics-report",
							icon: "mdi mdi-library-books",
							component: StatisticsReportView,
							child: [

							]
						},
						{
							collapse: true,
							open: true,
							name: "Software Update",
							state: "software-update",
							icon: "mdi mdi-briefcase-upload",
							child:  [
								{
									name: "Firmware Management",
									path: "/firmware-management",
									icon: "mdi mdi-briefcase-upload",
									component: FirmwareManagement,
								},
								{
									name: "Sound Management",
									path: "/sound-management",
									icon: "mdi mdi-briefcase-upload",
									component: SoundManagement,
								},
								{
									name: "Approved Users",
									path: "/approved-users",
									icon: "mdi mdi-briefcase-upload",
									component: ApprovedUsers,
								},
								{
									name: "Aborted Firmware",
									path: "/aborted-firmware",
									icon: "mdi mdi-briefcase-upload",
									component: AbortedFirmwareManagement,
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Support",
							state: "support",
							icon: "mdi mdi-rocket",
							child:  [
								{
									path: "/downloads",
									name: 'Downloads',
									state: "downloads",
									icon: 'mdi mdi-cloud-download',
									component: Downloads
								},
								{
									path: "/promotions",
									name: 'Promotions',
									state: "promotions",
									icon: 'mdi mdi-gift',
									component: Promotions
								},
								{
									path: "/survey",
									name: 'Survey',
									state: "survey",
									icon: 'mdi mdi-server',
									component: Survey
								},
								{
									path: "/feedback",
									name: 'Feedback',
									state: "feedback",
									icon: 'mdi mdi-contact-mail',
									component: Feedback
								},
							]
						},
						{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

					];
				} else {
					ThemeRoutes = [
						{
							collapse: false,
							open: false,
							name: "Dashboard",
							path: "/dashboard",
							state: "dashboard",
							icon: "mdi mdi-view-dashboard",
							component: Dashboard,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Procedure Reports",
							path: "/procedure-reports",
							state: "procedure-reports",
							icon: "mdi mdi-library-books",
							component: Procedures,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Top Performers",
							path: "/top-performers",
							state: "top-performers",
							icon: "mdi mdi-trophy-outline",
							component: TopPerformers,
							child: [

							]
						},
						{
							collapse: true,
							open: true,
							name: "Photobiomodulation (PBM)",
							state: "calculators",
							icon: "",
							child:  [
								{
									path: "/pbm-protocols",
									name: 'PBM Protocols',
									icon: 'mdi mdi-cloud-download',
									component: PBMProtocols
								},
								{
									path: "/pbm-calculator",
									name: 'PBM Dosage Calculator',
									icon: 'mdi mdi-calculator',
									component: PBMCalculator
								},
								{
									path: "/dft-calculator",
									name: 'PBM Dosage in Seconds',
									icon: 'mdi mdi-calculator',
									component: Calculator2
								},
								{
									path: "/tfd-calculator",
									name: 'PBM Dosage in J/Cm2',
									icon: 'mdi mdi-calculator',
									component: Calculator3
								},
							]
						},
						{
							collapse: false,
							open: false,
							path: "/heartbeat",
							name: "Heartbeat",
							state: "Heartbeat",
							icon: "mdi mdi-heart-pulse",
							component: Heartbeat,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/users",
							name: "Users",
							state: "Users",
							icon: "mdi mdi-account-multiple",
							component: Users,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/user-message-logs",
							name: "User Message Logs",
							state: "user-message-logs",
							icon: "mdi mdi-google-circles-extended",
							component: UserMessageLogs,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/devices",
							name: "Devices",
							state: "Devices",
							icon: "mdi mdi-google-circles-extended",
							component: Devices,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/error-messages",
							name: "Error Messages",
							state: "error-messages",
							icon: "mdi mdi-google-circles-extended",
							component: ErrorMessages,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/warranty-records",
							name: "Warranty Records",
							state: "warranty-records",
							icon: "mdi mdi-shield-outline",
							component: WarrantyRecords,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							path: "/calibration",
							name: "Calibration",
							state: "calibration",
							icon: "mdi mdi-chart-bar",
							component: Calibration,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "User Feedback",
							path: "/user-feedback",
							state: "user-feedback",
							icon: "mdi mdi-library-books",
							component: DashboardFeedbackView,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "NPS User Feedback",
							path: "/nps-user-feedback",
							state: "nps-user-feedback",
							icon: "mdi mdi-library-books",
							component: NPSFeedback,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Footpedal Report",
							path: "/footpedal-report",
							state: "footpedal-report",
							icon: "mdi mdi-library-books",
							component: FootpedalReport,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Purchase Date Upload",
							path: "/batch-shipment-date-upload",
							state: "batch-shipment-date-upload",
							icon: "mdi mdi-library-books",
							component: BatchShipmentDateUploadView,
							child: [

							]
						},
						{
							collapse: false,
							open: false,
							name: "Statistics Report",
							path: "/statistics-report",
							state: "statistics-report",
							icon: "mdi mdi-library-books",
							component: StatisticsReportView,
							child: [

							]
						},
						{
							collapse: true,
							open: true,
							name: "Software Update",
							state: "software-update",
							icon: "mdi mdi-briefcase-upload",
							child:  [
								{
									name: "Firmware Management",
									path: "/firmware-management",
									icon: "mdi mdi-briefcase-upload",
									component: FirmwareManagement,
								},
								{
									name: "Sound Management",
									path: "/sound-management",
									icon: "mdi mdi-briefcase-upload",
									component: SoundManagement,
								},
								{
									name: "Approved Users",
									path: "/approved-users",
									icon: "mdi mdi-briefcase-upload",
									component: ApprovedUsers,
								},
								{
									name: "Aborted Firmware",
									path: "/aborted-firmware",
									icon: "mdi mdi-briefcase-upload",
									component: AbortedFirmwareManagement,
								},
							]
						},
						{
							collapse: true,
							open: true,
							name: "Support",
							state: "support",
							icon: "mdi mdi-rocket",
							child:  [
								{
									path: "/downloads",
									name: 'Downloads',
									state: "downloads",
									icon: 'mdi mdi-cloud-download',
									component: Downloads
								},
								{
									path: "/promotions",
									name: 'Promotions',
									state: "promotions",
									icon: 'mdi mdi-gift',
									component: Promotions
								},
								{
									path: "/survey",
									name: 'Survey',
									state: "survey",
									icon: 'mdi mdi-server',
									component: Survey
								},
								{
									path: "/feedback",
									name: 'Feedback',
									state: "feedback",
									icon: 'mdi mdi-contact-mail',
									component: Feedback
								},
							]
						},
						{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }

					];
				}

			}
		}

		this.setState({ ThemeRoutes });
	}


	/*--------------------------------------------------------------------------------*/
	/*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
	/*--------------------------------------------------------------------------------*/
	expandLogo() {
		document.getElementById("logobg").classList.toggle("expand-logo");
	}

	updateExpanded(seconddd, propstate) {

		this.setState(seconddd);

		if (this.state.activeSubMenu.length > 0) {
			for ( var i = 0; i < this.state.activeSubMenu.length; i++ ) {
				if (this.state.activeSubMenu[i].name != propstate) {
					let submenu = {};
					submenu[this.state.activeSubMenu[i].name] = false;
					this.setState(submenu);
				}
			}
		}
	}
	/*--------------------------------------------------------------------------------*/
	/*Verifies if routeName is the one active (in browser input)                      */
	/*--------------------------------------------------------------------------------*/
	activeRoute(routeName) { return this.props.location.pathname.indexOf(routeName) > -1 ? 'selected' : ''; }


	submitRequest = (event) => {
		event.preventDefault();

		this.setState({ responsePending: true });

		console.log("submitRequest");
		let serialNumber = event.target.serialnumber.value;
    console.log(`serialNumber is:`, serialNumber);

		this.getDeviceToken(serialNumber);
	}

	getDeviceToken = async (serialNumber) => {

		var url = "https://ww7drce55l.execute-api.us-west-2.amazonaws.com/v1/get-keys?SerialNumber="+serialNumber;

		console.log("getDeviceToken URL: " + url);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("getDeviceToken response: " + JSON.stringify(body));

		if (body.status == 'Success') {

			navigator.clipboard.writeText(JSON.stringify(body));

			this.toggle();
			this.setState({
				statusSubject: "Success!",
				statusMessage: "Successfully Retrieved Key and Copied to Your Clipboard!"
			})

		} else {

			this.toggle();
			this.setState({
				statusSubject: "FAILED!",
				statusMessage: "Failed to get Key."
			})

		}

		this.setState({ responsePending: false });

		this.toggle2();

	};

	getUserNPSFeedbackRecord = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-dashboard-user-nps-feedback-record";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});
		const body = await response.json();
		console.log("getUserNPSFeedbackRecord: " + JSON.stringify(body));

		if (body.status == 'Success') {
			if (body.response.length > 0) {

			} else {
				//this.noResults = true;
				//this.toggle4();
			}
		}
	};

	submitNPSFeedback = (event) => {
		event.preventDefault();

    console.log("Submit Feedback");
		let name = event.target.name.value;
    console.log(`name is:`, name);
    let email = event.target.email.value;
    console.log(`email is:`, email);
    var pollselection = event.target.pollselection;
    //console.log(`radio1 is:`, radio1);

		var pollselectionValue;

		for (var i = 0; i < pollselection.length; i++) {
      if (pollselection[i].checked) {
        pollselectionValue = pollselection[i].value;
      }
    }

		console.log(`pollselectionValue:`, pollselectionValue);

    var feedbackRecord = {
      Name: name,
      Email: email,
      Rating: pollselectionValue,
			RatingDate: Moment().format('YYYY-MM-DD'),
			LoginCount: this.state.userNPSFeedbackRecord[0] ? this.state.userNPSFeedbackRecord[0].LoginCount : 1
    };

		console.log(`feedbackRecord is:`, feedbackRecord);

		//this.toggle4();

    this.saveNPSFeedbackRecord(feedbackRecord);
	}

	saveNPSFeedbackRecord = async (feedbackRecord) => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"save-dashboard-user-nps-feedback-record";

		console.log("createFeedbackRecord URL: " + url);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: this.state.currentUser.jwtToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(feedbackRecord)
    });

		const body = await response.json();
		console.log("saveNPSFeedbackRecord response: " + JSON.stringify(body));

		if (body.status == 'Success') {

      // var feedbackRecordID = body.response.insertId;
			//
      // this.saveAllProcedureItems(feedbackRecordID);

			this.toggle4();

			let msg = "Feedback successfully submitted!";

			toast.success(msg, {
				position: "top-center",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
				closeButton: false
			});

		} else {

			let msg = "Sorry, your feedback was not submitted. Please try again.";

			toast.error(msg, {
				position: "top-center",
				autoClose: 3000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
				closeButton: false
			});

		}

	};

	userNPSNotToday = () => {
			localStorage.setItem('userNPSNotToday', true);
			this.toggle4();
	}

	getUserFeedbackRecord = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-dashboard-user-feedback-record";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});
		const body = await response.json();
		console.log("getUserFeedbackRecord: " + JSON.stringify(body));

		if (body.status == 'Success') {
			if (body.response.length > 0) {

			} else {
				//this.noResults = true;
				this.toggle3();
			}
		}
	};

	submitFeedback = (event) => {
		event.preventDefault();

    console.log("Submit Feedback");
		let name = event.target.name.value;
    console.log(`name is:`, name);
    let email = event.target.email.value;
    console.log(`email is:`, email);
    var notExperienced = event.target.NotExperienced.checked;
    console.log(`notExperienced is:`, notExperienced);

		var feedback = event.target.feedback.value;
    console.log(`feedback is:`, feedback);

    var feedbackRecord = {
      Name: name,
      Email: email,
      Rating: this.state.currentRatingValue,
      Feedback: feedback,
      Declined: false,
			NotExperienced: notExperienced,
    };

		console.log(`feedbackRecord is:`, feedbackRecord);

		//this.toggle3();

    this.createFeedbackRecord(feedbackRecord, false);
	}

	submitDeclinedFeedback = (name, email) => {

    console.log("Submit Declined Feedback");
    console.log(`name is:`, name);
    console.log(`email is:`, email);

		var feedback = document.getElementById("feedback").value;
		var notExperienced = document.getElementById("NotExperienced").checked;

    var feedbackRecord = {
      Name: name,
      Email: email,
      Rating: this.state.currentRatingValue,
      Feedback: feedback ? feedback : null,
      Declined: true,
			NotExperienced: notExperienced,
    };

		console.log(`submitDeclinedFeedback is:`, feedbackRecord);

		this.toggle3();

    this.createFeedbackRecord(feedbackRecord, true);

	}

	createFeedbackRecord = async (feedbackRecord, declined) => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-dashboard-user-feedback-record";

		console.log("createFeedbackRecord URL: " + url);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: this.state.currentUser.jwtToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(feedbackRecord)
    });

		const body = await response.json();
		console.log("createFeedbackRecord response: " + JSON.stringify(body));

		if (body.status == 'Success') {

      // var feedbackRecordID = body.response.insertId;
			//
      // this.saveAllProcedureItems(feedbackRecordID);

			if (!declined) {

				this.toggle3();

				let msg = "Feedback successfully submitted!";

		    toast.success(msg, {
		      position: "top-center",
		      autoClose: 3000,
		      hideProgressBar: true,
		      closeOnClick: true,
		      pauseOnHover: true,
		      draggable: true,
		      progress: undefined,
		      theme: "dark",
		      closeButton: false
		    });
			}

		} else {

			this.toggle5();

			if (!declined) {

	      let msg = "Sorry, your feedback was not submitted. Please try again.";

	      toast.error(msg, {
	        position: "top-center",
	        autoClose: 3000,
	        hideProgressBar: true,
	        closeOnClick: true,
	        pauseOnHover: true,
	        draggable: true,
	        progress: undefined,
	        theme: "dark",
	        closeButton: false
	      });

			}

		}

	};

	getPBMProtocolsFeedbackRecord = async () => {

		//var email = this.state.currentUser.payload.email;

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-pbm-protocols-ad-feedback-record";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});
			const body = await response.json();
			console.log("getPBMProtocolsFeedbackRecord: " + JSON.stringify(body));

			if (body.status == 'Success') {
				if (body.results.length > 0) {

				} else {
					//this.noResults = true;
					this.toggle5();
				}
			}

		} catch (err) {
			console.log("getPBMProtocolsFeedbackRecord error: "+err);
		}

	};

	submitPBMPopUpClicked = (name, email, status) => {

    console.log("Submit Declined Feedback");
    console.log(`name is:`, name);
    console.log(`email is:`, email);
		console.log(`status is:`, status);

    var pbmRecord = {
      name: name,
      email: email,
      status: status,
    };

		console.log(`submitPBMPopUpClicked is:`, pbmRecord);

		this.toggle5();

    this.createPBMPopUpClickedRecord(pbmRecord);

	}

	createPBMPopUpClickedRecord = async (pbmRecord) => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-pbm-protocols-ad-feedback?Status="+pbmRecord.status;

		console.log("createFeedbackRecord URL: " + url);

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});
			const body = await response.json();

			console.log("createPBMPopUpClickedRecord response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {

			}

			if (pbmRecord.status==1) {
				//window.location.href="/pbm-protocols";
				const { from } = this.props.location.state || { from: { pathname: "/pbm-protocols" } };
				this.props.history.push(from);
			}

		} catch (err) {
			console.log("createPBMPopUpClickedRecord error: "+err);
		}

	};

	getWarrantyPopupRecord = async () => {

		//var email = this.state.currentUser.payload.email;

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-warranty-email-drip-record";

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});
			const body = await response.json();
			console.log("getWarrantyPopupRecord: " + JSON.stringify(body));

			if (body.status == 'Success') {
				if (body.results.length > 0) {

					var record = body.results[0];

					this.setState({ warrantyPopupRecord: record });

					if (record.PopupStatus!=0) {
						this.toggle6();
					}

				} else {
					//this.noResults = true;
					//this.toggle5();
				}
			}

		} catch (err) {
			console.log("getWarrantyPopupRecord error: "+err);
		}

	};

	submitWarrantyPopUpClicked = (status) => {

    console.log("Submit Declined Feedback");
    //console.log(`name is:`, name);
    //console.log(`email is:`, email);
		console.log(`status is:`, status);

    var warrantyRecord = {
      status: status,
    };

		console.log(`submitWarrantyPopUpClicked is:`, warrantyRecord);

		this.toggle6();

    this.updateWarrantyPopUpRecord(warrantyRecord);

	}

	updateWarrantyPopUpRecord = async (warrantyRecord) => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"update-warranty-email-drip-record?PopupStatus=0";

		console.log("createFeedbackRecord URL: " + url);

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});
			const body = await response.json();

			console.log("updateWarrantyPopUpRecord response: " + JSON.stringify(body));

			if (body.status == 'Success') {

			} else {

			}

			if (warrantyRecord.status==1) {
				//window.location.href="/pbm-protocols";

				var path = "/customize/" + this.state.warrantyPopupRecord.SerialNumber;

				const { from } = this.props.location.state || { from: { pathname: path } };
				this.props.history.push(from);
			}

		} catch (err) {
			console.log("updateWarrantyPopUpRecord error: "+err);
		}

	};

	render() {

		const { windowWidth } = this.state;

		var emailStr = "N/A";
		var nameStr = "N/A";

		emailStr = this.state.currentUser.payload.email;
		nameStr = this.state.currentUser.payload.given_name+" "+this.state.currentUser.payload.family_name;

		console.log("emailStr: "+emailStr);
		console.log("nameStr: "+nameStr);

		var pbmProtocolsPath = "/pbm-protocols";

		return (
			<Fragment>
			<aside className="left-sidebar" id="sidebarbg" data-sidebarbg={this.props.settings.activeSidebarBg} onMouseEnter={this.expandLogo} onMouseLeave={this.expandLogo}>
				<div className="scroll-sidebar">
					<PerfectScrollbar className="sidebar-nav pb-5">
						{/*--------------------------------------------------------------------------------*/}
						{/* Sidebar Menus will go here                                                */}
						{/*--------------------------------------------------------------------------------*/}
						<Nav id="sidebarnav">
							{this.state.ThemeRoutes.map((prop, key) => {
								if (prop.redirect) {
									return null;
								}
								else if (prop.navlabel) {
									return (
										<li className="nav-small-cap" key={key}>
											<i className={prop.icon}></i>
											<span className="hide-menu">{prop.name}</span>
										</li>
									);
								}
								else if (prop.collapse) {
									let firstdd = {};
									firstdd[prop["state"]] = !this.state[prop.state];
									//console.log("this.state[prop.state]: "+JSON.stringify(!this.state[prop.state]));
									//console.log("firstdd: "+JSON.stringify(firstdd));
									return (
										/*--------------------------------------------------------------------------------*/
										/* Menus wiil be goes here                                                        */
										/*--------------------------------------------------------------------------------*/
										<Fragment>
										{(!this.state.currentUser.payload['custom:role'] || this.state.currentUser.payload['custom:role']<2) && prop.name=='PBM Calculator' &&
											<div style={{ width: '100%', height: '2px', backgroundColor: "#ffc72c", margin: '25px' }} id="line"></div>
										}
										<li className={this.activeRoute(prop.path) + ' sidebar-item'} key={key}>
											<span data-toggle="collapse" className="sidebar-link has-arrow" aria-expanded={this.state[prop.state]} onClick={() => this.setState(firstdd)} >
												{prop.icon.length > 0 ? (
													<Fragment>
														<i className={prop.icon} />
														<span className="hide-menu">{prop.name}</span>
													</Fragment>
												) : (
													<span className="hide-menu pl-2">{prop.name}</span>
												)}
											</span>
											{/*--------------------------------------------------------------------------------*/}
											{/* Sub-Menus wiil be goes here                                                    */}
											{/*--------------------------------------------------------------------------------*/}
											<Collapse isOpen={this.state[prop.state]}>
												<ul className="first-level">
													{prop.child.map((prop, key) => {
														if (prop.redirect) return null;
														if (prop.collapse) {
															let seconddd = {};
															seconddd[prop["state"]] = !this.state[prop.state];
															//console.log("this.state[prop.state]: "+JSON.stringify(!this.state[prop.state]));
															//console.log("seconddd: "+JSON.stringify(seconddd));

															var item = {
																name: prop["state"],
																isOpen: !this.state[prop.state],
															};

															if (this.state.activeSubMenu.length > 0) {
																let exists = false;
																for ( var i = 0; i < this.state.activeSubMenu.length; i++ ) {
																	if (this.state.activeSubMenu[i].item == prop["state"]) {
																		exists = true;
																	}
																}
																if (!exists) {
																	this.state.activeSubMenu.push(item)
																}
															} else {
																this.state.activeSubMenu.push(item)
															}

															//console.log("activeSubMenu: "+JSON.stringify(this.state.activeSubMenu));
															return (
																<li className={this.activeRoute(prop.path) + ' sidebar-item'} key={key} >
																	<span data-toggle="collapse" className="sidebar-link has-arrow" aria-expanded={this.state[prop.state]} onClick={() => this.updateExpanded(seconddd, prop["state"])} >
																		<i className={prop.icon} />
																		{prop.online ? (
																			<Fragment>
																			<img className="mr-2" src={greenpin} width="20" height="20"/>
																			</Fragment>
																		) : (
																			<Fragment>
																			<img className="mr-2" src={redpin} width="20" height="20"/>
																			</Fragment>
																		)}
																		<span className="hide-menu">{prop.name}</span>
																	</span>
																	{/*--------------------------------------------------------------------------------*/}
																	{/* Sub-Menus wiil be goes here                                                    */}
																	{/*--------------------------------------------------------------------------------*/}
																	<Collapse isOpen={this.state[prop.state]}>
																		<ul className="second-level">
																			{prop.subchild.map((prop, key) => {
																				if (prop.redirect) return null;
																				return (
																					<li className={this.activeRoute(prop.path) + ' sidebar-item'} key={key} >
																						<NavLink to={prop.path} activeClassName="active" className="sidebar-link">
																							<i className={prop.icon} />
																							<span className="hide-menu">{prop.name}</span>
																						</NavLink>
																					</li>
																				);
																			})}
																		</ul>
																	</Collapse>
																</li>
															);
														}

														if (prop.path=='/chat') {
															return (
																/*--------------------------------------------------------------------------------*/
																/* Adding Sidebar Item                                                            */
																/*--------------------------------------------------------------------------------*/
																<Fragment>
																<li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'+' pl-3'} key={key}>
																	<NavLink to={prop.path} className="sidebar-link" activeClassName="active" onClick={()=>{this.resetBadgeCount()}}>
																		<i className={prop.icon} />
																		<span className="hide-menu">{prop.name}</span>
																		{this.state.badgeCount>0 &&
																			<h5><Badge className="ml-2" color="danger" pill>{this.state.badgeCount}</Badge></h5>
																		}
																	</NavLink>
																</li>
																</Fragment>
															);
														}
														return (
															/*--------------------------------------------------------------------------------*/
															/* Adding Sidebar Item                                                            */
															/*--------------------------------------------------------------------------------*/
															<Fragment>
															<li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'+' pl-3'} key={key}>
																<NavLink to={prop.path} className="sidebar-link" activeClassName="active">
																	{prop.icon.length > 0 ? (
																		<Fragment>
																			<i className={prop.icon} />
																			<span className="hide-menu">{prop.name}</span>
																		</Fragment>
																	) : (
																		<span className="hide-menu">{prop.name}</span>
																	)}
																</NavLink>
															</li>
															</Fragment>
														);
													})}
												</ul>
											</Collapse>
										</li>
										</Fragment>
									);
								}
								else {

									if (prop.path=='/chat') {
										return (
											/*--------------------------------------------------------------------------------*/
											/* Adding Sidebar Item                                                            */
											/*--------------------------------------------------------------------------------*/
											<Fragment>
											<li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
												<NavLink to={prop.path} className="sidebar-link" activeClassName="active" onClick={()=>{this.resetBadgeCount()}}>
													<i className={prop.icon} />
													<span className="hide-menu">{prop.name}</span>
													{this.state.badgeCount>0 &&
														<h5><Badge className="ml-2" color="danger" pill>{this.state.badgeCount}</Badge></h5>
													}
												</NavLink>
											</li>
											</Fragment>
										);
									}

									return (
										/*--------------------------------------------------------------------------------*/
										/* Adding Sidebar Item                                                            */
										/*--------------------------------------------------------------------------------*/
										<li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
											<NavLink to={prop.path} className="sidebar-link" activeClassName="active">
												<i className={prop.icon} />
												<span className="hide-menu">{prop.name}</span>
											</NavLink>
										</li>
									);

								}
							})}

							{this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role'] > 0 &&
								<li className="sidebar-item" key="1000000">
									<NavLink to="#" className="sidebar-link" activeClassName="active" onClick={() => {this.toggle()}}>
										<i className="mdi mdi-memory" />
										<span className="hide-menu">Retrieve Device Token</span>
									</NavLink>
								</li>
							}
						</Nav>

					</PerfectScrollbar>
					{
						<div style={{ width: "100%", position: "absolute", bottom: 15, height: "45px", backgroundColor: "#000" }}>
							<img style={{ position: "relative", display: "block", marginLeft: "auto", marginRight: "auto" }} className="" src={poweredByAWS} width="175" height="44"/>
						</div>
					}
				</div>
				<div>
					<Modal isOpen={this.state.modal} toggle={this.toggle}>
						<ModalHeader toggle={this.toggle}>Encryption Key</ModalHeader>
						<Form onSubmit={this.submitRequest}>
						<ModalBody>
							{this.state.responsePending ? (
								<Fragment>
									<Fade in="true">
									<Row className="justify-content-center">
										<Loader type="ThreeDots" color="#48b203" height={100} width={100}/>
									</Row>
									</Fade>
								</Fragment>
							) : (
								<Fragment>
									<Fade in="true">
									<Row>
										<Col xs="12" md="12" lg="12" className="text-left pb-3">
											<h4>Retrieve Token Key For a Device.</h4>
										</Col>
									</Row>
									<FormGroup>
										<Label for="subject">Serial Number:</Label>
										<Input type="text" name="serialnumber" id="serialnumber"/>
									</FormGroup>
									</Fade>
								</Fragment>
							)}
						</ModalBody>
						<ModalFooter>
							<Button color="primary" className="ml-1" onClick={this.toggle} disabled={this.state.responsePending}>Cancel</Button>
							<Button color="primary" type="submit" disabled={this.state.responsePending}>Retrieve</Button>
						</ModalFooter>
						</Form>
					</Modal>
				</div>
				<div>
					<Modal isOpen={this.state.modal2} toggle={this.toggle2}>
						<ModalHeader toggle={this.toggle2}>{this.state.statusSubject}</ModalHeader>
						<ModalBody>
							<span>{this.state.statusMessage}</span>
						</ModalBody>
						<ModalFooter>
							<Button color="primary" className="ml-1" onClick={this.toggle2}>Done</Button>
						</ModalFooter>
					</Modal>
				</div>
				<div>
					<Modal isOpen={this.state.modal3}>
						<ModalHeader>Can you provide us quick feedback?</ModalHeader>
						<Form onSubmit={this.submitFeedback}>
						<Input type="hidden" name="email" id="email" defaultValue={emailStr}/>
						<Input type="hidden" name="name" id="name" defaultValue={nameStr}/>
						<ModalBody>
							<Label>How do you like the Dashboard?</Label>
							<Row className="text-center mt-1 mb-4">
								<Col>
									<Rating style={{ marginLeft: "auto", marginRight: "auto"}} onClick={this.handleRatingChange} size={50} ratingValue={this.state.currentRatingValue} readonly={false} transition={false} />
								</Col>
							</Row>
							<hr/>
							<Row style={{ height: "25px" }}>
								<Col md="12">
									<Input className="ml-1" type="checkbox" name="NotExperienced" id="NotExperienced"/><Label className="ml-4" style={{ lineHeight: "1.6" }}>Not enough use yet to provide feedback</Label>
								</Col>
							</Row>
							<hr/>
							<Label>Do you have suggestions on what can be improved?</Label>
							<Input style={{width:'100%', height: '150px'}} type="textarea" name="feedback" id="feedback"/>
						</ModalBody>
						<ModalFooter>
							<div style={{ width: '50%' }} className="text-left">
								<Button color="primary" style={{ float: 'left' }} className="" onClick={() => {this.submitDeclinedFeedback(nameStr, emailStr);}}>NOT TODAY</Button>
							</div>
							<div style={{ width: '50%' }} className="text-right">
								<Button color="primary" style={{ float: 'right' }} type="submit">SEND</Button>
							</div>
						</ModalFooter>
						</Form>
					</Modal>
				</div>
				<div>
					<Modal isOpen={this.state.modal4}>
						<ModalHeader>Can you provide us quick feedback?</ModalHeader>
						<Form onSubmit={this.submitNPSFeedback}>
						<Input type="hidden" name="email" id="email" defaultValue={emailStr}/>
						<Input type="hidden" name="name" id="name" defaultValue={nameStr}/>
						<ModalBody>
							<Label>On a scale of 1 to 10, how likely are you to recommend the Gemini EVO Soft Tissue Laser to a friend or colleague?</Label>
							<Label>(1 = highly unlikely, 10 = highly likely)</Label>
							<Row className="text-center mt-1 mb-4">
								<Col lg="1">
								</Col>
								<Col lg="1">
									<Row>
										<Label>1</Label>
									</Row>
									<Row>
										<FormGroup check>
											<Input type="radio" name="pollselection"  value="1"/>
										</FormGroup>
									</Row>
								</Col>
								<Col lg="1">
									<Row>
										<Label>2</Label>
									</Row>
									<Row>
										<FormGroup check>
											<Input type="radio" name="pollselection"  value="2"/>
										</FormGroup>
									</Row>
								</Col>
								<Col lg="1">
									<Row>
										<Label>3</Label>
									</Row>
									<Row>
										<FormGroup check>
											<Input type="radio" name="pollselection"  value="3"/>
										</FormGroup>
									</Row>
								</Col>
								<Col lg="1">
									<Row>
										<Label>4</Label>
									</Row>
									<Row>
										<FormGroup check>
											<Input type="radio" name="pollselection"  value="4"/>
										</FormGroup>
									</Row>
								</Col>
								<Col lg="1">
									<Row>
										<Label>5</Label>
									</Row>
									<Row>
										<FormGroup check>
											<Input type="radio" name="pollselection"  value="5"/>
										</FormGroup>
									</Row>
								</Col>
								<Col lg="1">
									<Row>
										<Label>6</Label>
									</Row>
									<Row>
										<FormGroup check>
											<Input type="radio" name="pollselection"  value="6"/>
										</FormGroup>
									</Row>
								</Col>
								<Col lg="1">
									<Row>
										<Label>7</Label>
									</Row>
									<Row>
										<FormGroup check>
											<Input type="radio" name="pollselection"  value="7"/>
										</FormGroup>
									</Row>
								</Col>
								<Col lg="1">
									<Row>
										<Label>8</Label>
									</Row>
									<Row>
										<FormGroup check>
											<Input type="radio" name="pollselection"  value="8"/>
										</FormGroup>
									</Row>
								</Col>
								<Col lg="1">
									<Row>
										<Label>9</Label>
									</Row>
									<Row>
										<FormGroup check>
											<Input type="radio" name="pollselection"  value="9"/>
										</FormGroup>
									</Row>
								</Col>
								<Col lg="1">
									<Row>
										<Label>10</Label>
									</Row>
									<Row>
										<FormGroup check>
											<Input type="radio" name="pollselection"  value="10"/>
										</FormGroup>
									</Row>
								</Col>
							</Row>
						</ModalBody>
						<ModalFooter>
							<div style={{ width: '50%' }} className="text-left">
								<Button color="primary" style={{ float: 'left' }} className="" onClick={() => {this.userNPSNotToday();}}>NOT TODAY</Button>
							</div>
							<div style={{ width: '50%' }} className="text-right">
								<Button color="primary" style={{ float: 'right' }} type="submit">SEND</Button>
							</div>
						</ModalFooter>
						</Form>
					</Modal>
				</div>
				<div>
					<Modal isOpen={this.state.modal5} toggle={this.toggle5} contentClassName={"custom-modal-style-pbm"}>
						<Input type="hidden" name="email" id="email" defaultValue={emailStr}/>
						<Input type="hidden" name="name" id="name" defaultValue={nameStr}/>
						<ModalBody>
							<img style={{ position: "relative", display: "block", marginLeft: "auto", marginRight: "auto" }} className="" src={pbmProtocolsAd} width="100%"/>
						</ModalBody>
						<ModalFooter>
							<div style={{ width: '50%' }} className="text-left">
								<Button color="primary" style={{ float: 'left' }} className="" onClick={() => {this.submitPBMPopUpClicked(nameStr, emailStr, 0);}}>Not Today</Button>
							</div>
							<div style={{ width: '50%' }} className="text-right">
								<Button color="primary" style={{ float: 'right' }} onClick={() => {this.submitPBMPopUpClicked(nameStr, emailStr, 1);}}>Access Now</Button>
							</div>
						</ModalFooter>
					</Modal>
				</div>
				<div>
					<Modal isOpen={this.state.modal6} toggle={this.toggle6} contentClassName={"custom-modal-style-pbm"}>
						<Input type="hidden" name="email" id="email" defaultValue={emailStr}/>
						<Input type="hidden" name="name" id="name" defaultValue={nameStr}/>
						<ModalBody>
							<div className="text-center" style={{ color: "#fff" }}>
							<h3>Secure Your Investment</h3>
							<h4>Your Gemini EVO is eligible for extended warranty. Click below to explore options.</h4>
							</div>
							<img style={{ position: "relative", display: "block", marginLeft: "auto", marginRight: "auto" }} className="" src={warrantyPopup} width="100%"/>
						</ModalBody>
						<ModalFooter>
							<div style={{ width: '50%' }} className="text-left">
								<Button color="primary" style={{ float: 'left' }} className="" onClick={() => {this.submitWarrantyPopUpClicked(0);}}>Not Today</Button>
							</div>
							<div style={{ width: '50%' }} className="text-right">
								<Button color="primary" style={{ float: 'right' }} onClick={() => {this.submitWarrantyPopUpClicked(1);}}>Explore Now</Button>
							</div>
						</ModalFooter>
					</Modal>
				</div>
			</aside>
			<ToastContainer />
			</Fragment>
		);
	}
}
export default connect(mapStateToProps)(Sidebar);
