import React from "react";
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Row,
	Col,
	Button,

} from 'reactstrap';
import Iframe from 'react-iframe'
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as mqtt from 'react-paho-mqtt';
import Paho from 'paho-mqtt';
import { getConnectionURL } from "../../mqtt-helpers/mqtt-request";
import { authenticationService } from '../../jwt/_services';
import ReactGA from "react-ga4";

import {
  ExtendedWarranty,
} from '../../components/vitals-customize/';

class WarrantyMobile extends React.Component {

	constructor(props) {
    super(props);

		// const currentUser = authenticationService.currentUserValue;
		// console.log('currentUser info: ' + JSON.stringify(currentUser));
		// console.log('currentUser info: ' + JSON.stringify(currentUser.token));

		const query = new URLSearchParams(this.props.location.search);
    const token = query.get('token')
    console.log("token: "+token)//123

		const currentUser = { jwtToken: token };

    if (token) {
      if (token.length>0) {
        console.log("authToken: "+token)
        this.setState({currentUser: { jwtToken: token }});
				const currentUser = { jwtToken: token };
      }
    }

    this.state = {
			mainMessage: [''],
			subMessage: [''],
			currentUser,
      messageToSend:'',
			currentDevice: this.props.match.params.id,
      isConnected: false,
			devices: [''],
	  };

    this.iframeURL = 'https://www.geminilaser.com/dashboard/support/g2_remote.php?sn='+this.state.currentDevice;
		//this.iframeURL = 'https://www.google.com';
  }

	componentDidMount() {
		ReactGA.pageview(window.location.pathname);

	}

	render() {
		var customizePath = "/customize/"+this.state.currentDevice;
    var vitalsPath = "/vitals/"+this.state.currentDevice;
		var vitalsDashboardPath = "/vitals-dashboard/"+this.state.currentDevice;
		var deviceLogs = "/device-logs/"+this.state.currentDevice;
		return (
			<div>
				<Row style={{ height: "1000px" }}>
					<Col xs="12" md="12" lg="12">
						<ExtendedWarranty currentDevice={this.state.currentDevice} currentUser={this.state.currentUser} height={1000} />
					</Col>
				</Row>
			</div>
		);
	}
}

export default WarrantyMobile;
