import React from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';

class RemoteSupport extends React.Component {

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 3,
			totalProcedures: 0,
	  };

  }

	async componentDidMount() {

	}

	render() {
		var remoteSupportPath = "/remotesupport/"+this.props.currentDevice;
		return (
      <Card className="card-hover">
				<CardBody style={{ minHeight: 210 }}>
					<CardTitle>Interactive Remote Support</CardTitle>
					<Row className="pt-5">
						<Col xs="1" md="1" lg="1">
						</Col>
						<Col xs="10" md="10" lg="10">
							<Button style={{width: '100%'}} color="brightgreen" href={remoteSupportPath}>
								LAUNCH
							</Button>
						</Col>
						<Col xs="1" md="1" lg="1">
						</Col>
					</Row>
        </CardBody>
      </Card>
		);
	}
}

export default RemoteSupport;
