import React from "react";
import { Component, Fragment } from "react";
import {
	Progress,
	Card,
	CardBody,
  CardTitle,
	Input,
	Row,
	Col,
	Button,
	ButtonGroup,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ButtonDropdown,
	Fade
} from 'reactstrap';

import img1 from '../../../assets/images/background/active-bg.png';
import { Formik, Field, Form, ErrorMessage } from 'formik';

var currency_list = [
		{"name":"US Dollar","code":"USD","symbol":"$"},
		{"name":"Argentine Peso","code":"ARS","symbol":"$"},
		{"name":"Argentine Peso","code":"ARS","symbol":"$"},
	    {"name":"Australian Dollar","code":"AUD","symbol":"$"},
	    {"name":"Belgian Franc","code":"BEF","symbol":"fr"},
	    {"name":"Bolivian Boliviano","code":"BOB","symbol":"Bs."},
	    {"name":"Brazilian Real","code":"BRL","symbol":"R$"},
	    {"name":"British Pound Sterling","code":"GBP","symbol":"£"},
     {"name":"Canadian Dollar","code":"CAD","symbol":"$"},
	     {"name":"Cayman Islands Dollar","code":"KYD","symbol":"$"},
	     {"name":"Chilean Peso","code":"CLP","symbol":"$"},
	     {"name":"Chinese Yuan","code":"CNY","symbol":"¥"},
	     {"name":"Colombian Peso","code":"COP","symbol":"$"},
	     {"name":"Dominican Peso","code":"DOP","symbol":"$"},
	     {"name":"Euro","code":"EUR","symbol":"€"},
	     {"name":"German Mark","code":"DEM","symbol":"DM"},
	     {"name":"Israeli New Sheqel","code":"ILS","symbol":"₪"},
	     {"name":"Italian Lira","code":"ITL","symbol":"L,£"},
	     {"name":"Japanese Yen","code":"JPY","symbol":"¥"},
	     {"name":"Jordanian Dinar","code":"JOD","symbol":"ا.د"},
	     {"name":"Kuwaiti Dinar","code":"KWD","symbol":"ك.د"},
	     {"name":"Macedonian Denar","code":"MKD","symbol":"ден"},
	     {"name":"Mexican Peso","code":"MXN","symbol":"$"},
	     {"name":"New Zealand Dollar","code":"NZD","symbol":"$"},
	     {"name":"Omani Rial","code":"OMR","symbol":".ع.ر"},
	     {"name":"Swiss Franc","code":"CHF","symbol":"CHf"},
	     {"name":"United Arab Emirates Dirham","code":"AED","symbol":"إ.د"}
];

class ReturnOnInvestmentCustomize extends React.Component {

	noResults = true;

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 2,
			isOpen: false,
			currentItem: "Procedure Revenue",
			currentDataSetting: 0,
			allProcedures: [],
			nonSurgicalProcedures: [],
			surgicalProcedures: [],
			painReliefProcedures: [],
			savedProcedures: [],
			userCurrency: "USD",
			userCurrencySymbol: "$",
			colors: [{color:'warning'}, {color:'warning'}, {color:'success'}, {color:'info'}, {color:'teal'}, {color:'cyan'}],
			noDevices: false,
			noDataForDevices: false,
			currentDevice: this.props.currentDevice,
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			currentUser: this.props.currentUser,
			isGeneralUser: true
	  };

		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
		this.onPriceChange = this.onPriceChange.bind(this);
		this.onGoalChange = this.onGoalChange.bind(this);
		this.toggleDropDown = this.toggleDropDown.bind(this);
		this.onPriceFocus = this.onPriceFocus.bind(this);
		this.getPriceValue = this.getPriceValue.bind(this);
		this.onPriceBlur = this.onPriceBlur.bind(this);
		this.onGoalFocus = this.onGoalFocus.bind(this);
		this.getGoalValue = this.getGoalValue.bind(this);
		this.onGoalBlur = this.onGoalBlur.bind(this);
  }

	async componentDidMount() {

		if (this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']>0) {

			this.setState({ isGeneralUser: false });

		}

		this.getUserCurrency();
	 	this.getData(this.state.rSelected);
	}

	selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		//setTimeout(this.getData, 1000);
  }

	toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	saveAll(category) {

		const { nonSurgicalProcedures } = this.state;
		for (var i=0; i<nonSurgicalProcedures.length;i++) {
			if (nonSurgicalProcedures[i].SaveActive) {
				this.saveProcedurePrice(nonSurgicalProcedures[i], 1, i);
			}
		}

		const { surgicalProcedures } = this.state;
		for (var i=0; i<surgicalProcedures.length;i++) {
			if (surgicalProcedures[i].SaveActive) {
				this.saveProcedurePrice(surgicalProcedures[i], 2, i);
			}
		}

		const { painReliefProcedures } = this.state;
		for (var i=0; i<painReliefProcedures.length;i++) {
			if (painReliefProcedures[i].SaveActive) {
				this.saveProcedurePrice(painReliefProcedures[i], 3, i);
			}
		}

		// if (category === 1) {
		// 	const { nonSurgicalProcedures } = this.state;
		// 	for (var i=0; i<nonSurgicalProcedures.length;i++) {
		// 		if (nonSurgicalProcedures[i].SaveActive) {
		// 			this.saveProcedurePrice(nonSurgicalProcedures[i], category, i);
		// 		}
		// 	}
		// } else if (category === 2) {
		// 	const { surgicalProcedures } = this.state;
		// 	for (var i=0; i<surgicalProcedures.length;i++) {
		// 		if (surgicalProcedures[i].SaveActive) {
		// 			this.saveProcedurePrice(surgicalProcedures[i], category, i);
		// 		}
		// 	}
		// } else if (category === 3) {
		// 	const { painReliefProcedures } = this.state;
		// 	for (var i=0; i<painReliefProcedures.length;i++) {
		// 		if (painReliefProcedures[i].SaveActive) {
		// 			this.saveProcedurePrice(painReliefProcedures[i], category, i);
		// 		}
		// 	}
		// }

	}

	async saveProcedurePrice(procedure, category, index) {

		console.log("Procedure about to save: " + JSON.stringify(procedure));

		let numberOnly = 0;

		if (procedure.NewPrice) {
			numberOnly = procedure.NewPrice;
			//numberOnly = procedure.NewPrice.replace('$', '');
		} else {
			numberOnly = procedure.Price;
		}

		numberOnly = parseInt(numberOnly);

		let goalNumberOnly = 0;

		if (procedure.NewGoal) {
			goalNumberOnly = procedure.NewGoal;
		} else {
			goalNumberOnly = procedure.Goal;
		}

		goalNumberOnly = parseInt(goalNumberOnly);

		console.log("OBJID: " + procedure.OBJID);
		 console.log("procedureName: " + procedure.Name);
		 console.log("CategoryID: " + procedure.CategoryID);
		 console.log("price: " + numberOnly);
		 console.log("procedureID: " + procedure.ProcedureID);
		 console.log("serialNumber: " + this.state.currentDevice);

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"update-procedure-price-for-device?CategoryID="+procedure.CategoryID+"&ProcedureID="+procedure.OBJID+"&ProcedureName="+procedure.Name+"&Price="+numberOnly+"&Goal="+goalNumberOnly+"&SerialNumber="+this.state.currentDevice;

		console.log("ROI url: " + url);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("updateprocedurepricefordevice response: " + JSON.stringify(body));
		console.log("nonSurgicalProcedures changed: " + JSON.stringify(this.state.nonSurgicalProcedures));
		console.log("surgicalProcedures changed: " + JSON.stringify(this.state.surgicalProcedures));
		console.log("painReliefProcedures changed: " + JSON.stringify(this.state.painReliefProcedures));

		// const { nonSurgicalProcedures } = this.state;
		// if (nonSurgicalProcedures[index].SaveActive) {
		// 	nonSurgicalProcedures[index].SaveActive = false;
		// }
		// this.setState({ nonSurgicalProcedures });
		//
		// const { surgicalProcedures } = this.state;
		// if (surgicalProcedures[index].SaveActive) {
		// 	surgicalProcedures[index].SaveActive = false;
		// }
		// this.setState({ surgicalProcedures });
		//
		// const { painReliefProcedures } = this.state;
		// if (painReliefProcedures[index].SaveActive) {
		// 	painReliefProcedures[index].SaveActive = false;
		// }
		// this.setState({ painReliefProcedures });

		if (category === 1) {
			const { nonSurgicalProcedures } = this.state;
			if (nonSurgicalProcedures[index].SaveActive) {
				nonSurgicalProcedures[index].SaveActive = false;
			}
			this.setState({ nonSurgicalProcedures });
		} else if (category === 2) {
			const { surgicalProcedures } = this.state;
			if (surgicalProcedures[index].SaveActive) {
				surgicalProcedures[index].SaveActive = false;
			}
			this.setState({ surgicalProcedures });
		} else if (category === 3) {
			const { painReliefProcedures } = this.state;
			if (painReliefProcedures[index].SaveActive) {
				painReliefProcedures[index].SaveActive = false;
			}
			this.setState({ painReliefProcedures });
		}
	}

	onGoalFocus = (index, procedure, event) => {
		console.log("onGoalFocus procedure to change: " + JSON.stringify(procedure));

		if (this.state.rSelected === 1) {
			const { nonSurgicalProcedures } = this.state;
			nonSurgicalProcedures[index].NewGoal = "";
			this.setState({ nonSurgicalProcedures });
			//this.state.nonSurgicalProcedures[index].Price = event.target.value;
		} else if (this.state.rSelected === 2) {
			const { surgicalProcedures } = this.state;
			surgicalProcedures[index].NewGoal = "";
			this.setState({ surgicalProcedures });
		} else if (this.state.rSelected === 3) {
			const { painReliefProcedures } = this.state;
			painReliefProcedures[index].NewGoal = "";
			this.setState({ painReliefProcedures });
		}
  }

	onGoalBlur = (index, procedure, event) => {
		console.log("procedure to change: " + JSON.stringify(procedure));

		var inputValue = event.target.value;
		console.log("onGoalBlur inputValue: " + inputValue);

		if (inputValue!=this.state.userCurrencySymbol) {
			inputValue = procedure.NewGoal;
		} else {
			inputValue = procedure.Goal;
		}

		if (this.state.rSelected === 1) {
			const { nonSurgicalProcedures } = this.state;
			nonSurgicalProcedures[index].NewGoal = inputValue;
			this.setState({ nonSurgicalProcedures });
		} else if (this.state.rSelected === 2) {
			const { surgicalProcedures } = this.state;
			surgicalProcedures[index].NewGoal = inputValue;
			this.setState({ surgicalProcedures });
		} else if (this.state.rSelected === 3) {
			const { painReliefProcedures } = this.state;
			painReliefProcedures[index].NewGoal = inputValue;
			this.setState({ painReliefProcedures });
		}
  }

	onGoalChange = (index, procedure, event) => {
		console.log("procedure to change: " + JSON.stringify(procedure));
		const re = /^[0-9\b]+$/;
		let numberOnly = event.target.value.replace(this.state.userCurrencySymbol, '');

		if (re.test(numberOnly)) {
			numberOnly = parseInt(numberOnly);
		}

		console.log("onGoalChange numberOnly: " + numberOnly);

		if (numberOnly === '' || re.test(numberOnly)) {
			if (this.state.rSelected === 1) {
				const { nonSurgicalProcedures } = this.state;
		    nonSurgicalProcedures[index].NewGoal = numberOnly;
				//nonSurgicalProcedures[index].Price = numberOnly;
				nonSurgicalProcedures[index].SaveActive = true;
		    this.setState({ nonSurgicalProcedures });
				//this.state.nonSurgicalProcedures[index].Price = event.target.value;
			} else if (this.state.rSelected === 2) {
				const { surgicalProcedures } = this.state;
				surgicalProcedures[index].NewGoal = numberOnly;
		    //surgicalProcedures[index].Price = numberOnly;
				surgicalProcedures[index].SaveActive = true;
		    this.setState({ surgicalProcedures });
				//this.state.surgicalProcedures[index].Price = event.target.value;
			} else if (this.state.rSelected === 3) {
				const { painReliefProcedures } = this.state;
		    painReliefProcedures[index].NewGoal = numberOnly;
				//painReliefProcedures[index].Price = numberOnly;
				painReliefProcedures[index].SaveActive = true;
		    this.setState({ painReliefProcedures });
				//this.state.painReliefProcedures[index].Price = event.target.value;
			}
		} else {
			if (this.state.rSelected === 1) {
				const { nonSurgicalProcedures } = this.state;
		    nonSurgicalProcedures[index].Goal = 0;
		    this.setState({ nonSurgicalProcedures });
				//this.state.nonSurgicalProcedures[index].Price = event.target.value;
			} else if (this.state.rSelected === 2) {
				const { surgicalProcedures } = this.state;
		    surgicalProcedures[index].Goal = 0;
		    this.setState({ surgicalProcedures });
			} else if (this.state.rSelected === 3) {
				const { painReliefProcedures } = this.state;
		    painReliefProcedures[index].Goal = 0;
		    this.setState({ painReliefProcedures });
			}
		}
  }

	onPriceFocus = (index, procedure, event) => {
		console.log("procedure to change: " + JSON.stringify(procedure));

		if (this.state.rSelected === 1) {
			const { nonSurgicalProcedures } = this.state;
			nonSurgicalProcedures[index].NewPrice = "";
			this.setState({ nonSurgicalProcedures });
			//this.state.nonSurgicalProcedures[index].Price = event.target.value;
		} else if (this.state.rSelected === 2) {
			const { surgicalProcedures } = this.state;
			surgicalProcedures[index].NewPrice = "";
			this.setState({ surgicalProcedures });
		} else if (this.state.rSelected === 3) {
			const { painReliefProcedures } = this.state;
			painReliefProcedures[index].NewPrice = "";
			this.setState({ painReliefProcedures });
		}
  }

	onPriceBlur = (index, procedure, event) => {
		console.log("procedure to change: " + JSON.stringify(procedure));

		var inputValue = event.target.value;
		console.log("onPriceBlur inputValue: " + inputValue);

		if (inputValue!=this.state.userCurrencySymbol) {
			inputValue = procedure.NewPrice;
		} else {
			inputValue = procedure.Price;
		}

		if (this.state.rSelected === 1) {
			const { nonSurgicalProcedures } = this.state;
			nonSurgicalProcedures[index].NewPrice = inputValue;
			this.setState({ nonSurgicalProcedures });
		} else if (this.state.rSelected === 2) {
			const { surgicalProcedures } = this.state;
			surgicalProcedures[index].NewPrice = inputValue;
			this.setState({ surgicalProcedures });
		} else if (this.state.rSelected === 3) {
			const { painReliefProcedures } = this.state;
			painReliefProcedures[index].NewPrice = inputValue;
			this.setState({ painReliefProcedures });
		}
  }

	onPriceChange = (index, procedure, event) => {
		console.log("procedure to change: " + JSON.stringify(procedure));
		const re = /^[0-9\b]+$/;
		let numberOnly = event.target.value.replace(this.state.userCurrencySymbol, '');

		if (re.test(numberOnly)) {
			numberOnly = parseInt(numberOnly);
		}

		console.log("onPriceChange numberOnly: " + numberOnly);

		if (numberOnly === '' || re.test(numberOnly)) {
			if (this.state.rSelected === 1) {
				const { nonSurgicalProcedures } = this.state;
		    nonSurgicalProcedures[index].NewPrice = numberOnly;
				//nonSurgicalProcedures[index].Price = numberOnly;
				nonSurgicalProcedures[index].SaveActive = true;
		    this.setState({ nonSurgicalProcedures });
				//this.state.nonSurgicalProcedures[index].Price = event.target.value;
			} else if (this.state.rSelected === 2) {
				const { surgicalProcedures } = this.state;
				surgicalProcedures[index].NewPrice = numberOnly;
		    //surgicalProcedures[index].Price = numberOnly;
				surgicalProcedures[index].SaveActive = true;
		    this.setState({ surgicalProcedures });
				//this.state.surgicalProcedures[index].Price = event.target.value;
			} else if (this.state.rSelected === 3) {
				const { painReliefProcedures } = this.state;
		    painReliefProcedures[index].NewPrice = numberOnly;
				//painReliefProcedures[index].Price = numberOnly;
				painReliefProcedures[index].SaveActive = true;
		    this.setState({ painReliefProcedures });
				//this.state.painReliefProcedures[index].Price = event.target.value;
			}
		} else {
			if (this.state.rSelected === 1) {
				const { nonSurgicalProcedures } = this.state;
		    nonSurgicalProcedures[index].Price = 0;
		    this.setState({ nonSurgicalProcedures });
				//this.state.nonSurgicalProcedures[index].Price = event.target.value;
			} else if (this.state.rSelected === 2) {
				const { surgicalProcedures } = this.state;
		    surgicalProcedures[index].Price = 0;
		    this.setState({ surgicalProcedures });
			} else if (this.state.rSelected === 3) {
				const { painReliefProcedures } = this.state;
		    painReliefProcedures[index].Price = 0;
		    this.setState({ painReliefProcedures });
			}
		}
  }

	async onRadioBtnClick(rSelected) {
		await this.setState({ rSelected });
		//await this.getData(rSelected);
	}

	getData = async category => {
		console.log("Getting Top Procedures by Category Data");

		if (this.state.currentUser) {
			//console.log("topprocedures 1: ");

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"all-procedures-for-device?SerialNumber="+this.props.currentDevice;

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all-procedures-for-device response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				if (body.results.length>0) {
					this.setState({ allProcedures: body.results }, () => console.log("allProcedures: "+this.state.allProcedures));

					var nonSurgical = body.results.filter(function (procedure) {
						return procedure.CategoryID === 1;
					});
					this.setState({ nonSurgicalProcedures: nonSurgical }, () => console.log("nonSurgicalProcedures: "+this.state.nonSurgicalProcedures));

					var surgical = body.results.filter(function (procedure) {
						return procedure.CategoryID === 2;
					});
					this.setState({ surgicalProcedures: surgical }, () => console.log("surgicalProcedures: "+this.state.surgicalProcedures));

					var painRelief = body.results.filter(function (procedure) {
						return procedure.CategoryID === 3;
					});
					this.setState({ painReliefProcedures: painRelief }, () => console.log("painReliefProcedures: "+this.state.painReliefProcedures));

				} else {
					this.state.noDataForDevices = true;
				}
			} else {
				this.state.noDataForDevices = true;
			}
		}
	};

	getUserCurrency = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-user-currency";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("getUserCurrency response: " + JSON.stringify(body));

		if (body.status == 'Success') {
			if (body.results.length>0) {
				let currency = currency_list.find(c => c.code === body.results[0].Currency);
				this.setState({ userCurrency: body.results[0].Currency, userCurrencySymbol: currency.symbol})
			}
		} else {

		}

	};

	updateUserCurrency = async (currency) => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"update-user-currency?Currency="+currency;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("updateUserCurrency response: " + JSON.stringify(body));

		if (body.status == 'Success') {

		} else {

		}

	};

	getPriceValue(procedure) {

		//console.log("getPriceValue procedure to change: " + JSON.stringify(procedure));

		var price = this.state.userCurrencySymbol+'0';

		if (procedure.Price) {
			price = this.state.userCurrencySymbol+procedure.Price;
		}

		//console.log("getPriceValue NewPrice exists: " + procedure.NewPrice);

		if (procedure.NewPrice!=undefined) {
			price = this.state.userCurrencySymbol+procedure.NewPrice;
			//console.log("getPriceValue NewPrice exists: " + price);
		}

		//console.log("price: " + price);

		return price;
	}

	getGoalValue(procedure) {

		console.log("getGoalValue procedure to change: " + JSON.stringify(procedure));

		var goal = this.state.userCurrencySymbol+'0';

		if (procedure.Goal) {
			goal = this.state.userCurrencySymbol+procedure.Goal;
		}

		console.log("getGoalValue NewGoal exists: " + procedure.NewGoal);

		if (procedure.NewGoal!=undefined) {
			goal = this.state.userCurrencySymbol+procedure.NewGoal;
			console.log("getPriceValue NewGoal exists: " + goal);
		}

		console.log("goal: " + goal);

		return goal;
	}

	onChangeCurrency = (event) => {

		console.log("onChangeCurrency input id: " + event.target.id);
		console.log("onChangeCurrency input value: " + event.target.value);

		let id = event.target.id;
		let value = event.target.value;

		let currency = currency_list.find(c => c.code === value);

		this.setState({ userCurrency: value, userCurrencySymbol: currency.symbol });

		//this.updateUserCurrency(value);

	}

	saveCurrency = () => {

		this.updateUserCurrency(this.state.userCurrency);

	}

	getReadyToSaveColor(procedure) {
		var color = {};

		if (procedure.SaveActive && procedure.SaveActive==true) {
			color = { color: "#48b203" };
		}

		return color;
	}

	render() {
		return (
			/*--------------------------------------------------------------------------------*/
			/* Used In Dashboard-1 [Classic]                                                  */
			/*--------------------------------------------------------------------------------*/
			<Card className="card-hover">
				<CardBody style={{ minHeight: 450 }}>
					<Row>
						<Col xs="7" md="7" lg="7" className="text-left">
							{this.state.currentDataSetting === 0 &&
								<CardTitle>Enter Revenue Per Procedure</CardTitle>
							}
							{this.state.currentDataSetting === 1 &&
								<CardTitle>Enter Revenue Goals</CardTitle>
							}
							{this.state.currentDataSetting === 2 &&
								<CardTitle>Revenue Configuration</CardTitle>
							}
						</Col>
						<Col xs="5" md="5" lg="5" className="text-right">
							<ButtonDropdown isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
								<DropdownToggle size='sm' color="dark" caret>
									Settings
								</DropdownToggle>
								<DropdownMenu right>
									<DropdownItem onClick={() => this.selectDropDownItem(0, 'Procedure Revenue')}>Procedure Revenue</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(1, 'Set Revenue Goals')}>Set Revenue Goals</DropdownItem>
									{this.state.isGeneralUser &&
										<DropdownItem onClick={() => this.selectDropDownItem(2, 'International Currency')}>International Currency</DropdownItem>
									}
								</DropdownMenu>
							</ButtonDropdown>
						</Col>
					</Row>
					{this.state.currentDataSetting != 2 &&
						<Fragment>
						{this.state.currentDataSetting == 0 ? (
							<Fragment>
							<Fade in="true">
							<Row className="pt-1">
								<Col xs="12" md="12" lg="12">
									<div className="mt-1 mb-1">
										<ButtonGroup style={{ width: '100%' }}>
											<Button
												style={{width: '100%'}}
												color="dark"
												onClick={() => this.onRadioBtnClick(1)}
												active={this.state.rSelected === 1}
											>
												Non Surgical
											</Button>
											<Button
												color="dark"
												style={{width: '100%'}}
												onClick={() => this.onRadioBtnClick(2)}
												active={this.state.rSelected === 2}
											>
												Surgical
											</Button>
											<Button
												color="dark"
												style={{width: '100%'}}
												onClick={() => this.onRadioBtnClick(3)}
												active={this.state.rSelected === 3}
											>
												Pain Relief
											</Button>
										</ButtonGroup>
									</div>
								</Col>
							</Row>
							</Fade>
							</Fragment>
						) : (
							<Fragment>
							<Fade in="true">
							<Row className="pt-1">
								<Col xs="12" md="12" lg="12">
									<div className="mt-1 mb-1">
										<ButtonGroup style={{ width: '100%' }}>
											<Button
												style={{width: '100%'}}
												color="yellow"
												onClick={() => this.onRadioBtnClick(1)}
												active={this.state.rSelected === 1}
											>
												Non Surgical
											</Button>
											<Button
												color="yellow"
												style={{width: '100%'}}
												onClick={() => this.onRadioBtnClick(2)}
												active={this.state.rSelected === 2}
											>
												Surgical
											</Button>
											<Button
												color="yellow"
												style={{width: '100%'}}
												onClick={() => this.onRadioBtnClick(3)}
												active={this.state.rSelected === 3}
											>
												Pain Relief
											</Button>
										</ButtonGroup>
									</div>
								</Col>
							</Row>
							</Fade>
							</Fragment>
						)}
						</Fragment>
					}
					{this.state.noDevices || this.state.noDataForDevices &&
						<div className="mt-5 text-center">
						{!this.state.noDataForDevices &&
							<h5>User has no devices</h5>
						}
						{this.state.noDataForDevices &&
							<h5>No data for user's devices</h5>
						}
						</div>
					}
					{!this.state.noDevices && !this.state.noDataForDevices &&
						<Fragment>
						{this.state.currentDataSetting === 0 &&
							<Fade in="true">
							<div className="pt-2">
								{this.state.rSelected === 1 &&
									<Fragment>
									<div style={{ height: 280 }}>
									{this.state.nonSurgicalProcedures.map((procedure, i) => (
										<Fragment>
						        <Row key={i} className="mt-1">
											<Col xs="4" md="4" lg="4" className="text-left">
												<span style={this.getReadyToSaveColor(procedure)}>{procedure.Name}</span>
											</Col>
											<Col xs="8" md="8" lg="8">
												<span className="float-left" style={{ width: "15%", lineHeight: "1.7"}}>Price: </span>
												<Input className="float-right" onBlur={(e) => this.onPriceBlur(i, procedure, e)} onFocus={(e) => this.onPriceFocus(i, procedure, e)} onChange={(e) => this.onPriceChange(i, procedure, e)} style={{ height: "25px", width: "85%"}} id={this.state.rSelected+"-"+procedure.ProcedureID} value={this.getPriceValue(procedure)} type="text" disabled={!this.state.isGeneralUser}/>
											</Col>
						        </Row>
										</Fragment>
						      ))}
									</div>
									{this.state.isGeneralUser &&
										<Row>
											<Col xs="12" md="12" lg="12">
												<Button size="sm" color="brightgreen" style={{width: '100%'}} onClick={() => this.saveAll(1)} >Save</Button>
											</Col>
										</Row>
									}
									</Fragment>
								}
								{this.state.rSelected === 2 &&
									<Fragment>
									<div style={{ height: 280 }}>
									{this.state.surgicalProcedures.map((procedure, i) => (
										<Fragment>
						        <Row key={i} className="mt-1">
											<Col xs="4" md="4" lg="4" className="text-left">
												<span style={this.getReadyToSaveColor(procedure)}>{procedure.Name}</span>
											</Col>
											<Col xs="8" md="8" lg="8">
												<span className="float-left" style={{ width: "15%", lineHeight: "1.7"}}>Price: </span>
												<Input className="float-right" onBlur={(e) => this.onPriceBlur(i, procedure, e)} onFocus={(e) => this.onPriceFocus(i, procedure, e)} onChange={(e) => this.onPriceChange(i, procedure, e)} style={{ height: "25px", width: "85%"}} id={this.state.rSelected+"-"+procedure.ProcedureID} value={this.getPriceValue(procedure)} type="text" disabled={!this.state.isGeneralUser} />
											</Col>
						        </Row>
										</Fragment>
						      ))}
									</div>
									{this.state.isGeneralUser &&
										<Row>
											<Col xs="12" md="12" lg="12">
												<Button size="sm" color="brightgreen" style={{width: '100%'}} onClick={() => this.saveAll(2)} >Save</Button>
											</Col>
										</Row>
									}
									</Fragment>
								}
								{this.state.rSelected === 3 &&
									<Fragment>
									<div style={{ height: 280 }}>
									{this.state.painReliefProcedures.map((procedure, i) => (
										<Fragment>
						        <Row key={i} className="mt-1">
											<Col xs="4" md="4" lg="4" className="text-left">
												<span style={this.getReadyToSaveColor(procedure)}>{procedure.Name}</span>
											</Col>
											<Col xs="8" md="8" lg="8">
												<span className="float-left" style={{ width: "15%", lineHeight: "1.7"}}>Price: </span>
												<Input className="float-right" onBlur={(e) => this.onPriceBlur(i, procedure, e)} onFocus={(e) => this.onPriceFocus(i, procedure, e)} onChange={(e) => this.onPriceChange(i, procedure, e)} style={{ height: "25px", width: "85%"}} id={this.state.rSelected+"-"+procedure.ProcedureID} value={this.getPriceValue(procedure)} type="text" disabled={!this.state.isGeneralUser} />
											</Col>
											{
												// {procedure.SaveActive && procedure.SaveActive==true ? (
												// 	<Col xs="3" md="3" lg="3" className="text-right">
												// 		<Button size="sm" color="brightgreen" style={{width: '100%'}} onClick={() => this.saveProcedurePrice(procedure, 3, i)} >Save</Button>
												// 	</Col>
												// ) : (
												// 	<Col xs="3" md="3" lg="3" className="text-right">
												// 	</Col>
												// )}
											}
						        </Row>
										</Fragment>
						      ))}
									</div>
									{this.state.isGeneralUser &&
										<Row>
											<Col xs="12" md="12" lg="12">
												<Button size="sm" color="brightgreen" style={{width: '100%'}} onClick={() => this.saveAll(3)} >Save</Button>
											</Col>
										</Row>
									}
									</Fragment>
								}
							</div>
							</Fade>
						}
						{this.state.currentDataSetting === 1 &&
							<Fade in="true">
							<div className="pt-2">
								{this.state.rSelected === 1 &&
									<Fragment>
									<div style={{ height: 280 }}>
									{this.state.nonSurgicalProcedures.map((procedure, i) => (
										<Fragment>
						        <Row key={i} className="mt-1">
											<Col xs="4" md="4" lg="4" className="text-left">
												<span style={this.getReadyToSaveColor(procedure)}>{procedure.Name}</span>
											</Col>
											<Col xs="8" md="8" lg="8">
												<span className="float-left" style={{ width: "15%", lineHeight: "1.7"}}>Goal: </span>
												<Input className="float-right" onBlur={(e) => this.onGoalBlur(i, procedure, e)} onFocus={(e) => this.onGoalFocus(i, procedure, e)} onChange={(e) => this.onGoalChange(i, procedure, e)} style={{ height: "25px", width: "85%"}} id={this.state.rSelected+"-"+procedure.ProcedureID} value={this.getGoalValue(procedure)} type="text" disabled={!this.state.isGeneralUser} />
											</Col>
						        </Row>
										</Fragment>
						      ))}
									</div>
									{this.state.isGeneralUser &&
										<Row>
											<Col xs="12" md="12" lg="12">
												<Button size="sm" color="brightgreen" style={{width: '100%'}} onClick={() => this.saveAll(1)} >Save</Button>
											</Col>
										</Row>
									}
									</Fragment>
								}
								{this.state.rSelected === 2 &&
									<Fragment>
									<div style={{ height: 280 }}>
									{this.state.surgicalProcedures.map((procedure, i) => (
										<Fragment>
						        <Row key={i} className="mt-1">
											<Col xs="4" md="4" lg="4" className="text-left">
												<span style={this.getReadyToSaveColor(procedure)}>{procedure.Name}</span>
											</Col>
											<Col xs="8" md="8" lg="8">
												<span className="float-left" style={{ width: "15%", lineHeight: "1.7"}}>Goal: </span>
												<Input className="float-right" onBlur={(e) => this.onGoalBlur(i, procedure, e)} onFocus={(e) => this.onGoalFocus(i, procedure, e)} onChange={(e) => this.onGoalChange(i, procedure, e)} style={{ height: "25px", width: "85%"}} id={this.state.rSelected+"-"+procedure.ProcedureID} value={this.getGoalValue(procedure)} type="text" disabled={!this.state.isGeneralUser} />
											</Col>
						        </Row>
										</Fragment>
						      ))}
									</div>
									{this.state.isGeneralUser &&
										<Row>
											<Col xs="12" md="12" lg="12">
												<Button size="sm" color="brightgreen" style={{width: '100%'}} onClick={() => this.saveAll(2)} >Save</Button>
											</Col>
										</Row>
									}
									</Fragment>
								}
								{this.state.rSelected === 3 &&
									<Fragment>
									<div style={{ height: 280 }}>
									{this.state.painReliefProcedures.map((procedure, i) => (
										<Fragment>
						        <Row key={i} className="mt-1">
											<Col xs="4" md="4" lg="4" className="text-left">
												<span style={this.getReadyToSaveColor(procedure)}>{procedure.Name}</span>
											</Col>
											<Col xs="8" md="8" lg="8">
												<span className="float-left" style={{ width: "15%", lineHeight: "1.7"}}>Goal: </span>
												<Input className="float-right" onBlur={(e) => this.onGoalBlur(i, procedure, e)} onFocus={(e) => this.onGoalFocus(i, procedure, e)} onChange={(e) => this.onGoalChange(i, procedure, e)} style={{ height: "25px", width: "85%"}} id={this.state.rSelected+"-"+procedure.ProcedureID} value={this.getGoalValue(procedure)} type="text" disabled={!this.state.isGeneralUser} />
											</Col>
											{
												// {procedure.SaveActive && procedure.SaveActive==true ? (
												// 	<Col xs="3" md="3" lg="3" className="text-right">
												// 		<Button size="sm" color="brightgreen" style={{width: '100%'}} onClick={() => this.saveProcedurePrice(procedure, 3, i)} >Save</Button>
												// 	</Col>
												// ) : (
												// 	<Col xs="3" md="3" lg="3" className="text-right">
												// 	</Col>
												// )}
											}
						        </Row>
										</Fragment>
						      ))}
									</div>
									{this.state.isGeneralUser &&
										<Row>
											<Col xs="12" md="12" lg="12">
												<Button size="sm" color="brightgreen" style={{width: '100%'}} onClick={() => this.saveAll(3)} >Save</Button>
											</Col>
										</Row>
									}
									</Fragment>
								}
							</div>
							</Fade>
						}
						{this.state.currentDataSetting === 2 &&
							<Fade in="true">
							<Row className="mt-4">
								<Col xs="12" md="12" lg="12">
									<span className="float-left" style={{ width: "25%", lineHeight: "2.0"}}>Select Currency: </span>
									<Input className="float-left" style={{height: '30px', padding: '3px', width: "75%"}} type="select" name="Currency" id="Currency" value={this.state.userCurrency} onChange={this.onChangeCurrency}>
										{currency_list.map((currency, i) => (
											<option value={currency.code}>{currency.name} {currency.symbol}</option>
										))}
									</Input>
								</Col>
							</Row>
							{this.state.isGeneralUser &&
								<Row className="mt-4">
									<Col xs="12" md="12" lg="12">
										<Button size="sm" color="brightgreen" style={{width: '100%'}} onClick={() => this.saveCurrency()} >Save</Button>
									</Col>
								</Row>
							}
							</Fade>
						}
						</Fragment>
					}
				</CardBody>
			</Card>

		);
	}
}

export default ReturnOnInvestmentCustomize;
