import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";
import { saveAs } from "file-saver";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import update from 'react-addons-update';
import redpin from '../../../assets/images/pins/red_pin.png';
import greenpin from '../../../assets/images/pins/green_pin.png';
import brokenpin from '../../../assets/images/pins/broken_pin.png';
import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class FeedbackView extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			currentUser,
			modal: false,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			message: "",
			subject: "Gemini EVO Feedback",
			statusSubject: "Failed",
			statusMessage: "Failed to send message.",
	  };

		this.toggle = this.toggle.bind(this);
		this.onSubjectChange = this.onSubjectChange.bind(this);
		this.onMessageChange = this.onMessageChange.bind(this);

  }

	async componentDidMount() {

    //this.sendEmail();
	}

	componentWillUnmount() {
		clearInterval(this.intervalID);

	}

	toggle() {

		if (this.state.modal) {
			this.setState({
		    message: ""
		  });
		}

	  this.setState({
	    modal: !this.state.modal
	  });
	}

	onSubjectChange = (event) => {
    this.setState({subject:event.target.value});
  };

	onMessageChange = (event) => {
    this.setState({message:event.target.value});
  };

	sendEmail = async () => {

		let requestBody = {
				subject:this.state.subject,
				message:this.state.message
			}

		console.log("sendEmail requestBody: " + JSON.stringify(requestBody));

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-user-support-email";

		const response = await fetch(url, {
			method: 'POST',
			headers: {
				Authorization: this.state.currentUser.jwtToken,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(requestBody)
		});

		const body = await response.json();
		console.log("sendEmail response: " + JSON.stringify(body));

		if (body.status == 'Success') {

			this.setState({
				statusSubject: "SUCCESS!",
				statusMessage: "Email Message sent Successfully!"
			})

		} else {

			this.setState({
				statusSubject: "FAILED!",
				statusMessage: "Failed to send Email Message."
			})

		}

		this.toggle();

	};

  render() {
		return (
			<Fragment>
				<Row className="mb-3">
					<Col md="6">
						<h1 style={{color:'#737782'}}>Feedback</h1>
					</Col>
				</Row>
				<Row>
					<Col md="8">
            <Card className="card-hover">
      				<CardBody>
							<Row className="pt-4">
								<Col xs="12" md="12" lg="12">
									<Label style={{fontSize:'18px'}} for="detailedIssue">Subject:</Label>
									<Input style={{width:'100%'}} type="text" name="message" id="message" value={this.state.subject} onChange={this.onSubjectChange}/>
								</Col>
							</Row>
							<Row className="pt-4">
								<Col xs="12" md="12" lg="12">
									<Label style={{fontSize:'18px'}} for="detailedIssue">Leave Your Feedback:</Label>
									<Input style={{width:'100%', height: '300px'}} type="textarea" name="message" id="message" value={this.state.message} onChange={this.onMessageChange}/>
								</Col>
							</Row>
							<Row className="pt-4 text-right">
								<Col xs="12" md="12" lg="12">
									<Button className="ml-3" size="lg" color="yellow" title="Send Email" onClick={() => this.sendEmail()} disabled={this.state.message=="" ? true : false}>
										Send Message
									</Button>
								</Col>
							</Row>
      				</CardBody>
      			</Card>
					</Col>
				</Row>
				<div>
					<Modal isOpen={this.state.modal} toggle={this.toggle}>
	  				<ModalHeader toggle={this.toggle}>{this.state.statusSubject}</ModalHeader>
	  				<ModalBody>
							<span>{this.state.statusMessage}</span>
	  				</ModalBody>
						<ModalFooter>
							<Button color="primary" className="ml-1" onClick={this.toggle}>OK</Button>
						</ModalFooter>
				  </Modal>
				</div>
			</Fragment>
		);
	}
}

export default FeedbackView;
