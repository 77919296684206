import Fulllayout from '../layouts/fulllayout.js';
import Blanklayout from '../layouts/blanklayout.js';
import Mobilelayout from '../layouts/mobilelayout.js';

var indexRoutes = [
	{ path: "/authentication", name: "Authentication", component: Blanklayout },
  { path: '/', name: 'Dashboard', component: Fulllayout },
	{ path: '/mobile', name: 'Mobile', component: Mobilelayout }
];

export default indexRoutes;
