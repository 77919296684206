import React from "react";
import { Component, Fragment } from "react";
import {
	Card,
	CardBody,
	CardTitle,
	Row,
	Col,
	Button,

} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as mqtt from 'react-paho-mqtt';
import Paho from 'paho-mqtt';
import { getConnectionURL } from "../../mqtt-helpers/mqtt-request";
import { authenticationService } from '../../jwt/_services';
import ReactGA from "react-ga4";

import {
	BatteryHealth,
	Booster200V,
	WifiStrength,
	VisualAudioStatus,
	BleStrength
} from '../../components/vitals/';

import {
	UsedPowerSettings,
	TotalProcedures,
	LaserUsage,
	TopProcedures,
  TotalHourlyUsagePVM,
  TopProceduresCat,
  WavelengthUsage
} from '../../components/mobile-vitals-dashboard/';

class MobileVitalsDashboard extends React.Component {

	constructor(props) {
    super(props);

		var connectionTimeout;

		// const currentUser = authenticationService.currentUserValue;
		// console.log('currentUser info: ' + JSON.stringify(currentUser));
		// console.log('currentUser info: ' + JSON.stringify(currentUser.token));

		this.onMessageArrived = this.onMessageArrived.bind(this);
    this.onConnectionLost = this.onConnectionLost.bind(this);

    this.state = {
			mainMessage: [''],
			noDevices: false,
			subMessage: [''],
			client: null,
      messageToSend:'',
			currentDevice: this.props.match.params.id,
      isConnected: false,
			isReceivingMessages: false,
			devices: [''],
      authToken: ""
	  };

  }

	componentDidMount() {

		ReactGA.pageview(window.location.pathname);

    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('token')
    console.log("token: "+token)//123

    if (token) {
      if (token.length>0) {
        console.log("authToken: "+token)
        this.setState({authToken:token});
      }
    }

    setTimeout(this.connectToMQTT, 1000);
		//this.connectToMQTT();

		if (this.state.currentDevice === "00000") {
			this.setState({noDevices: true});
		}

		//setInterval(this.checkIsConnected, 2000);
	}

	componentWillUnmount() {

		//var currentConnectedDevice = JSON.parse(localStorage.getItem('currentConnectedDevice'));

		// if (currentConnectedDevice == this.state.currentDevice) {
		// 	localStorage.setItem('currentConnectedDevice', '');
		// 	localStorage.setItem('isDeviceReceivingMessages', false);
		// }

		if (this.state.client && this.state.client.isConnected()) {
			this.state.client.disconnect();
		}

	}

  connectToMQTT = async () => {

    //var url = "https://hdlnjb45hb.execute-api.us-west-2.amazonaws.com/v1/request-url?RequestType=1";
    var url = "https://hdlnjb45hb.execute-api.us-west-2.amazonaws.com/v1/request-url?RequestType=0&SerialNumber="+this.state.currentDevice;

    var token = "";

		if (token = this.state.authToken) {
			token = token = this.state.authToken;
		}

    try {

      const response = await fetch(url, {
  			method: 'GET',
  			headers: {
  				Authorization: token
  			},
  		});

      const body = await response.json();
  		console.log("connectToMQTT body: " + JSON.stringify(body));

  		if (body.status == 'Success') {
        var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        console.log('random hash: ' + hash);
        var clientIdHash = "g2_"+this.state.currentDevice+"_"+body.type;
        console.log('clientIdHash: ' + clientIdHash);
        this.state.client = new Paho.Client(body.response, clientIdHash);
        this.state.client.onMessageArrived = this.onMessageArrived;
        this.state.client.onConnectionLost = this.onConnectionLost;
        this.state.client.connect({
          timeout: 3,
          useSSL: true,
          mqttVersion: 4,
          cleanSession: true,
          onSuccess: this.onConnect,
          onFailure: (e) => {console.log("here is the error" , e); }
        });
  		} else {
        //ADD ERROR MESSAGE
        console.log('error response: ' + body.response);
      }

		}
		catch (err) {
			console.log("connectToMQTT error: "+err);
		}

	}

	// connectToMQTT = async () => {
	// 	authenticationService.getDeviceConnectionURL(this.state.currentDevice)
	// 			.then(
	// 					connectionUrl => {
	// 						console.log('connectToMQTT connectionUrl: ' + JSON.stringify(connectionUrl));
  //
	// 						//var url1 = "wss://a1f3ynjyzalf3r-ats.iot.us-west-2.amazonaws.com/mqtt?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAS3T2QUYOSMZKW4V2%2F20210201%2Fus-west-2%2Fiotdevicegateway%2Faws4_request&X-Amz-Date=20210201T225801Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=dff6d2d6b29772129d10a43adf2b218d07b3cd3423ac13e32c71c6d7f8032eaf";
  //
	// 						if (connectionUrl.status == 'Success') {
	// 							var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
	// 							console.log('random hash: ' + hash);
	// 							var clientIdHash = "g2_"+this.state.currentDevice+"_"+connectionUrl.type;
	// 							this.state.client = new Paho.Client(connectionUrl.response, clientIdHash);
	// 							this.state.client.onMessageArrived = this.onMessageArrived;
	// 							this.state.client.onConnectionLost = this.onConnectionLost;
	// 							this.state.client.connect({
	// 								timeout: 3,
	// 								useSSL: true,
	// 								mqttVersion: 4,
	// 								cleanSession: true,
	// 								onSuccess: this.onConnect,
	// 								onFailure: (e) => {console.log("here is the error" , e); }
	// 							});
	// 						}
	// 					},
	// 					error => {
	// 						console.log('error.message: ' + error.message);
	// 					}
	// 			);
	// }

	checkIsConnected = () => {
		if (this.state.isReceivingMessages) {
			console.log("Vitals Is Connected!");
		} else {
			console.log("Vitals Is Not Connected!");
		}
	}

	updateIsConnected = () => {
		this.setState({isReceivingMessages: false});

		//localStorage.setItem('currentConnectedDevice', '');
		localStorage.setItem('isDeviceReceivingMessages', false);

		console.log("Vitals isReceivingMessages false");
	}

	onMessageArrived(entry) {
		this.setState({isReceivingMessages: true});
		clearTimeout(this.connectionTimeout)
    console.log("Vitals onMessageArrived:"+entry.payloadString);
		const msg = JSON.parse(entry.payloadString);

		localStorage.setItem('currentConnectedDevice', JSON.stringify(msg.serial_num));
		localStorage.setItem('isDeviceReceivingMessages', true);

		if (msg.batch) {
			this.setState({mainMessage: JSON.parse(entry.payloadString)});
			console.log("vitals_main_messages:"+this.state.mainMessage);
		} else {
			this.setState({subMessage: JSON.parse(entry.payloadString)});
			console.log("vitals_sub_messages:"+this.state.subMessage);
		}
		this.connectionTimeout = setInterval(this.updateIsConnected, 10000);
  }

  onConnect = () => {
    const { client } = this.state;
    console.log("Connected!!!!");
    //client.subscribe('00013/out');
    this.setState({isConnected: true, error: ''})
		//this.setState({isReceivingMessages: true})
		//this.sendMessage("@@@get_all");
		console.log('myCurrentDevice id: ' + this.state.currentDevice);
		//this.setState({currentDevice: this.state.currentDevice});
		const subChannel = this.state.currentDevice+'/out';
		this.state.client.subscribe(subChannel);
		this.connectionTimeout = setInterval(this.updateIsConnected, 10000);
  };


  sendMessage(){
    var message = new Paho.Message(this.state.messageToSend);
    message.destinationName = "00013";

    if(this.state.isConnected){
      this.state.client.send(message);
    }else{
      this.connect(this.state.client)
        .then(() => {
          this.state.client.send(message);
          this.setState({error: '', isConnected: true});
        })
        .catch((error)=> {
          console.log(error);
          this.setState({error: error});
        });
  	}
  }


  onConnectionLost(responseObject) {
    if (responseObject.errorCode !== 0) {
      console.log("vitals onConnectionLost:"+responseObject.errorMessage);
      this.setState({error: 'Lost Connection', isConnected: false});
    }
  }

	render() {

		return (
			<div>
				{this.state.noDevices &&
						<Row className="mb-3">
							<Col md="2">
								<h1 style={{color:'#e04e28'}}>DASHBOARD</h1>
							</Col>
							<Col md="2">
								<h4 className="pt-3">No Devices</h4>
							</Col>
						</Row>
				}
				{!this.state.noDevices &&
				<Fragment>
				<Row className="mb-3">
					<Col lg="12" xl="12">
						<h4 className="pt-3 text-center">Serial {this.state.currentDevice}</h4>
					</Col>
          {
            // <Col lg="6" xl="3">
  					// 	<h4 className="pt-3">Serial {this.state.currentDevice}</h4>
  					// </Col>
          }
				</Row>
				<Row>
          <Col lg="6" xl="4">
            <TotalHourlyUsagePVM currentDevice={this.state.currentDevice} authToken={this.state.authToken} />
            <UsedPowerSettings currentDevice={this.state.currentDevice} authToken={this.state.authToken} />
          </Col>
          <Col lg="6" xl="4">
            <TotalProcedures currentDevice={this.state.currentDevice} authToken={this.state.authToken} />
            <LaserUsage currentDevice={this.state.currentDevice} authToken={this.state.authToken} />
          </Col>
          <Col lg="6" xl="4">
            <WavelengthUsage currentDevice={this.state.currentDevice} authToken={this.state.authToken} />
          </Col>
          <Col lg="6" xl="4">
            <TopProcedures currentDevice={this.state.currentDevice} authToken={this.state.authToken} />
          </Col>
          <Col lg="6" xl="4">
            <TopProceduresCat currentDevice={this.state.currentDevice} authToken={this.state.authToken} />
          </Col>

					<Col lg="6" xl="4">
            <Booster200V message={this.state.mainMessage} currentDevice={this.state.currentDevice} isReceivingMessages={this.state.isReceivingMessages} />
            <BatteryHealth message={this.state.mainMessage} isReceivingMessages={this.state.isReceivingMessages} currentDevice={this.state.currentDevice}/>
					</Col>
					<Col lg="6" xl="4">
						<WifiStrength message={this.state.mainMessage} isReceivingMessages={this.state.isReceivingMessages} currentDevice={this.state.currentDevice} />
						<BleStrength message={this.state.mainMessage} isReceivingMessages={this.state.isReceivingMessages} currentDevice={this.state.currentDevice} />
					</Col>
					<Col lg="6" xl="4">
						<VisualAudioStatus message={this.state.mainMessage} isReceivingMessages={this.state.isReceivingMessages} currentDevice={this.state.currentDevice}/>
					</Col>
				</Row>
				</Fragment>
				}
			</div>
		);
	}
}

export default MobileVitalsDashboard;
