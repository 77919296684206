import React from "react";
import { Component, Fragment } from "react";
import {
	Progress,
	Card,
	CardBody,
  CardTitle,
	Input,
	Row,
	Col,
	Button,
	ButtonGroup,
	ListGroup,
	ListGroupItem,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ButtonDropdown,
	Tooltip
} from 'reactstrap';

import img1 from '../../../assets/images/background/active-bg.png';
import Moment from 'moment';
var moment = require('moment-timezone');

let lineOptions56 = {
	tooltips: {
		titleFontSize: 16,
		bodyFontSize: 14
	},
};

class TopProceduresCat extends React.Component {

	noResults = true;
	intervalID = 0;

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 2,
			topProcedures: [],
			colors: [{color:'warning'}, {color:'warning'}, {color:'success'}, {color:'info'}, {color:'teal'}, {color:'cyan'}, {color:'warning'}, {color:'success'}, {color:'info'}, {color:'teal'}],
			noDevices: false,
			noDataForDevices: false,
			currentDataSetting: 0,
			startDate: Moment().subtract(3, 'year').format('YYYY-MM-DD'),
			endDate: Moment().format('YYYY-MM-DD'),
			currentDate: Moment().format('YYYY-MM-DD'),
			isOpen: false,
			currentItem: "Global",
			tooltipOpen: false,
			totalProceduresLabel: 0,
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			ratioRange: 100,
			marketType: localStorage.getItem('marketType') ? JSON.parse(localStorage.getItem('marketType')) : 0
	  };

		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
		this.toggleDropDown = this.toggleDropDown.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.toggleTooltip = this.toggleTooltip.bind(this);
  }

	async componentDidMount() {

		document.body.addEventListener('set-market-type', this.handleMarketTypeChange.bind(this));

		if (this.state.currentUser) {
			this.getData(this.state.rSelected);
		} else {
			setTimeout(()=>{ this.getData(this.state.rSelected); }, 1000);
		}

		if (this.state.marketType==0) {
			this.intervalID = setInterval(()=>{ this.getData(this.state.rSelected); }, 600000);
		} else {
			this.intervalID = setInterval(()=>{ this.getData(this.state.rSelected); }, 600000);
		}
	}

	handleMarketTypeChange(event) {
    console.log("Top Procedures Category Market has changed to: ", event.detail);

		this.setState({ marketType: event.detail.id });
		setTimeout(()=>{ this.getData(this.state.rSelected); }, 200);

		if (event.detail.id==0) {
			clearInterval(this.intervalID);
			this.intervalID = setInterval(()=>{ this.getData(this.state.rSelected); }, 600000);
		} else {
			clearInterval(this.intervalID);
			this.intervalID = setInterval(()=>{ this.getData(this.state.rSelected); }, 600000);
		}
  }

	toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

	toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	handleChange(event) {
		if (event.target.getAttribute('name') == 'startDate') {
			this.setState({startDate: event.target.value});
			this.setState({
	      currentItem: "Custom Range",
				currentDataSetting: 3
	    });
		} else if (event.target.getAttribute('name') == 'endDate') {
			//console.log('this.state.code: ' + event.target.value);
			this.setState({endDate: event.target.value});
			this.setState({
				currentItem: "Custom Range",
				currentDataSetting: 3
	    });
		}

		setTimeout(()=>{ this.getData(this.state.rSelected); }, 1000);
  }

	handleSubmit(event) {

		this.getData(this.state.rSelected);
  }

	selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		if (id==1) {
			this.setState({ startDate: Moment().subtract(1, 'week').format('YYYY-MM-DD') });
		} else if (id==2) {
			this.setState({ startDate: Moment().subtract(1, 'month').format('YYYY-MM-DD') });
		} else if (id==3) {
			this.setState({ startDate: Moment().format('YYYY-MM-DD') });
		}

		this.setState({ endDate: Moment().format('YYYY-MM-DD') });

		setTimeout(()=>{ this.getData(this.state.rSelected); }, 1000);
  }

	stepBack () {
		if (this.state.currentDataSetting==1) {
			let revisedStartDate = Moment(this.state.startDate).subtract(1, 'week').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).subtract(1, 'week').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		} else if (this.state.currentDataSetting==2) {
			let revisedStartDate = Moment(this.state.startDate).subtract(1, 'month').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).subtract(1, 'month').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		}

		setTimeout(()=>{ this.getData(this.state.rSelected); }, 1000);
	}

	stepForward () {
		if (this.state.currentDataSetting==1) {
			let revisedStartDate = Moment(this.state.startDate).add(1, 'week').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).add(1, 'week').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		} else if (this.state.currentDataSetting==2) {
			let revisedStartDate = Moment(this.state.startDate).add(1, 'month').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).add(1, 'month').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		}

		setTimeout(()=>{ this.getData(this.state.rSelected); }, 1000);
	}

	componentWillUnmount() {
		//console.log("Dashboard componentWillUnmount");
		clearInterval(this.intervalID);
		document.body.removeEventListener('set-market-type', this.handleMarketTypeChange.bind(this));
	}

	async onRadioBtnClick(rSelected) {
		await this.setState({ rSelected });
		await this.getData(rSelected);
	}

	getData = async category => {

		var timezone = Moment.tz.guess();

		if (this.state.currentDataSetting==0) {
			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"top-procedures-by-category?MarketType="+this.state.marketType+"&CategoryId="+category;

			var token = "";

			if (this.state.currentUser) {
				token = this.state.currentUser.jwtToken;
			}

			if (this.props.authToken) {
				console.log("top-procedures-cat this.props.authToken: " + this.props.authToken);
				token = this.props.authToken;
			}

			try {

				const response = await fetch(url, {
					method: 'GET',
					headers: {
						Authorization: token
					},
				});

				const body = await response.json();
				console.log("topprocedures by cat body response: " + JSON.stringify(body));

				if (body.status == 'Success') {

					var total = 0;

					body.results.map((prop, key) => {
						total = total + parseInt(prop.total);
					});

					this.setState({ totalProceduresLabel: total });

					if (body.results.length>0) {
						var largest = 100;
						for (var i=0; i < body.results.length; i++) {
							if (body.results[i].total > largest) {
								largest = body.results[i].total;
							}
						}
						this.setState({ topProcedures: body.results }, () => console.log(this.state.topProcedures));
						this.setState({
							noDataForDevices: false,
							ratioRange: 2*largest
				    });
					} else {

						if (this.state.currentUser && this.state.currentUser.role && this.state.currentUser.role>1) {
							if (this.state.topProcedures.length>0) {
								this.setState({
									noDataForDevices: false
								});
							} else {
								this.setState({
									noDataForDevices: true
								});
							}
						} else {
							this.setState({
								noDataForDevices: true
							});
						}

					}
				} else {
					if (this.state.currentUser && this.state.currentUser.role && this.state.currentUser.role>1) {
						if (this.state.topProcedures.length>0) {
							this.setState({
								noDataForDevices: false
							});
						} else {
							this.setState({
								noDataForDevices: true
							});
						}
					} else {
						this.setState({
							noDataForDevices: true
						});
					}
				}

			}
			catch (err) {
				console.log(err);
			}

		} else {
			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"top-procedures-by-category-date-range?MarketType="+this.state.marketType+"&CategoryId="+category+"&StartDate="+this.state.startDate+"&EndDate="+this.state.endDate+"&Timezone="+timezone;

			var token = "";

			if (this.state.currentUser) {
				token = this.state.currentUser.jwtToken;
			}

			if (this.props.authToken) {
				console.log("top-procedures-cat this.props.authToken: " + this.props.authToken);
				token = this.props.authToken;
			}

			try {

				const response = await fetch(url, {
					method: 'GET',
					headers: {
						Authorization: token
					},
				});

				const body = await response.json();
				console.log("topprocedures by cat body response: " + JSON.stringify(body));

				if (body.status == 'Success') {

					var total = 0;

					body.results.map((prop, key) => {
						total = total + parseInt(prop.total);
					});

					this.setState({ totalProceduresLabel: total });

					if (body.results.length>0) {
						var largest = 100;
						for (var i=0; i < body.results.length; i++) {
							if (body.results[i].total > largest) {
								largest = body.results[i].total;
							}
						}
						this.setState({ topProcedures: body.results }, () => console.log(this.state.topProcedures));
						this.setState({
							noDataForDevices: false,
							ratioRange: 2*largest
				    });
					} else {
						if (this.state.currentUser && this.state.currentUser.role && this.state.currentUser.role>1) {
							if (this.state.topProcedures.length>0) {
								this.setState({
									noDataForDevices: false
								});
							} else {
								this.setState({
									noDataForDevices: true
								});
							}
						} else {
							this.setState({
								noDataForDevices: true
							});
						}
					}
				} else {
					if (this.state.currentUser && this.state.currentUser.role && this.state.currentUser.role>1) {
						if (this.state.topProcedures.length>0) {
							this.setState({
								noDataForDevices: false
							});
						} else {
							this.setState({
								noDataForDevices: true
							});
						}
					} else {
						this.setState({
							noDataForDevices: true
						});
					}
				}

			}
			catch (err) {
				console.log(err);
			}

		}

	}

	getColor(presetID) {

		var color = "success";

		if (presetID>=1 && presetID<=5) {
			color = "success";
		} else if (presetID>=11 && presetID<=19) {
			color = "info";
		} else if (presetID>=21 && presetID<=23) {
			color = "yellow";
		}

		return color;

	}

	render() {
		return (
			/*--------------------------------------------------------------------------------*/
			/* Used In Dashboard-1 [Classic]                                                  */
			/*--------------------------------------------------------------------------------*/
			<Card className="card-hover">
				<CardBody style={{ minHeight: 650 }}>
					<Row>
						<Col xs="5" md="5" lg="5" className="text-left">
							<CardTitle>Top Procedures by Category
							<i style={{color: '#ffffff'}} className="mdi mdi-information-outline ml-1" id="TopProceduresCatTooltip"/>
							<Tooltip placement="top" isOpen={this.state.tooltipOpen} target="TopProceduresCatTooltip" toggle={this.toggleTooltip}>
								Displays the most common procedures performed by category by desired time period, as well as how many times each of these top procedures were performed.
							</Tooltip>
							</CardTitle>
						</Col>
						<Col xs="2" md="2" lg="2" className="text-right">
							<h5 className="text-center" style={{color: '#fec007', lineHeight: "1.7"}}>{this.state.totalProceduresLabel}</h5>
						</Col>
						<Col xs="5" md="5" lg="5" className="text-right">
							<ButtonDropdown isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
								<DropdownToggle color="dark" caret>
									{this.state.currentItem}
								</DropdownToggle>
								<DropdownMenu right>
									<DropdownItem onClick={() => this.selectDropDownItem(0, 'Global')}>Global</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(1, 'By Week')}>By Week</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(2, 'By Month')}>By Month</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(3, 'Custom Range')}>Custom Range</DropdownItem>
								</DropdownMenu>
							</ButtonDropdown>
						</Col>
					</Row>
					{this.state.noDevices ? (
						<div className="mt-5 text-center">
							<h5>User has no devices</h5>
						</div>
					) : (
						<Fragment>
						{this.state.currentDataSetting == 0 || this.state.currentDataSetting == 3 ? (
							<Fragment>
							<Row className="pt-3">
								<Col xs="12" md="12" lg="12">
									<div className="mt-1 mb-1">
										<ButtonGroup style={{ width: '100%' }}>
											<Button
												style={{width: '100%'}}
												color="success"
												onClick={() => this.onRadioBtnClick(1)}
												active={this.state.rSelected === 1}
											>
												Non Surgical
											</Button>
											<Button
												color="info"
												style={{width: '100%'}}
												onClick={() => this.onRadioBtnClick(2)}
												active={this.state.rSelected === 2}
											>
												Surgical
											</Button>
											<Button
												color="yellow"
												style={{width: '100%'}}
												onClick={() => this.onRadioBtnClick(3)}
												active={this.state.rSelected === 3}
											>
												Pain Relief
											</Button>
										</ButtonGroup>
									</div>
								</Col>
							</Row>
							<div className="ml-auto mt-1" style={{height: '390px'}}>
									{this.state.noDataForDevices ? (
										<div className="d-flex justify-content-center align-items-center" style={{ height: '100%'}}>
											<h5>No data for user's devices</h5>
										</div>
									) : (
										<div className="pt-3">
											{this.state.topProcedures.map((procedure, i) => (
												<Fragment>
												<Row key={i}>
													<Col xs="3" md="3" lg="3" className="text-left">
														<span>{procedure.total}</span>
													</Col>
													<Col xs="9" md="9" lg="9" className="text-right">
														{procedure.name == "7mm" || procedure.name == "3mm" || procedure.name == "25mm" ? (
															<span>{procedure.name} PBM</span>
														) : (
															<span>{procedure.name}</span>
														)}
													</Col>
												</Row>
												<Progress multi className="mt-2 mb-3">
													<Progress bar color={this.getColor(procedure.preset)} value={(procedure.total/this.state.ratioRange)*100} />
												</Progress>
												</Fragment>
											))}
										</div>
									)}
							</div>
							{this.state.currentDataSetting != 0 &&
								<Fragment>
								<Row className="pt-5 mt-5">
									<Col lg="12" xl="6" className="text-right">
										<div className="float-left" style={{ minWidth: '10%' }}>
											<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>From:</h6>
										</div>
										<div className="float-left ml-1" style={{ minWidth: '75%' }}>
											<Input style={{ minWidth: '135px' }} name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleChange} />
										</div>
									</Col>
									<Col lg="12" xl="6" className="">
										<div className="float-left text-right" style={{ minWidth: '10%' }}>
											<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>To:</h6>
										</div>
										<div className="float-left ml-1" style={{ minWidth: '75%' }}>
											<Input style={{ minWidth: '135px' }} name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
										</div>
										{
											// <div className="float-left ml-1" style={{ minWidth: '16%' }}>
											// <Button size="sm" color="dark" onClick={() => this.handleSubmit()} title="Go" disabled={this.state.currentDataSetting != 3}>
											// 	GO
											// </Button>
											// </div>
										}
									</Col>
								</Row>
								</Fragment>
							}
							</Fragment>
						) : (
							<Fragment>
							<Row className="pt-3">
								<Col xs="12" md="12" lg="12">
									<div className="mt-1 mb-1">
										<ButtonGroup style={{ width: '100%' }}>
											<Button
												style={{width: '100%'}}
												color="success"
												onClick={() => this.onRadioBtnClick(1)}
												active={this.state.rSelected === 1}
											>
												Non Surgical
											</Button>
											<Button
												color="info"
												style={{width: '100%'}}
												onClick={() => this.onRadioBtnClick(2)}
												active={this.state.rSelected === 2}
											>
												Surgical
											</Button>
											<Button
												color="yellow"
												style={{width: '100%'}}
												onClick={() => this.onRadioBtnClick(3)}
												active={this.state.rSelected === 3}
											>
												Pain Relief
											</Button>
										</ButtonGroup>
									</div>
								</Col>
							</Row>
							<Row className="align-items-center mt-4" style={{height: '370px'}}>
								<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
									<Button size="sm" color="link" onClick={() => this.stepBack()} title="Step Back">
										<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-left mdi-24px"/>
									</Button>
								</div>
								<div style={{ width: '80%', margin: '0 auto', height: '100%' }}>
									<div className="ml-4 mt-1">
											{this.state.noDataForDevices ? (
												<div className="d-flex justify-content-center align-items-center" style={{ height: '100%'}}>
													<h5>No data for user's devices</h5>
												</div>
											) : (
												<div className="pt-1">
													{this.state.topProcedures.map((procedure, i) => (
														<Fragment>
														<Row key={i}>
															<Col xs="3" md="3" lg="3" className="text-left">
																<span>{procedure.total}</span>
															</Col>
															<Col xs="9" md="9" lg="9" className="text-right">
																{procedure.name == "7mm" || procedure.name == "3mm" || procedure.name == "25mm" ? (
																	<span>{procedure.name} PBM</span>
																) : (
																	<span>{procedure.name}</span>
																)}
															</Col>
														</Row>
														<Progress multi className="mt-2 mb-3">
															<Progress bar color={this.getColor(procedure.preset)} value={(procedure.total/this.state.ratioRange)*100} />
														</Progress>
														</Fragment>
													))}
												</div>
											)}
									</div>
								</div>
								<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
									<Button size="sm" color="link" disabled={this.state.currentDate==this.state.endDate} onClick={() => this.stepForward()} title="Step Forward">
										<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-right mdi-24px"/>
									</Button>
								</div>
							</Row>
							<Row className="pt-5 mt-5">
								<Col lg="12" xl="6" className="text-right">
									<div className="float-left" style={{ minWidth: '10%' }}>
										<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>From:</h6>
									</div>
									<div className="float-left ml-1" style={{ minWidth: '75%' }}>
										<Input style={{ minWidth: '135px' }} name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleChange} />
									</div>
								</Col>
								<Col lg="12" xl="6" className="">
									<div className="float-left text-right" style={{ minWidth: '10%' }}>
										<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>To:</h6>
									</div>
									<div className="float-left ml-1" style={{ minWidth: '75%' }}>
										<Input style={{ minWidth: '135px' }} name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
									</div>
									{
										// <div className="float-left ml-1" style={{ minWidth: '16%' }}>
										// <Button size="sm" color="dark" onClick={() => this.handleSubmit()} title="Go">
										// 	GO
										// </Button>
										// </div>
									}
								</Col>
							</Row>
							</Fragment>
						)}
						</Fragment>
					)}
				</CardBody>
			</Card>

		);
	}
}

export default TopProceduresCat;
