import React from "react";
import { Component, Fragment } from "react";
import {
	Card,
	CardBody,
	CardTitle,
	Row,
	Col,
	Button,

} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as mqtt from 'react-paho-mqtt';
import Paho from 'paho-mqtt';
import { getConnectionURL } from "../../mqtt-helpers/mqtt-request";
import { authenticationService } from '../../jwt/_services';
import ReactGA from "react-ga4";

import {
	TopProceduresCat,
	BatteryHealth,
	Booster200V,
	WavelengthUsage,
	WifiStrength,
	ExtendedWarranty,
	VisualAudioStatus,
	TotalHourlyUsagePVM,
	BleStrength
} from '../../components/vitals/';

class Vitals extends React.Component {

	constructor(props) {
    super(props);

		var connectionTimeout;

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

		this.onMessageArrived = this.onMessageArrived.bind(this);
    this.onConnectionLost = this.onConnectionLost.bind(this);

		// var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    // console.log('random hash: ' + hash);
		// const client = new Paho.Client(getConnectionURL(), hash+this.props.match.params.id);
    // client.onMessageArrived = this.onMessageArrived;
    // client.onConnectionLost = this.onConnectionLost;
    // client.connect({
		// 	timeout: 3,
		// 	useSSL: true,
		// 	mqttVersion: 4,
		// 	cleanSession: true,
		// 	onSuccess: this.onConnect,
		// 	onFailure: (e) => {console.log("here is the error" , e); }
    // });

    this.state = {
			userVitalsHistoryData: null,
			mainMessage: [''],
			noDevices: false,
			subMessage: [''],
			client: null,
			currentUser,
      messageToSend:'',
			currentDevice: this.props.match.params.id,
      isConnected: false,
			isReceivingMessages: false,
			devices: [''],
	  };

  }

	componentDidMount() {

		ReactGA.pageview(window.location.pathname);

		this.connectToMQTT();

		if (this.state.currentDevice === "00000") {
			this.setState({noDevices: true});
		}

		this.getUserVitalsHistoryData();
		//setInterval(this.checkIsConnected, 2000);
	}

	componentWillUnmount() {

		//var currentConnectedDevice = JSON.parse(localStorage.getItem('currentConnectedDevice'));

		// if (currentConnectedDevice == this.state.currentDevice) {
		// 	localStorage.setItem('currentConnectedDevice', '');
		// 	localStorage.setItem('isDeviceReceivingMessages', false);
		// }

		if (this.state.client && this.state.client.isConnected()) {
			this.state.client.disconnect();
		}

	}

	getUserVitalsHistoryData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-dashboard-user-vitals-history?SerialNumber="+this.state.currentDevice;

		try {
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("getUserVitalsHistoryData response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				console.log("getUserVitalsHistoryData response: " + JSON.stringify(body.response[0].Data));

				console.log("getUserVitalsHistoryData Data: " + JSON.stringify(JSON.parse(body.response[0].Data)));

				var historyMessage = JSON.parse(body.response[0].Data);
				console.log("historyMessage: " + JSON.stringify(historyMessage));
				console.log("historyMessage fw_up: " + JSON.stringify(historyMessage.fw_up));

				this.setState({ userVitalsHistoryData: historyMessage });
			}

		}
		catch (err) {
			console.log(err);
		}

	}

	connectToMQTT = async () => {
		authenticationService.getDeviceConnectionURL(this.state.currentDevice)
				.then(
						connectionUrl => {
							console.log('connectToMQTT connectionUrl: ' + JSON.stringify(connectionUrl));

							//var url1 = "wss://a1f3ynjyzalf3r-ats.iot.us-west-2.amazonaws.com/mqtt?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAS3T2QUYOSMZKW4V2%2F20210201%2Fus-west-2%2Fiotdevicegateway%2Faws4_request&X-Amz-Date=20210201T225801Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=dff6d2d6b29772129d10a43adf2b218d07b3cd3423ac13e32c71c6d7f8032eaf";

							if (connectionUrl.status == 'Success') {
								var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
								console.log('random hash: ' + hash);
								var clientIdHash = "g2_"+this.state.currentDevice+"_"+connectionUrl.type;
								this.state.client = new Paho.Client(connectionUrl.response, clientIdHash);
								this.state.client.onMessageArrived = this.onMessageArrived;
								this.state.client.onConnectionLost = this.onConnectionLost;
								this.state.client.connect({
									timeout: 3,
									useSSL: true,
									mqttVersion: 4,
									cleanSession: true,
									onSuccess: this.onConnect,
									onFailure: (e) => {console.log("here is the error" , e); }
								});
							}
						},
						error => {
							console.log('error.message: ' + error.message);
						}
				);
	}

	checkIsConnected = () => {
		if (this.state.isReceivingMessages) {
			console.log("Vitals Is Connected!");
		} else {
			console.log("Vitals Is Not Connected!");
		}
	}

	updateIsConnected = () => {
		this.setState({isReceivingMessages: false});

		//localStorage.setItem('currentConnectedDevice', '');
		localStorage.setItem('isDeviceReceivingMessages', false);

		console.log("Vitals isReceivingMessages false");

		this.getUserVitalsHistoryData();
	}

	onMessageArrived(entry) {
		this.setState({isReceivingMessages: true});
		clearTimeout(this.connectionTimeout)
    console.log("Vitals onMessageArrived:"+entry.payloadString);
		const msg = JSON.parse(entry.payloadString);

		localStorage.setItem('currentConnectedDevice', JSON.stringify(msg.serial_num));
		localStorage.setItem('isDeviceReceivingMessages', true);

		if (msg.batch) {
			this.setState({mainMessage: JSON.parse(entry.payloadString)});
			console.log("vitals_main_messages:"+this.state.mainMessage);
		} else {
			this.setState({subMessage: JSON.parse(entry.payloadString)});
			console.log("vitals_sub_messages:"+this.state.subMessage);
		}
		this.connectionTimeout = setInterval(this.updateIsConnected, 10000);
  }

  onConnect = () => {
    const { client } = this.state;
    console.log("Connected!!!!");
    //client.subscribe('00013/out');
    this.setState({isConnected: true, error: ''})
		//this.setState({isReceivingMessages: true})
		//this.sendMessage("@@@get_all");
		console.log('myCurrentDevice id: ' + this.state.currentDevice);
		//this.setState({currentDevice: this.state.currentDevice});
		const subChannel = this.state.currentDevice+'/out';
		this.state.client.subscribe(subChannel);
		this.connectionTimeout = setInterval(this.updateIsConnected, 10000);
  };


  sendMessage(){
    var message = new Paho.Message(this.state.messageToSend);
    message.destinationName = "00013";

    if(this.state.isConnected){
      this.state.client.send(message);
    }else{
      this.connect(this.state.client)
        .then(() => {
          this.state.client.send(message);
          this.setState({error: '', isConnected: true});
        })
        .catch((error)=> {
          console.log(error);
          this.setState({error: error});
        });
  	}
  }


  onConnectionLost(responseObject) {
    if (responseObject.errorCode !== 0) {
      console.log("vitals onConnectionLost:"+responseObject.errorMessage);
      this.setState({error: 'Lost Connection', isConnected: false});
    }
  }

	render() {
		var customizePath = "/customize/"+this.state.currentDevice;
		var remoteSupportPath = "/remotesupport/"+this.state.currentDevice;
		var vitalsDashboardPath = "/vitals-dashboard/"+this.state.currentDevice;
		var deviceLogs = "/device-logs/"+this.state.currentDevice;
		return (
			<div>
				{this.state.noDevices &&
						<Row className="mb-3">
							<Col md="2">
								<h1 style={{color:'#e04e28'}}>Vitals</h1>
							</Col>
							<Col md="2">
								<h4 className="pt-3">No Devices</h4>
							</Col>
						</Row>
				}
				{!this.state.noDevices &&
				<Fragment>
				<Row className="mb-3">
					<Col lg="6" xl="2">
						<h1 style={{color:'#e04e28'}}>Vitals</h1>
					</Col>
					<Col lg="6" xl="4">
						<Row className="mb-3">
							<Col lg="6" xl="6" className="text-right">
								<h4 className="pt-3">Serial {this.state.currentDevice}</h4>
							</Col>
							<Col lg="6" xl="6" className="text-left">
								{this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']==2 &&
									<Fragment>
									{this.state.isReceivingMessages ? (
										<h3 style={{color: '#48b203'}} className="pt-2">( LIVE )</h3>
									) : (
										<Fragment>
										{this.state.userVitalsHistoryData &&
											<h3 style={{color: '#CD9706'}} className="pt-2">( HISTORICAL )</h3>
										}
										</Fragment>
									)}
									</Fragment>
								}
							</Col>
						</Row>
					</Col>
					<Col lg="6" xl="6">
						<div className="float-right">
							<Button color="dark" href={customizePath}>
								Customize
							</Button>
							{this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']>0 &&
								<Fragment>
								<Button className="ml-2" color="dark" href={remoteSupportPath}>
									Remote Support
								</Button>
								<Button className="ml-2" color="dark" href={vitalsDashboardPath}>
									Dashboard
								</Button>
								<Button className="ml-2" color="dark" href={deviceLogs}>
									Device Logs
								</Button>
								</Fragment>
							}
						</div>
					</Col>
				</Row>
				<Row>
					<Col lg="6" xl="4">
						<TopProceduresCat currentDevice={this.state.currentDevice} />
					</Col>
					<Col lg="6" xl="4">
						<BatteryHealth historyMessage={this.state.userVitalsHistoryData} message={this.state.mainMessage} isReceivingMessages={this.state.isReceivingMessages} currentDevice={this.state.currentDevice}/>
						<TotalHourlyUsagePVM currentDevice={this.state.currentDevice} />
					</Col>
					<Col lg="6" xl="4">
						<WavelengthUsage currentDevice={this.state.currentDevice} />
					</Col>
					<Col lg="6" xl="4">
						<WifiStrength historyMessage={this.state.userVitalsHistoryData} message={this.state.mainMessage} isReceivingMessages={this.state.isReceivingMessages} currentDevice={this.state.currentDevice} />
						<BleStrength historyMessage={this.state.userVitalsHistoryData} message={this.state.mainMessage} isReceivingMessages={this.state.isReceivingMessages} currentDevice={this.state.currentDevice} />
					</Col>
					<Col lg="6" xl="4">
						<VisualAudioStatus historyMessage={this.state.userVitalsHistoryData} message={this.state.mainMessage} isReceivingMessages={this.state.isReceivingMessages} currentDevice={this.state.currentDevice}/>
					</Col>
					<Col lg="6" xl="4">
						<Booster200V historyMessage={this.state.userVitalsHistoryData} message={this.state.mainMessage} currentDevice={this.state.currentDevice} isReceivingMessages={this.state.isReceivingMessages} />
					</Col>
				</Row>
				</Fragment>
				}
			</div>
		);
	}
}

export default Vitals;
