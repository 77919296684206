/** @jsx jsx */
import { jsx } from '@emotion/core'

import { CometChat } from '@cometchat-pro/chat';

import Avatar from "../Avatar";
import BadgeCount from "../BadgeCount";
import StatusIndicator from "../StatusIndicator";

import { listItem, itemThumbnailStyle, itemDetailStyle, itemNameStyle, itemDescStyle, itemRowStyle, itemLastMsgStyle } from "./style";

const userview = (props) => {

  let userPresence = (
    <StatusIndicator
    widgetsettings={props.widgetsettings}
    status={props.user.status}
    cornerRadius="50%"
    borderColor={props.theme.color.darkSecondary}
    borderWidth="1px" />
  );

  const toggleTooltip = (event, flag) => {

    const elem = event.target;

    const scrollWidth = elem.scrollWidth;
    const clientWidth = elem.clientWidth;

    if(scrollWidth <= clientWidth) {
      return false;
    }

    if(flag) {
      elem.setAttribute("title", elem.textContent);
    } else {
      elem.removeAttribute("title");
    }
  }

  const getLastMessage = () => {

    if(!props.conversation.lastMessage)
      return false;

    const lastMessage = props.conversation.lastMessage;

    let message = null;
    if (lastMessage.hasOwnProperty("deletedAt")) {

      message = (props.loggedInUser.uid === lastMessage.sender.uid) ? "⚠ You deleted this message." : "⚠ This message was deleted.";

    } else {

      switch (lastMessage.category) {
        case "message":
          message = getMessage();
        break;
        default:
        break;
      }
    }
    return message;
  }

  // const getBadgeCount = () => {
  //
  //   console.log("user: ", props.user);
  //
  //   var badgeCount = 2;
  //
  //   return badgeCount;
  //
  //   CometChat.getUnreadMessageCountForUser(props.user.uid).then(
  //     array => {
  //       console.log("Message array fetched", array);
  //       console.log("Message count", array[props.user.uid]);
  //       badgeCount = array[props.user.uid];
  //
  //       console.log("badgeCount: ", badgeCount);
  //
  //       //var badgeCount = array.length;
  //       //this.setState({ badgeCount });
  //       //localStorage.setItem('badgeCount', badgeCount);
  //
  //       return badgeCount;
  //
  //     }, error => {
  //       console.log("Error in getting message count", error);
  //
  //       return 0;
  //     }
  //   );
  //
  // }

  const getMessage = () => {

    let message = "";
    const type = props.conversation.lastMessage.type;

    switch(type) {
      case CometChat.MESSAGE_TYPE.TEXT:
        message = props.conversation.lastMessage.text;
      break;
      case CometChat.MESSAGE_TYPE.MEDIA:
        message = "Media message";
      break;
      case CometChat.MESSAGE_TYPE.IMAGE:
        message = "📷 Image ";
      break;
      case CometChat.MESSAGE_TYPE.FILE:
        message = "📁 File";
      break;
      case CometChat.MESSAGE_TYPE.VIDEO:
        message = "🎥 Video";
      break;
      case CometChat.MESSAGE_TYPE.AUDIO:
        message = "🎵 Audio";
      break;
      case CometChat.MESSAGE_TYPE.CUSTOM:
        message = "Custom message";
      break;
      default:
      break;
    }

    return message;
  }

  return (
    <div css={listItem(props)} onClick={() => props.clickeHandler(props.user, props.badgeCount)} className="list__item">
      <div css={itemThumbnailStyle()} className="list__item__thumbnail">
        <Avatar
        image={props.user.avatar}
        cornerRadius="50%"
        borderColor={props.theme.color.secondary}
        borderWidth="1px" />
        {userPresence}
      </div>
      <div css={itemDetailStyle()} className="list__item__details">
        <div css={itemNameStyle()} className="item__details__name"
        onMouseEnter={event => toggleTooltip(event, true)}
        onMouseLeave={event => toggleTooltip(event, false)}>{props.user.name}</div>
        <div css={itemDescStyle(props.theme)} className="item__details__desc"></div>
      </div>
      <div css={itemRowStyle()} className="item__details_block_two">
      {
        <BadgeCount theme={props.theme} count={props.badgeCount} />
      }
      </div>
    </div>
  )
}

export default userview;
