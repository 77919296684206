import React from "react";
import {
	Card,
	CardBody,
	CardTitle,
	Row,
	Col,

} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactGA from "react-ga4";

import {
	MapContainerFull
} from '../../components/heartbeat/';

class HeartbeatFull extends React.Component {

	constructor(props) {
    super(props);

  }

	componentDidMount() {
		ReactGA.pageview(window.location.pathname);
	}

	render() {
		return (
			<div>
				<Row>
					<Col md="12">
						<MapContainerFull />
					</Col>
				</Row>
			</div>
		);
	}
}

export default HeartbeatFull;


// <Row>
// 	<Col xs={12}>
// 		<h1>All Procedures from Database:</h1>
// 		{this.state.procedures.map(procedure => (
// 				<p key={procedure.OBJID}>{procedure.Name}</p>
// 		))}
// 	</Col>
// </Row>
