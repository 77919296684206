import React from "react";
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';

class ExtendedWarranty extends React.Component {

	noResults = true;

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 3,
			totalProcedures: 0,
	  };

		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
		this.toggle = this.toggle.bind(this);
  }

	async componentDidMount() {
	 	this.getData(this.state.rSelected);
	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

  onRadioBtnClick(rSelected) {
    this.setState({ rSelected });
		this.getData(rSelected);
  }

	getData = async queryInterval => {

		console.log("queryInterval: " + JSON.stringify(queryInterval));

		const response = await fetch('/api/totalprocedures', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ interval: queryInterval }),
		});
		const body = await response.json();
		console.log("totalprocedures: " + JSON.stringify(body));

		if (body.status != 400) {
			if (body.results.length > 0) {
				//let powerAvg = parseFloat(body.results[0].power, 10)/10;
				//let roundedPower = powerAvg.toFixed(1)
				this.setState({ totalProcedures: body.results[0].total }, () => console.log(this.state.totalProcedures));
				this.noResults = false;
			} else {
				this.noResults = true;
			}
			this.forceUpdate();
		}
	};

	render() {
		return (
      <Card className="card-hover">
				<CardBody style={{ minHeight: 193 }}>
					<CardTitle>Extended Warranty</CardTitle>
					<Row>
						<Col xs="1" md="1" lg="1">
						</Col>
						<Col xs="10" md="10" lg="10">
							<div className="">
								<ButtonGroup style={{ width: '100%' }}>
									<Button
										size="sm"
										style={{width: '150px'}}
										color="good"
										onClick={() => this.onRadioBtnClick(1)}
										active={this.state.rSelected === 1}
										disabled={this.state.rSelected !== 1}
									>
										Purchase
									</Button>
									<Button
										size="sm"
										color="verydark"
										style={{width: '150px'}}
									disabled>
									</Button>
									<Button
										size="sm"
										color="damaged"
										style={{width: '150px'}}
										onClick={() => this.onRadioBtnClick(3)}
										active={this.state.rSelected === 3}
										disabled={this.state.rSelected !== 3}
									>
										Not Available
									</Button>
								</ButtonGroup>
							</div>
						</Col>
						<Col xs="1" md="1" lg="1">
						</Col>
					</Row>
					<div className="pb-3">
						<Row className="mt-3">
							<Col className="text-left">
								<h4>Purchased on 10/23/2020</h4>
							</Col>
						</Row>
						<Row className="mt-1">
							<Col xs="6" md="6" lg="6" className="text-left">
								<span>250</span>
							</Col>
							<Col xs="6" md="6" lg="6" className="text-right">
								<span>Days Left</span>
							</Col>
						</Row>
						<Progress multi className="mt-1">
							<Progress bar color="yellow" value="65" />
						</Progress>
					</div>
        </CardBody>
				<div>
				<Modal color="dark" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
	        <ModalHeader toggle={this.toggle}>You're about to update a Preset Setting</ModalHeader>
	        <ModalBody>
						Are you sure you want to update that Preset setting?
	        </ModalBody>
	        <ModalFooter>
						<Button color="secondary" onClick={this.toggle}>Cancel</Button>
	          <Button color="secondary" onClick={() => {this.confirmSavePreset()}}>Confirm</Button>
	        </ModalFooter>
	      </Modal>
				</div>
      </Card>
		);
	}
}

export default ExtendedWarranty;
