import React, { useState } from "react";
import { Component, Fragment, PureComponent } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Fade
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';

class Firmware extends React.Component {

	intervalID = 0;
	intervalID2 = 1;

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 3,
			totalProcedures: 0,
			firmwareUpdate: false,
			isReadyToUpdate: false,
			showUpdateFlow: false,
			updatingMessage: this.props.updatingMessage,
			updateStatus: 0,
			updateFinished: false,
			checkUpdate: 0
	  };

		this.toggle = this.toggle.bind(this);

		this.updateErrors = [
			{ name: "Update Not Complete" },
			{ name: "Successful update" },
			{ name: "Sound partition not found" },
			{ name: "Failed to erase sound partition" },
			{ name: "Failed to initialise HTTP connection" },
			{ name: "Failed to open HTTP connection" },
			{ name: "Couldn't allocate memory to upgrade data buffer" },
			{ name: "Connection closed" },
			{ name: "SSL data read error" },
			{ name: "Firmware OTA partition not found" },
			{ name: "Failed to initialise HTTP connection" },
			{ name: "Failed to open HTTP connection" },
			{ name: "esp_ota_begin failed" },
			{ name: "Couldn't allocate memory to upgrade data buffer" },
			{ name: "SSL data read error" },
			{ name: "esp_ota_write failed" },
			{ name: "esp_ota_end failed" },
			{ name: "esp_ota_set_boot_partition failed" },
			{ name: "Aborted, low battery" },
		];
  }

	async componentDidMount() {
		console.log("MY firmwareUpdatedMessageReceived: "+this.props.firmwareUpdatedMessageReceived);

		//setTimeout(()=>{ this.checkIfUpdatingInProgress(); }, 3000);
		this.intervalID2 = setInterval(()=>{ this.checkIfUpdatingInProgress(); }, 3000);
	}

	componentWillUnmount() {
		//console.log("WavelengthUsage componentWillUnmount");
		clearInterval(this.intervalID);
		clearInterval(this.intervalID2);
	}

	checkIfUpdatingInProgress() {
		console.log("checkIfUpdatingInProgress Is Updating Value..."+this.props.isUpdating);

		if (this.props.isUpdating) {
			console.log("Is Updating...");

			this.setState({
				isReadyToUpdate: true,
				showUpdateFlow: true
			});

			if (this.state.checkUpdate==0)
				this.intervalID = setInterval(()=>{ this.checkIfUpdateCompleted(); }, 1000);
				this.setState({
					checkUpdate: 1,
				});
		} else {
			// this.setState({
			// 	updateStatus: 0,
			// });
		}
	}

	toggle() {
	  this.setState({
	    showUpdateFlow: !this.state.showUpdateFlow
	  });

		if (this.state.isReadyToUpdate) {
			this.setState({
				isReadyToUpdate: false
			});
		}
	}

	checkIfUpdateCompleted() {

		console.log("MY firmwareUpdatedMessageReceived: "+this.props.firmwareUpdatedMessageReceived);
	  if (this.props.firmwareUpdatedMessageReceived) {
			console.log("this.props.firmwareUpdatedMessage.update_status: "+this.props.firmwareUpdatedMessage.update_status);
			if (this.props.firmwareUpdatedMessage.update_status) {
				clearInterval(this.intervalID);
				this.setState({
					updateStatus: this.props.firmwareUpdatedMessage.update_status,
					updateFinished: true,
					checkUpdate: 0,
				});
			}
		}
	}

	confirmStartUpdate() {
		this.setState({
			isReadyToUpdate: true
		});

		var message = '@@@fw_update,'+this.props.currentDeviceUID;
		console.log("send message: "+message);
		this.props.sendMessage(message, this.props.state);

		this.intervalID = setInterval(()=>{ this.checkIfUpdateCompleted(); }, 1000);
	}

	confirmUpdateCompleted() {

		this.setState({
			showUpdateFlow: false,
			isReadyToUpdate: false,
			updateFinished: false,
			updateStatus: 0
		});

		clearInterval(this.intervalID);
	}

	render() {
		return (
      <Card className="card-hover">
				<CardBody style={{ minHeight: 210 }}>
				{this.props.isReceivingMessages &&
					<Fragment>
						{this.state.showUpdateFlow ? (
							<Fragment>
							{!this.state.isReadyToUpdate &&
								<Fragment>
								<Fade in="true">
								<Row className="mt-4">
									<Col xs="12" md="12" lg="12" className="text-center">
										<h5>Are you sure you want to update your Gemini Firmware?</h5>
									</Col>
								</Row>
								<Row className="mt-4">
									<Col xs="12" md="12" lg="12" className="text-center">
										<Button className="mr-4" color="secondary" onClick={this.toggle}>Cancel</Button>
										<Button color="yellow" onClick={() => {this.confirmStartUpdate()}}>Confirm Update</Button>
									</Col>
								</Row>
								</Fade>
								</Fragment>
							}
							{this.state.isReadyToUpdate &&

								<Fragment>
								{!this.state.updateFinished &&
									<Fragment>
									<Fade in="true">
									<Row className="mt-5">
										<Col xs="6" md="6" lg="6" className="text-left">
											<span>Progress</span>
										</Col>
										<Col xs="6" md="6" lg="6" className="text-right">
											<span>{this.props.updatingMessage.updating ? this.props.updatingMessage.updating : "0"}%</span>
										</Col>
									</Row>
									<Progress multi className="mt-3">
										<Progress bar color="yellow" value={this.props.updatingMessage.updating} />
									</Progress>
									</Fade>
									</Fragment>
								}
								{this.state.updateFinished &&
									<Fragment>
									{this.state.updateStatus==1 ? (
										<Fragment>
										<Fade in="true">
										<Row className="mt-4">
											<Col xs="12" md="12" lg="12" className="text-center">
												<h4 color="warning">Update Successful.</h4>
											</Col>
										</Row>
										<Row className="mt-4">
											<Col xs="12" md="12" lg="12" className="text-center">
												<Button style={{width: 100}} className="mr-4" color="secondary"  onClick={() => {this.confirmUpdateCompleted()}}>OK</Button>
											</Col>
										</Row>
										</Fade>
										</Fragment>
									) : (
										<Fragment>
										<Fade in="true">
										<Row className="mt-4">
											<Col xs="12" md="12" lg="12" className="text-center">
												<h5 color="warning">Update failed, it can happen sometimes…</h5>
											</Col>
										</Row>
										<Row className="mt-4">
											<Col xs="12" md="12" lg="12" className="text-center">
												<Button className="mr-4" color="secondary" onClick={this.toggle}>Cancel</Button>
												<Button color="yellow" onClick={() => {this.confirmStartUpdate()}}>Try Again</Button>
											</Col>
										</Row>
										<Row className="mt-4">
											<Col xs="12" md="12" lg="12" className="text-right">
												<span>Error code: {this.state.updateStatus}</span>
											</Col>
										</Row>
										</Fade>
										</Fragment>
									)}
									</Fragment>
								}
								</Fragment>
							}
						</Fragment>
					) : (
						<Fragment>
							<Fade in="true">
							<Row>
								<Col xs="6" md="6" lg="6" className="text-left">
									<CardTitle>Firmware</CardTitle>
								</Col>
								<Col xs="6" md="6" lg="6" className="text-right">
									<span style={{color:'#414755'}}>Installed Version {this.props.mainMessage.fw_ver}</span>
								</Col>
							</Row>
							{this.props.mainMessage.fw_up && this.props.mainMessage.fw_up !=0 ? (
								<Fragment>
								<Row className="pb-4 pt-3 text-center">
									<Col xs="12" md="12" lg="12">
										<h6>Update v{this.props.mainMessage.fw_up} is available</h6>
									</Col>
								</Row>
								<Row className="text-center">
									<Col xs="1" md="1" lg="1">
									</Col>
									<Col xs="10" md="10" lg="10">
										<Button style={{width: '100%'}} color="yellow" onClick={() => {this.toggle()}}>
											UPDATE
										</Button>
									</Col>
									<Col xs="1" md="1" lg="1">
									</Col>
								</Row>
								</Fragment>
							) : (
								<Fragment>
								<Row className="pb-4 pt-5 text-center">
									<Col xs="12" md="12" lg="12">
										<h6>Firmware is Up To Date.</h6>
									</Col>
								</Row>
								</Fragment>
							)}

							</Fade>
						</Fragment>
					)}
					</Fragment>
				}
				{!this.props.isReceivingMessages &&
					<Fragment>
					<Row>
						<Col xs="6" md="6" lg="6" className="text-left">
							<CardTitle>Firmware</CardTitle>
						</Col>
					</Row>
					<div className="mt-5 text-center">
						<h5>Devices {this.props.currentDevice} is Currently not Connected.</h5>
					</div>
					</Fragment>
				}
        </CardBody>
				<div>
				<Modal color="dark" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
	        <ModalHeader toggle={this.toggle}>Updating Gemini Laser Firmware</ModalHeader>
	        <ModalBody>

	        </ModalBody>
	        <ModalFooter>
					<Button color="secondary" onClick={this.toggle}>Cancel</Button>
	        </ModalFooter>
	      </Modal>
				</div>
      </Card>
		);
	}
}

export default Firmware;
