import React from 'react';
import {
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	CustomInput,
	FormGroup,
	Row,
	Col,
	UncontrolledTooltip,
	Button
} from 'reactstrap';
import { Auth } from "aws-amplify";
import img1 from '../../assets/images/logo2@2x.png';
import img2 from '../../assets/images/background/login-register.jpg';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { authenticationService } from '../../jwt/_services';
import ReactGA from "react-ga4";
const sidebarBackground = {
	//backgroundImage: "url(" + img2 + ")",
	backgroundColor: "#313642",
	backgroundRepeat: "no-repeat",
	backgroundPosition: "bottom center"
};

const formBackground = {
	//backgroundImage: "url(" + img2 + ")",
	backgroundColor: "#414755"
};

class Register extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			password: '',
			code: '',
			responseData: [],
			waitingForCode: false,
	  };

		this.handleClick = this.handleClick.bind(this);
	}
	handleClick() {
		console.log('submitted');
	}

	componentDidMount() {
		ReactGA.pageview(window.location.pathname);
	}

	confirmSignUp = (e) => {
		Auth.confirmSignUp(this.state.email, this.state.code)
      .then((data) => {
				console.log('confirmSignUp');
        console.log(data);
				this.setState({waitingForCode: false});
				this.setState({email: ''});
				this.setState({code: ''});
      })
      .catch((err) => console.log('confirmSignUp error: '+err));
	}

	resendCode = () => {
		Auth.resendSignUp(this.state.email)
      .then(() => {
        console.log("code resent successfully");
      })
      .catch((e) => {
        console.log('resendCode error: '+e);
      });
	}

	render() {
		return <div className="">
			{/*--------------------------------------------------------------------------------*/}
			{/*Register Cards*/}
			{/*--------------------------------------------------------------------------------*/}
			<div className="auth-wrapper d-flex no-block justify-content-center align-items-center" style={sidebarBackground}>
				<div className="auth-box on-sidebar" style={formBackground}>
					<div id="registerform">
						<div className="logo">
							<span className="db"><img src={img1} height="100" width="100" alt="logo" /></span>
							<h5 className="mb-3"></h5>
							<h5 className="font-medium mb-3 text-light">Sign Up to Admin</h5>
						</div>
						<Row>
							<Col xs="12">
								{!this.state.waitingForCode ? (
									<Formik
															initialValues={{
																	email: '',
																	password: '',
																	firstname: '',
																	lastname: ''
															}}
															validationSchema={Yup.object().shape({
																	email: Yup.string().required('Email is required'),
																	password: Yup.string().required('Password is required'),
																	firstname: Yup.string().required('First Name is required'),
																	lastname: Yup.string().required('Last Name is required')
															})}
															onSubmit={({ firstname, lastname, email, password }, { setStatus, setSubmitting }) => {
																	setStatus();
																	console.log('submitted');

																	Auth.signUp({ username: email, password, attributes: {
																		'email': email,
																		'given_name': firstname,
																		'family_name': lastname
																		}
																	})
														      .then((data) => {
														        console.log('confirmSignUp data: '+JSON.stringify(data));
																		firstname = '';
																		this.setState({waitingForCode: true});
														        this.setState({password: password});
																		this.setState({email: email});
																		this.setState({responseData: data});
																		setSubmitting(false);
														      })
														      .catch((err) => {
														        console.log('signUp error: '+JSON.stringify(err));
																		setStatus(err.message);
																		setSubmitting(false);
														      });

															}}
															render={({ errors, status, touched, isSubmitting }) => (
									<Form className="mt-3" id="registerform">
										<InputGroup className="mb-3">
											<InputGroupAddon addonType="prepend">
											</InputGroupAddon>
											<Field name="firstname" placeholder="First Name" type="text" className={'form-control' + (errors.firstname && touched.firstname ? ' is-invalid' : '')} />
	                                		<ErrorMessage name="firstname" component="div" className="invalid-feedback" />
										</InputGroup>
										<InputGroup className="mb-3">
											<InputGroupAddon addonType="prepend">
											</InputGroupAddon>
											<Field name="lastname" placeholder="Last Name" type="lastname" className={'form-control' + (errors.lastname && touched.lastname ? ' is-invalid' : '')} />
	                                		<ErrorMessage name="lastname" component="div" className="invalid-feedback" />

										</InputGroup>
										<InputGroup className="mb-3">
											<InputGroupAddon addonType="prepend">
											</InputGroupAddon>

											<Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
	                                		<ErrorMessage name="email" component="div" className="invalid-feedback" />
										</InputGroup>
										<InputGroup className="mb-3">
											<InputGroupAddon addonType="prepend">
											</InputGroupAddon>
											<Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
	                                		<ErrorMessage name="password" component="div" className="invalid-feedback" />

										</InputGroup>

										<CustomInput className=" text-light" type="checkbox" id="exampleCustomCheckbox" label="I agree to all Terms" />
										<Row className="mb-3 mt-3">
											<Col xs="12">
												<button type="submit" className="btn btn-block btn-primary" disabled={isSubmitting}>Sign Up</button>
											</Col>
										</Row>
										{status &&
	                                <div className={'alert alert-danger'}>{status}</div>
	                            }
										<div className="text-center text-light">
											Already have an account? <a href="/authentication/login" className="text-info ml-1"><b>Sign In</b></a>
										</div>
									</Form>
									)}
									/>
								) : (
									<Formik
															initialValues={{
																	signUpCode: ''
															}}
															validationSchema={Yup.object().shape({
																	signUpCode: Yup.string().required('Sign Up Code is required')
															})}
															onSubmit={({ signUpCode }, { setStatus, setSubmitting }) => {
																	setStatus();
																	console.log('submitted');

																	Auth.confirmSignUp(this.state.email, signUpCode)
															      .then((data) => {
																			console.log('confirmSignUp');
															        console.log(data);
																			setStatus(data);
																			this.setState({waitingForCode: false});
																			setSubmitting(false);
																			window.location.href="/authentication/login";
															      })
															      .catch((err) => {
																			console.log('confirmSignUp error: '+err);
																			setSubmitting(false);
																		});

															}}
															render={({ errors, status, touched, isSubmitting }) => (
									<Form className="mt-3" id="confirmForm">
										<InputGroup id="signUpCode" className="mb-3">
											<InputGroupAddon id="signUpCode" addonType="prepend">
											</InputGroupAddon>
											<Field id="signUpCode" name="signUpCode" placeholder="Sign Up Code" type="text" className={'form-control' + (errors.signUpCode && touched.signUpCode ? ' is-invalid' : '')} />
	                                		<ErrorMessage id="signUpCode" name="signUpCode" component="div" className="invalid-feedback" />
										</InputGroup>
										<Row className="mb-3 mt-3">
											<Col xs="6">
												<button type="submit" className="btn btn-block btn-primary" disabled={isSubmitting}>Confirm Sign Up</button>
											</Col>
											<Col xs="6">
												<button className="btn btn-block btn-primary" disabled={isSubmitting} onClick={() => this.resendCode()}>Resend code</button>
											</Col>
										</Row>
										{status &&
	                  	<div className={'alert alert-danger'}>{status}</div>
	                  }
									</Form>
									)}
									/>
								)}
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</div>;
	}
}

export default Register;
