import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class AbortedFirmwareManagementList extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		var connectionTimeout;

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			currentUser,
			rSelected: 3,
			totalProcedures: 0,
			modal: false,
      modal2: false,
			modal4: false,
			modal5: false,
			modal6: false,
      dropdownOpen: false,
			currentUser,
			testingFirmware: [],
			testingFirmwareUserItems: [],
      pendingFirmware: [],
			approvedFirmware: [],
			devices: [],
			devicesIcons: [],
      roles: [],
      obj: {},
			statusSubject: "Failed",
			statusMessage: "Failed to send message.",
			deviceInfo: [],
			userPhoneNumber: "",
			allowSMS: false,
			shipmentDate: Moment().format('YYYY-MM-DD'),
			userRole: parseInt(currentUser.payload["custom:fw_approver"]),
			selectedTestingStatus: 0,
			submitTestTitle: "Complete Testing",
			firmwareTestingComplete: false,
			firmwareApprovedMask: 0,
			firmwareApprovalUsers: []
	  };

		// this.toggle = this.toggle.bind(this);
		// this.toggle2 = this.toggle2.bind(this);
		// this.toggle3 = this.toggle3.bind(this);
		// this.toggle4 = this.toggle4.bind(this);
		// this.toggle5 = this.toggle5.bind(this);
		// this.toggle6 = this.toggle6.bind(this);
		// this.getData = this.getData.bind(this);


  }

	async componentDidMount() {

		console.log("userRole: "+this.state.userRole);

		this.getFirmwareData();
		//this.intervalID = setInterval(this.getFirmwareData, 3000);
	}

	componentWillUnmount() {
		clearInterval(this.intervalID);
	}

  getFirmwareData = async () => {

    var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-aborted-firmware-testing";

    console.log("firmware testing url: " + url);

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: this.state.currentUser.jwtToken
      },
    });

    const body = await response.json();
    console.log("firmware testing user list: " + JSON.stringify(body));

    if (body.status == 'Success') {
      //this.setState({ testingFirmwareUserItems: body.response });

      var allItems = body.response;
      var currentVersion = "";

      if (allItems.length>0) {
        currentVersion = allItems[0].Version;
        console.log("currentVersion: " + currentVersion);

        var firmwareVersionArray = [];
        var firmwareUserItems = [];

        for (var i=0; i<allItems.length; i++) {

          console.log("currentVersion: " + currentVersion);
          console.log("currentItem Version: " + allItems[i].Version);

          if (allItems[i].Version==currentVersion) {
            firmwareUserItems.push(allItems[i]);
          } else {
            var versionItem = {
              version: currentVersion,
              data: firmwareUserItems
            }

            console.log("versionItem: " + JSON.stringify(versionItem));
            firmwareVersionArray.push(versionItem);
            currentVersion = allItems[i].Version;
            console.log("new currentVersion: " + currentVersion);
            firmwareUserItems = [];
            firmwareUserItems.push(allItems[i]);
          }

        }

        var finalVersionItem = {
          version: currentVersion,
          data: firmwareUserItems
        }

        console.log("finalVersionItem: " + JSON.stringify(finalVersionItem));
        firmwareVersionArray.push(finalVersionItem);

        this.setState({ testingFirmwareUserItems: firmwareVersionArray });
        console.log("firmwareVersionArray: " + JSON.stringify(firmwareVersionArray));
      }

      //firmwareTestingComplete
    } else {
      //ADD ERROR MESSAGE

    }
  }

  render() {

    const { userRole } = this.state;

    const data = this.state.testingFirmwareUserItems.map((firmwareTest, key) => {

      console.log("data firmwareTest: " + JSON.stringify(firmwareTest));

      var isCurrectUser = true;

      if (firmwareTest.UserEmail == this.state.currentUser.payload.email) {
        isCurrectUser = true;
      }

      return {
        id: key,
        firmwareID: firmwareTest.ID,
        userName: firmwareTest.UserName,
        userEmail: firmwareTest.UserEmail,
        version: firmwareTest.Version,
        location: firmwareTest.Location,
        comments: firmwareTest.Comments,
        testingStatus: firmwareTest.TestingStatus,
        tableData: 0,
        actions: (
          // we've added some custom button actions
          <div className="text-center">
            {/* use this button to add a edit kind of action */}
            {/* use this button to remove the data row */}
            {isCurrectUser &&
              <Fragment>
              <Button
                className="ml-1 mr-1"
                size="sm"
                onClick={() => {
                  let obj = data.find(o => o.id === key);
                  console.log('button obj: ' + obj);
                  this.setState({
                    obj: obj,
                    modal4: !this.state.modal4,
                    selectedTestingStatus: 3,
                    submitTestTitle: "Complete Testing"
                  });
                  //this.takeAction(obj);
                }}
                color="brightgreen"
                round="true"
                icon="true"
              >
                Complete
              </Button>
              <Button
                className="ml-1 mr-1"
                size="sm"
                onClick={() => {
                  let obj = data.find(o => o.id === key);
                  console.log('button obj: ' + obj);
                  this.setState({
                    obj: obj,
                    modal4: !this.state.modal4,
                    selectedTestingStatus: 1,
                    submitTestTitle: "Skip Testing"
                  });
                  //this.takeAction(obj);
                }}
                color="white"
                round="true"
                icon="true"
              >
                Skip
              </Button>
              <Button
                className="ml-1 mr-1"
                size="sm"
                onClick={() => {
                  let obj = data.find(o => o.id === key);
                  console.log('button obj: ' + obj);
                  this.setState({
                    obj: obj,
                    modal4: !this.state.modal4,
                    selectedTestingStatus: 2,
                    submitTestTitle: "Reject Testing Version"
                  });
                  //this.takeAction(obj);
                }}
                color="red"
                round="true"
                icon="true"
              >
                Reject
              </Button>
              </Fragment>
            }
          </div>
        )
      };
    });

    const filterCaseInsensitive = (filter, row) => {
           const id = filter.pivotId || filter.id;
           if (row[id] !== null){
               return (
                   row[id] !== undefined ?
                       String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                       :
                       true
               );
           }
       };

    return (
      <Fragment>
      {this.state.testingFirmwareUserItems.map((versionItem, i) => (
        <Row>
          <Col md="12">
            <Card className="card-hover">
              <CardBody>
                <Row className="mb-3">
                  <Col md="6">
                    <h2 style={{color:'#737782'}}>Firmware Version {versionItem.version}</h2>
                  </Col>
                  <Col md="6">
                  </Col>
                </Row>
                  <ReactTable
                    style={{backgroundColor:'#ffc062', padding:'10px', color: '#000'}}
                    columns={[
                      {
                        Header: "User Name",
                        accessor: "UserName",
                        style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                        Cell: row => (
                            <div style={{ textAlign: "center" }}>{row.value}</div>
                          )
                      },
                      {
                        Header: "User Email",
                        accessor: "UserEmail",
                        style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                        Cell: row => (
                            <div style={{ textAlign: "center" }}>{row.value}</div>
                          )
                      },
                      {
                        Header: "Version",
                        accessor: "Version",
                        style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                        Cell: row => (
                            <div style={{ textAlign: "center" }}>{row.value}</div>
                          )
                      },
                      {
                        Header: "Location",
                        accessor: "Location",
                        style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
                        Cell: row => (
                            <div style={{ textAlign: "center" }}>{row.value}</div>
                          )
                      },
                      {
                        Header: "Comments",
                        accessor: "Comments",
                        style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center', 'whiteSpace': 'unset'},
                        Cell: row => (
                            <Fragment>
                            {row.value ? (
                              <Fragment>
                              {row.value.length > 45 ? (
                                <div style={{ textAlign: "justify", height: "42px", overflow: "auto" }}>{row.value}</div>
                              ) : (
                                <div style={{ textAlign: "center"}}>{row.value}</div>
                              )}
                              </Fragment>
                            ) : (
                              <div style={{ textAlign: "center" }}>No Comments</div>
                            )}
                            </Fragment>
                          )
                      }
                    ]}
                    defaultPageSize={4}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    data={versionItem.data}
                    id="table0"
                  />
              </CardBody>
            </Card>
          </Col>
        </Row>
      ))}
      </Fragment>
    );
  }
}

export default AbortedFirmwareManagementList;
