import React from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';

class Relays extends React.Component {

	noResults = true;

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 3,
			totalProcedures: 0,
	  };

		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
  }

	async componentDidMount() {
	 	//this.getData(this.state.rSelected);
	}

  onRadioBtnClick(rSelected) {
    this.setState({ rSelected });
		this.getData(rSelected);
  }

	getData = async queryInterval => {

		console.log("queryInterval: " + JSON.stringify(queryInterval));

		const response = await fetch('/api/totalprocedures', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ interval: queryInterval }),
		});
		const body = await response.json();
		console.log("totalprocedures: " + JSON.stringify(body));

		if (body.status != 400) {
			if (body.results.length > 0) {
				//let powerAvg = parseFloat(body.results[0].power, 10)/10;
				//let roundedPower = powerAvg.toFixed(1)
				this.setState({ totalProcedures: body.results[0].total }, () => console.log(this.state.totalProcedures));
				this.noResults = false;
			} else {
				this.noResults = true;
			}
			this.forceUpdate();
		}
	};

	render() {
		return (
      <Card className="card-hover">
				<CardBody style={{ minHeight: 193 }}>
					<div className="d-md-flex align-items-right">
						<div>
							<CardTitle>Relays</CardTitle>
						</div>
						<div className="ml-auto d-flex no-block align-items-center led-progress-label">
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="brightgreen" value="100"/>
							</Progress>
							<h6>Good</h6>
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="yellow" value="100"/>
							</Progress>
							<h6>Weak</h6>
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="poor" value="100"/>
							</Progress>
							<h6>Poor</h6>
						</div>
					</div>
					<Row className="mt-2">
						<Col xs="2" md="2" lg="2" className="mt-1">
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="brightgreen" value="100"/>
							</Progress>
						</Col>
						<Col xs="2" md="2" lg="2" className="mt-1">
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="yellow" value="100" />
							</Progress>
						</Col>
						<Col xs="2" md="2" lg="2" className="mt-1">
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="brightgreen" value="100" />
							</Progress>
						</Col>
						<Col xs="2" md="2" lg="2" className="mt-1">
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="brightgreen" value="100" />
							</Progress>
						</Col>
						<Col xs="2" md="2" lg="2" className="mt-1">
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="brightgreen" value="100" />
							</Progress>
						</Col>
						<Col xs="2" md="2" lg="2" className="mt-1">
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="poor" value="100" />
							</Progress>
						</Col>
					</Row>
					<Row>
						<Col xs="2" md="2" lg="2">
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="brightgreen" value="100"/>
							</Progress>
						</Col>
						<Col xs="2" md="2" lg="2">
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="yellow" value="100" />
							</Progress>
						</Col>
						<Col xs="2" md="2" lg="2">
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="brightgreen" value="100" />
							</Progress>
						</Col>
						<Col xs="2" md="2" lg="2">
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="brightgreen" value="100" />
							</Progress>
						</Col>
						<Col xs="2" md="2" lg="2">
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="brightgreen" value="100" />
							</Progress>
						</Col>
						<Col xs="2" md="2" lg="2">
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="poor" value="100" />
							</Progress>
						</Col>
					</Row>
					<Row>
						<Col xs="2" md="2" lg="2">
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="brightgreen" value="100"/>
							</Progress>
						</Col>
						<Col xs="2" md="2" lg="2">
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="yellow" value="100" />
							</Progress>
						</Col>
						<Col xs="2" md="2" lg="2">
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="brightgreen" value="100" />
							</Progress>
						</Col>
						<Col xs="2" md="2" lg="2">
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="brightgreen" value="100" />
							</Progress>
						</Col>
						<Col xs="2" md="2" lg="2">
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="brightgreen" value="100" />
							</Progress>
						</Col>
						<Col xs="2" md="2" lg="2">
							<Progress multi className="mt-2 mb-3 vertical">
								<Progress bar color="poor" value="100" />
							</Progress>
						</Col>
					</Row>
					<Row>
						<Col xs="2" md="2" lg="2" className="text-center led-progress-label text-nowrap">
							<h6>SWITCH</h6>
						</Col>
						<Col xs="2" md="2" lg="2" className="text-center led-progress-label text-nowrap">
							<h6>ON/OFF</h6>
						</Col>
						<Col xs="2" md="2" lg="2" className="text-center led-progress-label text-nowrap">
							<h6>THIS ONE</h6>
						</Col>
						<Col xs="2" md="2" lg="2" className="text-center led-progress-label text-nowrap">
							<h6>SWITCH</h6>
						</Col>
						<Col xs="2" md="2" lg="2" className="text-center led-progress-label text-nowrap">
							<h6>ON/OFF</h6>
						</Col>
						<Col xs="2" md="2" lg="2" className="text-center led-progress-label text-nowrap">
							<h6>THIS ONE</h6>
						</Col>
					</Row>
        </CardBody>
      </Card>
		);
	}
}

export default Relays;
