var Moment = require('moment');
var Crypto = require("crypto-js");
//import { authenticationService } from '../../jwt/_services';
//var SHA256 = require("crypto-js/sha256");

//const currentUser = authenticationService.currentUserValue;

//EXAMPLE -> wss://a1f3ynjyzalf3r-ats.iot.us-west-2.amazonaws.com/mqtt?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIOUUVVL3OA6B3G6Q%2F20200512%2Fus-west-2%2Fiotdevicegateway%2Faws4_request&X-Amz-Date=20200512T042102Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=bf1668f07eb99eca315a534d6c0ee3c683ff7d583c41baaea3361b1d0f8c821d
export function getConnectionURL() {
	var time = Moment.utc();
	var dateStamp = time.format('YYYYMMDD');
	console.log("dateStamp: "+dateStamp);
	var amzdate = dateStamp + 'T' + time.format('HHmmss') + 'Z';
	var service = 'iotdevicegateway';
	var region = "us-west-2";
	var secretKey = "XR0ZAFNUuixhuz4qXTE52HmMp9yZNhSbsv58OL1V";
	var accessKey = "AKIAIOUUVVL3OA6B3G6Q";
	var algorithm = 'AWS4-HMAC-SHA256';
	var method = 'GET';
	var canonicalUri = '/mqtt';
	var host = "a1f3ynjyzalf3r-ats.iot.us-west-2.amazonaws.com";

	var credentialScope = dateStamp + '/' + region + '/' + service + '/' + 'aws4_request';
	var canonicalQuerystring = 'X-Amz-Algorithm=AWS4-HMAC-SHA256';
	canonicalQuerystring += '&X-Amz-Credential=' + encodeURIComponent(accessKey + '/' + credentialScope);
	canonicalQuerystring += '&X-Amz-Date=' + amzdate;
	canonicalQuerystring += '&X-Amz-Expires=86400';
	canonicalQuerystring += '&X-Amz-SignedHeaders=host';

	var canonicalHeaders = 'host:' + host + '\n';
	var payloadHash = sha256('');
	var canonicalRequest = method + '\n' + canonicalUri + '\n' + canonicalQuerystring + '\n' + canonicalHeaders + '\nhost\n' + payloadHash;
	console.log('canonicalRequest ' + canonicalRequest);

	var stringToSign = algorithm + '\n' +  amzdate + '\n' +  credentialScope + '\n' +  sha256(canonicalRequest);
	var signingKey = getSignatureKey(secretKey, dateStamp, region, service);
	console.log('stringToSign-------');
	console.log(stringToSign);
	console.log('------------------');
	console.log('signingKey ' + signingKey);
	var signature = sign(signingKey, stringToSign);

	canonicalQuerystring += '&X-Amz-Signature=' + signature;
	var requestUrl = 'wss://' + host + canonicalUri + '?' + canonicalQuerystring;
	console.log("requestUrl: " + requestUrl);

	return requestUrl;
}

function getSignatureKey(key, dateStamp, regionName, serviceName) {
	var kDate = Crypto.HmacSHA256(dateStamp, 'AWS4' + key);
	var kRegion = Crypto.HmacSHA256(regionName, kDate);
	var kService = Crypto.HmacSHA256(serviceName, kRegion);
	var kSigning = Crypto.HmacSHA256('aws4_request', kService);
	return kSigning;
}

function sign(key, msg){
	var hash = Crypto.HmacSHA256(msg, key);
	return hash.toString(Crypto.enc.Hex);
}

function sha256(msg) {
	var hash = Crypto.SHA256(msg);
	return hash.toString(Crypto.enc.Hex);
}
