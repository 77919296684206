export function getEmailTextMessageOne(repairInfo) {
   var emailTextString = `Dear Gemini EVO User,
We have observed that your Gemini EVO has encountered difficulties in updating to our latest software release. Several factors can contribute to this update failure. Here are the most common issues associated with update failures:
1. Local Network Strength: It is crucial to ensure that your internet service provider provides a minimum download speed of 10Mbps.
2. Wi-Fi Signal Strength: If the Wi-Fi signal between your Gemini EVO and your local network is weak, we recommend relocating your Gemini EVO closer to your local router to enhance connectivity.
3. Stringent Firewall Settings: In the event that your local network operates behind a highly secure firewall, consider connecting your Gemini EVO to an alternative network.
We sincerely apologize for any inconvenience this situation may have caused. We are committed to assisting you in resolving this issue. If any of the suggested measures above do not resolve the problem, please do not hesitate to send us a message, and we will work collaboratively to find a solution.
Thank you for selecting Gemini EVO.
Team Gemini EVO`;

    return emailTextString;
}

export function getEmailHTMLMessageOne() {

  var emailHTMLString = `<!DOCTYPE html>
<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
   <head>
      <title></title>
   </head>
   <body style="background-color: #ffffff; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
     <table width="100%">
       <tr>
          <td style="padding: 5px; text-align: left;">
            Dear Gemini EVO User,
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            We have observed that your Gemini EVO has encountered difficulties in updating to our latest software release. Several factors can contribute to this update failure. Here are the most common issues associated with update failures:
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            1. Local Network Strength: It is crucial to ensure that your internet service provider provides a minimum download speed of 10Mbps.
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            2. Wi-Fi Signal Strength: If the Wi-Fi signal between your Gemini EVO and your local network is weak, we recommend relocating your Gemini EVO closer to your local router to enhance connectivity.
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            3. Stringent Firewall Settings: In the event that your local network operates behind a highly secure firewall, consider connecting your Gemini EVO to an alternative network.          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            We sincerely apologize for any inconvenience this situation may have caused. We are committed to assisting you in resolving this issue. If any of the suggested measures above do not resolve the problem, please do not hesitate to send us a message, and we will work collaboratively to find a solution.
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Thank you for selecting Gemini EVO.
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Team Gemini EVO
          </td>
       </tr>
     </table>
   </body>
</html>`;

	return emailHTMLString;
}

export function getEmailTextMessageTwo(repairInfo) {
   var emailTextString = `Dear Gemini EVO user,
We have observed that your Gemini EVO has encountered difficulties in updating to our latest software release. This can occur when the Gemini EVO battery has an indication of 2 bars or less.
To resolve this issue, simply connect your Gemini EVO power supply for approximately 5 minutes and then turn your unit ON. The software update will resume automatically as long as you are connected to a Wi-Fi network.
We apologize for any inconvenience and appreciate your support.
Thank you for choosing Gemini EVO.
Team Gemini EVO`;

    return emailTextString;
}

export function getEmailHTMLMessageTwo() {

  var emailHTMLString = `<!DOCTYPE html>
<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
   <head>
      <title></title>
   </head>
   <body style="background-color: #ffffff; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
     <table width="100%">
       <tr>
          <td style="padding: 5px; text-align: left;">
            Dear Gemini EVO User,
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            We have observed that your Gemini EVO has encountered difficulties in updating to our latest software release. This can occur when the Gemini EVO battery has an indication of 2 bars or less.
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            To resolve this issue, simply connect your Gemini EVO power supply for approximately 5 minutes and then turn your unit ON. The software update will resume automatically as long as you are connected to a Wi-Fi network.
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            We apologize for any inconvenience and appreciate your support.
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Thank you for choosing Gemini EVO.
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Team Gemini EVO
          </td>
       </tr>
     </table>
   </body>
</html>`;

	return emailHTMLString;
}

export function getEmailTextMessageThree(repairInfo) {
   var emailTextString = `Dear Gemini EVO User,
We have identified that your Gemini EVO's activation pedal may have become unpaired from your Gemini EVO unit. We want to assure you that the process to resolve this issue is straightforward. Below, you will find links to comprehensive video guides that offer detailed walkthroughs for re-establishing the connection for your activation pedal.
If your Gemini Evo is powered by 2 AA batteries:
https://www.youtube.com/watch?v=wp5nrEFBYjU&t=10s
If your Gemini Evo is powered by a single large battery:
https://www.youtube.com/watch?v=ax9q1XCXlvY
Should you require further clarification or find yourself in need of additional assistance, please do not hesitate to contact us. Our team is dedicated to providing the support you need.
Thank you for choosing Gemini EVO.
Sincerely,
Team Gemini EVO
Team@GeminiEVO.com`;

    return emailTextString;
}

export function getEmailHTMLMessageThree() {

  var emailHTMLString = `<!DOCTYPE html>
<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
   <head>
      <title></title>
   </head>
   <body style="background-color: #ffffff; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
     <table width="100%">
       <tr>
          <td style="padding: 5px; text-align: left;">
            Dear Gemini EVO User,
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            We have identified that your Gemini EVO's activation pedal may have become unpaired from your Gemini EVO unit. We want to assure you that the process to resolve this issue is straightforward. Below, you will find links to comprehensive video guides that offer detailed walkthroughs for re-establishing the connection for your activation pedal.
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            If your Gemini Evo is powered by 2 AA batteries <a href="https://www.youtube.com/watch?v=wp5nrEFBYjU&t=10s">Click Here</a>
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            If your Gemini Evo is powered by a single large battery <a href="https://www.youtube.com/watch?v=ax9q1XCXlvY">Click Here</a>
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Should you require further clarification or find yourself in need of additional assistance, please do not hesitate to contact us. Our team is dedicated to providing the support you need.
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Thank you for selecting Gemini EVO.
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Team Gemini EVO
          </td>
       </tr>
       <tr>
          <td style="padding: 5px; text-align: left;">
            Team@GeminiEVO.com
          </td>
       </tr>
     </table>
   </body>
</html>`;

	return emailHTMLString;
}
