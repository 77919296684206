import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import brokenpin from '../../../assets/images/pins/broken_pin.png';
import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';
import '../../../assets/calculator/css/calculator.css';

var doseRange = 0;
var viewCalc1 = 0;
var viewCalc2 = 0;

var adapterValue, objectiveValue, painlevelValue, colorValue;
var painlevelValue = 0;

class Calculator2View extends React.Component {

	constructor(props) {
    super(props);

    this.state = {
			noDevices: false,
			devicesLoaded: false,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			deviceInfo: [],
      timeOutput: 50,
      timeOutput1: 50
	  };

    this.calculateTimeDose = this.calculateTimeDose.bind(this);
    this.colorLevels = this.colorLevels.bind(this);
    this.painLevels = this.painLevels.bind(this);
    this.calculateDose = this.calculateDose.bind(this);
    this.calculateTime = this.calculateTime.bind(this);

  }

	async componentDidMount() {

    //document.getElementById("objective").style.display = "none";
    //document.getElementById("painlevel").style.display = "none";
    //document.getElementById("tissuecolor").style.display = "none";
    //document.getElementById("doserange1").style.display = "none";
    //document.getElementById("adaptergeneral").style.display = "none";
    //document.getElementById("timeslider").style.display = "none";
    //document.getElementById("doserange2").style.opacity = "0";
    //document.getElementById("adaptergeneral1").style.display = "none";
    //document.getElementById("timeslider1").style.display = "none";
    //document.getElementById("timerange2").style.opacity = "0";

    //this.getData();
		//setInterval(this.getData, 4000);

		var input = document.getElementById("1");
		input.setAttribute("checked", "checked");
	}

  adapterSelection() {
    console.log("adapterSelection selected");
    var adapter = document.getElementsByName("selectedadapter");
    for (var i = 0; i < adapter.length; i++) {
      if (adapter[i].checked) {
        adapterValue = adapter[i].value;
        var checkingElements = document.querySelectorAll(".customradio");
        checkingElements[i].classList.add("selected");
        document.getElementById("objective").style.display = "inherit";
      } else {
        var checkingElements = document.querySelectorAll(".customradio");
        checkingElements[i].classList.remove("selected");
      }
    }

    console.log("adapterSelection adapterValue: "+adapterValue);
    return adapterValue;
  }

  objectiveSelection() {
    var objective = document.getElementsByName("selectedobjective");
    for (var i = 0; i < objective.length; i++) {
      if (objective[i].checked) {
        objectiveValue = objective[i].value;
        var checkingElements = document.querySelectorAll(".customradio3");
        checkingElements[i].classList.add("selected");
        if (objectiveValue == "relief") {
          document.getElementById("painlevel").style.display = "inherit";
        } else if (objectiveValue == "healing") {
          document.getElementById("tissuecolor").style.display = "inherit";
          document.getElementById("painlevel").style.display = "none";
        }
      } else {
        var checkingElements = document.querySelectorAll(".customradio3");
        checkingElements[i].classList.remove("selected");
      }
    }

    return objectiveValue;
  }

  painSelection() {
    var painlevel = document.getElementsByName("selectedpain");
    for (var i = 0; i < painlevel.length; i++) {
      if (painlevel[i].checked) {
        painlevelValue = painlevel[i].value;
        var checkingElements = document.querySelectorAll(".customradio4");
        checkingElements[i].classList.add("selected");
        document.getElementById("tissuecolor").style.display = "inherit";
      } else {
        var checkingElements = document.querySelectorAll(".customradio4");
        checkingElements[i].classList.remove("selected");
      }
    }

    return painlevelValue;
  }

  tissueSelection() {
    var color = document.getElementsByName("selectedcolor");
    for (var i = 0; i < color.length; i++) {
      if (color[i].checked) {
        colorValue = color[i].value;
        var checkingElements = document.querySelectorAll(".customradio5");
        checkingElements[i].classList.add("selected");
      } else {
        var checkingElements = document.querySelectorAll(".customradio5");
        checkingElements[i].classList.remove("selected");
      }
    }

    return colorValue;
  }

  calculateTimeDose() {
    var adapterValue = this.adapterSelection();
    var objectiveValue = this.objectiveSelection();
    var painlevelValue = this.painSelection();
    var colorValue = this.tissueSelection();

    if (adapterValue == "25mm") {
      document.getElementById("treatementinstructions").innerHTML =
        "Hold the PBM Adapter at a fixed spot with PBM spacer touching target tissue until timer is complete.";
      var density = 0.204;
      if (objectiveValue == "healing") {
        var initialVal = 30;
        var increment = -2;
        this.colorLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && painlevelValue == 0) {
        document.getElementById("calculatedtime1").innerHTML = "/";
        document.getElementById("calculateddose1").innerHTML = "/";
      }
      if (objectiveValue == "relief" && colorValue == "i") {
        var initialVal = 55;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "ii") {
        var initialVal = 50;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "iii") {
        var initialVal = 45;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "iv") {
        var initialVal = 40;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "v") {
        var initialVal = 35;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "vi") {
        var initialVal = 30;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
    } else if (adapterValue == "7mm") {
      document.getElementById("treatementinstructions").innerHTML =
        "Hold the PBM Adapter in a fixed spot either in contact or 1-2mm away from target area until timer is complete.";
      var density = 0.789;
      if (objectiveValue == "healing") {
        var initialVal = 25;
        var increment = -2;
        this.colorLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && painlevelValue == 0) {
        document.getElementById("calculatedtime1").innerHTML = "/";
        document.getElementById("calculateddose1").innerHTML = "/";
      }
      if (objectiveValue == "relief" && colorValue == "i") {
        var initialVal = 48;
        var increment = 2;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "ii") {
        var initialVal = 44;
        var increment = 2;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "iii") {
        var initialVal = 42;
        var increment = 2;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "iv") {
        var initialVal = 38;
        var increment = 2;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "v") {
        var initialVal = 34;
        var increment = 2;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "vi") {
        var initialVal = 30;
        var increment = 2;
        this.painLevels(colorValue, density, initialVal, increment);
      }
    } else if (adapterValue == "3mm") {
      document.getElementById("treatementinstructions").innerHTML =
        "Scan the desired treatment spot while staying 2-3mm from target tissue. The treatment spot should be no larger than 15mm in diameter.";
      var density = 4.286;
      if (objectiveValue == "healing") {
        var initialVal = 20;
        var increment = -2;
        this.colorLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && painlevelValue == 0) {
        document.getElementById("calculatedtime1").innerHTML = "/";
        document.getElementById("calculateddose1").innerHTML = "/";
      }
      if (objectiveValue == "relief" && colorValue == "i") {
        var initialVal = 38;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "ii") {
        var initialVal = 34;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "iii") {
        var initialVal = 32;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "iv") {
        var initialVal = 28;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "v") {
        var initialVal = 24;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "vi") {
        var initialVal = 20;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
    }
  }

  colorLevels(colorValue, density, initialVal, increment) {
    if (colorValue == "i") {
      this.printValues(density, initialVal, increment, 0);
    } else if (colorValue == "ii") {
      this.printValues(density, initialVal, increment, 1);
    } else if (colorValue == "iii") {
      this.printValues(density, initialVal, increment, 2);
    } else if (colorValue == "iv") {
      this.printValues(density, initialVal, increment, 3);
    } else if (colorValue == "v") {
      this.printValues(density, initialVal, increment, 4);
    } else if (colorValue == "vi") {
      this.printValues(density, initialVal, increment, 5);
    }
  }

  painLevels(colorValue, density, initialVal, increment) {
    if (painlevelValue == "1") {
      this.printValues(density, initialVal, increment, 0);
    } else if (painlevelValue == "2") {
      this.printValues(density, initialVal, increment, 1);
    } else if (painlevelValue == "3") {
      this.printValues(density, initialVal, increment, 2);
    } else if (painlevelValue == "4") {
      this.printValues(density, initialVal, increment, 3);
    } else if (painlevelValue == "5") {
      this.printValues(density, initialVal, increment, 4);
    }
  }

  printValues(density, initialVal, increment, row) {
    var calculatedVal = initialVal + increment * row;
    document.getElementById("calculatedtime1").innerHTML =
      calculatedVal + " seconds";
    document.getElementById("calculateddose1").innerHTML = Math.round(
      calculatedVal * density
    );
    if (density == 4.286) {
      document.getElementById("calculateddose1").innerHTML = "N/A";
    }
  }

  showDoseRange() {
    if (doseRange == 0) {
      document.getElementById("doserange1").style.display = "inherit";
      document.getElementById("viewDose").innerHTML = "HIDE DOSE";
      doseRange = 1;
    } else {
      document.getElementById("doserange1").style.display = "none";
      document.getElementById("viewDose").innerHTML = "SHOW DOSE";
      doseRange = 0;
    }
  }

  showCalc1() {
    if (viewCalc1 == 0) {
      document.getElementById("adaptergeneral").style.display = "inherit";
      document.getElementById("timeslider").style.display = "inherit";
      document.getElementById("doserange2").style.opacity = "1";
      document.getElementById("calcinfo").style.display = "none";
      document.getElementById("viewCalc1").innerHTML = "HIDE CALCULATOR";
      viewCalc1 = 1;
    } else {
      document.getElementById("adaptergeneral").style.display = "none";
      document.getElementById("timeslider").style.display = "none";
      document.getElementById("doserange2").style.opacity = "0";
      document.getElementById("calcinfo").style.display = "block";
      document.getElementById("viewCalc1").innerHTML = "USE CALCULATOR";
      viewCalc1 = 0;
    }
  }

  showCalc2() {
    if (viewCalc2 == 0) {
      document.getElementById("adaptergeneral1").style.display = "inherit";
      document.getElementById("timeslider1").style.display = "inherit";
      document.getElementById("timerange2").style.opacity = "1";
      document.getElementById("calcinfo1").style.display = "none";
      document.getElementById("viewCalc2").innerHTML = "HIDE CALCULATOR";
      viewCalc2 = 1;
    } else {
      document.getElementById("adaptergeneral1").style.display = "none";
      document.getElementById("timeslider1").style.display = "none";
      document.getElementById("timerange2").style.opacity = "0";
      document.getElementById("calcinfo1").style.display = "block";
      document.getElementById("viewCalc2").innerHTML = "USE CALCULATOR";
      viewCalc2 = 0;
    }
  }

  resetCalculator() {
    //location.reload();
    return false;
  }

  calculateDose() {
    var adaptergeneral = document.getElementsByName("selectedadaptergeneral");
    var timegeneral = document.getElementById("myRange");
    var adaptergeneralValue;

    this.setState({ timeOutput: timegeneral.value});

    for (var i = 0; i < adaptergeneral.length; i++) {
      if (adaptergeneral[i].checked) {
        adaptergeneralValue = adaptergeneral[i].value;
        var checkingElements = document.querySelectorAll(".customradio1");
        checkingElements[i].classList.add("selected");

				// if (adaptergeneralValue == "3mm") {
				// 	var input = document.getElementById("1");
				// 	input.setAttribute("checked", "checked");
				// } else if (adaptergeneralValue == "7mm") {
				// 	var input = document.getElementById("2");
				// 	input.setAttribute("checked", "checked");
				// } else if (adaptergeneralValue == "25mm") {
				// 	var input = document.getElementById("3");
				// 	input.setAttribute("checked", "checked");
				// }

      } else {
        var checkingElements = document.querySelectorAll(".customradio1");
        checkingElements[i].classList.remove("selected");

				// if (adaptergeneralValue == "3mm") {
				// 	var input = document.getElementById("1");
				// 	input.removeAttribute("checked");
				// } else if (adaptergeneralValue == "7mm") {
				// 	var input = document.getElementById("2");
				// 	input.removeAttribute("checked");
				// } else if (adaptergeneralValue == "25mm") {
				// 	var input = document.getElementById("3");
				// 	input.removeAttribute("checked");
				// }

      }
    }

    var timegeneralValue = timegeneral.value;

    if (adaptergeneralValue == "25mm") {
      document.getElementById("calculateddose4").innerHTML = Math.round(
        (1 * timegeneralValue) / 4.91
      ) + " J/cm2";
    } else if (adaptergeneralValue == "7mm") {
      document.getElementById("calculateddose4").innerHTML = Math.round(
        (0.3 * timegeneralValue) / 0.38
      ) + " J/cm2";
    } else if (adaptergeneralValue == "3mm") {
      document.getElementById("calculateddose4").innerHTML = Math.round(
        (0.3 * timegeneralValue) / 0.07
      ) + " J/cm2";
    }

    this.changeFillWidth();
  }

  changeFillWidth() {
    var timegeneral = document.getElementById("myRange");
    var fill = document.getElementById("fill");

    var timegeneralValue = timegeneral.value;

    if (timegeneralValue == 99) {
      fill.style.width = "100%";
    } else {
      fill.style.width = timegeneralValue + "%";
    }
  }

  calculateTime() {
    var adaptergeneral1 = document.getElementsByName("selectedadaptergeneral1");
    var dosegeneral = document.getElementById("myRange1");
    var adaptergeneralValue1;

    this.setState({ timeOutput1: dosegeneral.value});

    for (var i = 0; i < adaptergeneral1.length; i++) {
      if (adaptergeneral1[i].checked) {
        adaptergeneralValue1 = adaptergeneral1[i].value;
        var checkingElements = document.querySelectorAll(".customradio2");
        checkingElements[i].classList.add("selected");
      } else {
        var checkingElements = document.querySelectorAll(".customradio2");
        checkingElements[i].classList.remove("selected");
      }
    }

    var dosegeneralValue = dosegeneral.value;

    if (adaptergeneralValue1 == "25mm") {
      document.getElementById("calculatedtime2").innerHTML = Math.round(
        dosegeneralValue / 0.204
      );
    } else if (adaptergeneralValue1 == "7mm") {
      document.getElementById("calculatedtime2").innerHTML = Math.round(
        dosegeneralValue / 0.789
      );
    } else if (adaptergeneralValue1 == "3mm") {
      document.getElementById("calculatedtime2").innerHTML = Math.round(
        dosegeneralValue / 4.286
      );
    }

    this.changeFillWidth1();
  }

  changeFillWidth1() {
    var dosegeneral = document.getElementById("myRange1");
    var fill1 = document.getElementById("fill1");

    var dosegeneralValue = dosegeneral.value;

    if (dosegeneralValue == 99) {
      fill1.style.width = "100%";
    } else {
      fill1.style.width = dosegeneralValue + "%";
    }
  }

  test() {
    console.log("Testing Worked");
  }

  getData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"all-devices";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all devices: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ devices: body.results });
		} else {
      //ADD ERROR MESSAGE
    }

		// for ( var i = 0; i < this.state.devices.length; i++ ) {
    //
		// 	if (!this.state.devices[i].geo_timestamp || !this.state.devices[i].WifiProvisioned) {
		// 		this.state.devicesIcons.push( {
		// 			serial_number: this.state.devices[i].SerialNumber,
	  //       current_icon: brokenpin
		// 		});
		// 	} else {
		// 		this.state.devicesIcons.push( {
		// 			serial_number: this.state.devices[i].SerialNumber,
	  //       current_icon: redpin
		// 		});
		// 	}
    //
		// }
    //
    // this.setState({ devicesLoaded: true });
	};

	render() {

		return (
      <Card className="card-hover">
				<CardBody>
          <Fragment>
          <div id="calculator">
            <h1 style={{ fontSize: '3em' }}>Treatment Dosage in Seconds</h1>
            <p className="mt-4">Use this PBM calculator to determine an appropriate treatment time based on the PBM Adapter used and desired treatment objective. This is directional guidance from the manufacturer and should not be interpreted as clinical advice by any means, nor should this calculator replace the necessary training required of operators to properly use the Gemini EVO for PBM applications.</p>

						{
							//<p id="viewCalc1" onClick={this.showCalc1}>USE CALCULATOR</p>
						}

            <div id="generalcalc" className="mt-4" style={{width: "100%"}}>
              <div id="adaptergeneral">
                <label >Select Adapter</label><br/>
                <label className='radiolabel'><span className="customradio1 selected"></span><input type="radio"  id="1" name="selectedadaptergeneral" value="3mm" onClick={this.calculateDose}/>3mm</label>
                <label className='radiolabel'><span className="customradio1"></span><input type="radio"  id="2" name="selectedadaptergeneral" value="7mm" onClick={this.calculateDose}/>7mm</label>
                <label className='radiolabel'><span className="customradio1"></span><input type="radio"  id="3" name="selectedadaptergeneral" value="25mm" onClick={this.calculateDose}/>25mm</label>
                <hr/>
              </div>
              <div id="timeslider">
                <label >Select Treatment Time <span>(s)</span></label><br/>
                <input type="range" min="1" max="99" value={this.state.timeOutput} className="slider" id="myRange" onChange={this.changeFillWidth, this.calculateDose} />
                <span id="custom-slider"><span id="fill"></span></span><br/>
                <hr/>
              </div>

							<div style={{ flexDirection: 'row', width: '40%' }}>
								<Row style={{ width: '100px', margin: '5px'}}>
									<output name="timeOutputName" id="calculateddose3">{this.state.timeOutput} seconds </output>
								</Row>
								<Row style={{ width: '100px', margin: '5px', marginRight: '10px'}}>
									<div id="calculatedequal1"><h1> = </h1></div>
								</Row>
								<Row style={{ width: '135px', margin: '5px'}}>
									<div id="calculateddose4">214 J/cm2 </div>
								</Row>
							</div>

							{
								// <div id="doserange2">
	              //   <label>Dose <span>(J/cm2)</span></label><br/>
	              //   <div id="calculateddose2">214</div>
	              // </div>
							}

							{
								//<p id="calcinfo">Use this PBM dose calculator to determine dosage based on PBM Adapter used and desired treatment time.</p>
							}
            </div>

            <p id="bottomtext">Note: All calculations are made in accordance to the factory preset average power settings for each PBM Adapter.</p>

          </div>
          </Fragment>
        </CardBody>
      </Card>
		);
	}
}

export default Calculator2View;
