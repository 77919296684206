import React from "react";
import { Component, Fragment, PureComponent } from "react";
import { Auth } from "aws-amplify";
import { ToastContainer, toast } from 'react-toastify';

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	CustomInput,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Fade,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { authenticationService } from '../../../jwt/_services';
import Chart from 'react-c3-component';
import 'c3/c3.css';

class DeleteAccount extends React.Component {

	constructor(props) {
    super(props);

		//const currentUser = authenticationService.currentUserValue;

    this.state = {
			rSelected: 3,
			totalProcedures: 0,
			currentUserEmail: JSON.parse(localStorage.getItem('currentUserEmail')),
			isEditing: false,
			firstName: JSON.parse(localStorage.getItem('firstName')),
			lastName: JSON.parse(localStorage.getItem('lastName')),
			phoneNumber: '',
			status: 1,
			modal: false,
      modal2: false,
			code: 123456,
			subscriptionContactExists: false,
			isSubscribed: false,
			currentUser: this.props.currentUser,
			allowPhoneContact: false,
			isContactArchived: false
	  };

		this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);

  }

	async componentDidMount() {

		console.log("currentUser: "+JSON.stringify(this.props.currentUser));
		console.log("status: "+JSON.stringify(this.state.status));

	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

  toggle2() {
	  this.setState({
      modal: false,
	    modal2: !this.state.modal2
	  });
	}


	// sendAccountDeletionRequest = async () => {
	//
	// 	var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-account-deletion-request";
	//
	// 	const response = await fetch(url, {
	// 		method: 'GET',
	// 		headers: {
	// 			Authorization: this.state.currentUser.jwtToken
	// 		},
	// 	});
	//
	// 	const body = await response.json();
	// 	console.log("sendAccountDeletionRequest data: " + JSON.stringify(body));
	//
	// 	if (body.status == 'Success') {
	// 		//this.setState({ feedbackData: body.response });
	// 	} else {
  //     //ADD ERROR MESSAGE
  //   }
	//
	// };

	sendAccountDeletionRequest = async () => {

    // toast.success("Account deletion complete, please log out to create a new account.", {
    //   position: "top-center",
    //   autoClose: 3000,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "dark",
    //   closeButton: false
    // });

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-account-deletion-request";

		try {

			//console.log("sendAccountDeletionRequest this.props.currentUser.jwtToken: " + JSON.stringify(this.props.currentUser.jwtToken));

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("sendAccountDeletionRequest response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				//this.toggle4();
				toast.success("Account deletion complete, please log out to create a new account.", {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					closeButton: false
				});

			} else {

				//this.toggle4();
				toast.error(body.response, {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					closeButton: false
				});

			}

		}
		catch (err) {
			console.log("sendAccountDeletionRequest Fetch Error: " + err);
		}

		this.toggle2();

	};

	render() {
		return (
      <Card className="card-hover">
				<CardBody style={{ height: 1000 }}>
          <Fragment>
          <Fade in="true">
          {
            <Row className="pt-5">
            	<Col xs="12" md="12" lg="12">
            		<Button style={{ width: '100%' }} color="danger" size="sm" onClick={()=>{ this.toggle()} }>DELETE ACCOUNT</Button>
            	</Col>
            </Row>
          }
          </Fade>
          </Fragment>
        </CardBody>
        <ToastContainer/>
				<div>
				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>Confirm</ModalHeader>
					<ModalBody>
						<span>You are about to delete your Gemini EVO account, all related usage data will be permanently lost. Are you sure?</span>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" className="ml-1" onClick={this.toggle}>Cancel</Button>
						<Button color="primary" className="ml-1" onClick={this.toggle2}>CONFIRM DELETE</Button>
					</ModalFooter>
				</Modal>
        <Modal isOpen={this.state.modal2} toggle={this.toggle2}>
					<ModalHeader toggle={this.toggle2}>Confirm</ModalHeader>
					<ModalBody>
						<span>Are you REALLY sure?</span>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" className="ml-1" onClick={this.toggle2}>Cancel</Button>
						<Button color="primary" className="ml-1" onClick={this.sendAccountDeletionRequest}>CONFIRM DELETE</Button>
					</ModalFooter>
				</Modal>
				</div>
      </Card>
		);
	}
}

export default DeleteAccount;
