import React from "react";
import { Component, Fragment, PureComponent } from "react";
import { Auth } from "aws-amplify";
import DropIn from "braintree-web-drop-in-react";
import Moment from 'moment';
import Loader from 'react-loader-spinner'

import {
	Card,
	CardBody,
	CardTitle,
  CardFooter,
	Col,
	Row,
	Progress,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	CustomInput,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Fade
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { authenticationService } from '../../../jwt/_services';
import Chart from 'react-c3-component';
import 'c3/c3.css';

class UpgradeUser extends React.Component {

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 3,
			totalProcedures: 0,
			currentUserEmail: JSON.parse(localStorage.getItem('currentUserEmail')),
			isEditing: false,
			firstName: JSON.parse(localStorage.getItem('firstName')),
			lastName: JSON.parse(localStorage.getItem('lastName')),
			phoneNumber: '',
			status: 1,
			code: 123456,
      upgraded: false,
      purchasePending: false,
      purchaseCompleted: false,
      clientToken: null
	  };

    this.moveToStep1 = this.moveToStep1.bind(this);
    this.moveToStep2 = this.moveToStep2.bind(this);
    this.moveToStep3 = this.moveToStep3.bind(this);

  }

	async componentDidMount() {
		console.log("currentUser: "+JSON.stringify(this.props.currentUser));

    var upgradeLevel = JSON.parse(localStorage.getItem('upgradeLevel'));
    console.log("upgradeLevel account: "+JSON.stringify(upgradeLevel));

		if (upgradeLevel) {
			this.setState({upgraded: true});
		} else {
      var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"braintree-token";

  		const response = await fetch(url, {
  			method: 'GET',
  			headers: {
  				Authorization: this.props.currentUser.jwtToken
  			},
  		});

  		//const response = await response.json();

  		//const response = await fetch("https://www.geminilaser.com/g2/braintree/get_token.php");
      const body = await response.json();
  		console.log("MY token: "+body.response);
  		console.log("Current User: "+JSON.stringify(this.props.currentUser));

  		if (body.status == 'Success') {
  			this.setState({
  	      clientToken: body.response
  	    });
  		}
    }
	}

  moveToStep1() {
    this.setState({
      status: 1
    });
  }

  moveToStep2() {
    this.setState({
      status: 2
    });
  }

  moveToStep3() {

    var test = {
      OBJID: 1,
      LoginID: "rstickel@azenamedical.com",
      Level2Confirmation: "GHK789IUG"
    };

    localStorage.setItem('upgradeLevel', JSON.stringify(test));

    window.location.reload(true);
  }

  async buy() {
    // Send the nonce to your server
    const { nonce } = await this.instance.requestPaymentMethod();

		this.setState({purchasePending: true});

    var paymentAmount = "500.00";

		authenticationService.purchaseUpgrade(
			nonce,
			paymentAmount)
		.then(
				upgradeResponse => {

						let status = upgradeResponse.status;
						console.log('status: ' + status);
						console.log('upgradeResponse: ' + JSON.stringify(upgradeResponse));

						if (status === 'Success') {
								console.log('upgrade response info: ' + JSON.stringify(upgradeResponse.response));
								this.setState({purchasePending: false});
								this.setState({purchaseCompleted: true});
								console.log('purchaseCompleted: true');

                localStorage.setItem('upgradeLevel', JSON.stringify(upgradeResponse.response[0]));
                window.location.reload(true);

						} else if (status === 'Error') {
								console.log('error info: ' + upgradeResponse.response);
						} else {
							console.log('status info: ' + status);
						}
				},
				error => {
						console.log('status info: ' + error);
				}
		);

  }

	async sendCode() {
		var code = Math.floor(100000 + Math.random() * 900000);
		console.log("random code: "+code);

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"verify-change?ConfirmationCode="+code;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.props.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("Device Registered User response: " + JSON.stringify(body.response));

		if (body.status == 'Success') {
			this.setState({code: code});
		}
	}

	async updateUser(firstname, lastname, phoneNumber) {
		const user = await Auth.currentAuthenticatedUser();
		await Auth.updateUserAttributes(user, {
			'given_name': firstname,
			'family_name': lastname,
			'custom:phone': phoneNumber
		});
	}

	setVerifyStatus(status) {
		console.log("set status!");

		if (status == 2) {
			this.sendCode();
		}

		this.setState({status: status});
	}

	render() {
		return (
      <Card className="card-hover">
        {this.state.upgraded ? (
          <Fragment>
            <CardBody style={{ height: 400 }}>
            <Row>
              <Col xs="6" md="6" lg="6" className="text-left">
                <CardTitle>Premium User</CardTitle>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="12" lg="12" className="text-left">
                <span>User has access to all features and functionality.</span>
              </Col>
            </Row>
            </CardBody>
          </Fragment>
        ) : (
          <Fragment>
          {this.state.status == 1 &&
            <Fragment>
            <CardBody style={{ height: 400 }}>
            <Fade in="true">
            <Row>
              <Col xs="6" md="6" lg="6" className="text-left">
                <CardTitle>Upgrade User</CardTitle>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col xs="1" md="1" lg="1">
              </Col>
              <Col xs="10" md="10" lg="10">
                <div className="">
                  <Button
                    size="md"
                    style={{width: '100%'}}
                    color="brightgreen"
                    onClick={() => {this.moveToStep2()}}
                  >
                    Purchase Premium User Upgrade
                  </Button>
                </div>
              </Col>
              <Col xs="1" md="1" lg="1">
              </Col>
            </Row>
            </Fade>
            </CardBody>
            </Fragment>
          }
          {this.state.status == 2 &&
            <Fragment>
            <CardBody>
            <Fade in="true">
            <Row>
              <Col xs="6" md="6" lg="6" className="text-left">
                <CardTitle>Purchase Upgrade</CardTitle>
              </Col>
            </Row>
            <Row>
              <Col xs="3" md="3" lg="3" className="text-left">
                <span>Upgrade</span>
              </Col>
              <Col xs="9" md="9" lg="9" className="text-right">
                <span>Adds additional features and functionality.</span>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs="3" md="3" lg="3" className="text-left">
                <span>Price</span>
              </Col>
              <Col xs="9" md="9" lg="9" className="text-right">
                <span>US $500.00</span>
              </Col>
            </Row>
            {this.state.purchasePending &&
              <Fade in="true">
              <Row className="justify-content-center">
                <Loader type="ThreeDots" color="#48b203" height={100} width={100}/>
              </Row>
              </Fade>
            }
            {!this.state.purchasePending &&
              <div>
              <Fade in="true">
                <DropIn
                   style={{ maxHeight: 300 }}
                  options={{ authorization: this.state.clientToken }}
                  onInstance={(instance) => (this.instance = instance)}
                />
              </Fade>
              </div>
            }
            </Fade>
            </CardBody>
            <CardFooter>
              <Button className="mr-2" color="secondary" onClick={() => {this.moveToStep1()}}>BACK</Button>
              <Button color="brightgreen" onClick={this.buy.bind(this)}>CONFIRM PURCHASE</Button>
            </CardFooter>
            </Fragment>
          }
          </Fragment>
        )}
      </Card>
		);
	}
}

export default UpgradeUser;
