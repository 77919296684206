import React from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';

class MessageCenter extends React.Component {

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 3,
			totalProcedures: 0,
	  };

  }

	async componentDidMount() {

	}

	render() {
		return (
      <Card className="card-hover">
				<CardBody style={{ minHeight: 417 }}>
					<CardTitle>Message Center</CardTitle>
					<Row className="pt-4 pb-4">
						<Col xs="12" md="12" lg="12">
							<Button style={{width: '100%'}} color="dark">
								Display Communication Error
							</Button>
						</Col>
					</Row>
					<Row className="pb-4">
						<Col xs="12" md="12" lg="12">
							<Button style={{width: '100%'}} color="dark">
								Relay 02 Error
							</Button>
						</Col>
					</Row>
					<Row className="pb-4">
						<Col xs="12" md="12" lg="12">
							<Button style={{width: '100%'}} color="dark">
								200v Booster Error
							</Button>
						</Col>
					</Row>
					<Row className="pb-4">
						<Col xs="12" md="12" lg="12">
							<Button style={{width: '100%'}} color="dark">
								Display Communication Error
							</Button>
						</Col>
					</Row>
					<Row className="pb-4">
						<Col xs="12" md="12" lg="12">
							<Button style={{width: '100%'}} color="dark">
								Display Communication Error
							</Button>
						</Col>
					</Row>
        </CardBody>
      </Card>
		);
	}
}

export default MessageCenter;
