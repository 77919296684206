import React from "react";
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
  FormGroup,
  Label
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';

class PreSetSurgical extends React.Component {

	constructor(props) {
    super(props);
    this.state = {
			totalProcedures: 0,
			presetDefaults:{
			"p0111":11,"p0211":12,"p0311":10,
			"p0112":13,"p0212":15,"p0312":11,
			"p0113":8,"p0213":9,"p0313":7,
			"p0114":12,"p0214":14,"p0314":11,
			"p0115":8,"p0215":9,"p0315":7,
			"p0116":10,"p0216":11,"p0316":9,
			"p0117":12,"p0217":14,"p0317":11,
			"p0118":8,"p0218":10,"p0318":7,
			"p0119":9,"p0219":10,"p0319":8,
			"p0101":6,"p0201":6,"p0301":5,
			"p0102":4,"p0202":5,"p0302":3,
			"p0103":7,"p0203":8,"p0303":6,
			"p0104":9,"p0204":10,"p0304":8,
			"p0105":0,"p0205":0,"p0305":0,
			"p0121":1,"p0221":1,"p0321":1,
			"p0122":1,"p0222":1,"p0322":1,
			"p0123":10,"p0223":10,"p0323":10},
			isEditing: false,
			currentPresets:[],
			currentPreset:0,
			currentPresetValue:0,
			rSelected: 1,
			modal: false,
			modalReset: false,
			modalPresetConfirmation: false,
			hasData: false,
			currentMessage: "",
			showNotRecommended: false,
			acknowledgeSelected: false
	  };
		this.userPresets = [];

		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
		this.toggle = this.toggle.bind(this);
		this.toggleReset = this.toggleReset.bind(this);
		this.togglePresetConfirmation = this.togglePresetConfirmation.bind(this);
		this.toggleCheckbox = this.toggleCheckbox.bind(this);
  }

	async componentDidMount() {
		//this.props.sendMessage('testing my function');
		//this.props.sendMessage('@@@save_preset'+'011113'+this.props.currentDeviceUID);
		//this.setState({currentPresets: this.props.currentPresets});
		//this.checkForPresets();
		//this.userPresets = JSON.parse(JSON.stringify(this.props.presetDefaults));
		this.intervalID = setInterval(this.checkForPresets, 1000);
	}

	async onRadioBtnClick(rSelected) {
		await this.setState({ rSelected });
	}

	checkPresets () {

		var foundNotRecommendedValue = false;

		this.props.procedures.map((procedure, key) => {

			if (procedure.OBJID<10){
				var preset = 'p'+'0'+this.state.rSelected+'0'+procedure.OBJID;
			} else {
				var preset = 'p'+'0'+this.state.rSelected+procedure.OBJID;
			}

			if (this.userPresets[preset]>= 18) {
				console.log(preset + ' is ' + this.userPresets[preset]);
				foundNotRecommendedValue = true;
			}

		})

		if (foundNotRecommendedValue) {
			this.setState({ showNotRecommended: true });
		} else {
			this.setState({ showNotRecommended: false });
		}
	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	toggleReset() {
		this.setState({
			modalReset: !this.state.modalReset
		});
	}

	togglePresetConfirmation() {
		this.setState({
			modalPresetConfirmation: !this.state.modalPresetConfirmation
		});
	}

	checkForPresets = () => {
		//console.log("currentPresets Value: ", this.props.currentPresets);
		if (this.props.currentPresets.product) {
			this.setState({currentPresets: this.props.currentPresets});
			this.userPresets = JSON.parse(JSON.stringify(this.props.currentPresets));
			//this.setState({userPresets: JSON.parse(JSON.stringify(this.props.currentPresets))})
			console.log("have userPresets: ", this.userPresets);

			this.checkPresets();

			this.setState({hasData: true});
			clearInterval(this.intervalID);
		} else {
			if (this.props.isReceivingMessages) {
				this.props.getDeviceInfo();
			}
		}
	}

	updatePreset(increase, preset, value) {
		console.log("clicked!");
		console.log("currentPresets: ", this.props.currentPresets);

		if (preset != this.state.currentPreset && this.state.currentPreset != 0) {

		}

		this.state.currentPreset = preset;
		this.state.currentPresetValue = value;

		if (increase) {
			if (value < 20){
				var newPreset = this.userPresets[preset]+1;
				console.log("new Value: "+newPreset);
				this.userPresets[preset] = this.userPresets[preset]+1;
			}
		} else {
			if (value > 0){
				var newPreset = this.userPresets[preset]-1;
				console.log("new Value: "+newPreset);
				this.userPresets[preset] = this.userPresets[preset]-1;
			}
		}
		console.log("state preset: "+this.state.currentPresets[preset]);
		console.log("user preset: "+this.userPresets[preset]);

		var changedPresetDefault = this.state.presetDefaults[preset];
		console.log("changedPresetDefault: "+changedPresetDefault);

		var changedPreset = this.userPresets[preset];

		var diff = changedPreset - changedPresetDefault;

		if (diff>0) {
			var diffPercent = (diff/changedPresetDefault)*100;
			console.log("diffPercent: "+diffPercent);

			if (diffPercent > 15) {
				this.togglePresetConfirmation();
			}
		}

		this.forceUpdate();
	}

	savepresent(preset, value, procedure) {

		this.state.currentPreset = preset;
		this.state.currentPresetValue = value;

		var objID = procedure.OBJID;
		var formatValue = value;
		if (value<10){
			formatValue = '0'+value;
		}

		if (objID<10){
			objID = '0'+objID;
		}

		var message = '@@@save_preset'+'0'+this.state.rSelected+objID+formatValue+','+this.props.currentDeviceUID;
		this.setState({currentMessage: message});
		console.log("new Value to save: "+message);
		//this.props.sendMessage(message, this.props.state);
		this.toggle();
		this.checkPresets();
	}

	cancelPreset (preset) {
		console.log("preset: "+preset);
		console.log("state preset: "+this.state.currentPresets[preset]);
		console.log("user preset: "+this.userPresets[preset]);
		this.userPresets[preset] = this.state.currentPresets[preset];
		this.forceUpdate();
	}

	confirmSavePreset () {
		this.props.sendMessage(this.state.currentMessage, this.props.state);
		this.state.currentPresets[this.state.currentPreset] = this.userPresets[this.state.currentPreset];
		this.toggle();
	}

	confirmResetPresets () {
		var message = '@@@reset_surgical_preset,'+this.props.currentDeviceUID;
		this.props.sendMessage(message, this.props.state);

		let presets = {
			index: this.userPresets.index,
			product: this.userPresets.product,
			serial_num: this.userPresets.serial_num
		};

		let resetPreset = {
		    ...presets,
		    ...this.state.presetDefaults
		};

		//this.userPresets = JSON.parse(JSON.stringify(this.props.presetDefaults));
		//this.state.currentPresets = JSON.parse(JSON.stringify(this.props.presetDefaults));

		this.userPresets = resetPreset;
		this.state.currentPresets = JSON.parse(JSON.stringify(this.userPresets));
		//this.intervalID = setInterval(this.checkForPresets, 1000);
		this.toggleReset();
	}

	checkPresetAcknowledgement = (event) => {
		event.preventDefault();

		console.log("Acknowledge Preset Setting");

	  let confirmed = event.target.confirmed.checked;
    console.log(`confirmed is:`, confirmed);

		if (confirmed) {
			this.togglePresetConfirmation();
		} else {
			this.cancelPreset(this.state.currentPreset);
			this.togglePresetConfirmation();
		}

	}

	toggleCheckbox(event) {
		console.log("Acknowledge Preset Setting");

	  let confirmed = event.target.checked;
    console.log(`confirmed is:`, confirmed);

		if (confirmed) {
			this.setState({ acknowledgeSelected: true });
		} else {
			this.setState({ acknowledgeSelected: false });
		}
  }

	presetAcknowledgementCanceled () {
		this.cancelPreset(this.state.currentPreset);
		this.togglePresetConfirmation();
	}

	render() {

		return (
      <Card className="card-hover">
				<CardBody style={{ minHeight: 480 }}>
					<Row className="pb-1">
						<Col xs="5" md="5" lg="5" className="text-left">
							<CardTitle>Pre-Set Surgical</CardTitle>
						</Col>
						<Col xs="7" md="7" lg="7" className="text-right">
							<ul className="list-inline font-12 dl mt-1">
								{this.state.showNotRecommended &&
									<li className="border-0 p-0 list-inline-item" style={{color:'#dc3545'}}>
										<i className="fa fa-circle"></i><span style={{color:'#ffffff'}}> Not Recommended</span></li>
								}
								<li className="border-0 p-0 list-inline-item" style={{color:'#ffc10e'}}>
									<i className="fa fa-circle"></i><span style={{color:'#ffffff'}}> Not Factory</span></li>
							</ul>
						</Col>
					</Row>
					{this.props.isReceivingMessages && this.state.hasData &&
						<Fragment>
						<Row className="pb-2">
							<Col xs="12" md="12" lg="12">
								<div className="mt-1 mb-1">
									<ButtonGroup style={{ width: '100%' }}>
										<Button
											style={{width: '100%'}}
											color="dark"
											onClick={() => this.onRadioBtnClick(1)}
											active={this.state.rSelected === 1}
										>
											810
										</Button>
										<Button
											color="dark"
											style={{width: '100%'}}
											onClick={() => this.onRadioBtnClick(2)}
											active={this.state.rSelected === 2}
										>
											980
										</Button>
										<Button
											color="dark"
											style={{width: '100%'}}
											onClick={() => this.onRadioBtnClick(3)}
											active={this.state.rSelected === 3}
										>
											Dual
										</Button>
									</ButtonGroup>
								</div>
							</Col>
						</Row>
						<div style={{ height: 310 }}>
							{
							this.props.procedures.map((procedure, key) => {
											// if even, render grey background

											if (procedure.OBJID<10){
												var preset = 'p'+'0'+this.state.rSelected+'0'+procedure.OBJID;
											} else {
												var preset = 'p'+'0'+this.state.rSelected+procedure.OBJID;
											}


											//console.log("presetRow: "+preset);
											//console.log("currentPresets: ", this.state.currentPresets);
											//console.log("this.state.userPresets[preset]: "+this.userPresets[preset]);
											//console.log("this.props.currentPresets[preset]: "+this.props.currentPresets[preset]);

											var watts = 0.0;

											if (this.userPresets[preset]) {
												watts = this.userPresets[preset];
											}

											var changedPresetDefault = this.state.presetDefaults[preset];
											//onsole.log("changedPresetDefault: "+changedPresetDefault);

											var changedPreset = this.userPresets[preset];

											var diff = 0;

											if (changedPreset > changedPresetDefault) {
										    diff = changedPreset - changedPresetDefault;
										  }

											var diffPercent = (diff/changedPresetDefault)*100;

											if (this.userPresets[preset] != this.state.currentPresets[preset]) {

												if (diffPercent > 15) {

													// don't forget to return what you want to render!
													return (
														<Fragment>
														<Row key={key} className="pt-1">
															<Col xs="5" md="5" lg="5">
																<h5 style={{color:'#dc3545'}}>{procedure.Name}</h5>
															</Col>
															<Col style={{color:'#dc3545'}} xs="3" md="3" lg="3" className="text-center">
																<i class="fa fa-minus-circle" aria-hidden="true" onClick={() => { this.updatePreset(false, preset, this.userPresets[preset])}}></i>
																<span> {(watts/10).toFixed(1)} W </span>
																<i class="fa fa-plus-circle" aria-hidden="true" onClick={() => { this.updatePreset(true, preset, this.userPresets[preset])}}></i>
															</Col>
															<Col style={{color:'#dc3545'}} xs="4" md="4" lg="4" className="text-right">
																<Button size="sm" style={{width: '55px'}} onClick={() => { this.cancelPreset(preset)}} >Cancel</Button>
																<span> </span>
																<Button size="sm" color="brightgreen" style={{width: '55px'}} onClick={() => { this.savepresent(preset, this.userPresets[preset], procedure)}} >Save</Button>
															</Col>
														</Row>
														</Fragment>
													);

												} else {

													// don't forget to return what you want to render!
													return (
														<Fragment>
														<Row key={key} className="pt-1">
															<Col xs="5" md="5" lg="5">
																<h5 style={{color:'#ffc10e'}}>{procedure.Name}</h5>
															</Col>
															<Col style={{color:'#ffc10e'}} xs="3" md="3" lg="3" className="text-center">
																<i class="fa fa-minus-circle" aria-hidden="true" onClick={() => { this.updatePreset(false, preset, this.userPresets[preset])}}></i>
																<span> {(watts/10).toFixed(1)} W </span>
																<i class="fa fa-plus-circle" aria-hidden="true" onClick={() => { this.updatePreset(true, preset, this.userPresets[preset])}}></i>
															</Col>
															<Col style={{color:'#ffc10e'}} xs="4" md="4" lg="4" className="text-right">
																<Button size="sm" style={{width: '55px'}} onClick={() => { this.cancelPreset(preset)}} >Cancel</Button>
																<span> </span>
																<Button size="sm" color="brightgreen" style={{width: '55px'}} onClick={() => { this.savepresent(preset, this.userPresets[preset], procedure)}} >Save</Button>
															</Col>
														</Row>
														</Fragment>
													);

												}


											} else {
												// you can also use ternary expression

												if (this.state.currentPresets[preset] != this.state.presetDefaults[preset]) {

													if (diffPercent > 15) {

														return (
															<Fragment>
															<Row key={key} className="pt-1">
																<Col xs="5" md="5" lg="5">
																		<h5 style={{color:'#dc3545'}}>{procedure.Name}</h5>
																</Col>
																<Col style={{color:'#dc3545'}} xs="3" md="3" lg="3" className="text-center">
																	<i class="fa fa-minus-circle" aria-hidden="true" onClick={() => { this.updatePreset(false, preset, this.userPresets[preset])}}></i>
																	<span> {(watts/10).toFixed(1)} W </span>
																	<i class="fa fa-plus-circle" aria-hidden="true" onClick={() => { this.updatePreset(true, preset, this.userPresets[preset])}}></i>
																</Col>
																<Col style={{color:'#dc3545'}} xs="4" md="4" lg="4" className="text-center">
																</Col>
															</Row>
															</Fragment>
														);

													} else {

														return (
															<Fragment>
															<Row key={key} className="pt-1">
																<Col xs="5" md="5" lg="5">
																		<h5 style={{color:'#ffc10e'}}>{procedure.Name}</h5>
																</Col>
																<Col style={{color:'#ffc10e'}} xs="3" md="3" lg="3" className="text-center">
																	<i class="fa fa-minus-circle" aria-hidden="true" onClick={() => { this.updatePreset(false, preset, this.userPresets[preset])}}></i>
																	<span> {(watts/10).toFixed(1)} W </span>
																	<i class="fa fa-plus-circle" aria-hidden="true" onClick={() => { this.updatePreset(true, preset, this.userPresets[preset])}}></i>
																</Col>
																<Col style={{color:'#ffc10e'}} xs="4" md="4" lg="4" className="text-center">
																</Col>
															</Row>
															</Fragment>
														);

													}

												} else {

													return (
														<Fragment>
														<Row key={key} className="pt-1">
															<Col xs="5" md="5" lg="5">
																	<h5>{procedure.Name}</h5>
															</Col>
															<Col xs="3" md="3" lg="3" className="text-center">
																<i class="fa fa-minus-circle" aria-hidden="true" onClick={() => { this.updatePreset(false, preset, this.userPresets[preset])}}></i>
																<span> {(watts/10).toFixed(1)} W </span>
																<i class="fa fa-plus-circle" aria-hidden="true" onClick={() => { this.updatePreset(true, preset, this.userPresets[preset])}}></i>
															</Col>
															<Col xs="4" md="4" lg="4" className="text-center">
															</Col>
														</Row>
														</Fragment>
													);

												}

											}
										})
							}
						</div>
						<Row className="pb-4">
							<Col xs="12" md="12" lg="12">
								<Button style={{width: '100%'}} color="red" onClick={() => {this.toggleReset()}}>
									Factory Reset
								</Button>
							</Col>
						</Row>
						</Fragment>
					}
					{!this.props.isReceivingMessages &&
						<div className="mt-5 text-center">
							<h5>Devices {this.props.currentDevice} is Currently not Connected.</h5>
						</div>
					}
        </CardBody>
				<div>
				<Modal color="dark" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
	        <ModalHeader toggle={this.toggle}>You're about to update a Preset Setting</ModalHeader>
	        <ModalBody>
						Are you sure you want to update that Preset setting?
	        </ModalBody>
	        <ModalFooter>
						<Button color="secondary" onClick={this.toggle}>Cancel</Button>
	          <Button color="secondary" onClick={() => {this.confirmSavePreset()}}>Confirm</Button>
	        </ModalFooter>
	      </Modal>
				</div>
				<div>
				<Modal color="dark" isOpen={this.state.modalReset} toggle={this.toggleReset} className={this.props.className}>
	        <ModalHeader toggle={this.toggleReset}>You're about to reset all Surgical Presets</ModalHeader>
	        <ModalBody>
						Are you sure you want to factory reset all surgical presets?
	        </ModalBody>
	        <ModalFooter>
						<Button color="secondary" onClick={this.toggleReset}>Cancel</Button>
	          <Button color="secondary" onClick={() => {this.confirmResetPresets()}}>Confirm</Button>
	        </ModalFooter>
	      </Modal>
				</div>
				<div>
				<Modal color="dark" isOpen={this.state.modalPresetConfirmation} toggle={this.togglePresetConfirmation} className={this.props.className}>
	        <ModalHeader toggle={this.togglePresetConfirmation}>You're about to update a Preset Setting</ModalHeader>
	        <ModalBody>
						Any wattage setting 15% or greater than preset default is not recommended. Are you sure you want to update that Preset setting?
						<Form onSubmit={this.checkPresetAcknowledgement}>
							<FormGroup check className="mt-2 mb-2">
				        <Label for="confirmed" check>
				        <Input name="confirmed" id="confirmed" type="checkbox" onChange={this.toggleCheckbox} />
				          Check to Confirm
				        </Label>
				      </FormGroup>
							<FormGroup>
  							<Button color="primary" type="submit" disabled={!this.state.acknowledgeSelected}>Acknowledge</Button>
  							<Button color="secondary" className="ml-1" onClick={() => {this.presetAcknowledgementCanceled()}}>Cancel</Button>
  						</FormGroup>
  					</Form>
	        </ModalBody>
	      </Modal>
				</div>
      </Card>
		);
	}
}

export default PreSetSurgical;
