import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
	Tooltip
} from 'reactstrap';

import AnimatedCursor from "react-animated-cursor"
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import brokenpin from '../../../assets/images/pins/broken_pin.png';
import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';
import '../../../assets/calculator/css/calculator.css';
import ReactImageDot from './React-Image-Dot/ReactImageDot';
import DotsInfo from './React-Image-Dot/DotsInfo';
import skull1 from './images/skull.jpeg';
import skullFront from './images/skull_front.png';
import skullLeft from './images/skull_left.png';
import skullRight from './images/skull_right.png';

import intraOralRight from './images/intra_oral_right_small.png';
import intraOralLeft from './images/intra_oral_left_small.png';
import extraOralRight from './images/extra_oral_right_small.png';
import extraOralLeft from './images/extra_oral_left_small.png';
import extraOralFront from './images/extra_oral_front_small.png';

import intraOralRightLarge from './images/intra_oral_right.png';
import intraOralLeftLarge from './images/intra_oral_left.png';
import extraOralRightLarge from './images/extra_oral_right.png';
import extraOralLeftLarge from './images/extra_oral_left.png';
import extraOralFrontLarge from './images/extra_oral_front.png';

import frontBlackWhite from './images/front_back_white_small.png';
import frontBlackWhiteLarge from './images/front_back_white.png';

var doseRange = 0;
var viewCalc1 = 0;
var viewCalc2 = 0;

var adapterValue, objectiveValue, painlevelValue, colorValue;
var painlevelValue = 0;

class PBMCalculatorView extends React.Component {

	constructor(props) {
    super(props);

		this.initialDots = [];

    this.state = {
			noDevices: false,
			devicesLoaded: false,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			deviceInfo: [],
      timeOutput: 50,
      timeOutput1: 50,
			dots: [],
			dots2: [],
			dots3: [],
			dots4: [],
			dots5: [],
			sideState: 0,
			typeState: 0,
			totalCount: 0,
			tipSize: 5,
			tooltip1Open: false,
			tooltip2Open: false,
			tooltip3Open: false,
			modal: false,
			adapterSelection: "7mm",
			windowWidth: window.innerWidth,
			steps: [
	      {
	        target: '#adapter',
	        content: 'Select a treatment adapter.',
					placement: 'top'
	      },
	      {
	        target: '#treatmentcount',
	        content: 'Open the treatment selection app.',
					placement: 'auto'
	      }
	    ],
			run: true,
			stepIndex: 0,
			stepsModal: [
				{
	        target: '.react-image-dot__wrapper',
	        content: 'Mouse click on the body locations you want to treat.',
					placement: 'auto'
	      }
	    ],
			runModal: false,
			stepIndexModal: 0,
			modalIsOpen: false
	  };

    this.calculateTimeDose = this.calculateTimeDose.bind(this);
    this.colorLevels = this.colorLevels.bind(this);
    this.painLevels = this.painLevels.bind(this);
    this.calculateDose = this.calculateDose.bind(this);
    this.calculateTime = this.calculateTime.bind(this);

		this.toggle = this.toggle.bind(this);

		this.toggleTooltip1 = this.toggleTooltip1.bind(this);
		this.toggleTooltip2 = this.toggleTooltip2.bind(this);
		this.toggleTooltip3 = this.toggleTooltip3.bind(this);

  }

	handleResize = (e) => {
	  this.setState({ windowWidth: window.innerWidth });
	}

	async componentDidMount() {

		window.addEventListener("resize", this.handleResize);

    document.getElementById("objective").style.display = "none";
    document.getElementById("painlevel").style.display = "none";
    document.getElementById("tissuecolor").style.display = "none";
		document.getElementById("treatmentcount").style.display = "none";


	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	toggleTooltip1() {
    this.setState({
      tooltip1Open: !this.state.tooltip1Open
    });
  }

	toggleTooltip2() {
    this.setState({
      tooltip2Open: !this.state.tooltip2Open
    });
  }

	toggleTooltip3() {
    this.setState({
      tooltip3Open: !this.state.tooltip3Open
    });
  }

	handleJoyrideCallback = data => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    }
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ run: false });
    }

    console.groupCollapsed(type);
    console.log(data); //eslint-disable-line no-console
    console.groupEnd();
  };

	toggle() {
	  this.setState({
	    modal: !this.state.modal,
			sideState: 1,
			typeState: 1,
			run: true,
			stepIndex: 2
	  });
	}

	calculateTotal = () => {

		let leftTotal = 0;
		let rightTotal = 0;
		let frontTotal = 0;

		let totalCount = 0;

		if (this.state.typeState==0) {
			leftTotal = this.state.dots.length;
			rightTotal = this.state.dots2.length;

			totalCount = leftTotal + rightTotal;
		} else {
			leftTotal = this.state.dots3.length;
			rightTotal = this.state.dots4.length;
			frontTotal = this.state.dots5.length;

			totalCount = leftTotal + frontTotal + rightTotal
		}

		console.log("totalCount: " + totalCount);
		this.setState({ totalCount });

		document.getElementById("calculatedtreatments1").innerHTML = totalCount;
  }

	addDot = (dot) => {
    this.setState({
      dots: [
        ...this.state.dots,
        dot,
      ],
    });

		setTimeout(this.calculateTotal, 200);
  }

  deleteDot = (index) => {

		console.log("pbm delete: " + index);

    this.setState({
      dots: this.state.dots.filter((e, i) => {
        return i !== index;
      }),
    });
		setTimeout(this.calculateTotal, 200);
  }

  resetDots = () => {
    this.setState({
      dots: this.initialDots,
    });
		setTimeout(this.calculateTotal, 200);
  }

	addDot2 = (dot) => {
    this.setState({
      dots2: [
        ...this.state.dots2,
        dot,
      ],
    });
		setTimeout(this.calculateTotal, 200);
  }

  deleteDot2 = (index) => {

		console.log("pbm delete: " + index);

    this.setState({
      dots2: this.state.dots2.filter((e, i) => {
        return i !== index;
      }),
    });
		setTimeout(this.calculateTotal, 200);
  }

  resetDots2 = () => {
    this.setState({
      dots2: this.initialDots,
    });
		setTimeout(this.calculateTotal, 200);
  }

	addDot3 = (dot) => {
    this.setState({
      dots3: [
        ...this.state.dots3,
        dot,
      ],
    });
		setTimeout(this.calculateTotal, 200);
  }

  deleteDot3 = (index) => {

		console.log("pbm delete: " + index);

    this.setState({
      dots3: this.state.dots3.filter((e, i) => {
        return i !== index;
      }),
    });
		setTimeout(this.calculateTotal, 200);
  }

  resetDots3 = () => {
    this.setState({
      dots3: this.initialDots,
    });
		setTimeout(this.calculateTotal, 200);
  }

	addDot4 = (dot) => {
    this.setState({
      dots4: [
        ...this.state.dots4,
        dot,
      ],
    });
		setTimeout(this.calculateTotal, 200);
  }

  deleteDot4 = (index) => {

		console.log("pbm delete: " + index);

    this.setState({
      dots4: this.state.dots4.filter((e, i) => {
        return i !== index;
      }),
    });
		setTimeout(this.calculateTotal, 200);
  }

  resetDots4 = () => {
    this.setState({
      dots4: this.initialDots,
    });
		setTimeout(this.calculateTotal, 200);
  }

	addDot5 = (dot) => {
    this.setState({
      dots5: [
        ...this.state.dots5,
        dot,
      ],
    });
		setTimeout(this.calculateTotal, 200);
  }

  deleteDot5 = (index) => {

		console.log("pbm delete: " + index);

    this.setState({
      dots5: this.state.dots5.filter((e, i) => {
        return i !== index;
      }),
    });
		setTimeout(this.calculateTotal, 200);
  }

  resetDots5 = () => {
    this.setState({
      dots5: this.initialDots,
    });
		setTimeout(this.calculateTotal, 200);
  }

	viewLeftSkull = () => {

		if (this.state.typeState==0) {
			this.setState({
				sideState: 0,
			});
		} else {
			if (this.state.sideState==1) {
				this.setState({
					sideState: 0,
				});
			} else if (this.state.sideState==2) {
				this.setState({
					sideState: 1,
				});
			}
		}

	}

	viewRightSkull = () => {

		if (this.state.typeState==0) {
			this.setState({
				sideState: 1,
			});
		} else {
			if (this.state.sideState==0) {
				this.setState({
					sideState: 1,
				});
			} else if (this.state.sideState==1) {
				this.setState({
					sideState: 2,
				});
			}
		}

	}

	viewExtraOralSkullType = () => {
		this.setState({
			typeState: 1,
			sideState: 0
		});

		setTimeout(this.calculateTotal, 200);
	}

	viewIntraOralSkullType = () => {
		this.setState({
			typeState: 0,
			sideState: 0
		});

		setTimeout(this.calculateTotal, 200);
	}

  adapterSelection() {
    console.log("adapterSelection selected");
    var adapter = document.getElementsByName("selectedadapter");
    for (var i = 0; i < adapter.length; i++) {
      if (adapter[i].checked) {
        adapterValue = adapter[i].value;
        var checkingElements = document.querySelectorAll(".customradio");
        checkingElements[i].classList.add("selected");
        document.getElementById("objective").style.display = "inherit";
      } else {
        var checkingElements = document.querySelectorAll(".customradio");
        checkingElements[i].classList.remove("selected");
      }
    }

    console.log("adapterSelection adapterValue: "+adapterValue);

		this.resetDots();
		this.resetDots2();
		this.resetDots3();
		this.resetDots4();
		this.resetDots5();



    return adapterValue;
  }

  objectiveSelection() {
    var objective = document.getElementsByName("selectedobjective");
    for (var i = 0; i < objective.length; i++) {
      if (objective[i].checked) {
        objectiveValue = objective[i].value;
        var checkingElements = document.querySelectorAll(".customradio3");
        checkingElements[i].classList.add("selected");
        if (objectiveValue == "relief") {
          document.getElementById("painlevel").style.display = "inherit";
        } else if (objectiveValue == "healing") {
          document.getElementById("tissuecolor").style.display = "inherit";
          document.getElementById("painlevel").style.display = "none";
        }
      } else {
        var checkingElements = document.querySelectorAll(".customradio3");
        checkingElements[i].classList.remove("selected");
      }
    }

    return objectiveValue;
  }

  painSelection() {
    var painlevel = document.getElementsByName("selectedpain");
    for (var i = 0; i < painlevel.length; i++) {
      if (painlevel[i].checked) {
        painlevelValue = painlevel[i].value;
        var checkingElements = document.querySelectorAll(".customradio4");
        checkingElements[i].classList.add("selected");
        document.getElementById("tissuecolor").style.display = "inherit";
      } else {
        var checkingElements = document.querySelectorAll(".customradio4");
        checkingElements[i].classList.remove("selected");
      }
    }

    return painlevelValue;
  }

  tissueSelection() {
    var color = document.getElementsByName("selectedcolor");
    for (var i = 0; i < color.length; i++) {
      if (color[i].checked) {
        colorValue = color[i].value;
        var checkingElements = document.querySelectorAll(".customradio5");
        checkingElements[i].classList.add("selected");
				document.getElementById("treatmentcount").style.display = "inherit";
				this.toggle();
      } else {
        var checkingElements = document.querySelectorAll(".customradio5");
        checkingElements[i].classList.remove("selected");
      }
    }

		this.setState({ run: true, stepIndex: 1});

    return colorValue;
  }

  calculateTimeDose() {
    var adapterValue = this.adapterSelection();
    var objectiveValue = this.objectiveSelection();
    var painlevelValue = this.painSelection();
    var colorValue = this.tissueSelection();

    if (adapterValue == "25mm") {

			this.setState({ tipSize: 25});
      //document.getElementById("treatementinstructions").innerHTML = "Hold the PBM Adapter at a fixed spot with PBM spacer touching target tissue until timer is complete.";
      var density = 0.204;
      if (objectiveValue == "healing") {
        var initialVal = 30;
        var increment = -2;
        this.colorLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && painlevelValue == 0) {
        document.getElementById("calculatedtime3").innerHTML = "/";
				if (document.getElementById("calculateddose4")) {
					document.getElementById("calculateddose4").innerHTML = "/";
				}
      }
      if (objectiveValue == "relief" && colorValue == "i") {
        var initialVal = 55;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "ii") {
        var initialVal = 50;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "iii") {
        var initialVal = 45;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "iv") {
        var initialVal = 40;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "v") {
        var initialVal = 35;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "vi") {
        var initialVal = 30;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
    } else if (adapterValue == "7mm") {

			this.setState({ tipSize: 9});

      //document.getElementById("treatementinstructions").innerHTML = "Hold the PBM Adapter in a fixed spot either in contact or 1-2mm away from target area until timer is complete.";
      var density = 0.789;
      if (objectiveValue == "healing") {
        var initialVal = 25;
        var increment = -2;
        this.colorLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && painlevelValue == 0) {
        document.getElementById("calculatedtime3").innerHTML = "/";
				if (document.getElementById("calculateddose4")) {
					document.getElementById("calculateddose4").innerHTML = "/";
				}
      }
      if (objectiveValue == "relief" && colorValue == "i") {
        var initialVal = 48;
        var increment = 2;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "ii") {
        var initialVal = 44;
        var increment = 2;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "iii") {
        var initialVal = 42;
        var increment = 2;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "iv") {
        var initialVal = 38;
        var increment = 2;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "v") {
        var initialVal = 34;
        var increment = 2;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "vi") {
        var initialVal = 30;
        var increment = 2;
        this.painLevels(colorValue, density, initialVal, increment);
      }
    } else if (adapterValue == "3mm") {
			this.setState({ tipSize: 5});
      //document.getElementById("treatementinstructions").innerHTML = "Scan the desired treatment spot while staying 2-3mm from target tissue. The treatment spot should be no larger than 15mm in diameter.";
      var density = 4.286;
      if (objectiveValue == "healing") {
        var initialVal = 20;
        var increment = -2;
        this.colorLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && painlevelValue == 0) {
        document.getElementById("calculatedtime3").innerHTML = "/";
				if (document.getElementById("calculateddose4")) {
					document.getElementById("calculateddose4").innerHTML = "/";
				}
      }
      if (objectiveValue == "relief" && colorValue == "i") {
        var initialVal = 38;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "ii") {
        var initialVal = 34;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "iii") {
        var initialVal = 32;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "iv") {
        var initialVal = 28;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "v") {
        var initialVal = 24;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
      if (objectiveValue == "relief" && colorValue == "vi") {
        var initialVal = 20;
        var increment = 3;
        this.painLevels(colorValue, density, initialVal, increment);
      }
    }
  }

  colorLevels(colorValue, density, initialVal, increment) {
    if (colorValue == "i") {
      this.printValues(density, initialVal, increment, 0);
    } else if (colorValue == "ii") {
      this.printValues(density, initialVal, increment, 1);
    } else if (colorValue == "iii") {
      this.printValues(density, initialVal, increment, 2);
    } else if (colorValue == "iv") {
      this.printValues(density, initialVal, increment, 3);
    } else if (colorValue == "v") {
      this.printValues(density, initialVal, increment, 4);
    } else if (colorValue == "vi") {
      this.printValues(density, initialVal, increment, 5);
    }
  }

  painLevels(colorValue, density, initialVal, increment) {
    if (painlevelValue == "1") {
      this.printValues(density, initialVal, increment, 0);
    } else if (painlevelValue == "2") {
      this.printValues(density, initialVal, increment, 1);
    } else if (painlevelValue == "3") {
      this.printValues(density, initialVal, increment, 2);
    } else if (painlevelValue == "4") {
      this.printValues(density, initialVal, increment, 3);
    } else if (painlevelValue == "5") {
      this.printValues(density, initialVal, increment, 4);
    }
  }

  printValues(density, initialVal, increment, row) {
    var calculatedVal = initialVal + increment * row;
    document.getElementById("calculatedtime3").innerHTML =
      calculatedVal + " seconds";
		if (document.getElementById("calculateddose4")) {
			document.getElementById("calculateddose4").innerHTML = Math.round(
	      calculatedVal * density
	    ) + " J/cm2";
		}

		document.getElementById("calculatedtreatments1").innerHTML = this.state.totalCount;
    if (density == 4.286) {
			if (document.getElementById("calculateddose4")) {
				document.getElementById("calculateddose4").innerHTML = "N/A";
			}
    }
  }

  showDoseRange() {
    if (doseRange == 0) {
      document.getElementById("doserange1").style.display = "inherit";
      document.getElementById("viewDose").innerHTML = "HIDE DOSE";
      doseRange = 1;
    } else {
      document.getElementById("doserange1").style.display = "none";
      document.getElementById("viewDose").innerHTML = "SHOW DOSE";
      doseRange = 0;
    }
  }

  showCalc1() {
    if (viewCalc1 == 0) {
      document.getElementById("adaptergeneral").style.display = "inherit";
      document.getElementById("timeslider").style.display = "inherit";
      document.getElementById("doserange2").style.opacity = "1";
      document.getElementById("calcinfo").style.display = "none";
      document.getElementById("viewCalc1").innerHTML = "HIDE CALCULATOR";
      viewCalc1 = 1;
    } else {
      document.getElementById("adaptergeneral").style.display = "none";
      document.getElementById("timeslider").style.display = "none";
      document.getElementById("doserange2").style.opacity = "0";
      document.getElementById("calcinfo").style.display = "block";
      document.getElementById("viewCalc1").innerHTML = "USE CALCULATOR";
      viewCalc1 = 0;
    }
  }

  showCalc2() {
    if (viewCalc2 == 0) {
      document.getElementById("adaptergeneral1").style.display = "inherit";
      document.getElementById("timeslider1").style.display = "inherit";
      document.getElementById("timerange2").style.opacity = "1";
      document.getElementById("calcinfo1").style.display = "none";
      document.getElementById("viewCalc2").innerHTML = "HIDE CALCULATOR";
      viewCalc2 = 1;
    } else {
      document.getElementById("adaptergeneral1").style.display = "none";
      document.getElementById("timeslider1").style.display = "none";
      document.getElementById("timerange2").style.opacity = "0";
      document.getElementById("calcinfo1").style.display = "block";
      document.getElementById("viewCalc2").innerHTML = "USE CALCULATOR";
      viewCalc2 = 0;
    }
  }

  resetCalculator() {
    //location.reload();
    return false;
  }

  calculateDose() {
    var adaptergeneral = document.getElementsByName("selectedadaptergeneral");
    var timegeneral = document.getElementById("myRange");
    var adaptergeneralValue;

    this.setState({ timeOutput: timegeneral.value});

    for (var i = 0; i < adaptergeneral.length; i++) {
      if (adaptergeneral[i].checked) {
        adaptergeneralValue = adaptergeneral[i].value;
        var checkingElements = document.querySelectorAll(".customradio1");
        checkingElements[i].classList.add("selected");
      } else {
        var checkingElements = document.querySelectorAll(".customradio1");
        checkingElements[i].classList.remove("selected");
      }
    }

    var timegeneralValue = timegeneral.value;

    if (adaptergeneralValue == "25mm") {
      document.getElementById("calculateddose2").innerHTML = Math.round(
        (1 * timegeneralValue) / 4.91
      );
    } else if (adaptergeneralValue == "7mm") {
      document.getElementById("calculateddose2").innerHTML = Math.round(
        (0.3 * timegeneralValue) / 0.38
      );
    } else if (adaptergeneralValue == "3mm") {
      document.getElementById("calculateddose2").innerHTML = Math.round(
        (0.3 * timegeneralValue) / 0.07
      );
    }

    this.changeFillWidth();
  }

  changeFillWidth() {
    var timegeneral = document.getElementById("myRange");
    var fill = document.getElementById("fill");

    var timegeneralValue = timegeneral.value;

    if (timegeneralValue == 99) {
      fill.style.width = "100%";
    } else {
      fill.style.width = timegeneralValue + "%";
    }
  }

  calculateTime() {
    var adaptergeneral1 = document.getElementsByName("selectedadaptergeneral1");
    var dosegeneral = document.getElementById("myRange1");
    var adaptergeneralValue1;

    this.setState({ timeOutput1: dosegeneral.value});

    for (var i = 0; i < adaptergeneral1.length; i++) {
      if (adaptergeneral1[i].checked) {
        adaptergeneralValue1 = adaptergeneral1[i].value;
        var checkingElements = document.querySelectorAll(".customradio2");
        checkingElements[i].classList.add("selected");
      } else {
        var checkingElements = document.querySelectorAll(".customradio2");
        checkingElements[i].classList.remove("selected");
      }
    }

    var dosegeneralValue = dosegeneral.value;

    if (adaptergeneralValue1 == "25mm") {
      document.getElementById("calculatedtime2").innerHTML = Math.round(
        dosegeneralValue / 0.204
      );
    } else if (adaptergeneralValue1 == "7mm") {
      document.getElementById("calculatedtime2").innerHTML = Math.round(
        dosegeneralValue / 0.789
      );
    } else if (adaptergeneralValue1 == "3mm") {
      document.getElementById("calculatedtime2").innerHTML = Math.round(
        dosegeneralValue / 4.286
      );
    }

    this.changeFillWidth1();
  }

  changeFillWidth1() {
    var dosegeneral = document.getElementById("myRange1");
    var fill1 = document.getElementById("fill1");

    var dosegeneralValue = dosegeneral.value;

    if (dosegeneralValue == 99) {
      fill1.style.width = "100%";
    } else {
      fill1.style.width = dosegeneralValue + "%";
    }
  }

  test() {
    console.log("Testing Worked");
  }

  getData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"all-devices";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all devices: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ devices: body.results });
		} else {
      //ADD ERROR MESSAGE
    }

		// for ( var i = 0; i < this.state.devices.length; i++ ) {
    //
		// 	if (!this.state.devices[i].geo_timestamp || !this.state.devices[i].WifiProvisioned) {
		// 		this.state.devicesIcons.push( {
		// 			serial_number: this.state.devices[i].SerialNumber,
	  //       current_icon: brokenpin
		// 		});
		// 	} else {
		// 		this.state.devicesIcons.push( {
		// 			serial_number: this.state.devices[i].SerialNumber,
	  //       current_icon: redpin
		// 		});
		// 	}
    //
		// }
    //
    // this.setState({ devicesLoaded: true });
	};

	closeModal = () => {
    this.setState({
      modalIsOpen: false,
      runModal: false,
    });
  };

  afterOpenModal = () => {
    this.setState({
      runModal: true,
    });

		console.log("afterOpenModal");
  };

	render() {

		const { dots } = this.state;
		const { dots2 } = this.state;
		const { dots3 } = this.state;
		const { dots4 } = this.state;
		const { dots5 } = this.state;
		const { run, steps, stepIndex, runModal, stepsModal, stepIndexModal } = this.state;

		const { windowWidth } = this.state;

		return (
      <Card className="card-hover">
				<CardBody>
          <Fragment>
          <div id="calculator">
            <h1 style={{ fontSize: '3em' }}>Treatment Dosage Calculator</h1>
            <p className="mt-2">Use this PBM calculator to determine an appropriate treatment time based on the PBM Adapter used and desired treatment objective. This is directional guidance from the manufacturer and should not be interpreted as clinical advice by any means, nor should this calculator replace the necessary training required of operators to properly use the Gemini EVO for PBM applications.</p>

            <div id="firstcalc" className="mt-1" style={{ width: "100%"}}>
              <div id="adapter">
                  <label >Select Adapter<br/><br/></label>
                  <label className='radiolabel'>
									<i style={{color: '#b1b8be'}} className="mdi mdi-information-outline mr-1" id="TooltipOne"/>
									<Tooltip placement="top" isOpen={this.state.tooltip1Open} target="TooltipOne" toggle={this.toggleTooltip1}>
										Scan the desired treatment spot while staying 2-3mm from target tissue. The treatment spot should be no larger than 15mm in diameter.
									</Tooltip>
									<span className="customradio "></span><input type="radio" name="selectedadapter" value="3mm" onClick={this.adapterSelection, this.calculateTimeDose} />3mm (Scan Treatment)</label>
                  <label className='radiolabel'>
									<i style={{color: '#b1b8be'}} className="mdi mdi-information-outline mr-1" id="TooltipTwo"/>
									<Tooltip placement="top" isOpen={this.state.tooltip2Open} target="TooltipTwo" toggle={this.toggleTooltip2}>
										Hold the PBM Adapter in a fixed spot either in contact or 1-2mm away from target area until timer is complete.
									</Tooltip>
									<span className="customradio"></span><input type="radio" name="selectedadapter" value="7mm" onClick={this.adapterSelection, this.calculateTimeDose}/>7mm (Spot Treatment)</label>
                  <label className='radiolabel'>
									<i style={{color: '#b1b8be'}} className="mdi mdi-information-outline mr-1" id="TooltipThree"/>
									<Tooltip placement="top" isOpen={this.state.tooltip3Open} target="TooltipThree" toggle={this.toggleTooltip3}>
										Hold the PBM Adapter at a fixed spot with PBM spacer touching target tissue until timer is complete.
									</Tooltip>
									<span className="customradio"></span><input type="radio" name="selectedadapter" value="25mm" onClick={this.adapterSelection, this.calculateTimeDose}/>25mm (Spot Treatment)</label>
                  <hr/>
									<div id="objective">
		                <label >Select Objective<br/><br/></label>
		                <label className='radiolabel'><span className="customradio3 "></span><input type="radio" name="selectedobjective" value="relief" onClick={this.objectiveSelection, this.calculateTimeDose} />Pain Relief</label>
		                <label className='radiolabel'><span className="customradio3"></span><input type="radio" name="selectedobjective" value="healing" onClick={this.objectiveSelection, this.calculateTimeDose}/>Promote Healing</label>
		                <hr/>
		              </div>
              </div>

              <div id="painlevel">
                <label id="painlevelNA">Select Pain Level<br/><br/></label>
                <label className='radiolabel'><span className="customradio4 "></span><input type="radio"  name="selectedpain" value="1" onClick={this.painSelection, this.calculateTimeDose} />1 - least</label>
                <label className='radiolabel'><span className="customradio4"></span><input type="radio"  name="selectedpain" value="2" onClick={this.painSelection, this.calculateTimeDose}/>2</label>
                <label className='radiolabel'><span className="customradio4"></span><input type="radio"  name="selectedpain" value="3" onClick={this.painSelection, this.calculateTimeDose}/>3</label>
                <label className='radiolabel'><span className="customradio4"></span><input type="radio"  name="selectedpain" value="4" onClick={this.painSelection, this.calculateTimeDose}/>4</label>
                <label className='radiolabel'><span className="customradio4"></span><input type="radio"  name="selectedpain" value="5" onClick={this.painSelection, this.calculateTimeDose}/>5 - most</label>
                <hr/>
              </div>
              <div id="tissuecolor">
                <label >Select Tissue Color<br/><span>(Fitzpatrick Scale)</span></label>
                <label className='radiolabel'><span className="customradio5 "></span><input type="radio"  name="selectedcolor" value="i" onClick={this.tissueSelection, this.calculateTimeDose} />Type I <span id="i"></span></label>
                <label className='radiolabel'><span className="customradio5"></span><input type="radio"  name="selectedcolor" value="ii" onClick={this.tissueSelection, this.calculateTimeDose}/>Type II <span id="ii"></span></label>
                <label className='radiolabel'><span className="customradio5"></span><input type="radio"  name="selectedcolor" value="iii" onClick={this.tissueSelection, this.calculateTimeDose}/>Type III <span id="iii"></span></label>
                <label className='radiolabel'><span className="customradio5"></span><input type="radio"  name="selectedcolor" value="iv" onClick={this.tissueSelection, this.calculateTimeDose}/>Type IV <span id="iv"></span></label>
                <label className='radiolabel'><span className="customradio5"></span><input type="radio"  name="selectedcolor" value="v" onClick={this.tissueSelection, this.calculateTimeDose}/>Type V <span id="v"></span></label>
                <label className='radiolabel'><span className="customradio5"></span><input type="radio"  name="selectedcolor" value="vi" onClick={this.tissueSelection, this.calculateTimeDose}/>Type VI <span id="vi"></span></label>
              </div>
							<div id="treatmentcount">
								<label >Select Treatment Targets<br/><br/></label>
								<div className="float-right" style={{ width: "352px", height: "352px", display: 'block'}}>

									<div style={{cursor:"pointer", width: "100%"}} title="Select Treatments" className="" onClick={this.toggle}><img style={{width: "100%"}} src={frontBlackWhite} alt="Select Treatments" /></div>

								</div>
							</div>
            </div>
						<div id="timerange1" style={{ width: "100%"}}>
							{windowWidth > 1000 &&
								<div style={{ flexDirection: 'row', width: '100%' ,justifyContent: "center", alignItems: "center" }}>
									<div style={{ width: '100px', float: "left", margin: "5px" }}>
										<div id="calculatedtreatments1">/</div>
									</div>
									<div style={{ width: '380px', float: "left", margin: "5px" }}>
										<h3 className="mt-3" style={{ color: "#999999"}}>Treatments. Set Gemini EVO to </h3>
									</div>
									<div style={{ width: '200px', float: "left", margin: "5px" }}>
										<output name="timeOutputName1" id="calculatedtime3">/</output>
									</div>
									{adapterValue == "3mm" ? (
										<div style={{ width: '600px', float: "left", margin: "5px" }}>
											<h3 className="mt-3" style={{ color: "#999999"}}>using a scanning motion around the treatment area.</h3>
										</div>
									) : (
										<Fragment>
										<div style={{ width: '170px', float: "left", margin: "5px" }}>
											<h3 className="mt-3" style={{ color: "#999999"}}>which equals</h3>
										</div>
										<div style={{ width: '200px', float: "left", margin: "5px" }}>
											<div id="calculateddose4">/</div>
										</div>
										<div style={{ width: '225px', float: "left", margin: "5px" }}>
											<h3 className="mt-3" style={{ color: "#999999"}}>per treatment.</h3>
										</div>
										</Fragment>
									)}
								</div>
							}
							{windowWidth < 1001 &&
								<div style={{ width: '100%' ,justifyContent: "center", alignItems: "left" }}>
									<div style={{ width: '120px', float: "left", margin: "5px" }}>
										<div id="calculatedtreatments1">/</div>
									</div>
									<div style={{ width: '400px', float: "left", margin: "5px" }}>
										<h3 className="mt-3" style={{ color: "#999999"}}>Treatments. Set Gemini EVO to </h3>
									</div>
									<div style={{ width: '225px', float: "left", margin: "5px" }}>
										<output name="timeOutputName1" id="calculatedtime3">/</output>
									</div>
									{adapterValue == "3mm" ? (
										<div style={{ width: '100%', float: "left", margin: "5px" }}>
											<h3 className="mt-3" style={{ color: "#999999"}}>using a scanning motion around the treatment area.</h3>
										</div>
									) : (
										<Fragment>
										<div style={{ width: '200px', float: "left", margin: "5px" }}>
											<h3 className="mt-3" style={{ color: "#999999"}}>which equals</h3>
										</div>
										<div style={{ width: '225px', float: "left", margin: "5px" }}>
											<div id="calculateddose4">/</div>
										</div>
										<div style={{ width: '225px', float: "left", margin: "5px" }}>
											<h3 className="mt-3" style={{ color: "#999999"}}>per treatment.</h3>
										</div>
										</Fragment>
									)}
								</div>
							}
							{
								// <div style={{ flexDirection: 'row', width: '100%', flex:1 ,justifyContent: "center", alignItems: "center" }}>
								// 	<Row style={{ width: '120px', display: "block"}}>
								// 		<div id="calculatedtreatments1">/</div>
								// 	</Row>
								// 	<Row style={{ width: '400px', display: "block"}}>
								// 		<h3 className="mt-3" style={{ color: "#999999"}}>Treatments. Set Gemini EVO to </h3>
								// 	</Row>
								// 	<Row style={{ width: '225px', display: "block"}}>
								// 		<output name="timeOutputName1" id="calculatedtime3">/</output>
								// 	</Row>
								// 	{adapterValue == "3mm" ? (
								// 		<Fragment>
								// 			<Row style={{ width: '600px', display: "block"}}>
								// 				<h3 className="mt-3" style={{ color: "#999999"}}>using a scanning motion around the treatment area.</h3>
								// 			</Row>
								// 		</Fragment>
								// 	) : (
								// 		<Fragment>
								// 			<Row style={{ width: '200px', display: "block"}}>
								// 				<h3 className="mt-3" style={{ color: "#999999"}}>which equals</h3>
								// 			</Row>
								// 			<Row style={{ width: '225px', display: "block"}}>
								// 				<div id="calculateddose4">/</div>
								// 			</Row>
								// 			<Row style={{ width: '225px', display: "block"}}>
								// 				<h3 className="mt-3" style={{ color: "#999999"}}>per treatment.</h3>
								// 			</Row>
								// 		</Fragment>
								// 	)}
								// </div>
							}
							<hr/>
						</div>

						<p id="bottomtext">Note: All calculations are made in accordance to the factory preset average power settings for each PBM Adapter.</p>
					</div>
          </Fragment>
        </CardBody>
				<div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} centered={true} contentClassName={windowWidth>1000 ? "custom-modal-style" : "custom-modal-style-small"} onOpened={this.afterOpenModal} onClosed={this.closeModal}>
    				<ModalHeader toggle={this.toggle}>Select Treatment Targets</ModalHeader>
    				<ModalBody>
						{this.state.typeState == 0 &&
							<Fragment>
							{this.state.sideState == 0 &&
								<Fragment>
									<ReactImageDot
										backgroundImageUrl={intraOralLeft}
										width={windowWidth>1000 ? 700 : 500}
										height={windowWidth>1000 ? 700 : 500}
										dots={dots}
										deleteDot={this.deleteDot}
										addDot={this.addDot}
										dotRadius={this.state.tipSize}
										dotStyles={{
											backgroundColor: 'red',
											boxShadow: '0 2px 4px gray',
										}}
										backgroundSize={'contain'}
									/>
									<Button color='dark' onClick={this.resetDots}>Reset</Button>
								</Fragment>
							}
							{this.state.sideState == 1 &&
								<Fragment>
									<ReactImageDot
										backgroundImageUrl={intraOralRight}
										width={windowWidth>1000 ? 700 : 500}
										height={windowWidth>1000 ? 700 : 500}
										dots={dots2}
										deleteDot={this.deleteDot2}
										addDot={this.addDot2}
										dotRadius={this.state.tipSize}
										dotStyles={{
											backgroundColor: 'red',
											boxShadow: '0 2px 4px gray',
										}}
										backgroundSize={'contain'}
									/>
									<Button color='dark' onClick={this.resetDots2}>Reset</Button>
								</Fragment>
							}
							</Fragment>
						}
						{this.state.typeState == 1 &&
							<Fragment>
							{this.state.sideState == 0 &&
								<Fragment>
									<ReactImageDot
										backgroundImageUrl={extraOralLeft}
										width={windowWidth>1000 ? 700 : 500}
										height={windowWidth>1000 ? 700 : 500}
										dots={dots3}
										deleteDot={this.deleteDot3}
										addDot={this.addDot3}
										dotRadius={this.state.tipSize}
										dotStyles={{
											backgroundColor: 'red',
											boxShadow: '0 2px 4px gray',
										}}
										backgroundSize={'contain'}
									/>
									<Button color='dark' onClick={this.resetDots3}>Reset</Button>
								</Fragment>
							}
							{this.state.sideState == 1 &&
								<Fragment>
									<ReactImageDot
										backgroundImageUrl={extraOralFront}
										width={windowWidth>1000 ? 700 : 500}
										height={windowWidth>1000 ? 700 : 500}
										dots={dots5}
										deleteDot={this.deleteDot5}
										addDot={this.addDot5}
										dotRadius={this.state.tipSize}
										dotStyles={{
											backgroundColor: 'red',
											boxShadow: '0 2px 4px gray',
										}}
										backgroundSize={'contain'}
									/>
									<Button color='dark' onClick={this.resetDots5}>Reset</Button>
								</Fragment>
							}
							{this.state.sideState == 2 &&
								<Fragment>
									<ReactImageDot
										backgroundImageUrl={extraOralRight}
										width={windowWidth>1000 ? 700 : 500}
										height={windowWidth>1000 ? 700 : 500}
										dots={dots4}
										deleteDot={this.deleteDot4}
										addDot={this.addDot4}
										dotRadius={this.state.tipSize}
										dotStyles={{
											backgroundColor: 'red',
											boxShadow: '0 2px 4px gray',
										}}
										backgroundSize={'contain'}
									/>
									<Button color='dark' onClick={this.resetDots4}>Reset</Button>
								</Fragment>
							}
							</Fragment>
						}
						<Button className="float-right ml-1 mr-1" color='dark' onClick={this.viewRightSkull}>
							<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-right-bold"/>
						</Button>
						<Button className="float-right ml-1 mr-1" color='dark' onClick={this.viewLeftSkull}>
							<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-left-bold"/>
						</Button>
						{this.state.tipSize!="25" &&
							<Fragment>
								<Button className="float-right ml-1 mr-1" color='dark' onClick={this.viewExtraOralSkullType}>Extra Oral</Button>
								<Button className="float-right ml-1 mr-1" color='dark' onClick={this.viewIntraOralSkullType}>Intra Oral</Button>
							</Fragment>
						}
    				</ModalBody>
  					<ModalFooter>
  						<Button color="primary" className="ml-1" onClick={this.toggle}>DONE</Button>
  					</ModalFooter>
  			  </Modal>
				</div>
				{
					// <Joyride callback={this.handleJoyrideCallback}
	        //   run={run}
	        //   stepIndex={stepIndex}
					// 	disableScrolling={true}
	        //   steps={steps}/>
					// <Joyride callback={this.handleJoyrideCallback}
		      //    run={runModal}
		      //    stepIndex={stepIndexModal}
		      //    steps={stepsModal}
					// 	 disableScrolling={true}
					// 	 styles={{
	        //     options: {
	        //       zIndex: 10000,
	        //     },
	        //   }}/>
				}
      </Card>
		);
	}
}

export default PBMCalculatorView;
