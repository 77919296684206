import React from 'react';
import indexRoutes from './routes/';
import { Router, Route, Switch, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { history } from './jwt/_helpers';
import { PrivateRoute } from './routes/PrivateRoutes';
import Blanklayout from './layouts/blanklayout';
import Mobilelayout from './layouts/mobilelayout';
import HttpsRedirect from 'react-https-redirect';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: null
        };

    }

    componentDidMount() {
      //this.forceHTTPS();
  	}

    forceHTTPS() {

      let location = window.location;

      //let testDomain = "localhost:3000";
      let domain1 = "azenadashboard-dev.herokuapp.com";
      let domain2 = "azenadashboard-staging.herokuapp.com";
      let domain3 = "dashboard.herokuapp.com";
      const host = window.location.host;

      // if ((testDomain === host) && (window.location.protocol !== "https:")){
      //   window.location.replace("https://google.com");
      // }
      if ((domain1 === host) && (window.location.protocol !== "https:")){
        window.location.replace("https://azenadashboard-dev.herokuapp.com"+location.pathname);
      }
      if ((domain2 === host) && (window.location.protocol !== "https:")){
        window.location.replace("https://azenadashboard-staging.herokuapp.com"+location.pathname);
      }
      if ((domain3 === host) && (window.location.protocol !== "https:")){
        window.location.replace("https://dashboard.herokuapp.com"+location.pathname);
      }
    }


    render() {

        return (
            <HttpsRedirect>
              <Provider store={configureStore()}>
                  <Router basename="/" history={history}>
                      <Switch>
                          <Route path="/authentication/login" component={Blanklayout} />;
                          {
                            //<Route path="/fpreplacement" component={Blanklayout} />;
                            //<Route path="/fpreplacementresults" component={Blanklayout} />;
                          }
                          <Route path="/mobile/mobile-dashboard" component={Mobilelayout} />;
                          <Route path="/mobile/mobile-vitals-dashboard" component={Mobilelayout} />;
                          <Route path="/mobile/warranty" component={Mobilelayout} />;
                          <Route path="/mobile/delete-account" component={Mobilelayout} />;
                          <Route path="/rejoy" component={Blanklayout} />;
                          <Route path="/heartbeat/full" component={Mobilelayout} />;

                          {indexRoutes.map((prop, key) => {
                              return <PrivateRoute path={prop.path} key={key} component={prop.component} />;
                          })
                        }
                      </Switch>
                  </Router>
              </Provider>
            </HttpsRedirect>
        )
    }
}

export default App;
