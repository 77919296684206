import React from "react";
import { Component, Fragment } from "react";
import {
	Progress,
	Card,
	CardBody,
  CardTitle,
	Input,
	Row,
	Col,
	Button,
	ButtonGroup,
	ListGroup,
	ListGroupItem,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ButtonDropdown,
	Tooltip
} from 'reactstrap';

import img1 from '../../../assets/images/background/active-bg.png';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Moment from 'moment';
var moment = require('moment-timezone');

var currency_list = [
		{"name":"US Dollar","code":"USD","symbol":"$"},
		{"name":"Argentine Peso","code":"ARS","symbol":"$"},
		{"name":"Argentine Peso","code":"ARS","symbol":"$"},
	    {"name":"Australian Dollar","code":"AUD","symbol":"$"},
	    {"name":"Belgian Franc","code":"BEF","symbol":"fr"},
	    {"name":"Bolivian Boliviano","code":"BOB","symbol":"Bs."},
	    {"name":"Brazilian Real","code":"BRL","symbol":"R$"},
	    {"name":"British Pound Sterling","code":"GBP","symbol":"£"},
     {"name":"Canadian Dollar","code":"CAD","symbol":"$"},
	     {"name":"Cayman Islands Dollar","code":"KYD","symbol":"$"},
	     {"name":"Chilean Peso","code":"CLP","symbol":"$"},
	     {"name":"Chinese Yuan","code":"CNY","symbol":"¥"},
	     {"name":"Colombian Peso","code":"COP","symbol":"$"},
	     {"name":"Dominican Peso","code":"DOP","symbol":"$"},
	     {"name":"Euro","code":"EUR","symbol":"€"},
	     {"name":"German Mark","code":"DEM","symbol":"DM"},
	     {"name":"Israeli New Sheqel","code":"ILS","symbol":"₪"},
	     {"name":"Italian Lira","code":"ITL","symbol":"L,£"},
	     {"name":"Japanese Yen","code":"JPY","symbol":"¥"},
	     {"name":"Jordanian Dinar","code":"JOD","symbol":"ا.د"},
	     {"name":"Kuwaiti Dinar","code":"KWD","symbol":"ك.د"},
	     {"name":"Macedonian Denar","code":"MKD","symbol":"ден"},
	     {"name":"Mexican Peso","code":"MXN","symbol":"$"},
	     {"name":"New Zealand Dollar","code":"NZD","symbol":"$"},
	     {"name":"Omani Rial","code":"OMR","symbol":".ع.ر"},
	     {"name":"Swiss Franc","code":"CHF","symbol":"CHf"},
	     {"name":"United Arab Emirates Dirham","code":"AED","symbol":"إ.د"}
];

class ReturnOnInvestment extends React.Component {

	noResults = true;
	intervalID = 0;

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 2,
			allProcedures: [],
			nonSurgicalProcedures: [],
			surgicalProcedures: [],
			painReliefProcedures: [],
			savedProcedures: [],
			colors: [{color:'warning'}, {color:'warning'}, {color:'success'}, {color:'info'}, {color:'teal'}, {color:'cyan'}],
			noDevices: false,
			noDataForDevices: false,
			totalSavings: 0,
			currentDevice: this.props.currentDevice,
			currentDataSetting: 0,
			currentDataSetting2: 0,
			startDate: Moment().subtract(3, 'year').format('YYYY-MM-DD'),
			endDate: Moment().format('YYYY-MM-DD'),
			currentDate: Moment().format('YYYY-MM-DD'),
			isOpen: false,
			isOpen2: false,
			currentItem: "Global",
			currentItem2: "All Devices",
			tooltipOpen: false,
			userCurrency: "USD",
			userCurrencySymbol: "$",
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
		this.toggleDropDown = this.toggleDropDown.bind(this);
		this.toggleDropDown2 = this.toggleDropDown2.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.toggleTooltip = this.toggleTooltip.bind(this);
  }

	async componentDidMount() {

		if (this.state.currentUser) {
			this.getUserCurrency();
			this.getDeviceList();
			this.getData(this.state.rSelected);
		} else {
			setTimeout(()=>{ this.getUserCurrency(); }, 1000);
			setTimeout(()=>{ this.getDeviceList(); }, 1000);
			setTimeout(()=>{ this.getData(this.state.rSelected); }, 1000);
		}

		this.intervalID = setInterval(()=>{ this.getData(this.state.rSelected); }, 600000);
	}

	getDeviceList = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"device-list";

		var token = "";

		if (this.state.currentUser) {
			token = this.state.currentUser.jwtToken;
		}

		if (this.props.authToken) {
			console.log("ReturnOnInvestment this.props.authToken: " + this.props.authToken);
			token = this.props.authToken;
		}

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: token
				},
			});

			const body = await response.json();
			console.log("all devices: " + JSON.stringify(body));

			if (body.status == 'Success') {
				if (body.response.length>0) {
					this.setState({ deviceList: body.response });
				}
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log("getDeviceList error: "+err);
		}

	};

	getUserCurrency = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-user-currency";

		var token = "";

		if (this.state.currentUser) {
			token = this.state.currentUser.jwtToken;
		}

		if (this.props.authToken) {
			console.log("ReturnOnInvestment this.props.authToken: " + this.props.authToken);
			token = this.props.authToken;
		}

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: token
				},
			});

			const body = await response.json();
			console.log("getUserCurrency response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				if (body.results.length>0) {
					let currency = currency_list.find(c => c.code === body.results[0].Currency);
					this.setState({ userCurrency: body.results[0].Currency, userCurrencySymbol: currency.symbol})
				} else {
					let currency = currency_list.find(c => c.code === 'USD');
					this.setState({ userCurrency: 'USD', userCurrencySymbol: currency.symbol})
				}

			} else {

			}

		}
		catch (err) {
			console.log(err);
		}

	};

	toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

	toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	toggleDropDown2() {
    this.setState({
      isOpen2: !this.state.isOpen2
    });
  }

	handleChange(event) {
		if (event.target.getAttribute('name') == 'startDate') {
			this.setState({startDate: event.target.value});
			this.setState({
	      currentItem: "Custom Range",
				currentDataSetting: 3
	    });
		} else if (event.target.getAttribute('name') == 'endDate') {
			//console.log('this.state.code: ' + event.target.value);
			this.setState({endDate: event.target.value});
			this.setState({
				currentItem: "Custom Range",
				currentDataSetting: 3
	    });
		}

		setTimeout(()=>{ this.getData(this.state.rSelected); }, 1000);
  }

	handleSubmit(event) {

		this.getData(this.state.rSelected);
  }

	selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		if (id==1) {
			this.setState({ startDate: Moment().subtract(1, 'week').format('YYYY-MM-DD') });
		} else if (id==2) {
			this.setState({ startDate: Moment().subtract(1, 'month').format('YYYY-MM-DD') });
		} else if (id==3) {
			this.setState({ startDate: Moment().format('YYYY-MM-DD') });
		}

		this.setState({ endDate: Moment().format('YYYY-MM-DD') });

		setTimeout(()=>{ this.getData(this.state.rSelected); }, 1000);
  }

	selectDropDownItem2(id, title) {

    this.setState({
      currentItem2: title,
			currentDataSetting2: id
    });

		setTimeout(()=>{ this.getData(this.state.rSelected); }, 1000);
  }

	stepBack () {
		if (this.state.currentDataSetting==1) {
			let revisedStartDate = Moment(this.state.startDate).subtract(1, 'week').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).subtract(1, 'week').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		} else if (this.state.currentDataSetting==2) {
			let revisedStartDate = Moment(this.state.startDate).subtract(1, 'month').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).subtract(1, 'month').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		}

		setTimeout(()=>{ this.getData(this.state.rSelected); }, 1000);
	}

	stepForward () {
		if (this.state.currentDataSetting==1) {
			let revisedStartDate = Moment(this.state.startDate).add(1, 'week').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).add(1, 'week').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		} else if (this.state.currentDataSetting==2) {
			let revisedStartDate = Moment(this.state.startDate).add(1, 'month').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).add(1, 'month').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		}

		setTimeout(()=>{ this.getData(this.state.rSelected); }, 1000);
	}

	componentWillUnmount() {
		//console.log("ROI componentWillUnmount");
		clearInterval(this.intervalID);
	}

	async onRadioBtnClick(rSelected) {
		await this.setState({ rSelected });
		//await this.getData(rSelected);
	}

	getData = async category => {

		var timezone = Moment.tz.guess();

		if (this.state.currentDataSetting==0) {

			if (this.state.currentDataSetting2==0) {
				var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"return-on-investment-for-user-devices";

				var token = "";

				if (this.state.currentUser) {
					token = this.state.currentUser.jwtToken;
				}

				if (this.props.authToken) {
					console.log("ReturnOnInvestment this.props.authToken: " + this.props.authToken);
					token = this.props.authToken;
				}

				try {

					const response = await fetch(url, {
						method: 'GET',
						headers: {
							Authorization: token
						},
					});

					const body = await response.json();
					console.log("returnoninvestmentforuserdevices response: " + JSON.stringify(body));

					if (body.status == 'Success') {

						//let totalSavings = (body.results.reduce((grandTotal,procedure) =>  grandTotal = grandTotal + (procedure.total*procedure.Price) , 0 ));
						let totalSavings = (body.results.reduce((grandTotal,procedure) =>  grandTotal = grandTotal + procedure.Amount , 0 ));
						this.setState({ totalSavings })
						console.log("Total response: "+totalSavings);

						if (body.results.length>0) {
							this.setState({ allProcedures: body.results }, () => console.log("allProcedures: "+this.state.allProcedures));

							var nonSurgical = body.results.filter(function (procedure) {
								return procedure.CategoryID === 1;
							});
							this.setState({ nonSurgicalProcedures: nonSurgical }, () => console.log("nonSurgicalProcedures: "+JSON.stringify(this.state.nonSurgicalProcedures)));

							var surgical = body.results.filter(function (procedure) {
								return procedure.CategoryID === 2;
							});
							this.setState({ surgicalProcedures: surgical }, () => console.log("surgicalProcedures: "+this.state.surgicalProcedures));

							var painRelief = body.results.filter(function (procedure) {
								return procedure.CategoryID === 3;
							});
							this.setState({ painReliefProcedures: painRelief }, () => console.log("painReliefProcedures: "+this.state.painReliefProcedures));

							this.setState({
								noDataForDevices: false
					    });
						} else {
							this.setState({
								noDataForDevices: true
					    });
						}
					} else {
						this.setState({
							noDataForDevices: true
						});
					}

				}
				catch (err) {
					console.log(err);
				}

			} else {
				var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"return-on-investment-for-user-device?SerialNumber="+this.state.currentItem2;

				var token = "";

				if (this.state.currentUser) {
					token = this.state.currentUser.jwtToken;
				}

				if (this.props.authToken) {
					console.log("ReturnOnInvestment this.props.authToken: " + this.props.authToken);
					token = this.props.authToken;
				}

				try {

					const response = await fetch(url, {
						method: 'GET',
						headers: {
							Authorization: token
						},
					});

					const body = await response.json();
					console.log("returnoninvestmentforuserdevice response: " + JSON.stringify(body));

					if (body.status == 'Success') {

						//let totalSavings = (body.results.reduce((grandTotal,procedure) =>  grandTotal = grandTotal + (procedure.total*procedure.Price) , 0 ));
						let totalSavings = (body.results.reduce((grandTotal,procedure) =>  grandTotal = grandTotal + procedure.Amount , 0 ));
						this.setState({ totalSavings })
						console.log("Total response: "+totalSavings);

						if (body.results.length>0) {
							this.setState({ allProcedures: body.results }, () => console.log("allProcedures: "+this.state.allProcedures));

							var nonSurgical = body.results.filter(function (procedure) {
								return procedure.CategoryID === 1;
							});
							this.setState({ nonSurgicalProcedures: nonSurgical }, () => console.log("nonSurgicalProcedures: "+JSON.stringify(this.state.nonSurgicalProcedures)));

							var surgical = body.results.filter(function (procedure) {
								return procedure.CategoryID === 2;
							});
							this.setState({ surgicalProcedures: surgical }, () => console.log("surgicalProcedures: "+this.state.surgicalProcedures));

							var painRelief = body.results.filter(function (procedure) {
								return procedure.CategoryID === 3;
							});
							this.setState({ painReliefProcedures: painRelief }, () => console.log("painReliefProcedures: "+this.state.painReliefProcedures));

							this.setState({
								noDataForDevices: false
					    });
						} else {
							this.setState({
								noDataForDevices: true
					    });
						}
					} else {
						this.setState({
							noDataForDevices: true
						});
					}

				}
				catch (err) {
					console.log(err);
				}

			}

		} else {

			if (this.state.currentDataSetting2==0) {
				var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"return-on-investment-for-user-devices-date-range?StartDate="+this.state.startDate+"&EndDate="+this.state.endDate+"&Timezone="+timezone;

				var token = "";

				if (this.state.currentUser) {
					token = this.state.currentUser.jwtToken;
				}

				if (this.props.authToken) {
					console.log("ReturnOnInvestment this.props.authToken: " + this.props.authToken);
					token = this.props.authToken;
				}

				try {

					const response = await fetch(url, {
						method: 'GET',
						headers: {
							Authorization: token
						},
					});

					const body = await response.json();
					console.log("returnoninvestmentforuserdevices date range response: " + JSON.stringify(body));

					if (body.status == 'Success') {

						//let totalSavings = (body.results.reduce((grandTotal,procedure) =>  grandTotal = grandTotal + (procedure.total*procedure.Price) , 0 ));
						let totalSavings = (body.results.reduce((grandTotal,procedure) =>  grandTotal = grandTotal + procedure.Amount , 0 ));
						this.setState({ totalSavings })
						console.log("Total response: "+totalSavings);

						if (body.results.length>0) {
							this.setState({ allProcedures: body.results }, () => console.log("allProcedures: "+this.state.allProcedures));

							var nonSurgical = body.results.filter(function (procedure) {
								return procedure.CategoryID === 1;
							});
							this.setState({ nonSurgicalProcedures: nonSurgical }, () => console.log("nonSurgicalProcedures: "+this.state.nonSurgicalProcedures));

							var surgical = body.results.filter(function (procedure) {
								return procedure.CategoryID === 2;
							});
							this.setState({ surgicalProcedures: surgical }, () => console.log("surgicalProcedures: "+this.state.surgicalProcedures));

							var painRelief = body.results.filter(function (procedure) {
								return procedure.CategoryID === 3;
							});
							this.setState({ painReliefProcedures: painRelief }, () => console.log("painReliefProcedures: "+this.state.painReliefProcedures));

							this.setState({
								noDataForDevices: false
							});
						} else {
							this.setState({
								noDataForDevices: true
							});
						}
					} else {
						this.setState({
							noDataForDevices: true
						});
					}

				}
				catch (err) {
					console.log(err);
				}

			} else {
				var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"return-on-investment-for-user-device-date-range?SerialNumber="+this.state.currentItem2+"&StartDate="+this.state.startDate+"&EndDate="+this.state.endDate+"&Timezone="+timezone;

				var token = "";

				if (this.state.currentUser) {
					token = this.state.currentUser.jwtToken;
				}

				if (this.props.authToken) {
					console.log("ReturnOnInvestment this.props.authToken: " + this.props.authToken);
					token = this.props.authToken;
				}

				try {

					const response = await fetch(url, {
						method: 'GET',
						headers: {
							Authorization: token
						},
					});

					const body = await response.json();
					console.log("returnoninvestmentforuserdevice date range response: " + JSON.stringify(body));

					if (body.status == 'Success') {

						//let totalSavings = (body.results.reduce((grandTotal,procedure) =>  grandTotal = grandTotal + (procedure.total*procedure.Price) , 0 ));
						let totalSavings = (body.results.reduce((grandTotal,procedure) =>  grandTotal = grandTotal + procedure.Amount , 0 ));
						this.setState({ totalSavings })
						console.log("Total response: "+totalSavings);

						if (body.results.length>0) {
							this.setState({ allProcedures: body.results }, () => console.log("allProcedures: "+this.state.allProcedures));

							var nonSurgical = body.results.filter(function (procedure) {
								return procedure.CategoryID === 1;
							});
							this.setState({ nonSurgicalProcedures: nonSurgical }, () => console.log("nonSurgicalProcedures: "+this.state.nonSurgicalProcedures));

							var surgical = body.results.filter(function (procedure) {
								return procedure.CategoryID === 2;
							});
							this.setState({ surgicalProcedures: surgical }, () => console.log("surgicalProcedures: "+this.state.surgicalProcedures));

							var painRelief = body.results.filter(function (procedure) {
								return procedure.CategoryID === 3;
							});
							this.setState({ painReliefProcedures: painRelief }, () => console.log("painReliefProcedures: "+this.state.painReliefProcedures));

							this.setState({
								noDataForDevices: false
							});
						} else {
							this.setState({
								noDataForDevices: true
							});
						}
					} else {
						this.setState({
							noDataForDevices: true
						});
					}

				}
				catch (err) {
					console.log(err);
				}

			}

		}

	};

	getColor(categoryID) {

		var color = "success";

		if (categoryID==1) {
			color = "success";
		} else if (categoryID==2) {
			color = "info";
		} else if (categoryID==3) {
			color = "yellow";
		}

		return color;

	}

	getBarValue(procedure) {

		var barValue = 0;

		var total = parseInt(procedure.total);
		var price = parseInt(procedure.Price);
		var goal = parseInt(procedure.Goal);

		var totalRevenue = procedure.Amount;
		barValue = totalRevenue/goal;

		console.log("BarValue: "+barValue);

		return barValue*100;

	}

	getGoalText(procedure) {

		var color = "success";

		return color;

	}

	render() {
		return (
			/*--------------------------------------------------------------------------------*/
			/* Used In Dashboard-1 [Classic]                                                  */
			/*--------------------------------------------------------------------------------*/
			<Card className="card-hover">
				<CardBody style={{ minHeight: 650 }}>
					<Row>
						<Col xs="6" className="text-left">
							<CardTitle>Revenue Generated
							<i style={{color: '#ffffff'}} className="mdi mdi-information-outline ml-1" id="RevenueGeneratedTooltip"/>
							<Tooltip placement="top" isOpen={this.state.tooltipOpen} target="RevenueGeneratedTooltip" toggle={this.toggleTooltip}>
								View revenue generated by procedure. Requires initial designation of fee charged by procedure under Your Devices The Name of Your Laser Unit Customize.
							</Tooltip>
							</CardTitle>
						</Col>
						<Col xs="6" className="text-right">
							<div>
							<ButtonDropdown size="sm" className="ml-1 mr-1" isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
								<DropdownToggle color="dark" caret>
									{this.state.currentItem}
								</DropdownToggle>
								<DropdownMenu right>
									<DropdownItem onClick={() => this.selectDropDownItem(0, 'Global')}>Global</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(1, 'By Week')}>By Week</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(2, 'By Month')}>By Month</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(3, 'Custom Range')}>Custom Range</DropdownItem>
								</DropdownMenu>
							</ButtonDropdown>
							{this.state.deviceList &&
								<ButtonDropdown size="sm" isOpen={this.state.isOpen2} toggle={this.toggleDropDown2}>
									<DropdownToggle color="dark" caret>
										{this.state.currentItem2}
									</DropdownToggle>
									<DropdownMenu right>
										<DropdownItem onClick={() => this.selectDropDownItem2(0, 'All Devices')}>All Devices</DropdownItem>
										{this.state.deviceList.map((device, i) => (
											<DropdownItem onClick={() => this.selectDropDownItem2(i+1, device.SerialNumber)}>{device.SerialNumber}</DropdownItem>
										))}
									</DropdownMenu>
								</ButtonDropdown>
							}
							</div>
						</Col>
					</Row>
					<Row className="pt-1">
						<Col xs="12" md="12" lg="12">
							<div className="mt-1 mb-1">
								<ButtonGroup style={{ width: '100%' }}>
									<Button
										style={{width: '100%'}}
										color="success"
										onClick={() => this.onRadioBtnClick(1)}
										active={this.state.rSelected === 1}
									>
										Non Surgical
									</Button>
									<Button
										color="info"
										style={{width: '100%'}}
										onClick={() => this.onRadioBtnClick(2)}
										active={this.state.rSelected === 2}
									>
										Surgical
									</Button>
									<Button
										color="yellow"
										style={{width: '100%'}}
										onClick={() => this.onRadioBtnClick(3)}
										active={this.state.rSelected === 3}
									>
										Pain Relief
									</Button>
								</ButtonGroup>
							</div>
						</Col>
					</Row>
					<Row>
						<Col xs="12" md="12" lg="12" className="text-center mt-1">
							{!this.state.noDevices && !this.state.noDataForDevices &&
								<CardTitle style={{color:'#ffc10e'}}>Total: {this.state.userCurrencySymbol}{this.state.totalSavings.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</CardTitle>
							}
						</Col>
					</Row>
					{this.state.noDevices || this.state.noDataForDevices &&
						<div className="mt-5 text-center">
						{!this.state.noDataForDevices &&
							<h5>User has no devices</h5>
						}
						{this.state.noDataForDevices &&
							<h5>No data for user's devices</h5>
						}
						</div>
					}
					{!this.state.noDevices && !this.state.noDataForDevices &&
						<div className="" style={{height: '412px'}}>
							{this.state.currentDataSetting == 0 || this.state.currentDataSetting == 3 ? (
								<Fragment>
								{this.state.rSelected === 1 &&
									<Fragment>
									{this.state.nonSurgicalProcedures.map((procedure, i) => (
										<Fragment>
										<Row key={i} className={i>0 ? "mt-3" : ""}>
											<Col xs="12">
												<div className="text-left" style={{ width: "30%", float: "left" }}>
													{procedure.Amount > 0 ? (
														<span style={{ width: "50px" }}>{this.state.userCurrencySymbol}{(procedure.Amount).toLocaleString(navigator.language, { minimumFractionDigits: 0 })} earned</span>
													) : (
														<span style={{ width: "50px" }}>{this.state.userCurrencySymbol}{(0).toLocaleString(navigator.language, { minimumFractionDigits: 0 })} earned</span>
													)}
												</div>
												<div className="text-center" style={{ width: "40%", float: "left" }}>
													{procedure.total > 0 && procedure.Price > 0 ? (
														<span>({procedure.Name})</span>
													) : (
														<span>({procedure.Name})</span>
													)}
												</div>
												<div className="text-right" style={{ width: "30%", float: "left" }}>
													{procedure.Amount > 0 ? (
														<span>{procedure.Goal && procedure.Goal!=0 ? "Goal "+this.state.userCurrencySymbol+(procedure.Goal).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : "No Goal"}</span>
													) : (
														<span>{procedure.Goal && procedure.Goal!=0 ? "Goal "+this.state.userCurrencySymbol+(procedure.Goal).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : "No Goal"}</span>
													)}
												</div>
											</Col>
										</Row>
										{procedure.Goal && procedure.Goal!=0 && procedure.Goal!="" && procedure.total>0 ? (
											<Fragment>
												<Row>
													<Col xs="12" md="12" lg="12" className="text-right">
														<Progress multi className="mt-1 float-left" style={{ height: "10px", width: "100%"}}>
															<Progress bar color={this.getColor(procedure.CategoryID)} value={this.getBarValue(procedure)} />
														</Progress>
														{
															//<span className="float-right" style={{color:'#ffc10e', width: "15%"}}>{this.state.userCurrencySymbol}{procedure.Goal}</span>
														}
													</Col>
												</Row>
											</Fragment>
										) : (
											<Fragment>
											</Fragment>
										)}
										</Fragment>
									))}
									</Fragment>
								}
								{this.state.rSelected === 2 &&
									<Fragment>
									{this.state.surgicalProcedures.map((procedure, i) => (
										<Fragment>
										<Row key={i} className={i>0 ? "mt-3" : ""}>
											<Col xs="12">
												<div className="text-left" style={{ width: "30%", float: "left" }}>
													{procedure.Amount > 0 ? (
														<span style={{ width: "50px" }}>{this.state.userCurrencySymbol}{(procedure.Amount).toLocaleString(navigator.language, { minimumFractionDigits: 0 })} earned</span>
													) : (
														<span style={{ width: "50px" }}>{this.state.userCurrencySymbol}{(0).toLocaleString(navigator.language, { minimumFractionDigits: 0 })} earned</span>
													)}
												</div>
												<div className="text-center" style={{ width: "40%", float: "left" }}>
													{procedure.total > 0 && procedure.Price > 0 ? (
														<span>({procedure.Name})</span>
													) : (
														<span>({procedure.Name})</span>
													)}
												</div>
												<div className="text-right" style={{ width: "30%", float: "left" }}>
													{procedure.Amount > 0 ? (
														<span>{procedure.Goal && procedure.Goal!=0 ? "Goal "+this.state.userCurrencySymbol+(procedure.Goal).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : "No Goal"}</span>
													) : (
														<span>{procedure.Goal && procedure.Goal!=0 ? "Goal "+this.state.userCurrencySymbol+(procedure.Goal).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : "No Goal"}</span>
													)}
												</div>
											</Col>
										</Row>
										{procedure.Goal && procedure.Goal!=0 && procedure.Goal!="" && procedure.total>0 ? (
											<Fragment>
												<Row>
													<Col xs="12" md="12" lg="12" className="text-right">
														<Progress multi className="mt-1 float-left" style={{ height: "10px", width: "100%"}}>
															<Progress bar color={this.getColor(procedure.CategoryID)} value={this.getBarValue(procedure)} />
														</Progress>
														{
															//<span className="float-right" style={{color:'#ffc10e', width: "15%"}}>{this.state.userCurrencySymbol}{procedure.Goal}</span>
														}
													</Col>
												</Row>
											</Fragment>
										) : (
											<Fragment>
											</Fragment>
										)}
										</Fragment>
									))}
									</Fragment>
								}
								{this.state.rSelected === 3 &&
									<Fragment>
									{this.state.painReliefProcedures.map((procedure, i) => (
										<Fragment>
										<Row key={i} className={i>0 ? "mt-3" : ""}>
											<Col xs="12">
												<div className="text-left" style={{ width: "30%", float: "left" }}>
													{procedure.Amount > 0 ? (
														<span style={{ width: "50px" }}>{this.state.userCurrencySymbol}{(procedure.Amount).toLocaleString(navigator.language, { minimumFractionDigits: 0 })} earned</span>
													) : (
														<span style={{ width: "50px" }}>{this.state.userCurrencySymbol}{(0).toLocaleString(navigator.language, { minimumFractionDigits: 0 })} earned</span>
													)}
												</div>
												<div className="text-center" style={{ width: "40%", float: "left" }}>
													{procedure.total > 0 && procedure.Price > 0 ? (
														<span>({procedure.Name})</span>
													) : (
														<span>({procedure.Name})</span>
													)}
												</div>
												<div className="text-right" style={{ width: "30%", float: "left" }}>
													{procedure.Amount > 0 ? (
														<span>{procedure.Goal && procedure.Goal!=0 ? "Goal "+this.state.userCurrencySymbol+(procedure.Goal).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : "No Goal"}</span>
													) : (
														<span>{procedure.Goal && procedure.Goal!=0 ? "Goal "+this.state.userCurrencySymbol+(procedure.Goal).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : "No Goal"}</span>
													)}
												</div>
											</Col>
										</Row>
										{procedure.Goal && procedure.Goal!=0 && procedure.Goal!="" && procedure.total>0 ? (
											<Fragment>
												<Row>
													<Col xs="12" md="12" lg="12" className="text-right">
														<Progress multi className="mt-1 float-left" style={{ height: "10px", width: "100%"}}>
															<Progress bar color={this.getColor(procedure.CategoryID)} value={this.getBarValue(procedure)} />
														</Progress>
														{
															//<span className="float-right" style={{color:'#ffc10e', width: "15%"}}>{this.state.userCurrencySymbol}{procedure.Goal}</span>
														}
													</Col>
												</Row>
											</Fragment>
										) : (
											<Fragment>
											</Fragment>
										)}
										</Fragment>
									))}
									</Fragment>
								}
								</Fragment>
							) : (
								<Fragment>
								{this.state.rSelected === 1 &&
									<Fragment>
									<Row className="align-items-center">
										<div style={{ width: '5%', margin: '0 auto', height: '100%' }}>
											<Button size="sm" color="link" onClick={() => this.stepBack()} title="Step Back">
												<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-left mdi-24px"/>
											</Button>
										</div>
										<div style={{ width: '80%', margin: '0 auto', height: '100%' }}>
											<div className="ml-4 mt-1">
													{this.state.noDataForDevices ? (
														<div className="d-flex justify-content-center align-items-center" style={{ height: '100%'}}>
															<h5>No data for user's devices</h5>
														</div>
													) : (
														<div className="">
														{this.state.nonSurgicalProcedures.map((procedure, i) => (
															<Fragment>
															<Row key={i} className={i>0 ? "mt-3" : ""}>
																<Col xs="12">
																	<div className="text-left" style={{ width: "30%", float: "left" }}>
																		{procedure.Amount > 0 ? (
																			<span style={{ width: "50px" }}>{this.state.userCurrencySymbol}{(procedure.Amount).toLocaleString(navigator.language, { minimumFractionDigits: 0 })} earned</span>
																		) : (
																			<span style={{ width: "50px" }}>{this.state.userCurrencySymbol}{(0).toLocaleString(navigator.language, { minimumFractionDigits: 0 })} earned</span>
																		)}
																	</div>
																	<div className="text-center" style={{ width: "40%", float: "left" }}>
																		{procedure.total > 0 && procedure.Price > 0 ? (
																			<span>({procedure.Name})</span>
																		) : (
																			<span>({procedure.Name})</span>
																		)}
																	</div>
																	<div className="text-right" style={{ width: "30%", float: "left" }}>
																		{procedure.Amount > 0 ? (
																			<span>{procedure.Goal && procedure.Goal!=0 ? "Goal "+this.state.userCurrencySymbol+(procedure.Goal).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : "No Goal"}</span>
																		) : (
																			<span>{procedure.Goal && procedure.Goal!=0 ? "Goal "+this.state.userCurrencySymbol+(procedure.Goal).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : "No Goal"}</span>
																		)}
																	</div>
																</Col>
															</Row>
															{procedure.Goal && procedure.Goal!=0 && procedure.Goal!="" && procedure.total>0 ? (
																<Fragment>
																	<Row>
																		<Col xs="12" md="12" lg="12" className="text-right">
																			<Progress multi className="mt-1 float-left" style={{ height: "10px", width: "100%"}}>
																				<Progress bar color={this.getColor(procedure.CategoryID)} value={this.getBarValue(procedure)} />
																			</Progress>
																			{
																				//<span className="float-right" style={{color:'#ffc10e', width: "15%"}}>{this.state.userCurrencySymbol}{procedure.Goal}</span>
																			}
																		</Col>
																	</Row>
																</Fragment>
															) : (
																<Fragment>
																</Fragment>
															)}
															</Fragment>
														))}
														</div>
													)}
											</div>
										</div>
										<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
											<Button size="sm" color="link" disabled={this.state.currentDate==this.state.endDate} onClick={() => this.stepForward()} title="Step Forward">
												<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-right mdi-24px"/>
											</Button>
										</div>
									</Row>
									</Fragment>
								}
								{this.state.rSelected === 2 &&
									<Fragment>
									<Row className="align-items-center">
										<div style={{ width: '5%', margin: '0 auto', height: '100%' }}>
											<Button size="sm" color="link" onClick={() => this.stepBack()} title="Step Back">
												<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-left mdi-24px"/>
											</Button>
										</div>
										<div style={{ width: '80%', margin: '0 auto', height: '100%' }}>
											<div className="ml-4 mt-1">
													{this.state.noDataForDevices ? (
														<div className="d-flex justify-content-center align-items-center" style={{ height: '100%'}}>
															<h5>No data for user's devices</h5>
														</div>
													) : (
														<div className="">
														{this.state.surgicalProcedures.map((procedure, i) => (
															<Fragment>
															<Row key={i} className={i>0 ? "mt-3" : ""}>
																<Col xs="12">
																	<div className="text-left" style={{ width: "30%", float: "left" }}>
																		{procedure.Amount > 0 ? (
																			<span style={{ width: "50px" }}>{this.state.userCurrencySymbol}{(procedure.Amount).toLocaleString(navigator.language, { minimumFractionDigits: 0 })} earned</span>
																		) : (
																			<span style={{ width: "50px" }}>{this.state.userCurrencySymbol}{(0).toLocaleString(navigator.language, { minimumFractionDigits: 0 })} earned</span>
																		)}
																	</div>
																	<div className="text-center" style={{ width: "40%", float: "left" }}>
																		{procedure.total > 0 && procedure.Price > 0 ? (
																			<span>({procedure.Name})</span>
																		) : (
																			<span>({procedure.Name})</span>
																		)}
																	</div>
																	<div className="text-right" style={{ width: "30%", float: "left" }}>
																		{procedure.Amount > 0 ? (
																			<span>{procedure.Goal && procedure.Goal!=0 ? "Goal "+this.state.userCurrencySymbol+(procedure.Goal).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : "No Goal"}</span>
																		) : (
																			<span>{procedure.Goal && procedure.Goal!=0 ? "Goal "+this.state.userCurrencySymbol+(procedure.Goal).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : "No Goal"}</span>
																		)}
																	</div>
																</Col>
															</Row>
															{procedure.Goal && procedure.Goal!=0 && procedure.Goal!="" && procedure.total>0 ? (
																<Fragment>
																	<Row>
																		<Col xs="12" md="12" lg="12" className="text-right">
																			<Progress multi className="mt-1 float-left" style={{ height: "10px", width: "100%"}}>
																				<Progress bar color={this.getColor(procedure.CategoryID)} value={this.getBarValue(procedure)} />
																			</Progress>
																			{
																				//<span className="float-right" style={{color:'#ffc10e', width: "15%"}}>{this.state.userCurrencySymbol}{procedure.Goal}</span>
																			}
																		</Col>
																	</Row>
																</Fragment>
															) : (
																<Fragment>
																</Fragment>
															)}
															</Fragment>
											      ))}
														</div>
													)}
											</div>
										</div>
										<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
											<Button size="sm" color="link" disabled={this.state.currentDate==this.state.endDate} onClick={() => this.stepForward()} title="Step Forward">
												<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-right mdi-24px"/>
											</Button>
										</div>
									</Row>
									</Fragment>
								}
								{this.state.rSelected === 3 &&
									<Fragment>
									<Row className="align-items-center">
										<div style={{ width: '5%', margin: '0 auto', height: '100%' }}>
											<Button size="sm" color="link" onClick={() => this.stepBack()} title="Step Back">
												<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-left mdi-24px"/>
											</Button>
										</div>
										<div style={{ width: '80%', margin: '0 auto', height: '100%' }}>
											<div className="ml-4 mt-1">
													{this.state.noDataForDevices ? (
														<div className="d-flex justify-content-center align-items-center" style={{ height: '100%'}}>
															<h5>No data for user's devices</h5>
														</div>
													) : (
														<div className="">
														{this.state.painReliefProcedures.map((procedure, i) => (
															<Fragment>
															<Row key={i} className={i>0 ? "mt-3" : ""}>
																<Col xs="12">
																	<div className="text-left" style={{ width: "30%", float: "left" }}>
																		{procedure.Amount > 0 ? (
																			<span style={{ width: "50px" }}>{this.state.userCurrencySymbol}{(procedure.Amount).toLocaleString(navigator.language, { minimumFractionDigits: 0 })} earned</span>
																		) : (
																			<span style={{ width: "50px" }}>{this.state.userCurrencySymbol}{(0).toLocaleString(navigator.language, { minimumFractionDigits: 0 })} earned</span>
																		)}
																	</div>
																	<div className="text-center" style={{ width: "40%", float: "left" }}>
																		{procedure.total > 0 && procedure.Price > 0 ? (
																			<span>({procedure.Name})</span>
																		) : (
																			<span>({procedure.Name})</span>
																		)}
																	</div>
																	<div className="text-right" style={{ width: "30%", float: "left" }}>
																		{procedure.Amount > 0 ? (
																			<span>{procedure.Goal && procedure.Goal!=0 ? "Goal "+this.state.userCurrencySymbol+(procedure.Goal).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : "No Goal"}</span>
																		) : (
																			<span>{procedure.Goal && procedure.Goal!=0 ? "Goal "+this.state.userCurrencySymbol+(procedure.Goal).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : "No Goal"}</span>
																		)}
																	</div>
																</Col>
															</Row>
															{procedure.Goal && procedure.Goal!=0 && procedure.Goal!="" && procedure.total>0 ? (
																<Fragment>
																	<Row>
																		<Col xs="12" md="12" lg="12" className="text-right">
																			<Progress multi className="mt-1 float-left" style={{ height: "10px", width: "100%"}}>
																				<Progress bar color={this.getColor(procedure.CategoryID)} value={this.getBarValue(procedure)} />
																			</Progress>
																			{
																				//<span className="float-right" style={{color:'#ffc10e', width: "15%"}}>{this.state.userCurrencySymbol}{procedure.Goal}</span>
																			}
																		</Col>
																	</Row>
																</Fragment>
															) : (
																<Fragment>
																</Fragment>
															)}
															</Fragment>
											      ))}
														</div>
													)}
											</div>
										</div>
										<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
											<Button size="sm" color="link" disabled={this.state.currentDate==this.state.endDate} onClick={() => this.stepForward()} title="Step Forward">
												<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-right mdi-24px"/>
											</Button>
										</div>
									</Row>
									</Fragment>
								}
								</Fragment>
							)}
						</div>
					}
					{this.state.currentDataSetting == 0 || this.state.currentDataSetting == 3 ? (
						<Fragment>
						{this.state.currentDataSetting != 0 &&
							<Fragment>
							<Row className="mt-5">
								<Col lg="12" xl="6" className="text-right">
									<div className="float-left" style={{ minWidth: '10%' }}>
										<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>From:</h6>
									</div>
									<div className="float-left ml-1" style={{ minWidth: '75%' }}>
										<Input style={{ minWidth: '135px' }} name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleChange} />
									</div>
								</Col>
								<Col lg="12" xl="6" className="">
									<div className="float-left text-right" style={{ minWidth: '10%' }}>
										<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>To:</h6>
									</div>
									<div className="float-left ml-1" style={{ minWidth: '75%' }}>
										<Input style={{ minWidth: '135px' }} name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
									</div>
									{
										// <div className="float-left ml-1" style={{ minWidth: '16%' }}>
										// <Button size="sm" color="dark" onClick={() => this.handleSubmit()} title="Go" disabled={this.state.currentDataSetting != 3}>
										// 	GO
										// </Button>
										// </div>
									}
								</Col>
							</Row>
							</Fragment>
						}
						</Fragment>
					) : (
						<Fragment>
						<Row className="mt-5">
							<Col lg="12" xl="6" className="text-right">
								<div className="float-left" style={{ minWidth: '10%' }}>
									<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>From:</h6>
								</div>
								<div className="float-left ml-1" style={{ minWidth: '75%' }}>
									<Input style={{ minWidth: '135px' }} name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleChange} />
								</div>
							</Col>
							<Col lg="12" xl="6" className="">
								<div className="float-left text-right" style={{ minWidth: '10%' }}>
									<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>To:</h6>
								</div>
								<div className="float-left ml-1" style={{ minWidth: '75%' }}>
									<Input style={{ minWidth: '135px' }} name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
								</div>
								{
									// <div className="float-left ml-1" style={{ minWidth: '16%' }}>
									// <Button size="sm" color="dark" onClick={() => this.handleSubmit()} title="Go">
									// 	GO
									// </Button>
									// </div>
								}
							</Col>
						</Row>
						</Fragment>
					)}
				</CardBody>
			</Card>

		);
	}
}

export default ReturnOnInvestment;
