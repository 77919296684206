import React from "react";
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';

import batteryEmpty from './imgs/Battery_Empty.png';
import battery25 from './imgs/Battery_25.png';
import battery50 from './imgs/Battery_50.png';
import battery75 from './imgs/Battery_75.png';
import battery100 from './imgs/Battery_100.png';

class BatteryHealth extends React.Component {

	noResults = true;

	constructor(props) {

    super(props);
    this.state = {
			rSelected: 3,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			totalProcedures: 0,
			powerConnected: true,
	  };

  }

	async componentDidMount() {

		//setInterval(this.checkIsConnected, 2000);
	}

	getMessage = () => {
		const {message} = this.props

		console.log("onMessageArrivedInBatteryHealth:"+message);
  }

	checkIsConnected = () => {
		console.log("checkIsConnected: "+this.props.isReceivingMessages);
		if (this.props.isReceivingMessages) {
			console.log("Battery Is Connected!");
		} else {
			console.log("Battery Is Not Connected!");
		}
	}

	render() {

		var voltageLabel = " 0V";
		var voltageIndicator = 0;
		var amperageLabel = " 0mA";
		var amperageIndicator = 0;
		if (this.props.message.V) {
			voltageLabel = this.props.message.V/1000;
			voltageLabel = parseFloat(voltageLabel);
			voltageIndicator = voltageLabel/20*100;
			voltageLabel = voltageLabel.toFixed(1)+" V";
		}
		if (this.props.message.I) {
			amperageLabel = this.props.message.I;
			amperageLabel = parseFloat(amperageLabel);
			amperageIndicator = amperageLabel/2000*100;
			amperageLabel = amperageLabel.toFixed(1)+" mA";
		}

		var powerConnected = false;
		if (this.props.message.dc>=16000) {
			powerConnected = true;
		}

		var batteryLevelImg = batteryEmpty;

		if (this.props.message.V>13000 && this.props.message.V<14200) {
			batteryLevelImg = battery25;
		} else if (this.props.message.V>14200 && this.props.message.V<15200) {
			batteryLevelImg = battery50;
		} else if (this.props.message.V>15200 && this.props.message.V<16000) {
			batteryLevelImg = battery75;
		} else if (this.props.message.V>16000 && this.props.message.V<16800) {
			batteryLevelImg = battery100;
		}


		var voltageHistoryLabel = " 0V";
		var voltageHistoryIndicator = 0;
		var amperageHistoryLabel = " 0mA";
		var amperageHistoryIndicator = 0;
		var powerHistoryConnected = false;
		var batteryLevelHistoryImg = batteryEmpty;
		if (this.props.historyMessage) {
			console.log("this.props.historyMessage:"+JSON.stringify(this.props.historyMessage));
			console.log("this.props.historyMessage.V:"+this.props.historyMessage.V);
			if (this.props.historyMessage.V) {
				voltageHistoryLabel = this.props.historyMessage.V/1000;
				voltageHistoryLabel = parseFloat(voltageHistoryLabel);
				voltageHistoryIndicator = voltageHistoryLabel/20*100;
				voltageHistoryLabel = voltageHistoryLabel.toFixed(1)+" V";
			}
			if (this.props.historyMessage.I) {
				amperageHistoryLabel = this.props.historyMessage.I;
				amperageHistoryLabel = parseFloat(amperageHistoryLabel);
				amperageHistoryIndicator = amperageHistoryLabel/2000*100;
				amperageHistoryLabel = amperageHistoryLabel.toFixed(1)+" mA";
			}

			if (this.props.historyMessage.dc>=16000) {
				powerHistoryConnected = true;
			}

			if (this.props.historyMessage.V>13000 && this.props.historyMessage.V<14200) {
				batteryLevelHistoryImg = battery25;
			} else if (this.props.historyMessage.V>14200 && this.props.historyMessage.V<15200) {
				batteryLevelHistoryImg = battery50;
			} else if (this.props.historyMessage.V>15200 && this.props.historyMessage.V<16000) {
				batteryLevelHistoryImg = battery75;
			} else if (this.props.historyMessage.V>16000 && this.props.historyMessage.V<16800) {
				batteryLevelHistoryImg = battery100;
			}

			console.log("voltageHistoryLabel:"+voltageHistoryLabel);
			console.log("voltageHistoryIndicator:"+voltageHistoryIndicator);
			console.log("amperageHistoryLabel:"+amperageHistoryLabel);
			console.log("amperageHistoryIndicator:"+amperageHistoryIndicator);
		} else {
			console.log("no this.props.historyMessage");
		}

		return (
      <Card className="card-hover">
				<CardBody style={{ minHeight: 192 }}>
					<Row>
						<Col xs="4" md="4" lg="4" className="text-left">
							<CardTitle>Battery Health</CardTitle>
						</Col>
						{this.props.isReceivingMessages &&
							<Col xs="8" md="8" lg="8" className="text-right">
								{powerConnected ? (
									<Fragment>
									<div className="float-left" style={{lineHeight: '1', width: '50%', color: '#48b203'}}><i color="brightgreen" className="mdi mdi-power-plug font-24"/></div>
									<div className="float-right" style={{width: '50%'}}><span style={{fontSize: '17px'}}>Power Supply</span></div>
									</Fragment>
								) : (
									<Fragment>
									<div className="float-left ml-2" style={{lineHeight: '1.5', width: '40%', color: '#dc3545'}}><img style={{filter: "invert(1)"}} src={batteryLevelImg} width="37" height="15"/></div>
									<div className="float-right" style={{minWidth: '35%'}}><span style={{fontSize: '17px'}}>Battery</span></div>
									</Fragment>
								)}
							</Col>
						}
						{!this.props.isReceivingMessages &&
							<Col xs="8" md="8" lg="8" className="text-right">
								{powerHistoryConnected ? (
									<Fragment>
									<div className="float-left" style={{lineHeight: '1', width: '50%', color: '#48b203'}}><i color="brightgreen" className="mdi mdi-power-plug font-24"/></div>
									<div className="float-right" style={{width: '50%'}}><span style={{fontSize: '17px'}}>Power Supply</span></div>
									</Fragment>
								) : (
									<Fragment>
									<div className="float-left ml-2" style={{lineHeight: '1.5', width: '40%', color: '#dc3545'}}><img style={{filter: "invert(1)"}} src={batteryLevelHistoryImg} width="37" height="15"/></div>
									<div className="float-right" style={{minWidth: '35%'}}><span style={{fontSize: '17px'}}>Battery</span></div>
									</Fragment>
								)}
							</Col>
						}
					</Row>
					{this.props.isReceivingMessages &&
						<Fragment>
						<Row>
							<Col xs="12" md="12" lg="12">
								<div className="">
								<ButtonGroup style={{ width: '100%' }}>
									<Button
										style={{width: '100%', cursor: 'default'}}
										color="good"
										active={this.props.message.vsys > 12000 && this.props.message.vsys < 20000}
										disabled={this.props.message.vsys < 12000 && this.props.message.vsys > 20000}
									>
										Good
									</Button>
									<Button
										color="damaged"
										style={{width: '100%', cursor: 'default'}}
										active={this.props.message.vsys < 12000 && this.props.message.vsys > 20000}
										disabled={this.props.message.vsys > 12000 && this.props.message.vsys < 20000}
									>
										Service
									</Button>
								</ButtonGroup>
								</div>
							</Col>
						</Row>
						<div className="pb-3">
							<Row className="mt-2">
								<Col xs="6" md="6" lg="6" className="text-left">
									<span>Voltage</span>
								</Col>
								<Col xs="6" md="6" lg="6" className="text-right">
									<span>{voltageLabel}</span>
								</Col>
							</Row>
							<Progress multi className="mt-1">
								<Progress bar color="yellow" value={voltageIndicator} />
							</Progress>
							<Row className="mt-3">
								<Col xs="6" md="6" lg="6" className="text-left">
									<span>Amperage</span>
								</Col>
								<Col xs="6" md="6" lg="6" className="text-right">
									<span>{amperageLabel}</span>
								</Col>
							</Row>
							<Progress multi className="mt-1">
								<Progress bar color="cyan" value={amperageIndicator} />
							</Progress>
						</div>
						</Fragment>
					}
					{!this.props.isReceivingMessages &&
						<Fragment>
						{this.state.currentUser && this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']==2 && this.props.historyMessage ? (
							<Fragment>
							<Row>
								<Col xs="12" md="12" lg="12">
									<div className="">
									<ButtonGroup style={{ width: '100%' }}>
										<Button
											style={{width: '100%', cursor: 'default'}}
											color="good"
											active={this.props.historyMessage.vsys > 12000 && this.props.historyMessage.vsys < 20000}
											disabled={this.props.historyMessage.vsys < 12000 && this.props.historyMessage.vsys > 20000}
										>
											Good
										</Button>
										<Button
											color="damaged"
											style={{width: '100%', cursor: 'default'}}
											active={this.props.historyMessage.vsys < 12000 && this.props.historyMessage.vsys > 20000}
											disabled={this.props.historyMessage.vsys > 12000 && this.props.historyMessage.vsys < 20000}
										>
											Service
										</Button>
									</ButtonGroup>
									</div>
								</Col>
							</Row>
							<div className="pb-3">
								<Row className="mt-2">
									<Col xs="6" md="6" lg="6" className="text-left">
										<span>Voltage</span>
									</Col>
									<Col xs="6" md="6" lg="6" className="text-right">
										<span>{voltageHistoryLabel}</span>
									</Col>
								</Row>
								<Progress multi className="mt-1">
									<Progress bar color="yellow" value={voltageHistoryIndicator} />
								</Progress>
								<Row className="mt-3">
									<Col xs="6" md="6" lg="6" className="text-left">
										<span>Amperage</span>
									</Col>
									<Col xs="6" md="6" lg="6" className="text-right">
										<span>{amperageHistoryLabel}</span>
									</Col>
								</Row>
								<Progress multi className="mt-1">
									<Progress bar color="cyan" value={amperageHistoryIndicator} />
								</Progress>
							</div>
							</Fragment>
						) : (
							<div className="mt-5 text-center">
								<h5>Device {this.props.currentDevice} is Currently not Connected.</h5>
							</div>
						)}
						</Fragment>
					}
        </CardBody>
      </Card>
		);
	}
}

export default BatteryHealth;
