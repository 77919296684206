import React from "react";
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ButtonDropdown,
	Tooltip
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import Moment from 'moment';
var moment = require('moment-timezone');

let lineOptions = {
	elements: { point: { radius: 2 } },
	maintainAspectRatio: false,
	tooltips: {
		titleFontSize: 16,
		bodyFontSize: 14
	},
	layout: {
			padding: {
				right: 6
			}
	},
	plugins: {
		datalabels: {
			display: false,
		}
	},
	scales: {
		xAxes: [{
			gridLines: {
				display: false,
				drawBorder: false,
			},
			ticks: {
				display: false
			}
		}],
		yAxes: [{
			gridLines: {
				display: false,
				drawBorder: false,
			},
			ticks: {
				display: false
			}
		}]
	},
	legend: {
		display: false,
		labels: {
			fontColor: 'rgb(255, 99, 132)'
		}
	}
};

//Line chart
let lineData2 = {
	labels: [1, 2, 3, 4, 5, 6, 7, 8],
	datasets: [{
		label: 'Outcome',
		borderWidth: 2,
		backgroundColor: 'rgba(79,195,247,.1)',
		borderColor: 'rgb(79,195,247)',
		pointBorderColor: 'rgb(79,195,247)',
		pointBackgroundColor: 'rgb(79,195,247)',
		data: [0, 8, 11, 22, 8, 10, 5, 21]
	}]
};

class LaserUsage extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);
    this.state = {
			totalUsage: '',
			usage: [],
			chartData: {},
			noDevices: false,
			noDataForDevices: false,
			currentDataSetting: 0,
			startDate: Moment().subtract(1, 'week').format('YYYY-MM-DD'),
			endDate: Moment().format('YYYY-MM-DD'),
			currentDate: Moment().format('YYYY-MM-DD'),
			isOpen: false,
			currentItem: "Global",
			tooltipOpen: false,
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.toggleDropDown = this.toggleDropDown.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.toggleTooltip = this.toggleTooltip.bind(this);

  }

	async componentDidMount() {

		//console.log("currentUser Laser usage: "+ this.state.currentUser);

		if (this.state.currentUser.payload["custom:role"] && this.state.currentUser.payload["custom:role"]>0) {
			//this.selectDropDownItem(1, "By Week");
			this.getData();
		} else {
			this.getData();
		}


		//setTimeout(this.getData, 200);
		this.intervalID = setInterval(this.getData, 5000);
	}

	toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

	toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	handleChange(event) {
		if (event.target.getAttribute('name') == 'startDate') {
			this.setState({startDate: event.target.value});
			this.setState({
	      currentItem: "Custom Range",
				currentDataSetting: 3
	    });
		} else if (event.target.getAttribute('name') == 'endDate') {
			//console.log('this.state.code: ' + event.target.value);
			this.setState({endDate: event.target.value});
			this.setState({
				currentItem: "Custom Range",
				currentDataSetting: 3
	    });
		}

		setTimeout(this.getData, 1000);
  }

	handleSubmit(event) {

		this.getData();
  }

	selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		if (id==1) {
			this.setState({ startDate: Moment().subtract(1, 'week').format('YYYY-MM-DD') });
		} else if (id==2) {
			this.setState({ startDate: Moment().subtract(1, 'month').format('YYYY-MM-DD') });
		}

		this.setState({ endDate: Moment().format('YYYY-MM-DD') });
		//this.getData();
		setTimeout(this.getData, 1000);
  }

	stepBack () {
		if (this.state.currentDataSetting==1) {
			let revisedStartDate = Moment(this.state.startDate).subtract(1, 'week').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).subtract(1, 'week').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		} else if (this.state.currentDataSetting==2) {
			let revisedStartDate = Moment(this.state.startDate).subtract(1, 'month').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).subtract(1, 'month').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		}
		//this.getData();
		setTimeout(this.getData, 1000);
	}

	stepForward () {
		if (this.state.currentDataSetting==1) {
			let revisedStartDate = Moment(this.state.startDate).add(1, 'week').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).add(1, 'week').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		} else if (this.state.currentDataSetting==2) {
			let revisedStartDate = Moment(this.state.startDate).add(1, 'month').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).add(1, 'month').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		}
		//this.getData();
		setTimeout(this.getData, 1000);
	}

	componentWillUnmount() {
		//console.log("Laser-Usage componentWillUnmount");
		clearInterval(this.intervalID);
	}

	getData = async () => {

		var timezone = Moment.tz.guess();

		if (this.state.currentDataSetting==0) {

			var url1 = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"global-laser-usage-total-for-device?SerialNumber="+this.props.currentDevice;

			const response1 = await fetch(url1, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body1 = await response1.json();
			console.log("global-laser-usage-total-for-device body response: " + JSON.stringify(body1));

			if (body1.status == 'Success') {
				if (body1.results.length>0) {
					//let total = parseFloat(body1.results[0].totalseconds, 10)/60;

					var d = Number(body1.results[0].totalseconds);
					var h = Math.floor(d / 3600);
					var m = Math.floor(d % 3600 / 60);
					var s = Math.floor(d % 3600 % 60);

					var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
					var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
					var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";

					this.setState({ totalUsage: hDisplay + mDisplay + sDisplay });
				} else {
					this.state.noDataForDevices = true;
				}
				this.forceUpdate();
			}

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"global-laser-usage-for-device-by-day?SerialNumber="+this.props.currentDevice+"&Timezone="+timezone;

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("global-laser-usage-for-device-by-day body response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				if (body.results.length>0) {
					this.setState({ startDate: Moment(body.results[0].date).format('YYYY-MM-DD') });
					this.setState({ endDate: Moment(body.results[body.results.length-1].date).format('YYYY-MM-DD') });
					const { keywordData } = body.results;
					const chartData = {
						labels: body.results.map(k => Moment(k.CreationDate).format('MMM Do YYYY')),
						datasets: [{
							label: 'Total Minutes',
							borderWidth: 2,
							backgroundColor: 'rgba(79,195,247,.1)',
							borderColor: 'rgb(79,195,247)',
							pointBorderColor: 'rgb(79,195,247)',
							pointBackgroundColor: 'rgb(79,195,247)',
							data: body.results.map(k => parseFloat(k.dailyusageseconds/60).toFixed(2))
						}]
					}

					this.setState({ usage: body.results });
					this.setState({ chartData });
				} else {
					this.state.noDataForDevices = true;
				}
				//this.forceUpdate();
			} else {
				this.state.noDataForDevices = true;
			}
		} else {

			var url1 = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"global-laser-usage-total-for-device-date-range?SerialNumber="+this.props.currentDevice+"&StartDate="+this.state.startDate+"&EndDate="+this.state.endDate+"&Timezone="+timezone;

			const response1 = await fetch(url1, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body1 = await response1.json();
			console.log("global-laser-usage-total-for-device-date-range body response: " + JSON.stringify(body1));

			if (body1.status == 'Success') {
				if (body1.results.length>0) {
					//let total = parseFloat(body1.results[0].totalseconds, 10)/60;

					var d = Number(body1.results[0].totalseconds);
					var h = Math.floor(d / 3600);
					var m = Math.floor(d % 3600 / 60);
					var s = Math.floor(d % 3600 % 60);

					var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
					var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
					var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";

					this.setState({ totalUsage: hDisplay + mDisplay + sDisplay });
					this.state.noDataForDevices = false;
				} else {
					this.state.noDataForDevices = true;
				}
				this.forceUpdate();
			}

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"global-laser-usage-for-device-by-day-date-range?SerialNumber="+this.props.currentDevice+"&StartDate="+this.state.startDate+"&EndDate="+this.state.endDate+"&Timezone="+timezone;

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("global-laser-usage-for-device-by-day-date-range body response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				if (body.results.length>0) {
					const { keywordData } = body.results;
					const chartData = {
						labels: body.results.map(k => Moment(k.CreationDate).format('MMM Do YYYY')),
						datasets: [{
							label: 'Total Minutes',
							borderWidth: 2,
							backgroundColor: 'rgba(79,195,247,.1)',
							borderColor: 'rgb(79,195,247)',
							pointBorderColor: 'rgb(79,195,247)',
							pointBackgroundColor: 'rgb(79,195,247)',
							data: body.results.map(k => parseFloat(k.dailyusageseconds/60).toFixed(2))
						}]
					}

					this.setState({ usage: body.results });
					this.setState({ chartData });
					this.state.noDataForDevices = false;
				} else {
					this.state.noDataForDevices = true;
				}
				//this.forceUpdate();
			} else {
				this.state.noDataForDevices = true;
			}
		}

	}

	render() {
		return (
      <Card className="card-hover">
				<CardBody style={{ minHeight: 250 }}>
					<Row>
						<Col xs="6" md="6" lg="6" className="text-left">
							<CardTitle>Life Time Laser Usage
							<i style={{color: '#ffffff'}} className="mdi mdi-information-outline ml-1" id="LaserUsageTooltip"/>
							<Tooltip placement="top" isOpen={this.state.tooltipOpen} target="LaserUsageTooltip" toggle={this.toggleTooltip}>
								Displays total laser usage time by desired time period. This is how long the laser has been fired in both manual and preset modes.
							</Tooltip>
							</CardTitle>
						</Col>
						<Col xs="6" md="6" lg="6" className="text-right">
							<ButtonDropdown isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
								<DropdownToggle color="dark" caret>
									{this.state.currentItem}
								</DropdownToggle>
								<DropdownMenu right>

									<DropdownItem onClick={() => this.selectDropDownItem(0, 'Global')}>Global</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(1, 'By Week')}>By Week</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(2, 'By Month')}>By Month</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(3, 'Custom Range')}>Custom Range</DropdownItem>
								</DropdownMenu>
							</ButtonDropdown>
						</Col>
					</Row>
					{this.state.noDevices ? (
						<div className="mt-5 text-center">
							<h5>User has no devices</h5>
						</div>
					) : (
						<Fragment>
						{this.state.currentDataSetting == 0 || this.state.currentDataSetting == 3 ? (
							<Fragment>
							<div className="ml-auto mt-1">
								<div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 110 }}>
									{this.state.noDataForDevices ? (
										<div className="d-flex justify-content-center align-items-center" style={{ height: '100%'}}>
											<h5>No data for user's devices</h5>
										</div>
									) : (
										<Line data={this.state.chartData} options={lineOptions} />
									)}
								</div>
								<div className="text-center" style={{ width: '100%'}}>
									{!this.state.noDataForDevices &&
										<span style={{color:'#33d1bf', lineHeight: '2.3'}}>{this.state.totalUsage}</span>
									}
								</div>
							</div>
							{this.state.currentDataSetting != 0 &&
								<Fragment>
								<Row className="mt-1">
									<Col lg="12" xl="6" className="text-right">
										<div className="float-left" style={{ minWidth: '10%' }}>
											<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>From:</h6>
										</div>
										<div className="float-left ml-1" style={{ minWidth: '75%' }}>
											<Input style={{ minWidth: '135px' }} name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleChange} />
										</div>
									</Col>
									<Col lg="12" xl="6" className="">
										<div className="float-left text-right" style={{ minWidth: '10%' }}>
											<h6 style={{ lineHeight: '1.8', minWidth: '23px' }}>To:</h6>
										</div>
										<div className="float-left ml-1" style={{ minWidth: '75%' }}>
											<Input style={{ minWidth: '135px' }} name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
										</div>
										{
											// <div className="float-left ml-1" style={{ minWidth: '16%' }}>
											// <Button size="sm" color="dark" onClick={() => this.handleSubmit()} title="Go" disabled={this.state.currentDataSetting != 3}>
											// 	GO
											// </Button>
											// </div>
										}
									</Col>
								</Row>
								</Fragment>
							}
							</Fragment>
						) : (
							<Fragment>
							<Row className="align-items-center">
								<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
									<Button size="sm" color="link" onClick={() => this.stepBack()} title="Vitals">
										<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-left mdi-24px"/>
									</Button>
								</div>
								<div style={{ width: '80%', margin: '0 auto', height: '100%' }}>
									<div className="ml-4 mt-1">
										<div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 110 }}>
											{this.state.noDataForDevices ? (
												<div className="d-flex justify-content-center align-items-center" style={{ height: '100%'}}>
													<h5>No data for user's devices</h5>
												</div>
											) : (
												<Line data={this.state.chartData} options={lineOptions} />
											)}
										</div>
										<div className="text-center" style={{ width: '100%'}}>
											{!this.state.noDataForDevices &&
												<span style={{color:'#33d1bf', lineHeight: '2.3'}}>{this.state.totalUsage}</span>
											}
										</div>
									</div>
								</div>
								<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
									<Button size="sm" color="link" disabled={this.state.currentDate==this.state.endDate} onClick={() => this.stepForward()} title="Vitals">
										<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-right mdi-24px"/>
									</Button>
								</div>
							</Row>
							<Row className="mt-1">
								<Col lg="12" xl="6" className="text-right">
									<div className="float-left" style={{ minWidth: '10%' }}>
										<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>From:</h6>
									</div>
									<div className="float-left ml-1" style={{ minWidth: '75%' }}>
										<Input style={{ minWidth: '135px' }} name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleChange} />
									</div>
								</Col>
								<Col lg="12" xl="6" className="">
									<div className="float-left text-right" style={{ minWidth: '10%' }}>
										<h6 style={{ lineHeight: '1.8', minWidth: '23px' }}>To:</h6>
									</div>
									<div className="float-left ml-1" style={{ minWidth: '75%' }}>
										<Input style={{ minWidth: '135px' }} name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
									</div>
									{
										// <div className="float-left ml-1" style={{ minWidth: '16%' }}>
										// <Button size="sm" color="dark" onClick={() => this.handleSubmit()} title="Go">
										// 	GO
										// </Button>
										// </div>
									}
								</Col>
							</Row>
							</Fragment>
						)}
						</Fragment>
					)}
        </CardBody>
      </Card>
		);
	}
}

export default LaserUsage;
