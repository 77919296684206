import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";
import { getEmailTextMessageOne, getEmailHTMLMessageOne, getEmailTextMessageTwo, getEmailHTMLMessageTwo, getEmailTextMessageThree, getEmailHTMLMessageThree } from "../../devices/deviceslist/get-email-html";
import Loader from 'react-loader-spinner'

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
	Fade
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

var noActionNeededList = [];

class ErrorMessagesList extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			obj: {},
			bomData: [],
      logData: [],
			filteredLogData: [],
			users: [],
			devices: [],
			errorCodeData: [],
			errorCorrespondenceLogs: [],
			statusGroupSelected: 0,
			modal: false,
			modal2: false,
			modal3: false,
			modal4: false,
			userMarket: 0,
			messageType: "Email",
			isInternationalTech: false,
			statusSubject: "Failed",
			statusMessage: "Failed to send message.",
			loadingErrors: true,
			isSending: false,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			currentErrorCorrespondenceLog: {},
			noActionNeededList: [],
			isAutoResolving: false
	  };

		this.updateErrors = [
			{ name: "Update Not Complete" },
			{ name: "Successful update" },
			{ name: "Sound Memory partition not found" },
			{ name: "Sound Memory partition couldn't be erased" },
			{ name: "Couldn't initialize HTTP call to grab Sound bin file" },
			{ name: "Couldn't open HTTP call to grab  Sound bin file" },
			{ name: "Couldn't allocate enough memory" },
			{ name: "HTTP connection closed, no data was received" },
			{ name: "Issues with SSL connection" },
			{ name: "Firmware Memory partition not found" },
			{ name: "Couldn't initialize HTTP call to grab Firmware bin file" },
			{ name: "Couldn't open HTTP call to grab Firmware bin file" },
			{ name: "Error returned by esp_ota_begin call" },
			{ name: "Couldn't allocate enough memory" },
			{ name: "Issues with SSL connection" },
			{ name: "Error returned by esp_ota_write call" },
			{ name: "Error returned by esp_ota_end call" },
			{ name: "Error returned by esp_ota_set_boot_partition call" },
			{ name: "Battery is below 14200mV" },
		];

		this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		this.toggle3 = this.toggle3.bind(this);
		this.toggle4 = this.toggle4.bind(this);
		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);

  }


	async componentDidMount() {

		await this.getDevices();

		await this.getUsersData();

		await this.getErrorCodeData();

		await this.getErrorCorrespondenceLogs();

    this.getErrorMessageLogData();

		//this.identifyBatchCorrespondence();
	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	toggle2() {
	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

	toggle3() {
	  this.setState({
	    modal3: !this.state.modal3
	  });
	}

	toggle4() {
	  this.setState({
	    modal4: !this.state.modal4
	  });
	}

	async onRadioBtnClick(statusGroupSelected) {

		await this.setState({ statusGroupSelected });

		if (statusGroupSelected==0) {

			var filteredLogData =	this.state.logData;
			console.log("filteredLogData: " + JSON.stringify(filteredLogData));
			this.setState({ filteredLogData: filteredLogData });

		} else if (statusGroupSelected==1) {

			var filteredLogData =	this.state.logData.filter(log => log.wifi_ssid !== "AzenaProv");
			console.log("filteredLogData: " + JSON.stringify(filteredLogData));
			this.setState({ filteredLogData: filteredLogData });

		} else if (statusGroupSelected==2) {

			var filteredLogData =	this.state.logData.filter(log => log.wifi_ssid === "AzenaProv");
			console.log("filteredLogData: " + JSON.stringify(filteredLogData));
			this.setState({ filteredLogData: filteredLogData });

		}

		//setTimeout(() => this.getBOMData(userMarket), 300);
		//this.getBOMData(userMarket);

		//this.getFootpedalReportData();
		//setTimeout(() => this.getFootpedalReportData(), 300);

	}

	componentWillUnmount() {
		//console.log("Laser-Usage componentWillUnmount");
		//clearInterval(this.intervalID);
	}

	getUsersData = async () => {

		try {

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-users";

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});
			const body = await response.json();
			console.log("all users: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ users: body.response.Users });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
		}

	};

	getDevices = async () => {

		try {

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"all-devices";

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("all devices: " + JSON.stringify(body));

			if (body.status == 'Success') {

				let devices = body.results;

				this.setState({ devices: devices });

			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
		}

	};

  getErrorMessageLogData = async () => {

		try {

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-error-messages";

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("getErrorMessageLogData data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ logData: body.results, filteredLogData: body.results, loadingErrors: false });
				this.identifyBatchCorrespondence(body.results);
			} else {
	      //ADD ERROR MESSAGE
				this.setState({ loadingErrors: false });
	    }

		}
		catch (err) {
			console.log(err);
			this.setState({ loadingErrors: false });
		}

	};

	getErrorCodeData = async () => {

		try {

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-error-codes";

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("getErrorCodeData data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ errorCodeData: body.results });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
		}

	};

	getErrorCorrespondenceLogs = async () => {

		try {

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-error-correspondence-logs";

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("getErrorCorrespondenceLogs data: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ errorCorrespondenceLogs: body.results });
			} else {
	      //ADD ERROR MESSAGE
	    }

		}
		catch (err) {
			console.log(err);
		}

	};

	deleteErrorRecord = async () => {

		//console.log("Delete Note: " + this.state.obj.description);

		try {

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-error-record?ErrorRecordID="+this.state.obj.logID;

	    console.log("Delete note url: " + url);

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("deleteErrorRecord response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				this.toggle();
	      this.getErrorMessageLogData();

			} else {

			}

		}
		catch (err) {
			console.log(err);
		}

	};

	getUserData = async (serialNumber) => {
		//console.log("Getting Device Registered User");

		if (this.state.currentUser) {
			//console.log("topprocedures 1: ");

			try {

				var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-user-for-device?SerialNumber="+serialNumber;

				const response = await fetch(url, {
					method: 'GET',
					headers: {
						Authorization: this.state.currentUser.jwtToken
					},
				});

				const body = await response.json();
				console.log("Device Registered User response: " + JSON.stringify(body.response));

				if (body.status == 'Success') {

					let deviceInfo = body.response;

					this.setState({deviceInfo});
					//console.log("Device Reg Phone: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("custom:phone")).map(filteredName => (filteredName.Value)));
					let phoneNumber = deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("custom:phone")).map(filteredName => (filteredName.Value));
					let allowphonecontact = deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("custom:allowphonecontact")).map(filteredName => (filteredName.Value));
					//console.log("Allow SMS: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("custom:allowphonecontact")).map(filteredName => (filteredName.Value)));

					let firstName = deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value));
					let lastName = deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("family_name")).map(filteredName => (filteredName.Value));
					//let email = deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("email")).map(filteredName => (filteredName.Value));
					let email = deviceInfo.userInfo.Username;

					console.log("Device Registered User email: " + email);

					var allowPhoneContactValue = false;

					if (allowphonecontact) {
						if (allowphonecontact == "1") {
							allowPhoneContactValue = true;
						} else {
							allowPhoneContactValue = false;
						}
					}

					this.setState({
						userPhoneNumber: phoneNumber,
						allowSMS: allowPhoneContactValue,
						msgFirstName: firstName,
						msgLastName: lastName,
						userEmail: email
					})
					this.state.noDataForDevices = false;
				} else {
					this.state.noDataForDevices = true;
				}

				this.setState({
					modal2: !this.state.modal2,
					messageType: "Email",
				});

			}
			catch (err) {
				console.log(err);
			}

		}
	};

	onChangeMessageType = (event) => {

		console.log("input id: " + event.target.id);
		console.log("input value: " + event.target.value);
		console.log("checked value: " + event.target.checked);

		let id = event.target.id;
		let value = event.target.value;
		let checked = event.target.checked;

		this.setState({ messageType: value });

		// if (checked != undefined) {
		//
		// 	if (event.target.type == 'checkbox') {
		// 		repairLog[id] = checked ? 1 : 0;
		// 		console.log("set checked as value");
		// 	} else if (event.target.type == 'date') {
		// 		repairLog[id] = value;
		// 		console.log("set checked as value");
		// 	}
		//
		// }

		console.log("onChangeMessageType value: " + JSON.stringify(value));

	}

	sendMessage = (event) => {
		event.preventDefault();

		this.setState({ isSending: true });

		//console.log("Send message");
		let email = event.target.email.value;
    //console.log(`email is:`, email);
		let phone = event.target.phone.value;
		//console.log(`phone is:`, phone);
		let messagetype = event.target.messagetype.value;
		//console.log(`messagetype is:`, messagetype);

		let subject = "";
		if (event.target.subject) {
			subject = event.target.subject.value;
		}
		console.log(`subject is:`, subject);

		let message = "";
		if (event.target.message) {
			message = event.target.message.value;
		}
		console.log(`message is:`, message);

		let formattedPhoneNumber = this.enforcePhoneNumberPattern(phone);
		//console.log(`Formatted Phone Number is:`, formattedPhoneNumber);

		if (messagetype == "Email") {
			this.sendEmailMessage(email, subject, message);
		} else if (messagetype == "About your Gemini EVO update due to Wi-Fi") {
			this.sendTemplateEmailMessage(email, messagetype, 1);
		} else if (messagetype == "About your Gemini EVO update due to low battery") {
			this.sendTemplateEmailMessage(email, messagetype, 2);
		} else if (messagetype == "About your Gemini EVO activation pedal pairing") {
			this.sendTemplateEmailMessage(email, messagetype, 3);
		} else if (messagetype == "Phone Call") {
			//this.sendTextMessage(formattedPhoneNumber, subject, message);
			this.saveCorrespondenceLogWithNotes(formattedPhoneNumber, subject, message);
			this.toggle2();
			this.setState({ isSending: false });
		} else if (messagetype == "No Action Needed") {
			//this.sendTextMessage(formattedPhoneNumber, subject, message);
			this.saveCorrespondenceLogWithNotes(formattedPhoneNumber, subject, messagetype);
			this.toggle2();
			this.setState({ isSending: false });
		} else if (messagetype == "No Action Needed - Customer Rebonded") {
			//this.sendTextMessage(formattedPhoneNumber, subject, message);
			this.saveCorrespondenceLogWithNotes(formattedPhoneNumber, subject, messagetype);
			this.toggle2();
			this.setState({ isSending: false });
		} else if (messagetype == "Addressed on other occurance") {
			//this.sendTextMessage(formattedPhoneNumber, subject, message);
			this.saveCorrespondenceLogWithNotes(formattedPhoneNumber, subject, messagetype);
			this.toggle2();
			this.setState({ isSending: false });
		} else if (messagetype == "Internal Testing Unit") {
			//this.sendTextMessage(formattedPhoneNumber, subject, message);
			this.saveCorrespondenceLogWithNotes(formattedPhoneNumber, subject, messagetype);
			this.toggle2();
			this.setState({ isSending: false });
		} else if (messagetype == "Production Unit") {
			//this.sendTextMessage(formattedPhoneNumber, subject, message);
			this.saveCorrespondenceLogWithNotes(formattedPhoneNumber, subject, messagetype);
			this.toggle2();
			this.setState({ isSending: false });
		} else if (messagetype == "Other") {
			//this.sendTextMessage(formattedPhoneNumber, subject, message);
			this.saveCorrespondenceLogWithNotes(formattedPhoneNumber, subject, message);
			this.toggle2();
			this.setState({ isSending: false });
		} else {
			this.sendTextMessage(formattedPhoneNumber, subject, message);
		}
	}

	sendEmailMessage = async (recipient, subject, message) => {

		var logDetails = {
			SerialNumber: this.state.obj.SerialNumber,
			FirstName: this.state.msgFirstName,
			LastName: this.state.msgLastName,
			Email: recipient,
			TechnicianEmail: this.state.currentUser.payload.email,
			TechnicianFirstName: this.state.currentUser.payload.given_name,
			TechnicianLastName: this.state.currentUser.payload.family_name,
		}

		var requestBody = {
			emailRecipient:[recipient],
			emailSubject:subject,
			emailHTMLMessage:message,
			emailTextMessage:message,
			emailType: 2,
			logDetails
		}

		console.log("send-email-to-user requestBody: " + JSON.stringify(requestBody));

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-email-to-user";

		try {

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: this.state.currentUser.jwtToken,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(requestBody)
			});

			const body = await response.json();
			console.log("send-email-to-user response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				await this.saveCorrespondenceLog(recipient, subject, message);

				this.toggle2();
				this.setState({
					statusSubject: "SUCCESS!",
					statusMessage: "Email sent Successfully!"
				})

			} else {

				this.toggle2();
				this.setState({
					statusSubject: "FAILED!",
					statusMessage: "Failed to send Email to "+recipient+"."
				})

			}

		}
		catch (err) {
			console.log(err);
		}

		this.toggle3();
		this.setState({ isSending: false });

	};

	sendTemplateEmailMessage = async (recipient, subject, template) => {

		var textBody = getEmailTextMessageOne();
		var htmlBody = getEmailHTMLMessageOne();

		var logDetails = {
			SerialNumber: this.state.obj.SerialNumber,
			FirstName: this.state.msgFirstName,
			LastName: this.state.msgLastName,
			Email: recipient,
			TechnicianEmail: this.state.currentUser.payload.email,
			TechnicianFirstName: this.state.currentUser.payload.given_name,
			TechnicianLastName: this.state.currentUser.payload.family_name,
		}

		var requestBody = {
			emailRecipient:[recipient],
			emailSubject:subject,
			emailHTMLMessage:htmlBody,
			emailTextMessage:textBody,
			emailType: 2,
			logDetails
		}

		if (template==1) {
			textBody = getEmailTextMessageOne();
			htmlBody = getEmailHTMLMessageOne();

			requestBody = {
				emailRecipient:[recipient],
				emailSubject:subject,
				emailHTMLMessage:htmlBody,
				emailTextMessage:textBody,
				emailType: 2,
				logDetails
			}
		} else if (template==2) {
			textBody = getEmailTextMessageTwo();
			htmlBody = getEmailHTMLMessageTwo();

			requestBody = {
				emailRecipient:[recipient],
				emailSubject:subject,
				emailHTMLMessage:htmlBody,
				emailTextMessage:textBody,
				emailType: 2,
				logDetails
			}
		} else if (template==3) {
			textBody = getEmailTextMessageThree();
			htmlBody = getEmailHTMLMessageThree();

			requestBody = {
				emailRecipient:[recipient],
				emailSubject:subject,
				emailHTMLMessage:htmlBody,
				emailTextMessage:textBody,
				emailType: 2,
				logDetails
			}
		}

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-email-to-user";

		try {

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: this.state.currentUser.jwtToken,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(requestBody)
			});

			const body = await response.json();
			console.log("send-email-to-user response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				await this.saveCorrespondenceLog(recipient, subject, textBody);

				this.toggle2();
				this.setState({
					statusSubject: "SUCCESS!",
					statusMessage: "Email sent Successfully!"
				})

			} else {

				this.toggle2();
				this.setState({
					statusSubject: "FAILED!",
					statusMessage: "Failed to send Email to "+recipient+"."
				})

			}

		}
		catch (err) {
			console.log(err);
		}

		this.toggle3();
		this.setState({ isSending: false });

	};

	sendTextMessage = async (recipient, subject, message) => {

		var logDetails = {
			SerialNumber: this.state.obj.SerialNumber,
			FirstName: this.state.msgFirstName,
			LastName: this.state.msgLastName,
			PhoneNumber: recipient,
			TechnicianEmail: this.state.currentUser.payload.email,
			TechnicianFirstName: this.state.currentUser.payload.given_name,
			TechnicianLastName: this.state.currentUser.payload.family_name,
		}

		var requestBody = {
			TextRecipient:recipient,
			TextSubject:subject,
			TextMessage:message,
			textType: 2,
			logDetails
		}

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"post-text-message-to-user";

		//?TextRecipient="+recipient+"&TextSubject="+subject+"&TextMessage="+encodeURIComponent(message)

		try {

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: this.state.currentUser.jwtToken,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(requestBody)
			});

			// const response = await fetch(url, {
			// 	method: 'GET',
			// 	headers: {
			// 		Authorization: this.state.currentUser.jwtToken
			// 	},
			// });

			const body = await response.json();
			console.log("Send Text response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				await this.saveCorrespondenceLog(recipient, subject, message);

				this.toggle2();
				this.setState({
					statusSubject: "SUCCESS!",
					statusMessage: "Text Message sent Successfully!"
				})

			} else {

				this.toggle2();
				this.setState({
					statusSubject: "FAILED!",
					statusMessage: "Failed to send Text Message to "+recipient+"."
				})

			}

		}
		catch (err) {
			console.log(err);
		}

		this.toggle3();
		this.setState({ isSending: false });

	};

	saveCorrespondenceLog = async (recipient, subject, message) => {

		var requestBody = {
			ErrorID: this.state.obj.logID,
			TableID: this.state.obj.TableID,
			MessageRecipient:recipient,
			MessageSubject:subject,
			Message:message,
			SenderTechnicianName:this.state.currentUser.payload.given_name + " " +this.state.currentUser.payload.family_name,
			SenderTechnicianEmail:this.state.currentUser.payload.email
		}

		console.log("create-error-correspondence-log requestBody: " + JSON.stringify(requestBody));

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-error-correspondence-log";

		try {

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: this.state.currentUser.jwtToken,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(requestBody)
			});

			const body = await response.json();
			console.log("create-error-correspondence-log response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.getErrorCorrespondenceLogs();
			} else {

			}

		}
		catch (err) {
			console.log(err);
		}

	};

	saveCorrespondenceLogWithNotes = async (recipient, subject, message) => {

		var requestBody = {
			ErrorID: this.state.obj.logID,
			TableID: this.state.obj.TableID,
			MessageRecipient:recipient,
			MessageSubject:subject,
			Message:message,
			SenderTechnicianName:this.state.currentUser.payload.given_name + " " +this.state.currentUser.payload.family_name,
			SenderTechnicianEmail:this.state.currentUser.payload.email
		}

		console.log("create-error-correspondence-log requestBody: " + JSON.stringify(requestBody));

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-error-correspondence-log";

		try {

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: this.state.currentUser.jwtToken,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(requestBody)
			});

			const body = await response.json();
			console.log("create-error-correspondence-log response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				await this.saveCorrespondenceLogNotes(this.state.obj.logID, this.state.obj.TableID, message);
				this.getErrorCorrespondenceLogs();
			} else {

			}

		}
		catch (err) {
			console.log(err);
		}

	};

	identifyBatchCorrespondence = async (logs) => {

		var noActionNeededListTemp = [];

    const data = logs.map((prop, key) => {

			var name = "";
			var email = "";
			var phone = "";
			var city = "";
			var state = "";
			var country = "";
			var canContact = false;
			var deviceLog = [];
			var loginID = "";

			//console.log("prop " + JSON.stringify(prop));
			//console.log("prop.LoginID: " + JSON.stringify(prop.LoginID));

			var deviceLog =	this.state.devices.find(device => device.SerialNumber === prop.SerialNumber);
			//console.log("deviceLog: " + JSON.stringify(deviceLog));

			if (deviceLog) {
				loginID = deviceLog.LoginID;
				console.log("deviceLog: " + JSON.stringify(deviceLog));
				console.log("loginID: " + JSON.stringify(loginID));
				console.log("prop.SerialNumber: " + JSON.stringify(prop.SerialNumber));
			}

			if (prop.LoginID) {
				loginID = prop.LoginID;
			}

			//console.log("loginID: " + JSON.stringify(loginID));

			var userLog =	{};

			if (deviceLog && loginID) {

				city = deviceLog.geo_city;
				state = deviceLog.geo_state;
				country = deviceLog.geo_country;

				userLog =	this.state.users.find(user => user.Username.toLowerCase() === loginID.toLowerCase());

				if (userLog) {
					console.log("userLog: " + JSON.stringify(userLog));
					name = userLog.Attributes.filter(attribute => attribute.Name == "given_name").map(name => name.Value) + " " + userLog.Attributes.filter(attribute => attribute.Name == "family_name").map(name => name.Value);
					canContact = userLog.Attributes.filter(attribute => attribute.Name == "custom:allowphonecontact").map(name => name.Value) == 1 ? "YES" : "NO";
					email = userLog.Attributes.filter(attribute => attribute.Name == "email").map(name => name.Value);
					phone = userLog.Attributes.filter(attribute => attribute.Name == "custom:phone").map(name => name.Value);
				}

			}

			if (prop.geo_city) {
				city = prop.geo_city;
				state = prop.geo_state;
				country = prop.geo_country;
			}

			var remoteSupportPath = "/remotesupport/"+prop.SerialNumber;
			var remotesupportblackbox = "/remotesupportblackbox/"+prop.SerialNumber;

			var correspondenceSent = false;
			var correspondenceDate = null;
			var correspondenceSubject = "";
			var correspondenceMessage = "";
			var correspondenceTech = "";
			var correspondenceNotes = "";

			console.log("prop.id: " + JSON.stringify(prop.id));

			var correspondenceLog = this.state.errorCorrespondenceLogs.find(log => log.ECErrorID === prop.id);

			if (correspondenceLog) {

				console.log("correspondenceLog: " + JSON.stringify(correspondenceLog));

				if (correspondenceLog.MessageRecipient) {
					//console.log("correspondenceLog.MessageRecipient: " + JSON.stringify(correspondenceLog.MessageRecipient));
					//console.log("prop.id: " + JSON.stringify(prop.id));
					correspondenceSent = true;
				}

				correspondenceDate = correspondenceLog.CreationDate;
				correspondenceSubject = correspondenceLog.MessageSubject;
				correspondenceMessage = correspondenceLog.Message;
				correspondenceTech = correspondenceLog.SenderTechnicianName;
				correspondenceNotes = correspondenceLog.Notes;
			}

			console.log("correspondenceSent: " + JSON.stringify(correspondenceSent));

			var binary = this.convertToBinary(prop.errorcode);

			//console.log("binary: " + binary);
			//console.log("approvedMaskBinary: " + approvedMaskBinary);

			var issueArray = [];

			if (prop.tableID==1) {

				var issue = "("+prop.errorcode+")"+" "+this.updateErrors[prop.errorcode].name;
				issueArray.push(issue);

			} else if (prop.tableID==2) {
				var noActionNeededCount = 0;

				for (var i = 0; i < this.state.errorCodeData.length; i++){

					//UserApproveValue
					var errorCodeBinary = this.convertToBinary(this.state.errorCodeData[i].OBJID);

					//console.log("errorCodeBinary: " + errorCodeBinary);


					var indexBinary = binary.length - errorCodeBinary.length;
					//console.log("binary.charAt(indexBinary): " + binary.charAt(indexBinary));

					if (binary.charAt(indexBinary)==1) {
						//console.log("issue: " + this.state.errorCodeData[i].Name);

						if (this.state.errorCodeData[i].OBJID=="1024" || this.state.errorCodeData[i].OBJID=="4") {
							noActionNeededCount = noActionNeededCount+1;
						}

						var issue = "("+this.state.errorCodeData[i].OBJID+")"+" "+this.state.errorCodeData[i].Name;

						issueArray.push(issue);
					}

				}
			}

			if (parseInt(prop.SerialNumber) < 1000 && !correspondenceSubject) {

				console.log("Internal Testing Unit: " + JSON.stringify(prop.SerialNumber));

				let listItem = {
					errorID: prop.id,
					tableID: prop.tableID,
					recipient: phone[0] ? phone[0] : "",
					subject: "Internal Testing Unit",
					message: "Internal Testing Unit",
				};

				noActionNeededListTemp.push(listItem);

			} else {

				if (noActionNeededCount==issueArray.length && !correspondenceSubject) {

					// console.log("correspondenceSent: " + correspondenceSent);
					// console.log("SerialNumber: " + prop.SerialNumber);
					// console.log("noActionNeededCount: " + noActionNeededCount);
					// console.log("issueArray: " + JSON.stringify(issueArray));
					// console.log("should send no action needed!");

					let listItem = {
						errorID: prop.id,
						tableID: prop.tableID,
						recipient: phone[0] ? phone[0] : "",
						subject: "No Action Needed",
		        message: "No Action Needed",
					};

					noActionNeededListTemp.push(listItem);

					//this.saveCorrespondenceLogWithNotes(phone, "No Action Needed", "No Action Needed");
				}

			}

			return {
				id: key,
				logID: prop.id,
				SerialNumber: prop.SerialNumber,
        ErrorCode: prop.errorcode,
				Issue: issueArray,
        CreationDate: prop.CreationDate,
				CorrespondenceSent: correspondenceSent,
				CorrespondenceDate: correspondenceDate,
				CorrespondenceSubject: correspondenceSubject,
				CorrespondenceMessage: correspondenceMessage,
				CorrespondenceTech: correspondenceTech,
				userInfo: {name: name, email: email, phone: phone, city: city, state: state},
				name: name,
				email: email,
				city: city,
				state: state,
				canContact: canContact,
				Notes: correspondenceNotes,
				TableID: prop.tableID
			};
		});

		if (noActionNeededListTemp.length>0) {
			console.log("noActionNeededList length: " + JSON.stringify(noActionNeededListTemp.length));
			console.log("noActionNeededList: " + JSON.stringify(noActionNeededListTemp));

			//noActionNeededList = noActionNeededListTemp;
			this.setState({ noActionNeededList: noActionNeededListTemp }, () => console.log("noActionNeededList: "+JSON.stringify(this.state.noActionNeededList)),);

		}

	}

	saveBatchCorrespondence = async () => {

		this.setState({ isAutoResolving: true });

		var list = this.state.noActionNeededList;
		console.log("noActionNeededList length: " + JSON.stringify(this.state.noActionNeededList.length));

		for (var i=0; i<list.length; i++) {
			var listItem = list[i];
			await this.saveCorrespondenceLogWithNotesForErrorID(listItem.errorID, listItem.tableID, listItem.recipient, listItem.subject, listItem.message);
		}

		this.setState({ noActionNeededList: [] });
		this.getErrorCorrespondenceLogs();

		this.setState({ isAutoResolving: false });
		//console.log("noActionNeededList: " + JSON.stringify(this.state.noActionNeededList));
		//console.log("noActionNeededList: " + JSON.stringify(noActionNeededList));

	}

	saveCorrespondenceLogWithNotesForErrorID = async (errorID, tableID, recipient, subject, message) => {

		var requestBody = {
			ErrorID: errorID,
			TableID: tableID,
			MessageRecipient:recipient,
			MessageSubject:subject,
			Message:message,
			SenderTechnicianName:this.state.currentUser.payload.given_name + " " +this.state.currentUser.payload.family_name,
			SenderTechnicianEmail:this.state.currentUser.payload.email
		}

		//console.log("create-error-correspondence-log requestBody: " + JSON.stringify(requestBody));

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-error-correspondence-log";

		try {

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: this.state.currentUser.jwtToken,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(requestBody)
			});

			const body = await response.json();
			console.log("create-error-correspondence-log response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				await this.saveCorrespondenceLogNotes(errorID, tableID, message);
				//this.getErrorCorrespondenceLogs();
			} else {

			}

		}
		catch (err) {
			console.log(err);
		}

	};

	saveNote = (event) => {
		event.preventDefault();

		//console.log("Send message");
		let logID = event.target.logID.value;
    console.log(`logID is:`, logID);
		let tableID = event.target.tableID.value;
    console.log(`tableID is:`, tableID);
		let notes = event.target.notes.value;
		console.log(`notes is:`, notes);

		this.saveCorrespondenceLogNotes(logID, tableID, notes);
	}

	saveCorrespondenceLogNotes = async (logID, tableID, notes) => {

		var requestBody = {
			ErrorID: logID,
			TableID: tableID,
			NotesOnly: true,
			Notes:notes
		}

		console.log("create-error-correspondence-log requestBody: " + JSON.stringify(requestBody));

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-error-correspondence-log";

		try {

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: this.state.currentUser.jwtToken,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(requestBody)
			});

			const body = await response.json();
			console.log("create-error-correspondence-log response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.toggle4();
				this.setState({ modal4: false });
				this.getErrorCorrespondenceLogs();
			} else {

			}

		}
		catch (err) {
			console.log(err);
		}

	};

	enforcePhoneNumberPattern(string) {
	  let newString = string.match(/[0-9]{0,14}/g);

	  if (newString === null) {
	    return '';
	  }

	  // Join parts returned from RegEx match
	  newString = newString.join('');

	  // Start number with "+"
	  newString = '+' + newString;

	  // Limit length to 15 characters
	  newString = newString.substring(0, 15);

	  return newString;
	}

	// program to convert decimal to binary
	convertToBinary(x) {
		//console.log(`digit: `+x);
		let bin = 0;
		let rem, i = 1, step = 1;
		while (x != 0) {
				rem = x % 2;
				// console.log(
				// 		`Step ${step++}: ${x}/2, Remainder = ${rem}, Quotient = ${parseInt(x/2)}`
				// );
				x = parseInt(x / 2);
				bin = bin + rem * i;
				i = i * 10;
		}
		//console.log(`Binary: `+bin);

		return bin.toString();
	}

	filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id;

		//console.log("filterCaseInsensitive row[id]: " + JSON.stringify(id));



		if (id == 'CreationDate' || id == 'CorrespondenceDate') {
			var dateString = Moment(row[id]).utc().format('MM/DD/YYYY');
			return (
					dateString !== undefined ?
							String(dateString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (id == 'userInfo') {
			var userInfoString = row[id].name + " " + row[id].phone + " " + row[id].email  + " " + row[id].city  + " " + row[id].state  + " " + row[id].country ;
			return (
					userInfoString !== undefined ?
							String(userInfoString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (id == 'SerialFirmware') {
			var userInfoString = row[id].SerialNumber + " " + row[id].FirmwareVersion;
			return (
					userInfoString !== undefined ?
							String(userInfoString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (id == 'CTechCDate') {

			var dateString = Moment(row[id].CorrespondenceDate).utc().format('MM/DD/YYYY h:mma');

			var userInfoString = row[id].CorrespondenceTech + " " + dateString;
			return (
					userInfoString !== undefined ?
							String(userInfoString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (row[id] !== null){
			return (
					row[id] !== undefined ?
							String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}
	}

	getTrProps = (state, rowInfo, instance) => {

		console.log("getTrProps rowInfo: " + rowInfo);

		return {
			style: {
				"border-bottom": "solid 1px #eab82a"
			}
		};
  };

  render() {

    const data = this.state.filteredLogData.map((prop, key) => {

			var name = "";
			var email = "";
			var phone = "";
			var city = "";
			var state = "";
			var country = "";
			var canContact = false;
			var deviceLog = [];
			var loginID = "";
			var firmwareVersion = "";

			//console.log("prop " + JSON.stringify(prop));
			//console.log("prop.LoginID: " + JSON.stringify(prop.LoginID));

			var deviceLog =	this.state.devices.find(device => device.SerialNumber === prop.SerialNumber);
			//console.log("deviceLog: " + JSON.stringify(deviceLog));

			if (deviceLog) {
				loginID = deviceLog.LoginID;
				firmwareVersion = deviceLog.firmware_version;
				//console.log("deviceLog: " + JSON.stringify(deviceLog));
				//console.log("loginID: " + JSON.stringify(loginID));
				//console.log("prop.SerialNumber: " + JSON.stringify(prop.SerialNumber));

				if (firmwareVersion.length==1) {
					firmwareVersion = parseFloat(firmwareVersion).toFixed(1);
				}
			}

			if (prop.LoginID) {
				loginID = prop.LoginID;
			}

			//console.log("loginID: " + JSON.stringify(loginID));

			var userLog =	{};

			if (deviceLog && loginID) {

				city = deviceLog.geo_city;
				state = deviceLog.geo_state;
				country = deviceLog.geo_country;

				userLog =	this.state.users.find(user => user.Username.toLowerCase() === loginID.toLowerCase());

				if (userLog) {
					//console.log("userLog: " + JSON.stringify(userLog));
					name = userLog.Attributes.filter(attribute => attribute.Name == "given_name").map(name => name.Value) + " " + userLog.Attributes.filter(attribute => attribute.Name == "family_name").map(name => name.Value);
					canContact = userLog.Attributes.filter(attribute => attribute.Name == "custom:allowphonecontact").map(name => name.Value) == 1 ? "YES" : "NO";
					email = userLog.Attributes.filter(attribute => attribute.Name == "email").map(name => name.Value);
					phone = userLog.Attributes.filter(attribute => attribute.Name == "custom:phone").map(name => name.Value);
				}

			}

			if (prop.geo_city) {
				city = prop.geo_city;
				state = prop.geo_state;
				country = prop.geo_country;
			}

			var remoteSupportPath = "/remotesupport/"+prop.SerialNumber;
			var remotesupportblackbox = "/remotesupportblackbox/"+prop.SerialNumber;

			var correspondenceSent = false;
			var correspondenceDate = null;
			var correspondenceSubject = "";
			var correspondenceMessage = "";
			var correspondenceTech = "";
			var correspondenceNotes = "";

			//console.log("prop.id: " + JSON.stringify(prop.id));

			var correspondenceLog = this.state.errorCorrespondenceLogs.find(log => log.ECErrorID === prop.id);

			if (correspondenceLog) {

				//console.log("correspondenceLog: " + JSON.stringify(correspondenceLog));

				if (correspondenceLog.MessageSubject) {
					//console.log("correspondenceLog.MessageRecipient: " + JSON.stringify(correspondenceLog.MessageRecipient));
					//console.log("prop.id: " + JSON.stringify(prop.id));
					correspondenceSent = true;
				}

				correspondenceDate = correspondenceLog.CreationDate;
				correspondenceSubject = correspondenceLog.MessageSubject;
				correspondenceMessage = correspondenceLog.Message;
				correspondenceTech = correspondenceLog.SenderTechnicianName;
				correspondenceNotes = correspondenceLog.Notes;
			}

			//console.log("correspondenceSent: " + JSON.stringify(correspondenceSent));

			var binary = this.convertToBinary(prop.errorcode);

			//console.log("binary: " + binary);
			//console.log("approvedMaskBinary: " + approvedMaskBinary);

			var issueArray = [];

			if (prop.tableID==1) {

				console.log("prop.errorcode: " + prop.errorcode);
				console.log("prop.errorcode: " + prop.errorcode);

				var issue = "("+prop.errorcode+")"+" "+this.updateErrors[prop.errorcode].name;
				issueArray.push(issue);

			} else if (prop.tableID==2) {
				for (var i = 0; i < this.state.errorCodeData.length; i++){

					//UserApproveValue
					var errorCodeBinary = this.convertToBinary(this.state.errorCodeData[i].OBJID);

					//console.log("errorCodeBinary: " + errorCodeBinary);


					var indexBinary = binary.length - errorCodeBinary.length;
					//console.log("binary.charAt(indexBinary): " + binary.charAt(indexBinary));

					if (binary.charAt(indexBinary)==1) {
						//console.log("issue: " + this.state.errorCodeData[i].Name);

						var issue = "("+this.state.errorCodeData[i].OBJID+")"+" "+this.state.errorCodeData[i].Name;

						issueArray.push(issue);
					}

				}
			}

			return {
				id: key,
				logID: prop.id,
				SerialNumber: prop.SerialNumber,
				FirmwareVersion: firmwareVersion,
				SerialFirmware: { SerialNumber: prop.SerialNumber, FirmwareVersion: firmwareVersion },
        ErrorCode: prop.errorcode,
				Issue: issueArray,
        CreationDate: prop.CreationDate,
				CorrespondenceSent: correspondenceSent,
				CorrespondenceDate: correspondenceDate,
				CorrespondenceSubject: correspondenceSubject,
				CorrespondenceMessage: correspondenceMessage,
				CorrespondenceTech: correspondenceTech,
				CTechCDate: { CorrespondenceTech: correspondenceTech, CorrespondenceDate: correspondenceDate },
				userInfo: {name: name, email: email, phone: phone, city: city, state: state, country: country},
				name: name,
				email: email,
				city: city,
				state: state,
				canContact: canContact,
				Notes: correspondenceNotes,
				TableID: prop.tableID,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						<Button size="sm" color="link" href={remoteSupportPath} title="Remote Support" target="_blank">
							<i className="mdi mdi-lan-connect mdi-24px"/>
						</Button>
						{this.state.currentUser.payload["custom:role"] && this.state.currentUser.payload["custom:role"] > 1 &&
							<Button size="sm" color="link" href={remotesupportblackbox} title="Remote Support Black Box" target="_blank">
								<i className="mdi mdi-play-box-outline mdi-24px"/>
							</Button>
						}
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                //console.log('button obj LoginID: ' + obj.Email);

								this.setState({
									obj: obj,
								});

								this.getUserData(obj.SerialNumber);

								//this.showVerification(obj);
							}}
							color="link"
							size="sm"
							round="true"
							icon="true"
							 title="Send Message"
							className=""
						>
							<i className="mdi mdi-email mdi-24px" />
						</Button>
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
                //console.log('button obj LoginID: ' + obj.Email);

								this.setState({
									obj: obj,
								});

								this.setState({
									modal4: true,
								});

								//this.showVerification(obj);
							}}
							color="link"
							size="sm"
							round="true"
							icon="true"
							 title="Notes"
							className=""
						>
							<i className="mdi mdi-message-text-outline mdi-24px" />
						</Button>
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
								console.log('button obj: ' + obj);
								this.setState({
									modal: !this.state.modal,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-1"
							disabled={prop.tableID==1}
						>
							<i className="fa fa-trash-alt" />
						</Button>
					</div>
				)
			};
		});

		return (
			<Fragment>
				<Row>
					<Col md="12">
						<Card className="card-hover">
							<CardBody>
								<Row className="mb-3">
									<Col sm="12" md="4">
										<h1 style={{color:'#737782'}}>Error Messages</h1>
									</Col>
									<Col sm="12" md="4">
										<div className="mt-1 mb-1">
											<ButtonGroup style={{ width: '100%' }}>
												<Button
													size="sm"
													style={{width: '100%'}}
													color="btngray"
													onClick={() => this.onRadioBtnClick(0)}
													active={this.state.statusGroupSelected === 0}
												>
													All Errors
												</Button>
												<Button
													size="sm"
													className="ml-1"
													color="btngray"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(1)}
													active={this.state.statusGroupSelected === 1}
												>
													All Customer Errors
												</Button>
												<Button
													size="sm"
													className="ml-1"
													color="btngray"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(2)}
													active={this.state.statusGroupSelected === 2}
												>
													Internal Errors
												</Button>
											</ButtonGroup>
										</div>
									</Col>
									<Col sm="0" md="2">
									</Col>
									<Col sm="12" md="2">
									{this.state.loadingErrors || this.state.isAutoResolving ? (
										<Fragment>
											<Fade in="true">
												<Row className="justify-content-center">
													<Loader type="ThreeDots" color="#ffc108" height={50} width={200}/>
												</Row>
											</Fade>
										</Fragment>
									) : (
										<Fragment>
										{this.state.noActionNeededList.length > 0 &&
											<div>
												<Button className="float-right ml-3" color="yellow" onClick={this.saveBatchCorrespondence}>Auto Resolve</Button>
											</div>
										}
										</Fragment>
									)}
									</Col>
								</Row>
								<ReactTable
									style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
									columns={[
										{
											Header: "Serial/FW",
											accessor: "SerialFirmware",
											minWidth: 80,
											maxWidth: 110,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<Fragment>
													<div style={{ textAlign: "center" }}>
													<div style={{ textAlign: "center", width: "100%" }}>{row.value.SerialNumber}</div>
													<div style={{ textAlign: "center", width: "100%" }}>{row.value.FirmwareVersion}</div>
													</div>
													</Fragment>
												)
										},
                    {
											Header: "Issue",
											accessor: "Issue",
											minWidth: 200,
											maxWidth: 230,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
												<div style={{ textAlign: "center" }}>
													{row.value.map((issue, i) => (
														<Fragment>
														<Col xs="12">
															<span width="100%">{issue}</span>
														</Col>
														</Fragment>
														))
													}
												</div>
												)
										},
										{
											Header: "Error Date",
											accessor: "CreationDate",
											minWidth: 110,
											maxWidth: 140,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<Fragment>
													<div style={{ textAlign: "center" }}>
													<div style={{ textAlign: "center", width: "100%" }}>{Moment(row.value).format('MM/DD/YYYY')}</div>
													<div style={{ textAlign: "center", width: "100%" }}>{Moment(row.value).format('h:mm:ss a')}</div>
													</div>
													</Fragment>
												)
										},
										{
											Header: "User Info",
											accessor: "userInfo",
											minWidth: 280,
											maxWidth: 310,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<Fragment>
													<div style={{ textAlign: "center", width: "100%" }}>{row.value.name}</div>
													{
														// <Fragment>
														// <div className="float-left" style={{ textAlign: "right", width: "50%" }}>{row.value.city + ", " + row.value.state}</div>
														// <div className="float-right ml-2" style={{ textAlign: "left", width: "47%" }}>{row.value.country}</div>
														// </Fragment>
													}
													{row.value.city &&
														<div style={{ textAlign: "center", width: "100%" }}>{row.value.city}, {row.value.state} | {row.value.country}</div>
													}
													{row.value.phone &&
														<div style={{ textAlign: "center", width: "100%" }}>{row.value.phone}</div>
													}
													<div style={{ textAlign: "center", width: "100%" }}>{row.value.email}</div>
													</Fragment>
												)
										},
										{
											Header: "Contact",
											accessor: "canContact",
											minWidth: 60,
											maxWidth: 190,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
										{
											Header: "Handled",
											accessor: "CorrespondenceSent",
											minWidth: 70,
											maxWidth: 100,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<Fragment>
													{
														//mdi-close-circle-outline
													}
													{row.value == false ? (
														<i className="mdi mdi-checkbox-blank-outline mdi-24px ml-auto mr-auto" />
													) : (
														<i className="mdi mdi-checkbox-marked mdi-24px ml-auto mr-auto" />
													)}
													</Fragment>
												)
										},
										{
											Header: "Message Subject",
											accessor: "CorrespondenceSubject",
											minWidth: 250,
											maxWidth: 280,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
										{
											Header: "Tech/Date Sent",
											accessor: "CTechCDate",
											minWidth: 150,
											maxWidth: 180,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
												<Fragment>
												<div style={{ textAlign: "center" }}>
												<div style={{ textAlign: "center", width: "100%" }}>{row.value.CorrespondenceTech}</div>
												<div style={{ textAlign: "center", width: "100%" }}>{Moment(row.value.CorrespondenceDate).isValid() ? Moment(row.value.CorrespondenceDate).format('MM/DD/YYYY h:mma') : ""}</div>
												</div>
												</Fragment>
												)
										},
										{
											Header: "Actions",
											accessor: "actions",
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											width: 210,
											sortable: false,
											filterable: false
										}
									]}
									defaultPageSize={15}
									sortable={true}
									showPaginationBottom={true}
									className="-striped -highlight"
									data={data}
									filterable
									defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
									getTrProps={this.getTrProps}
									getTrGroupProps={(state, rowInfo, column, instance) => {
													if (rowInfo !== undefined) {
															return {
																	onClick: (e, handleOriginal) => {
																		console.log('It was in this row:', rowInfo)
																		console.log('with ID:', rowInfo.original.categoryID);

																		// this.setState({
																		// 	obj: rowInfo.original,
																		// 	modal4: true,
																		// });



																		//currentErrorCorrespondenceLog
																		// let obj = data.find(o => o.id === rowInfo.index);
																		// console.log('It was in this row obj:', obj)

																		//this.goToRepairLog(rowInfo.original.repairId);
																	}
															}
													}}
											}
								>
								{(state, makeTable, instance) => {
							            let recordsInfoText = "";

							            const { filtered, pageRows, pageSize, sortedData, page } = state;

							            if (sortedData && sortedData.length > 0) {
							              let isFiltered = filtered.length > 0;

							              let totalRecords = sortedData.length;

							              let recordsCountFrom = page * pageSize + 1;

							              let recordsCountTo = recordsCountFrom + pageRows.length - 1;

							              if (isFiltered)
							                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
							              else
							                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
							            } else recordsInfoText = "No records";

							            return (
							              <div className="main-grid mt-2">
															{makeTable()}
															<Row className="mt-2 text-center">
																<Col md="12">
																	<Label style={{fontSize:'15px'}} for=""><b>{recordsInfoText}</b></Label>
																</Col>
															</Row>
							              </div>
							            );
							          }}
							        </ReactTable>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<div>
				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>Delete Error Record</ModalHeader>
					<ModalBody>
						<span>Are you sure you want to delete this Error Record?</span>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.deleteErrorRecord}>Yes</Button>
						<Button color="secondary" className="ml-1" onClick={this.toggle}>Cancel</Button>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.modal2} toggle={this.toggle2}>
					{this.state.userEmail ? (
					<Fragment>

						<ModalHeader toggle={this.toggle2}>
						{this.state.messageType == "Phone Call" &&
							<Fragment>
							Phone Call
							</Fragment>
						}
						{this.state.messageType == "Other" &&
							<Fragment>
							Other
							</Fragment>
						}
						{this.state.messageType != "Phone Call" && this.state.messageType != "Notes" &&
							<Fragment>
							Send a Message
							</Fragment>
						}
						</ModalHeader>
						<ModalBody>
							<Row>
								<Col xs="12" md="12" lg="12" className="text-left pb-3">
									{this.state.messageType == "Phone Call" &&
										<Fragment>
										<h4>Log phone call to owner of Device: {this.state.obj.SerialNumber}</h4>
										</Fragment>
									}
									{this.state.messageType == "Other" &&
										<Fragment>
										<h4>Resolve for Device: {this.state.obj.SerialNumber}</h4>
										</Fragment>
									}
									{this.state.messageType != "Phone Call" && this.state.messageType != "Notes" &&
										<Fragment>
										<h4>Send message to owner of Device: {this.state.obj.SerialNumber}</h4>
										</Fragment>
									}
								</Col>
							</Row>
							<Form onSubmit={this.sendMessage}>
								<Input type="hidden" name="email" id="email" defaultValue={this.state.userEmail}/>
								<Input type="hidden" name="phone" id="phone" defaultValue={this.state.userPhoneNumber}/>
								<FormGroup>
					        <Label for="messagetype">Select Message Type:</Label>
					        <Input type="select" name="messagetype" id="messagetype" onChange={this.onChangeMessageType}>
					          <option>Email</option>
										<option>About your Gemini EVO update due to Wi-Fi</option>
										<option>About your Gemini EVO update due to low battery</option>
										<option>About your Gemini EVO activation pedal pairing</option>
										{this.state.userPhoneNumber.length > 0 &&
											<Fragment>
											<option>Text Message</option>
											<option>Phone Call</option>
											</Fragment>
										}
										<option>No Action Needed</option>
										<option>No Action Needed - Customer Rebonded</option>
										<option>Addressed on other occurance</option>
										<option>Internal Testing Unit</option>
										<option>Production Unit</option>
										<option>Other</option>
					        </Input>
					      </FormGroup>
								{(this.state.messageType == "Text Message" || this.state.messageType == "Email") &&
									<Fragment>
									<FormGroup>
										<Label for="subject">Subject:</Label>
										<Input type="text" name="subject" id="subject" placeholder="Enter your subject..."/>
									</FormGroup>
									<FormGroup>
										<Label for="message">Message:</Label>
										<Input type="textarea" name="message" id="message" placeholder="Enter your message..."/>
									</FormGroup>
									</Fragment>
								}
								{this.state.messageType == "Other" &&
									<Fragment>
									<FormGroup>
										<Label for="subject">Subject:</Label>
										<Input type="text" name="subject" id="subject" placeholder="Enter your subject..."/>
									</FormGroup>
									<FormGroup>
										<Label for="message">Notes:</Label>
										<Input type="textarea" name="message" id="message" placeholder="Enter your notes..." defaultValue={this.state.obj.Notes}/>
									</FormGroup>
									</Fragment>
								}
								{this.state.messageType == "No Action Needed" &&
									<Fragment>
									<FormGroup>
										<Label for="subject">Subject:</Label>
										<Input type="text" name="subject" id="subject" defaultValue="No Action Needed"/>
									</FormGroup>
									</Fragment>
								}
								{this.state.messageType == "No Action Needed - Customer Rebonded" &&
									<Fragment>
									<FormGroup>
										<Label for="subject">Subject:</Label>
										<Input type="text" name="subject" id="subject" defaultValue={this.state.messageType}/>
									</FormGroup>
									</Fragment>
								}
								{this.state.messageType == "Addressed on other occurance" &&
									<Fragment>
									<FormGroup>
										<Label for="subject">Subject:</Label>
										<Input type="text" name="subject" id="subject" defaultValue={this.state.messageType}/>
									</FormGroup>
									</Fragment>
								}
								{this.state.messageType == "Internal Testing Unit" &&
									<Fragment>
									<FormGroup>
										<Label for="subject">Subject:</Label>
										<Input type="text" name="subject" id="subject" defaultValue={this.state.messageType}/>
									</FormGroup>
									</Fragment>
								}
								{this.state.messageType == "Production Unit" &&
									<Fragment>
									<FormGroup>
										<Label for="subject">Subject:</Label>
										<Input type="text" name="subject" id="subject" defaultValue={this.state.messageType}/>
									</FormGroup>
									</Fragment>
								}
								{this.state.messageType == "Phone Call" &&
									<Fragment>
									<FormGroup>
						        <Label for="subject">Select Message Type:</Label>
						        <Input type="select" name="subject" id="subject">
											<option>Select</option>
						          <option>Phone Call - Resolved</option>
											<option>Phone Call - Voicemail</option>
						        </Input>
						      </FormGroup>
									<FormGroup>
										<Label for="message">Notes:</Label>
										<Input type="textarea" name="message" id="message" placeholder="Enter your call notes..." defaultValue={this.state.obj.Notes}/>
									</FormGroup>
									</Fragment>
								}
								<FormGroup>
									<Button color="primary" type="submit" disabled={this.state.isSending}>Send</Button>
									<Button color="secondary" className="ml-1" onClick={this.toggle2}>Cancel</Button>
								</FormGroup>
							</Form>
						</ModalBody>
					</Fragment>
				) : (
					<Fragment>
						{
							// <ModalHeader toggle={this.toggle2}>No Current Owner</ModalHeader>
							// <ModalBody>
							// 	<span>This device currently does not have an owner. {this.state.userEmail}</span>
							// </ModalBody>
							// <ModalFooter>
							// 	<Button color="secondary" className="ml-1" onClick={this.toggle2}>OK</Button>
							// </ModalFooter>
						}
						<ModalHeader toggle={this.toggle2}>
								Other
						</ModalHeader>
						<ModalBody>
							<Row>
								<Col xs="12" md="12" lg="12" className="text-left pb-3">
									<h4>Resolve for Device: {this.state.obj.SerialNumber}</h4>
								</Col>
							</Row>
							<Form onSubmit={this.sendMessage}>
								<Input type="hidden" name="email" id="email" defaultValue={this.state.userEmail}/>
								<Input type="hidden" name="phone" id="phone" defaultValue={this.state.userPhoneNumber}/>
								<FormGroup>
					        <Label for="messagetype">Select Message Type:</Label>
					        <Input type="select" name="messagetype" id="messagetype" onChange={this.onChangeMessageType}>
										<option>Other</option>
					        </Input>
					      </FormGroup>
								<FormGroup>
									<Label for="subject">Subject:</Label>
									<Input type="text" name="subject" id="subject" placeholder="Enter your subject..."/>
								</FormGroup>
								<FormGroup>
									<Label for="message">Notes:</Label>
									<Input type="textarea" name="message" id="message" placeholder="Enter your notes..." defaultValue={this.state.obj.Notes}/>
								</FormGroup>
								<FormGroup>
									<Button color="primary" type="submit" disabled={this.state.isSending}>Send</Button>
									<Button color="secondary" className="ml-1" onClick={this.toggle2}>Cancel</Button>
								</FormGroup>
							</Form>
						</ModalBody>
					</Fragment>
				)}
				</Modal>
				<Modal isOpen={this.state.modal3} toggle={this.toggle3}>
  				<ModalHeader toggle={this.toggle3}>{this.state.statusSubject}</ModalHeader>
  				<ModalBody>
						<span>{this.state.statusMessage}</span>
  				</ModalBody>
					<ModalFooter>
						<Button color="primary" className="ml-1" onClick={this.toggle3}>OK</Button>
					</ModalFooter>
			  </Modal>
				<Modal isOpen={this.state.modal4} toggle={this.toggle4}>
  				<ModalHeader toggle={this.toggle4}>Error Report Notes</ModalHeader>
  				<ModalBody>
						<Form onSubmit={this.saveNote}>
							<Input type="hidden" name="logID" id="logID" defaultValue={this.state.obj.logID}/>
							<Input type="hidden" name="tableID" id="tableID" defaultValue={this.state.obj.TableID}/>
							<FormGroup>
								<Label for="notes">Notes:</Label>
								<Input rows="10" type="textarea" name="notes" id="notes" placeholder="Enter error report notes..." defaultValue={this.state.obj.Notes}/>
							</FormGroup>
							<FormGroup>
								<Button className="float-right ml-2" color="primary" type="submit">Save</Button>
								<Button className="float-right" color="secondary" onClick={this.toggle4}>Cancel</Button>
							</FormGroup>
						</Form>
  				</ModalBody>
			  </Modal>
				</div>
			</Fragment>
		);
	}
}

export default ErrorMessagesList;
