import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";
import { saveAs } from "file-saver";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import laserTrainingLogo from '../../../assets/images/laser-training-logo-2.png';
import drKusekThumb from '../../../assets/images/Thumbnail-Ultradent-Dr-Kusek.png';
import rdhKristinThumb from '../../../assets/images/Thumbnail-Ultradent-RDH-Kristin.png';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class LaserCETrainingView extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			noDevices: false,
			devicesLoaded: false,
			currentUser,
			modal: false,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			devices: [],
			downloads: []
	  };

		// this.toggle4 = this.toggle4.bind(this);
		// this.toggle5 = this.toggle5.bind(this);
		// this.toggle6 = this.toggle6.bind(this);
		// this.handleDateChange = this.handleDateChange.bind(this);

  }

	async componentDidMount() {

		//this.getData();
    //this.getDownloadsData();

		//console.log("device list: " + JSON.stringify(this.state.deviceList));

		//let obj = data1.find(o => o.id === key);
	}

	componentWillUnmount() {
		//clearInterval(this.intervalID);
	}

	render() {
		return (
			<Fragment>
				<Row className="mb-3">
					<Col md="8">
						<h1 style={{color:'#737782'}}>Ultradent’s Free Online Laser Dentistry Training Program</h1>
					</Col>
          <Col md="4">
            <div className="float-right" style={{ width: '350px', marginTop: '-15px'}}>
              <img style={{ maxWidth: '350px' }} className="mr-2" src={laserTrainingLogo}/>
            </div>
					</Col>
				</Row>
        <Row className="mb-4">
					<Col md="12">
          <div className="text-center" style={{ width: '100%'}}>
            <a href="https://share.hsforms.com/11QSp_r6EQGCKC169oKlXIw3gd5z" target="_blank">
              <Button
                size="md"
                style={{width: '400px'}}
                color="yellow"
              >
                Sign Up for Access
              </Button>
            </a>
          </div>
					</Col>
				</Row>
				<Row className="mb-3">
          <Col sm="12" md="12" lg="6" xl="6">
            <Card className="" style={{ height: "980px" }}>
              <CardBody>
                <Row className="mb-3">
                  <Col xs="10">
                    <h3 style={{color:'#ffc107'}}>3 CE CREDITS</h3>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="12" className="text-center">
                    <div style={{ width: '100%'}}>
                      <img style={{ maxWidth: '100%' }} className="mr-2" src={drKusekThumb}/>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="12" className="">
                    <div style={{ width: '100%'}}>
                      <p style={{color:'#ffc107'}}><strong>Laser-Assisted Soft Tissue Applications in Every Day Dentistry by Dr. Kusek</strong></p>
                      <p>Dr. Kusek&apos;s course will help you understand laser physics, the types of lasers in the dental industry, and how to implement a diode laser into your practice. He will discuss multiple uses for your Gemini and Gemini Evo Laser. Clinical procedures covered include:</p>
                      <ul style={{ marginLeft: "40px", "font-size": "1.3em" }}>
                        <li style={{ "list-style-type": "circle" }}>Troughing for analog or virtual impression</li>
                        <li style={{ "list-style-type": "circle" }}>Frenectomies</li>
                        <li style={{ "list-style-type": "circle" }}>Biopsies</li>
                        <li style={{ "list-style-type": "circle" }}>Esthetic recontouring</li>
                        <li style={{ "list-style-type": "circle" }}>Operculectomy</li>
                        <li style={{ "list-style-type": "circle" }}>Implant uncovering</li>
                        <li style={{ "list-style-type": "circle" }}>Treating erosive lichen planus</li>
                        <li style={{ "list-style-type": "circle" }}>Laser desensitization</li>
                        <li style={{ "list-style-type": "circle" }}>Gingivectomies</li>
                        <li style={{ "list-style-type": "circle" }}>Creating ovate pontics</li>
                        <li style={{ "list-style-type": "circle" }}>Photobiomodulation</li>
                      </ul>
                      <p>We will introduce you to photobiomodulation (PBM) for treatment of aphthous and herpetic ulcers, TMJ pain, pain relief for tooth extractions, post-surgical sites, and dry socket. This dental CE course will also cover treatment planning, medical billing and coding, patient management, and laser safety.</p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" md="12" lg="6" xl="6">
            <Card className="" style={{ height: "980px" }}>
              <CardBody>
                <Row className="mb-3">
                  <Col xs="12">
                    <h3 style={{color:'#ffc107'}}>2 CE CREDITS</h3>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="12" className="text-center">
                    <div style={{ width: '100%'}}>
                      <img style={{ maxWidth: '100%' }} className="mr-2" src={rdhKristinThumb}/>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="12" className="">
                    <div style={{ width: '100%'}}>
                      <p style={{color:'#ffc107'}}><strong>Diode Laser Training for the Dental Hygienist by Kristin Hunter</strong></p>
                      <p>Kristin Hunter will introduce you to lasers in dental hygiene. She will help you gain an understanding of the use of lasers and review procedures that are within the hygienist&apos;s scope of practice. She will cover laser physics and different types of lasers in the dental industry, as well as how to get the best Gemini and Gemini EVO diode laser setup in your office. She will review topics such as:</p>
                      <ul style={{ marginLeft: "40px", "font-size": "1.3em" }}>
                        <li style={{ "list-style-type": "circle" }}>Laser Bacterial Reduction (LBR)</li>
                        <li style={{ "list-style-type": "circle" }}>Laser-assisted Periodontal Therapy (LAPT)</li>
                        <li style={{ "list-style-type": "circle" }}>Photobiomodulation (PBM)</li>
                        <li style={{ "list-style-type": "circle" }}>Patient management</li>
                        <li style={{ "list-style-type": "circle" }}>Treatment planning</li>
                        <li style={{ "list-style-type": "circle" }}>Billing and Coding</li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
				</Row>
        <Row className="">
					<Col md="12">
          <div className="text-center" style={{ width: '100%'}}>
            <a href="https://share.hsforms.com/11QSp_r6EQGCKC169oKlXIw3gd5z" target="_blank">
              <Button
                size="md"
                style={{width: '400px'}}
                color="yellow"
              >
                Sign Up for Access
              </Button>
            </a>
          </div>
					</Col>
				</Row>
        <Row className="mt-5">
					<Col md="12">
          <div className="text-center" style={{ width: '100%'}}>
            <h5>If you need any further assistance, please contact Ultradent at <a href="mailto:lasertraining@ultradent.com?subject=Free%20Laser%20Training%20Help">lasertraining@ultradent.com</a></h5>
          </div>
					</Col>
				</Row>
			</Fragment>
		);
	}
}

export default LaserCETrainingView;
