import React from "react";
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ButtonDropdown,
	Tooltip
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import Moment from 'moment';
var moment = require('moment-timezone');

let lineOptions = {
	maintainAspectRatio: false,
	elements: { point: { show: true } },
	layout: {
			padding: {
				right: 6
			}
	},
	tooltips: {
		titleFontSize: 16,
		bodyFontSize: 14
	},
	plugins: {
		datalabels: {
			display: false,
		}
	},
	scales: {
		xAxes: [{
			gridLines: {
				display: false,
				drawBorder: false,
			},
			ticks: {
				display: false
			}
		}],
		yAxes: [{
			gridLines: {
				display: false,
				drawBorder: false,
			},
			ticks: {
				display: false
			}
		}]
	},
	legend: {
		display: false,
		labels: {
			fontColor: 'rgb(255, 99, 132)'
		}
	}

}

class TotalHourlyUsagePVM extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);
    this.state = {
			usage: [],
			chartData: {},
			noDevices: false,
			noDataForDevices: false,
			currentDataSetting: 0,
			startDate: Moment().subtract(1, 'week').format('YYYY-MM-DD'),
			endDate: Moment().format('YYYY-MM-DD'),
			currentDate: Moment().format('YYYY-MM-DD'),
			isOpen: false,
			currentItem: "Global",
			tooltipOpen: false,
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			presetTotal: 0,
			manualTotal: 0,
			marketType: localStorage.getItem('marketType') ? JSON.parse(localStorage.getItem('marketType')) : 0
	  };

		this.toggleDropDown = this.toggleDropDown.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.toggleTooltip = this.toggleTooltip.bind(this);
  }

	async componentDidMount() {

		document.body.addEventListener('set-market-type', this.handleMarketTypeChange.bind(this));
		//console.log("pvm marketType: " + this.state.marketType);

		if (this.state.currentUser) {
			if (this.state.currentUser.payload["custom:role"] && this.state.currentUser.payload["custom:role"]>0) {
				this.selectDropDownItem(1, "By Week");
			}
			console.log("totalhourlyusage currentUser: " + JSON.stringify(this.state.currentUser));
		}

		console.log("totalhourlyusage deviceList: " + JSON.stringify(this.state.deviceList));

		//this.getData();
		setTimeout(this.getData, 200);

		if (this.state.marketType==0) {
			this.intervalID = setInterval(()=>{ this.getData(); }, 600000);
		} else {
			this.intervalID = setInterval(()=>{ this.getData(); }, 600000);
		}

		console.log("Start Date: " + JSON.stringify(this.state.startDate));
		console.log("End Date: " + JSON.stringify(this.state.endDate));
	}

	handleMarketTypeChange(event) {
    console.log("PvM Market has changed to: ", event.detail);
    // your business logic here .. this.setState({...});
		this.setState({ marketType: event.detail.id });
		setTimeout(this.getData, 200);

		if (event.detail.id==0) {
			clearInterval(this.intervalID);
			this.intervalID = setInterval(()=>{ this.getData(); }, 600000);
		} else {
			clearInterval(this.intervalID);
			this.intervalID = setInterval(()=>{ this.getData(); }, 600000);
		}
  }

	toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

	toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	handleChange(event) {
		if (event.target.getAttribute('name') == 'startDate') {
			this.setState({startDate: event.target.value});
			this.setState({
	      currentItem: "Custom Range",
				currentDataSetting: 3
	    });
		} else if (event.target.getAttribute('name') == 'endDate') {
			//console.log('this.state.code: ' + event.target.value);
			this.setState({endDate: event.target.value});
			this.setState({
				currentItem: "Custom Range",
				currentDataSetting: 3
	    });
		}
		setTimeout(()=>{ this.getData(); }, 1000);
  }

	handleSubmit(event) {

		this.getData();
  }

	selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		if (id==1) {
			this.setState({ startDate: Moment().subtract(1, 'week').format('YYYY-MM-DD') });
		} else if (id==2) {
			this.setState({ startDate: Moment().subtract(1, 'month').format('YYYY-MM-DD') });
		} else if (id==3) {
			this.setState({ startDate: Moment().format('YYYY-MM-DD') });
		}

		this.setState({ endDate: Moment().format('YYYY-MM-DD') });
		//this.getData();
		setTimeout(this.getData, 1000);
  }

	stepBack () {
		if (this.state.currentDataSetting==1) {
			let revisedStartDate = Moment(this.state.startDate).subtract(1, 'week').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).subtract(1, 'week').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		} else if (this.state.currentDataSetting==2) {
			let revisedStartDate = Moment(this.state.startDate).subtract(1, 'month').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).subtract(1, 'month').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		}
		setTimeout(()=>{ this.getData(); }, 1000);
	}

	stepForward () {
		if (this.state.currentDataSetting==1) {
			let revisedStartDate = Moment(this.state.startDate).add(1, 'week').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).add(1, 'week').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		} else if (this.state.currentDataSetting==2) {
			let revisedStartDate = Moment(this.state.startDate).add(1, 'month').format('YYYY-MM-DD');
			let revisedEndDate = Moment(this.state.endDate).add(1, 'month').format('YYYY-MM-DD');
			console.log("Revised Start Date: " + JSON.stringify(revisedStartDate));
			console.log("Revised End Date: " + JSON.stringify(revisedEndDate));
			this.setState({ startDate: revisedStartDate });
			this.setState({ endDate: revisedEndDate });
		}
		setTimeout(()=>{ this.getData(); }, 1000);
	}

	componentWillUnmount() {
		//console.log("TotalHourlyUsagePVM componentWillUnmount");
		clearInterval(this.intervalID);
		document.body.removeEventListener('set-market-type', this.handleMarketTypeChange.bind(this));
	}

	getData = async () => {

		var timezone = Moment.tz.guess();

		if (this.state.currentDataSetting==0) {

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"total-hourly-usage?MarketType="+this.state.marketType+"&Timezone="+timezone;
			//url = encodeURI(url)
			console.log("total-hourly-usage url: " + url);
			var token = "";

			if (this.state.currentUser) {
				token = this.state.currentUser.jwtToken;
			}

			if (this.props.authToken) {
				console.log("TotalHourlyUsagePVM this.props.authToken: " + this.props.authToken);
				token = this.props.authToken;
			}

			try {

				const response = await fetch(url, {
					method: 'GET',
					headers: {
						Authorization: token
					},
				});

				const body = await response.json();
				console.log("totalhourlyusage body response: " + JSON.stringify(body));

				if (body.status == 'Success') {
					if (body.results.length>0) {
						this.createGraph(body.results);

						let manual = body.results.map(k => k.manual);
						let preset = body.results.map(k => k.preset);

						this.setState({ presetTotal: manual.length});
						this.setState({ manualTotal: preset.length});
						console.log("start Date is " + JSON.stringify(body.results[0].date));
						console.log("end Date is " + JSON.stringify(body.results[body.results.length-1].date));
						this.setState({ startDate: Moment(body.results[0].date).format('YYYY-MM-DD') });
						this.setState({ endDate: Moment(body.results[body.results.length-1].date).format('YYYY-MM-DD') });
						//this.state.noDataForDevices = false;
						this.setState({noDataForDevices: false});
					} else {
						//this.setState({noDataForDevices: true});
						if (this.state.chartData.length>0) {
							this.setState({
								noDataForDevices: false
							});
						} else {
							this.setState({
								noDataForDevices: true
							});
						}
					}
				} else {
					//this.setState({noDataForDevices: true});
					if (this.state.chartData.length>0) {
						this.setState({
							noDataForDevices: false
						});
					} else {
						this.setState({
							noDataForDevices: true
						});
					}
				}

			}
			catch (err) {
				console.log(err);
			}

		} else {

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"total-hourly-usage-date-range?MarketType="+this.state.marketType+"&StartDate="+this.state.startDate+"&EndDate="+this.state.endDate+"&Timezone="+timezone;
			console.log("total-hourly-usage-date-range url: " + url);
			var token = "";

			if (this.state.currentUser) {
				token = this.state.currentUser.jwtToken;
			}

			if (this.props.authToken) {
				console.log("TotalHourlyUsagePVM this.props.authToken: " + this.props.authToken);
				token = this.props.authToken;
			}

			try {

				const response = await fetch(url, {
					method: 'GET',
					headers: {
						Authorization: token
					},
				});

				const body = await response.json();
				console.log("totalhourlyusage date range body response: " + JSON.stringify(body));

				if (body.status == 'Success') {
					if (body.results.length>0) {
						this.createGraph(body.results);
						let manual = body.results.map(k => k.manual);
						let preset = body.results.map(k => k.preset);

						this.setState({ presetTotal: manual.length});
						this.setState({ manualTotal: preset.length});

						//this.state.noDataForDevices = false;
						this.setState({noDataForDevices: false});
					} else {
						//this.setState({noDataForDevices: true});
						if (this.state.chartData.length>0) {
							this.setState({
								noDataForDevices: false
							});
						} else {
							this.setState({
								noDataForDevices: true
							});
						}
					}
				} else {
					//this.setState({noDataForDevices: true});
					if (this.state.chartData.length>0) {
						this.setState({
							noDataForDevices: false
						});
					} else {
						this.setState({
							noDataForDevices: true
						});
					}
				}

			}
			catch (err) {
				console.log(err);
			}

		}

	}

	createGraph = (results) => {

		const { keywordData } = results;
		const chartData = {
			labels: results.map(k => Moment(k.CreationDate).format('MMM Do YYYY')),
			datasets: [{
				label: 'Total Manual Procedures',
				borderWidth: 0,
				backgroundColor: 'rgba(65,71,85,.6)',
				borderColor: 'rgba(65,71,85,.6)',
				pointBorderColor: 'rgba(65,71,85,.6)',
				pointBackgroundColor: 'rgba(255,199,44)',
				data: results.map(k => k.manual)
			},
			{
				label: 'Total Preset Procedures',
				borderWidth: 0,
				backgroundColor: 'rgba(233,236,239)',
				borderColor: 'rgba(233,236,239)',
				pointBorderColor: 'rgba(233,236,239)',
				pointBackgroundColor: 'rgba(255,199,44)',
				data: results.map(k => k.preset)
			}]
		}

		this.setState({ usage: results });
		this.setState({ chartData });
	}

	render() {

		return (
      <Card className="card-hover">
				<CardBody style={{ minHeight: 250 }}>
						<Row>
							<Col xs="6" md="6" lg="6" className="text-left">
								<CardTitle>Preset vs Manual
								<i style={{color: '#ffffff'}} className="mdi mdi-information-outline ml-1" id="PVMtooltip"/>
								<Tooltip placement="top" isOpen={this.state.tooltipOpen} target="PVMtooltip" toggle={this.toggleTooltip}>
					        This is the mix of pre-set vs manual procedures conducted in the desired time period.
					      </Tooltip>
								</CardTitle>
							</Col>
							<Col xs="6" md="6" lg="6" className="text-right">
								<ButtonDropdown isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
									<DropdownToggle color="dark" caret>
										{this.state.currentItem}
									</DropdownToggle>
									<DropdownMenu right>

										<DropdownItem onClick={() => this.selectDropDownItem(0, 'Global')}>Global</DropdownItem>
										<DropdownItem onClick={() => this.selectDropDownItem(1, 'By Week')}>By Week</DropdownItem>
										<DropdownItem onClick={() => this.selectDropDownItem(2, 'By Month')}>By Month</DropdownItem>
										<DropdownItem onClick={() => this.selectDropDownItem(3, 'Custom Range')}>Custom Range</DropdownItem>
									</DropdownMenu>
								</ButtonDropdown>
							</Col>
						</Row>
						{this.state.noDevices ? (
							<div className="mt-5 text-center">
								<h5>User has no devices</h5>
							</div>
						) : (
							<Fragment>
							{this.state.currentDataSetting == 0 || this.state.currentDataSetting == 3 ? (
								<Fragment>
								<div className="ml-auto mt-1">
									<div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 125 }}>
										{this.state.noDataForDevices ? (
											<div className="d-flex justify-content-center align-items-center" style={{ height: '100%'}}>
												<h5>No data for user's devices</h5>
											</div>
										) : (
											<Line data={this.state.chartData} options={lineOptions} />
										)}
									</div>
									<div className="text-center" style={{ width: '100%'}}>
										<ul className="list-inline font-12 dl mr-3 mb-0">
											<li className="border-0 p-0 list-inline-item" style={{color:'#e9ecef'}}>
												<i className="fa fa-circle"></i> Pre-Set ({this.state.presetTotal})</li>
											<li className="border-0 p-0 list-inline-item" style={{color:'#71747c'}}>
												<i className="fa fa-circle"></i> Manual ({this.state.manualTotal})</li>
										</ul>
									</div>
									{this.state.currentDataSetting != 0 &&
									<Fragment>
									<Row className="mt-2">
										<Col lg="12" xl="6" className="text-right">
											<div className="float-left" style={{ minWidth: '10%' }}>
												<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>From:</h6>
											</div>
											<div className="float-left ml-1" style={{ minWidth: '75%' }}>
												<Input style={{ minWidth: '135px' }} name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleChange} />
											</div>
										</Col>
										<Col lg="12" xl="6" className="">
											<div className="float-left text-right" style={{ minWidth: '10%' }}>
												<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>To:</h6>
											</div>
											<div className="float-left ml-1" style={{ minWidth: '75%' }}>
												<Input style={{ minWidth: '135px' }} name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
											</div>
											{
												// <div className="float-left ml-1" style={{ minWidth: '16%' }}>
												// 	<Button size="sm" color="dark" onClick={() => this.handleSubmit()} title="Vitals">
												// 		GO
												// 	</Button>
												// </div>
											}
										</Col>
									</Row>
									</Fragment>
									}
								</div>
								</Fragment>
							) : (
								<Fragment>
								<Row className="align-items-center">
									<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
										<Button size="sm" color="link" onClick={() => this.stepBack()} title="Vitals">
											<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-left mdi-24px"/>
										</Button>
									</div>
									<div style={{ width: '80%', margin: '0 auto', height: '100%' }}>
										<div className="ml-4 mt-1">
											<div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 125 }}>
												{this.state.noDataForDevices ? (
													<div className="d-flex justify-content-center align-items-center" style={{ height: '100%'}}>
														<h5>No data for user's devices</h5>
													</div>
												) : (
													<Line data={this.state.chartData} options={lineOptions} />
												)}
											</div>
											<div className="text-center" style={{ width: '100%'}}>
												<ul className="list-inline font-12 dl mr-3 mb-0">
													<li className="border-0 p-0 list-inline-item" style={{color:'#e9ecef'}}>
														<i className="fa fa-circle"></i> Pre-Set</li>
													<li className="border-0 p-0 list-inline-item" style={{color:'#71747c'}}>
														<i className="fa fa-circle"></i> Manual</li>
												</ul>
											</div>
										</div>
									</div>
									<div style={{ width: '10%', margin: '0 auto', height: '100%' }}>
										<Button size="sm" color="link" disabled={this.state.currentDate==this.state.endDate} onClick={() => this.stepForward()} title="Vitals">
											<i style={{color: '#b3b5bb'}} className="mdi mdi-arrow-right mdi-24px"/>
										</Button>
									</div>
								</Row>
								<Row className="mt-2">
									<Col lg="12" xl="6" className="text-right">
										<div className="float-left" style={{ minWidth: '10%' }}>
											<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>From:</h6>
										</div>
										<div className="float-left ml-1" style={{ minWidth: '75%' }}>
											<Input style={{ minWidth: '135px' }} name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleChange} />
										</div>
									</Col>
									<Col lg="12" xl="6" className="">
										<div className="float-left text-right" style={{ minWidth: '10%' }}>
											<h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>To:</h6>
										</div>
										<div className="float-left ml-1" style={{ minWidth: '75%' }}>
											<Input style={{ minWidth: '135px' }} name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
										</div>
										{
											// <div className="float-left ml-1" style={{ minWidth: '16%' }}>
											// 	<Button size="sm" color="dark" onClick={() => this.handleSubmit()} title="Vitals">
											// 		GO
											// 	</Button>
											// </div>
										}
									</Col>
								</Row>
								</Fragment>
							)}
							</Fragment>
						)}
        </CardBody>
      </Card>
		);
	}
}

export default TotalHourlyUsagePVM;
