import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class FootpedalReportList extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			obj: {},
			bomData: [],
      fpReportData: [],
			statusGroupSelected: 1,
			modal: false,
			userMarket: 0,
			isInternationalTech: false,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

		this.toggle = this.toggle.bind(this);
		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);

  }


	async componentDidMount() {

		//var userMarket = 0;

		//this.getBOMData(userMarket);

    this.getFootpedalReportData();
	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	async onRadioBtnClick(statusGroupSelected) {

		await this.setState({ statusGroupSelected });

		//setTimeout(() => this.getBOMData(userMarket), 300);
		//this.getBOMData(userMarket);

		//this.getFootpedalReportData();
		setTimeout(() => this.getFootpedalReportData(), 300);

	}

	componentWillUnmount() {
		//console.log("Laser-Usage componentWillUnmount");
		clearInterval(this.intervalID);
	}

  getFootpedalReportData = async () => {

		var url = "https://hui6wcvz1i.execute-api.us-west-2.amazonaws.com/v1/get-footpedal-report-data?Status="+this.state.statusGroupSelected;

    try {

  		const response = await fetch(url, {
  			method: 'GET',
  			headers: {
  				Authorization: this.state.currentUser.jwtToken
  			},
  		});

  		const body = await response.json();
  		console.log("all getFootpedalReportData data: " + JSON.stringify(body));

  		if (body.status == 'Success') {
  			this.setState({ fpReportData: body.response });
  		} else {
        //ADD ERROR MESSAGE
      }

    } catch (error) {
        console.log('error getFootpedalReportData: ', error);
    }

	};


	getBOMData = async (userMarket) => {

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com/v1/get-bill-of-materials?MarketID="+userMarket;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all BOM data: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ bomData: body.response });
		} else {
      //ADD ERROR MESSAGE
    }

	};

	setFootpedalReplacementStatus = async (replacementID, status) => {

		//console.log("Delete Note: " + this.state.obj.description);

		var url = "https://hui6wcvz1i.execute-api.us-west-2.amazonaws.com/v1/set-footpedal-replacement-status?ReplacementID="+replacementID+"&Status="+status;

    console.log("setFootpedalReplacementStatus url: " + url);

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("setFootpedalReplacementStatus response: " + JSON.stringify(body));

			if (body.status == 'Success') {

	      this.getFootpedalReportData();

			} else {


			}

		} catch (error) {
				console.log('error setFootpedalReplacementStatus: ', error);
		}

	};

	filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id;

		//console.log("filterCaseInsensitive row[id]: " + JSON.stringify(id));



		if (id == 'RatingDate') {
			var dateString = Moment(row[id]).utc().format('MM/DD/YYYY');
			return (
					dateString !== undefined ?
							String(dateString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (row[id] !== null){
			return (
					row[id] !== undefined ?
							String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}
	}

  render() {

    const data = this.state.fpReportData.map((prop, key) => {

			return {
				id: key,
				fpID: prop.id,
				Type: prop.Type,
        FirstName: prop.FirstName,
        LastName: prop.LastName,
        Email: prop.Email,
        Address: prop.Address,
        Address2: prop.Address2,
        City: prop.City,
        State: prop.State,
        Zip: prop.Zip,
				SerialNumber: prop.SerialNumber,
				Batch: prop.Batch,
        Date: prop.DateSubmitted,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{this.state.statusGroupSelected==0 ? (
							<Fragment>
								<Button
									onClick={() => {
										let obj = data.find(o => o.id === key);
										console.log('button obj: ' + obj);
										this.setState({
											obj: obj
										});

										this.setFootpedalReplacementStatus(obj.fpID, 1);
									}}
									color="brightgreen"
									size="sm"
									round="true"
									icon="true"
									className="ml-2"
								>
									Completed
								</Button>
							</Fragment>
						) : (
							<Fragment>
								<Button
									onClick={() => {
										let obj = data.find(o => o.id === key);
										console.log('button obj: ' + obj);
										this.setState({
											obj: obj
										});

										this.setFootpedalReplacementStatus(obj.fpID, 0);
									}}
									color="danger"
									size="sm"
									round="true"
									icon="true"
									className="ml-2"
								>
									Remove
								</Button>
							</Fragment>
						)}

						{/* use this button to add a edit kind of action */}
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let obj = data2.find(o => o.id === key);
                console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
							}}
							color="danger"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
						>
							<i className="fa fa-trash-alt" />
						</Button> */}
					</div>
				)
			};
		});

    // console.log("promotors total: " + JSON.stringify(promotors));
    // console.log("detractors total: " + JSON.stringify(detractors));
    //
    // var promotorsRatio = (promotors/participants)*100;
    // var detractorsRatio = (detractors/participants)*100;
    //
    // console.log("promotorsRatio: " + JSON.stringify(promotorsRatio));
    // console.log("detractorsRatio: " + JSON.stringify(detractorsRatio));
    //
    // if (this.state.npsData.length>0) {
    //   npsScore = parseFloat(promotorsRatio - detractorsRatio).toFixed(1);
    // }

		return (
			<Fragment>
				<Row>
					<Col md="12">
						<Card className="card-hover">
							<CardBody>
								<Row className="mb-3">
									<Col md="5">
										<h1 style={{color:'#737782'}}>Footpedal Replacement Report</h1>
									</Col>
									<Col md="2">
										<div className="mt-1 mb-1">
											<ButtonGroup style={{ width: '100%' }}>
												<Button
													style={{width: '100%'}}
													color="btngray"
													onClick={() => this.onRadioBtnClick(1)}
													active={this.state.statusGroupSelected === 1}
												>
													Replaced
												</Button>
												<Button
													className="ml-1"
													color="btngray"
													style={{width: '100%'}}
													onClick={() => this.onRadioBtnClick(0)}
													active={this.state.statusGroupSelected === 0}
												>
													Pending
												</Button>
											</ButtonGroup>
										</div>
									</Col>
									<Col className="text-right" md="5">
										{this.state.statusGroupSelected==1 ? (
											<h4 className="float-right mt-2 mr-2" style={{color:'#FFC108'}}>Total Replaced: {this.state.fpReportData.length}</h4>
										) : (
											<h4 className="float-right mt-2 mr-2" style={{color:'#FFC108'}}>Total Not Replaced: {this.state.fpReportData.length}</h4>
										)}
									</Col>
								</Row>
								<ReactTable
									style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
									columns={[
                    {
											Header: "Serial",
											accessor: "SerialNumber",
											minWidth: 6,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
                    {
											Header: "Batch",
											accessor: "Batch",
											minWidth: 8,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
										{
											Header: "Kit",
											accessor: "Type",
											minWidth: 6,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
										{
											Header: "First Name",
											accessor: "FirstName",
											minWidth: 14,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
										{
											Header: "Last Name",
											accessor: "LastName",
											minWidth: 12,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
                    {
											Header: "Address",
											accessor: "Address",
											minWidth: 15,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
                    {
											Header: "Address2",
											accessor: "Address2",
											minWidth: 10,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
                    {
											Header: "City",
											accessor: "City",
											minWidth: 15,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
                    {
											Header: "State",
											accessor: "State",
											minWidth: 10,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
                    {
											Header: "Zip",
											accessor: "Zip",
											minWidth: 8,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
										{
											Header: "Email",
											accessor: "Email",
											minWidth: 20,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{row.value}</div>
												)
										},
										{
											Header: "Date Submitted",
											accessor: "Date",
											minWidth: 12,
											style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
											Cell: row => (
													<div style={{ textAlign: "center" }}>{Moment(row.value).utc().format('MM/DD/YYYY')}</div>
												)
										},
										{
											Header: "Actions",
											accessor: "actions",
											width: 100,
											sortable: false,
											filterable: false
										}
									]}
									defaultPageSize={15}
									sortable={true}
									showPaginationBottom={true}
									className="-striped -highlight"
									data={data}
									filterable
									defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
									getTrGroupProps={(state, rowInfo, column, instance) => {
													if (rowInfo !== undefined) {
															return {
																	onClick: (e, handleOriginal) => {
																		console.log('It was in this row:', rowInfo)
																		console.log('with ID:', rowInfo.original.categoryID);

																		//this.goToRepairLog(rowInfo.original.repairId);
																	}
															}
													}}
											}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<div>
				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>Delete Feedback</ModalHeader>
					<ModalBody>
						<span>Are you sure you want to delete this User's Feedback?</span>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.deleteFeedback}>Yes</Button>
						<Button color="secondary" className="ml-1" onClick={this.toggle}>Cancel</Button>
					</ModalFooter>
				</Modal>
				</div>
			</Fragment>
		);
	}
}

export default FootpedalReportList;
