import React from "react";
import {
	Progress,
	Card,
  CardTitle,
	CardBody,
	Row,
	Col
} from 'reactstrap';

import Chart from 'react-c3-component';
import 'c3/c3.css';

import img1 from '../../../assets/images/background/active-bg.png';
import img2 from '../../../assets/images/dashboard/wavelength_color_guide.png';

class WavelengthUsage extends React.Component {

	intervalID = 0;
	pollingIntervalID = 1;

	constructor(props) {
    super(props);
    this.state = {
			totalUsage: '',
			wavelengthusage: {},
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };
  }

	async componentDidMount() {
		this.intervalID = setInterval(this.checkDeviceId, 1000);
	}

	componentWillUnmount() {
		//console.log("Vitals TotalHourlyUsagePVM componentWillUnmount");
		clearInterval(this.pollingIntervalID);
	}

	checkDeviceId = () => {
		const {currentDevice} = this.props;
		//console.log("currentDevice: "+currentDevice);

		if (currentDevice) {
			clearInterval(this.intervalID);
			this.getData();
			this.pollingIntervalID = setInterval(this.getData, 5000);
		}
  }

	getData = async () => {

		var url1 = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"global-laser-usage-total-for-device?SerialNumber="+this.props.currentDevice;

		try {
			const response1 = await fetch(url1, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body1 = await response1.json();
			console.log("global-laser-usage-total-for-device hours: " + JSON.stringify(body1));
			//let total = parseFloat(body1.results[0].totalseconds, 10)/60/60;
			//let roundedTotalHours = total.toFixed(2);
			//console.log("globallaserusagetotalfordevice hours: " + roundedTotalHours);

			if (body1.status == 'Success') {
				var d = Number(body1.results[0].totalseconds);
				var h = Math.floor(d / 3600);
				var m = Math.floor(d % 3600 / 60);
				var s = Math.floor(d % 3600 % 60);

				var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
				var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
				this.setState({ totalUsage: hDisplay+mDisplay });
			}
		}
		catch (err) {
			console.log(err);
		}

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"wavelength-usage-for-device?SerialNumber="+this.props.currentDevice;

		try {
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();

			if (body.status == 'Success') {
				console.log("api wavelengthusage data: " + JSON.stringify(body.results[0]));
				this.setState({ wavelengthusage: body.results[0] });
			}
		}
		catch (err) {
			console.log(err);
		}

	}

	render() {
		let m810 = parseFloat(this.state.wavelengthusage.m810);
		let m980 = parseFloat(this.state.wavelengthusage.m980);
		let mDual = parseFloat(this.state.wavelengthusage.mDual);
		let usage = this.state.totalUsage;
		//console.log("usage: " + usage)
		const options = {
			data: {
				columns: [
					['810', m810],
					['980', m980],
					['Dual', mDual],
				],

				type: 'donut',
				tooltip: {
					show: true
				}
			},
			donut: {
				label: {
					show: false
				},
				title: usage,
				width: 35,
			},
			legend: {
				hide: false,
				padding: 10
			},
			color: {
				pattern: ['#ff715b', '#6665dd', '#33d1bf']
			}
		}
		return (
			/*--------------------------------------------------------------------------------*/
			/* Used In Dashboard-1 [Classic]                                                  */
			/*--------------------------------------------------------------------------------*/
			<Card className="card-hover">
				<CardBody style={{ minHeight: 473 }}>
          <CardTitle>Wavelength Usage</CardTitle>
          <div className="mt-2">
						<Chart
							style={{ height: '387px', width: '100%' }}
							config={options}
						/>
          </div>
				</CardBody>
			</Card>

		);
	}
}

export default WavelengthUsage;
