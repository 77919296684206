import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";
import Loader from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Papa from 'papaparse';

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
	ButtonDropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu,
	Fade
} from 'reactstrap';

import update from 'react-addons-update';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

import csvPlaceholder from '../../../assets/images/csv-icon512x512.png';

class StatisticsReport extends React.Component {

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			repairCustomerNotes: [],
			productsData: [],
			productItemsForNote: [],
			errorMsg: null,
			obj: {},
			modal: false,
			isOpen: false,
			isUploadingFile: false,
			isFetching: false,
			currentItem: "ALL",
			currentDataSetting: 0,
			loadedSerialBatch: false,
			selectedFileSerialBatch: null,
			parsedCSVData: [],
      statisticsData: [],
      currentDataSetting: 0,
      currentItem2: "Global",
      currentDataSetting2: 0,
      isOpen: false,
      currentItem3: "All Markets",
      currentDataSetting3: 0,
      isOpen2: false,
      startDate: Moment().format('YYYY-MM-DD'),
			endDate: Moment().format('YYYY-MM-DD'),
			currentDate: Moment().format('YYYY-MM-DD'),
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
	  };

    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
		this.toggle = this.toggle.bind(this);
    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.toggleDropDown2 = this.toggleDropDown2.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

	async componentDidMount() {

		//this.getProductsData();
	}

  async onRadioBtnClick(currentDataSetting) {

		await this.setState({ currentDataSetting });

		//setTimeout(this.getCategoryData, 300);

	}

  selectDropDownItem2(id, title) {

    this.setState({
      currentItem3: title,
			currentDataSetting3: id
    });

		//localStorage.setItem('marketType', id);
		//document.body.dispatchEvent(new CustomEvent("set-market-type", {detail: { id }}));

  }

  selectDropDownItem(id, title) {

    this.setState({
      currentItem2: title,
			currentDataSetting2: id
    });

		if (id==1) {
			this.setState({ startDate: Moment().format('YYYY-MM-DD') });
		} else if (id==2) {
			this.setState({ startDate: Moment().subtract(1, 'week').format('YYYY-MM-DD') });
		} else if (id==3) {
			this.setState({ startDate: Moment().subtract(1, 'month').format('YYYY-MM-DD') });
		} else if (id==4) {
			this.setState({ startDate: Moment().format('YYYY-MM-DD') });
		}

		this.setState({ endDate: Moment().format('YYYY-MM-DD') });
		//setTimeout(()=>{ this.getData(); }, 1000);
  }

  handleChange(event) {
		if (event.target.getAttribute('name') == 'startDate') {
			this.setState({startDate: event.target.value});
			this.setState({
	      currentItem2: "Custom",
				currentDataSetting2: 4
	    });
		} else if (event.target.getAttribute('name') == 'endDate') {
			//console.log('this.state.code: ' + event.target.value);
			this.setState({endDate: event.target.value});
			this.setState({
				currentItem2: "Custom",
				currentDataSetting2: 4
	    });
		}

		//setTimeout(()=>{ this.getData(); }, 1000);
  }

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

  toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  toggleDropDown2() {
    this.setState({
      isOpen2: !this.state.isOpen2
    });
  }

	componentWillUnmount() {

	}

	onClickUploadSerialBatchHandler = () => {

		const file = this.state.selectedFileSerialBatch;

    if (!file) {
			this.setState({ errorMsg: 'Please select a CSV file.' });
      //setErrorMsg('Please select a CSV file.');
      return;
    }

    Papa.parse(file, {
      header: true,
      dynamicTyping: false,
      skipEmptyLines: true,
      delimiter: ',',
      complete: (result) => {

        console.log("Result: " + JSON.stringify(result));

        if (result.errors.length > 0) {
          //setErrorMsg('Error parsing the CSV file.');
					this.setState({ errorMsg: 'Error parsing the CSV file.' });
        } else {
          //setCsvData(result.data);
          //setErrorMsg(null);
					console.log("CsvData: " + JSON.stringify(result.data));

          if (result.data[result.data.length-1]["Serial Numbers"]) {

  					var formattedCSVData = [];

  					result.data.map((prop, key) => {

  						const serials = prop["Serial Numbers"];

              console.log("serials: " + JSON.stringify(serials));

  						const serialsArray = serials.split(",");

  						if (serialsArray.length > 1) {

  							serialsArray.map((prop2, key) => {

  								const data = {
  									id: key,
  									SerialNumber: prop2
  								};

  								formattedCSVData.push(data);

  							});

  						} else {

  							const data = {
  								id: key,
  								SerialNumber: prop["Serial Numbers"]
  							};

  							formattedCSVData.push(data);

  						}

  						//let extension = contentTypeArray[1];

  						return {
  							id: key,
  							SerialNumber: prop["Serial Numbers"]
  						};
  					});

  					console.log("CsvData formatted: " + JSON.stringify(formattedCSVData));

  					this.setState({ parsedCSVData: formattedCSVData, errorMsg: null });

            toast.success("Batch Serials uploaded successfully.", {
      				position: "top-center",
      				autoClose: 3000,
      				hideProgressBar: true,
      				closeOnClick: true,
      				pauseOnHover: true,
      				draggable: true,
      				progress: undefined,
      				theme: "dark",
      				closeButton: false
      			});

          } else {
            this.setState({ errorMsg: 'CSV Format is incorrect.' });
            this.setState({ parsedCSVData: [] });
          }

        }
      },
    });

	}

	// onClickUploadSerialBatchHandler = () => {
  //
	// 	const file = this.state.selectedFileSerialBatch;
  //
  //   if (!file) {
	// 		this.setState({ errorMsg: 'Please select a CSV file.' });
  //     //setErrorMsg('Please select a CSV file.');
  //     return;
  //   }
  //
  //   Papa.parse(file, {
  //     header: true,
  //     dynamicTyping: true,
  //     skipEmptyLines: true,
  //     complete: (result) => {
  //       if (result.errors.length > 0) {
  //         //setErrorMsg('Error parsing the CSV file.');
	// 				this.setState({ errorMsg: 'Error parsing the CSV file.' });
  //       } else {
  //         //setCsvData(result.data);
  //         //setErrorMsg(null);
	// 				console.log("CsvData: " + JSON.stringify(result.data));
  //
	// 				if (result.data[result.data.length-1].SerialNumber && result.data[result.data.length-1].BatchNumber) {
  //
	// 					for (const serial of result.data) {
  //
	// 						console.log("SerialNumber: " + JSON.stringify(serial.SerialNumber));
	// 						console.log("BatchNumber: " + JSON.stringify(serial.BatchNumber));
  //
	// 				  };
  //
	// 					const formattedData = result.data.map((prop, key) => {
  //
	// 						var serialNumber = "EL-0"+prop.SerialNumber;
  //
	// 						return {
	// 							SerialNumber: serialNumber,
	// 							BatchNumber: prop.BatchNumber
	// 						};
	// 					});
  //
	// 					this.setState({ errorMsg: null });
	// 					this.setState({ parsedCSVData: formattedData });
  //
	// 				} else {
	// 					this.setState({ errorMsg: 'CSV Format is incorrect.' });
	// 					this.setState({ parsedCSVData: [] });
	// 				}
  //
  //       }
  //     },
  //   });
  //
	// }

	onChangeSerialBatchHandler = (event) => {

		var contentType = event.target.files[0].type;

		const contentTypeArray = contentType.split("/");

		let extension = contentTypeArray[1];

		this.setState({
			selectedFileSerialBatch: event.target.files[0],
			loadedSerialBatch: true,
		})

		console.log("file type: "+event.target.files[0].type);
	}

	goToRepairLog(id){
		window.location.href="/repair-log/"+id;
	}



	getDeviceStatistics = async () => {

		this.setState({ isFetching: true });

    var serials = "";

    if (this.state.currentDataSetting==1) {
      serials = document.getElementById("SerialNumbers").value;
      serials = serials.replace(" ", "");

    } else if (this.state.currentDataSetting==2) {
      this.state.parsedCSVData.map((prop, key) => {

        if (serials.length > 0) {
          serials = serials + "," + prop.SerialNumber;
        } else {
          serials = prop.SerialNumber;
        }

  		});
    }

    console.log("getDeviceStatistics serials data: " + JSON.stringify(serials));

    var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-device-statistics";

    var timezone = Moment.tz.guess();

    var requestBody = {};

    if (serials.length>0) {
      requestBody.SerialNumbers = serials;
    }

    if (this.state.currentDataSetting2!=0) {
      console.log("currentDataSetting2: " + JSON.stringify(this.state.currentDataSetting2));
      requestBody.Timezone = timezone;
      requestBody.StartDate = this.state.startDate;
      requestBody.EndDate = this.state.endDate;
    }

    if (this.state.currentDataSetting3!=0) {
      console.log("currentDataSetting3: " + JSON.stringify(this.state.currentDataSetting3));
      requestBody.MarketType = this.state.currentDataSetting3;
    }

    console.log("getDeviceStatistics requestBody: " + JSON.stringify(requestBody));

    try {

      const response = await fetch(url, {
  			method: 'POST',
  			headers: {
  				Authorization: this.state.currentUser.jwtToken,
  				'Content-Type': 'application/json'
  			},
  			body: JSON.stringify(requestBody)
  		});

  		const body = await response.json();
  		console.log("getDeviceStatistics response: " + JSON.stringify(body));

  		if (body.status == 'Success') {

  			this.setState({ parsedCSVData: [] });

        if (this.state.currentDataSetting==2) {
          document.getElementById("fileSerialBatch").value = null;
          this.setState({ selectedFileSerialBatch: null, loadedSerialBatch: false });
        }

  			this.setState({ isFetching: false });
        this.setState({statisticsData: body.response});
  			toast.success("All device statistics successfully retrieved.", {
  				position: "top-center",
  				autoClose: 3000,
  				hideProgressBar: true,
  				closeOnClick: true,
  				pauseOnHover: true,
  				draggable: true,
  				progress: undefined,
  				theme: "dark",
  				closeButton: false
  			});

  		} else {
        //ADD ERROR MESSAGE
  			this.setState({ isFetching: false });
  			toast.error("Error retrieving device statistics. Please try again.", {
  				position: "top-center",
  				autoClose: 3000,
  				hideProgressBar: true,
  				closeOnClick: true,
  				pauseOnHover: true,
  				draggable: true,
  				progress: undefined,
  				theme: "dark",
  				closeButton: false
  			});

      }

		}
		catch (err) {
			console.log(err);

      this.setState({ isFetching: false });
      toast.error("Error retrieving device statistics. Please try again.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        closeButton: false
      });
		}

	};

  generateReport = async (data) => {

		const dataRevisedHeaders = data.map((data, key) => {

			return {
				id: data.id,
				"Serial Number": data.SerialNumber,
				Email: data.Email,
				Kit: data.Kit,
				City: data.City,
				State: data.State,
				Country: data.Country,
				Shipped: data.Shipped,
				Firmware: data.Firmware,
				"Total Procedures": data.TotalProcedures,
				Preset: data.Preset,
				Manual: data.Manual,
				"Lifetime Laser Usage (seconds)": data.LifetimeLaserUsage,
				"810 nm": data.m810,
				"980 nm": data.m980,
				"Dual": data.mDual,
				"Decontamination Total": data.DecontaminationTotal,
				"Debridement Total": data.DebridementTotal,
				"Aphthous Ulcer Total": data.AphthousUlcerTotal,
				"Hemostasis Total": data.HemostasisTotal,
				"Incision Excision Total": data.IncisionExcisionTotal,
				"Implant Recovery Total": data.ImplantRecoveryTotal,
				"Tooth Exposure Total": data.ToothExposureTotal,
				"Operculectomy Total": data.OperculectomyTotal,
				"Gingivoplasty Total": data.GingivoplastyTotal,
				"Gingivectomy Total": data.GingivectomyTotal,
				"Frenectomy Total": data.FrenectomyTotal,
				"Pulpotomy Total": data.PulpotomyTotal,
				"Troughing Total": data.TroughingTotal,
				"7mm Total": data.Total7mm,
				"3mm Total": data.Total3mm,
				"25mm Total": data.Total25mm
			};
		});

    console.log("generateReport data: " + JSON.stringify(dataRevisedHeaders));

    const config = { delimiter: ',' };
    const csv2Export = Papa.unparse( dataRevisedHeaders, config );

    console.log("generateReport csv2Export: " + JSON.stringify(csv2Export));

    var blob = new Blob([csv2Export]);
    if (window.navigator.msSaveOrOpenBlob)  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
        window.navigator.msSaveBlob(blob, "StatisticsReport.csv");
    else
    {
        var a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob, {type: "text/plain"});
        a.download = "StatisticsReport.csv";
        document.body.appendChild(a);
        a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
        document.body.removeChild(a);
    }

    // Papa.unparse(data, {
    //   delimiter: ",",
    //   header: true,
    //   complete: (result) => {
    //
    //     console.log("Result: " + JSON.stringify(result));
    //
    //     if (result.errors.length > 0) {
    //       //setErrorMsg('Error parsing the CSV file.');
		// 			this.setState({ errorMsg: 'Error parsing the data.' });
    //     } else {
    //       //setCsvData(result.data);
    //       //setErrorMsg(null);
		// 			console.log("CsvData: " + JSON.stringify(result.data));
    //
    //       var blob = new Blob([result]);
    //       if (window.navigator.msSaveOrOpenBlob)  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
    //           window.navigator.msSaveBlob(blob, "StatisticsReport.csv");
    //       else
    //       {
    //           var a = window.document.createElement("a");
    //           a.href = window.URL.createObjectURL(blob, {type: "text/plain"});
    //           a.download = "StatisticsReport.csv";
    //           document.body.appendChild(a);
    //           a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
    //           document.body.removeChild(a);
    //       }
    //
    //     }
    //   },
    // });

  }

	filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id;

		//console.log("filterCaseInsensitive row[id]: " + JSON.stringify(id));

		// if (id == 'creation_date') {
		// 	var dateString = Moment(row[id]).format('MMM Do YYYY');
		// 	return (
		// 			dateString !== undefined ?
		// 					String(dateString.toString().toLowerCase()).includes(filter.value.toLowerCase())
		// 			:
		// 					true
		// 	);
		// }
		//
		// if (id == 'SerialNumber') {
		// 	var serialString = row[id].SN;
		// 	return (
		// 			serialString !== undefined ?
		// 					String(serialString.toString().toLowerCase()).includes(filter.value.toLowerCase())
		// 			:
		// 					true
		// 	);
		// }

		if (row[id] !== null){
			return (
					row[id] !== undefined ?
							String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}
	}

  render() {

		const data = this.state.parsedCSVData.map((prop, key) => {

			return {
				id: key,
				SerialNumber: prop.SerialNumber,
				InvoiceDate: prop.InvoiceDate,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						{/* use this button to remove the data row */}
					</div>
				)
			};
		});

    const data1 = this.state.statisticsData.map((prop, key) => {

      var wavelengthTotal = parseInt(prop.m810) + parseInt(prop.m980) + parseInt(prop.mDual);

      let m810Percent = (parseInt(prop.m810)/wavelengthTotal)*100;
      let m980Percent = (parseInt(prop.m980)/wavelengthTotal)*100;
      let mDualPercent = (parseInt(prop.mDual)/wavelengthTotal)*100;

			var shippedDate = "N/A";

			if (prop.Shipped) {
				shippedDate = Moment(prop.Shipped).format('MM/DD/YYYY')
			}

			var firmwareVersion = "";

			if (prop.Firmware) {
				if (prop.Firmware.length==1) {
					firmwareVersion = parseFloat(prop.Firmware).toFixed(1);
				} else {
					firmwareVersion = prop.Firmware;
				}
			}

			return {
				id: key,
				SerialNumber: prop.SerialNumber,
				Email: prop.Email,
				Kit: prop.Kit,
				City: prop.City,
				State: prop.State,
				Country: prop.Country,
				Shipped: shippedDate,
				Firmware: firmwareVersion,
				TotalProcedures: prop.total_procedures,
        Preset: prop.preset,
        Manual: prop.manual,
        LifetimeLaserUsage: prop.lifetime_laser_usage,
        m810: m810Percent.toFixed(1)+"%",
        m980: m980Percent.toFixed(1)+"%",
        mDual: mDualPercent.toFixed(1)+"%",
        DecontaminationTotal: prop.DecontaminationTotal,
        DebridementTotal: prop.DebridementTotal,
        AphthousUlcerTotal: prop.AphthousUlcerTotal,
        HemostasisTotal: prop.HemostasisTotal,
        IncisionExcisionTotal: prop.IncisionExcisionTotal,
        ImplantRecoveryTotal: prop.ImplantRecoveryTotal,
        ToothExposureTotal: prop.ToothExposureTotal,
        OperculectomyTotal: prop.OperculectomyTotal,
        GingivoplastyTotal: prop.GingivoplastyTotal,
        GingivectomyTotal: prop.GingivectomyTotal,
        FrenectomyTotal: prop.FrenectomyTotal,
        PulpotomyTotal: prop.PulpotomyTotal,
        TroughingTotal: prop.TroughingTotal,
        Total7mm: prop.Total7mm,
        Total3mm: prop.Total3mm,
        Total25mm: prop.Total25mm
			};
		});

		// const data = this.state.parsedCSVData.map((prop, key) => {
    //
		// 	//var serialNumber = "EL-0"+prop.SerialNumber;
    //
		// 	return {
		// 		id: key,
		// 		SerialNumber: prop.SerialNumber,
		// 		BatchNumber: prop.BatchNumber,
		// 		actions: (
		// 			// we've added some custom button actions
		// 			<div className="text-center">
		// 				{/* use this button to add a edit kind of action */}
		// 				{/* use this button to remove the data row */}
		// 			</div>
		// 		)
		// 	};
		// });

		return (
      <Fragment>
        <Card className="">
          <CardBody>
            <Row className="mb-2">
              <Col md="3">
                <h3 style={{color:'#737782'}}>Statistics Report</h3>
              </Col>
              <Col xs="6" md="6" lg="6">
              </Col>
              <Col xs="3" md="3" lg="3">
                <div className="float-right">
                <ButtonDropdown isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
                  <DropdownToggle color="dark" caret>
                    {this.state.currentItem2}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => this.selectDropDownItem(0, 'Global')}>Global</DropdownItem>
                    <DropdownItem onClick={() => this.selectDropDownItem(1, 'Today')}>Today</DropdownItem>
                    <DropdownItem onClick={() => this.selectDropDownItem(2, 'Week')}>Week</DropdownItem>
                    <DropdownItem onClick={() => this.selectDropDownItem(3, 'Month')}>Month</DropdownItem>
                    <DropdownItem onClick={() => this.selectDropDownItem(4, 'Custom')}>Custom</DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
                </div>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs="3" md="3" lg="3">
                <div className="">
                  <ButtonGroup style={{ width: '60%' }}>
                    <Button
                      style={{width: '100%'}}
                      size="sm"
                      color="btngray"
                      onClick={() => this.onRadioBtnClick(0)}
                      active={this.state.currentDataSetting === 0}
                    >
                      All Serials
                    </Button>
                    <Button
                      className="ml-1"
                      style={{width: '100%'}}
                      size="sm"
                      color="btngray"
                      onClick={() => this.onRadioBtnClick(1)}
                      active={this.state.currentDataSetting === 1}
                    >
                      Enter Serials
                    </Button>
                    <Button
                      className="ml-1"
                      size="sm"
                      style={{width: '100%'}}
                      color="btngray"
                      onClick={() => this.onRadioBtnClick(2)}
                      active={this.state.currentDataSetting === 2}
                    >
                      Batch Upload Serials
                    </Button>
                  </ButtonGroup>
                </div>
              </Col>
              <Col xs="7" md="7" lg="7">
                <div style={{ width: "150px "}}>
                <ButtonDropdown className="float-left" isOpen={this.state.isOpen2} toggle={this.toggleDropDown2}>
                  <DropdownToggle size="sm" style={{ fontSize: "13px" }} color="yellow" caret>
                    <b>{this.state.currentItem3}</b>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => this.selectDropDownItem2(0, 'All Markets')}>All Markets</DropdownItem>
                    <DropdownItem onClick={() => this.selectDropDownItem2(1, 'US Only')}>US Only</DropdownItem>
                    <DropdownItem onClick={() => this.selectDropDownItem2(2, 'Demo Only')}>Demo Only</DropdownItem>
                    <DropdownItem onClick={() => this.selectDropDownItem2(3, 'International Only')}>International Only</DropdownItem>
                    <DropdownItem onClick={() => this.selectDropDownItem2(4, 'US+International+Brazil')}>US+International+Brazil</DropdownItem>
                    <DropdownItem onClick={() => this.selectDropDownItem2(5, 'Brazil Only')}>Brazil Only</DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
                </div>
              </Col>
              <Col xs="2" md="2" lg="2">
                {this.state.currentDataSetting2 != 0 &&
                  <Fragment>
                  <Row className="mt-2">
                    <Col lg="12" className="text-right">
                      <div className="float-right ml-1" style={{ minWidth: '75%' }}>
                        <Input style={{ minWidth: '135px' }} name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleChange} />
                      </div>
                      {this.state.currentDataSetting2 == 1 ? (
                        <div className="float-right" style={{ minWidth: '10%' }}>
                          <h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>Day:</h6>
                        </div>
                      ) : (
                        <div className="float-right" style={{ minWidth: '10%' }}>
                          <h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>From:</h6>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col lg="12" className="">
                      {this.state.currentDataSetting2 != 1 &&
                        <Fragment>
                        <div className="float-right ml-1" style={{ minWidth: '75%' }}>
                          <Input style={{ minWidth: '135px' }} name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleChange} />
                        </div>
                        <div className="float-right text-right" style={{ minWidth: '10%' }}>
                          <h6 style={{ lineHeight: '1.8', minWidth: '40px' }}>To:</h6>
                        </div>
                        </Fragment>
                      }
                    </Col>
                  </Row>
                  </Fragment>
                }
              </Col>
            </Row>
            {this.state.currentDataSetting==1 &&
              <Row className="mb-4">
                <Col xs="1" md="1" lg="1">
                  <Label style={{fontSize:'18px'}} for="State">Serial(s):</Label>
                </Col>
                <Col xs="4" md="4" lg="4">
                  <Input style={{width:'100%', height: '30px'}} type="text" name="SerialNumbers" id="SerialNumbers"/>
                </Col>
              </Row>
            }
            {this.state.currentDataSetting==2 &&
              <Fragment>
              {!this.state.isUploadingFile ? (
  							<Fragment>
  							<Row className="mb-4">
                  <Col xs="1" md="1" lg="1">
                    <Label style={{fontSize:'18px'}} for="State">Serial(s):</Label>
                  </Col>
  								<Col xs="3" md="3" lg="3">
  									<Input className="" type="file" name="fileSerialBatch" id="fileSerialBatch" accept=".csv" onChange={this.onChangeSerialBatchHandler} onClick={e => (e.target.value = null)}/>
  								</Col>
  								<Col xs="1" md="1" lg="1">
  									{this.state.loadedSerialBatch &&
  										<Button className="" size="sm" color="yellow" title="Upload" onClick={this.onClickUploadSerialBatchHandler}>
  											Upload
  										</Button>
  									}
  								</Col>
  								<Col xs="3" md="3" lg="3">
  									{this.state.loadedSerialBatch &&
  										<img style={{ marginTop: "-3px"}} className="ml-2" src={csvPlaceholder} width="30"/>
  									}
  								</Col>
  								<Col xs="1" md="1" lg="1">

  								</Col>
  							</Row>
  							<Row className="pt-3">
  								<Col xs="6" md="6" lg="6">
  									{this.state.errorMsg && <p className="error">{this.state.errorMsg}</p>}
  								</Col>
  							</Row>
  							</Fragment>
  						) : (
  							<Row className="pl-4 pl-4">
  									<Fade in="true">
  										<Row className="justify-content-center">
  											<Loader type="ThreeDots" color="#ffc108" height={30} width={200}/>
  										</Row>
  									</Fade>
  							</Row>
  						)}
              </Fragment>
            }
            <Row className="">
              <Col xs="6" md="6" lg="6">
                <Button className="float-left" size="large" color="yellow" title="Upload" onClick={this.getDeviceStatistics}>
                  Get Device Statistics
                </Button>
              </Col>
              <Col xs="6" md="6" lg="6">
                {!this.state.isFetching ? (
                  <Fragment>
                    {data1.length>0 &&
                      <Row className="">
                        <Col md="12">
                          <Button className="float-right" size="" color="yellow" title="Upload" onClick={()=>{this.generateReport(data1)}}>
                            Generate Report
                          </Button>
                        </Col>
                      </Row>
                    }
                  </Fragment>
                ) : (
                  <Fragment>
                    <Row className="pl-4 float-right">
                        <Fade in="true">
                          <Row className="justify-content-center">
                            <Loader type="ThreeDots" color="#ffc108" height={30} width={200}/>
                          </Row>
                        </Fade>
                    </Row>
                  </Fragment>
                )}
              </Col>
            </Row>
          </CardBody>


        </Card>
				<Card className="">
          <CardBody>
						<ReactTable
							style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
							columns={[
								{
									Header: "Serial Number",
									accessor: "SerialNumber",
                  width: 120,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
								{
									Header: "Email",
									accessor: "Email",
                  width: 240,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
								{
									Header: "Kit",
									accessor: "Kit",
                  width: 120,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
								{
									Header: "Firmware",
									accessor: "Firmware",
                  width: 120,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
								{
									Header: "City",
									accessor: "City",
                  width: 120,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
								{
									Header: "State",
									accessor: "State",
                  width: 120,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
								{
									Header: "Country",
									accessor: "Country",
                  width: 120,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
								{
									Header: "Shipped",
									accessor: "Shipped",
                  width: 120,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "Total Procedures",
									accessor: "TotalProcedures",
                  width: 120,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "Total Preset",
									accessor: "Preset",
                  width: 120,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "Total Manual",
									accessor: "Manual",
                  width: 120,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "Lifetime Laser Usage (seconds)",
									accessor: "LifetimeLaserUsage",
                  width: 220,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "810",
									accessor: "m810",
                  width: 100,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "980",
									accessor: "m980",
                  width: 100,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "Dual",
									accessor: "mDual",
                  width: 100,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "Decontamination Total",
									accessor: "DecontaminationTotal",
                  width: 170,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "Debridement Total",
									accessor: "DebridementTotal",
                  width: 140,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "Aphthous Ulcer Total",
									accessor: "AphthousUlcerTotal",
                  width: 150,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "Hemostasis Total",
									accessor: "HemostasisTotal",
                  width: 140,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "Incision Excision Total",
									accessor: "IncisionExcisionTotal",
                  width: 170,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "Implant Recovery Total",
									accessor: "ImplantRecoveryTotal",
                  width: 170,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "Tooth Exposure Total",
									accessor: "ToothExposureTotal",
                  width: 170,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "Operculectomy Total",
									accessor: "OperculectomyTotal",
                  width: 160,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "Gingivoplasty Total",
									accessor: "GingivoplastyTotal",
                  width: 160,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "Gingivectomy Total",
									accessor: "GingivectomyTotal",
                  width: 160,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "Frenectomy Total",
									accessor: "FrenectomyTotal",
                  width: 160,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "Pulpotomy Total",
									accessor: "PulpotomyTotal",
                  width: 150,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
								{
									Header: "Troughing Total",
									accessor: "TroughingTotal",
                  width: 120,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "7mm Total",
									accessor: "Total7mm",
                  width: 120,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "3mm Total",
									accessor: "Total3mm",
                  width: 120,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
                {
									Header: "25mm Total",
									accessor: "Total25mm",
                  width: 120,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
							]}
							defaultPageSize={15}
							sortable={true}
							showPaginationBottom={true}
							className="-striped -highlight"
							data={data1}
							filterable
							filterAll={true}
							defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
							getTrGroupProps={(state, rowInfo, column, instance) => {
											if (rowInfo !== undefined) {
													return {
															onClick: (e, handleOriginal) => {
																//console.log('It was in this row:', rowInfo)
																//console.log('with ID:', rowInfo.original.categoryID);

																//this.goToRepairLog(rowInfo.original.repairId);
															}
													}
											}}
									}
						/>
					</CardBody>
        </Card>
				<ToastContainer/>
      </Fragment>
		);
	}
}

export default StatisticsReport;
