import React from "react";
import {
	Card,
	CardBody,
	CardTitle,
	Row,
	Col,

} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { authenticationService } from '../../jwt/_services';
import ReactGA from "react-ga4";

import {
	ChangePassword,
  UserInfo,
	UpgradeUser
} from '../../components/account/';

class Account extends React.Component {

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;

		this.state = {
			currentUser
		}

  }

	componentDidMount() {
		ReactGA.pageview(window.location.pathname);
		//console.log("ReactGA.pageview: " + window.location.pathname);
	}

	render() {
		return (
			<div>
				<Row>
					<Col lg="6" xl="4">
            <UserInfo currentUser={this.state.currentUser}/>
					</Col>
					<Col lg="6" xl="4">
            <ChangePassword currentUser={this.state.currentUser} />
					</Col>
					{
					// UNCOMMENT TO TURN ON USER ACCOUNT UPGRADE FUNCTIONALITY
					// <Col md="4">
          //   <UpgradeUser currentUser={this.state.currentUser} />
					// </Col>
					}
				</Row>
			</div>
		);
	}
}

export default Account;
