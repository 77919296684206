import { CometChat } from "@cometchat-pro/chat";

import * as enums from '../../util/enums.js';
var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

export class UserListManager {

    userRequest = null;
    userListenerId = "userlist_" + hash + new Date().getTime();
    conversationListenerId = "chatlist_" + hash + new Date().getTime();

    constructor(friendsOnly, searchKey) {

        let role = "support";
        if (searchKey) {
            this.usersRequest = new CometChat.UsersRequestBuilder().setLimit(30).setRole(role).friendsOnly(friendsOnly).setSearchKeyword(searchKey).build();
        } else {
            this.usersRequest = new CometChat.UsersRequestBuilder().setLimit(30).setRole(role).friendsOnly(friendsOnly).build();
        }
    }

    fetchNextUsers() {
        return this.usersRequest.fetchNext();
    }

    attachListeners(callback) {

      console.log("attachListeners this.userListenerId ", this.userListenerId);

      CometChat.addUserListener(
          this.userListenerId,
          new CometChat.UserListener({
              onUserOnline: onlineUser => {
                  /* when someuser/friend comes online, user will be received here */

                  console.log("attachListeners onlineUser: ", onlineUser);
                  callback(onlineUser);
              },
              onUserOffline: offlineUser => {
                  /* when someuser/friend went offline, user will be received here */

                  console.log("attachListeners offlineUser: ", offlineUser);
                  callback(offlineUser);
              }
          })
      );
    }

    // attachMessageListeners(callback) {
    //
    //   CometChat.addUserListener(
    //       this.userListenerId,
    //       new CometChat.UserListener({
    //           onUserOnline: onlineUser => {
    //               /* when someuser/friend comes online, user will be received here */
    //               console.log("attachListeners onlineUser: ", onlineUser);
    //               callback(enums.USER_ONLINE, onlineUser);
    //           },
    //           onUserOffline: offlineUser => {
    //               /* when someuser/friend went offline, user will be received here */
    //               console.log("attachListeners offlineUser: ", offlineUser);
    //               callback(enums.USER_OFFLINE, offlineUser);
    //           }
    //       })
    //   );
    //
    //   CometChat.addMessageListener(
    //       this.userListenerId,
    //       new CometChat.MessageListener({
    //           onTextMessageReceived: textMessage => {
    //               callback(enums.TEXT_MESSAGE_RECEIVED, null, textMessage);
    //           },
    //           onMediaMessageReceived: mediaMessage => {
    //               callback(enums.MEDIA_MESSAGE_RECEIVED, null, mediaMessage);
    //           },
    //           onCustomMessageReceived: customMessage => {
    //               callback(enums.CUSTOM_MESSAGE_RECEIVED, null, customMessage);
    //           },
    //           onMessageDeleted: deletedMessage => {
    //               callback(enums.MESSAGE_DELETED, null, deletedMessage);
    //           },
    //           onMessageEdited: editedMessage => {
    //               callback(enums.MESSAGE_EDITED, null, editedMessage);
    //           }
    //       })
    //   );
    // }

    removeListeners() {

        CometChat.removeUserListener(this.userListenerId);
    }
}
