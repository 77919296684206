import React from "react";
import { Component, Fragment } from "react";
import ReactTable from 'react-table-v6';
import Moment from 'moment';
//import CsvGenerator from '@exlabs/react-csv-generator';
import { CSVLink, CSVDownload } from "react-csv";
import { getEmailTextMessageOne, getEmailHTMLMessageOne, getEmailTextMessageTwo, getEmailHTMLMessageTwo, getEmailTextMessageThree, getEmailHTMLMessageThree } from "./get-email-html";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
	Badge,
	ButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Fade
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class UsersList extends React.Component {

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 3,
			totalProcedures: 0,
			modal: false,
      modal2: false,
			modal3: false,
			modal4: false,
			modal5: false,
			modal6: false,
      dropdownOpen: false,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
      users: [],
			devices: [],
      roles: [],
      obj: {},
			code: 123456,
			verificationError: false,
			statusSubject: "Failed",
			statusMessage: "Failed to send message.",
			userToDeleteDevices: [],
			currentDataSetting: 0,
			isOpen: false,
			currentItem: "All Devices",
			messageType: "Email",
			badgeCount: 0,
			accountDeletionRequests: []
	  };

		this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
		this.toggle3 = this.toggle3.bind(this);
		this.toggle4 = this.toggle4.bind(this);
		this.toggle5 = this.toggle5.bind(this);
		this.toggle6 = this.toggle6.bind(this);

		this.toggleDropDown = this.toggleDropDown.bind(this);
		this.changeUserRole = this.changeUserRole.bind(this);
  }

	async componentDidMount() {

		this.getAccountDeletionRequests();
		this.getDevices();
    this.getData();
	}

	selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		if (id==1) {
			//this.setState({ startDate: Moment().subtract(1, 'week').format('YYYY-MM-DD') });
		} else if (id==2) {
			//this.setState({ startDate: Moment().subtract(1, 'month').format('YYYY-MM-DD') });
		}

		//this.setState({ endDate: Moment().format('YYYY-MM-DD') });
		//setTimeout(this.getData, 1000);
  }

	toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	getAccountDeletionRequests = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-all-account-delete-requests";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});
		const body = await response.json();
		console.log("getAccountDeletionRequests body: " + JSON.stringify(body));

		if (body.status == 'Success') {

			var requests = body.response;

			var filteredRequests = [];

			filteredRequests = requests.filter((request) => request.status == 0);

			this.setState({ accountDeletionRequests: body.response, badgeCount: filteredRequests.length });
		} else {
      //ADD ERROR MESSAGE
    }

	};

  getData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-users";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});
		const body = await response.json();
		console.log("all users: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ users: body.response.Users });
		} else {
      //ADD ERROR MESSAGE
    }

	};

	getDevices = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"all-devices";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all devices: " + JSON.stringify(body));

		if (body.status == 'Success') {

			let devices = body.results;

			this.setState({ devices: devices });

			// var devices2 = [];
			// var devices2 = [];
			//
			// for ( var i = 0; i < this.state.devices.length; i++ ) {
			//
			// 	if (this.state.devices[i].geo_timestamp || this.state.devices[i].WifiProvisioned) {
			// 		devices2.push(this.state.devices[i]);
			// 	} else {
			// 		devicesIcons.push( {
			// 			id: i,
			// 			serial_number: this.state.devices[i].SerialNumber,
		  //       current_icon: brokenpin
			// 		});
			// 	}
			//
			// }

		} else {
      //ADD ERROR MESSAGE
    }

	};

	resolveUserRequestToDeleteDevices = async (obj) => {

		console.log("Delete User: " + obj.email);

		await this.deleteRequestedUser(obj);
		this.updateDeleteRequestStatus(obj)

	};

	getUserToDeleteDevices = async (obj) => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"device-list-for-user?UserEmail="+obj.email;
		console.log("url: " + url);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});
		const body = await response.json();
		console.log("all user devices: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ userToDeleteDevices: body.response });
			this.toggle3();
		} else {
      //ADD ERROR MESSAGE
			this.setState({ userToDeleteDevices: [] });
			this.toggle3();
    }

	};

	updateDeleteRequestStatus = async (obj) => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"update-account-delete-request-status?DeleteRequestID="+obj.requestID+"&Status=1";
		console.log("url: " + url);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});
		const body = await response.json();
		console.log("updateDeleteRequestStatus body: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.getAccountDeletionRequests();
		}

	};

	changeUserRole = (event, id) => {

		console.log("changeUserRole input id: " + id);
		console.log("changeUserRole input value: " + event.target.value);

		let value = event.target.value;
		var role = 0;

		if (value=='General User') {
			role = 0;
		} else if (value=='Tech Support') {
			role = 1;
		} else if (value=='Administrator') {
			role = 2;
		}

		console.log(`user obj:`, this.state.users[id]);

		let newObj = JSON.parse(JSON.stringify(this.state.users));
    console.log(`user copy is:`, newObj);

		let oldObj = this.state.users[id];

		var username = oldObj.Username;
		var allowphonecontact = oldObj.Attributes.filter(attribute => attribute.Name == "custom:allowphonecontact").map(name => name.Value);
		var firstname = oldObj.Attributes.filter(attribute => attribute.Name == "given_name").map(name => name.Value);
		var lastname = oldObj.Attributes.filter(attribute => attribute.Name == "family_name").map(name => name.Value);
		var email = oldObj.Attributes.filter(attribute => attribute.Name == "email").map(name => name.Value);
		var phone = oldObj.Attributes.filter(attribute => attribute.Name == "custom:phone").map(name => name.Value);
		var sub = oldObj.Attributes.filter(attribute => attribute.Name == "sub").map(name => name.Value);

		var originRole = oldObj.Attributes.filter(attribute => attribute.Name == "custom:role").map(name => name.Value);

		if (role!=originRole) {
			newObj[id] = {
				Username: username,
				Attributes: [ /* required */
					    {
					      Name: 'given_name', /* required */
					      Value: firstname
					    },
							{
					      Name: 'family_name', /* required */
					      Value: lastname
					    },
							{
					      Name: 'email', /* required */
					      Value: email
					    },
							{
					      Name: 'custom:role', /* required */
					      Value: role
					    },
							{
					      Name: 'custom:phone', /* required */
					      Value: phone
					    },
							{
					      Name: 'custom:allowphonecontact', /* required */
					      Value: allowphonecontact
					    },
							{
					      Name: 'sub', /* required */
					      Value: sub
					    },
					  ],
	    }

			this.setState({
				users: newObj
			})

			this.changeRoleForUser(sub, role);
		}

	}

	changeRoleForUser = async (userSubID, role) => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"change-user-role?UserID="+userSubID+"&RoleChange="+role;
		console.log("url: " + url);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});
		const body = await response.json();
		console.log("role change response: " + JSON.stringify(body));

		if (body.status == 'Success') {
			//this.setState({ userToDeleteDevices: body.response });
			//this.toggle3();
			this.getData();
		} else {
      //ADD ERROR MESSAGE
			//this.setState({ userToDeleteDevices: [] });
			//this.toggle3();
    }

	};

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

  toggle2() {

	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

	showVerification(obj) {
		this.sendCode(obj);

	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

	toggle3() {
	  this.setState({
	    modal3: !this.state.modal3
	  });
	}

	toggle4() {
	  this.setState({
	    modal4: !this.state.modal4
	  });
	}

	toggle5() {
	  this.setState({
	    modal5: !this.state.modal5
	  });
	}

	toggle6() {
	  this.setState({
	    modal6: !this.state.modal6
	  });
	}

	async sendCode(obj) {

		console.log("Send Code to User: " + obj.email);

		var code = Math.floor(100000 + Math.random() * 900000);
		console.log("random code: "+code);

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"verify-change-for-user?UserEmail="+obj.email+"&ConfirmationCode="+code;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("send code status: " + JSON.stringify(body.status));

		if (body.status == 'Success') {
			this.setState({code: code});
		}
	}

  handleVerifySubmit = (event) => {
		event.preventDefault();

	  let code = event.target.code.value;
    console.log(`code is:`, code);

		if (code == this.state.code) {
			this.setState({
				verificationError: false
			});

			this.setState({
		    modal2: !this.state.modal2
		  });
			this.toggle();
		} else {
			this.setState({
		    verificationError: true
		  });
		}
	}

  addNewUser = async (firstname, lastname, phone, email) => {

    // authenticationService.register(firstname, lastname, email, password, role)
    //     .then(
    //         user => {
		//
    //             let status = user.status;
    //             console.log('status: ' + status);
		//
    //             if (status === 'Success') {
    //                 this.toggle2();
    //                 this.getData();
    //             } else if (status === 'Error') {
    //                 console.log('error info: ' + user.response);
    //             } else {
    //               console.log('status info: ' + status);
    //             }
		//
    //         },
    //         error => {
    //             console.log('error info: ' + error);
    //         }
    //     );

	};

	onChangeMessageType = (event) => {

		console.log("input id: " + event.target.id);
		console.log("input value: " + event.target.value);
		console.log("checked value: " + event.target.checked);

		let id = event.target.id;
		let value = event.target.value;
		let checked = event.target.checked;

		this.setState({ messageType: value });

		// if (checked != undefined) {
		//
		// 	if (event.target.type == 'checkbox') {
		// 		repairLog[id] = checked ? 1 : 0;
		// 		console.log("set checked as value");
		// 	} else if (event.target.type == 'date') {
		// 		repairLog[id] = value;
		// 		console.log("set checked as value");
		// 	}
		//
		// }

		console.log("onChangeMessageType value: " + JSON.stringify(value));

	}

	sendMessage = (event) => {
		event.preventDefault();

		console.log("Send message");

	  let firstname = event.target.firstname.value;
    console.log(`firstname is:`, firstname);
    let lastname = event.target.lastname.value;
    console.log(`lastname is:`, lastname);
		let email = event.target.email.value;
    console.log(`email is:`, email);
		let phone = event.target.phone.value;
		console.log(`phone is:`, phone);
		let allowphonecontact = event.target.allowphonecontact.value;
		console.log(`allowphonecontact is:`, allowphonecontact);
		let messagetype = event.target.messagetype.value;
		console.log(`messagetype is:`, messagetype);


		let subject = "";
		if (event.target.subject) {
			subject = event.target.subject.value;
		}
		console.log(`subject is:`, subject);

		let message = "";
		if (event.target.message) {
			message = event.target.message.value;
		}
		console.log(`message is:`, message);

		let formattedPhoneNumber = this.enforcePhoneNumberPattern(phone);
		console.log(`Formatted Phone Number is:`, formattedPhoneNumber);

		if (messagetype == "Email") {
			this.sendEmailMessage(email, subject, message);
		} else if (messagetype == "Wifi Email Template") {
			this.sendTemplateEmailMessage(email, 1);
		} else if (messagetype == "Low Battery Email Template") {
			this.sendTemplateEmailMessage(email, 2);
		} else if (messagetype == "Firmware Update Email Template") {
			this.sendTemplateEmailMessage(email, 3);
		} else {
			this.sendTextMessage(formattedPhoneNumber, subject, message);
		}
	}

	enforcePhoneNumberPattern(string) {
	  let newString = string.match(/[0-9]{0,14}/g);

	  if (newString === null) {
	    return '';
	  }

	  // Join parts returned from RegEx match
	  newString = newString.join('');

	  // Start number with "+"
	  newString = '+' + newString;

	  // Limit length to 15 characters
	  newString = newString.substring(0, 15);

	  return newString;
	}

	sendTextMessage = async (recipient, subject, message) => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-text-message-to-user?TextRecipient="+recipient+"&TextSubject="+subject+"&TextMessage="+encodeURIComponent(message);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("Send Text response: " + JSON.stringify(body.response));

		if (body.status == 'Success') {

			this.toggle4();
			this.setState({
				statusSubject: "SUCCESS!",
				statusMessage: "Text Message sent Successfully!"
			})

		} else {

			this.toggle4();
			this.setState({
				statusSubject: "FAILED!",
				statusMessage: "Failed to send Text Message to "+recipient+"."
			})

		}

		this.toggle5();

	};

	// sendEmailMessage = async (recipient, subject, message) => {
	//
	// 	var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-email-to-user?EmailRecipient="+recipient+"&EmailSubject="+subject+"&EmailMessage="+encodeURIComponent(message);
	//
	// 	//console.log("Send Email url: " + url);
	//
	// 	const response = await fetch(url, {
	// 		method: 'GET',
	// 		headers: {
	// 			Authorization: this.state.currentUser.jwtToken
	// 		},
	// 	});
	//
	// 	const body = await response.json();
	// 	console.log("Send Text response: " + JSON.stringify(body.response));
	//
	// 	if (body.status == 'Success') {
	//
	// 		this.toggle4();
	// 		this.setState({
	// 			statusSubject: "SUCCESS!",
	// 			statusMessage: "Email sent Successfully!"
	// 		})
	//
	// 	} else {
	//
	// 		this.toggle4();
	// 		this.setState({
	// 			statusSubject: "FAILED!",
	// 			statusMessage: "Failed to send Email to "+recipient+"."
	// 		})
	//
	// 	}
	//
	// 	this.toggle5();
	//
	// };

	sendEmailMessage = async (recipient, subject, message) => {

		var requestBody = {
			emailRecipient:[recipient],
			emailSubject:subject,
			emailHTMLMessage:message,
			emailTextMessage:message
		}

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-email-to-user";

		try {

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: this.state.currentUser.jwtToken,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(requestBody)
			});

			const body = await response.json();
			console.log("send-email-to-user response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				this.toggle4();
				this.setState({
					statusSubject: "SUCCESS!",
					statusMessage: "Email sent Successfully!"
				})

			} else {

				this.toggle4();
				this.setState({
					statusSubject: "FAILED!",
					statusMessage: "Failed to send Email to "+recipient+"."
				})

			}

		}
		catch (err) {
			console.log(err);
		}

		this.toggle5();

	};

	sendTemplateEmailMessage = async (recipient, template) => {

		var textBody = getEmailTextMessageOne();
		var htmlBody = getEmailHTMLMessageOne();

		var requestBody = {
			emailRecipient:[recipient],
			emailSubject:'Wifi related issues - Update Failure',
			emailHTMLMessage:htmlBody,
			emailTextMessage:textBody
		}

		if (template==1) {
			textBody = getEmailTextMessageOne();
			htmlBody = getEmailHTMLMessageOne();

			requestBody = {
				emailRecipient:[recipient],
				emailSubject:'Wifi related issues - Update Failure',
				emailHTMLMessage:htmlBody,
				emailTextMessage:textBody
			}
		} else if (template==2) {
			textBody = getEmailTextMessageTwo();
			htmlBody = getEmailHTMLMessageTwo();

			requestBody = {
				emailRecipient:[recipient],
				emailSubject:'Low Battery issues - Update Failure',
				emailHTMLMessage:htmlBody,
				emailTextMessage:textBody
			}
		} else if (template==3) {
			textBody = getEmailTextMessageThree();
			htmlBody = getEmailHTMLMessageThree();

			requestBody = {
				emailRecipient:[recipient],
				emailSubject:'Firmware update related issues - Update Failure',
				emailHTMLMessage:htmlBody,
				emailTextMessage:textBody
			}
		}

		var url = "https://cqzybp52y3.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-email-to-user";

		try {

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: this.state.currentUser.jwtToken,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(requestBody)
			});

			const body = await response.json();
			console.log("send-email-to-user response: " + JSON.stringify(body));

			if (body.status == 'Success') {

				this.toggle4();
				this.setState({
					statusSubject: "SUCCESS!",
					statusMessage: "Email sent Successfully!"
				})

			} else {

				this.toggle4();
				this.setState({
					statusSubject: "FAILED!",
					statusMessage: "Failed to send Email to "+recipient+"."
				})

			}

		}
		catch (err) {
			console.log(err);
		}

		this.toggle5();

	};

  handleSubmit = (event) => {
		event.preventDefault();
		let id = event.target.id.value;
    //console.log(`id:`, id);
	  let firstname = event.target.firstname.value;
    //console.log(`firstname is:`, firstname);
    let lastname = event.target.lastname.value;
    //console.log(`lastname is:`, lastname);
		let email = event.target.email.value;
    //console.log(`email is:`, email);
		let phone = event.target.phone.value;
		//console.log(`phone is:`, phone);
		let allowphonecontactChange = event.target.allowphonecontactChange.value;

		if (allowphonecontactChange == "YES") {
			allowphonecontactChange = "1";
		} else {
			allowphonecontactChange = "0";
		}
		//console.log(`phone is:`, phone);
		let role = event.target.role.value;

    //console.log(`role is:`, role);
		let newObj = JSON.parse(JSON.stringify(this.state.users));
    //console.log(`user copy is:`, newObj);
		//newObj[id] = [firstname, lastname, email, role];
    newObj[id] = {
			Attributes: [ /* required */
				    {
				      Name: 'given_name', /* required */
				      Value: firstname
				    },
						{
				      Name: 'family_name', /* required */
				      Value: lastname
				    },
						{
				      Name: 'email', /* required */
				      Value: email
				    },
						{
				      Name: 'custom:role', /* required */
				      Value: role
				    },
						{
				      Name: 'custom:phone', /* required */
				      Value: phone
				    },
						{
				      Name: 'custom:allowphonecontact', /* required */
				      Value: allowphonecontactChange
				    },
				  ],
    }
		this.setState({
			users: newObj,
			modal: !this.state.modal
		})
    console.log("saved user is:", this.state.users[id].Attributes);

    this.updateUser(firstname, lastname, phone, email, allowphonecontactChange);
	}

  updateUser = async (firstname, lastname, phone, email, allowphonecontact) => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-user?UserEmail="+email+"&UserPhone="+phone+"&UserFirstName="+firstname+"&UserLastName="+lastname+"&AllowPhoneContact="+allowphonecontact;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("Device Registered User response: " + JSON.stringify(body.response));

		if (body.status == 'Success') {
			//this.setState({deviceInfo: body.response});
			//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
			this.state.noDataForDevices = false;
		} else {
			this.state.noDataForDevices = true;
		}

	};

	deleteUser = async () => {

		this.setState({ modal3: false });

		console.log("Delete User: " + this.state.obj.email);

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-user?UserEmail="+this.state.obj.email;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("Delete Registered User response: " + JSON.stringify(body));

		if (body.status == 'Success') {
			//this.setState({deviceInfo: body.response});
			//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
			const arrayCopy = this.state.users.filter((row) => row.Username !== this.state.obj.userID);
    	this.setState({users: arrayCopy});
		} else {

		}

	};

	deleteRequestedUser = async (obj) => {

		this.setState({ modal3: false });

		console.log("Delete User: " + obj.email);

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-user?UserEmail="+obj.email;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("Delete Registered User response: " + JSON.stringify(body));

		if (body.status == 'Success') {
			//this.setState({deviceInfo: body.response});
			//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
			const arrayCopy = this.state.users.filter((row) => row.Username !== obj.email);
    	this.setState({users: arrayCopy});

			this.getData();
		} else {

		}

	};

  handleChange = (selectedOption) => {
    console.log(`Option selected:`, selectedOption.target.value);
    console.log(`obj is:`, this.state.obj);
    this.state.obj.role = selectedOption.target.value;
    console.log(`new obj is:`, this.state.obj);
  }

	// filterCaseInsensitive(filter, row) {
	// 	const id = filter.pivotId || filter.id;
	//
	// 	if (row[id] !== null){
	// 		return (
	// 				row[id] !== undefined ?
	// 						String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
	// 				:
	// 						true
	// 		);
	// 	}
	// }

	filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id;

		//console.log("filterCaseInsensitive row[id]: " + JSON.stringify(id));

		if (id == 'userDevices') {

			let provisionedSearchString = "Wifi, Provisioned, un, not, no";

			if (String(provisionedSearchString.toString().toLowerCase()).includes(filter.value.toLowerCase())) {

				let unprovisionedSearchString = "not un no";

				var searchProvisioned = true;

				if (String(unprovisionedSearchString.toString().toLowerCase()).includes(filter.value.toLowerCase())) {
					searchProvisioned = false
				}

				var deviceProvisioned = false;

				for (var i=0; i<row[id].length; i++) {

					if (row[id][i].geo_timestamp || row[id][i].WifiProvisioned) {
						deviceProvisioned = true;
						break;
					}

				}

				return (

					searchProvisioned ? deviceProvisioned : !deviceProvisioned

				);

			} else {

				var serialString = "";

				for (var i=0; i<row[id].length; i++) {

					if (i==0) {
						serialString = row[id][i].SerialNumber;
					} else {
						serialString = serialString+", "+row[id][i].SerialNumber;
					}

				}

				//console.log("filterCaseInsensitive serialString: " + JSON.stringify(serialString));

				return (
						serialString !== undefined ?
								String(serialString.toString().toLowerCase()).includes(filter.value.toLowerCase())
						:
								true
				);

			}

		}

		if (id == 'creation_date') {
			var dateString = Moment(row[id]).format('MMM Do YYYY');
			return (
					dateString !== undefined ?
							String(dateString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (row[id] !== null){
			return (
					row[id] !== undefined ?
							String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}
	}

	render() {

		console.log('render this.state.users: ' + JSON.stringify(this.state.users));

		var deletionRequestData = this.state.accountDeletionRequests.map((request, key) => {

			return {
				id: key,
        requestID: request.id,
				email: request.email,
				status: request.status,
				CreationDate: request.CreationDate,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						{request.status!=1 &&
							<Button
								onClick={() => {
									let obj = deletionRequestData.find(o => o.id === key);
									console.log('button obj: ' + obj);
									this.setState({
										obj: obj
									});

									this.resolveUserRequestToDeleteDevices(obj);
								}}
								color="link"
								size="sm"
								round="true"
								icon="true"
								className="ml-2"
								title="Delete Account"
							>
								<i style={{ color: "#e1212c"}} className="fa fa-trash-alt fa-lg" />
							</Button>
						}

						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let newdata = data2;
								newdata.find((o, i) => {
									if (o.id === key) {
										newdata.splice(i, 1);
										console.log(newdata);
										return true;
									}
									return false;
								});
								this.setState({ jsonData: newdata });
							}}
							className="ml-1"
							color="danger"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-times" />
						</Button> */}
					</div>
				)
			};
		});

    var data = this.state.users.map((user, key) => {

			var role = user.Attributes.filter(attribute => attribute.Name == "custom:role").map(name => name.Value)

			var roleString = "General User";
			if (role) {
				if (role == "1") {
					roleString = "Tech Support"
				} else if (role == "2") {
					roleString = "Administrator"
				}
			}

			var allowphonecontact = user.Attributes.filter(attribute => attribute.Name == "custom:allowphonecontact").map(name => name.Value)
			var allowphonecontactString = "No";
			if (allowphonecontact) {
				if (allowphonecontact == "0") {
					allowphonecontactString = "No"
				} else if (allowphonecontact == "1") {
					allowphonecontactString = "Yes"
				}
			}

			var userDevices = this.state.devices.filter(device => {

				if (device.LoginID) {
					if (device.LoginID.toString().toLowerCase() == user.Username.toString().toLowerCase()) {
						return true;
					}
				}

			});
			console.log('user:'+user.Username+' Devices: ' + JSON.stringify(userDevices));

			// Type is 1=at least 1 device Fully Registered, 2=all devices Registered + Scanned (exit App), 3=no devices
			var dataFilterType = 0;

			if (userDevices.length>0) {

				userDevices.map((device, i) => {

					(device.geo_timestamp || device.WifiProvisioned) ? dataFilterType=1 : dataFilterType=2;
				});

			} else {
				dataFilterType = 3;
			}

			return {
				id: key,
        userID: user.Username,
				firstname: user.Attributes.filter(attribute => attribute.Name == "given_name").map(name => name.Value),
				lastname: user.Attributes.filter(attribute => attribute.Name == "family_name").map(name => name.Value),
				phone: user.Attributes.filter(attribute => attribute.Name == "custom:phone").map(name => name.Value),
				email: user.Attributes.filter(attribute => attribute.Name == "email").map(name => name.Value),
				roleString: {role: roleString, id: key},
				role: role,
				allowphonecontactString: allowphonecontactString,
				allowphonecontact: allowphonecontact,
				creation_date: user.UserCreateDate,
				userDevices: userDevices,
				dataFilterType: dataFilterType,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
								console.log('button obj: ' + obj);
								this.setState({
									obj: obj
								});
								this.showVerification(obj);
							}}
							color="link"
							size="sm"
							round="true"
							icon="true"
							title="Edit User Details"
						>
							<i className="mdi mdi-verified mdi-24px" />
						</Button>
						<Button
							onClick={() => {
								let obj = data.find(o => o.id === key);
								console.log('button obj: ' + obj);
								this.setState({
									modal4: !this.state.modal4,
									obj: obj
								});
								//this.showVerification(obj);
							}}
							color="link"
							size="sm"
							round="true"
							icon="true"
							className="ml-2"
							title="Send Message"
						>
							<i className="mdi mdi-email mdi-24px" />
						</Button>
						{this.state.currentUser.payload["custom:role"] && this.state.currentUser.payload["custom:role"]>1 &&
							<Button
								onClick={() => {
									let obj = data.find(o => o.id === key);
									console.log('button obj: ' + obj);
									this.setState({
										obj: obj
									});

									this.getUserToDeleteDevices(obj);
								}}
								color="link"
								size="sm"
								round="true"
								icon="true"
								className="ml-2"
								title="Delete Account"
							>
								<i style={{ color: "#e1212c"}} className="fa fa-trash-alt fa-lg" />
							</Button>
						}

						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let newdata = data2;
								newdata.find((o, i) => {
									if (o.id === key) {
										newdata.splice(i, 1);
										console.log(newdata);
										return true;
									}
									return false;
								});
								this.setState({ jsonData: newdata });
							}}
							className="ml-1"
							color="danger"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-times" />
						</Button> */}
					</div>
				)
			};
		});

		var csvData = [];

		if (this.state.currentDataSetting!=0) {
			data = data.filter(user => user.dataFilterType == this.state.currentDataSetting);

			csvData = data.map((user, key) => {

				var devices = "";

				user.userDevices.map((device, i) => {

					if (i>0) {
						devices = devices+", "+device.SerialNumber;
					} else {
						devices = device.SerialNumber;
					}

				});

				return {
					id: key,
	        email: user.userID,
					firstname: user.firstname,
					lastname: user.lastname,
					phone: user.phone,
					email: user.email,
					roleString: user.roleString.role,
					allowphonecontactString: user.allowphonecontactString,
					creation_date: user.creation_date,
					userDevices: devices
				};

			});

			//console.log('csvData: ' + JSON.stringify(csvData));
		}

    const filterCaseInsensitive = (filter, row) => {
           const id = filter.pivotId || filter.id;
           if (row[id] !== null){
               return (
                   row[id] !== undefined ?
                       String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                       :
                       true
               );
           }
       };

		//admin columns
		var columns = [
			{
				Header: "Created",
				accessor: "creation_date",
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				Cell: row => (
						<div style={{ textAlign: "center" }}>{Moment(row.value).format('MMM Do YYYY')}</div>
					)
			},
			{
				Header: "First Name",
				accessor: "firstname",
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				Cell: row => (
						<div style={{ textAlign: "center" }}>{row.value}</div>
					)
			},
			{
				Header: "Last Name",
				accessor: "lastname",
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				Cell: row => (
						<div style={{ textAlign: "center" }}>{row.value}</div>
					)
			},
			{
				Header: "Email",
				accessor: "email",
				minWidth: 260,
				maxWidth: 280,
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				Cell: row => (
						<div style={{ textAlign: "center" }}>{row.value}</div>
					)
			},
			{
				Header: "Phone Number",
				accessor: "phone",
				minWidth: 160,
				maxWidth: 190,
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				Cell: row => (
						<div style={{ textAlign: "center" }}>{row.value}</div>
					)
			},
			{
				Header: "Registered Device",
				accessor: "userDevices",
				minWidth: 150,
				maxWidth: 180,
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				Cell: row => (
					<div style={{ textAlign: "center" }}>
						{row.value && row.value.length>0 ? (
							<Fragment>
							{row.value.map((device, i) => (
								<Fragment>
									{device.geo_timestamp || device.WifiProvisioned ? (
										<Fragment>
											<Col xs="12">
												{device.AppPlatform!=1 && device.AppPlatform!=2 &&
													<Fragment>
													<span>Serial: </span><span className="mr-1" style={{ color: "#ffc108"}}>{device.SerialNumber}</span><i style={{ color: '#42BF00'}} className="mdi mdi-checkbox-marked-circle mdi-18px" />
													</Fragment>
												}
												{device.AppPlatform==1 &&
													<Fragment>
													<i style={{ fontSize: "22px", position: "relative", top: "4px", color: "#fff"}} className="material-icons">apple</i><span className="ml-1">Serial: </span><span className="mr-1" style={{ color: "#ffc108"}}>{device.SerialNumber}</span><i style={{ color: '#42BF00'}} className="mdi mdi-checkbox-marked-circle mdi-18px" />
													</Fragment>
												}
												{device.AppPlatform==2 &&
													<Fragment>
													<i style={{ fontSize: "22px", position: "relative", top: "4px", color: "#a4c639"}} className="material-icons">android</i><span className="ml-1">Serial: </span><span className="mr-1" style={{ color: "#ffc108"}}>{device.SerialNumber}</span><i style={{ color: '#42BF00'}} className="mdi mdi-checkbox-marked-circle mdi-18px" />
													</Fragment>
												}
											</Col>
										</Fragment>
									) : (
										<Fragment>
											<Col xs="12">
												{device.AppPlatform!=1 && device.AppPlatform!=2 &&
													<Fragment>
													<span>Serial: </span><span className="mr-1" style={{ color: "#ffc108"}}>{device.SerialNumber}</span><i style={{ color: '#e2212b'}} className="mdi mdi-checkbox-marked-circle mdi-18px" />
													</Fragment>
												}
												{device.AppPlatform==1 &&
													<Fragment>
													<i style={{ fontSize: "22px", position: "relative", top: "4px", color: "#fff"}} className="material-icons">apple</i><span className="ml-1">Serial: </span><span className="mr-1" style={{ color: "#ffc108"}}>{device.SerialNumber}</span><i style={{ color: '#e2212b'}} className="mdi mdi-checkbox-marked-circle mdi-18px" />
													</Fragment>
												}
												{device.AppPlatform==2 &&
													<Fragment>
													<i style={{ fontSize: "22px", position: "relative", top: "4px", color: "#a4c639"}} className="material-icons">android</i><span className="ml-1">Serial: </span><span className="mr-1" style={{ color: "#ffc108"}}>{device.SerialNumber}</span><i style={{ color: '#e2212b'}} className="mdi mdi-checkbox-marked-circle mdi-18px" />
													</Fragment>
												}
											</Col>
										</Fragment>
									)}
								</Fragment>
								))
							}

							</Fragment>
						) : (
							<Fragment>
								<span>No Devices</span>
							</Fragment>
						)}
						</div>
					)
			},
			{
				Header: "Send SMS",
				accessor: "allowphonecontactString",
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				Cell: row => (
						<div style={{ textAlign: "center" }}>{row.value}</div>
					)
			},
			{
				Header: "Role",
				accessor: "roleString",
				minWidth: 130,
				maxWidth: 160,
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				Cell: row => (
						<Fragment>
							{
								//<div style={{ textAlign: "center" }}>{row.value}</div>
								// <Input style={{height: '30px', padding: '3px'}} type="select" name="RepairIDsOne" id="RepairIDsOne" value={this.state.selectedModalRepair} onChange={this.onChangeRepairSelection}>
								// 	<option value="0">Select Repair</option>
								// </Input>
							}
							<Input style={{height: '30px', padding: '3px'}} type="select" value={row.value.role} onChange={(e)=>this.changeUserRole(e, row.value.id)}>
								<option value="General User">General User</option>
								<option value="Tech Support">Tech Support</option>
								<option value="Administrator">Administrator</option>
							</Input>
						</Fragment>
					)
			},
			{
				Header: "Actions",
				accessor: "actions",
				style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
				minWidth: 160,
				maxWidth: 200,
				sortable: false,
				filterable: false
			}
		];

		if (this.state.currentUser.payload["custom:role"] && this.state.currentUser.payload["custom:role"]==1) {

			if (this.state.currentUser.payload["custom:market"] == "us-azena") {
				columns = [
					{
						Header: "Created",
						accessor: "creation_date",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						Cell: row => (
								<div style={{ textAlign: "center" }}>{Moment(row.value).format('MMM Do YYYY')}</div>
							)
					},
					{
						Header: "First Name",
						accessor: "firstname",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "Last Name",
						accessor: "lastname",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "Email",
						accessor: "email",
						minWidth: 220,
						maxWidth: 250,
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "Phone Number",
						accessor: "phone",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "Registered Device",
						accessor: "userDevices",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						Cell: row => (
							<div style={{ textAlign: "center" }}>
								{row.value && row.value.length>0 ? (
									<Fragment>
									{row.value.map((device, i) => (
										<Fragment>
											{device.geo_timestamp || device.WifiProvisioned ? (
												<Fragment>
													<Col xs="12">
														<span>Serial: </span><span className="mr-1" style={{ color: "#ffc108"}}>{device.SerialNumber}</span><i style={{ color: '#42BF00'}} className="mdi mdi-checkbox-marked-circle mdi-18px" />
													</Col>
												</Fragment>
											) : (
												<Fragment>
													<Col xs="12">
														<span>Serial: </span><span style={{ color: "#ffc108"}}>{device.SerialNumber}</span>
													</Col>
												</Fragment>
											)}
										</Fragment>
										))
									}

									</Fragment>
								) : (
									<Fragment>
										<span>No Devices</span>
									</Fragment>
								)}
								</div>
							)
					},
					{
						Header: "Send SMS",
						accessor: "allowphonecontactString",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "Actions",
						accessor: "actions",
						minWidth: 160,
						maxWidth: 200,
						sortable: false,
						filterable: false
					}
				];
			} else {
				columns = [
					{
						Header: "Created",
						accessor: "creation_date",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						Cell: row => (
								<div style={{ textAlign: "center" }}>{Moment(row.value).format('MMM Do YYYY')}</div>
							)
					},
					{
						Header: "First Name",
						accessor: "firstname",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "Last Name",
						accessor: "lastname",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "Email",
						accessor: "email",
						minWidth: 220,
						maxWidth: 250,
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "Phone Number",
						accessor: "phone",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					},
					{
						Header: "Registered Device",
						accessor: "userDevices",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						Cell: row => (
							<div style={{ textAlign: "center" }}>
								{row.value && row.value.length>0 ? (
									<Fragment>
									{row.value.map((device, i) => (
										<Fragment>
											{device.geo_timestamp || device.WifiProvisioned ? (
												<Fragment>
													<Col xs="12">
														<span>Serial: </span><span className="mr-1" style={{ color: "#ffc108"}}>{device.SerialNumber}</span><i style={{ color: '#42BF00'}} className="mdi mdi-checkbox-marked-circle mdi-18px" />
													</Col>
												</Fragment>
											) : (
												<Fragment>
													<Col xs="12">
														<span>Serial: </span><span style={{ color: "#ffc108"}}>{device.SerialNumber}</span>
													</Col>
												</Fragment>
											)}
										</Fragment>
										))
									}

									</Fragment>
								) : (
									<Fragment>
										<span>No Devices</span>
									</Fragment>
								)}
								</div>
							)
					},
					{
						Header: "Send SMS",
						accessor: "allowphonecontactString",
						style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
						Cell: row => (
								<div style={{ textAlign: "center" }}>{row.value}</div>
							)
					}
				];
			}

		}

		return (
      <Card className="card-hover">
				<CardBody>
          <Row className="mb-3">
            <Col xs="6" md="6" lg="6">
              <h1 style={{color:'#737782'}}>Gemini Users</h1>
            </Col>
						<Col xs="6" md="6" lg="6" className="text-right">
							{this.state.currentUser.payload["custom:role"] && this.state.currentUser.payload["custom:role"]>1 &&
								<Button className="float-right ml-3" color="yellow" onClick={this.toggle6}>Deleted Accounts
									<Fragment>
									{
										// {this.state.badgeCount > 0 &&
										// 	<Fade><Badge style={{ position: "absolute", top: "-13px", right: "-13px", fontSize: "14px" }} color="danger" pill>{this.state.badgeCount}</Badge></Fade>
										// }
									}
									</Fragment>
								</Button>
							}
							<ButtonDropdown isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
								<DropdownToggle color="dark" caret>
									{this.state.currentItem}
								</DropdownToggle>
								<DropdownMenu right>
									<DropdownItem onClick={() => this.selectDropDownItem(0, 'All Devices')}>All Devices</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(1, 'Fully Registered')}>Fully Registered</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(2, 'Registered + Scanned (exit App)')}>Registered + Scanned (exit App)</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(3, 'Registered (not scanned)')}>Registered (not scanned)</DropdownItem>
								</DropdownMenu>
							</ButtonDropdown>
							{this.state.currentDataSetting === 2 &&
								<CSVLink
									data={csvData}
									filename={"registered+scanned(exit App).csv"}
									className="ml-3 float-right btn btn-primary"
									target="_blank">
									Download
								</CSVLink>
							}
						</Col>
          </Row>
          <ReactTable
            style={{backgroundColor:'#000', padding:'10px'}}
						columns={columns}
						defaultPageSize={15}
            sortable={true}
						sorted={[{ // the sorting model for the table
					      id: 'creation_date',
					      desc: true
					    }]}
						showPaginationBottom={true}
						className="-striped -highlight"
						data={data}
						filterable
						filterAll={true}
						defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
					>
					{(state, makeTable, instance) => {
				            let recordsInfoText = "";

				            const { filtered, pageRows, pageSize, sortedData, page } = state;

				            if (sortedData && sortedData.length > 0) {
				              let isFiltered = filtered.length > 0;

				              let totalRecords = sortedData.length;

				              let recordsCountFrom = page * pageSize + 1;

				              let recordsCountTo = recordsCountFrom + pageRows.length - 1;

				              if (isFiltered)
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
				              else
				                recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
				            } else recordsInfoText = "No records";

				            return (
				              <div className="main-grid mt-2">
												{makeTable()}
												<Row className="mt-2 text-center">
													<Col md="12">
														<Label style={{fontSize:'15px'}} for=""><b>{recordsInfoText}</b></Label>
													</Col>
												</Row>
				              </div>
				            );
				          }}
				        </ReactTable>
        </CardBody>
				<div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
  				<ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
  				<ModalBody>
  					<Form onSubmit={this.handleSubmit}>
  						<Input type="hidden" name="id" id="id" defaultValue={this.state.obj.id}/>
              <Input type="hidden" name="userID" id="userID" defaultValue={this.state.obj.userID}/>
							<Input type="hidden" name="email" id="email" defaultValue={this.state.obj.email}/>
							<Input type="hidden" name="role" id="role" defaultValue={this.state.obj.role}/>
							<Input type="hidden" name="allowphonecontact" id="allowphonecontact" defaultValue={this.state.obj.allowphonecontact}/>
  						<FormGroup>
  							<Label for="firstname">First Name</Label>
  							<Input type="text" name="firstname" id="firstname" defaultValue={this.state.obj.firstname}/>
  						</FormGroup>
  						<FormGroup>
  							<Label for="lastname">Last Name</Label>
  							<Input type="text" name="lastname" id="lastname" defaultValue={this.state.obj.lastname}/>
  						</FormGroup>
							<FormGroup>
  							<Label for="lastname">Phone Number</Label>
  							<Input type="text" name="phone" id="phone" defaultValue={this.state.obj.phone}/>
  						</FormGroup>
							<FormGroup>
				        <Label for="messagetype">Send SMS:</Label>
				        <Input type="select" name="allowphonecontactChange" id="allowphonecontactChange" defaultValue={this.state.obj.allowphonecontact==1 ? "YES" : "NO"}>
				          <option>YES</option>
									<option>NO</option>
				        </Input>
				      </FormGroup>
  						<FormGroup>
  							<Button color="primary" type="submit">Save</Button>
  							<Button color="secondary" className="ml-1" onClick={this.toggle}>Cancel</Button>
  						</FormGroup>
  					</Form>
  				</ModalBody>
			  </Modal>
        <Modal isOpen={this.state.modal2} toggle={this.toggle2}>
  				<ModalHeader toggle={this.toggle2}>Enter the Code</ModalHeader>
  				<ModalBody>
						<Row>
							<Col xs="12" md="12" lg="12" className="text-left">
								<h4>We sent a verification code to: {this.state.obj.email}</h4>
							</Col>
						</Row>
  					<Form onSubmit={this.handleVerifySubmit}>
  						<FormGroup>
  							<Label for="code">Verification Code</Label>
  							<Input type="text" name="code" id="code"/>
  						</FormGroup>
							{this.state.verificationError &&
														<div className={'alert alert-danger'}>Verification code does not match.</div>
												}
  						<FormGroup>
  							<Button color="primary" type="submit">VERIFY CODE</Button>
								<Button color="secondary" className="ml-1" onClick={()=>{ this.sendCode(this.state.obj)} }>RESEND EMAIL</Button>
  							<Button color="secondary" className="ml-1" onClick={this.toggle2}>CANCEL</Button>
  						</FormGroup>
  					</Form>
  				</ModalBody>
			  </Modal>
				<Modal isOpen={this.state.modal3} toggle={this.toggle3}>
  				<ModalHeader toggle={this.toggle3}>Delete User: {this.state.obj.email}</ModalHeader>
  				<ModalBody>
						{this.state.userToDeleteDevices.length>0 ? (
							<span>Are you sure you want to delete this user with the following devices?</span>
						) : (
							<span>Are you sure you want to delete this user?</span>
						)}
						<Row className="mt-3 mb-3">
							{this.state.userToDeleteDevices.map((device, key) => {
												return (

													<Col xs="4" md="4" lg="4">
														<Button
															style={{ backgroundColor: '#000', width: '100%'}}
															onClick={() => {

															}}
															color="link"
															size="sm"
															round="true"
															className="mt-1 mb-1"
														>
															{device.Nickname}
														</Button>
													</Col>

													);
											 })}
	          </Row>
  				</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.deleteUser}>Delete</Button>
						<Button color="secondary" className="ml-1" onClick={this.toggle3}>Cancel</Button>
					</ModalFooter>
			  </Modal>
				<Modal isOpen={this.state.modal4} toggle={this.toggle4}>
  				<ModalHeader toggle={this.toggle4}>Send a Message</ModalHeader>
  				<ModalBody>
						<Row>
							<Col xs="12" md="12" lg="12" className="text-left pb-3">
								<h4>Send message to: {this.state.obj.firstname} {this.state.obj.lastname}</h4>
							</Col>
						</Row>
  					<Form onSubmit={this.sendMessage}>
							<Input type="hidden" name="email" id="email" defaultValue={this.state.obj.email}/>
							<Input type="hidden" name="phone" id="phone" defaultValue={this.state.obj.phone}/>
							<Input type="hidden" name="firstname" id="firstname" defaultValue={this.state.obj.firstname}/>
							<Input type="hidden" name="lastname" id="lastname" defaultValue={this.state.obj.lastname}/>
							<Input type="hidden" name="allowphonecontact" id="allowphonecontact" defaultValue={this.state.obj.allowphonecontact}/>
							<FormGroup>
				        <Label for="messagetype">Select Message Type:</Label>
				        <Input type="select" name="messagetype" id="messagetype" onChange={this.onChangeMessageType}>
				          <option>Email</option>
									{this.state.obj.allowphonecontact == "1" &&
										<Fragment>
										<option>Text Message</option>
										</Fragment>
									}
				        </Input>
				      </FormGroup>
							<Fragment>
							<FormGroup>
								<Label for="subject">Subject:</Label>
								<Input type="text" name="subject" id="subject" placeholder="Enter your subject..."/>
							</FormGroup>
							<FormGroup>
								<Label for="message">Message:</Label>
								<Input type="textarea" name="message" id="message" placeholder="Enter your message..."/>
							</FormGroup>
							</Fragment>
  						<FormGroup>
  							<Button color="primary" type="submit">Send</Button>
  							<Button color="secondary" className="ml-1" onClick={this.toggle4}>Cancel</Button>
  						</FormGroup>
  					</Form>
  				</ModalBody>
			  </Modal>
				<Modal isOpen={this.state.modal5} toggle={this.toggle5}>
  				<ModalHeader toggle={this.toggle5}>{this.state.statusSubject}</ModalHeader>
  				<ModalBody>
						<span>{this.state.statusMessage}</span>
  				</ModalBody>
					<ModalFooter>
						<Button color="primary" className="ml-1" onClick={this.toggle5}>OK</Button>
					</ModalFooter>
			  </Modal>
				<Modal isOpen={this.state.modal6} toggle={this.toggle6} contentClassName="custom-modal-style-medium">
  				<ModalHeader toggle={this.toggle6}>Deleted Accounts</ModalHeader>
  				<ModalBody>
						<ReactTable
							style={{backgroundColor:'#000', padding:'10px', autocomplete: 'none'}}
							columns={[
								{
									Header: "Date",
									accessor: "CreationDate",
									minWidth: 4,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{Moment(row.value).format('MM/DD/YYYY')}</div>
										)
								},
								{
									Header: "Email",
									accessor: "email",
									minWidth: 8,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
											<div style={{ textAlign: "center" }}>{row.value}</div>
										)
								},
								{
									Header: "Status",
									accessor: "status",
									minWidth: 5,
									style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
									Cell: row => (
										<Fragment>
											<div style={{ textAlign: "center", color: "#41bf00" }}>COMPLETED</div>
										</Fragment>
										)
								}
							]}
							defaultPageSize={15}
							sortable={true}
							showPaginationBottom={true}
							className="-striped -highlight"
							data={deletionRequestData}
							filterable
							defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
							getTrGroupProps={(state, rowInfo, column, instance) => {
											if (rowInfo !== undefined) {
													return {
															onClick: (e, handleOriginal) => {
																console.log('It was in this row:', rowInfo)
																console.log('with ID:', rowInfo.original.categoryID);

																//this.goToRepairLog(rowInfo.original.repairId);
															}
													}
											}}
									}
						/>
  				</ModalBody>
					<ModalFooter style={{ "background-color": "#000"}}>
						<Button color="primary" className="ml-1" onClick={this.toggle6}>OK</Button>
					</ModalFooter>
			  </Modal>
				</div>
      </Card>
		);
	}
}

export default UsersList;
