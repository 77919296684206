import React from "react";
import { Component, Fragment, PureComponent } from "react";
import { Auth } from "aws-amplify";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	UncontrolledAlert,
	Fade
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import { authenticationService } from '../../../jwt/_services';

class ChangePassword extends React.Component {

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 3,
			totalProcedures: 0,
			modal: false,
			isEditing: false,
			currentUserEmail: JSON.parse(localStorage.getItem('currentUserEmail')),
			oldPassword: "",
			newPassword: "",
			confirmPassword: "",
			passwordChangeSuccessful: false,
			status: 1,
			code: 123456,
	  };

		this.toggle = this.toggle.bind(this);
  }

	async componentDidMount() {

	}

	async sendCode() {
		var code = Math.floor(100000 + Math.random() * 900000);
		console.log("random code: "+code);

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"verify-change?ConfirmationCode="+code;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.props.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("Device Registered User response: " + JSON.stringify(body.response));

		if (body.status == 'Success') {
			this.setState({code: code});
		}
	}

	async changePassword(oldPassword, newPassword, confirmPassword) {

		Auth.currentAuthenticatedUser()
    .then(user => {
        return Auth.changePassword(user, oldPassword, newPassword);
    })
    .then(data => {
			console.log("Password Update: "+data);
			this.setState({
		    passwordChangeSuccessful: true
		  });
			setTimeout(()=>{ this.fadeOutAlert(); }, 4000);
		})
    .catch(err => console.log(err));

	}

	fadeOutAlert() {
	  this.setState({
	    passwordChangeSuccessful: false
	  });
	}

	toggleEditing() {
		console.log("setEdit!");
		if (this.state.isEditing) {
			this.setState({isEditing: false});
		} else {
			this.setState({isEditing: true});
		}
	}

	setVerifyStatus(status) {
		console.log("set status!");

		if (status == 2) {
			this.sendCode();
		}

		this.setState({status: status});
	}

	toggle() {
	  this.setState({
	    show: !this.state.show
	  });
	}

	resetPasswordCall = () => {
		const { currentUser } = this.state;
		authenticationService.forgotPassword(this.state.currentUserEmail)
		.then(
				response => {

						let status = response.status;
						console.log('status: ' + status);

						if (status === 'Success') {
								console.log('reset password response info: ' + response);
								this.toggle();
						} else if (status === 'Error') {
								console.log('error info: ' + response.response);
						} else {
							console.log('status info: ' + status);
						}
				},
				error => {
						console.log('status info: ' + error);
				}
		);

	}

	render() {
		return (
      <Card className="card-hover">
				<CardBody style={{ height: 400 }}>
				{this.state.status == 1 &&
					<Fragment>
					<Fade in="true">
						<CardTitle>Change Password</CardTitle>
						<Row className="pb-4 pt-5">
							<Col xs="2" md="2" lg="2">
							</Col>
							<Col xs="2" md="2" lg="2" className="text-right">
								<h6>Email:</h6>
							</Col>
							<Col xs="6" md="6" lg="6" className="text-left">
								<h6 style={{ color: '#414755' }}>{this.state.currentUserEmail}</h6>
							</Col>
							<Col xs="2" md="2" lg="2">
							</Col>
						</Row>
						<Row className="text-center">
							<Col xs="1" md="1" lg="1">
							</Col>
							<Col xs="10" md="10" lg="10">
								<Button style={{width: '100%'}} color="dark" onClick={()=>{ this.setVerifyStatus(2)} }>
									Change Password
								</Button>
							</Col>
							<Col xs="1" md="1" lg="1">
							</Col>
						</Row>
						<Row className="text-center mt-4">
							<Col xs="1" md="1" lg="1">
							</Col>
							<Col xs="10" md="10" lg="10">
								<UncontrolledAlert color="brightgreen" isOpen={this.state.passwordChangeSuccessful} fade={true}>
									Password Successfully Changed
								</UncontrolledAlert>
							</Col>
							<Col xs="1" md="1" lg="1">
							</Col>
						</Row>
					</Fade>
					</Fragment>
				}
				{this.state.status == 2 &&
					<Fragment>
					<Fade in="true">
					<Row>
						<Col xs="6" md="6" lg="6" className="text-left">
							<CardTitle>Enter the Code</CardTitle>
						</Col>
						<Col xs="6" md="6" lg="6" className="text-right">
							<Button style={{ color: '#414755' }} size="sm" onClick={()=>{ this.setVerifyStatus(1)} }>Cancel</Button>
						</Col>
					</Row>
					<Row>
						<Col xs="12" md="12" lg="12" className="text-left">
							<h4>We sent a verification code to: {this.state.currentUserEmail}</h4>
						</Col>
					</Row>
					<Formik
											initialValues={{
													code: ""
											}}
											validationSchema={Yup.object().shape({
													code: Yup.string().required('Verification Code is Required')
											})}
											onSubmit={({ code }, { setStatus, setSubmitting }) => {
													//setStatus();

													if (code == this.state.code) {
														setSubmitting(false);
														this.setVerifyStatus(3);
													} else {
														setStatus("Code entered does not match.");
														setSubmitting(false);
													}

											}}
											render={({ errors, status, touched, isSubmitting }) => (
					<Form className="" id="loginform">
						<InputGroup className="mb-3">
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
									Verification Code
								</InputGroupText>
							</InputGroupAddon>
							<Field name="code" type="text" className={'form-control' + (errors.code && touched.code ? ' is-invalid' : '')} />
															<ErrorMessage name="code" component="div" className="invalid-feedback" />
						</InputGroup>

						<Row className="mb-3">
							<Col xs="12">
								<Button style={{width: '100%'}} color="dark" onClick={()=>{ this.sendCode()} }>
									RESEND EMAIL
								</Button>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col xs="12">
								<button type="submit" className="btn btn-block btn-primary" disabled={isSubmitting}>VERIFY CODE</button>
							</Col>
						</Row>
						{status &&
													<div className={'alert alert-danger'}>{status}</div>
											}
					</Form>
					)}
					/>
					</Fade>
						</Fragment>
				}
				{this.state.status == 3 &&
					<Fragment>
					<Fade in="true">
					<Row>
						<Col xs="6" md="6" lg="6" className="text-left">
							<CardTitle>Registered User</CardTitle>
						</Col>
						<Col xs="6" md="6" lg="6" className="text-right">
							<Button style={{ color: '#414755' }} size="sm" onClick={()=>{ this.setVerifyStatus(1)} }>Cancel</Button>
						</Col>
					</Row>
					<Formik
											initialValues={{
													oldPassword: this.state.oldPassword,
													newPassword: this.state.newPassword,
													confirmPassword: this.state.confirmPassword
											}}
											validationSchema={Yup.object().shape({
													oldPassword: Yup.string().required('Old Password is required'),
													newPassword: Yup.string().required('New Password is required'),
													confirmPassword: Yup.string().required('Confirm Password is required')
											})}
											onSubmit={({ oldPassword, newPassword, confirmPassword }, { setStatus, setSubmitting }) => {

													if (newPassword != confirmPassword) {
														setStatus("New Password and Confirm Password must match.");
														setSubmitting(false);
													} else {
														this.changePassword(oldPassword, newPassword, confirmPassword);
														this.setVerifyStatus(1);
													}

											}}
											render={({ errors, status, touched, isSubmitting }) => (
					<Form className="" id="changePasswordForm">
						<InputGroup className="mb-2">
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
									Old Password
								</InputGroupText>
							</InputGroupAddon>
							<Field name="oldPassword" type="password" className={'form-control' + (errors.oldPassword && touched.oldPassword ? ' is-invalid' : '')} />
															<ErrorMessage name="oldPassword" component="div" className="invalid-feedback" />
						</InputGroup>

						<InputGroup className="mb-2">
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
									New Password
								</InputGroupText>
							</InputGroupAddon>
							<Field name="newPassword" type="password" className={'form-control' + (errors.newPassword && touched.newPassword ? ' is-invalid' : '')} />
															<ErrorMessage name="newPassword" component="div" className="invalid-feedback" />
						</InputGroup>

						<InputGroup className="mb-2">
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
									Confirm Password
								</InputGroupText>
							</InputGroupAddon>
							<Field name="confirmPassword" type="password" className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
															<ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
						</InputGroup>
						<Row className="mb-3">
							<Col xs="12">
								<button type="submit" className="btn btn-block btn-primary" disabled={isSubmitting}>Save</button>
							</Col>
						</Row>
						{status &&
													<div className={'alert alert-danger'}>{status}</div>
											}
					</Form>
					)}
					/>
					</Fade>
						</Fragment>
				}
        </CardBody>
				{
				// <div>
				// <Modal color="dark" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
	      //   <ModalHeader toggle={this.toggle}>Change Password</ModalHeader>
	      //   <ModalBody>
				// 		An email has been sent to {this.state.currentUserEmail}. Please follow the link provided to complete your password reset.
	      //   </ModalBody>
	      //   <ModalFooter>
	      //     <Button color="secondary" onClick={this.toggle}>OK</Button>
	      //   </ModalFooter>
	      // </Modal>
				// </div>
				}
      </Card>
		);
	}
}

export default ChangePassword;
