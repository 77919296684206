
import React, { Component, Fragment, useEffect, useState } from "react";
import {
	Card,
	CardBody,
	CardTitle,
	Row,
	Col,

} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as mqtt from 'react-paho-mqtt';
import Paho from 'paho-mqtt';
import { getConnectionURL } from "../../mqtt-helpers/mqtt-request";
import { authenticationService } from '../../jwt/_services';
import ReactGA from "react-ga4";
import {
	TotalHourlyUsagePVM,
	UsedPowerSettings,
	TotalProcedures,
	LaserUsage,
	WavelengthUsage,
	TopProcedures,
	TopProceduresCat,
	UnitsOn,
	ReturnOnInvestment
} from '../../components/dashboard/';

import update from 'react-addons-update';
import redpin from '../dashboard/imgs/red_pin.png';
import greenpin from '../dashboard/imgs/green_pin.png';

var messagesArray = [];
var online_markers = [];
var devicesIcons = [];

class MobileDashboard extends React.Component {

	intervalID = 0;
	intervalID2 = 1;

	//messagesArray = [];

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		//console.log('currentUser info: ' + JSON.stringify(currentUser));
		//console.log('currentUser info: ' + JSON.stringify(currentUser.token));

		this.onMessageArrived = this.onMessageArrived.bind(this);
    this.onConnectionLost = this.onConnectionLost.bind(this);

    this.state = {
			mainMessage: [''],
			subMessage: [''],
			online_markers: [],
			devicesLoaded: false,
			currentUser,
			client: null,
      messageToSend:'',
      isConnected: false,
			devicesLogged: [],
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			devices: [],
			onlineDevices: [],
			devicesIcons: [],
			count: 0,
			handlingMessage: false,
      authToken: ""
	  };

		this.handleMessage = this.handleMessage.bind(this);
		this.testCall = this.testCall.bind(this);

  }

	componentDidMount() {

		ReactGA.pageview(window.location.pathname);

    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('token')
    console.log("token: "+token)//123

    if (token) {
      if (token.length>0) {
        console.log("authToken: "+token)
        this.setState({authToken:token});
      }
    }

		//this.connectToMQTT();
		this.getData();

		//this.intervalID2 = setInterval(this.handleMessage, 3000);
		//this.intervalID = setInterval(this.getMessage, 5000);
	}

	componentWillUnmount() {
		clearInterval(this.intervalID);
		clearInterval(this.intervalID2);

		if (this.state.client && this.state.client.isConnected()) {
			this.state.client.disconnect();
		}

	}

	getData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"all-devices";

    var token = "";

		if (this.state.currentUser) {
			token = this.state.currentUser.jwtToken;
		}

    if (!this.state.currentUser) {
      token = this.state.authToken;
    }

    try {

      const response = await fetch(url, {
  			method: 'GET',
  			headers: {
  				Authorization: token
  			},
  		});

  		const body = await response.json();
  		console.log("all devices: " + JSON.stringify(body));

  		if (body.status == 'Success') {
  			this.setState({ devices: body.results });
  		} else {
        //ADD ERROR MESSAGE
      }

  		for ( var i = 0; i < this.state.devices.length; i++ ) {
        devicesIcons.push( {
  				serial_number: this.state.devices[i].SerialNumber,
          current_icon: redpin
  			});
  		}

      this.setState({ devicesLoaded: true });

      //console.log("MobileDashboard authToken: "+this.state.authToken);

		}
		catch (err) {
			console.log(err);
		}

	};

  connectToMQTT = async () => {

    var url = "https://hdlnjb45hb.execute-api.us-west-2.amazonaws.com/v1/request-url?RequestType=1";

    var token = "";

		if (this.state.currentUser) {
			token = this.state.currentUser.jwtToken;
		}

    if (!this.state.currentUser) {
      token = this.state.authToken;
    }

    try {

      const response = await fetch(url, {
  			method: 'GET',
  			headers: {
  				Authorization: token
  			},
  		});

      const body = await response.json();
  		console.log("connectToMQTT body: " + JSON.stringify(body));

  		if (body.status == 'Success') {
        var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        console.log('random hash: ' + hash);
        this.state.client = new Paho.Client(body.response, hash+this.props.match.params.id);
        this.state.client.onMessageArrived = this.onMessageArrived;
        this.state.client.onConnectionLost = this.onConnectionLost;
        this.state.client.connect({
          timeout: 3,
          useSSL: true,
          mqttVersion: 4,
          cleanSession: true,
          onSuccess: this.onConnect,
          onFailure: (e) => {console.log("here is the error" , e); }
        });
  		} else {
        //ADD ERROR MESSAGE
        console.log('error response: ' + body.response);
      }

		}
		catch (err) {
			console.log(err);
		}

	}

	// connectToMQTT = async () => {
	// 	authenticationService.getGlobalConnectionURL()
	// 			.then(
	// 					connectionUrl => {
	// 						console.log('connectToMQTT connectionUrl: ' + JSON.stringify(connectionUrl));
  //
	// 						if (connectionUrl.status == 'Success') {
	// 							var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
	// 							console.log('random hash: ' + hash);
	// 							this.state.client = new Paho.Client(connectionUrl.response, hash+this.props.match.params.id);
	// 							this.state.client.onMessageArrived = this.onMessageArrived;
	// 							this.state.client.onConnectionLost = this.onConnectionLost;
	// 							this.state.client.connect({
	// 								timeout: 3,
	// 								useSSL: true,
	// 								mqttVersion: 4,
	// 								cleanSession: true,
	// 								onSuccess: this.onConnect,
	// 								onFailure: (e) => {console.log("here is the error" , e); }
	// 							});
	// 						}
	// 					},
	// 					error => {
	// 						console.log('error.message: ' + error.message);
	// 					}
	// 			);
	// }

	onMessageArrived(entry) {
    //console.log("dashboard onMessageArrived:"+entry.payloadString);
		const msg = JSON.parse(entry.payloadString);
		if (msg.batch) {

			messagesArray.push(msg);
			//console.log("dashboard messagesArray:"+messagesArray);
			//console.log("dashboard_main_messages:"+this.state.mainMessage);
		} else {
			//console.log("dashboard_sub_messages:"+this.state.subMessage);
		}

  }

	testCall() {

		let messages = messagesArray;
		console.log("dashboard messagesArray 2:"+messages);
	}


	handleMessage = async () => {

		let messages = messagesArray;
		//console.log("dashboard messagesArray 2:"+messages);

		console.log("Handling Message");

		var resetArray = false;

		for ( var i = 0; i < messages.length; i++ ) {

			let msg = messages[i];

			var found_online_marker = false;

			var anIndex = 0;
			var onlineDevice = online_markers.find(function(device, index) {

				//console.log('handleMessage device.serial_number: ' + JSON.stringify(device.serial_number));
				//console.log('handleMessage msg.serial_num: ' + JSON.stringify(msg.serial_num));

				if(device.serial_number == msg.serial_num) {
					online_markers[ index ].last_update = new Date();
					//console.log('handleMessage found serial: ' + JSON.stringify(msg.serial_num));
					found_online_marker = true;
					return true;
				}
			});

			if ( found_online_marker == false ) {
				//console.log("unit on msg: "+JSON.stringify(msg));

				var city = "Unknown";
				var state = "Unknown";
				var country = "Unknown";

				let device = this.state.devices.find(d => d.SerialNumber === msg.serial_num);

				if (device) {

					console.log("handleMessage device not found serial: "+device.SerialNumber);
					console.log("handleMessage device not found city: "+device.geo_city);

					city = device.geo_city;
					state = device.geo_state;
					country = device.geo_country;
				}

				let life810 = msg.life810;
				let life980 = msg.life980;

				var d = Number(life810 + life980);
				var h = Math.floor(d / 3600);
				var m = Math.floor(d % 3600 / 60);
				var s = Math.floor(d % 3600 % 60);

				var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
				var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
				var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";

				let usageTotal = hDisplay;

				if (h == 0) {
					usageTotal = mDisplay;
				}

				if (m == 0) {
					usageTotal = sDisplay;
				}

				online_markers.push( {
					serial_number: msg.serial_num,
	        icon: greenpin,
					last_update: new Date(),
					city: city,
					state: state,
					country: country,
					version: msg.fw_ver,
					usage: usageTotal
				});

				//console.log('handleMessage online markers count: ' + JSON.stringify(online_markers.length));
				//console.log('handleMessage online markers: ' + JSON.stringify(online_markers));
			}

			if (i == (messages.length-1)) {
				resetArray = true;
			}

		}

		if (resetArray) {
			messagesArray = [];
		}

	}

	getMessage = () => {
    //console.log('mapcontainer lat: ' + this.mainMessage.geo_lat);
    //console.log('mapcontainer lon: ' + this.mainMessage.geo_lng);
    console.log('online markers count: ' + JSON.stringify(online_markers.length));
    //console.log('all markers: ' + JSON.stringify(this.state.markerObjects[12].icon.url));
    //this.state.devices[12].SerialNumber = "00100";
    //console.log('updated serial: ' + this.state.devices[12].SerialNumber);
		//console.log('devicesIcons: ' + this.state.devicesIcons);

		for ( var a = 0; a < online_markers.length; a++ ) {

			if ( new Date() - online_markers[ a ].last_update > 10000 ) {
				online_markers[a].icon = redpin;
			} else {
				online_markers[a].icon = greenpin;
			}

		}

    // for ( var a = 0; a < online_markers.length; a++ ) {
    //   for ( var i = 0; i < devicesIcons.length; i++ ) {
    //     if ( devicesIcons[ i ].serial_number == online_markers[ a ].serial_number ) {
    //       // console.log("Found marker " + online_markers[a].serial_number)
    //       if ( new Date() - online_markers[ a ].last_update > 10000 ) {
		//
		// 				devicesIcons[i].current_icon = redpin;
		// 				online_markers[a].icon = redpin;
		//
    //         // this.setState(update(this.state, {
    //         // 	devicesIcons: {
    //         // 		[i]: {
    //         //       current_icon: {
    //         //         $set: redpin
    //         //       }
    //         // 		}
    //         // 	}
    //         // }));
    //         // this.setState(update(this.state, {
    //         // 	online_markers: {
    //         // 		[a]: {
    //         //       icon: {
    //         //         $set: redpin
    //         //       }
    //         // 		}
    //         // 	}
    //         // }));
    //       } else {
		//
		// 				devicesIcons[i].current_icon = greenpin;
		// 				online_markers[a].icon = greenpin;
		//
    //         // this.setState(update(this.state, {
    //         // 	devicesIcons: {
    //         // 		[i]: {
    //         //       current_icon: {
    //         //         $set: greenpin
    //         //       }
    //         // 		}
    //         // 	}
    //         // }));
    //         // this.setState(update(this.state, {
    //         // 	online_markers: {
    //         // 		[a]: {
    //         //       icon: {
    //         //         $set: greenpin
    //         //       }
    //         // 		}
    //         // 	}
    //         // }));
    //       }
    //     }
    //   }
    // }
  }

	onConnect = () => {
    const { client } = this.state;
    console.log("Connected!!!!");
    //client.subscribe('00013/out');
    this.setState({isConnected: true, error: ''})
		//this.sendMessage("@@@get_all");
		console.log('myCurrentDevice id: ' + this.state.currentDevice);
		//this.setState({currentDevice: this.state.currentDevice});
		//const subChannel = "0#";
		const subChannel = "#";
		this.state.client.subscribe(subChannel);
  };

  onConnectionLost(responseObject) {
    if (responseObject.errorCode !== 0) {
      console.log("onConnectionLost:"+responseObject.errorMessage);
      this.setState({error: 'Lost Connection', isConnected: false});
			this.connectToMQTT();
    }
  }

	render() {
		return (
			<div>
				<Row>
					<Col lg="6" xl="4">
						<TotalHourlyUsagePVM authToken={this.state.authToken} />
						<UsedPowerSettings authToken={this.state.authToken} />
					</Col>
					<Col lg="6" xl="4">
						<TotalProcedures authToken={this.state.authToken} />
						<LaserUsage authToken={this.state.authToken} />
					</Col>
					<Col lg="6" xl="4">
						<WavelengthUsage authToken={this.state.authToken} />
					</Col>
					<Col lg="6" xl="4">
						<TopProcedures authToken={this.state.authToken} />
					</Col>
					<Col lg="6" xl="4">
						<TopProceduresCat authToken={this.state.authToken} />
					</Col>
					<Col lg="6" xl="4">
						<ReturnOnInvestment authToken={this.state.authToken} />
					</Col>
				</Row>
			</div>
		);
	}
}

export default MobileDashboard;
