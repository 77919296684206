// react plugin for creating vector maps
import React from "react";
import { Component, Fragment, PureComponent } from "react";
import ReactDOM from 'react-dom';
import update from 'react-addons-update';
import { VectorMap } from "react-jvectormap";
import { compose, withProps, withStateHandlers } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"
import Moment from 'moment';
import redpin from './imgs/red_pin.png';
import greenpin from './imgs/green_pin.png';
import './map.css';
import * as mqtt from 'react-paho-mqtt';
import Paho from 'paho-mqtt';
import { getConnectionURL } from "../../../mqtt-helpers/mqtt-request";
import { authenticationService } from '../../../jwt/_services';
import {
  Card,
  CardBody,
  Row,
  Col,
  Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ButtonDropdown,
} from 'reactstrap';

var messagesArray = [];
var online_markers = [];
var devicesIcons = [];

//const {google} = this.props;
const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

const MapWithAMarker = compose(
  withStateHandlers(() => ({
    isOpen: false,
    showInfoIndex: '0'
  }), {
    onToggleOpen: ({ isOpen }) => () => ({
      isOpen: !isOpen,
      showInfoIndex: '0'
    }),
    showInfo: ({ showInfoIndex,isOpen }) => (a) => ({
      isOpen: !isOpen,
      showInfoIndex: a
    })
  }),
  withScriptjs,
  withGoogleMap
)(props =>
  <GoogleMap
    options={props.mapStyle}
    defaultZoom={3.8}
    defaultCenter={{ lat: 35, lng: -95.35 }}
  >
    <MarkerClusterer
      maxZoom={20}
      averageCenter
      enableRetinaIcons
      minimumClusterSize={2}
    >
    {
      props.devices.map((device, i) => (
          <Fragment>
          {props.markerDisplayStatus == 0 &&
            <Marker
              key={"marker_key_"+device.SerialNumber}
              id={"marker_id_"+device.SerialNumber}
              onClick={()=>{ props.showInfo(i)} }
              name={device.SerialNumber}
              serial_number={device.SerialNumber}
              firmware={device.firmware_version}
              lastseen={device.geo_timestamp}
              zIndex={props.devicesIcons[i].current_icon == greenpin ? 999999 : 0}
              icon={{
                url: props.devicesIcons[i].current_icon,
                scaledSize: new window.google.maps.Size(15,18)
              }}
              position={{lat: parseFloat(device.latitude), lng: parseFloat(device.longitude)}}>
              {(props.showInfoIndex == i ) && <InfoWindow onCloseClick={props.onToggleOpen}>
                  <div style={{ width: '280px', height: '150px' }}>
                    <h6>SN: {device.SerialNumber}</h6>
                    <h6>Firmware: {device.firmware_version}</h6>
                    <h6>Updated On: {Moment(device.firmware_date).format("LLL")}</h6>
                    <h6>Last seen on: {Moment(device.geo_timestamp).format("LLL")}</h6>
                    <h6>Owner: {props.devicesIcons[i].name}</h6>
                    <h6>Email: {props.devicesIcons[i].email}</h6>
                    {props.devicesIcons[i].current_icon == greenpin &&
                      <h6><a href={"/remotesupport/"+device.SerialNumber}>Remote Support</a> | <a href={"/customize/"+device.SerialNumber}>Customize</a></h6>
                    }
                  </div>
                  </InfoWindow>}
              </Marker>
          }
          {props.markerDisplayStatus == 1 && props.devicesIcons[i].current_icon == greenpin &&
            <Marker
              key={"marker_key_"+device.SerialNumber}
              id={"marker_id_"+device.SerialNumber}
              onClick={()=>{ props.showInfo(i)} }
              name={device.SerialNumber}
              serial_number={device.SerialNumber}
              firmware={device.firmware_version}
              lastseen={device.geo_timestamp}
              zIndex={props.devicesIcons[i].current_icon == greenpin ? 999999 : 0}
              icon={{
                url: props.devicesIcons[i].current_icon,
                scaledSize: new window.google.maps.Size(15,18)
              }}
              position={{lat: parseFloat(device.latitude), lng: parseFloat(device.longitude)}}>
              {(props.showInfoIndex == i ) && <InfoWindow onCloseClick={props.onToggleOpen}>
                  <div style={{ width: '280px', height: '150px' }}>
                    <h6>SN: {device.SerialNumber}</h6>
                    <h6>Firmware: {device.firmware_version}</h6>
                    <h6>Updated On: {Moment(device.firmware_date).format("LLL")}</h6>
                    <h6>Last seen on: {Moment(device.geo_timestamp).format("LLL")}</h6>
                    <h6>Owner: {props.devicesIcons[i].name}</h6>
                    <h6>Email: {props.devicesIcons[i].email}</h6>
                    {props.devicesIcons[i].current_icon == greenpin &&
                      <h6><a href={"/remotesupport/"+device.SerialNumber}>Remote Support</a> | <a href={"/customize/"+device.SerialNumber}>Customize</a></h6>
                    }
                  </div>
                  </InfoWindow>}
              </Marker>
          }
          {props.markerDisplayStatus == 2 && props.devicesIcons[i].current_icon != greenpin &&
            <Marker
              key={"marker_key_"+device.SerialNumber}
              id={"marker_id_"+device.SerialNumber}
              onClick={()=>{ props.showInfo(i)} }
              name={device.SerialNumber}
              serial_number={device.SerialNumber}
              firmware={device.firmware_version}
              lastseen={device.geo_timestamp}
              zIndex={props.devicesIcons[i].current_icon == greenpin ? 999999 : 0}
              icon={{
                url: props.devicesIcons[i].current_icon,
                scaledSize: new window.google.maps.Size(15,18)
              }}
              position={{lat: parseFloat(device.latitude), lng: parseFloat(device.longitude)}}>
              {(props.showInfoIndex == i ) && <InfoWindow onCloseClick={props.onToggleOpen}>
                  <div style={{ width: '280px', height: '150px' }}>
                    <h6>SN: {device.SerialNumber}</h6>
                    <h6>Firmware: {device.firmware_version}</h6>
                    <h6>Updated On: {Moment(device.firmware_date).format("LLL")}</h6>
                    <h6>Last seen on: {Moment(device.geo_timestamp).format("LLL")}</h6>
                    <h6>Owner: {props.devicesIcons[i].name}</h6>
                    <h6>Email: {props.devicesIcons[i].email}</h6>
                    {props.devicesIcons[i].current_icon == greenpin &&
                      <h6><a href={"/remotesupport/"+device.SerialNumber}>Remote Support</a> | <a href={"/customize/"+device.SerialNumber}>Customize</a></h6>
                    }
                  </div>
                  </InfoWindow>}
              </Marker>
          }
          </Fragment>
      ))
    }

    </MarkerClusterer>
  </GoogleMap>
);

class DeviceRow extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        online_markers: [],
      };
      //console.log('devices count: ' + this.state.online_markers.length);
    }

    async componentDidMount() {
  		setInterval(this.refreshDevices, 10000);
  	}

    refreshDevices = () => {
      this.setState({online_markers: this.props.online_markers});
      //console.log('refreshDevices devices count: ' + this.state.online_markers.length);
    }

    goToVitals(serial){
      window.location.href="/vitals/"+serial;
    }

    render() {
        //const {id, serial_number} = this.props;
        //console.log('devices count: ' + this.state.online_markers.length);
        return (
          <div className="mt-2">
          {this.state.online_markers.map((device, i) => (
            <Fragment>
            <Row >
              <Col md="12" className="text-center">
                <div style={{ minwidth: '290px', cursor:'pointer' }} onClick={() => this.goToVitals(device.serial_number)}>
                  <div className="float-left">
                    <img style={{ width: '23px', height: '23px' }} src={device.icon}/>
                  </div>
                  {device.city ? (
                    <Fragment>
                      <div className="float-left">
                        <span className="ml-2">Serial: {device.serial_number} - {device.city}{device.state ? ", "+device.state : ""}</span>
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="float-left">
                        <h4 className="ml-2">Serial: {device.serial_number}</h4>
                      </div>
                    </Fragment>
                  )}
                </div>

              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg="12">
                <hr className="new1" />
              </Col>
            </Row>
            </Fragment>
            ))
          }
          </div>
        );
    }
}

class MapContainer extends React.Component {

  intervalID = 0;
	intervalID2 = 1;

  constructor(props) {
    super(props);

    this.onMarkerMounted = element => {
      this.setState(prevState => ({
        markerObjects: [...prevState.markerObjects, element.marker]
      }))
    };

    const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

		this.onMessageArrived = this.onMessageArrived.bind(this);
    this.onConnectionLost = this.onConnectionLost.bind(this);
    // var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    // console.log('random hash: ' + hash);
		// const client = new Paho.Client(getConnectionURL(), hash+currentUser.OBJID);
    // client.onMessageArrived = this.onMessageArrived;
    // client.onConnectionLost = this.onConnectionLost;
    // client.connect({
		// 	timeout: 3,
		// 	useSSL: true,
		// 	mqttVersion: 4,
		// 	cleanSession: true,
		// 	onSuccess: this.onConnect,
		// 	onFailure: (e) => {console.log("here is the error" , e); }
    // });

    var mainMessage = [];
		var subMessage = [];

    this.state = {
      isOpen: false,
      isDropDownOpen: false,
      online_markers: [],
			client: null,
			currentUser,
      messageToSend:'',
      isConnected: false,
      devicesLoaded: false,
      isMarkerShown: false,
      showingInfoWindow: false,
      markerObjects: [],
			rSelected: 3,
			devices: [],
      users: [],
      devicesIcons: [],
      currentItem: "All Units",
      markerDisplayStatus: 0,
      style: {
        width: '100%',
        height: '100%',
      },
      mapStyle: {
        zoomControl: true,
        zoomControlOptions: {
          position: 7,
        },
				mapTypeControl: false,
				fullscreenControl: false,
        streetViewControl: false,
				styles: [{
          elementType: "geometry",
          stylers: [ {
            color: "#1D2C4D"
          } ]
        },{
          elementType: "labels.text.fill",
          stylers: [ {
            color: "#8EC3B9"
          } ]
        }, {
          elementType: "labels.text.stroke",
          stylers: [ {
            color: "#1A3646"
          } ]
        }, {
          featureType: "administrative.country",
          elementType: "geometry.stroke",
          stylers: [ {
            color: "#4B6878"
          } ]
        }, {
          featureType: "administrative.land_parcel",
          stylers: [ {
            visibility: "off"
          } ]
        }, {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [ {
            color: "#64779E"
          } ]
        }, {
          featureType: "administrative.neighborhood",
          stylers: [ {
            visibility: "off"
          } ]
        }, {
          featureType: "administrative.province",
          elementType: "geometry.stroke",
          stylers: [ {
            color: "#4B6878"
          } ]
        }, {
          featureType: "landscape.man_made",
          elementType: "geometry.stroke",
          stylers: [ {
            color: "#334E87"
          } ]
        }, {
          featureType: "landscape.natural",
          elementType: "geometry",
          stylers: [ {
            color: "#023E58"
          } ]
        }, {
          featureType: "landscape.natural",
          elementType: "labels.text",
          stylers: [ {
            color: "#FFEB3B"
          }, {
            visibility: "off"
          } ]
        }, {
          featureType: "poi",
          elementType: "geometry",
          stylers: [ {
            color: "#283D6A"
          } ]
        }, {
          featureType: "poi",
          elementType: "labels.text",
          stylers: [ {
            visibility: "off"
          } ]
        }, {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [ {
            color: "#6F9BA5"
          } ]
        }, {
          featureType: "poi",
          elementType: "labels.text.stroke",
          stylers: [ {
            color: "#1D2C4D"
          } ]
        }, {
          featureType: "poi.business",
          stylers: [ {
            visibility: "off"
          } ]
        }, {
          featureType: "poi.park",
          elementType: "geometry.fill",
          stylers: [ {
            color: "#023E58"
          } ]
        }, {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [ {
            color: "#3C7680"
          } ]
        }, {
          featureType: "road",
          elementType: "geometry",
          stylers: [ {
            color: "#304A7D"
          } ]
        }, {
          featureType: "road",
          elementType: "labels",
          stylers: [ {
            visibility: "off"
          } ]
        }, {
          featureType: "road",
          elementType: "labels.icon",
          stylers: [ {
            visibility: "off"
          } ]
        }, {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [ {
            color: "#98A5BE"
          } ]
        }, {
          featureType: "road",
          elementType: "labels.text.stroke",
          stylers: [ {
            color: "#1D2C4D"
          } ]
        }, {
          featureType: "road.arterial",
          stylers: [ {
            visibility: "off"
          } ]
        }, {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [ {
            color: "#2C6675"
          } ]
        }, {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [ {
            color: "#255763"
          } ]
        }, {
          featureType: "road.highway",
          elementType: "labels",
          stylers: [ {
            visibility: "off"
          } ]
        }, {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [ {
            color: "#B0D5CE"
          } ]
        }, {
          featureType: "road.highway",
          elementType: "labels.text.stroke",
          stylers: [ {
            color: "#023E58"
          } ]
        }, {
          featureType: "road.local",
          stylers: [ {
            visibility: "off"
          } ]
        }, {
          featureType: "transit",
          stylers: [ {
            visibility: "off"
          } ]
        }, {
          featureType: "transit",
          elementType: "labels.text.fill",
          stylers: [ {
            color: "#98A5BE"
          } ]
        }, {
          featureType: "transit",
          elementType: "labels.text.stroke",
          stylers: [ {
            color: "#1D2C4D"
          } ]
        }, {
          featureType: "transit.line",
          elementType: "geometry.fill",
          stylers: [ {
            color: "#283D6A"
          } ]
        }, {
          featureType: "transit.station",
          elementType: "geometry",
          stylers: [ {
            color: "#3A4762"
          } ]
        }, {
          featureType: "water",
          elementType: "geometry",
          stylers: [ {
            color: "#0E1626"
          } ]
        }, {
          featureType: "water",
          elementType: "labels.text",
          stylers: [ {
            visibility: "off"
          } ]
        }, {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [ {
            color: "#4E6D70"
          } ]
        }]
      }
	  };

    var isOpen = false;
    var activeMarker = {};
    var selectedPlace = {};

    this.toggleDropDown = this.toggleDropDown.bind(this);
  }

  _mapLoaded(mapProps, map) {
     map.setOptions({
        styles: this.state.mapStyle
     })
  }

  async componentDidMount() {
    this.connectToMQTT();
    await this.getUsers();
    this.getData();

    this.intervalID2 = setInterval(this.handleMessage, 5000);
		this.intervalID = setInterval(this.getMessage, 8000);
	}

  componentWillUnmount() {
		clearInterval(this.intervalID);
    clearInterval(this.intervalID2);

		if (this.state.client && this.state.client.isConnected()) {
			this.state.client.disconnect();
		}

	}

  getUsers = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-users";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});
		const body = await response.json();
		console.log("all users: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ users: body.response.Users });

		} else {
      //ADD ERROR MESSAGE
    }

	};

  getData = async () => {
		//const response = await fetch('/api/hello');
    var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"all-devices";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});
		const body = await response.json();
		console.log("MapContainer devices: " + JSON.stringify(body.results));

		if (body.status == 'Success') {
			this.setState({ devices: body.results });
		}

    let devicesResults = body.results;

    let devicesIconsTemp = [];

    for ( var i = 0; i < devicesResults.length; i++ ) {

      var deviceIconObject = {
        id: i,
				serial_number: devicesResults[i].SerialNumber,
        current_icon: redpin,
        name: "N/A",
        email: "N/A"
			};

      if (devicesResults[i].LoginID) {
        let user = this.state.users.find(u => u.Username.toString().toLowerCase() === devicesResults[i].LoginID.toString().toLowerCase());

        //console.log('Identity - serial: '+devicesResults[i].SerialNumber+' | user:'+user.Username);

        if (user) {
          let firstName = user.Attributes.filter(attribute => attribute.Name == "given_name").map(name => name.Value);
  				let lastName = user.Attributes.filter(attribute => attribute.Name == "family_name").map(name => name.Value);

          deviceIconObject = {
            id: i,
    				serial_number: devicesResults[i].SerialNumber,
            current_icon: redpin,
            name: firstName+" "+lastName,
            email: devicesResults[i].LoginID.toString().toLowerCase()
    			};
        } else {
          deviceIconObject = {
            id: i,
    				serial_number: devicesResults[i].SerialNumber,
            current_icon: redpin,
            name: "N/A",
            email: "N/A"
    			};
        }

      }

      devicesIconsTemp.push(deviceIconObject);
		}

    this.setState({ devicesIcons: devicesIconsTemp });
    //console.log("devicesIcons devices: " + JSON.stringify(devicesIconsTemp));

    this.setState({ devicesLoaded: true });
	};

  toggleDropDown() {
    this.setState({
      isDropDownOpen: !this.state.isDropDownOpen
    });
  }

  selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			markerDisplayStatus: id
    });

  }

  connectToMQTT = async () => {
		authenticationService.getGlobalConnectionURL()
				.then(
						connectionUrl => {
							console.log('connectToMQTT connectionUrl: ' + JSON.stringify(connectionUrl));

							//var url1 = "wss://a1f3ynjyzalf3r-ats.iot.us-west-2.amazonaws.com/mqtt?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAS3T2QUYOSMZKW4V2%2F20210201%2Fus-west-2%2Fiotdevicegateway%2Faws4_request&X-Amz-Date=20210201T225801Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=dff6d2d6b29772129d10a43adf2b218d07b3cd3423ac13e32c71c6d7f8032eaf";

							if (connectionUrl.status == 'Success') {
								var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
								console.log('random hash: ' + hash);
								this.state.client = new Paho.Client(connectionUrl.response, hash+this.state.currentUser.payload.sub);
								this.state.client.onMessageArrived = this.onMessageArrived;
								this.state.client.onConnectionLost = this.onConnectionLost;
								this.state.client.connect({
									timeout: 3,
									useSSL: true,
									mqttVersion: 4,
									cleanSession: true,
									onSuccess: this.onConnect,
									onFailure: (e) => {console.log("here is the error" , e); }
								});
							}
						},
						error => {
							console.log('error.message: ' + error.message);
						}
				);
	}

  onMessageArrived(entry) {
    //console.log("dashboard onMessageArrived:"+entry.payloadString);
		const msg = JSON.parse(entry.payloadString);
		if (msg.batch) {
			messagesArray.push(msg);
		} else {
			//this.setState({subMessage: JSON.parse(entry.payloadString)});
			//console.log("dashboard_sub_messages:"+this.state.subMessage);
		}

  }

  handleMessage = async () => {

		let messages = messagesArray;
		//console.log("dashboard messagesArray 2:"+messages);

		//this.setState({ handlingMessage: true });

		//console.log("Handling Message");

		var resetArray = false;

		for ( var i = 0; i < messages.length; i++ ) {

			let msg = messages[i];

			var found_online_marker = false;

			var anIndex = 0;
			var onlineDevice = online_markers.find(function(device, index) {

				if(device.serial_number == msg.serial_num) {
					online_markers[ index ].last_update = new Date();
					//console.log('handleMessage found serial: ' + JSON.stringify(msg.serial_num));
					found_online_marker = true;
					return true;
				}
			});

			if ( found_online_marker == false ) {
				//console.log("add green");

				var city = "Unknown";
				var state = "Unknown";
				var country = "Unknown";

				let device = this.state.devices.find(d => d.SerialNumber === msg.serial_num);

				//console.log("handleMessage device not found serial: "+device.SerialNumber);
				//console.log("handleMessage device not found city: "+device.geo_city);

				if (device) {
					city = device.geo_city;
					state = device.geo_state;
					country = device.geo_country;
				}

				let life810 = msg.life810;
				let life980 = msg.life980;

				var d = Number(life810 + life980);
				var h = Math.floor(d / 3600);
				var m = Math.floor(d % 3600 / 60);
				var s = Math.floor(d % 3600 % 60);

				var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
				var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
				var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";

				online_markers.push( {
					serial_number: msg.serial_num,
	        icon: greenpin,
					last_update: new Date(),
					city: city,
					state: state,
					country: country,
					version: msg.fw_ver,
					usage: hDisplay
				});

				//console.log('handleMessage online markers count: ' + JSON.stringify(online_markers.length));
				//console.log('handleMessage online markers: ' + JSON.stringify(online_markers));
			}

      let device = this.state.devicesIcons.find(d => d.serial_number === msg.serial_num);
      //console.log('handleMessage device: ' + JSON.stringify(device));

      if (device) {
        let newDevicesIcons = JSON.parse(JSON.stringify(this.state.devicesIcons));

        newDevicesIcons[device.id].current_icon = greenpin;

        this.setState({ devicesIcons: newDevicesIcons });
      }

      //console.log('handleMessage all devicesIcons: ' + JSON.stringify(this.state.devicesIcons));

			if (i == (messages.length-1)) {
				resetArray = true;
			}

		}

		if (resetArray) {
			messagesArray = [];
		}


		//this.setState({ handlingMessage: false });

	}


  getMessage = () => {
    //console.log('mapcontainer lat: ' + this.mainMessage.geo_lat);
    //console.log('mapcontainer lon: ' + this.mainMessage.geo_lng);
    //console.log('online markers count: ' + JSON.stringify(online_markers.length));

		for ( var a = 0; a < online_markers.length; a++ ) {

      let device = this.state.devicesIcons.find(d => d.serial_number === online_markers[ a ].serial_number);
      //console.log('handleMessage device: ' + JSON.stringify(device));

			if ( new Date() - online_markers[ a ].last_update > 10000 ) {
				online_markers[a].icon = redpin;

        if (device) {
          let newDevicesIcons = JSON.parse(JSON.stringify(this.state.devicesIcons));

          newDevicesIcons[device.id].current_icon = redpin;

          this.setState({ devicesIcons: newDevicesIcons });
        }

        //console.log('handleMessage all devicesIcons: ' + JSON.stringify(this.state.devicesIcons));
			} else {
				online_markers[a].icon = greenpin;

        if (device) {
          let newDevicesIcons = JSON.parse(JSON.stringify(this.state.devicesIcons));

          newDevicesIcons[device.id].current_icon = greenpin;

          this.setState({ devicesIcons: newDevicesIcons });
        }

        //console.log('handleMessage all devicesIcons: ' + JSON.stringify(this.state.devicesIcons));
			}

		}

  }

  // onMessageArrived(entry) {
  //   console.log("Map onMessageArrived:"+entry.payloadString);
	// 	const msg = JSON.parse(entry.payloadString);
	// 	if (msg.batch) {
	// 		this.mainMessage = JSON.parse(entry.payloadString);
	// 		console.log("heartbeat_main_messages geo_lat:"+this.mainMessage.geo_lat);
	// 	} else {
	// 		this.subMessage = JSON.parse(entry.payloadString);
	// 		console.log("heartbeat_sub_messages serial_num:"+this.subMessage.serial_num);
	// 	}
  //
	// 	var found_online_marker = false;
	// 	for ( var i = 0; i < this.state.online_markers.length; i++ ) {
	// 		if ( this.state.online_markers[ i ].serial_number == msg.serial_num ) {
	// 			this.state.online_markers[ i ].last_update = new Date();
	// 			found_online_marker = true;
	// 		}
	// 	}
	// 	if ( found_online_marker == false ) {
  //
  //     var city = '';
  //     var state = '';
  //
  //     for ( var i = 0; i < this.state.devices.length; i++ ) {
  //       if (msg.serial_num == this.state.devices[i].SerialNumber) {
  //         if (this.state.devices[i].geo_city) {
  //           city = this.state.devices[i].geo_city;
  //         }
  //         if (this.state.devices[i].geo_state) {
  //           state = this.state.devices[i].geo_state;
  //         }
  //       }
  //     }
  //
	// 		this.state.online_markers.push( {
	// 			serial_number: msg.serial_num,
  //       icon: greenpin,
	// 			last_update: new Date(),
  //       city: city,
  //       state: state
	// 		} );
	// 	}
  //
  //   for ( var i = 0; i < this.state.online_markers.length; i++ ) {
  //     for ( var j = 0; j < this.state.devicesIcons.length; j++ ) {
  //       if ( this.state.online_markers[ i ].serial_number == this.state.devicesIcons[j].serial_number ) {
  //         this.setState(update(this.state, {
  //         	devicesIcons: {
  //         		[j]: {
  //               current_icon: {
  //                 $set: greenpin
  //               }
  //         		}
  //         	}
  //         }));
  //         //this.state.devicesIcons[j].current_icon = 'https://img.icons8.com/emoji/48/000000/green-heart.png';
  //         //console.log("Serial:"+this.state.devicesIcons[j].serial_number+" current_icon:"+this.state.devicesIcons[j].current_icon);
  // 			}
  //     }
	// 	}
  // }

  onConnect = () => {
    const { client } = this.state;
    console.log("Connected!!!!");
    //client.subscribe('00013/out');
    this.setState({isConnected: true, error: ''})
		//this.sendMessage("@@@get_all");
		//console.log('myCurrentDevice id: ' + this.state.currentDevice);
		//this.setState({currentDevice: this.state.currentDevice});
		const subChannel = "#";
		this.state.client.subscribe(subChannel);
  };


  sendMessage(){
    var message = new Paho.Message(this.state.messageToSend);
    message.destinationName = "00013";

    if(this.state.isConnected){
      this.state.client.send(message);
    }else{
      this.connect(this.state.client)
        .then(() => {
          this.state.client.send(message);
          this.setState({error: '', isConnected: true});
        })
        .catch((error)=> {
          console.log(error);
          this.setState({error: error});
        });
  	}
  }


  onConnectionLost(responseObject) {
    if (responseObject.errorCode !== 0) {
      console.log("onConnectionLost:"+responseObject.errorMessage);
      this.setState({error: 'Lost Connection', isConnected: false});
    }
  }


  onMouseoverMarker(props, marker, e) {
    console.log('marker mouseover');
  }

  onToggleOpen = () => {
    console.log('marker clicked');
    if (this.isOpen) {
      this.isOpen = false;
      console.log('info closed');
    } else {
      this.isOpen = true;
      console.log('info open');
    }
  }

  onClose = props => {
    if (this.isOpen) {
      this.isOpen = true;
      this.activeMarker = null;
    }
  };

  onMarkerClustererClick = props => {
    if (this.isOpen) {
      this.isOpen = false;
      this.activeMarker = null;
    }
  };

  render() {

    //console.log('devicesIcons serial: ' + this.state.devicesIcons[0].serial_number);

    return <div>
      <Row className="mb-3">
        <Col xs="6">
          <h1>Heartbeat</h1>
        </Col>
        <Col xs="6" className="text-right">
          <ButtonDropdown isOpen={this.state.isDropDownOpen} toggle={this.toggleDropDown}>
            <DropdownToggle color="dark" caret>
              {this.state.currentItem}
            </DropdownToggle>
            <DropdownMenu right>

              <DropdownItem onClick={() => this.selectDropDownItem(0, 'All Units')}>All Units</DropdownItem>
              <DropdownItem onClick={() => this.selectDropDownItem(1, 'Online Only')}>Online Only</DropdownItem>
              <DropdownItem onClick={() => this.selectDropDownItem(2, 'Offline Only')}>Offline Only</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </Col>
      </Row>
      <Row>
        <Col lg="6" xl="8" xxl="9">
          <Card>
            <CardBody style={{ width: '100%', height: '900px' }}>
              <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                {this.state.devicesLoaded && this.state.devicesIcons.length>0 &&
                  <MapWithAMarker
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAzQxot97t6EU-lgq8nPlT94TlTfqe-Xe0&v=3.exp&libraries=geometry,drawing,places"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `100%` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  devices={this.state.devices}
                  markerDisplayStatus={this.state.markerDisplayStatus}
                  devicesIcons={this.state.devicesIcons}
                  mapStyle={this.state.mapStyle}
                  />
                }
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="6" xl="4" xxl="3">
          <Card>
            <CardBody style={{ width: '100%', height: '900px' }}>
              <DeviceRow online_markers={online_markers} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>;
  }
}

export default MapContainer;
