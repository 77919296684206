import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import mobileRoutes from '../routes/mobileroutes.js';

import { connect } from 'react-redux';
import { Fragment } from "react";
import Header from './layout-components/header/header';
import Sidebar from './layout-components/sidebar/sidebar';
import Footer from './layout-components/footer/footer';
import Customizer from './layout-components/customizer/customizer';


class Mobilelayout extends React.Component {
  render() {
    return (
      <div id="main-wrapper" dir="ltr" data-theme="dark" data-layout="vertical" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <Switch>
          {mobileRoutes.map((prop, key) => {
            if (prop.redirect)
              return (
                <Redirect from={prop.path} to={prop.pathTo} key={key} />
              );
            return (
              <Route path={prop.path} component={prop.component} key={key} />
            );
          })}
        </Switch>
      </div>

    )
  }
}
export default Mobilelayout;
