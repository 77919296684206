import React from 'react';
import { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
	Nav,
	NavItem,
	NavLink,
	Button,
	Navbar,
	NavbarBrand,
	Collapse,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledCarousel,
	Progress,
	ListGroup,
	ListGroupItem,
	Row,
	Col,
	Form,
	FormGroup,
	Input,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Dropdown,
	ButtonDropdown
} from 'reactstrap';
import * as data from './data';
import { authenticationService } from '../../../jwt/_services';

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import logodarkicon from '../../../assets/images/logo-icon.png';
import logolighticon from '../../../assets/images/logo-light-icon.png';
import logodarktext from '../../../assets/images/logo-text.png';
import logolighttext from '../../../assets/images/logo-light-text.png';
import profilephoto from '../../../assets/images/users/1.jpg';
import azenalogo from '../../../assets/images/logo_only@3x.png';
import azenaglogo from '../../../assets/images/logo2@2x.png';
import azenalogotext from '../../../assets/images/text_only@3x.png';
import azenaevologo from '../../../assets/images/logo2_x3.png';
import azenaevologotext from '../../../assets/images/logo2_x3_text.png';
import headerMenuLogo from '../../../assets/images/header_menu_logo.png';

import evoProfileIcon from '../../../assets/images/evo_profile_icon.png';

const mapStateToProps = state => ({
	...state
});

class Header extends React.Component {

	intervalID = 0;

	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.showMobilemenu = this.showMobilemenu.bind(this);
		this.sidebarHandler = this.sidebarHandler.bind(this);
		this.toggleResetModal = this.toggleResetModal.bind(this);
		this.toggleDropDown = this.toggleDropDown.bind(this);

		this.state = {
			isOpen: false,
			currentUserEmail: JSON.parse(localStorage.getItem('currentUserEmail')),
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			devices: JSON.parse(localStorage.getItem('deviceList')),
			firstName: JSON.parse(localStorage.getItem('firstName')),
			lastName: JSON.parse(localStorage.getItem('lastName')),
			modal: false,
			isOpen2: false,
			currentItem: "All Markets",
		};
	}

	componentDidMount() {

		if (this.state.currentUser.payload["custom:role"] && this.state.currentUser.payload["custom:role"] == 2) {
			this.setMarketDropDown();
		}

		this.intervalID = setInterval(this.refreshUserData.bind(this), 5000);

	}

	setMarketDropDown() {
		let marketType = localStorage.getItem('marketType') ? JSON.parse(localStorage.getItem('marketType')) : 0;
		console.log("header marketType: " + marketType);
		switch (parseInt(marketType)) {
			case 0: {
				this.setState({
		      currentItem: 'All Markets',
					currentDataSetting: 0
		    });
				break;
			}
			case 1: {
				this.setState({
		      currentItem: 'US Only',
					currentDataSetting: 1
		    });
				break;
			}
			case 2: {
				this.setState({
		      currentItem: 'Demo Only',
					currentDataSetting: 2
		    });
				break;
			}
			case 3: {
				this.setState({
		      currentItem: 'International Only',
					currentDataSetting: 3
		    });
				break;
			}
			case 4: {
				this.setState({
		      currentItem: 'US+International+Brazil',
					currentDataSetting: 4
		    });
				break;
			}
			case 5: {
				this.setState({
		      currentItem: 'International Only',
					currentDataSetting: 5
		    });
				break;
			}
			default: {
				break;
			}
		}
	}

	componentWillUnmount() {
		clearInterval(this.intervalID);
	}

	refreshUserData() {

		var currentUserEmail = JSON.parse(localStorage.getItem('currentUserEmail'));
		var currentUser = JSON.parse(localStorage.getItem('currentUser'));
		var devices = JSON.parse(localStorage.getItem('deviceList'));
		var firstName = JSON.parse(localStorage.getItem('firstName'));
		var lastName = JSON.parse(localStorage.getItem('lastName'));

		this.setState({ currentUserEmail, currentUser, devices, firstName, lastName })
	}

	toggleDropDown() {
    this.setState({
      isOpen2: !this.state.isOpen2
    });
  }

	selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id
    });

		localStorage.setItem('marketType', id);
		document.body.dispatchEvent(new CustomEvent("set-market-type", {detail: { id }}));

  }

	/*--------------------------------------------------------------------------------*/
	/*To open NAVBAR in MOBILE VIEW                                                   */
	/*--------------------------------------------------------------------------------*/
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}
	/*--------------------------------------------------------------------------------*/
	/*To open SIDEBAR-MENU in MOBILE VIEW                                             */
	/*--------------------------------------------------------------------------------*/
	showMobilemenu() {
		document.getElementById('main-wrapper').classList.toggle('show-sidebar');
	}
	logout() {
		authenticationService.logout();
	}
	toggleResetModal() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	resetPasswordCall = () => {
		const { currentUser } = this.state;
		authenticationService.forgotPassword(this.state.currentUserEmail)
		.then(
				response => {

						let status = response.status;
						console.log('status: ' + status);

						if (status === 'Success') {
								console.log('reset password response info: ' + response);
								this.toggleResetModal();
						} else if (status === 'Error') {
								console.log('error info: ' + response.response);
						} else {
							console.log('status info: ' + status);
						}
				},
				error => {
						console.log('status info: ' + error);
				}
		);

	}
	sidebarHandler = () => {
		let element = document.getElementById('main-wrapper');
		switch (this.props.settings.activeSidebarType) {
			case 'full':
			case 'iconbar':
				element.classList.toggle('mini-sidebar');
				if (element.classList.contains('mini-sidebar')) {
					element.setAttribute('data-sidebartype', 'mini-sidebar');
				} else {
					element.setAttribute(
						'data-sidebartype',
						this.props.settings.activeSidebarType
					);
				}
				break;

			case 'overlay':
			case 'mini-sidebar':
				element.classList.toggle('full');
				if (element.classList.contains('full')) {
					element.setAttribute('data-sidebartype', 'full');
				} else {
					element.setAttribute(
						'data-sidebartype',
						this.props.settings.activeSidebarType
					);
				}
				break;
			default:
		}
	};

	render() {
		var customizePath = "/customize/00000"

		if (this.state.devices) {
			var customizePath = "/customize/"+this.state.devices[0].SerialNumber;
		}
		var accountpath = "/account"
		return (
			<header className="topbar navbarbg" data-navbarbg={this.props.settings.activeNavbarBg}>
				<Navbar className={"top-navbar " + (this.props.settings.activeNavbarBg === "skin6" ? 'navbar-light' : 'navbar-dark')} expand="md">
					<div className="navbar-header" id="logobg" data-logobg={this.props.settings.activeLogoBg}>
						{/*--------------------------------------------------------------------------------*/}
						{/* Mobile View Toggler  [visible only after 768px screen]                         */}
						{/*--------------------------------------------------------------------------------*/}
						<span className="nav-toggler d-block d-md-none" onClick={this.showMobilemenu}>
							<i className="ti-menu ti-close" />
						</span>
						{/*--------------------------------------------------------------------------------*/}
						{/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
						{/*--------------------------------------------------------------------------------*/}
						<NavbarBrand href="/">
							<b className="logo-icon">
								<img src={logodarkicon} alt="homepage" className="dark-logo" />
								<img
									src={azenaevologotext}
									alt="homepage"
									height="0"
									className="light-logo"
								/>
							</b>
							<span className="logo-text">
								<img src={logodarktext} alt="homepage" className="dark-logo" />
								<img
									src={azenaevologotext}
									height="17"
									width="150"
									className="light-logo ml-5"
									alt="homepage"
								/>
							</span>
						</NavbarBrand>
						{/*--------------------------------------------------------------------------------*/}
						{/* Mobile View Toggler  [visible only after 768px screen]                         */}
						{/*--------------------------------------------------------------------------------*/}
						<span className="topbartoggler d-block d-md-none" onClick={this.toggle}>
							<i className="ti-more" />
						</span>
					</div>
					<Collapse className="navbarbg" isOpen={this.state.isOpen} navbar data-navbarbg={this.props.settings.activeNavbarBg} >
						<Nav className="float-left" navbar>
							<NavItem>
								<NavLink href="#" className="d-none d-md-block" onClick={this.sidebarHandler}>
									<i className="ti-menu" />
								</NavLink>
							</NavItem>
						</Nav>
						<Nav className="ml-auto mr-5" navbar>
						{this.state.currentUser.payload["custom:role"] && this.state.currentUser.payload["custom:role"] == 2 &&
							<ButtonDropdown className="float-right" isOpen={this.state.isOpen2} toggle={this.toggleDropDown}>
								<DropdownToggle style={{ fontSize: "18px" }} color="yellow" caret>
									<b>{this.state.currentItem}</b>
								</DropdownToggle>
								<DropdownMenu right>

									<DropdownItem onClick={() => this.selectDropDownItem(0, 'All Markets')}>All Markets</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(1, 'US Only')}>US Only</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(2, 'Demo Only')}>Demo Only</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(3, 'International Only')}>International Only</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(4, 'US+International+Brazil')}>US+International+Brazil</DropdownItem>
									<DropdownItem onClick={() => this.selectDropDownItem(5, 'Brazil Only')}>Brazil Only</DropdownItem>
								</DropdownMenu>
							</ButtonDropdown>
						}
						</Nav>
						<Nav className="ml-auto float-right" navbar>
							{/*--------------------------------------------------------------------------------*/}
							{/* Start Notifications Dropdown                                                   */}
							{/*--------------------------------------------------------------------------------*/}

							{

								// <UncontrolledDropdown nav inNavbar>
								// 	<DropdownToggle nav caret>
								// 		<i className="mdi mdi-bell font-24" />
								// 	</DropdownToggle>
								// 	<DropdownMenu right className="mailbox">
								// 		<span className="with-arrow">
								// 			<span className="bg-primary" />
								// 		</span>
								// 		<div className="d-flex no-block align-items-center p-3 bg-primary text-white mb-2">
								// 			<div className="">
								// 				<h4 className="mb-0">4 New</h4>
								// 				<p className="mb-0">Notifications</p>
								// 			</div>
								// 		</div>
								// 		<div className="message-center notifications">
								// 			{/*<!-- Message -->*/}
								// 			{data.notifications.map((notification, index) => {
								// 				return (
								// 					<span href="" className="message-item" key={index}>
								// 						<span className={"btn btn-circle btn-" + notification.iconbg}>
								// 							<i className={notification.iconclass} />
								// 						</span>
								// 						<div className="mail-contnet">
								// 							<h5 className="message-title">{notification.title}</h5>
								// 							<span className="mail-desc">
								// 								{notification.desc}
								// 							</span>
								// 							<span className="time">{notification.time}</span>
								// 						</div>
								// 					</span>
								// 				);
								// 			})}
								// 		</div>
								// 		<a className="nav-link text-center mb-1 text-dark" href=";">
								// 			<strong>Check all notifications</strong>{' '}
								// 			<i className="fa fa-angle-right" />
								// 		</a>
								// 	</DropdownMenu>
								// </UncontrolledDropdown>
							}

							{/*--------------------------------------------------------------------------------*/}
							{/* End Notifications Dropdown                                                     */}
							{/*--------------------------------------------------------------------------------*/}

							{/*--------------------------------------------------------------------------------*/}
							{/* Start Profile Dropdown                                                         */}
							{/*--------------------------------------------------------------------------------*/}
							<UncontrolledDropdown nav inNavbar>
								<DropdownToggle nav caret className="pro-pic">
									<div className="d-flex no-block align-items-center text-white mb-2" style={{width: '220px'}}>
										<div className="mr-2" style={{height: '65px', width: '75%'}}>
											{this.state.currentUser.payload["custom:role"] ? (
												<Fragment>
												{this.state.currentUser.payload["custom:role"] == 0 &&
													<p className="mb-0" style={{height: '20px', lineHeight: '50px'}}>Gemini User:</p>
												}
												{this.state.currentUser.payload["custom:role"] == 1 &&
													<p className="mb-0" style={{height: '20px', lineHeight: '50px'}}>Support User:</p>
												}
												{this.state.currentUser.payload["custom:role"] == 2 &&
													<p className="mb-0" style={{height: '20px', lineHeight: '50px'}}>Admin User:</p>
												}
												</Fragment>
											) : (
												<p className="mb-0" style={{height: '20px', lineHeight: '50px'}}>Gemini User:</p>
											)}
											<p className="mb-0" style={{height: '25px', lineHeight: '50px'}}>{this.state.firstName} {this.state.lastName}</p>
										</div>
										<div className="" style={{height: '65px', width: '25%'}}>
											<img
												src={evoProfileIcon}
												alt="user"
												className=""
												width="43"
											/>
										</div>
									</div>
								</DropdownToggle>
								<DropdownMenu right className="user-dd">
									<span className="with-arrow">
										<span className="bg-primary" />
									</span>
									<div style={{ color: "#000"}} className="d-flex no-block align-items-center p-3 bg-primary mb-2">
										<div className="">
											<img
												src={headerMenuLogo}
												alt="user"
												className="rounded"
												width="60"
											/>
										</div>
										<div className="ml-2">
											<h4 className="mb-0">{this.state.firstName} {this.state.lastName}</h4>
											<p className=" mb-0">{this.state.currentUserEmail}</p>
										</div>
									</div>
									<DropdownItem tag={NavLink} href={accountpath}>
										<i className="ti-settings mr-1 ml-1" /> Account Settings
                  </DropdownItem>
									<DropdownItem divider />
									<DropdownItem style={{cursor:"pointer"}} tag={NavLink} href={accountpath}>
										<i className="mdi mdi-lock mr-1 ml-1" /> Change Password
                  </DropdownItem>
									<DropdownItem style={{cursor:"pointer"}} tag={NavLink} onClick={this.logout}>
										<i className="fa fa-power-off mr-1 ml-1" /> Logout
                  </DropdownItem>
									{/*// <Button
									// 	color="success"
									// 	className="btn-rounded ml-3 mb-2 mt-2"
									// >
									// 	View Profile
                  // </Button>*/}
								</DropdownMenu>
							</UncontrolledDropdown>
							{/*--------------------------------------------------------------------------------*/}
							{/* End Profile Dropdown                                                           */}
							{/*--------------------------------------------------------------------------------*/}
						</Nav>
					</Collapse>
				</Navbar>
				<div>
				<Modal color="dark" isOpen={this.state.modal} toggle={this.toggleResetModal} className={this.props.className}>
	        <ModalHeader toggle={this.toggleResetModal}>Reset Password</ModalHeader>
	        <ModalBody>
						An email has been sent to {this.state.currentUserEmail}. Please follow the link provided to complete your password reset.
	        </ModalBody>
	        <ModalFooter>
	          <Button color="secondary" onClick={this.toggleResetModal}>OK</Button>
	        </ModalFooter>
	      </Modal>
				</div>
			</header>
		);
	}
}
export default connect(mapStateToProps)(Header);
