import React from "react";
import {
	Card,
	CardBody,
	CardTitle,
	Row,
	Col,

} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { authenticationService } from '../../jwt/_services';
import ReactGA from "react-ga4";

import {
	ChangePassword,
  UserInfo,
	UpgradeUser,
  DeleteAccount
} from '../../components/account/';

class DeleteAccountMobile extends React.Component {

	constructor(props) {
    super(props);

		//const currentUser = authenticationService.currentUserValue;

		const query = new URLSearchParams(this.props.location.search);
    const token = query.get('token')
    console.log("token: "+token)//123

		const currentUser = { jwtToken: token };

    if (token) {
      if (token.length>0) {
        console.log("authToken: "+token)
        this.setState({currentUser: { jwtToken: token }});
				const currentUser = { jwtToken: token };
      }
    }

		this.state = {
			currentUser
		}

  }

	componentDidMount() {
		ReactGA.pageview(window.location.pathname);
		//console.log("ReactGA.pageview: " + window.location.pathname);
	}

	render() {
		return (
			<div>
				<Row style={{ height: "1000px" }}>
					<Col lg="12" xl="12">
            <DeleteAccount currentUser={this.state.currentUser} height={1000}/>
					</Col>
				</Row>
			</div>
		);
	}
}

export default DeleteAccountMobile;
