import React from "react";
import {
	Progress,
	Card,
  CardTitle,
	CardBody,
	Row,
	Col,
	Tooltip
} from 'reactstrap';

import Chart from 'react-c3-component';
import 'c3/c3.css';

import img1 from '../../../assets/images/background/active-bg.png';
import img2 from '../../../assets/images/dashboard/wavelength_color_guide.png';

class WavelengthUsage extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);
    this.state = {
			totalUsage: '',
			totalProcedures: 0,
			wavelengthusage: {},
			noDevices: false,
			noDataForDevices: false,
			tooltipOpen: false,
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			marketType: localStorage.getItem('marketType') ? JSON.parse(localStorage.getItem('marketType')) : 0
	  };

		this.toggleTooltip = this.toggleTooltip.bind(this);
  }

	async componentDidMount() {

		document.body.addEventListener('set-market-type', this.handleMarketTypeChange.bind(this));

		if (this.state.currentUser) {
			this.getData();
			this.getTotalProcedures();
		} else {
			setTimeout(()=>{ this.getData(); }, 1000);
		}

		if (this.state.marketType==0) {
			this.intervalID = setInterval(()=>{ this.getData(); }, 600000);
		} else {
			this.intervalID = setInterval(()=>{ this.getData(); }, 600000);
		}

	}

	handleMarketTypeChange(event) {
    console.log("Wavelength Usage Market has changed to: ", event.detail);
    // your business logic here .. this.setState({...});
		this.setState({ marketType: event.detail.id });
		setTimeout(this.getData, 200);
		setTimeout(this.getTotalProcedures, 250);

		if (event.detail.id==0) {
			clearInterval(this.intervalID);
			this.intervalID = setInterval(()=>{ this.getData(); }, 600000);
			this.intervalID = setInterval(()=>{ this.getTotalProcedures(); }, 500000);
		} else {
			clearInterval(this.intervalID);
			this.intervalID = setInterval(()=>{ this.getData(); }, 600000);
			this.intervalID = setInterval(()=>{ this.getTotalProcedures(); }, 500000);
		}
  }

	toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

	componentWillUnmount() {
		//console.log("WavelengthUsage componentWillUnmount");
		clearInterval(this.intervalID);
		document.body.removeEventListener('set-market-type', this.handleMarketTypeChange.bind(this));
	}

	getTotalProcedures = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-total-procedures?MarketType="+this.state.marketType;

		var token = "";

		if (this.state.currentUser) {
			token = this.state.currentUser.jwtToken;
		}

		if (this.props.authToken) {
			console.log("getTotalProcedures this.props.authToken: " + this.props.authToken);
			token = this.props.authToken;
		}

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: token
				},
			});

			const body = await response.json();
			console.log("getTotalProcedures body response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				if (body.response.length>0) {
					this.setState({ totalProcedures: body.response[0].total });
				}
				//this.forceUpdate();
			} else {


			}

		}
		catch (err) {
			console.log("getTotalProcedures error: "+err);
		}

	}

	getData = async () => {

		var url1 = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"global-laser-usage-total?MarketType="+this.state.marketType;

		var token = "";

		if (this.state.currentUser) {
			token = this.state.currentUser.jwtToken;
		}

		if (this.props.authToken) {
			console.log("wavelengthusage this.props.authToken: " + this.props.authToken);
			token = this.props.authToken;
		}

		try {

			const response1 = await fetch(url1, {
				method: 'GET',
				headers: {
					Authorization: token
				},
			});

			const body1 = await response1.json();
			console.log("wavelengthusage globallaserusagetotal body response: " + JSON.stringify(body1));

			if (body1.status == 'Success') {
				if (body1.results.length>0) {

					var d = Number(body1.results[0].totalseconds);
					var h = Math.floor(d / 3600);
					var m = Math.floor(d % 3600 / 60);
					var s = Math.floor(d % 3600 % 60);

					var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
					var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";

					//let total = parseFloat(body1.results[0].totalseconds, 10)/60;
					//let roundedTotalHours = total.toFixed(2)
					this.setState({ totalUsage: hDisplay + mDisplay });
				} else {
					if (this.state.totalUsage) {
						this.setState({
							noDataForDevices: false
						});
					} else {
						this.setState({
							noDataForDevices: true
						});
					}
					//this.state.noDataForDevices = true;
				}
			} else {
				//this.state.noDataForDevices = true;
				if (this.state.totalUsage) {
					this.setState({
						noDataForDevices: false
					});
				} else {
					this.setState({
						noDataForDevices: true
					});
				}
			}

		}
		catch (err) {
			console.log("wavelengthusage get global-laser-usage-total error: "+err);
		}

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"wavelength-usage?MarketType="+this.state.marketType;

		try {

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: token
				},
			});

			const body = await response.json();
			console.log("wavelengthusage body response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				if (body.results.length>0) {
					this.setState({ wavelengthusage: body.results[0] });
				} else {
					if (this.state.wavelengthusage) {
						this.setState({
							noDataForDevices: false
						});
					} else {
						this.setState({
							noDataForDevices: true
						});
					}
					//this.state.noDataForDevices = true;
				}
				//this.forceUpdate();
			} else {
				if (this.state.wavelengthusage) {
					this.setState({
						noDataForDevices: false
					});
				} else {
					this.setState({
						noDataForDevices: true
					});
				}
				//this.state.noDataForDevices = true;
			}

		}
		catch (err) {
			console.log("wavelengthusage get wavelength-usage error: "+err);
		}

	};

	render() {

		let currentUserHeight = { height: 441 };

		if (this.state.currentUser) {
			if (this.state.currentUser.role == 1) {
				currentUserHeight = { height: 393 };
			}
		}

		let m810 = parseFloat(this.state.wavelengthusage.m810);
		let m980 = parseFloat(this.state.wavelengthusage.m980);
		let mDual = parseFloat(this.state.wavelengthusage.mDual);
		//console.log("m810: " + m810)
		let usage = this.state.totalUsage;
		//console.log("usage: " + usage)
		const options = {
			data: {
				columns: [
					['810', m810],
					['980', m980],
					['Dual', mDual],
				],

				type: 'donut',
				tooltip: {
					show: true
				}
			},
			donut: {
				label: {
					show: false
				},
				title: usage,
				width: 35,
			},
			legend: {
				hide: false,
				padding: 10
			},
			color: {
				pattern: ['#ff715b', '#6665dd', '#33d1bf']
			}
		}
		return (
			/*--------------------------------------------------------------------------------*/
			/* Used In Dashboard-1 [Classic]                                                  */
			/*--------------------------------------------------------------------------------*/
			<Card className="card-hover">
				<CardBody style={{ minHeight: 540 }}>
					<CardTitle>Wavelength Usage
					<i style={{color: '#ffffff'}} className="mdi mdi-information-outline ml-1" id="WavelengthUsageTooltip"/>
					<Tooltip placement="top" isOpen={this.state.tooltipOpen} target="WavelengthUsageTooltip" toggle={this.toggleTooltip}>
						Shows the mix of wavelength modes used
					</Tooltip>
					</CardTitle>
					{this.state.noDevices || this.state.noDataForDevices &&
						<div className="mt-5 text-center">
						{!this.state.noDataForDevices &&
							<h5>User has no devices</h5>
						}
						{this.state.noDataForDevices &&
							<h5>No data for user's devices</h5>
						}
						</div>
					}
					{!this.state.noDevices && !this.state.noDataForDevices &&
						<div className="pt-4">
							<Chart
								style={{ height: '400px', width: '100%' }}
								config={options}
							/>
							{this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']>0 &&
								<h6 style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "-200px"}}>{(this.state.totalProcedures).toLocaleString()} procedures</h6>
							}
	          </div>
					}
				</CardBody>
			</Card>

		);
	}
}

export default WavelengthUsage;
