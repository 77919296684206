import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";
import { saveAs } from "file-saver";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import update from 'react-addons-update';
import redpin from '../../../assets/images/pins/red_pin.png';
import greenpin from '../../../assets/images/pins/green_pin.png';
import brokenpin from '../../../assets/images/pins/broken_pin.png';
import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class PBMProtocolsView extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			noDevices: false,
			devicesLoaded: false,
			currentUser,
			modal: false,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			devices: [],
			downloads: []
	  };

		// this.toggle4 = this.toggle4.bind(this);
		// this.toggle5 = this.toggle5.bind(this);
		// this.toggle6 = this.toggle6.bind(this);
		// this.handleDateChange = this.handleDateChange.bind(this);

  }

	async componentDidMount() {

		//this.getData();
    this.getDownloadsData();

		//console.log("device list: " + JSON.stringify(this.state.deviceList));

		//let obj = data1.find(o => o.id === key);
	}

	componentWillUnmount() {
		clearInterval(this.intervalID);
	}

  getData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"all-devices";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all devices: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ devices: body.results });
		} else {
      //ADD ERROR MESSAGE
    }

	};

	getDownloadsData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-support-material?MaterialType=6";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all downloads: " + JSON.stringify(body));

		if (body.status == 'Success') {

			var intlDevices = [];

			if (this.state.deviceList) {
				intlDevices = this.state.deviceList.filter(device => device.Kit.toLowerCase() === "intl");
			}

			console.log("intlDevices: " + JSON.stringify(intlDevices));

			let downloads = body.results;

			var filteredDownloads = downloads.filter(download => download.Title.toLowerCase().includes("intl user's manual")==false);

			if (intlDevices.length>0) {
				filteredDownloads = downloads.filter(download => download.Title!="User's Manual: Gemini EVO 810+980 Diode Laser");
			}

			console.log("filteredDownloads: " + JSON.stringify(filteredDownloads));

			this.setState({ downloads: filteredDownloads });
		} else {
      //ADD ERROR MESSAGE
    }

	};

	downloadFile = (fileUrl, fileName) => {
		console.log("Download File: " + fileName);
    saveAs(fileUrl, fileName);
  };

	render() {
		return (
			<Fragment>
				<Row className="mb-3">
					<Col md="6">
						<h1 style={{color:'#737782'}}>PBM Protocols</h1>
					</Col>
				</Row>
				<Row className="mb-3">
					{this.state.downloads.map((download, key) => {
						return (

							<Col sm="12" md="12" lg="6" xl="6">
								<Card className="card-hover">
									<CardBody>
										<Row className="mb-3">
											<Col xs="6">
												<h3 style={{color:'#737782'}}>{download.Title}</h3>
											</Col>
											<Col xs="6">
											<div className="float-right">
												<a href={download.ItemUrl} target="_blank" download={download.FileName}>
													<Button
														size="md"
														style={{width: '100%'}}
														color="dark"
													>
														{download.ButtonTitle}
													</Button>
												</a>
											</div>
											<div style={{  }} className="float-right mr-3">
												<h6 style={{color:'#737782', lineHeight: "2.3"}}>{download.PageCount}</h6>
											</div>
											</Col>
										</Row>
										<Row className="mb-3">
											<Col md="12" className="text-center">
												<div style={{ width: '100%'}}>
													<a href={download.ItemUrl} target="_blank" download={download.FileName}>
														<img style={{ maxHeight: '280px', maxWidth: '100%', cursor:"pointer" }} className="mr-2" src={download.PreviewImageUrl}/>
													</a>
												</div>
											</Col>
											{
												// <Col md="6">
												// 	<div className="mt-2">
												// 		<h4 style={{color:'#737782'}}>{download.Description}</h4>
												// 	</div>
												// </Col>
											}
										</Row>
									</CardBody>
								</Card>
							</Col>

							);
					 })}
				</Row>
			</Fragment>
		);
	}
}

export default PBMProtocolsView;
