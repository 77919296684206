import React from "react";
import { Component, Fragment, PureComponent } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup
} from 'reactstrap';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import * as mqtt from 'react-paho-mqtt';
import Paho from 'paho-mqtt';
import { authenticationService } from '../../../jwt/_services';
import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';

const timer = ms => new Promise(res => setTimeout(res, ms))

class LaserUnits extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		this.onMessageArrived = this.onMessageArrived.bind(this);
    this.onConnectionLost = this.onConnectionLost.bind(this);

		const currentUser = authenticationService.currentUserValue;
		console.log("currentUser sidebar: "+JSON.stringify(currentUser));

    this.state = {
			rSelected: 3,
			totalProcedures: 0,
			isEditing: false,
			testDevices: [
				{SerialNumber:"00013",
				UID:"9298d3e2ad450ad13b08527188fc18110c1a88870ed593a2385b9b5ff160edfa",
				Nickname:"00013",
				WarrantyStartDate:null,
				WarrantyLength:0},
				{SerialNumber:"00045",
				UID:"fd052c351cb3c00f4d050673d8ce779476964061349947753fd3cfe1c4c1e7ed",
				Nickname:"00045",
				WarrantyStartDate:null,
				WarrantyLength:0}
			],
			currentDeviceEditing: {},
			currentUser,
			isReceivingMessages: this.props.isReceivingMessages,
			currentDevice: this.props.currentDevice,
			currentDeviceToCheck: this.props.currentDevice,
			deviceList: JSON.parse(localStorage.getItem('deviceList')),
			deviceListOnline: [],
	  };

  }

	async componentDidMount() {
		console.log("deviceList: "+JSON.stringify(this.state.deviceList));

		for ( var i = 0; i < this.state.deviceList.length; i++ ) {
			var device = this.state.deviceList[i];
			device.online = false;
			device.last_update = new Date();
			if (this.state.deviceList[i].SerialNumber == this.state.currentDevice) {
				if (this.props.isReceivingMessages) {
					device.online = true;
				}
			}

			this.state.deviceListOnline.push(device);
			console.log("laser units this.state.deviceListOnline: "+JSON.stringify(this.state.deviceListOnline));
		}

		this.connectToMQTT();

		this.intervalID = setInterval(this.getMessage, 3000);
	}

	componentWillUnmount() {
		clearInterval(this.intervalID);

		if (this.state.client && this.state.client.isConnected()) {
			this.state.client.disconnect();
		}

	}

	async checkSerialNumbers () {

	  for (var i = 0; i < this.state.deviceList.length; i++) {

			if (this.state.deviceList[i].SerialNumber != this.state.currentDevice) {
				this.connectToMQTT(this.state.deviceList[i].SerialNumber);
				console.log("number count: "+i);
		    await timer(3000); // then the created Promise can be awaited
			}
	  }
	}

	getMessage = () => {

		console.log('laser units getMessage ');

		for ( var i = 0; i < this.state.deviceListOnline.length; i++ ) {

			var time = new Date() - this.state.deviceListOnline[i].last_update;
			var serial = this.state.deviceList[i].SerialNumber;

			console.log('Sidebar serial: '+serial+' time is: ' + time);

			if ( new Date() - this.state.deviceListOnline[ i ].last_update > 10000 ) {

				this.state.deviceListOnline[i].online = false;

			}

		}

  }

	connectToMQTT = async () => {
		authenticationService.getDevicesAliveURL()
				.then(
						connectionUrl => {
							console.log('Sidebar connectToMQTT connectionUrl: ' + JSON.stringify(connectionUrl));

							//var url1 = "wss://a1f3ynjyzalf3r-ats.iot.us-west-2.amazonaws.com/mqtt?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAS3T2QUYOSMZKW4V2%2F20210201%2Fus-west-2%2Fiotdevicegateway%2Faws4_request&X-Amz-Date=20210201T225801Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=dff6d2d6b29772129d10a43adf2b218d07b3cd3423ac13e32c71c6d7f8032eaf";

							if (connectionUrl.status == 'Success') {
								var hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
								console.log('random hash: ' + hash);
								this.state.client = new Paho.Client(connectionUrl.response, hash+this.state.currentUser.payload.sub);
								this.state.client.onMessageArrived = this.onMessageArrived.bind(this);
								this.state.client.onConnectionLost = this.onConnectionLost.bind(this);
								this.state.client.connect({
									timeout: 10,
									useSSL: true,
									mqttVersion: 4,
									cleanSession: true,
									onSuccess: this.onConnect,
									onFailure: (e) => {console.log("here is the error" , e); }
								});
							}
						},
						error => {
							console.log('error.message: ' + error.message);
						}
				);
	}

	onMessageArrived(entry) {
		//this.setState({isReceivingMessages: true});
    //console.log("Laser Unit onMessageArrived: "+entry.payloadString);
		const msg = JSON.parse(entry.payloadString);
		//console.log("Laser Unit SerialNumber: " +msg.serial_num+ " online");

		for ( var i = 0; i < this.state.deviceListOnline.length; i++ ) {
			if (this.state.deviceListOnline[i].SerialNumber == msg.serial_num) {
				this.state.deviceListOnline[i].online = true;
				this.state.deviceListOnline[i].last_update = new Date();
			}
		}

		//console.log("onMessageArrived laser units this.state.deviceListOnline: "+JSON.stringify(this.state.deviceListOnline));
  }

	onConnect = () => {
    const { client } = this.state;
    console.log("Laser Unit Connected!!!!");
    //client.subscribe('00013/out');
    this.setState({isConnected: true, error: ''})

		//console.log('myCurrentDevice Sidebar id: ' + this.state.currentDeviceToCheck);
		const subChannel = 'I_am_Alive';
		this.state.client.subscribe(subChannel);
  };

	onConnectionLost(responseObject) {
    if (responseObject.errorCode !== 0) {
      console.log("onConnectionLost:"+responseObject.errorMessage);
      this.setState({error: 'Lost Connection', isConnected: false});
			//this.onConnect();
    }
  }

	setDeviceBeingEdited(device) {
		this.state.currentDeviceEditing = device;
		console.log("currentDeviceEditing: "+JSON.stringify(this.state.currentDeviceEditing));

		this.toggleEditing();
	}

	toggleEditing() {
		console.log("setEdit!");

		if (this.state.isEditing) {
			this.setState({isEditing: false});
		} else {
			this.setState({isEditing: true});
		}
	}

	render() {
		return (
      <Card className="card-hover">
				<CardBody style={{ minHeight: 210 }}>
				{!this.state.isEditing &&
					<Fragment>
					<CardTitle>Laser Units Registered</CardTitle>
						<Col xs="12" md="12" lg="12">
							<Row>
							{this.state.deviceList.map((device, key) => {
	 						 return (

									<Col xs="6" md="6" lg="6">
										<Row className={key<4 ? "visible": "invisible"}>
										 <div>
											 <div className="float-left" style={{ width: '150px' }}>
											 	{this.state.deviceListOnline.length == this.state.deviceList.length ? (
													<Fragment>
												 	{this.state.deviceListOnline[key].online ? (
														<Button size="sm" style={{width: '100%'}} color="brightgreen" href={"/vitals/"+device.SerialNumber}>
	 													 {device.Nickname}
	 												 </Button>
													) : (
														<Button size="sm" style={{width: '100%'}} color="red" href={"/vitals/"+device.SerialNumber}>
	 													 {device.Nickname}
	 												 </Button>
													)}
													</Fragment>
												) : (
													<Fragment>
														<Button size="sm" style={{width: '100%'}} color="red" href={"/vitals/"+device.SerialNumber}>
														 {device.Nickname}
													 	</Button>
													</Fragment>
												)}

											 </div>
											 <div className="float-left ml-2">
												 <i className="mdi mdi-tooltip-edit font-24" onClick={()=>{ this.setDeviceBeingEdited(device)} }/>
											 </div>
										 </div>
									 </Row>
									</Col>

									);
							 })}

							</Row>
						</Col>
					</Fragment>
					}
					{this.state.isEditing &&
						<Fragment>
						<Row>
							<Col xs="6" md="6" lg="6" className="text-left">
								<CardTitle>Laser Units Registered</CardTitle>
							</Col>
							<Col xs="6" md="6" lg="6" className="text-right">
								<Button style={{ color: '#414755' }} size="sm" onClick={()=>{ this.toggleEditing()} }>Cancel</Button>
							</Col>
						</Row>
						<Formik
												initialValues={{
														nickname: this.state.currentDeviceEditing.Nickname
												}}
												validationSchema={Yup.object().shape({
														nickname: Yup.string().required('Nickname is required')
												})}
												onSubmit={({ nickname }, { setStatus, setSubmitting }) => {
														setStatus();
														authenticationService.updateDeviceInfo(this.state.currentDeviceEditing.UID, this.state.currentDeviceEditing.SerialNumber, nickname)
																.then(
																		user => {

																				let status = user.status;
																				console.log('status: ' + status);

																				if (status === 'Success') {
																					  console.log('status info: ' + status);
																						this.state.deviceList = JSON.parse(localStorage.getItem('deviceList'));
																						this.toggleEditing();
																				} else if (status === 'Error') {
																						console.log('error info: ' + user.response);
																						setSubmitting(false);
																						setStatus(user.response);
																				} else {
																					console.log('status info: ' + status);
																				}
																		},
																		error => {
																				setSubmitting(false);
																				setStatus(error);
																		}
																);
												}}
												render={({ errors, status, touched, isSubmitting }) => (
						<Form className="" id="loginform">
							<InputGroup className="mb-2">
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										Device Nickname
									</InputGroupText>
								</InputGroupAddon>
								<Field name="nickname" type="text" className={'form-control' + (errors.nickname && touched.nickname ? ' is-invalid' : '')} />
																<ErrorMessage name="nickname" component="div" className="invalid-feedback" />
							</InputGroup>
							<Row className="mb-3">
								<Col xs="12">
									<button type="submit" className="btn btn-block btn-primary" disabled={isSubmitting}>Save</button>
								</Col>
							</Row>
							{status &&
														<div className={'alert alert-danger'}>{status}</div>
												}
						</Form>
						)}
						/>
							</Fragment>
					}
        </CardBody>
      </Card>
		);
	}
}

export default LaserUnits;
