import React from "react";
import {
	Card,
	CardBody,
	CardTitle,
	Row,
	Col,

} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

// import {
// 	TestLiveChat
// } from '../../components/test-features/';

class TestFeature extends React.Component {

	constructor(props) {
    super(props);

  }

	componentDidMount() {

	}

	render() {
		return (
			<div>
				<Row>
					<Col md="12">
						{
							//<TestLiveChat />
						}
					</Col>
				</Row>
			</div>
		);
	}
}

export default TestFeature;
