import React from 'react';
import {
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	CustomInput,
	FormGroup,
	Row,
	Col,
	UncontrolledTooltip,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Label,
  Card,
  CardBody
} from 'reactstrap';
import { Auth } from "aws-amplify";
import azenaevologo from '../../assets/images/logo2_x3.png';
import azenaevologotext from '../../assets/images/logo2_x3_text.png';
import img1 from '../../assets/images/logo2_x3.png';
import img2 from '../../assets/images/background/login-register.jpg';
import labelExp from '../../assets/images/footpedal-form/label-exp.png';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Autocomplete from "react-google-autocomplete";
import ReactGA from "react-ga4";
import * as Yup from 'yup';
import { authenticationService } from '../../jwt/_services';

const YOUR_GOOGLE_MAPS_API_KEY = 'AIzaSyAURs2M8E46FUeKemjvViueBAODjIY9-qU';

const sidebarBackground = {
	//backgroundImage: "url(" + img2 + ")",
	backgroundColor: "#313642",
	backgroundRepeat: "no-repeat",
	backgroundPosition: "bottom center"
};

const formBackground = {
	//backgroundImage: "url(" + img2 + ")",
	backgroundColor: "#414755"
};

class FootpedalReplacementForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			modal: false,
			modal2: false,
			code: '',
			email: '',
			password: '',
			confirmPassword: '',
			resetSuccessful: false,
			resetConfirmSuccessful: false,
			formStatus: "",
      formProcessing: false,
      formHash: ""
	  };

		this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.resetPasswordCall = this.resetPasswordCall.bind(this);
		this.resetPasswordConfirmCall = this.resetPasswordConfirmCall.bind(this)

	}

	async componentDidMount() {
		ReactGA.pageview(window.location.pathname);

    //this.sendEmailToSupport();
		//this.testCheckDeviceSubmission();
	}

	testCheckDeviceSubmission = async () => {

		var url = "https://hui6wcvz1i.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"check-device-submission";

		const response = await fetch(url, {
			method: 'GET',
		});

		const body = await response.json();
		console.log("testCheckDeviceSubmission data: " + JSON.stringify(body));

		if (body.status == 'Success') {
			//this.setState({ feedbackData: body.response });
		} else {
      //ADD ERROR MESSAGE
    }

	};

	handleClick() {
		var elem = document.getElementById('replacementform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		var elem = document.getElementById('changeform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		document.getElementById('recoverform').style.display = "block";
	}

	backToLogin() {
		var elem = document.getElementById('recoverform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		var elem = document.getElementById('changeform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		document.getElementById('replacementform').style.display = "block";
		//this.toggle();
	}

	showChangeForm() {
		var elem = document.getElementById('replacementform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		var elem = document.getElementById('recoverform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		document.getElementById('changeform').style.display = "block";
	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });

		console.log('this.state.resetSuccessful: ' + this.state.resetSuccessful);
		if (this.state.resetSuccessful) {
			this.showChangeForm();
		}
	}

	toggle2() {
	  this.setState({
	    modal2: !this.state.modal2
	  });

		if (this.state.resetConfirmSuccessful) {
			this.backToLogin();
		}
	}

	handleChange(event) {
		if (event.target.getAttribute('name') == 'recoverformEmail') {

			let emailStr = event.target.value
			var noSpacesString= emailStr.replace(/ /g,'');
			this.setState({email: noSpacesString});
		} else if (event.target.getAttribute('name') == 'changeformCode') {
			//console.log('this.state.code: ' + event.target.value);
			this.setState({code: event.target.value});
		} else if (event.target.getAttribute('name') == 'changeformPassword') {
			//console.log('this.state.password: ' + event.target.value);
			this.setState({password: event.target.value});
		} else if (event.target.getAttribute('name') == 'changeformConfirmPassword') {
			//console.log('this.state.confirmPassword: ' + event.target.value);
			this.setState({confirmPassword: event.target.value});
		}
  }

	handleSubmit(event) {
    //alert('A email was submitted: ' + this.state.email);
		this.resetPasswordCall();
    event.preventDefault();
		this.toggle();
  }

	async resetPasswordCall(event) {
    event.preventDefault();

		//document.getElementById('changeformCode').value = "";
		//document.getElementById('changeformPassword').value = "";
		//document.getElementById('changeformConfirmPassword').value = "";

		//this.setState({ code: '', password: ''});

    try {
      await Auth.forgotPassword(this.state.email);
			this.state.resetSuccessful = true;
			console.log('sent confirmation email Successfully.');
			this.setState({ resetStatus: ''});
			this.toggle();
    } catch (error) {
			console.log('error: ' + error.message);
			this.state.resetSuccessful = false;
			this.setState({ resetStatus: error.message});
    }
  }

	async resetPasswordConfirmCall(event) {
		event.preventDefault();

		if (this.state.password == this.state.confirmPassword) {

	    try {
	      await Auth.forgotPasswordSubmit(
	        this.state.email,
	        this.state.code,
	        this.state.password
	      );
				console.log('Changed password Successfully.');
	      this.state.resetConfirmSuccessful = true;
				this.setState({ resetStatus: ''});
				this.toggle2();
	    } catch (error) {
				console.log('error: ' + error.message);
	      this.state.resetConfirmSuccessful = false;
				this.setState({ resetStatus: error.message});
	    }

		}
  }

  handleSubmit = (event) => {

		event.preventDefault();

    var validation = true;
    this.setState({ formStatus: "", formProcessing: true });

	  let firstName = event.target.FirstName.value;
    console.log(`firstName is:`, firstName);

    if (!firstName) {
      validation = false;
      this.setState({ formStatus: "First Name is Required." });
    }

    let lastName = event.target.LastName.value;
    console.log(`lastName is:`, lastName);

    if (!lastName) {
      validation = false;
      this.setState({ formStatus: "Last Name is Required." });
    }

		let address = event.target.Address.value;
    console.log(`address is:`, address);

    if (!address) {
      validation = false;
      this.setState({ formStatus: "Address is Required." });
    }

		let address2 = event.target.Address2.value;
    console.log(`address2 is:`, address2);

    let city = event.target.City.value;
    console.log(`city is:`, city);

    if (!city) {
      validation = false;
      this.setState({ formStatus: "City is Required." });
    }

    let state = event.target.State.value;
    console.log(`state is:`, state);

    if (!state) {
      validation = false;
      this.setState({ formStatus: "State is Required." });
    }

    let zip = event.target.Zip.value;
    console.log(`zip is:`, zip);

    if (!zip) {
      validation = false;
      this.setState({ formStatus: "Zip is Required." });
    }

    let email = event.target.Email.value;
    console.log(`email is:`, email);

    if (!email) {
      validation = false;
      this.setState({ formStatus: "Email is Required." });
    }

    let batch = event.target.Batch.value;
    console.log(`batch is:`, batch);

    if (!batch) {
      validation = false;
      this.setState({ formStatus: "Gemini EVO Lot is Required." });
    }

    let serialNumber = event.target.SerialNumber.value;
    console.log(`serialNumber is:`, serialNumber);

    if (!serialNumber) {
      validation = false;
      this.setState({ formStatus: "Gemini EVO Serial Number is Required." });
    }

    if (serialNumber.length > 5 || serialNumber.length < 5) {
      validation = false;
      this.setState({ formStatus: "Gemini EVO Serial Number is 5 digits. Please include any zeros before the number." });
    }

    var formData = {
			FirstName: firstName,
			LastName: lastName,
      Address: address,
			Address2: address2,
      City: city,
      State: state,
      Zip: zip,
      Email: email,
      Batch: batch,
      SerialNumber: serialNumber,
    };

    // formData = {
		// 	FirstName: "Ryan",
		// 	LastName: "Stickel",
    //   Address: "5309 Pine Grove Ave.",
    //   City: "Shasta Lake",
    //   State: "CA",
    //   Zip: "96019",
    //   Email: "rstickel@azenamedical.com",
    //   Batch: batch,
    //   SerialNumber: serialNumber,
    // };


    console.log("saved formData is:", JSON.stringify(formData));

    if (validation) {
      this.submitForm(formData);
    } else {
      this.setState({ formProcessing: false });
    }

	}

  submitForm = async (formData) => {

    var url = "https://hui6wcvz1i.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"footpedal-replacement-submission";

		try {

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(formData)
			});

			const body = await response.json();
			console.log("submitForm response: " + JSON.stringify(body));

			if (body.status == 'Success') {

	      this.setState({formHash: body.hash});
				await this.sendEmailToCustomer(body.hash);
	      this.sendEmailToSupport(body.hash);

			} else {

				//this.state.noDataForDevices = true;

	      if (body.error=="Serial and/or Lot do not qualify.") {
	        this.goToResults(2);
	      } else {
	        this.setState({ formStatus: body.error });
	      }

			}

	    this.setState({ formProcessing: false });

    } catch (error) {
			console.log('error: ' + error.message);
    }

	};

  sendEmailToSupport = async (hash) => {

    var formData = {
			hash: hash,
    };

    var url = "https://hui6wcvz1i.execute-api.us-west-2.amazonaws.com/v1/send-email-to-support";

    try {

  		const response = await fetch(url, {
  			method: 'POST',
  			headers: {
  				'Content-Type': 'application/json'
  			},
  			body: JSON.stringify(formData)
  		});

  		const body = await response.json();
  		console.log("sendEmailToSupport response: " + JSON.stringify(body));

  		if (body.status == 'Success') {
  			//.this.setState({formHash: body.hash});

        this.goToResults(1);

  		} else {
  			//this.state.noDataForDevices = true;
  		}

    } catch (err) {
			console.log(err);
		}

	};

	sendEmailToCustomer = async (hash) => {

    var formData = {
			hash: hash,
    };

    var url = "https://hui6wcvz1i.execute-api.us-west-2.amazonaws.com/v1/send-email-to-customer";

    try {

  		const response = await fetch(url, {
  			method: 'POST',
  			headers: {
  				'Content-Type': 'application/json'
  			},
  			body: JSON.stringify(formData)
  		});

  		const body = await response.json();
  		console.log("sendEmailToCustomer response: " + JSON.stringify(body));

  		if (body.status == 'Success') {
  			//.this.setState({formHash: body.hash});



  		} else {
  			//this.state.noDataForDevices = true;
  		}

    } catch (err) {
			console.log(err);
		}

	};

  goToResults(status){
		//window.location.href="/repair-log/"+id;
		// window.open(
		//   "/fpreplacementresults?status="+status
		// );

    window.location.href="/fpreplacementresults?status="+status;
	}

	render() {
		return <div className="">
			{/*--------------------------------------------------------------------------------*/}
			{/*Login Cards*/}
			{/*--------------------------------------------------------------------------------*/}
			<div className="auth-wrapper d-flex no-block justify-content-center align-items-center" style={sidebarBackground}>
				<div className="auth-box-2 on-sidebar" style={formBackground}>
					<div id="replacementform">
						<Row>
              <Col xs="1" md="4">
              </Col>
							<Col xs="10" md="4">
                <div className="logo">
    							<span className="db mb-5"><img style={{ width: "100%"}} src={img1} alt="logo" /></span>
    							<h5 className="mt-2 mb-4"></h5>
    						</div>
							</Col>
              <Col xs="1" md="4">
							</Col>
						</Row>
            <Row>
              <Col className="p-4" xs="12">
              <h5 className="font-medium mb-3 text-light">In an effort to provide the best possible user experience with your Gemini EVO, we will immediately replace eligible foot pedals if you have experienced any of the following intermittent issues:</h5>
              <h5 className="font-medium pl-4 mb-3 text-light">- Foot pedal not powering on (no foot pedal lights turn on after pressing on the foot pedal)</h5>
              <h5 className="font-medium pl-4 mb-3 text-light">- Frequent disconnection of Bluetooth connection between foot pedal and laser unit 	(may see AP error on laser display)</h5>
              <h5 className="font-medium pl-4 mb-3 text-light">- Foot pedal battery not holding charge</h5>
              <h5 className="font-medium mb-3 text-light">If you have experienced any of these intermittent issues, please fill out the information below to see if you are eligible for a foot pedal replacement:</h5>
              </Col>
						</Row>
            <Row>
              <Col className="mb-2" xs="12" md="6">
                <Card style={{ backgroundColor: "#586072" }}>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                    <Row className="mb-2">
                      <Col xs="4" md="4" lg="4">
                        <Label className="text-light float-right" style={{fontSize:'16px'}} for="FirstName">First Name:</Label>
                      </Col>
                      <Col xs="8" md="8" lg="8">
                        <Input style={{width:'100%', height: '25px'}} type="text" name="FirstName" id="FirstName"/>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs="4" md="4" lg="4">
                        <Label className="text-light float-right" style={{fontSize:'16px'}} for="LastName">Last Name:</Label>
                      </Col>
                      <Col xs="8" md="8" lg="8">
                        <Input style={{width:'100%', height: '25px'}} type="text" name="LastName" id="LastName"/>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs="4" md="4" lg="4">
                        <Label className="text-light float-right" style={{fontSize:'16px'}} for="Address">Address:</Label>
                      </Col>
                      <Col xs="8" md="8" lg="8">
												{
													//<Input style={{width:'100%', height: '25px'}} type="text" name="Address" id="Address"/>
												}
												<Autocomplete className="form-control" style={{width:'100%', height: '30px', color: '#b2b9bf'}} type="text" name="Address" id="Address"
																options={{
																	types: ["address"],
																	componentRestrictions: { country: "us" },
																}}
																onPlaceSelected={(place) => {
																	console.log(place);

																	var streetNumber = '';
																	var streetName = '';
																	var city = '';
																	var state = '';
																	var zip = '';

																	for (var i=0; i<place.address_components.length; i++) {

																		var component = place.address_components[i];

																		//console.log(component);

																		if (component.types[0]=='street_number') {
																			streetNumber = component.long_name;
																			console.log("streetNumber: " + streetNumber);
																		} else if (component.types[0]=='route') {
																			streetName = component.short_name;
																			console.log("streetName: " + streetName);
																		} else if (component.types[0]=='locality') {
																			city = component.short_name;
																			console.log("city: " + city);
																		} else if (component.types[0]=='administrative_area_level_1') {
																			state = component.short_name;
																			console.log("state: " + state);
																		} else if (component.types[0]=='postal_code') {
																			zip = component.short_name;
																			console.log("zip: " + zip);
																		}
																	}

																	document.getElementById("Address").value = streetNumber+" "+streetName;
																	document.getElementById("City").value = city;
																	document.getElementById("State").value = state;
																	document.getElementById("Zip").value = zip;

																}}
															/>
                      </Col>
                    </Row>
										<Row className="mb-2">
                      <Col xs="4" md="4" lg="4">
                        <Label className="text-light float-right" style={{fontSize:'16px'}} for="Address2">Address 2:</Label>
                      </Col>
                      <Col xs="8" md="8" lg="8">
                        <Input style={{width:'100%', height: '25px'}} type="text" name="Address2" id="Address2"/>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs="4" md="4" lg="4">
                        <Label className="text-light float-right" style={{fontSize:'16px'}} for="City">City:</Label>
                      </Col>
                      <Col xs="8" md="8" lg="8">
                        <Input style={{width:'100%', height: '25px'}} type="text" name="City" id="City"/>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs="4" md="4" lg="4">
                        <Label className="text-light float-right" style={{fontSize:'16px'}} for="State">State:</Label>
                      </Col>
                      <Col xs="8" md="8" lg="8">
                        <Input style={{width:'100%', height: '25px'}} type="text" name="State" id="State"/>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs="4" md="4" lg="4">
                        <Label className="text-light float-right" style={{fontSize:'16px'}} for="Zip">Zip:</Label>
                      </Col>
                      <Col xs="8" md="8" lg="8">
                        <Input style={{width:'100%', height: '25px'}} type="text" name="Zip" id="Zip"/>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs="4" md="4" lg="4">
                        <Label className="text-light float-right" style={{fontSize:'16px'}} for="Email">Email:</Label>
                      </Col>
                      <Col xs="8" md="8" lg="8">
                        <Input style={{width:'100%', height: '25px'}} type="text" name="Email" id="Email"/>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs="4" md="4" lg="4">
                        <Label className="text-light float-right" style={{fontSize:'16px'}} for="Batch">LOT:</Label>
                      </Col>
                      <Col xs="8" md="8" lg="8">
                        <Input style={{width:'100%', height: '25px'}} type="text" name="Batch" id="Batch" placeholder="Gemini EVO LOT"/>
                      </Col>
                      <Col xs="4" md="4" lg="4">
                      </Col>
                      <Col xs="8" md="8" lg="8">
                        <Label className="text-light" style={{fontSize:'12px'}}>See label under your Gemini EVO laser or foot pedal (green highlight in sample label)</Label>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs="4" md="4" lg="4">
                        <Label className="text-light float-right" style={{fontSize:'16px'}} for="SerialNumber">Serial Number:</Label>
                      </Col>
                      <Col xs="8" md="8" lg="8">
                        <Input style={{width:'100%', height: '25px'}} type="text" name="SerialNumber" id="SerialNumber" placeholder="Gemini EVO Serial Number (last five digits)"/>
                      </Col>
                      <Col xs="4" md="4" lg="4">
                      </Col>
                      <Col xs="8" md="8" lg="8">
                        <Label className="text-light" style={{fontSize:'12px'}}>See label under your Gemini EVO laser or foot pedal (last 5 digits, red highlight in sample label)</Label>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col xs="4" md="4" lg="4">
                      </Col>
                      <Col xs="8" md="8" lg="8">
                        <Button style={{width:'100%'}} className="" type="submit" size="lg" color="yellow" title="Submit" disabled={this.state.formProcessing}>
                          Submit
                        </Button>
                      </Col>
                    </Row>
                    </Form>
                    {this.state.formStatus!='' &&
  										<Row className="mt-3">
  											<Col xs="12">
  												<div className={'alert alert-danger'}>{this.state.formStatus}</div>
  											</Col>
  										</Row>
  									}
                  </CardBody>
                </Card>
							</Col>
              <Col className="mb-2 text-center" xs="12" md="6">
                <Card style={{ backgroundColor: "#586072" }}>
                  <CardBody>
                  <Row className="">
                    <Col xs="12" md="12" lg="12">
                      <Label className="text-light" style={{fontSize:'16px'}}>Sample Label:</Label>
                    </Col>
                    <Col xs="12" md="12" lg="12">
                      <img className="" style={{ width: "65%"}} src={labelExp} alt="logo" />
                    </Col>
                  </Row>
                  </CardBody>
                </Card>
              </Col>
						</Row>
					</div>
				</div>
			</div>
		</div>;
	}
}

export default FootpedalReplacementForm;
