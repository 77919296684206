import React from "react";
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';

class Booster200V extends React.Component {

	noResults = true;

	constructor(props) {
    super(props);

    this.state = {
			rSelected: 3,
			totalProcedures: 0,
			topic: ["00013"],
			message: [''],
			currentDevice: '',
      messageToSend:'',
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
      isConnected: false,
	  };

		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
  }

	async componentDidMount() {

		//setInterval(this.getMessage, 4000);
	}

	getMessage = () => {

		console.log("onMessageArrivedIn200vBoosterHV:"+this.props.message.hv);
		console.log("current Device n200vBooster:"+this.props.currentDevice);
  }

  onRadioBtnClick(rSelected) {
    this.setState({ rSelected });
  }

	render() {

		var voltageLabel = "0V";
		var voltageIndicator = 0;
		if (this.props.message.hv) {
			voltageLabel = this.props.message.hv;
			voltageLabel = parseFloat(voltageLabel);
			voltageIndicator = voltageLabel/220*100;
			voltageLabel = voltageLabel.toFixed(1)+"V";
		}

		var placeholderValue = 0

		var voltageHistoryLabel = "0V";
		var voltageHistoryIndicator = 0;
		if (this.props.historyMessage) {
			if (this.props.historyMessage.hv) {
				voltageHistoryLabel = this.props.historyMessage.hv;
				voltageHistoryLabel = parseFloat(voltageHistoryLabel);
				voltageHistoryIndicator = voltageHistoryLabel/220*100;
				voltageHistoryLabel = voltageHistoryLabel.toFixed(1)+"V";
			}
		}

		return (
      <Card className="card-hover">
				<CardBody style={{ minHeight: 417 }}>
					<CardTitle>Electroluminescent Display Status</CardTitle>
					{this.props.isReceivingMessages &&
						<Fragment>
						<Row>
							<Col xs="12" md="12" lg="12">
								<div className="">
									<ButtonGroup style={{ width: '100%' }}>
										<Button
											size="sm"
											style={{width: '100%', cursor: 'default'}}
											color="good"
											active={this.props.message.hv > 180 && this.props.message.hv < 220}
											disabled={this.props.message.hv < 180 && this.props.message.hv > 220}
										>
											Good
										</Button>
										<Button
											size="sm"
											color="damaged"
											style={{width: '100%', cursor: 'default'}}
											active={this.props.message.hv < 180 && this.props.message.hv > 220}
											disabled={this.props.message.hv > 180 && this.props.message.hv < 220}
										>
											Service
										</Button>
									</ButtonGroup>
								</div>
							</Col>
						</Row>
						<div className="pb-4">
							<Row className="mt-2">
								<Col xs="6" md="6" lg="6" className="text-left">
									<span>Voltage</span>
								</Col>
								<Col xs="6" md="6" lg="6" className="text-right">
									<span>{voltageLabel}</span>
								</Col>
							</Row>
							<Progress multi className="mt-1">
								<Progress bar color="yellow" value={voltageIndicator} />
							</Progress>
						</div>
						<CardTitle>ON/OFF Button Status</CardTitle>
						<Row>
							<Col xs="12" md="12" lg="12">
								<div className="pb-3">
									<ButtonGroup style={{ width: '100%' }}>
										<Button
											size="sm"
											style={{width: '100%', cursor: 'default'}}
											color="good"
											active={this.props.message.onoff > 0}
											disabled={this.props.message.onoff <= 0}
										>
											Good
										</Button>
										<Button
											size="sm"
											color="damaged"
											style={{width: '100%', cursor: 'default'}}
											active={this.props.message.onoff <= 0}
											disabled={this.props.message.onoff > 0}
										>
											Service
										</Button>
									</ButtonGroup>
								</div>
							</Col>
						</Row>
						<CardTitle>Emergency Button Status</CardTitle>
						<Row>
							<Col xs="12" md="12" lg="12">
								<div className="pb-3">
									<ButtonGroup style={{ width: '100%' }}>
										<Button
											size="sm"
											style={{width: '100%', cursor: 'default'}}
											color="good"
											active={this.props.message.eoff > 0}
											disabled={this.props.message.eoff <= 0}
										>
											Good
										</Button>
										<Button
											size="sm"
											color="damaged"
											style={{width: '100%', cursor: 'default'}}
											active={this.props.message.eoff <= 0}
											disabled={this.props.message.eoff > 0}
										>
											Service
										</Button>
									</ButtonGroup>
								</div>
							</Col>
						</Row>
						<CardTitle>Laser Internal Temperature Status</CardTitle>
						<Row>
							<Col xs="12" md="12" lg="12">
								<div className="pb-3">
									<Row>
										<Col xs="6" md="6" lg="6" className="text-left">
											<span>Temperature</span>
										</Col>
										<Col xs="6" md="6" lg="6" className="text-right">
											<span>{this.props.message.thermistor}&#176; Celsius</span>
										</Col>
									</Row>
									<Progress multi className="mt-1">
										<Progress bar color="yellow" value={this.props.message.thermistor} />
									</Progress>
								</div>
							</Col>
						</Row>
						</Fragment>
					}
					{!this.props.isReceivingMessages &&
						<Fragment>
						{this.state.currentUser && this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']==2 && this.props.historyMessage ? (
							<Fragment>
							<Row>
								<Col xs="12" md="12" lg="12">
									<div className="">
										<ButtonGroup style={{ width: '100%' }}>
											<Button
												size="sm"
												style={{width: '100%', cursor: 'default'}}
												color="good"
												active={this.props.historyMessage.hv > 180 && this.props.historyMessage.hv < 220}
												disabled={this.props.historyMessage.hv < 180 && this.props.historyMessage.hv > 220}
											>
												Good
											</Button>
											<Button
												size="sm"
												color="damaged"
												style={{width: '100%', cursor: 'default'}}
												active={this.props.historyMessage.hv < 180 && this.props.historyMessage.hv > 220}
												disabled={this.props.historyMessage.hv > 180 && this.props.historyMessage.hv < 220}
											>
												Service
											</Button>
										</ButtonGroup>
									</div>
								</Col>
							</Row>
							<div className="pb-4">
								<Row className="mt-2">
									<Col xs="6" md="6" lg="6" className="text-left">
										<span>Voltage</span>
									</Col>
									<Col xs="6" md="6" lg="6" className="text-right">
										<span>{voltageHistoryLabel}</span>
									</Col>
								</Row>
								<Progress multi className="mt-1">
									<Progress bar color="yellow" value={voltageHistoryIndicator} />
								</Progress>
							</div>
							<CardTitle>ON/OFF Button Status</CardTitle>
							<Row>
								<Col xs="12" md="12" lg="12">
									<div className="pb-3">
										<ButtonGroup style={{ width: '100%' }}>
											<Button
												size="sm"
												style={{width: '100%', cursor: 'default'}}
												color="good"
												active={this.props.historyMessage.onoff > 0}
												disabled={this.props.historyMessage.onoff <= 0}
											>
												Good
											</Button>
											<Button
												size="sm"
												color="damaged"
												style={{width: '100%', cursor: 'default'}}
												active={this.props.historyMessage.onoff <= 0}
												disabled={this.props.historyMessage.onoff > 0}
											>
												Service
											</Button>
										</ButtonGroup>
									</div>
								</Col>
							</Row>
							<CardTitle>Emergency Button Status</CardTitle>
							<Row>
								<Col xs="12" md="12" lg="12">
									<div className="pb-3">
										<ButtonGroup style={{ width: '100%' }}>
											<Button
												size="sm"
												style={{width: '100%', cursor: 'default'}}
												color="good"
												active={this.props.historyMessage.eoff > 0}
												disabled={this.props.historyMessage.eoff <= 0}
											>
												Good
											</Button>
											<Button
												size="sm"
												color="damaged"
												style={{width: '100%', cursor: 'default'}}
												active={this.props.historyMessage.eoff <= 0}
												disabled={this.props.historyMessage.eoff > 0}
											>
												Service
											</Button>
										</ButtonGroup>
									</div>
								</Col>
							</Row>
							<CardTitle>Laser Internal Temperature Status</CardTitle>
							<Row>
								<Col xs="12" md="12" lg="12">
									<div className="pb-3">
										<Row>
											<Col xs="6" md="6" lg="6" className="text-left">
												<span>Temperature</span>
											</Col>
											<Col xs="6" md="6" lg="6" className="text-right">
												<span>{this.props.historyMessage.thermistor}&#176; Celsius</span>
											</Col>
										</Row>
										<Progress multi className="mt-1">
											<Progress bar color="yellow" value={this.props.historyMessage.thermistor} />
										</Progress>
									</div>
								</Col>
							</Row>
							</Fragment>
						) : (
							<div className="mt-5 text-center">
								<h5>Device {this.props.currentDevice} is Currently not Connected.</h5>
							</div>
						)}
						</Fragment>
					}
        </CardBody>
      </Card>
		);
	}
}

export default Booster200V;
