import React from "react";

/** @jsx jsx */
import { jsx } from '@emotion/core'

import { CometChat } from '@cometchat-pro/chat';
import { CometChatManager } from "../../util/controller";
import { SvgAvatar } from '../../util/svgavatar';
import { UserListManager } from "./controller";
import * as enums from '../../util/enums.js';
import { incomingMessageAlert } from "../../../CometChat/resources/audio/";

import UserView from "../UserView";

import { theme } from "../../resources/theme";

import {
  contactWrapperStyle,
  contactHeaderStyle,
  contactHeaderCloseStyle,
  contactHeaderTitleStyle,
  contactSearchStyle,
  contactSearchInputStyle,
  contactMsgStyle,
  contactMsgTxtStyle,
  contactListStyle,
  contactAlphabetStyle
} from "./style";

import searchIcon from './resources/search-grey-icon.svg';
import navigateIcon from './resources/navigate_before.svg';

class CometChatUserList extends React.PureComponent {
  timeout;
  friendsOnly = false;
  decoratorMessage = "Loading...";

  constructor(props) {

    super(props);

    this.state = {
      userlist: [],
      userBadgeList: [],
      approvedTechList: ["team@geminievo.com"],
      approvedTechList2: ["adisessa@mac.com", "jabe@azenamedical.com", "poppy.insixiengmy@ultradent.com", "orlaina.augustine@ultradent.com", "heather.simpson@ultradent.com", "shyann.holliday@ultradent.com"],
      selectedUser: null
    }
    this.userListRef = React.createRef();
    this.theme = Object.assign({}, theme, this.props.theme);
  }

  componentDidMount() {

    if(this.props.hasOwnProperty("friendsOnly")) {
      this.friendsOnly = this.props.friendsOnly;
    }

    if(this.props.hasOwnProperty("widgetsettings")
    && this.props.widgetsettings
    && this.props.widgetsettings.hasOwnProperty("sidebar")
    && this.props.widgetsettings.sidebar.hasOwnProperty("user_listing")) {

      switch(this.props.widgetsettings.sidebar["user_listing"]) {
        case "friends":
          this.friendsOnly = true;
        break;
        default:
        break;
      }
    }

    this.audio = new Audio(incomingMessageAlert);

    this.UserListManager = new UserListManager(this.friendsOnly);
    this.getUsers();
    this.UserListManager.attachListeners(this.userUpdated);
    this.createChatListener();
    //this.UserListManager.attachMessageListeners(this.conversationUpdated);
  }

  createChatListener() {
    let listenerID = "incomingMessagesListenerUserList";

		CometChat.addMessageListener(
		  listenerID,
		  new CometChat.MessageListener({
		    onTextMessageReceived: textMessage => {
		      console.log("CometChatUserList Text message received successfully", textMessage);
					this.playAudio();
          this.dealWithNewMessage(textMessage);
					// let badgeCount = JSON.parse(localStorage.getItem('badgeCount'));
					// localStorage.setItem('badgeCount', badgeCount+1);
					// this.setState({ badgeCount: badgeCount+1 });
					// console.log("BadgeCount is: ", badgeCount+1);
		    },
		    onMediaMessageReceived: mediaMessage => {
		      console.log("CometChatUserList Media message received successfully", mediaMessage);
					this.playAudio();
          this.dealWithNewMessage(mediaMessage);
					// let badgeCount = JSON.parse(localStorage.getItem('badgeCount'));
					// localStorage.setItem('badgeCount', badgeCount+1);
					// this.setState({ badgeCount: badgeCount+1 });
					// console.log("BadgeCount is: ", badgeCount+1);
		    },
		    onCustomMessageReceived: customMessage => {
		      console.log("CometChatUserList Custom message received successfully", customMessage);
					this.playAudio();
          this.dealWithNewMessage(customMessage);
					// let badgeCount = JSON.parse(localStorage.getItem('badgeCount'));
					// localStorage.setItem('badgeCount', badgeCount+1);
					// this.setState({ badgeCount: badgeCount+1 });
					// console.log("BadgeCount is: ", badgeCount+1);
		    }
		  })
		);
  }

  dealWithNewMessage(message) {

    console.log("CometChatUserList dealWithNewMessage: ", message);

    var userBadgeList = this.state.userBadgeList;
    var found = false;

    userBadgeList.forEach(u => {

      if (u.uid === message.sender.uid) {
        found = true;
        u.count = u.count+1;
      }

    });

    if (!found) {
      let userBadgeItem = { uid: message.sender.uid, count: 1};
      userBadgeList.push(userBadgeItem);
    }

    console.log("CometChatUserList updated userBadgeList: ", userBadgeList);
    this.setState({ userBadgeList });
    this.forceUpdate();
  }

  playAudio = () => {

    //if it is disabled for chat wigdet in dashboard
    if (this.props.hasOwnProperty("widgetsettings")
    && this.props.widgetsettings
    && this.props.widgetsettings.hasOwnProperty("main")
    && (this.props.widgetsettings.main.hasOwnProperty("enable_sound_for_messages") === false
    || (this.props.widgetsettings.main.hasOwnProperty("enable_sound_for_messages")
    && this.props.widgetsettings.main["enable_sound_for_messages"] === false))) {
      return false;
    }

    this.audio.currentTime = 0;
    this.audio.play();
  }

  componentDidUpdate(prevProps) {

    const previousItem = JSON.stringify(prevProps.item);
    const currentItem = JSON.stringify(this.props.item);

    if (previousItem !== currentItem) {

      if (Object.keys(this.props.item).length === 0) {

        this.userListRef.scrollTop = 0;
        this.setState({ selectedUser: {} });

      } else {

        let userlist = [...this.state.userlist];

        //search for user
        let userKey = userlist.findIndex(u => u.uid === this.props.item.uid);
        if (userKey > -1) {

          let userObj = { ...userlist[userKey] };
          this.setState({ selectedUser: userObj });
        }
      }
    }

    //if user is blocked/unblocked, update userlist in state
    if(prevProps.item
    && Object.keys(prevProps.item).length
    && prevProps.item.uid === this.props.item.uid
    && prevProps.item.blockedByMe !== this.props.item.blockedByMe) {

      let userlist = [...this.state.userlist];

      //search for user
      let userKey = userlist.findIndex((u, k) => u.uid === this.props.item.uid);
      if(userKey > -1) {

        let userObj = {...userlist[userKey]};
        let newUserObj = Object.assign({}, userObj, {blockedByMe: this.props.item.blockedByMe});
        userlist.splice(userKey, 1, newUserObj);

        this.setState({ userlist: userlist });
      }
    }
  }

  componentWillUnmount() {

    this.UserListManager.removeListeners();
    this.UserListManager = null;
  }

  userUpdated = (user) => {

    console.log("userUpdated listener: ", user);

    let userlist = [...this.state.userlist];

    //search for user
    let userKey = userlist.findIndex((u, k) => u.uid === user.uid);

    //if found in the list, update user object
    if(userKey > -1) {

      let userObj = {...userlist[userKey]};
      let newUserObj = {...userObj, ...user};
      userlist.splice(userKey, 1, newUserObj);

      this.setState({ userlist: userlist });

    }
  }

  // conversationUpdated = (key, item, message, options) => {
  //
  //   console.log("conversationUpdated listener!");
  //   console.log("conversationUpdated key: "+JSON.stringify(key));
  //   console.log("conversationUpdated item: "+JSON.stringify(item));
  //   console.log("conversationUpdated message: "+JSON.stringify(message));
  //   console.log("conversationUpdated options: "+JSON.stringify(options));
  //
  // }

  handleScroll = (e) => {

    const bottom =
      Math.round(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) === Math.round(e.currentTarget.clientHeight);
    if (bottom) this.getUsers();
  }

  handleClick = (user, badgeCount) => {

    if(!this.props.onItemClick)
      return;

    console.log("chat user:", user);
    //this.setState({selectedUser: {...user}});
    //this.getUsers();

    if (badgeCount>0) {
      //this.setState({ userBadgeList: [] });

      var userBadgeList = this.state.userBadgeList;

      userBadgeList.forEach(u => {

        if (u.uid === user.uid) {
          u.count = 0;
        }

      });

      this.setState({ userBadgeList });
    }

    this.props.onItemClick(user, 'user');
  }

  handleMenuClose = () => {

    if(!this.props.actionGenerated) {
      return false;
    }

    this.props.actionGenerated("closeMenuClicked")
  }

  searchUsers = (e) => {

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    let val = e.target.value;
    this.timeout = setTimeout(() => {

      this.UserListManager = new UserListManager(this.friendsOnly, val);
      this.setState({ userlist: [] }, () => this.getUsers())
    }, 500)

  }

  getUsers = () => {

    new CometChatManager().getLoggedInUser().then((user) => {

      this.UserListManager.fetchNextUsers().then((userList) => {

        if(userList.length === 0) {
          this.decoratorMessage = "No users found";
        }

        userList.forEach(user => user = this.setAvatar(user));
        this.setState({ userlist: [...this.state.userlist, ...userList] });

        var userBadgeList = [];

        userList.forEach(user => {

          var badgeCount = 0;

          CometChat.getUnreadMessageCountForUser(user.uid).then(
            array => {
              console.log("Message array fetched", array);
              console.log("Message count", array[user.uid]);

              if (array[user.uid]) {
                badgeCount = array[user.uid];
                console.log("badgeCount: ", badgeCount);

                let userBadgeItem = { uid: user.uid, count: badgeCount};
                userBadgeList.push(userBadgeItem);
              }

            }, error => {
              console.log("Error in getting message count", error);
            }
          );

        });

        console.log("userBadgeList: ", userBadgeList);
        this.setState({ userBadgeList });

        this.handleClick(this.state.userlist[0]);

      }).catch((error) => {

        this.decoratorMessage = "Error";
        console.error("[CometChatUserList] getUsers fetchNext error", error);
      });

    }).catch((error) => {

      this.decoratorMessage = "Error";
      console.log("[CometChatUserList] getUsers getLoggedInUser error", error);
    });
  }

  setAvatar(user) {

    if(!user.getAvatar()) {

      const uid = user.getUid();
      const char = user.getName().charAt(0).toUpperCase();
      user.setAvatar(SvgAvatar.getAvatar(uid, char))
    }

  }

  render() {

    let messageContainer = null;
    if(this.state.userlist.length === 0) {
      messageContainer = (
        <div css={contactMsgStyle()} className="contacts__decorator-message">
          <p css={contactMsgTxtStyle(this.theme)} className="decorator-message">{this.decoratorMessage}</p>
        </div>
      );
    }

    const userList = [...this.state.userlist];
    let currentLetter = "";

    const userListFiltered = userList.filter((user, key) => {

      console.log("user email: ", JSON.stringify(user.metadata.email));

      const isApprovedTech = this.state.approvedTechList.find(techEmail => user.metadata.email === techEmail);

      if (isApprovedTech) {
        return user;
      }

    });

    console.log("userList userListFiltered: ", JSON.stringify(userListFiltered));
    console.log("userList userListFiltered length: ", JSON.stringify(userListFiltered.length));

    const users = userListFiltered.map((user, key) => {

      console.log("userList user: ", JSON.stringify(user));
      console.log("userList user.uid: ", JSON.stringify(user.uid));
      const chr = user.name[0].toUpperCase();
      let firstChar = null;

      let userBadge = this.state.userBadgeList.find(u => u.uid === user.uid);
      console.log("userBadge count: ", JSON.stringify(userBadge));

      var badgeCount = 0;
      if (userBadge) {
        badgeCount = userBadge.count;
      }

      console.log("badgeCount count: ", badgeCount);
      //COMMENTED OUT AS IT'S UNNEEDED
      // if (chr !== currentLetter) {
      //   currentLetter = chr;
      //   firstChar = (<div css={contactAlphabetStyle()} className="contacts__list__alphabet-filter">{currentLetter}</div>);
      // } else {
      //   firstChar = null;
      // }

      // if (user.status=="online") {
      //   return (
      //     <React.Fragment key={key}>
      //       {firstChar}
      //       <UserView
      //       theme={this.theme}
      //       user={user}
      //       selectedUser={this.state.selectedUser}
      //       widgetsettings={this.props.widgetsettings}
      //       clickeHandler={this.handleClick}  />
      //     </React.Fragment>
      //   );
      // }

      return (
        <React.Fragment key={key}>
          {firstChar}
          <UserView
          theme={this.theme}
          user={user}
          badgeCount={badgeCount}
          selectedUser={this.state.selectedUser}
          widgetsettings={this.props.widgetsettings}
          clickeHandler={this.handleClick}  />
        </React.Fragment>
      );

    });

    let closeBtn = (<div css={contactHeaderCloseStyle(navigateIcon)} className="header__close" onClick={this.handleMenuClose}></div>);
    if (!this.props.hasOwnProperty("enableCloseMenu") || (this.props.hasOwnProperty("enableCloseMenu") && this.props.enableCloseMenu === 0)) {
      closeBtn = null;
    }

    return (

      <div css={contactWrapperStyle()} className="contacts">
        <div css={contactHeaderStyle(this.theme)} className="contacts__header">
          {closeBtn}
          <h4 css={contactHeaderTitleStyle(this.props)} className="header__title">Contact Support</h4>
          <div></div>
        </div>
        {
          // <div css={contactSearchStyle()} className="contacts__search">
          //   <input
          //   type="text"
          //   autoComplete="off"
          //   css={contactSearchInputStyle(this.theme, searchIcon)}
          //   className="search__input"
          //   placeholder="SEARCH"
          //   onChange={this.searchUsers} />
          // </div>
        }
        {messageContainer}
        <div css={contactListStyle()} className="contacts__list" onScroll={this.handleScroll} ref={el => this.userListRef = el}>{users}</div>
      </div>
    );
  }
}

export default CometChatUserList;
