import React from 'react';
import {
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	CustomInput,
	FormGroup,
	Row,
	Col,
	UncontrolledTooltip,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Label,
  Card,
  CardBody
} from 'reactstrap';
import { Auth } from "aws-amplify";
import azenaevologo from '../../assets/images/logo2_x3.png';
import azenaevologotext from '../../assets/images/logo2_x3_text.png';
import img1 from '../../assets/images/logo2_x3.png';
import img2 from '../../assets/images/background/login-register.jpg';
import labelExp from '../../assets/images/footpedal-form/label-exp.png';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Autocomplete from "react-google-autocomplete";
import ReactGA from "react-ga4";
import * as Yup from 'yup';
import { authenticationService } from '../../jwt/_services';

const sidebarBackground = {
	//backgroundImage: "url(" + img2 + ")",
	backgroundColor: "#000",
	backgroundRepeat: "no-repeat",
	backgroundPosition: "bottom center"
};

const formBackground = {
	//backgroundImage: "url(" + img2 + ")",
	backgroundColor: "#000",
  textAlign: "center"
};

class RejoyView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			modal: false,
			modal2: false,
			code: '',
			email: '',
			password: '',
			confirmPassword: '',
			resetSuccessful: false,
			resetConfirmSuccessful: false,
			formStatus: "",
      formProcessing: false,
      formHash: ""
	  };

		this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.resetPasswordCall = this.resetPasswordCall.bind(this);
		this.resetPasswordConfirmCall = this.resetPasswordConfirmCall.bind(this)

	}

	async componentDidMount() {
		ReactGA.pageview(window.location.pathname);

    //this.sendEmailToSupport();
		//this.testCheckDeviceSubmission();
	}

	testCheckDeviceSubmission = async () => {

		var url = "https://hui6wcvz1i.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"check-device-submission";

		const response = await fetch(url, {
			method: 'GET',
		});

		const body = await response.json();
		console.log("testCheckDeviceSubmission data: " + JSON.stringify(body));

		if (body.status == 'Success') {
			//this.setState({ feedbackData: body.response });
		} else {
      //ADD ERROR MESSAGE
    }

	};

	handleClick() {
		var elem = document.getElementById('replacementform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		var elem = document.getElementById('changeform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		document.getElementById('recoverform').style.display = "block";
	}

	backToLogin() {
		var elem = document.getElementById('recoverform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		var elem = document.getElementById('changeform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		document.getElementById('replacementform').style.display = "block";
		//this.toggle();
	}

	showChangeForm() {
		var elem = document.getElementById('replacementform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		var elem = document.getElementById('recoverform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		document.getElementById('changeform').style.display = "block";
	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });

		console.log('this.state.resetSuccessful: ' + this.state.resetSuccessful);
		if (this.state.resetSuccessful) {
			this.showChangeForm();
		}
	}

	toggle2() {
	  this.setState({
	    modal2: !this.state.modal2
	  });

		if (this.state.resetConfirmSuccessful) {
			this.backToLogin();
		}
	}

	handleChange(event) {
		if (event.target.getAttribute('name') == 'recoverformEmail') {

			let emailStr = event.target.value
			var noSpacesString= emailStr.replace(/ /g,'');
			this.setState({email: noSpacesString});
		} else if (event.target.getAttribute('name') == 'changeformCode') {
			//console.log('this.state.code: ' + event.target.value);
			this.setState({code: event.target.value});
		} else if (event.target.getAttribute('name') == 'changeformPassword') {
			//console.log('this.state.password: ' + event.target.value);
			this.setState({password: event.target.value});
		} else if (event.target.getAttribute('name') == 'changeformConfirmPassword') {
			//console.log('this.state.confirmPassword: ' + event.target.value);
			this.setState({confirmPassword: event.target.value});
		}
  }

	handleSubmit(event) {
    //alert('A email was submitted: ' + this.state.email);
		this.resetPasswordCall();
    event.preventDefault();
		this.toggle();
  }

	async resetPasswordCall(event) {
    event.preventDefault();

		//document.getElementById('changeformCode').value = "";
		//document.getElementById('changeformPassword').value = "";
		//document.getElementById('changeformConfirmPassword').value = "";

		//this.setState({ code: '', password: ''});

    try {
      await Auth.forgotPassword(this.state.email);
			this.state.resetSuccessful = true;
			console.log('sent confirmation email Successfully.');
			this.setState({ resetStatus: ''});
			this.toggle();
    } catch (error) {
			console.log('error: ' + error.message);
			this.state.resetSuccessful = false;
			this.setState({ resetStatus: error.message});
    }
  }

	async resetPasswordConfirmCall(event) {
		event.preventDefault();

		if (this.state.password == this.state.confirmPassword) {

	    try {
	      await Auth.forgotPasswordSubmit(
	        this.state.email,
	        this.state.code,
	        this.state.password
	      );
				console.log('Changed password Successfully.');
	      this.state.resetConfirmSuccessful = true;
				this.setState({ resetStatus: ''});
				this.toggle2();
	    } catch (error) {
				console.log('error: ' + error.message);
	      this.state.resetConfirmSuccessful = false;
				this.setState({ resetStatus: error.message});
	    }

		}
  }

  handleSubmit = (event) => {

		event.preventDefault();

    var validation = true;
    this.setState({ formStatus: "", formProcessing: true });

	  let firstName = event.target.FirstName.value;
    console.log(`firstName is:`, firstName);

    if (!firstName) {
      validation = false;
      this.setState({ formStatus: "First Name is Required." });
    }

    let lastName = event.target.LastName.value;
    console.log(`lastName is:`, lastName);

    if (!lastName) {
      validation = false;
      this.setState({ formStatus: "Last Name is Required." });
    }

		let address = event.target.Address.value;
    console.log(`address is:`, address);

    if (!address) {
      validation = false;
      this.setState({ formStatus: "Address is Required." });
    }

		let address2 = event.target.Address2.value;
    console.log(`address2 is:`, address2);

    let city = event.target.City.value;
    console.log(`city is:`, city);

    if (!city) {
      validation = false;
      this.setState({ formStatus: "City is Required." });
    }

    let state = event.target.State.value;
    console.log(`state is:`, state);

    if (!state) {
      validation = false;
      this.setState({ formStatus: "State is Required." });
    }

    let zip = event.target.Zip.value;
    console.log(`zip is:`, zip);

    if (!zip) {
      validation = false;
      this.setState({ formStatus: "Zip is Required." });
    }

    let email = event.target.Email.value;
    console.log(`email is:`, email);

    if (!email) {
      validation = false;
      this.setState({ formStatus: "Email is Required." });
    }

    let batch = event.target.Batch.value;
    console.log(`batch is:`, batch);

    if (!batch) {
      validation = false;
      this.setState({ formStatus: "Gemini EVO Lot is Required." });
    }

    let serialNumber = event.target.SerialNumber.value;
    console.log(`serialNumber is:`, serialNumber);

    if (!serialNumber) {
      validation = false;
      this.setState({ formStatus: "Gemini EVO Serial Number is Required." });
    }

    if (serialNumber.length > 5 || serialNumber.length < 5) {
      validation = false;
      this.setState({ formStatus: "Gemini EVO Serial Number is 5 digits. Please include any zeros before the number." });
    }

    var formData = {
			FirstName: firstName,
			LastName: lastName,
      Address: address,
			Address2: address2,
      City: city,
      State: state,
      Zip: zip,
      Email: email,
      Batch: batch,
      SerialNumber: serialNumber,
    };

    // formData = {
		// 	FirstName: "Ryan",
		// 	LastName: "Stickel",
    //   Address: "5309 Pine Grove Ave.",
    //   City: "Shasta Lake",
    //   State: "CA",
    //   Zip: "96019",
    //   Email: "rstickel@azenamedical.com",
    //   Batch: batch,
    //   SerialNumber: serialNumber,
    // };


    console.log("saved formData is:", JSON.stringify(formData));

    if (validation) {
      this.submitForm(formData);
    } else {
      this.setState({ formProcessing: false });
    }

	}

  submitForm = async (formData) => {

    var url = "https://hui6wcvz1i.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"footpedal-replacement-submission";

		try {

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(formData)
			});

			const body = await response.json();
			console.log("submitForm response: " + JSON.stringify(body));

			if (body.status == 'Success') {

	      this.setState({formHash: body.hash});
				await this.sendEmailToCustomer(body.hash);
	      this.sendEmailToSupport(body.hash);

			} else {

				//this.state.noDataForDevices = true;

	      if (body.error=="Serial and/or Lot do not qualify.") {
	        this.goToResults(2);
	      } else {
	        this.setState({ formStatus: body.error });
	      }

			}

	    this.setState({ formProcessing: false });

    } catch (error) {
			console.log('error: ' + error.message);
    }

	};

  sendEmailToSupport = async (hash) => {

    var formData = {
			hash: hash,
    };

    var url = "https://hui6wcvz1i.execute-api.us-west-2.amazonaws.com/v1/send-email-to-support";

    try {

  		const response = await fetch(url, {
  			method: 'POST',
  			headers: {
  				'Content-Type': 'application/json'
  			},
  			body: JSON.stringify(formData)
  		});

  		const body = await response.json();
  		console.log("sendEmailToSupport response: " + JSON.stringify(body));

  		if (body.status == 'Success') {
  			//.this.setState({formHash: body.hash});

        this.goToResults(1);

  		} else {
  			//this.state.noDataForDevices = true;
  		}

    } catch (err) {
			console.log(err);
		}

	};

	sendEmailToCustomer = async (hash) => {

    var formData = {
			hash: hash,
    };

    var url = "https://hui6wcvz1i.execute-api.us-west-2.amazonaws.com/v1/send-email-to-customer";

    try {

  		const response = await fetch(url, {
  			method: 'POST',
  			headers: {
  				'Content-Type': 'application/json'
  			},
  			body: JSON.stringify(formData)
  		});

  		const body = await response.json();
  		console.log("sendEmailToCustomer response: " + JSON.stringify(body));

  		if (body.status == 'Success') {
  			//.this.setState({formHash: body.hash});



  		} else {
  			//this.state.noDataForDevices = true;
  		}

    } catch (err) {
			console.log(err);
		}

	};


	render() {
		return <div className="">
			{/*--------------------------------------------------------------------------------*/}
			{/*Login Cards*/}
			{/*--------------------------------------------------------------------------------*/}
			<div className="auth-wrapper d-flex no-block justify-content-center align-items-center" style={sidebarBackground}>
				<div className="auth-box-2 on-sidebar" style={formBackground}>
          <div style={{ height: "200px"}}>
          </div>
					<div>
            <Row>
              <Col>
                <h3 className="pb-4" style={{ color: "#858585" }}>Really?</h3>
              </Col>
						</Row>
            <Row>
              <Col>
                <h4 className="pb-4" style={{ color: "#4f4e4e" }}>Did you believe that? A video? It will be worth the wait.</h4>
              </Col>
						</Row>
            <Row>
              <Col>
                <h4 className="" style={{ color: "#4f4e4e" }}>We promise!</h4>
              </Col>
						</Row>
					</div>
				</div>
			</div>
		</div>;
	}
}

export default RejoyView;
