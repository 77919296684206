import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class FirmwareManagementList extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		var connectionTimeout;

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			currentUser,
			rSelected: 3,
			totalProcedures: 0,
			modal: false,
      modal2: false,
			modal4: false,
			modal5: false,
			modal6: false,
			modal7: false,
      dropdownOpen: false,
			currentUser,
			testingFirmware: [],
			testingFirmwareUserItems: [],
      pendingFirmware: [],
			approvedFirmware: [],
			devices: [],
			devicesIcons: [],
      roles: [],
      obj: {},
			statusSubject: "Failed",
			statusMessage: "Failed to send message.",
			deviceInfo: [],
			userPhoneNumber: "",
			allowSMS: false,
			shipmentDate: Moment().format('YYYY-MM-DD'),
			userRole: parseInt(currentUser.payload["custom:fw_approver"]),
			approvedUsersData: [],
			selectedTestingStatus: 0,
			submitTestTitle: "Complete Testing",
			firmwareTestingComplete: false,
			firmwareApprovedMask: 0,
			firmwareApprovalUsers: [],
			batchList: ["", "Mercury", "Venus", "EARTH", "Falcon"],
			kitList: ["", "US", "Demo", "Intl"]
	  };

		this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		this.toggle3 = this.toggle3.bind(this);
		this.toggle4 = this.toggle4.bind(this);
		this.toggle5 = this.toggle5.bind(this);
		this.toggle6 = this.toggle6.bind(this);
		this.toggle7 = this.toggle7.bind(this);
		this.getData = this.getData.bind(this);


  }

	async componentDidMount() {

		console.log("userRole: "+this.state.userRole);

		this.getApprovedUsersData();
		this.intervalID = setInterval(this.getData, 3000);
	}

	componentWillUnmount() {
		clearInterval(this.intervalID);
	}

	toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	toggle2() {
	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

	toggle3() {
	  this.setState({
	    modal3: !this.state.modal3
	  });
	}

	toggle4() {
	  this.setState({
	    modal4: !this.state.modal4
	  });
	}

	toggle5() {
	  this.setState({
	    modal5: !this.state.modal5
	  });
	}

	toggle6() {
	  this.setState({
	    modal6: !this.state.modal6
	  });
	}

	toggle7() {
	  this.setState({
	    modal7: !this.state.modal7
	  });
	}

	handleDateChange(event) {
		if (event.target.getAttribute('name') == 'shipmentdate') {
			this.setState({shipmentDate: event.target.value});
		}
  }

	getData() {
		this.getFirmwareData();
	}

	getApprovedUsersData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-approved-users";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("approvedUsersData: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ approvedUsersData: body.response });

		} else {
      //ADD ERROR MESSAGE
    }

	};

	getFirmwareData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-testing-firmware-updates";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("testing firmware: " + JSON.stringify(body));

		let testingFirmware = [];

		if (body.status == 'Success') {
			testingFirmware = body.response;
			this.setState({ testingFirmware: testingFirmware[0] });
		} else {
      //ADD ERROR MESSAGE
			//this.setState({ testingFirmware: [] });
    }

		if (testingFirmware.length>0) {

			console.log("testingFirmware: " + JSON.stringify(testingFirmware));

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-firmware-testing?Version="+testingFirmware[0].version+"&FirmwareID="+testingFirmware[0].ID;

			console.log("firmware testing url: " + url);

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("firmware testing user list: " + JSON.stringify(body));

			if (body.status == 'Success') {
				this.setState({ testingFirmwareUserItems: body.response });

				var userItems = body.response;
				var usersTested = 0;
				var usersSkipped = 0;
				var testingRejected = false;
				var approvedMask = 0;
				var firmwareApprovalUsers = [];

				for (var i=0; i<userItems.length; i++) {

					if (userItems[i].TestingStatus==3) {
						usersTested++;
						approvedMask = approvedMask+userItems[i].UserApproveValue;
						firmwareApprovalUsers.push(userItems[i]);
					} else if (userItems[i].TestingStatus==1) {
						usersSkipped++;
					} else if (userItems[i].TestingStatus==2) {
						testingRejected = true;
					}

				}

				if ((usersTested==userItems.length || usersTested==(userItems.length-usersSkipped)) && !testingRejected) {
					this.setState({ firmwareTestingComplete: true, firmwareApprovedMask: approvedMask, firmwareApprovalUsers });
					console.log("approvedMask: " + approvedMask);
				} else {
					this.setState({ firmwareTestingComplete: false });
				}

				//firmwareTestingComplete
			} else {
	      //ADD ERROR MESSAGE
				this.setState({ testingFirmwareUserItems: [], firmwareTestingComplete: false, firmwareApprovalUsers: [] });
	    }
		} else {
			this.setState({ testingFirmwareUserItems: [], firmwareTestingComplete: false, firmwareApprovalUsers: [] });
		}

		var url1 = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-pending-firmware-updates";

		const response1 = await fetch(url1, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body1 = await response1.json();
		console.log("pending firmware: " + JSON.stringify(body1));

		if (body1.status == 'Success') {
			this.setState({ pendingFirmware: body1.response });
		} else {
      //ADD ERROR MESSAGE
			this.setState({ pendingFirmware: [] });
    }

		var url2 = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-completed-firmware-updates";

		const response2 = await fetch(url2, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body2 = await response2.json();
		console.log("approved firmware: " + JSON.stringify(body2));

		if (body2.status == 'Success') {
			this.setState({ approvedFirmware: body2.response });
		} else {
      //ADD ERROR MESSAGE
			this.setState({ approvedFirmware: [] });
    }

	}

  handleChange = (selectedOption) => {
    console.log(`Option selected:`, selectedOption.target.value);
    console.log(`obj is:`, this.state.obj);
    this.state.obj.role = selectedOption.target.value;
    console.log(`new obj is:`, this.state.obj);
  }

	// program to convert decimal to binary
	convertToBinary(x) {
		//console.log(`digit: `+x);
		let bin = 0;
		let rem, i = 1, step = 1;
		while (x != 0) {
				rem = x % 2;
				// console.log(
				// 		`Step ${step++}: ${x}/2, Remainder = ${rem}, Quotient = ${parseInt(x/2)}`
				// );
				x = parseInt(x / 2);
				bin = bin + rem * i;
				i = i * 10;
		}
		//console.log(`Binary: `+bin);

		return bin.toString();
	}

	print(x) {
		console.log(`print digit: `+x);
	}

	sendFirmwareRejectionEmail = async (obj) => {

		let firmwareType = "Firmware";
		let name = this.state.currentUser.payload.given_name + " " + this.state.currentUser.payload.family_name;

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"send-firmware-rejection-email?FirmwareType="+firmwareType+"&FirmwareVersion="+obj.version+"&RejectorName="+name;

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("rejection email response: " + JSON.stringify(body));
	}

	updateFirmwareApproval(obj) {

		let isRejection = false;
		let revisedApproval = 0;

		var userItem = obj.approvalStatusArray.find(u => u.userEmail === this.state.currentUser.payload.email);

		console.log("updateFirmwareApproval obj: " + JSON.stringify(userItem));
		//console.log("updateFirmwareApproval obj: " + JSON.stringify(userItem));

		if (userItem) {

			if (userItem.state=="Waiting") {
				revisedApproval = obj.approved + userItem.userApproveValue;
			} else {
				revisedApproval = obj.approved - userItem.userApproveValue;
				isRejection = true;
			}

			console.log("obj.firmwareID: " + JSON.stringify(obj.firmwareID));
			console.log("revisedApproval: " + revisedApproval);
			console.log("isRejection: " + isRejection);

			this.updateFirmware(obj, revisedApproval, isRejection);

		}

	}

	updateFirmware = async (obj, revisedApproval, isRejection) => {

		console.log("New Approval obj is: "+obj);
		console.log("New Approval Value is: "+revisedApproval);

		if (obj.tableData==1 || obj.tableData==2) {

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"update-firmware-approval?UpdateID="+obj.firmwareID+"&Approval="+revisedApproval;

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("update firmware: " + JSON.stringify(body));

			if (body.status == 'Success') {

				let newObj = [];

				if (obj.tableData==1) {
					newObj = JSON.parse(JSON.stringify(this.state.pendingFirmware));
					newObj[obj.id].approved = revisedApproval;
					this.setState({ pendingFirmware: newObj });
				} else if (obj.tableData==2) {
					newObj = JSON.parse(JSON.stringify(this.state.approvedFirmware));
					newObj[obj.id].approved = revisedApproval;
					this.setState({ approvedFirmware: newObj });
				}

				this.getFirmwareData();

				if (isRejection) {
					//this.sendFirmwareRejectionEmail(obj);
				}

			} else {
	      //ADD ERROR MESSAGE
	    }

		}

	}

	updateFirmwareTested = async () => {

		let tested = 1;

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"update-firmware-tested?UpdateID="+this.state.testingFirmware.ID+"&Tested="+tested+"&Aborted=0";
		console.log("updateFirmwareTested url: " + url);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("update firmware: " + JSON.stringify(body));

		if (body.status == 'Success') {

			this.getFirmwareData();

		} else {
			//ADD ERROR MESSAGE
		}

	}

	updateFirmwareTestedAsAborted = async () => {

		let tested = 0;

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"update-firmware-tested?UpdateID="+this.state.testingFirmware.ID+"&Tested="+tested+"&Aborted=1";
		console.log("updateFirmwareTested url: " + url);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("update firmware: " + JSON.stringify(body));

		if (body.status == 'Success') {

			this.getFirmwareData();

		} else {
			//ADD ERROR MESSAGE
		}

	}

	abortTesting = async () => {

		for (var i=0; i<this.state.testingFirmwareUserItems.length; i++) {

			console.log("testingID: " + JSON.stringify(this.state.testingFirmwareUserItems[i].ID));
			//this.deleteFirmwareTestingItem(this.state.testingFirmwareUserItems[i].ID);
			await this.abortTestingForUser(this.state.testingFirmwareUserItems[i].ID);

		}

		this.updateFirmwareTestedAsAborted();
		this.toggle6();
		//this.getFirmwareData();
	}

	abortTestingForUser = async (testID) => {

		var requestBody = {
				TestID: testID,
				Comments:"aborted",
				TestingStatus:4
			}

		console.log("abortTestingForUser requestBody: " + JSON.stringify(requestBody));

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"update-firmware-testing";

		console.log("abortTestingForUser URL: " + url);

		const response = await fetch(url, {
			method: 'POST',
			headers: {
				Authorization: this.state.currentUser.jwtToken,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(requestBody)
		});

		const body = await response.json();
		console.log("abortTestingForUser response: " + JSON.stringify(body));

		if (body.status == 'Success') {

			//this.toggle4();
			//this.getData();

		} else {

			//this.toggle4();

		}

	}

	deleteFirmwareTestingItem = async (testingID) => {
		//delete-firmware-testing-item

		console.log("testingID: " + JSON.stringify(testingID));

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-firmware-testing-item?TestingID="+testingID;

		console.log("deleteFirmwareTestingItem URL: " + url);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("deleteFirmwareTestingItem response: " + JSON.stringify(body));

		if (body.status == 'Success') {

			//this.toggle2();
			//this.addUserToFirmwareTest(version, location, selectedUsers);

		} else {

			//this.toggle2();

		}
	}

	editFirmwareTest = async (event) => {

		event.preventDefault();

		console.log("editFirmwareTest");
		let location = event.target.location.value;
    console.log(`location is:`, location);
		let type = "testing";
    console.log(`type is:`, type);
		let version = event.target.version.value;
    console.log(`version is:`, version);
		let batch = event.target.batch.value;
    console.log(`batch is:`, batch);
		let kit = event.target.kit.value;
    console.log(`kit is:`, kit);

		var selectedUsersToAdd = [];

		for (var i=0; i<this.state.approvedUsersData.length; i++) {

			if (document.getElementById("Flagged-"+i)) {

				var userItem = this.state.testingFirmwareUserItems.find(f => f.UserEmail === this.state.approvedUsersData[i].Email);

				if (document.getElementById("Flagged-"+i).checked) {

					if (!userItem) {
						selectedUsersToAdd.push(this.state.approvedUsersData[i]);
					}

				} else {

					if (userItem) {
						console.log("deleteFirmwareTestingItem: " + JSON.stringify(userItem));
						this.deleteFirmwareTestingItem(userItem.ID)
					}

				}

			}

		}

		console.log("selectedUsers: " + JSON.stringify(selectedUsersToAdd));

		this.toggle5();

		var firmwareUpdateID = this.state.testingFirmware.ID;

		this.addUserToFirmwareTest(version, location, selectedUsersToAdd, firmwareUpdateID);

	};

	editFirmware = async (event) => {

		event.preventDefault();

		console.log("editFirmware");
		let type = event.target.type.value;
    console.log(`type is:`, type);
		// let serial = event.target.serial.value;
    // console.log(`serial is:`, serial);
		// let batch = event.target.batch.value;
    // console.log(`batch is:`, batch);
		// let kit = event.target.kit.value;
    // console.log(`kit is:`, kit);

		var serialnumber = "";
		if (event.target.serialnumber) {
			var serialnumber = event.target.serialnumber.value;
	    console.log(`serialnumber is:`, serialnumber);
		}

		var batch = "";
		if (event.target.batch) {
			var batch = event.target.batch.value;
	    console.log(`batch is:`, batch);
		}

		var kit = "";
		if (event.target.kit) {
			var kit = event.target.kit.value;
	    console.log(`kit is:`, kit);
		}

		var typelabel = "";
		if (type == "Recommended") {
			typelabel = "recommended";
		} else if (type == "Mandatory") {
			typelabel = "mandatory";
		} else if (type == "User Recommended") {
			typelabel = "user_recommended";
		} else if (type == "User Mandatory") {
			typelabel = "user_mandatory";
		}

		//this.toggle7();

		let firmwareID = this.state.obj.firmwareID;

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"edit-firmware?Type="+typelabel+"&SerialNumber="+serialnumber+"&FirmwareID="+firmwareID+"&Batch="+batch+"&Kit="+kit;

		console.log("editFirmware URL: " + url);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("editFirmware response: " + JSON.stringify(body));

		if (body.status == 'Success') {

			//let firmwareUpdateID = body.response.insertId;

			this.toggle7();
			//this.addUserToFirmwareTest(version, location, selectedUsers, firmwareUpdateID);

		} else {

			this.toggle7();

		}

	}

	createFirmwareTest = async (event) => {

		event.preventDefault();

		console.log("createFirmwareTest");
		let location = event.target.location.value;
    console.log(`location is:`, location);
		let type = "testing";
    console.log(`type is:`, type);
		let version = event.target.version.value;
    console.log(`version is:`, version);
		// let batch = event.target.batch.value;
    // console.log(`batch is:`, batch);
		// let kit = event.target.kit.value;
    // console.log(`kit is:`, kit);

		var selectedUsers = [];
		var approvedMask = 0;

		for (var i=0; i<this.state.approvedUsersData.length; i++) {

			if (document.getElementById("Flagged-"+i)) {

				if (document.getElementById("Flagged-"+i).checked) {
					selectedUsers.push(this.state.approvedUsersData[i]);
					approvedMask = approvedMask+this.state.approvedUsersData[i].ApproveValue;
				}

			}

		}

		console.log("selectedUsers: " + JSON.stringify(selectedUsers));

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-firmware-update?Version="+version+"&Type="+type+"&Location="+location+"&ApprovedMask="+approvedMask;

		console.log("createFirmwareTest URL: " + url);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("createFirmwareTest response: " + JSON.stringify(body));

		if (body.status == 'Success') {

			let firmwareUpdateID = body.response.insertId;

			this.toggle2();
			this.addUserToFirmwareTest(version, location, selectedUsers, firmwareUpdateID);

		} else {

			this.toggle2();

		}

	};

	addUserToFirmwareTest = async (version, location, selectedUsers, firmwareUpdateID) => {

		for (var i=0; i<selectedUsers.length; i++) {

			let requestBody = {
					Verion:version,
					Location:location,
					UserName:selectedUsers[i].Name,
					UserEmail:selectedUsers[i].Email,
					ApprovedUserID:selectedUsers[i].ID,
					UserApproveValue:selectedUsers[i].ApproveValue,
					FirmwareUpdateID: firmwareUpdateID
				}

			console.log("addUserToFirmwareTest requestBody: " + JSON.stringify(requestBody));

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-firmware-testing";

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: this.state.currentUser.jwtToken,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(requestBody)
			});

			const body = await response.json();
			console.log("addUserToFirmwareTest response: " + JSON.stringify(body));

			if (body.status == 'Success') {
				//this.setState({ repairCategories: body.response });
				//console.log("insertId: " + JSON.stringify(body.response.insertId));

			} else {
				//ADD ERROR MESSAGE
			}

		}

		this.getData();

	};

	submitTesting = async (event) => {

		event.preventDefault();

		console.log("submitTesting");
		let comments = event.target.comments.value;
    console.log(`comments is:`, comments);

		let requestBody = {
				Comments:comments,
				TestingStatus:this.state.selectedTestingStatus,
				TestID: this.state.obj.firmwareID
			}

		console.log("submitTesting requestBody: " + JSON.stringify(requestBody));

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"update-firmware-testing";

		console.log("submitTesting URL: " + url);

		const response = await fetch(url, {
			method: 'POST',
			headers: {
				Authorization: this.state.currentUser.jwtToken,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(requestBody)
		});

		const body = await response.json();
		console.log("submitTesting response: " + JSON.stringify(body));

		if (body.status == 'Success') {

			this.toggle4();
			this.getData();

		} else {

			this.toggle4();

		}

	};

	createFirmwareUpdate = async (event) => {

		event.preventDefault();

		console.log("createFirmwareUpdate");
		let location = event.target.location.value;
    console.log(`location is:`, location);
		let type = event.target.type.value;
    console.log(`type is:`, type);
		let version = event.target.version.value;
    console.log(`version is:`, version);

		var serialNumber = "";
		if (event.target.serialnumber) {
			var serialNumber = event.target.serialnumber.value;
	    console.log(`serialNumber is:`, serialNumber);
		}

		var batch = "";
		if (event.target.batch) {
			var batch = event.target.batch.value;
	    console.log(`batch is:`, batch);
		}

		var kit = "";
		if (event.target.kit) {
			var kit = event.target.kit.value;
	    console.log(`kit is:`, kit);
		}

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-firmware-update?Version="+version+"&Type="+type+"&Location="+location+"&ApprovedMask="+this.state.firmwareApprovedMask+"&Batch="+batch+"&Kit="+kit;

		if (serialNumber != "") {
			url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"create-firmware-update?Version="+version+"&Type="+type+"&Location="+location+"&ApprovedMask="+this.state.firmwareApprovedMask+"&Batch="+batch+"&Kit="+kit+"&SerialNumber="+serialNumber;
		}

		console.log("createFirmwareUpdate URL: " + url);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("createFirmwareUpdate response: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.toggle();
			//this.getData();
			this.updateFirmwareTested();

		} else {

			this.toggle();

		}

	};

	deleteFirmwareUpdate = async () => {

		console.log("deleteFirmwareUpdate");

    console.log(`updateID is:`, this.state.obj.firmwareID);

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"delete-firmware-update?UpdateID="+this.state.obj.firmwareID;

		console.log("deleteFirmwareUpdate URL: " + url);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("deleteFirmwareUpdate response: " + JSON.stringify(body));

		if (body.status == 'Success') {

			this.toggle3();
			this.getData();

		} else {

			//this.toggle2();

		}

	};

	render() {

		const { userRole } = this.state;

		const data = this.state.testingFirmwareUserItems.map((firmwareTest, key) => {

			console.log("data firmwareTest: " + JSON.stringify(firmwareTest));

			var isCurrectUser = false;

			if (firmwareTest.UserEmail == this.state.currentUser.payload.email) {
				isCurrectUser = true;
			}

			return {
				id: key,
				firmwareID: firmwareTest.ID,
				userName: firmwareTest.UserName,
				userEmail: firmwareTest.UserEmail,
				version: firmwareTest.Version,
				location: firmwareTest.Location,
				comments: firmwareTest.Comments,
				testingStatus: firmwareTest.TestingStatus,
				tableData: 0,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						{/* use this button to remove the data row */}
						{isCurrectUser &&
							<Fragment>
							<Button
								className="ml-1 mr-1"
								size="sm"
								onClick={() => {
									let obj = data.find(o => o.id === key);
	                console.log('button obj: ' + obj);
									this.setState({
										obj: obj,
										modal4: !this.state.modal4,
										selectedTestingStatus: 3,
										submitTestTitle: "Complete Testing"
									});
									//this.takeAction(obj);
								}}
								color="brightgreen"
								round="true"
								icon="true"
							>
								Complete
							</Button>
							<Button
								className="ml-1 mr-1"
								size="sm"
								onClick={() => {
									let obj = data.find(o => o.id === key);
	                console.log('button obj: ' + obj);
									this.setState({
										obj: obj,
										modal4: !this.state.modal4,
										selectedTestingStatus: 1,
										submitTestTitle: "Skip Testing"
									});
									//this.takeAction(obj);
								}}
								color="white"
								round="true"
								icon="true"
							>
								Skip
							</Button>
							<Button
								className="ml-1 mr-1"
								size="sm"
								onClick={() => {
									let obj = data.find(o => o.id === key);
	                console.log('button obj: ' + obj);
									this.setState({
										obj: obj,
										modal4: !this.state.modal4,
										selectedTestingStatus: 2,
										submitTestTitle: "Reject Testing Version"
									});
									//this.takeAction(obj);
								}}
								color="red"
								round="true"
								icon="true"
							>
								Reject
							</Button>
							</Fragment>
						}
					</div>
				)
			};
		});

		const data1 = this.state.pendingFirmware.map((firmware, key) => {

			var typelabel = "";
			if (firmware.type == "recommended") {
				typelabel = "Recommended";
			} else if (firmware.type == "mandatory") {
				typelabel = "Mandatory";
			} else if (firmware.type == "user_recommended") {
				typelabel = "User Recommended";
			} else if (firmware.type == "user_mandatory") {
				typelabel = "User Mandatory";
			}

			var binary = this.convertToBinary(firmware.approved);
			var approvedMaskBinary = this.convertToBinary(firmware.approved_mask);

			//console.log("binary: " + binary);
			//console.log("approvedMaskBinary: " + approvedMaskBinary);

			var approvalStatusArray = [];
			var approvalStatusString = ""
			var includesCurrectUser = false;
			var buttonTitle = "Approve";
			var buttonColor = "brightgreen";

			for (var i = 0; i < this.state.approvedUsersData.length; i++){

				//UserApproveValue
				var userBinary = this.convertToBinary(this.state.approvedUsersData[i].ApproveValue);
				//console.log("userBinary: " + userBinary);
				var userStatus = {};

				//console.log("binary length: " + binary.length);
				//console.log("approvedMaskBinary length: " + approvedMaskBinary.length);
				//console.log("userBinary length: " + userBinary.length);
				//console.log("Name: " + this.state.approvedUsersData[i].Name);

				var indexBinary = binary.length - userBinary.length;
				var indexMask = approvedMaskBinary.length - userBinary.length;

				if (userBinary.length<=approvedMaskBinary.length) {

					if (approvedMaskBinary.charAt(indexMask)==1) {

						//console.log("indexBinary: " + indexBinary);
						//console.log("charAt indexBinary: " + binary.charAt(indexBinary));
						//var index = 4 - 3;
						if (binary.charAt(indexBinary)==1) {
							userStatus = { userApproveValue: this.state.approvedUsersData[i].ApproveValue, userEmail: this.state.approvedUsersData[i].Email, state: "Approved", buttonColor: "red" };
							approvalStatusString = approvalStatusString+this.state.approvedUsersData[i].Name+": Approved\n";

							if (this.state.approvedUsersData[i].Email == this.state.currentUser.payload.email) {
								includesCurrectUser = true;
								buttonTitle = "Reject";
								buttonColor = "red"
							}

						} else {
							userStatus = { userApproveValue: this.state.approvedUsersData[i].ApproveValue, userEmail: this.state.approvedUsersData[i].Email, state: "Waiting", buttonColor: "brightgreen" };
							approvalStatusString = approvalStatusString+this.state.approvedUsersData[i].Name+": Waiting\n";

							if (this.state.approvedUsersData[i].Email == this.state.currentUser.payload.email) {
								includesCurrectUser = true;
							}
						}

						approvalStatusArray.push(userStatus);

					}

				}

			}

			console.log("data1 approvalStatusArray: " + JSON.stringify(approvalStatusArray));

			return {
				id: key,
				firmwareID: firmware.ID,
				version: firmware.version,
				type: typelabel,
				batch: firmware.batch,
				kit: firmware.kit,
				serialNumber: firmware.serial_num,
				approved: firmware.approved,
				approvalStatusString: approvalStatusString,
				approvalStatusArray: approvalStatusArray,
				location: firmware.location,
				tableData: 1,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						{/* use this button to remove the data row */}
						{includesCurrectUser &&
							<Fragment>
								<Button
									onClick={() => {
										let obj = data1.find(o => o.id === key);
										console.log('button obj: ' + obj.firmwareID);
										this.setState({
											obj: obj
										});
										this.updateFirmwareApproval(obj);
									}}
									color={buttonColor}
									round="true"
									icon="true"
								>
								{buttonTitle}
								</Button>
								<Button
									onClick={() => {
										let obj = data1.find(o => o.id === key);
										console.log('button obj: ' + obj);
										this.setState({
											modal7: !this.state.modal7,
											obj: obj
										});
									}}
									color="red"
									round="true"
									icon="true"
									className="ml-2"
								>
									<i className="fa fa-edit" />
								</Button>
								<Button
									onClick={() => {
										let obj = data1.find(o => o.id === key);
										console.log('button obj: ' + obj);
										this.setState({
											modal3: !this.state.modal3,
											obj: obj
										});
									}}
									color="red"
									round="true"
									icon="true"
									className="ml-2"
								>
									<i className="fa fa-trash-alt" />
								</Button>
							</Fragment>
						}

					</div>
				)
			};
		});

		const data2 = this.state.approvedFirmware.map((firmware, key) => {

			var typelabel = "";
			if (firmware.type == "recommended") {
				typelabel = "Recommended";
			} else if (firmware.type == "mandatory") {
				typelabel = "Mandatory";
			} else if (firmware.type == "user_recommended") {
				typelabel = "User Recommended";
			} else if (firmware.type == "user_mandatory") {
				typelabel = "User Mandatory";
			}

			var binary = this.convertToBinary(firmware.approved);
			var approvedMaskBinary = this.convertToBinary(firmware.approved_mask);

			//console.log("binary: " + binary);

			var approvalStatusArray = [];
			var approvalStatusString = ""
			var includesCurrectUser = false;
			var buttonTitle = "Approve";
			var buttonColor = "brightgreen";

			for (var i = 0; i < this.state.approvedUsersData.length; i++){

				//UserApproveValue
				var userBinary = this.convertToBinary(this.state.approvedUsersData[i].ApproveValue);
				//console.log("userBinary: " + userBinary);
				var userStatus = {};

				//console.log("binary length: " + binary.length);
				//console.log("approvedMaskBinary length: " + approvedMaskBinary.length);
				//console.log("userBinary length: " + userBinary.length);
				//console.log("Name: " + this.state.approvedUsersData[i].Name);

				var indexBinary = binary.length - userBinary.length;
				var indexMask = approvedMaskBinary.length - userBinary.length;

				if (userBinary.length<=approvedMaskBinary.length) {

					if (approvedMaskBinary.charAt(indexMask)==1) {

						//console.log("indexBinary: " + indexBinary);
						//console.log("charAt indexBinary: " + binary.charAt(indexBinary));
						//var index = 4 - 3;
						if (binary.charAt(indexBinary)==1) {
							userStatus = { userApproveValue: this.state.approvedUsersData[i].ApproveValue, userEmail: this.state.approvedUsersData[i].Email, state: "Approved", buttonColor: "red" };
							approvalStatusString = approvalStatusString+this.state.approvedUsersData[i].Name+": Approved\n";

							if (this.state.approvedUsersData[i].Email == this.state.currentUser.payload.email) {
								includesCurrectUser = true;
								buttonTitle = "Reject";
								buttonColor = "red"
							}

						} else {
							userStatus = { userApproveValue: this.state.approvedUsersData[i].ApproveValue, userEmail: this.state.approvedUsersData[i].Email, state: "Waiting", buttonColor: "brightgreen" };
							approvalStatusString = approvalStatusString+this.state.approvedUsersData[i].Name+": Waiting\n";

							if (this.state.approvedUsersData[i].Email == this.state.currentUser.payload.email) {
								includesCurrectUser = true;
							}
						}

						approvalStatusArray.push(userStatus);

					}

				}

			}

			console.log("data2 approvalStatusArray: " + JSON.stringify(approvalStatusArray));

			return {
				id: key,
				firmwareID: firmware.ID,
				version: firmware.version,
				type: typelabel,
				batch: firmware.batch,
				kit: firmware.kit,
				serialNumber: firmware.serial_num,
				approved: firmware.approved,
				approvalStatusString: approvalStatusString,
				approvalStatusArray: approvalStatusArray,
				location: firmware.location,
				tableData: 2,
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						{/* use this button to remove the data row */}
						{includesCurrectUser &&
							<Fragment>
							<Button
								onClick={() => {
									let obj = data2.find(o => o.id === key);
									console.log('button obj: ' + obj);
									this.setState({
										obj: obj
									});
									this.updateFirmwareApproval(obj);
								}}
								color={buttonColor}
								round="true"
								icon="true"
							>
							{buttonTitle}
							</Button>
							<Button
								onClick={() => {
									let obj = data2.find(o => o.id === key);
									console.log('button obj: ' + obj);
									this.setState({
										modal3: !this.state.modal3,
										obj: obj
									});
								}}
								color="red"
								round="true"
								icon="true"
								className="ml-2"
							>
								<i className="fa fa-trash-alt" />
							</Button>
							</Fragment>
						}

					</div>
				)
			};
		});

    const filterCaseInsensitive = (filter, row) => {
           const id = filter.pivotId || filter.id;
           if (row[id] !== null){
               return (
                   row[id] !== undefined ?
                       String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
                       :
                       true
               );
           }
       };

		return (
			<Fragment>
			<Row>
				<Col md="12">
					<Card className="card-hover">
						<CardBody>
							<Row className="mb-3">
								<Col md="6">
									<h2 style={{color:'#737782'}}>Firmware Testing</h2>
								</Col>
								<Col md="6">
									{this.state.testingFirmwareUserItems.length==0 ? (
										<Button color="dark" className="ml-1 float-right" onClick={this.toggle2}>Add New Firmware Test</Button>
									) : (
										<Fragment>
											<Button color="red" className="ml-1 float-right" onClick={this.toggle6}>Abort</Button>
											<Button color="dark" className="ml-1 float-right" onClick={this.toggle5}>Edit</Button>
										</Fragment>
									)}
								</Col>
							</Row>
							<ReactTable
		            style={{backgroundColor:'#ffc062', padding:'10px', color: '#000'}}
								columns={[
									{
										Header: "Tested",
										accessor: "testingStatus",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
												<div style={{ textAlign: "center" }}>
													{row.value==1 &&
														<i style={{color: '#fff'}} className="mdi mdi-skip-next-circle-outline mdi-24px"/>
													}
													{row.value==2 &&
														<i style={{color: '#dc3545'}} className="mdi mdi-alert-circle-outline mdi-24px"/>
													}
													{row.value==3 &&
														<i style={{color: '#21c603'}} className="mdi mdi-checkbox-marked-outline mdi-24px"/>
													}
												</div>
		                  )
									},
									{
										Header: "User Name",
										accessor: "userName",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
		                    <div style={{ textAlign: "center" }}>{row.value}</div>
		                  )
									},
									{
										Header: "User Email",
										accessor: "userEmail",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
		                    <div style={{ textAlign: "center" }}>{row.value}</div>
		                  )
									},
									{
										Header: "Version",
										accessor: "version",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
		                    <div style={{ textAlign: "center" }}>{row.value}</div>
		                  )
									},
									{
										Header: "Location",
										accessor: "location",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
		                    <div style={{ textAlign: "center" }}>{row.value}</div>
		                  )
									},
									{
										Header: "Comments",
										accessor: "comments",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center', 'whiteSpace': 'unset'},
										Cell: row => (
												<Fragment>
												{row.value ? (
													<Fragment>
													{row.value.length > 45 ? (
														<div style={{ textAlign: "justify", height: "42px", overflow: "auto" }}>{row.value}</div>
													) : (
														<div style={{ textAlign: "center"}}>{row.value}</div>
													)}
													</Fragment>
												) : (
													<div style={{ textAlign: "center" }}>No Comments</div>
												)}
												</Fragment>
		                  )
									},
									{
										Header: "Actions",
										accessor: "actions",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										minWidth: 150,
										maxWidth: 200,
										sortable: false,
										filterable: false
									}
								]}
								defaultPageSize={4}
								showPaginationBottom={true}
								className="-striped -highlight"
								data={data}
								id="table0"
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col md="12">
					<Card className="card-hover">
						<CardBody>
							<Row className="mb-3">
								<Col md="6">
									<h2 style={{color:'#737782'}}>Firmware Pending</h2>
								</Col>
								<Col md="6">
									{this.state.firmwareTestingComplete &&
										<Button color="dark" className="ml-1 float-right" onClick={this.toggle}>Add New Firmware Update</Button>
									}
								</Col>
							</Row>
							<ReactTable
		            style={{backgroundColor:'#6dce80', padding:'10px', color: '#000'}}
								columns={[
									{
										Header: "Version",
										accessor: "version",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
		                    <div style={{ textAlign: "center" }}>{row.value}</div>
		                  )
									},
									{
										Header: "Type",
										accessor: "type",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
		                    <div style={{ textAlign: "center" }}>{row.value}</div>
		                  )
									},
									{
										Header: "Batch",
										accessor: "batch",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
		                    <div style={{ textAlign: "center" }}>{row.value}</div>
		                  )
									},
									{
										Header: "Kit",
										accessor: "kit",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
		                    <div style={{ textAlign: "center" }}>{row.value}</div>
		                  )
									},
									{
										Header: "Serial Number",
										accessor: "serialNumber",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
		                    <div style={{ textAlign: "center" }}>{row.value}</div>
		                  )
									},
									{
										Header: "Approved",
										accessor: "approvalStatusString",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
												<Fragment>
												{row.value.split("\n").map(function(item) {
												  return (
														<Fragment>
												    <div style={{ textAlign: "center" }}>{item}</div>
														</Fragment>
												  )
												})}
												</Fragment>

		                  )
									},
									{
										Header: "Location",
										accessor: "location",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
		                    <div style={{ textAlign: "center" }}>{row.value}</div>
		                  )
									},
									{
										Header: "Actions",
										accessor: "actions",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										minWidth: 150,
										maxWidth: 200,
										sortable: false,
										filterable: false
									}
								]}
								defaultPageSize={3}
		            sortable={true}
								showPaginationBottom={true}
								className="-striped -highlight"
								data={data1}
								filterable
								id="table1"
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col md="12">
					<Card className="card-hover">
						<CardBody>
							<Row className="mb-3">
								<Col md="6">
									<h2 style={{color:'#737782'}}>Firmware Approved</h2>
								</Col>
							</Row>
							<ReactTable
		            style={{backgroundColor:'#414755', padding:'10px'}}
								columns={[
									{
										Header: "Version",
										accessor: "version",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
		                    <div style={{ textAlign: "center" }}>{row.value}</div>
		                  )
									},
									{
										Header: "Type",
										accessor: "type",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
		                    <div style={{ textAlign: "center" }}>{row.value}</div>
		                  )
									},
									{
										Header: "Batch",
										accessor: "batch",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
		                    <div style={{ textAlign: "center" }}>{row.value}</div>
		                  )
									},
									{
										Header: "Kit",
										accessor: "kit",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
		                    <div style={{ textAlign: "center" }}>{row.value}</div>
		                  )
									},
									{
										Header: "Serial Number",
										accessor: "serialNumber",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
		                    <div style={{ textAlign: "center" }}>{row.value}</div>
		                  )
									},
									{
										Header: "Approved",
										accessor: "approvalStatusString",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
												<Fragment>
												{row.value.split("\n").map(function(item) {
												  return (
														<Fragment>
												    <div style={{ textAlign: "center" }}>{item}</div>
														</Fragment>
												  )
												})}
												</Fragment>

		                  )
									},
									{
										Header: "Location",
										accessor: "location",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										Cell: row => (
		                    <div style={{ textAlign: "center" }}>{row.value}</div>
		                  )
									},
									{
										Header: "Actions",
										accessor: "actions",
										style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
										minWidth: 150,
										maxWidth: 200,
										sortable: false,
										filterable: false
									}
								]}
								defaultPageSize={3}
		            sortable={true}
								showPaginationBottom={true}
								className="-striped -highlight"
								data={data2}
								filterable
								id="table2"
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<div>
				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>Create new Firmware Update</ModalHeader>
					<Form onSubmit={this.createFirmwareUpdate}>
					<ModalBody>
						<FormGroup>
							<Label for="version">Version:</Label>
							<Input type="text" name="version" id="version" value={this.state.testingFirmware.version} disabled/>
						</FormGroup>
						<FormGroup>
							<Label for="type">Type:</Label>
							<Input type="select" name="type" id="type">
								<option>mandatory</option>
								<option>recommended</option>
								<option>user_mandatory</option>
								<option>user_recommended</option>
							</Input>
						</FormGroup>
						{document.getElementById('type') && (document.getElementById('type').value=="mandatory" || document.getElementById('type').value=="recommended") &&
							<Fragment>
							<FormGroup>
								<Label for="batch">Batch:</Label>
								<Input type="select" name="batch" id="batch" defaultValue={this.state.obj.batch}>
									{this.state.batchList.map((batch, i) => (
												<option value={batch}>{batch}</option>
									))}
								</Input>
							</FormGroup>
							<FormGroup>
								<Label for="kit">Kit:</Label>
								<Input type="select" name="kit" id="kit" defaultValue={this.state.obj.kit}>
									{this.state.kitList.map((kit, i) => (
												<option value={kit}>{kit}</option>
									))}
								</Input>
							</FormGroup>
							</Fragment>
						}
						{document.getElementById('type') && (document.getElementById('type').value=="user_mandatory" || document.getElementById('type').value=="user_recommended") &&
							<Fragment>
							<FormGroup>
								<Label for="serialnumber">Serial Number:</Label>
								<Input type="text" name="serialnumber" id="serialnumber"/>
							</FormGroup>
							</Fragment>
						}
						<FormGroup>
							<Label for="location">Location:</Label>
							<Input type="text" name="location" id="location" value={this.state.testingFirmware.location} disabled/>
						</FormGroup>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" className="ml-1" onClick={this.toggle}>Cancel</Button>
						<Button color="primary" type="submit">Add</Button>
					</ModalFooter>
					</Form>
				</Modal>
			</div>
			<div>
				<Modal isOpen={this.state.modal2} toggle={this.toggle2}>
					<ModalHeader toggle={this.toggle2}>Create New Firmware Test</ModalHeader>
					<Form onSubmit={this.createFirmwareTest}>
					<ModalBody>
						<FormGroup>
							<Label for="version">Version:</Label>
							<Input type="text" name="version" id="version"/>
						</FormGroup>
						{
							// <FormGroup>
							// 	<Label for="batch">Batch:</Label>
							// 	<Input type="select" name="batch" id="batch" defaultValue={this.state.obj.batch}>
							// 		{this.state.batchList.map((batch, i) => (
							// 					<option value={batch}>{batch}</option>
							// 		))}
							// 	</Input>
							// </FormGroup>
							// <FormGroup>
							// 	<Label for="kit">Kit:</Label>
							// 	<Input type="select" name="kit" id="kit" defaultValue={this.state.obj.kit}>
							// 		{this.state.kitList.map((kit, i) => (
							// 					<option value={kit}>{kit}</option>
							// 		))}
							// 	</Input>
							// </FormGroup>
							// <FormGroup>
							// 	<Label for="batch">Batch:</Label>
							// 	<Input type="text" name="batch" id="batch"/>
							// </FormGroup>
							// <FormGroup>
							// 	<Label for="kit">Kit:</Label>
							// 	<Input type="text" name="kit" id="kit"/>
							// </FormGroup>
						}
						<FormGroup>
							<Label for="location">Location:</Label>
							<Input type="text" name="location" id="location"/>
						</FormGroup>
						<Row className="mt-3 mb-3">
							{this.state.approvedUsersData.map((user, key) => {
								return (

									<Col xs="6" md="6" lg="6">
										<Row>
											<Col xs="8" md="8" lg="8">
												<Label style={{fontSize:'18px'}} for={"Flagged-"+key}>{user.Name}:</Label>
											</Col>
											<Col xs="2" md="2" lg="2">
												<Input type="checkbox" name={"Flagged-"+key} id={"Flagged-"+key}/>
											</Col>
										</Row>
									</Col>

									);
							 })}
	          </Row>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" className="ml-1" onClick={this.toggle2}>Cancel</Button>
						<Button color="primary" type="submit">Add</Button>
					</ModalFooter>
					</Form>
				</Modal>
			</div>
			<div>
				<Modal isOpen={this.state.modal3} toggle={this.toggle3}>
					<ModalHeader toggle={this.toggle3}>Delete Firmware Update Record</ModalHeader>
					<ModalBody>
						<span>Are you sure you want to delete this firmware update record?</span>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" className="ml-1" onClick={this.toggle3}>Cancel</Button>
						<Button color="primary" onClick={this.deleteFirmwareUpdate}>Confirm</Button>
					</ModalFooter>
				</Modal>
			</div>
			<div>
				<Modal isOpen={this.state.modal4} toggle={this.toggle4}>
					<ModalHeader toggle={this.toggle4}>{this.state.submitTestTitle}</ModalHeader>
					<Form onSubmit={this.submitTesting}>
					<ModalBody>
						<FormGroup>
							<Label for="comments">Comments:</Label>
							<Input style={{width:'100%', height: '200px'}} type="textarea" name="comments" id="comments"/>
						</FormGroup>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" className="ml-1" onClick={this.toggle4}>Cancel</Button>
						<Button color="primary" type="submit">Submit</Button>
					</ModalFooter>
					</Form>
				</Modal>
			</div>
			<div>
				<Modal isOpen={this.state.modal5} toggle={this.toggle5}>
					<ModalHeader toggle={this.toggle5}>Edit Firmware Test</ModalHeader>
					<Form onSubmit={this.editFirmwareTest}>
					<ModalBody>
						<FormGroup>
							<Label for="version">Version:</Label>
							<Input type="text" name="version" id="version" value={this.state.testingFirmware.version} disabled/>
						</FormGroup>
						<FormGroup>
							<Label for="location">Location:</Label>
							<Input type="text" name="location" id="location" value={this.state.testingFirmware.location} disabled/>
						</FormGroup>
						<Row className="mt-3 mb-3">
							{this.state.approvedUsersData.map((user, key) => {
								return (

									<Col xs="6" md="6" lg="6">
										<Row>
											<Col xs="8" md="8" lg="8">
												<Label style={{fontSize:'18px'}} for={"Flagged-"+key}>{user.Name}:</Label>
											</Col>
											<Col xs="2" md="2" lg="2">
												<Input type="checkbox" name={"Flagged-"+key} id={"Flagged-"+key} defaultChecked={this.state.testingFirmwareUserItems.find(f => f.UserEmail === user.Email) ? true : false}/>
											</Col>
										</Row>
									</Col>

									);
							 })}
	          </Row>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" className="ml-1" onClick={this.toggle5}>Cancel</Button>
						<Button color="primary" type="submit">Confirm Change</Button>
					</ModalFooter>
					</Form>
				</Modal>
			</div>
			<div>
				<Modal isOpen={this.state.modal6} toggle={this.toggle6}>
					<ModalHeader toggle={this.toggle6}>Abort Testing Firmware</ModalHeader>
					<ModalBody>
						<span>Are you sure you want to abort testing this firmware for all users?</span>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" className="ml-1" onClick={this.toggle6}>Cancel</Button>
						<Button color="primary" onClick={this.abortTesting}>Confirm</Button>
					</ModalFooter>
				</Modal>
			</div>
			<div>
				<Modal isOpen={this.state.modal7} toggle={this.toggle7}>
					<ModalHeader toggle={this.toggle7}>Edit Firmware</ModalHeader>
					<Form onSubmit={this.editFirmware}>
					<ModalBody>
						<FormGroup>
							<Label for="type">Type:</Label>
							<Input type="select" name="type" id="type" defaultValue={this.state.obj.type}>
								<option value="Mandatory">Mandatory</option>
								<option value="Recommended">Recommended</option>
								<option value="User Mandatory">User Mandatory</option>
								<option value="User Recommended">User Recommended</option>
							</Input>
						</FormGroup>

						{document.getElementById('type') && (document.getElementById('type').value=="Mandatory" || document.getElementById('type').value=="Recommended") &&
							<Fragment>
							<FormGroup>
								<Label for="batch">Batch:</Label>
								<Input type="select" name="batch" id="batch" defaultValue={this.state.obj.batch}>
									{this.state.batchList.map((batch, i) => (
												<option value={batch}>{batch}</option>
									))}
								</Input>
							</FormGroup>
							<FormGroup>
								<Label for="kit">Kit:</Label>
								<Input type="select" name="kit" id="kit" defaultValue={this.state.obj.kit}>
									{this.state.kitList.map((kit, i) => (
												<option value={kit}>{kit}</option>
									))}
								</Input>
							</FormGroup>
							</Fragment>
						}
						{document.getElementById('type') && (document.getElementById('type').value=="User Mandatory" || document.getElementById('type').value=="User Recommended") &&
							<Fragment>
							<FormGroup>
								<Label for="serialnumber">Serial Number:</Label>
								<Input type="text" name="serialnumber" id="serialnumber"/>
							</FormGroup>
							</Fragment>
						}

					</ModalBody>
					<ModalFooter>
						<Button color="primary" className="ml-1" onClick={this.toggle7}>Cancel</Button>
						<Button color="primary" type="submit">Confirm Change</Button>
					</ModalFooter>
					</Form>
				</Modal>
			</div>
			</Fragment>
		);
	}
}

export default FirmwareManagementList;
