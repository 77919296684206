import React from "react";
import { Component, Fragment } from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import ReactPDF from '@react-pdf/renderer';
import { pdf, Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';
import {
    hashRandom,
    hashString,
    hashObject,
    hashArray
  } from 'react-hash-string'

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label,
  Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ButtonDropdown
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';
import logoEvo from '../../../assets/images/logo2_x3_text.png';
import logoEvo2 from '../../../assets/images/logo2_x3_text_clear.png';
import warrantyPDF from '../../../assets/pdf/warranty/warranty.pdf';
var moment = require('moment-timezone');

// Create styles
const styles = StyleSheet.create({
  page: { backgroundColor: '#ffffff' },
  section: { color: 'white', textAlign: 'center', margin: 30 },
  title: {
    fontSize: 20,
    marginVertical: 5,
    textAlign: 'center',
    color: '#f3c12b'
  },
  TableCellHeaderStyle : {
    padding: 5,
    textAlign: "center",
    fontSize: 14,
    fontWeight: 900,
    backgroundColor: '#000000',
    color: '#f3c12b'
  },
  TableCellBodyStyle : {
    padding: 5,
    textAlign: "center",
    fontSize: 11,
    backgroundColor: '#ffffff'
  },
  TableStyle : {
    padding: 5,
  },
  Table: {
    marginVertical: 5,
    marginHorizontal: 5,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
});

// Create Document Component
// const MyDocument = (data) => (
//   <Document>
//     <Page size="A4" style={styles.page}>
//       <Text style={styles.section} fixed>
//         ~ Created with Azena Medical Gemini EVO Dashboard ~
//       </Text>
//       <Text style={styles.title}>Procedures Report</Text>
//       <Table data={this.props.data}>
//         <TableHeader>
//             <TableCell>
//                 First Name
//             </TableCell>
//             <TableCell>
//                 Last Name
//             </TableCell>
//             <TableCell>
//                 DOB
//             </TableCell>
//             <TableCell>
//                 Country
//             </TableCell>
//             <TableCell>
//                 Phone Number
//             </TableCell>
//         </TableHeader>
//         <TableBody>
//             <DataTableCell getContent={(r) => r.serialNumber}/>
//             <DataTableCell getContent={(r) => r.date}/>
//             <DataTableCell getContent={(r) => r.procedureType}/>
//             <DataTableCell getContent={(r) => r.wavelength}/>
//             <DataTableCell getContent={(r) => r.length}/>
//         </TableBody>
//       </Table>
//     </Page>
//   </Document>
// );


class MyDocument extends React.Component {

  async componentDidMount() {

    console.log("MyDocument procedure data: " + JSON.stringify(this.props.data));
	}

  render() {
         return (
           <Document>
             <Page size="A4" style={styles.page}>
               <Image style={styles.image} src={logoEvo2} />
               <Text style={styles.title}></Text>
               <Text style={styles.title}>Procedures Report</Text>
               <Text style={styles.title}></Text>
               <Table style={styles.Table} data={this.props.data}>
                 <TableHeader style={styles.Table} textAlign={"center"}>
                     <TableCell style={styles.TableCellHeaderStyle}>
                         Date
                     </TableCell>
                     <TableCell style={styles.TableCellHeaderStyle}>
                         Procedure
                     </TableCell>
                     <TableCell style={styles.TableCellHeaderStyle}>
                         Power
                     </TableCell>
                     <TableCell style={styles.TableCellHeaderStyle}>
                         Wavelength
                     </TableCell>
                     <TableCell style={styles.TableCellHeaderStyle}>
                         Treatments
                     </TableCell>
                     <TableCell style={styles.TableCellHeaderStyle}>
                         Dosage
                     </TableCell>
                     <TableCell style={styles.TableCellHeaderStyle}>
                         Length
                     </TableCell>
										 <TableCell style={styles.TableCellHeaderStyle}>
                         Serial
                     </TableCell>
										 <TableCell style={styles.TableCellHeaderStyle}>
                         Time
                     </TableCell>
                 </TableHeader>
                 <TableBody textAlign={"center"} >
								 		<DataTableCell style={styles.TableCellBodyStyle} getContent={(p) => p.date}/>
                    <DataTableCell style={styles.TableCellBodyStyle} getContent={(p) => p.procedureType}/>
                    <DataTableCell style={styles.TableCellBodyStyle} getContent={(p) => p.power}/>
                    <DataTableCell style={styles.TableCellBodyStyle} getContent={(p) => p.wavelength}/>
                    <DataTableCell style={styles.TableCellBodyStyle} getContent={(p) => p.treatments}/>
                    <DataTableCell style={styles.TableCellBodyStyle} getContent={(p) => p.dosage}/>
                    <DataTableCell style={styles.TableCellBodyStyle} getContent={(p) => p.length}/>
										<DataTableCell style={styles.TableCellBodyStyle} getContent={(p) => p.serialNumber}/>
										<DataTableCell style={styles.TableCellBodyStyle} getContent={(p) => p.time}/>
                 </TableBody>
               </Table>
             </Page>
           </Document>
     )}
}

class ProceduresList extends React.Component {

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 3,
			totalProcedures: 0,
      dropdownOpen: false,
      currentDataSetting: 5,
      startDate: Moment().subtract(1, 'month').format('YYYY-MM-DD'),
			endDate: Moment().format('YYYY-MM-DD'),
			currentDate: Moment().format('YYYY-MM-DD'),
			isOpen: false,
			currentItem: "Last 30 Days",
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
      procedures: [],
      procedureTypes: [],
      wavelengthTypes: ["UNKNOWN", "810", "980", "DUAL"],
      obj: {},
      objArray: [],
			code: 123456,
			verificationError: false,
			statusSubject: "Failed",
			statusMessage: "Failed to send message.",
      modal: false,
      modal2: false,
			modal3: false,
			modal4: false,
			selectAll: false,
			checked: [],
			selectdata: [],
			selectedProcedures: [],
			fileName: "",
			statusSubject: "Failed",
			statusMessage: "Failed to send message.",
	  };

		this.pdfdata = [];

    this.toggleDropDown = this.toggleDropDown.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
		this.toggle3 = this.toggle3.bind(this);
		this.toggle4 = this.toggle4.bind(this);
		this.handleChange = this.handleChange.bind(this);
    this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(this);

  }

	async componentDidMount() {

    this.getProcedureTypes();
		//let hash = hashRandom();
		//console.log("hash: " + JSON.stringify(hash));
	}

	handleChange = () => {
    var selectAll = !this.state.selectAll;
    this.setState({ selectAll: selectAll });
    var checkedCopy = [];
    this.state.selectdata.forEach(function(e, index) {
      checkedCopy.push(selectAll);
    });
    this.setState({
      checked: checkedCopy
    });
  };

  handleSingleCheckboxChange = index => {
    console.log(index);

    var checkedCopy = this.state.checked;
    checkedCopy[index] = !this.state.checked[index];
    if (checkedCopy[index] === false) {
      this.setState({ selectAll: false });
    }

    this.setState({
      checked: checkedCopy
    });
  };

  toggle() {
	  this.setState({
	    modal: !this.state.modal
	  });
	}

	generateReport(data) {

		if (data.length<201) {
			this.uploadToS3(data);

		  this.setState({
		    modal: !this.state.modal
		  });
		} else {
			this.toggle4();
		}

	}

  toggle2() {

	  this.setState({
	    modal2: !this.state.modal2
	  });
	}

	toggle3() {
	  this.setState({
	    modal3: !this.state.modal3
	  });
	}

	toggle4() {
	  this.setState({
	    modal4: !this.state.modal4
	  });
	}

  toggleDropDown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

	handleDateChange(event) {
		if (event.target.getAttribute('name') == 'startDate') {
			this.setState({startDate: event.target.value});
			this.setState({
	      currentItem: "Custom Range",
				currentDataSetting: 3
	    });
		} else if (event.target.getAttribute('name') == 'endDate') {
			//console.log('this.state.code: ' + event.target.value);
			this.setState({endDate: event.target.value});
			this.setState({
				currentItem: "Custom Range",
				currentDataSetting: 3
	    });
		}
  }

	handleSubmit(event) {

		this.getData();
  }

	selectDropDownItem(id, title) {

    this.setState({
      currentItem: title,
			currentDataSetting: id,
      procedures: [],
    });

		if (id==1) {
			this.setState({ startDate: Moment().startOf('day').format('YYYY-MM-DD') });
		} else if (id==2) {
			this.setState({ startDate: Moment().startOf('week').format('YYYY-MM-DD') });
		} else if (id==3) {
			this.setState({ startDate: Moment().startOf('month').format('YYYY-MM-DD') });
		} else if (id==5) {
			this.setState({ startDate: Moment().subtract(1, 'month').format('YYYY-MM-DD') });
		}

		this.setState({ endDate: Moment().format('YYYY-MM-DD') });
    setTimeout(this.getData, 300);
  }

	uploadToS3 = async (data) => {

		let fileName = this.state.currentUser.payload.sub+"_Report.pdf";

		this.setState({ fileName });

		//WORKING!!!!
		//let response1 = await fetch(warrantyPDF);
		//let blob = await response1.blob(); // download as Blob object

		const blob = await pdf(<MyDocument data={data} />).toBlob();

    try {

      var url = "https://udala92lwh.execute-api.us-west-2.amazonaws.com/v1/g2-reports/"+fileName;

  		const options = {
  			method: 'PUT',
  			headers: {
  				Authorization: this.state.currentUser.jwtToken
  			},
  			body: blob
  		};

      const response = await fetch(url, options);

  		const body = await response.json();
  		console.log("upload response: " + JSON.stringify(body));

  		if (body.status == 'Success') {
  			console.log("Upload Success!");
  		} else {
  			//ADD ERROR MESSAGE
  		}

    }
    catch (err) {
      console.log(err);
    }

	}

  getData = async () => {

    var timezone = Moment.tz.guess();

    if (this.state.currentDataSetting==0) {

  		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-all-preformed-procedures";

  		const response = await fetch(url, {
  			method: 'GET',
  			headers: {
  				Authorization: this.state.currentUser.jwtToken
  			},
  		});
  		const body = await response.json();
  		console.log("all procedures get-all-preformed-procedures: " + JSON.stringify(body));

  		if (body.status == 'Success') {

        if (body.results.length>0) {
          console.log("start Date is " + JSON.stringify(body.results[body.results.length-1].timestamp));
          console.log("end Date is " + JSON.stringify(body.results[0].timestamp));
          this.setState({ startDate: Moment(body.results[body.results.length-1].timestamp).format('YYYY-MM-DD') });
          this.setState({ endDate: Moment(body.results[0].timestamp).format('YYYY-MM-DD') });
    			this.setState({ procedures: body.results, selectdata: body.results });
  				var checkedCopy = [];
  		    var selectAll = this.state.selectAll;
  		    body.results.forEach(function(e, index) {
  		      checkedCopy.push(selectAll);
  		    });
  				this.setState({
  		      checked: checkedCopy,
  		      selectAll: selectAll
  		    });
        }

  		} else {
        //ADD ERROR MESSAGE
      }

    } else {

      var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-all-preformed-procedures-for-date-range?StartDate="+this.state.startDate+"&EndDate="+this.state.endDate+"&Timezone="+timezone;
      console.log("url: " + JSON.stringify(url));

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: this.state.currentUser.jwtToken
        },
      });
      const body = await response.json();
      console.log("all procedures get-all-preformed-procedures-for-date-range: " + JSON.stringify(body.results));

      if (body.status == 'Success') {
        this.setState({ procedures: body.results, selectdata: body.results });
				var checkedCopy = [];
		    var selectAll = this.state.selectAll;
		    body.results.forEach(function(e, index) {
		      checkedCopy.push(selectAll);
		    });
				this.setState({
		      checked: checkedCopy,
		      selectAll: selectAll
		    });
      } else {
        //ADD ERROR MESSAGE
      }

    }

	};

  getProcedureTypes = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"all-procedures";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});
		const body = await response.json();
		console.log("allprocedure Types: " + JSON.stringify(body));

		if (body.status == 'Success') {
			if (body.results.length > 0) {
        this.setState({ procedureTypes: body.results});
				console.log("procedureTypes: " + JSON.stringify(this.state.procedureTypes));
        this.getData();
			} else {
				//this.noResults = true;
			}
		}
	};

	sendMessage = (event) => {
		event.preventDefault();

		console.log("Send message");
		let email = event.target.email.value;
    console.log(`email is:`, email);

		this.sendEmailMessage(email);
	}

	sendEmailMessage = async (emailAddress) => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"email-pdf-to-user?EmailRecipient="+emailAddress+"&FileName="+this.state.fileName;

		console.log("Send Email URL: " + url);

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("Send Email response: " + JSON.stringify(body));

		if (body.status == 'Success') {

			this.toggle();
			this.setState({
				statusSubject: "SUCCESS!",
				statusMessage: "Email sent Successfully!"
			})

		} else {

			this.toggle();
			this.setState({
				statusSubject: "FAILED!",
				statusMessage: "Failed to send Email to "+emailAddress+"."
			})

		}

		this.toggle3();

	};

  filterCaseInsensitive(filter, row) {
		const id = filter.pivotId || filter.id;

		//console.log("filterCaseInsensitive row[id]: " + JSON.stringify(id));

		if (id == 'date') {
			var dateString = Moment(row[id]).format('MMM Do YYYY');
			return (
					dateString !== undefined ?
							String(dateString.toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}

		if (row[id] !== null){
			return (
					row[id] !== undefined ?
							String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
					:
							true
			);
		}
	}

  getTrProps (state, rowInfo, instance) {

    if (rowInfo) {
      //console.log('getTrProps rowInfo: ' + JSON.stringify(rowInfo.row.totalSeconds));

      if (rowInfo.row.procedureType != "7mm" && rowInfo.row.procedureType != "3mm" && rowInfo.row.procedureType != "25mm") {
        if (rowInfo.row.totalSeconds<11) {
          return {
              style: {
                color: "#FC9E2D"
              }
          }
        }
      }

    }
    return {};
  };

	render() {
		//console.log("Checked array is: " + JSON.stringify(this.state.checked));

    const pdfdata = this.state.procedures.map((procedure, key) => {

			var procedureLabel = "Manual";
      var dosageLabel = "";

      if (procedure.power) {
        procedureLabel = "Manual";
      } else if (procedure.preset) {
        let procedureArray = this.state.procedureTypes.find( t => t.OBJID == procedure.preset );
        //console.log("procedureArray: " + JSON.stringify(procedureArray));
        if (procedureArray) {
          procedureLabel = this.state.procedureTypes.find( t => t.OBJID == procedure.preset ).Name;

          if (procedureLabel == "25mm") {
            dosageLabel = Math.round(
              (1 * procedure.totalseconds) / 4.91
            ) + " J/cm2";
          } else if (procedureLabel == "7mm") {
            dosageLabel = Math.round(
              (0.3 * procedure.totalseconds) / 0.38
            ) + " J/cm2";
          } else if (procedureLabel == "3mm") {
            dosageLabel = Math.round(
              (0.3 * procedure.totalseconds) / 0.07
            ) + " J/cm2";
          }
        } else {
          procedureLabel = "Unknown"
        }
      }

			var powerLabel = "0.0 Watts";

      if (procedure.watts) {
        powerLabel = (procedure.watts/10).toFixed(1)+" Watts";
      } else if (procedure.power) {
        powerLabel = (procedure.power/10).toFixed(1)+" Watts";
      }

      var wavelengthType = this.state.wavelengthTypes[procedure.wavelength];

			var isSelected = false;

			if (procedure.selected) {
				isSelected = procedure.selected;
			}

      var d = Number(procedure.totalseconds);
      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);

      var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";

			return {
        id: key,
				serialNumber: procedure.SerialNumber,
        date: Moment(procedure.timestamp).format("MMM Do YYYY"),
        procedureType: procedureLabel,
        wavelength: wavelengthType,
        treatments: procedure.treatments,
        dosage: dosageLabel,
				power: powerLabel,
        length: hDisplay + mDisplay + sDisplay,
        totalSeconds: procedure.totalseconds,
				time: Moment(procedure.timestamp).format("h:mm:ssa"),
				selected: isSelected,
			};
		});

		var tempChecked = this.state.checked;

		const selectedProcedures = pdfdata.filter(procedure => tempChecked[procedure.id]==true);

		console.log("selectedProcedures: " + JSON.stringify(selectedProcedures));

    const data = this.state.procedures.map((procedure, key) => {

      var procedureLabel = "Manual";
      var dosageLabel = "";

      if (procedure.power) {
        procedureLabel = "Manual";
      } else if (procedure.preset) {
        let procedureArray = this.state.procedureTypes.find( t => t.OBJID == procedure.preset );
        //console.log("procedureArray: " + JSON.stringify(procedureArray));
        if (procedureArray) {
          procedureLabel = this.state.procedureTypes.find( t => t.OBJID == procedure.preset ).Name;

          if (procedureLabel == "25mm") {
            dosageLabel = Math.round(
              (1 * procedure.totalseconds) / 4.91
            ) + " J/cm2";
          } else if (procedureLabel == "7mm") {
            dosageLabel = Math.round(
              (0.3 * procedure.totalseconds) / 0.38
            ) + " J/cm2";
          } else if (procedureLabel == "3mm") {
            dosageLabel = Math.round(
              (0.3 * procedure.totalseconds) / 0.07
            ) + " J/cm2";
          }

        } else {
          procedureLabel = "Unknown"
        }
      }

			var powerLabel = "0.0 Watts";

      if (procedure.watts) {
        powerLabel = (procedure.watts/10).toFixed(1)+" Watts";
      } else if (procedure.power) {
        powerLabel = (procedure.power/10).toFixed(1)+" Watts";
      }

      var wavelengthType = this.state.wavelengthTypes[procedure.wavelength];

      var d = Number(procedure.totalseconds);
      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);

      var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";

			return {
				id: key,
        serialNumber: procedure.SerialNumber,
        date: procedure.timestamp,
        procedureType: procedureLabel,
        wavelength: wavelengthType,
        treatments: procedure.treatments,
        dosage: dosageLabel,
				power: powerLabel,
        length: hDisplay + mDisplay + sDisplay,
        totalSeconds: procedure.totalseconds,
				time: Moment(procedure.timestamp).format("h:mm:ssa"),
				actions: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
            <Button
							onClick={() => {
								let obj = pdfdata.find(o => o.id === key);
                console.log('button obj LoginID: ' + obj.Email);
								this.setState({
									modal2: !this.state.modal2,
									obj: obj,
                  objArray: obj
								});
							}}
							color="inverse"
							size="sm"
							round="true"
							icon="true"
							title="Send Message"
						>
							Generate Procedure Report
						</Button>
						{/* use this button to remove the data row */}
						{/* <Button
							onClick={() => {
								let newdata = data2;
								newdata.find((o, i) => {
									if (o.id === key) {
										newdata.splice(i, 1);
										console.log(newdata);
										return true;
									}
									return false;
								});
								this.setState({ jsonData: newdata });
							}}
							className="ml-1"
							color="danger"
							size="sm"
							round="true"
							icon="true"
						>
							<i className="fa fa-times" />
						</Button> */}
					</div>
				),
				checkbox: (
					// we've added some custom button actions
					<div className="text-center">
						{/* use this button to add a edit kind of action */}
						<Input
							onChange={() => {
								if (this.state.procedures[key].selected) {
									this.state.procedures[key].selected = !this.state.procedures[key].selected
								} else {
									this.state.procedures[key].selected = true;
								}

								//let obj = this.pdfdata.find(o => o.id === key);
								//obj.selected = !obj.selected;
								console.log('obj selected: ' + this.state.procedures[key].selected);
								//this.pdfdata[key] = obj;
							}}
							checked={this.state.procedures[key].selected}
							name="confirmed"
							id="confirmed"
							type="checkbox" />
							{this.state.procedures[key].selected ? "Selected" : "Not Selected"}
					</div>
				)
			};
		});

		return (
      <Card className="card-hover">
				<CardBody>
          <Row>
            <Col xs="12" md="5" lg="5" className="text-left">
              <h1 style={{color:'#737782'}}>Procedure Reports</h1>
            </Col>
            <Col xs="12" md="7" lg="7" className="text-left">
              <span style={{color:'#737782', fontSize: "18px"}}><b>Non Surgical</b> and <b>Surgical</b> procedures show in <b style={{ color: "#FC9E2D"}}>orange</b> are considered to be too short (less than 10 seconds) and will not be counted as a procedure due to the short time. <b>Pain Relief</b> procedures that are less than 10 seconds will be counted as a procedure.</span>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col xs="12" md="6" lg="6" className="text-left">
              <div className="d-flex justify-content-between float-left" style={{ width: '400px' }}>
                <div className="mt-1" style={{ width: '45px' }}>
                  <h6 style={{ lineHeight: '1.8' }}>From:</h6>
                </div>
                <div className="mt-1 ml-2" style={{ width: '135px' }}>
                  <Input name='startDate' size='sm' type="date" value={this.state.startDate} onChange={this.handleDateChange} />
                </div>
                <div className="mt-1 ml-2" style={{ width: '23px' }}>
                  <h6 style={{ lineHeight: '1.8' }}>To:</h6>
                </div>
                <div className="mt-1 ml-2" style={{ width: '135px' }}>
                  <Input name='endDate' size='sm' type="date" value={this.state.endDate} onChange={this.handleDateChange} />
                </div>
                <div className="mt-1 ml-2" style={{ width: '50px' }}>
                  <Button size="sm" color="dark" onClick={() => this.handleSubmit()} title="GO">
                    GO
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs="12" md="6" lg="6" className="text-right">
              <div>
                <ButtonDropdown isOpen={this.state.isOpen} toggle={this.toggleDropDown}>
                  <DropdownToggle color="dark" caret>
                    {this.state.currentItem}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => this.selectDropDownItem(0, 'Global')}>Global</DropdownItem>
										<DropdownItem onClick={() => this.selectDropDownItem(1, 'Today')}>Today</DropdownItem>
                    <DropdownItem onClick={() => this.selectDropDownItem(2, 'This Week')}>This Week</DropdownItem>
                    <DropdownItem onClick={() => this.selectDropDownItem(3, 'This Month')}>This Month</DropdownItem>
                    <DropdownItem onClick={() => this.selectDropDownItem(5, 'Last 30 Days')}>Last 30 Days</DropdownItem>
                    <DropdownItem onClick={() => this.selectDropDownItem(4, 'Custom Range')}>Custom Range</DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
								{selectedProcedures.length>0 &&
									<Button className="ml-3" size="md" color="brightgreen" title="PRINT REPORT" onClick={() => this.generateReport(selectedProcedures)}>
	                  Generate Report
	                </Button>
								}
              </div>
            </Col>
          </Row>
          <ReactTable
            style={{backgroundColor:'#000', padding:'10px'}}
						columns={[
							{
								Header: (
									<Fragment>
									<Label for="confirmed" check>
                  <Input
                    type="checkbox"
                    onChange={this.handleChange}
                    checked={this.state.selectAll}
                  />
										{this.state.selectAll ? "Unselect All" : "Select All"}
									</Label>
									</Fragment>
                ),
                Cell: row => (
									<div style={{ textAlign: "center", position: 'relative' }}>
                  <Input
                    type="checkbox"
                    defaultChecked={this.state.checked[row.index]}
                    checked={this.state.checked[row.index]}
                    onChange={() => this.handleSingleCheckboxChange(row.index)}
                  />
									</div>
                ),
                minWidth: 120,
								maxWidth: 150,
                sortable: false,
                filterable: false
							},
							{
								Header: "Date of Procedure",
								accessor: "date",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
                    <div style={{ textAlign: "center" }}>{Moment(row.value).format("MMM Do YYYY")}</div>
                  )
							},
							{
								Header: "Procedure Type",
								accessor: "procedureType",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
							},
							{
								Header: "Power",
								accessor: "power",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
							},
							{
								Header: "Wavelength",
								accessor: "wavelength",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
							},
              {
								Header: "Treatments",
								accessor: "treatments",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
							},
              {
								Header: "Dosage",
								accessor: "dosage",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
							},
							{
								Header: "Procedure Length",
								accessor: "length",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
							},
              {
								Header: "",
								accessor: "totalSeconds",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								show: false,
								Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
							},
							{
								Header: "Serial Number",
								accessor: "serialNumber",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
							},
							{
								Header: "Local Time",
								accessor: "time",
								style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
								Cell: row => (
                    <div style={{ textAlign: "center" }}>{row.value}</div>
                  )
							}
						]}
						defaultPageSize={15}
            sortable={true}
						showPaginationBottom={true}
						className="-striped -highlight"
						data={data}
            getTrProps={this.getTrProps}
						filterable
            defaultFilterMethod={(filter, row) => this.filterCaseInsensitive(filter, row) }
					/>
        </CardBody>
        <div>
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
    				<ModalHeader toggle={this.toggle}>Procedure Report</ModalHeader>
						<Form onSubmit={this.sendMessage}>
    				<ModalBody>
							<Row>
								<Col xs="12" md="12" lg="12" className="text-left pb-3">
									<h4>Send message to owner of Device: {this.state.obj.SerialNumber}</h4>
								</Col>
							</Row>
								<FormGroup>
									<Label for="subject">Send message to owner of Device:</Label>
									<Input type="text" name="email" id="email" defaultValue={this.state.currentUser.payload.email}/>
								</FormGroup>
              <PDFViewer style={{ height: "600px", width: "100%"}}>
                <MyDocument data={selectedProcedures} />
              </PDFViewer>
    				</ModalBody>
  					<ModalFooter>
  						<Button color="primary" className="ml-1" onClick={this.toggle}>Cancel</Button>
							<Button color="primary" type="submit">Send</Button>
  					</ModalFooter>
						</Form>
  			  </Modal>
				</div>
        <div>
          <Modal isOpen={this.state.modal2} toggle={this.toggle2}>
    				<ModalHeader toggle={this.toggle2}>Procedure Report</ModalHeader>
    				<ModalBody>
              <PDFViewer style={{ height: "730px", width: "100%"}}>
                <MyDocument data={[this.state.objArray]} />
              </PDFViewer>
    				</ModalBody>
  					<ModalFooter>
  						<Button color="primary" className="ml-1" onClick={this.toggle2}>OK</Button>
  					</ModalFooter>
  			  </Modal>
				</div>
				<div>
					<Modal isOpen={this.state.modal3} toggle={this.toggle3}>
						<ModalHeader toggle={this.toggle3}>{this.state.statusSubject}</ModalHeader>
						<ModalBody>
							<span>{this.state.statusMessage}</span>
						</ModalBody>
						<ModalFooter>
							<Button color="primary" className="ml-1" onClick={this.toggle3}>OK</Button>
						</ModalFooter>
					</Modal>
				</div>
				<div>
					<Modal isOpen={this.state.modal4} toggle={this.toggle4}>
						<ModalHeader toggle={this.toggle4}>Report Generation Error</ModalHeader>
						<ModalBody>
							<span>Maximum 200 procedures for Procedure Report.</span>
						</ModalBody>
						<ModalFooter>
							<Button color="primary" className="ml-1" onClick={this.toggle4}>OK</Button>
						</ModalFooter>
					</Modal>
				</div>
      </Card>
		);
	}
}

export default ProceduresList;
