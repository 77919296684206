import MobileDashboard from '../views/mobile-dashboard/mobile-dashboard';
import MobileVitalsDashboard from '../views/mobile-dashboard/mobile-vitals-dashboard';
import WarrantyMobile from '../views/vitals/warranty-mobile';
import DeleteAccountMobile from '../views/vitals/delete-account-mobile';
import HeartbeatFull from '../views/heartbeat/heartbeat-full';

var mobileRoutes = [
    { path: '/mobile/mobile-dashboard', name: 'MobileDashboard', icon: 'mdi mdi-account-key', component: MobileDashboard },
    { path: '/mobile/mobile-vitals-dashboard/:id', name: 'MobileVitalsDashboard', icon: 'mdi mdi-account-key', component: MobileVitalsDashboard },
    { path: '/mobile/warranty/:id', name: 'WarrantyMobile', icon: 'mdi mdi-account-key', component: WarrantyMobile },
    { path: '/mobile/delete-account', name: 'DeleteAccountMobile', icon: 'mdi mdi-account-key', component: DeleteAccountMobile },
    { path: '/heartbeat/full', name: 'HeartbeatFull', icon: 'mdi mdi-account-key', component: HeartbeatFull }
];
export default mobileRoutes;
