import React from "react";
import {
	Card,
	CardBody,
	CardTitle,
	Row,
	Col,

} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactGA from "react-ga4";

import {
	CalibrationView
} from '../../components/support/';

class Calibration extends React.Component {

	constructor(props) {
    super(props);

  }

	componentDidMount() {
		ReactGA.pageview(window.location.pathname);
	}

	render() {
		return (
			<div>
				<Row>
					<Col md="12">
            <CalibrationView />
					</Col>
				</Row>
			</div>
		);
	}
}

export default Calibration;
