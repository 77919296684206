import React from "react";
import { Component, Fragment, PureComponent } from "react";
import { Auth } from "aws-amplify";
import { withRouter } from 'react-router-dom';

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	CustomInput,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { authenticationService } from '../../../jwt/_services';
import Chart from 'react-c3-component';
import 'c3/c3.css';

class RegisteredUser extends React.Component {

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 3,
			totalProcedures: 0,
			currentUser: this.props.currentUser,
			noDataForDevices: true,
			deviceInfo: [],
	  };

  }

	async componentDidMount() {
		this.getData();
	}

	getData = async () => {
		console.log("Getting Device Registered User");

		if (this.state.currentUser) {
			//console.log("topprocedures 1: ");

			var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"get-user-for-device?SerialNumber="+this.props.currentDevice;

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: this.state.currentUser.jwtToken
				},
			});

			const body = await response.json();
			console.log("Device Registered User response: " + JSON.stringify(body.response));

			if (body.status == 'Success') {
				this.setState({deviceInfo: body.response});
				//console.log("Device Registered First Name: " + this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value)));
				this.state.noDataForDevices = false;
			} else {
				this.state.noDataForDevices = true;
			}
		}
	};

	render() {

		var firstName = "Unknown";
		var lastName = "Unknown";
		var email = "Unknown";
		var city = "Unknown";
		var state = "Unknown";
		var country = "Unknown";

		if (this.props.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']>0) {
			if (!this.state.noDataForDevices) {

				if (this.state.deviceInfo.userInfo.UserAttributes) {
					firstName = this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("given_name")).map(filteredName => (filteredName.Value));
				}

				if (this.state.deviceInfo.userInfo.UserAttributes) {
					lastName = this.state.deviceInfo.userInfo.UserAttributes.filter(attribute => attribute.Name.includes("family_name")).map(filteredName => (filteredName.Value));
				}

				if (this.state.deviceInfo.userInfo.Username) {
					email = this.state.deviceInfo.userInfo.Username;
				}

				if (this.state.deviceInfo.deviceAttributes[0].geo_city) {
					city = this.state.deviceInfo.deviceAttributes[0].geo_city;
				}
				if (this.state.deviceInfo.deviceAttributes[0].geo_state) {
					state = this.state.deviceInfo.deviceAttributes[0].geo_state;
				}
				if (this.state.deviceInfo.deviceAttributes[0].geo_country) {
					country = this.state.deviceInfo.deviceAttributes[0].geo_country;
				}
			}
		} else {
			firstName = this.props.currentUser.payload.given_name;
			lastName = this.props.currentUser.payload.family_name;
			email = this.props.currentUser.payload.email;
		}

		return (
      <Card className="card-hover">
				<CardBody style={{ minHeight: 450 }}>
					<Fragment>
					<Row>
						<Col xs="6" md="6" lg="6" className="text-left">
							<CardTitle>Registered User</CardTitle>
						</Col>
						<Col xs="6" md="6" lg="6" className="text-right">
							{!this.props.currentUser.payload["custom:role"] &&
								<i className="mdi mdi-account-edit font-24" style={{ color: '#8a9097', cursor:"pointer" }} onClick={event =>  window.location.href='/account'}/>
							}
						</Col>
					</Row>
					<Row className="pb-3 pt-3">
						<Col xs="3" md="3" lg="3">
							<h6>First Name:</h6>
						</Col>
						<Col xs="9" md="9" lg="9">
							<h6 style={{ color: '#414755' }}>{firstName}</h6>
						</Col>
					</Row>
					<Row className="pb-3">
						<Col xs="3" md="3" lg="3">
							<h6>Last Name:</h6>
						</Col>
						<Col xs="9" md="9" lg="9">
							<h6  style={{ color: '#414755' }}>{lastName}</h6>
						</Col>
					</Row>
					<Row className="pb-3">
						<Col xs="3" md="3" lg="3">
							<h6>Email:</h6>
						</Col>
						<Col xs="9" md="9" lg="9">
							<h6  style={{ color: '#414755' }}>{email}</h6>
						</Col>
					</Row>
					{this.props.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']>0 &&
					<Fragment>
						<Row className="pb-3">
							<Col xs="3" md="3" lg="3">
								<h6>City:</h6>
							</Col>
							<Col xs="9" md="9" lg="9">
								<h6  style={{ color: '#414755' }}>{city}</h6>
							</Col>
						</Row>
						<Row className="pb-3">
							<Col xs="3" md="3" lg="3">
								<h6>State:</h6>
							</Col>
							<Col xs="9" md="9" lg="9">
								<h6  style={{ color: '#414755' }}>{state}</h6>
							</Col>
						</Row>
						<Row className="pb-3">
							<Col xs="3" md="3" lg="3">
								<h6>Country:</h6>
							</Col>
							<Col xs="9" md="9" lg="9">
								<h6  style={{ color: '#414755' }}>{country}</h6>
							</Col>
						</Row>
					</Fragment>
					}
					</Fragment>
        </CardBody>
      </Card>
		);
	}
}

export default RegisteredUser;
