import React from "react";
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup
} from 'reactstrap';

import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';

class BleStrength extends React.Component {

	noResults = true;

	constructor(props) {
    super(props);
    this.state = {
			rSelected: 3,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			totalProcedures: 0,
	  };

		this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
  }

	async componentDidMount() {

		setInterval(this.getMessage, 4000);
	}

	getMessage = () => {
		console.log("onMessageArrivedInWifiStrength:"+this.props.message.wifi);
  }

  onRadioBtnClick(rSelected) {
    this.setState({ rSelected });
  }

	render() {
		return (
      <Card className="card-hover">
				<CardBody style={{ minHeight: 193 }}>
					<CardTitle>Bluetooth Signal</CardTitle>
					{this.props.isReceivingMessages &&
						<Fragment>
						<Row>
							<Col xs="12" md="12" lg="12">
								<div className="">
									<ButtonGroup style={{ width: '100%' }}>
										<Button
											style={{width: '100%', cursor: 'default'}}
											color="good"
											active={this.props.message.ble === 4}
											disabled={this.props.message.ble !== 4}
										>
											Good
										</Button>
										<Button
											color="weak"
											style={{width: '100%', cursor: 'default'}}
											active={this.props.message.ble === 3 || this.props.message.ble === 2}
											disabled={this.props.message.ble !== 3 || this.props.message.ble !== 2}
										>
											Weak
										</Button>
										<Button
											color="damaged"
											style={{width: '100%', cursor: 'default'}}
											active={this.props.message.ble === 1 || this.props.message.ble === 0}
											disabled={this.props.message.ble !== 1 || this.props.message.ble !== 0}
										>
											Poor
										</Button>
									</ButtonGroup>
								</div>
							</Col>
						</Row>
						<div className="pb-3">
							<Row className="mt-4">
								<Col xs="6" md="6" lg="6" className="text-left">
									<span>Strength</span>
								</Col>
							</Row>
							<Progress multi className="mt-1">
								<Progress bar color="yellow" value={this.props.message.ble*20} />
							</Progress>
						</div>
						{this.props.message.pedal_batt && this.props.message.fw_ver > 1.40 &&
							<Fragment>
							<Row className="mt-2 mb-2">
								<Col xs="6" md="6" lg="6" className="text-left">
									<span>Footpedal Battery</span>
								</Col>
							</Row>
							<Row>
								<Col xs="12" md="12" lg="12">
									<div className="">
										<ButtonGroup style={{ width: '100%' }}>
											<Button
												style={{width: '100%', cursor: 'default'}}
												color="good"
												active={this.props.message.pedal_batt === 3}
												disabled={this.props.message.pedal_batt !== 3}
											>
												Good
											</Button>
											<Button
												color="weak"
												style={{width: '100%', cursor: 'default'}}
												active={this.props.message.pedal_batt === 2}
												disabled={this.props.message.pedal_batt !== 2}
											>
												Medium
											</Button>
											<Button
												color="damaged"
												style={{width: '100%', cursor: 'default'}}
												active={this.props.message.pedal_batt === 1}
												disabled={this.props.message.pedal_batt !== 1}
											>
												Needs Charge
											</Button>
										</ButtonGroup>
									</div>
								</Col>
							</Row>
							</Fragment>
						}
						</Fragment>
					}
					{!this.props.isReceivingMessages &&
						<Fragment>
						{this.state.currentUser && this.state.currentUser.payload['custom:role'] && this.state.currentUser.payload['custom:role']==2 && this.props.historyMessage ? (
							<Fragment>
							<Row>
								<Col xs="12" md="12" lg="12">
									<div className="">
										<ButtonGroup style={{ width: '100%' }}>
											<Button
												style={{width: '100%', cursor: 'default'}}
												color="good"
												active={this.props.historyMessage.ble === 4}
												disabled={this.props.historyMessage.ble !== 4}
											>
												Good
											</Button>
											<Button
												color="weak"
												style={{width: '100%', cursor: 'default'}}
												active={this.props.historyMessage.ble === 3 || this.props.historyMessage.ble === 2}
												disabled={this.props.historyMessage.ble !== 3 || this.props.historyMessage.ble !== 2}
											>
												Weak
											</Button>
											<Button
												color="damaged"
												style={{width: '100%', cursor: 'default'}}
												active={this.props.historyMessage.ble === 1 || this.props.historyMessage.ble === 0}
												disabled={this.props.historyMessage.ble !== 1 || this.props.historyMessage.ble !== 0}
											>
												Poor
											</Button>
										</ButtonGroup>
									</div>
								</Col>
							</Row>
							<div className="pb-3">
								<Row className="mt-4">
									<Col xs="6" md="6" lg="6" className="text-left">
										<span>Strength</span>
									</Col>
								</Row>
								<Progress multi className="mt-1">
									<Progress bar color="yellow" value={this.props.historyMessage.ble*20} />
								</Progress>
							</div>
							{this.props.message.pedal_batt && this.props.message.fw_ver > 1.40 &&
								<Fragment>
								<Row className="mt-2 mb-2">
									<Col xs="6" md="6" lg="6" className="text-left">
										<span>Footpedal Battery</span>
									</Col>
								</Row>
								<Row>
									<Col xs="12" md="12" lg="12">
										<div className="">
											<ButtonGroup style={{ width: '100%' }}>
												<Button
													style={{width: '100%', cursor: 'default'}}
													color="good"
													active={this.props.message.pedal_batt === 3}
													disabled={this.props.message.pedal_batt !== 3}
												>
													Good
												</Button>
												<Button
													color="weak"
													style={{width: '100%', cursor: 'default'}}
													active={this.props.message.pedal_batt === 2}
													disabled={this.props.message.pedal_batt !== 2}
												>
													Medium
												</Button>
												<Button
													color="damaged"
													style={{width: '100%', cursor: 'default'}}
													active={this.props.message.pedal_batt === 1}
													disabled={this.props.message.pedal_batt !== 1}
												>
													Needs Charge
												</Button>
											</ButtonGroup>
										</div>
									</Col>
								</Row>
								</Fragment>
							}
							</Fragment>
						) : (
							<div className="mt-5 text-center">
								<h5>Device {this.props.currentDevice} is Currently not Connected.</h5>
							</div>
						)}
						</Fragment>
					}
        </CardBody>
      </Card>
		);
	}
}

export default BleStrength;
