import React from "react";
import ReactTable from 'react-table-v6'
import Moment from 'moment';
import { Component, Fragment } from "react";

import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
	Progress,
	Input,
	ListGroup,
	ListGroupItem,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

import update from 'react-addons-update';
import redpin from '../../../assets/images/pins/red_pin.png';
import greenpin from '../../../assets/images/pins/green_pin.png';
import brokenpin from '../../../assets/images/pins/broken_pin.png';
import { Line, Bar } from 'react-chartjs-2';
import Chart from 'react-c3-component';
import 'c3/c3.css';
import 'react-table-v6/react-table.css'
import { authenticationService } from '../../../jwt/_services';

class SurveyView extends React.Component {

	intervalID = 0;

	constructor(props) {
    super(props);

		const currentUser = authenticationService.currentUserValue;
		console.log('currentUser info: ' + JSON.stringify(currentUser));
		console.log('currentUser info: ' + JSON.stringify(currentUser.token));

    this.state = {
			noDevices: false,
			devicesLoaded: false,
			currentUser,
			modal: false,
			currentUser: JSON.parse(localStorage.getItem('currentUser')),
			devices: [],
	  };

		// this.toggle4 = this.toggle4.bind(this);
		// this.toggle5 = this.toggle5.bind(this);
		// this.toggle6 = this.toggle6.bind(this);
		// this.handleDateChange = this.handleDateChange.bind(this);

  }

	async componentDidMount() {

    //this.getData();
	}

	componentWillUnmount() {
		clearInterval(this.intervalID);

	}

  getData = async () => {

		var url = "https://9xnwyna9id.execute-api.us-west-2.amazonaws.com"+process.env.REACT_APP_ENV+"all-devices";

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: this.state.currentUser.jwtToken
			},
		});

		const body = await response.json();
		console.log("all devices: " + JSON.stringify(body));

		if (body.status == 'Success') {
			this.setState({ devices: body.results });
		} else {
      //ADD ERROR MESSAGE
    }

	};


	render() {
		return (
			<Row className="mb-3">
				<Col md="6">
					<Card className="card-hover">
						<CardBody>
							<Row className="mb-3">
								<Col md="12">
									<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdYZIBNb9kNibUzWWgAbHxUdTfsgRLQRz4eN1nJ8p7wk2CTLQ/viewform?embedded=true" width="100%" height="5139" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	}
}

export default SurveyView;
